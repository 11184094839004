import { Mask } from 'components/Mask/Mask';
import { useBottomSheet } from 'hooks/useBottomSheet';
import { omit } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { BottomSheetContents } from 'stores/BottomSheetStore';

import styles from './BottomSheetItem.module.scss';

export const BottomSheetItem = observer(
  ({
    id,
    content,
    isOpened,
    title,
    needCloseButton,
    sheetRef,
    actionAfterClose,
    closeAfterActionSuccess,
    onSpringStart,
    onSpringEnd,
    setBottomSheetHeight,
    ...rest
  }: BottomSheetContents) => {
    const [mask, setMask] = useState(false);
    const hasMask = typeof rest.blocking === 'boolean' ? rest.blocking : true;
    const propsWithoutBlock = omit(rest, 'blocking');
    const needHeader = title || needCloseButton;
    const { closeBottomSheet } = useBottomSheet();

    const handleClose = () => {
      // closeAfterActionSuccess 이 실패하면 바텀시트를 닫지 않는다. ex) confirm
      if (closeAfterActionSuccess && !closeAfterActionSuccess()) {
        return;
      }

      closeBottomSheet(id);

      if (actionAfterClose) {
        actionAfterClose();
      }
    };

    return (
      <>
        <Mask visible={mask} zIndex={190} onClick={handleClose} />
        <BottomSheet
          ref={sheetRef}
          onSpringEnd={() => {
            if (setBottomSheetHeight && sheetRef?.current) {
              setBottomSheetHeight(sheetRef.current.height);
            }
          }}
          onSpringStart={(e) => {
            if (onSpringStart) {
              onSpringStart(e);
            }

            if (e.type === 'OPEN') {
              if (hasMask) {
                setMask(true);
              }
            }

            if (e.type === 'CLOSE') {
              setMask(false);
            }
          }}
          header={
            needHeader ? (
              <Header title={title} needCloseButton={needCloseButton} handleClose={handleClose} />
            ) : undefined
          }
          scrollLocking={false}
          {...propsWithoutBlock}
          // 바텀시트 자체 블로킹 기능대신 해당 컴포넌트에서 custom해서 사용하기때문에 무조건 false
          blocking={false}
          open={isOpened}
        >
          {content}
        </BottomSheet>
      </>
    );
  }
);

interface HeaderProps {
  title?: string;
  needCloseButton?: boolean;
  handleClose: () => void;
}

const Header = ({ title, needCloseButton, handleClose }: HeaderProps) => {
  return (
    <div className={styles.header}>
      {needCloseButton && (
        <img
          src="/images/ic-line-close-black.png"
          style={{ width: 20, height: 20 }}
          onClick={handleClose}
        />
      )}
      <div className={styles.title} style={{ marginRight: needCloseButton ? 20 : 0 }}>
        {title}
      </div>
    </div>
  );
};
