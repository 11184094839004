export const ID_VALIDATION = '아이디는 4자 이상의 영문 또는 숫자로 입력해주세요.';
export const ID_DUPLICATE_VALIDATION = '아이디 중복 체크를 진행해주세요.';
export const PASSWORD_MISMATCH = '비밀번호가 일치하지 않습니다.';
export const PASSWORD_MATCH = '비밀번호가 일치합니다.';
export const PASSWORD_VALIDATION = '비밀번호는 8자 이상의 영어 숫자 조합입니다.';
export const USER_NAME_VALIDATION = '이름은 숫자를 제외하고 입력해주세요.';
export const PLATFORM_VALIDATION = '플랫폼을 선택해주세요.';
export const USER_EMAIL_VALIDATION = '이메일 형식이 잘못되었습니다.';
export const CONTACT_VALIDATION = '연락처 형식이 잘못되었습니다.';
export const SMS_CERTIFICATION_VALIDATION = 'SMS인증이 완료 되지않았습니다.';
export const COMPANY_NAME_VALIDATION = '회사명을 입력해주세요.';
export const COMPANY_INTRODUCTION_VALIDATION = '회사소개를 선택해주세요.';
export const TEL_NUMBER_VALIDATION = '전화번호는 - 을 제외한 숫자만 입력해주세요.';
export const CUSTOMER_CAR_NUMBER_VALIDATION = '차량 번호 형식을 확인해주세요.';
export const INVALID_ID_LICENSE_NUMBER_MESSAGE = '유효한 주민등록번호가 아닙니다.';
export const FAX_NUMBER_VALIDATION = '올바른 팩스번호를 입력해주세요.';
export const BIRTHDAY_VALIDATION = '올바른 생년월일을 입력해주세요.';
export const OWN_CAR_VALIDATOR = '보유차량은 공백을 제외하고 입력해주세요.';

export const CERTIFY_NUMBER_OVER = '인증시간이 만료되었습니다.';
export const CERTIFY_FAIL_MESSAGE = '인증번호 형식이 다릅니다.';
export const CERTIFY_NOT_SAME_MESSAGE = '인증번호가 일치하지 않습니다.';
export const CERTIFY_PHONE_SEND_MESSAGE = '인증번호를 발송하였습니다.';
export const CERTIFY_PHONE_SUCCESS_MESSAGE = '인증에 성공하였습니다.';

export const INPUT_NAME = '이름을 입력하세요.';
export const INPUT_ADDRESS = '주소를 입력하세요';
export const INPUT_EMAIL_ADDRESS = '이메일 주소를 입력하세요';
export const INPUT_EMAIL_INTRODUCE_PLACE_NAME = '소개처명을 입력하세요.';
