import { observer } from 'mobx-react-lite';
import useStores from 'stores/UseStores';

import { BottomSheetItem } from './BottomSheetItem';

/**
 * 최상단에서 관리하는 바텀시트 리스트.
 * 하나의 바텀시트로 처리하기 어려운 상황도 있어서 리스트로 제어.
 * open하면 리스트에 추가되고 close시 리스트에 제거된다.
 */
const BottomSheetList = observer(() => {
  const { bottomSheetStore } = useStores();
  const { bottomSheetContentsList } = bottomSheetStore;

  return (
    <>
      {bottomSheetContentsList.map((bottomSheetContents) => {
        return <BottomSheetItem key={bottomSheetContents.id} {...bottomSheetContents} />;
      })}
    </>
  );
});

export default BottomSheetList;
