import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { _strEnum } from 'helpers/zodHelpers';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const GetPoliciesParam = z.object({});
export type GetPoliciesParam = z.infer<typeof GetPoliciesParam>;

export interface SuccessResponse {
  policies: {
    ims: PolicyList[];
    kakao: PolicyList[];
  };
  defaultInfo: callApi2.DefaultInfo;
}
export interface PolicyList {
  agree: boolean;
  agree_at: string;
  apply_at: string;
  id: number;
}

export type GetPoliciesResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getPolicies(store: StoreType): Promise<GetPoliciesResponse> {
  const response = await callApi2.get<GetPoliciesResponse>(`/v3/orderer/policies`, {});

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
