// partnerDetail(params) {
//   return callApi({
//     method: 'GET',
//     url: `/v2/partners/${params.id}`,
//     stores: {
//       menuStore: params.menuStore,
//       authStore: params.authStore,
//     },
//     serverCookie: params.cookie,
//   });
// },

import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { omit } from 'lodash';
import { number, string, z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const GetPartnerDetailParam = z.object({
  id: z.number(),
});
export type GetPartnerDetailParam = z.infer<typeof GetPartnerDetailParam>;

export interface SuccessResponse {
  claim_by_partner: number;
  partner_info: PartnerInfo;
  requests_by_partner: number;
  defaultInfo: callApi2.DefaultInfo;
}
export interface PartnerInfo {
  id: number;
  partner_address: string;
  partner_contact: string;
  partner_manager: string;
  partner_name: string;
}

export type GetPartnerDetailResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getPartnerDetail(
  params: GetPartnerDetailParam,
  store: StoreType
): Promise<GetPartnerDetailResponse> {
  const response = await callApi2.get<GetPartnerDetailResponse>(
    `/v2/partners/${params.id}`,
    omit(params, ['id'])
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
