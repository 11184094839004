import { MobXProviderContext } from 'mobx-react';
import React from 'react';

import ApplyPartnerStore from './ApplyPartnerStore';
import AuthStore from './AuthStore';
import BankStore from './BankStore';
import BottomSheetStore from './BottomSheetStore';
import BranchStore from './BranchStore';
import CarAuctionStore from './CarAuctionStore';
import CarOptionStore from './CarOptionStore';
import CommonStore from './CommonStore';
import ConsignStore from './ConsignStore';
import ContractStore from './ContractStore';
import DeliveryAreaStore from './DeliveryAreaStore';
import GroupCostStore from './GroupCostStore';
import InsuranceSwitchStore from './InsuranceSwitchStore';
import LoadingStore from './LoadingStore';
import MenuStore from './MenuStore';
import ModalStore from './ModalStore';
import NormalContractStore from './NormalContractStore';

/**
 * React hooks를 사용하는 컴포넌트에서 store를 가져올 때 사용한다.
 * 참조) https://mobx-react.js.org/recipes-migration#hooks-for-the-rescue
 */
type Istores = {
  authStore: AuthStore;
  applyPartnerStore: ApplyPartnerStore;
  contractStore: ContractStore;
  normalContractStore: NormalContractStore;
  loadingStore: LoadingStore;
  menuStore: MenuStore;
  modalStore: ModalStore;
  insuranceSwitchStore: InsuranceSwitchStore;
  groupCostStore: GroupCostStore;
  deliveryAreaStore: DeliveryAreaStore;
  consignStore: ConsignStore;
  carAuctionStore: CarAuctionStore;
  commonStore: typeof CommonStore;
  branchStore: BranchStore;
  bottomSheetStore: BottomSheetStore;
  carOptionStore: CarOptionStore;
  bankStore: BankStore;
};
export default function useStores(): Istores {
  const stores = React.useContext(MobXProviderContext) as Istores;

  return stores;
}
