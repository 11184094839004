import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';

export interface IDeleteMonthlyCostTableParams {
  monthly_cost_table_id: string;
}

type SuccessResponse = {
  success: true;
};

export type IDeleteMonthlyCostTableResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function deleteMonthlyCostTable(params) {
  const response = await callApi2._delete<IDeleteMonthlyCostTableResponse>(
    `/v2/group-cost-tables/monthly/${params.monthly_cost_table_id}`,
    {}
  );

  return response;
}
