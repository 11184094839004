import callApi from '../../helpers/callApi';

export default {
  getPaymentMethodList(params) {
    return callApi({
      method: 'GET',
      url: '/v2/payment-methods',
      params: {
        page: params.page,
        in_use: params.inUse,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  setInUse(params) {
    return callApi({
      method: 'POST',
      url: `/v2/payment-methods/${params.id}/in-use`,
    });
  },
  registerPaymentMethod(params) {
    return callApi({
      method: 'POST',
      url: '/v2/payment-methods',
      data: params.data,
    });
  },
  editPaymentMethod(params) {
    return callApi({
      method: 'POST',
      url: `/v2/payment-methods/${params.id}`,
      data: params.data,
    });
  },
  removePaymentMethod(params) {
    return callApi({
      method: 'POST',
      url: `/v2/payment-methods/${params.id}/remove`,
    });
  },
};
