interface InputData {
  type?: string;
  placeholder?: string;
  comma?: boolean;
  style?: object;
  maxLength?: number;
  disabled?: boolean;
}

interface ReturnData {
  key: string;
  label: string;
  necessary?: boolean;
  input?: InputData;
  btn?: object;
  type?: string;
  radio?: object;
  checkBox?: object;
  select?: object;
}

interface RegisterInfoProps {
  isDelivery: boolean;
  rent_type: string;
  result?: Array<Array<ReturnData>>;
}

interface Result {
  result: Array<Array<ReturnData>>;
}

import { etcInsuranceData, majorInsuranceData } from './insuranceData';

const insuranceList = () => {
  return majorInsuranceData.concat(etcInsuranceData).filter((item) => item.value !== '기타');
};

const isRentTypeInsu = ({ result }: Result) => {
  const returnDay = {
    key: 'expect_return',
    label: '반납 예정 일시',
    type: 'fullDate',
    calendarOption: 'end',
  };
  const carInfo = [
    {
      key: 'crashCarNumber',
      label: '사고 차량번호',
      input: {
        type: 'text',
        placeholder: '12가1234, 서울01가1234',
        style: { width: '220px' },
      },
    },
    {
      key: 'insurance_company',
      label: '접수번호',
      type: 'insuranceType',
      select: {
        data: insuranceList(),
        arrow: true,
        style: { width: '168px', marginRight: '10px' },
        placeholder: '보험사를 선택해주세요',
      },
      input: {
        disabled: false,
      },
      addInput: {
        key: 'registration_id',
        input: {
          type: 'number',
          disabled: false,
          placeholder: '"-" 없이 숫자만 입력해주세요.',
          style: { width: '100%', marginRight: 0 },
        },
      },
    },
  ];

  return [result[0], [result[1][0], returnDay], carInfo, result[3]];
};

const deliveryPlaceInfo = ({ result }: Result) => {
  const rentPlace = [
    {
      key: 'rentPlace',
      label: '배차 장소',
      type: 'address',
      style: { width: '400px' },
      placeholder: '배차 장소를 입력해주세요.',
    },
  ];
  const returnPLace = [
    {
      key: 'returnPlace',
      label: '회차 장소',
      type: 'address',
      placeholder: '회차 장소를 입력해주세요.',
      style: { width: '400px' },
      checkBox: {
        text: '배/회차 장소 동일',
        value: false,
        containerStyle: { width: '200px', marginLeft: '10px' },
      },
    },
  ];

  return [...result, rentPlace, returnPLace];
};

export const registerInfo = ({ isDelivery, rent_type }: RegisterInfoProps) => {
  const returnData = [
    [
      {
        key: 'rentCar',
        label: '대여차량',
        necessary: true,
        input: {
          type: 'text',
          disabled: true,
          placeholder: '차량을 선택해주세요',
          style: { width: '250px' },
        },
        btn: {
          name: '차량선택',
        },
      },
      {
        key: 'rent_type',
        label: '대여종류',
        necessary: true,
        type: 'radio',
        radio: {
          data: [
            {
              value: 'daily',
              label: '일',
              match: 'rentTypeDay',
              default: true,
            },
            {
              value: 'monthly',
              label: '월',
              match: 'rentTypeMonth',
            },
            {
              value: 'long_term',
              label: '장기',
              match: 'rentTypeLong',
            },
            {
              value: 'insurance',
              label: '보험',
              match: 'rentTypeInsu',
            },
          ],
        },
      },
    ], // 1
    [
      {
        key: 'delivered',
        label: '대여일시',
        necessary: true,
        type: 'fullDate',
        calendarOption: 'start',
        longStartOption: rent_type === 'long_term',
      },
      {
        key: 'rentFee',
        label: '대여요금',
        input: {
          type: 'number',
          placeholder: '숫자만 입력해주세요.',
          comma: true,
          style: { width: 300 },
          maxLength: 11,
        },
      },
    ], //2
    [
      {
        key: 'expect_return',
        label: '반납일시',
        necessary: true,
        type: 'fullDate',
        calendarOption: 'end',
        disabled: rent_type === 'long_term',
      },
      {
        key: 'rentPeriod',
        label: '대여기간',
        type: 'output',
      },
    ],
    [
      {
        key: 'isDelivery',
        label: '딜리버리',
        necessary: true,
        type: 'radio',
        radio: {
          data: [
            { value: true, label: '있음', match: 'delivery_use' },
            { value: false, label: '없음', match: 'delivery_unuse', default: true },
          ],
        },
      },
      {
        key: 'employee',
        label: '배차 담당',
        input: {
          type: 'text',
          disabled: true,
          placeholder: '선택해주세요.',
          style: { width: '150px' },
        },
        btn: {
          name: '선택',
        },
      },
    ],
  ];
  let result: Array<Array<ReturnData>> = returnData;

  if (rent_type === 'insurance') {
    // 1 순서 지켜야함
    // TODO: TYPE 체크 필요
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // TODO@kangyongsuek
    result = isRentTypeInsu({ result });
  }

  if (isDelivery) {
    // 2
    result = deliveryPlaceInfo({ result });
  }

  return result;
};

export const driverInfo = () => {
  return [
    [
      {
        key: 'customerName',
        label: '예약 고객명',
        necessary: true,
        input: {
          type: 'text',
          placeholder: '예약 고객명을 입력해주세요.',
          style: { width: '300px' },
        },
        btn: {
          name: '고객 검색',
        },
      },
      {
        key: 'customerContract',
        label: '예약 고객 연락처',
        necessary: true,
        input: {
          type: 'number',
          placeholder: '"-" 없이 숫자만 입력해주세요.',
          style: { width: '300px' },
          maxLength: 11,
        },
        checkBox: {
          text: '알림톡 발송',
          value: false,
          containerStyle: {
            marginRight: '5px',
          },
          tooltip: {
            img: '/images/question/question-gray.png',
            position: 'top',
            message:
              '체크 후 저장 시 입력된 휴대폰번호로 예약완료 알림톡이 발송됩니다. (일반전화 불가)',
            style: { left: '7px', fontSize: '14px', fontWeight: 'normal' },
            triangleStyle: {
              top: '27px',
              left: '182px',
            },
          },
        },
      },
    ], // 1
    [
      {
        key: 'driverName',
        label: '운전자명',
        input: {
          type: 'text',
          placeholder: '실제 운전자명을 입력해주세요.',
          style: { width: '300px' },
        },
        checkBox: {
          text: '예약 고객과 동일',
          value: false,
        },
      },
      {
        key: 'driverContract',
        label: '운전자 연락처',
        input: {
          type: 'number',
          placeholder: '"-" 없이 숫자만 입력해주세요.',
          style: { width: '300px' },
          maxLength: 11,
        },
      },
    ], // 2
  ];
};

export const parterInfo = () => {
  return [
    [
      {
        key: 'partner_info',
        label: '소개처',
        input: {
          type: 'text',
          disabled: true,
          placeholder: '선택해주세요.',
          style: { width: '150px' },
        },
        btn: {
          name: '선택',
        },
      },
    ],
  ];
};

export const etcInfo = () => {
  return [
    [
      {
        key: 'booking_memo',
        label: '예약 메모',
        subLabel: '렌터카 업체 임직원과\n공유 할 수 있습니다.',
        labelStyle: { width: 96 },
        type: 'textarea',
        maxByte: 120,
        placeholder: '메모를 입력해주세요',
        style: { height: 130, width: 465 },
      },
    ],
  ];
};

export const alimtalkInfo = (type: string) => {
  const basic = [
    [{ key: 'customerContract', label: '예약 고객 연락처', type: 'output' }],
    [{ key: 'rent_type', label: '대여 종류', type: 'output' }],
    [{ key: 'carInfo', label: '차량 정보', type: 'output' }],
    [{ key: 'delivered', label: '대여일시', type: 'output' }],
    [{ key: 'expect_return', label: '반납일시', type: 'output' }],
    [{ key: 'delivery', label: '딜리버리', type: 'output' }],
  ];

  return type === '보험' ? basic.filter((arr) => arr[0].key !== 'experent_return') : basic;
};
