import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import TextArea from '../../../../atoms/TextArea';
import { ConfirmModalDefaultType as DefaultType } from '../../../../interface/utils/modalContents';
import ConfirmAlert from '../../../Alert/ConfirmAlert';
import styles from './NoshowReportModal.module.scss';

interface PropsType extends DefaultType {
  isPickup: boolean;
}

interface StatesType {
  value: string;
}

@inject('modalStore')
@observer
class NoshowReportModal extends Component<PropsType, StatesType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      value: '',
    };
  }

  getContentInfo = (isPickup: boolean) => {
    return (
      <div className={styles.no_show_process_info}>
        <article className={styles.no_show_process_info_title}>
          {'고객 노쇼(예약부도) 처리 유의사항 안내'}
        </article>
        <div>
          <article className={styles.article_wrap}>
            <div style={{ fontSize: 42, lineHeight: '24px', paddingRight: 5 }}>&#68340;</div>
            <div>
              <span>{'노쇼 신고 시 고객 예약은 '}</span>
              <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'즉시 취소 '}</span>
              <span>{'처리 되며, 되돌릴 수 없습니다.'}</span>
            </div>
          </article>
          <article className={styles.article_wrap}>
            <div style={{ fontSize: 42, lineHeight: '24px', paddingRight: 5 }}>&#68340;</div>
            {isPickup ? (
              <div>
                <span>{'제주도의 경우 비행기 결항, 지연 등의 상황을 '}</span>
                <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'고객과 꼭 확인 '}</span>
                <span>{'후 고객 노쇼 처리를 진행해주세요.'}</span>
              </div>
            ) : (
              <div>
                <span>{'고객과 통화연결이 되지 않더라도 반드시 '}</span>
                <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'배차장소'}</span>
                <span>{'에서 '}</span>
                <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'20분 이상 대기 '}</span>
                <span>{'해야 합니다.'}</span>
                <span>
                  {
                    ' (* 배차 장소가 공항일 경우 비행기 결항, 지연 등의 상황이 발생할 수 있으니 반드시 고객과 통화 후 처리해주세요.)'
                  }
                </span>
              </div>
            )}
          </article>
          <article className={styles.article_wrap}>
            <div style={{ fontSize: 42, lineHeight: '24px', paddingRight: 5 }}>&#68340;</div>
            <div>
              <span>{'고객 노쇼 처리로 인해 분쟁 발생 시, 고객과 연락한 이력이 없을 경우 '}</span>
              <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'불이익(페널티)'}</span>
              <span>{'이 발생할 수 있습니다.'}</span>
            </div>
          </article>
        </div>
        <div className={styles.warning_box}>
          {'잘못된 노쇼 처리로 인해 불이익(페널티)이 발생할 수 있으니 신중하게 진행해주세요.'}
        </div>
      </div>
    );
  };

  getContent = () => {
    const { value } = this.state;
    const { isPickup } = this.props;

    return (
      <>
        <div className={styles.text}>
          <article style={{ fontSize: 19 }}>고객이 약속 장소에 오시지 않으셨나요?</article>
          <article>자세한 상황을 입력해주세요. (ex. 연락두절)</article>
          <TextArea
            placeholder={'신고 내용을 입력해주세요.'}
            className={styles.text_area}
            maxByte={100}
            keyName={'value'}
            dataObj={this.state}
            value={value}
            setData={(_key: string, value: string) => this.setState({ value })}
          />
          {this.getContentInfo(isPickup)}
        </div>
      </>
    );
  };
  render() {
    const { value } = this.state;
    const cancelBtnStyle = { width: 70, padding: 0 };
    const confirmBtnStyle = { width: 110, padding: 0 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={() => this.props.onClickConfirm(value)}
        confirmButtonText={'고객 노쇼 처리'}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
        confirmDisabled={!(value.length > 1)}
      />
    );
  }
}

export default NoshowReportModal;
