import driver_type from '../driver_type.json';
import { etcInsuranceData, majorInsuranceData } from '../insuranceData';
import use_self_car_insurance_percentage from '../use_self_car_insurance_percentage.json';

const question = '/images/question/question-white.png';

export const basic = [
  // 기본정보
  [
    {
      key: 'delivered',
      label: '배차일시',
      necessary: true,
      type: 'fullDate',
      dataset: 'ins_delivered',
    },
    {
      key: 'rent_request_id',
      label: '렌카콜 연결',
      input: {
        type: 'number',
        disabled: true,
      },
      btn: {
        name: '선택',
        style: { width: 80 },
      },
      addText: '(렌카콜에 대한 계약서 작성시 선택하세요.)',
    },
  ],
];

// ===========================================================//
// ===========================================================//

export const customerCar = [
  // 고객 차량 정보
  [
    {
      key: 'customer_car_number',
      label: '사고 차량 번호',
      necessary: true,
      input: {
        type: 'text',
        placeholder: '예) 12가1234, 서울01가1234',
        disabled: false,
        style: { width: 200 },
      },
      btn: {
        name: '연식조회',
      },
      checkBox: {
        text: '기타차량: 이륜, 외교 외',
        value: false,
        func: () => {},
        containerStyle: { marginLeft: '12px' },
        valueReverseForBackEndData: true, // BackEnd에서 체크를 false, 미체크를 true로 설정하여 설정
      },
    },
    {
      key: 'customer_car_engine',
      label: '배기량',
      input: {
        type: 'number',
        comma: true,
        disabled: false,
      },
      unit: 'CC',
    },
  ],
  [
    {
      key: 'customer_car',
      label: '사고 차종',
      necessary: true,
      input: {
        type: 'text',
        disabled: false,
        style: { width: 200 },
      },
    },
    {
      key: 'customer_car_age',
      label: '연식',
      input: {
        type: 'number',
        disabled: false,
      },
    },
  ],
  [
    {
      key: 'insurance_company',
      label: '보험사',
      type: 'radio',
      radio: {
        data: majorInsuranceData,
        isDivideData: true,
        dividedPoint: majorInsuranceData.length - 1 || '',
        style: { minWidth: '69px', margin: '5px 0px' },
      },
      select: {
        data: etcInsuranceData,
        isInRadioComponent: true,
        style: { width: 180 },
      },
      necessary: true,
      column: 3.6,
    },
    'column',
    [
      {
        key: 'insurance_company',
        label: '접수번호',
        type: 'insuranceType',
        input: {
          type: 'text',
          style: { width: 150 },
        },
        addInput: {
          key: 'registration_id',
          input: {
            type: 'number',
            disabled: false,
            placeholder: '"-" 없이 숫자만 입력해주세요.',
            style: { width: '100%', marginRight: 0 },
          },
        },
        column: 1.2,
      },
      {
        key: 'industrial_company',
        label: '입고 공업사',
        input: {
          type: 'text',
          disabled: false,
          style: { width: 306 },
        },
        column: 1.2,
      },
      {
        key: 'industrial_company_tel',
        label: '공업사 연락처',
        input: {
          type: 'number',
          placeholder: '“-“ 없이 숫자만 입력해주세요.',
          disabled: false,
          style: { width: 306 },
        },
        column: 1.2,
      },
    ],
  ],
];

// ===========================================================//
// ===========================================================//

export const driverInfo = [
  // 운전자 정보
  [
    {
      key: 'customer_name',
      label: '운전자명',
      necessary: true,
      input: {
        type: 'text',
        disabled: false,
        pattern: /^[ㄱ-ㅎ|가-힣a-zA-Z\s*]{1,20}$/,
      },
      btn: {
        name: '운전자 검색',
      },
    },
    {
      key: 'is_corporation',
      label: '고객구분',
      necessary: true,
      type: 'radio',
      radio: {
        data: [
          { value: false, label: '개인', match: 'personal', default: true },
          { value: true, label: '법인', match: 'corporation' },
        ],
        style: { minWidth: '69px', margin: '5px 0px' },
      },
    },
  ],
  [
    {
      key: 'customer_id_number',
      label: '생년월일',
      necessary: true,
      type: 'double',
      input: {
        type: 'number',
        placeholder: '880101-1234567',
        style: { width: 150 },
        maxLength: [6, 7],
        disabled: false,
      },
      tooltip: {
        img: question,
        message: '개인정보보호법에 의하여 반납완료 30일 후 고객 주요 정보 일부가 삭제됩니다.',
        style: { marginLeft: '95px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
      },
    },
    {
      key: 'customer_contact',
      label: '연락처',
      necessary: true,
      input: {
        type: 'number',
        placeholder: '"-"없이 숫자만 입력해주세요.',
        maxLength: 11,
        style: { width: 200 },
      },
    },
  ],
  [
    {
      key: 'customer_driver_license',
      label: '면허번호',
      input: {
        type: 'text',
        placeholder: '숫자 또는 지역포함 숫자를 입력해주세요',
        style: { width: 319 },
        disabled: false,
      },
    },
    {
      key: 'customer_address',
      label: '주소',
      necessary: true,
      type: 'address',
    },
  ],
  [
    {
      key: 'customer_driver_type',
      label: '면허구분',
      type: 'select',
      select: {
        data: driver_type,
        arrow: true,
        listView: 'down', // default
        style: { width: 200 },
        disabled: false,
      },
      btn: {
        name: '면허 진위 조회',
      },
    },
    {
      key: 'customer_license_limit',
      label: '만기일',
      input: {
        type: 'number',
        placeholder: '예) 20201231',
      },
    },
  ],
];

// ===========================================================//
// ===========================================================//

export const secondDriverInfo = [
  // 제 2운전자 정보
  [
    {
      key: 'second_driver_name',
      label: '운전자명',
      necessary: true,
      input: {
        type: 'text',
        disabled: false,
        pattern: /^[ㄱ-ㅎ|가-힣a-zA-Z\s*]{1,20}$/,
      },
      btn: {
        name: '운전자 검색',
      },
    },
    {
      key: 'second_driver_contact',
      label: '연락처',
      necessary: true,
      input: {
        type: 'number',
        placeholder: '"-"없이 숫자만 입력해주세요.',
        maxLength: 11,
        style: { width: 200 },
      },
    },
  ],
  [
    {
      key: 'second_driver_id_number',
      label: '생년월일',
      necessary: true,
      type: 'double',
      input: {
        type: 'number',
        placeholder: '880101-1234567',
        style: { width: 150 },
        maxLength: [6, 7],
        disabled: false,
      },
      tooltip: {
        img: question,
        message: '개인정보보호법에 의하여 반납완료 30일 후 고객 주요 정보 일부가 삭제됩니다.',
        style: { marginLeft: '95px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
      },
    },
    {
      key: 'second_driver_address',
      label: '주소',
      necessary: true,
      type: 'address',
    },
  ],
  [
    {
      key: 'second_driver_license',
      label: '면허번호',
      input: {
        type: 'text',
        placeholder: '숫자 또는 지역포함 숫자를 입력해주세요',
        style: { width: 319 },
        disabled: false,
      },
    },
    {
      key: 'second_driver_license_limit',
      label: '만기일',
      input: {
        type: 'number',
        placeholder: '예) 20201231',
      },
    },
  ],
  [
    {
      key: 'second_driver_type',
      label: '면허구분',
      type: 'select',
      select: {
        data: driver_type,
        arrow: true,
        listView: 'down', // default
        style: { width: 200 },
        disabled: false,
      },
      btn: {
        name: '면허 진위 조회',
      },
    },
  ],
];

// ===========================================================//
// ===========================================================//

export const corporationInfo = [
  // 법인정보
  [
    {
      key: 'corporation_name',
      label: '법인명',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
      },
    },
    {
      key: 'corporation_business_number',
      label: '사업자 번호',
      input: {
        type: 'number',
        style: { width: 150 },
        maxLength: 10,
      },
    },
  ],
  [
    {
      key: 'corporation_number',
      label: '법인 번호',
      type: 'double',
      input: {
        type: 'number',
        maxLength: [6, 7],
        style: { width: 150 },
      },
    },
    {
      key: 'corporation_address',
      label: '법인 주소',
      type: 'address',
    },
  ],
  [
    {
      key: 'corporation_manager_name',
      label: '담당자',
      input: {
        type: 'text',
        style: { width: 150 },
      },
    },
    {
      key: 'corporation_manager_contact',
      label: '담당자 연락처',
      input: {
        type: 'number',
        placeholder: '"-"없이 숫자만 입력해주세요.',
        maxLength: 11,
        style: { width: 200 },
      },
    },
  ],
];

// ===========================================================//
// ===========================================================//

export const customerRentInfo = [
  // 렌트 정보
  [
    {
      key: 'rent_car_name',
      label: '렌트 차종',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
        placeholder: '차량을 선택해주세요.',
      },
      btn: {
        name: '차량선택',
      },
    },
    {
      key: 'rent_car_number',
      label: '렌트 차량 번호',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
      },
    },
  ],
  [
    {
      key: 'oil_type',
      label: '유종',
      necessary: true,
      input: {
        type: 'text',
        disabled: true,
        style: { width: 150 },
      },
    },
    {
      key: 'gas_charge',
      label: '유류량',
      necessary: true,
      unit: '%',
      parser: (value) => {
        if (value >= 1 && value <= 999) return value;

        return '';
      },
      input: {
        type: 'number',
        placeholder: '1~100 사이의 숫자 입력',
        style: { width: 212 },
      },
    },
  ],
  [
    {
      key: 'driven_distance',
      label: '배차시 km',
      input: {
        comma: true,
        type: 'number',
        placeholder: '예) 1000',
        style: { width: 150 },
      },
    },
    {
      key: 'dropoff_address',
      label: '회차장소',
      type: 'address',
      placeholder: '회차장소를 입력해주세요.',
    },
  ],
];

export const additionalCustomerRentInfo = [
  // 교체계약 렌트 정보
  [
    {
      key: 'rent_car_name',
      label: '렌트 차종',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
        placeholder: '차량을 선택해주세요.',
      },
      btn: {
        name: '차량선택',
      },
    },
    {
      key: 'rent_car_number',
      label: '렌트 차량 번호',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
      },
    },
  ],
  [
    {
      key: 'oil_type',
      label: '유종',
      necessary: true,
      input: {
        type: 'text',
        disabled: true,
        style: { width: 150 },
      },
    },
    {
      key: 'gas_charge',
      label: '유류량',
      necessary: true,
      unit: '%',
      parser: (value) => {
        if (value >= 1 && value <= 999) return value;

        return '';
      },
      input: {
        type: 'number',
        placeholder: '1~100 사이의 숫자 입력',
        style: { width: 212 },
      },
    },
  ],
  [
    {
      key: 'driven_distance',
      label: '배차시 km',
      input: {
        comma: true,
        type: 'number',
        placeholder: '예) 1000',
        style: { width: 150 },
      },
    },
  ],
];

export const connectCustomerRentInfo = [
  // 렌트 정보
  [
    {
      key: 'rent_car_name',
      label: '렌트 차종',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
        placeholder: '차량을 선택해주세요.',
      },
      btn: {
        name: '차량선택',
      },
    },
    {
      key: 'rent_car_number',
      label: '렌트 차량 번호',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
      },
    },
  ],
  [
    {
      key: 'oil_type',
      label: '유종',
      necessary: true,
      input: {
        type: 'text',
        disabled: true,
        style: { width: 150 },
      },
    },
    {
      key: 'gas_charge',
      label: '유류량',
      necessary: true,
      unit: '%',
      parser: (value) => {
        if (value >= 1 && value <= 999) return value;

        return '';
      },
      input: {
        type: 'number',
        placeholder: '1~100 사이의 숫자 입력',
        style: { width: 212 },
      },
      connectFuel: true,
    },
  ],
  [
    {
      key: 'driven_distance',
      label: '배차시 km',
      input: {
        comma: true,
        type: 'number',
        placeholder: '예) 1000',
        style: { width: 150 },
      },
      connectDistance: true,
    },
    {
      key: 'dropoff_address',
      label: '회차장소',
      type: 'address',
      placeholder: '회차장소를 입력해주세요.',
    },
  ],
];

export const additionalConnectCustomerRentInfo = [
  // 교체계약 렌트 정보
  [
    {
      key: 'rent_car_name',
      label: '렌트 차종',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
        placeholder: '차량을 선택해주세요.',
      },
      btn: {
        name: '차량선택',
      },
    },
    {
      key: 'rent_car_number',
      label: '렌트 차량 번호',
      necessary: true,
      input: {
        type: 'text',
        style: { width: 150 },
        disabled: true,
      },
    },
  ],
  [
    {
      key: 'oil_type',
      label: '유종',
      necessary: true,
      input: {
        type: 'text',
        disabled: true,
        style: { width: 150 },
      },
    },
    {
      key: 'gas_charge',
      label: '유류량',
      necessary: true,
      unit: '%',
      parser: (value) => {
        if (value >= 1 && value <= 999) return value;

        return '';
      },
      input: {
        type: 'number',
        placeholder: '1~100 사이의 숫자 입력',
        style: { width: 212 },
      },
      connectFuel: true,
    },
  ],
  [
    {
      key: 'driven_distance',
      label: '배차시 km',
      input: {
        comma: true,
        type: 'number',
        placeholder: '예) 1000',
        style: { width: 150 },
      },
      connectDistance: true,
    },
  ],
];

// ===========================================================//
// ===========================================================//

export const insuranceInfo = [
  // 보험정보
  [
    {
      key: 'insurance_age',
      label: '보험 가입 연령',
      necessary: true,
      type: 'radio',
      radio: {
        data: [
          { value: '21', label: '만 21세 이상', match: 'age_21' },
          { value: '26', label: '만 26세 이상', match: 'age_26', default: true },
        ],
        style: { minWidth: '69px', margin: '5px 0px' },
      },
    },
    {
      key: 'self_car_insurance',
      label: '자차보험',
      necessary: true,
      type: 'radio',
      radio: {
        data: [
          {
            value: true,
            label: '가입',
            match: 'insurance_on',
            default: true,
          },
          {
            value: false,
            label: '미가입',
            match: 'insurance_off',
          },
        ],
      },
    },
  ],
  [
    {
      // 1221 새로 추가되는 필드
      key: 'self_car_insurance_limit',
      label: '자차 한도',
      necessary: true,
      type: 'radio',
      radio: {
        data: [
          { value: 1000, label: '1천만원', match: 'self_limit_10' },
          { value: 2000, label: '2천만원', match: 'self_limit_20' },
          { value: 3000, label: '3천만원', match: 'self_limit_30', default: true },
        ],
      },
      btn: {
        name: '직접입력',
      },
      select: {
        data: use_self_car_insurance_percentage,
        arrow: true,
        listView: 'down', // default
        style: { width: 200, display: 'none' },
        disabled: false,
      },
      checkBox: {
        text: '비율(차량가액 기준)',
        func: () => {},
        containerStyle: { marginLeft: '12px' },
      },
    },
    {
      key: 'indemnfication_fee',
      label: '자차 면책금',
      necessary: true,
      type: 'radio',
      radio: {
        data: [
          { value: 30, label: '30만원', match: 'disclaim_30' },
          { value: 50, label: '50만원', match: 'disclaim_50', default: true },
          { value: 100, label: '100만원', match: 'disclaim_100' },
        ],
      },
      btn: {
        name: '직접입력',
      },
    },
  ],
  [
    {
      key: 'insurance_person_limit',
      label: '대인 한도',
      necessary: true,
      type: 'radio',
      radio: {
        data: [{ value: -1, label: '무한', match: 'limit_all', default: true }],
      },
      btn: {
        name: '직접입력',
      },
    },
    {
      key: 'people_indemnfication_fee',
      label: '대인 면책금(인당)',
      type: 'radio',
      radio: {
        data: [
          { value: 0, label: '없음', match: 'others_0' },
          { value: 30, label: '30만원', match: 'others_30' },
          { value: 50, label: '50만원', match: 'others_50' },
        ],
      },
      btn: {
        name: '직접입력',
      },
    },
  ],
  [
    {
      key: 'insurance_object_limit',
      label: '대물 한도',
      necessary: true,
      type: 'radio',
      radio: {
        data: [
          { value: 3000, label: '3천만원', match: 'limit_3000' },
          { value: 5000, label: '5천만원', match: 'limit_5000' },
          { value: 10000, label: '1억', match: 'limit_10000', default: true },
        ],
      },
      btn: {
        name: '직접입력',
      },
    },
    {
      key: 'object_indemnfication_fee',
      label: '대물 면책금(건당)',
      type: 'radio',
      radio: {
        data: [
          { value: 0, label: '없음', match: 'daemul_0' },
          { value: 30, label: '30만원', match: 'daemul_30' },
          { value: 50, label: '50만원', match: 'daemul_50' },
        ],
      },
      btn: {
        name: '직접입력',
      },
    },
  ],
  [
    {
      key: 'insurance_self_limit',
      label: '자손 한도',
      necessary: true,
      type: 'radio',
      radio: {
        data: [{ value: 1500, label: '1,500만원', match: 'limit_1500', default: true }],
      },
      btn: {
        name: '직접입력',
      },
    },
    {
      key: 'self_indemnification_fee',
      label: '자손 면책금',
      type: 'radio',
      radio: {
        data: [
          { value: 0, label: '없음', match: 'my_0' },
          { value: 30, label: '30만원', match: 'my_30' },
          { value: 50, label: '50만원', match: 'my_50' },
        ],
      },
      btn: {
        name: '직접입력',
      },
    },
  ],
];

// ===========================================================//
// ===========================================================//

export const etcInfo = [
  // 기타정보
  [
    {
      key: 'partner_info',
      label: '소개처',
      input: {
        type: 'text',
        disabled: true,
        placeholder: '선택해주세요.',
        style: { width: '150px' },
      },
      btn: {
        name: '선택',
      },
    },
    {
      key: 'sales_employee',
      label: '영업 직원',
      input: {
        type: 'text',
        disabled: true,
        placeholder: '선택해주세요.',
        style: { width: '150px' },
      },
      btn: {
        name: '선택',
      },
    },
  ],
  [
    {
      key: 'memo',
      label: '특이사항',
      subLabel: '렌터카 업체 임직원의\n계약서 메모에 공유됩니다.',
      type: 'textarea',
      maxByte: 120,
      placeholder: '예) 고객 성향 까칠함.',
      style: { height: 130 },
    },
    {
      key: 'customer_memo',
      label: '기타 계약사항',
      type: 'textarea',
      subLabel: '고객 계약서에 전달됩니다.',
      placeholder: '예) 계약자 외 운행 금지.',
      maxByte: 400,
      btn: '불러오기',
      btnFunc: () => {},
      tooltip: {
        img: question,
        message:
          '등록된 기타 계약사항 관리는<b>사업자 관리 > 사업자\n설정 > 기타 계약사항 목록</b>에서 하실 수 있습니다.',
        style: { marginLeft: '35px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
      },
      btnStyle: { minWidth: '80px', marginTop: '105px' },
      style: { height: 130 },
    },
  ],
  [
    {
      key: 'insurance_memo',
      label: '보험사 전달 사항',
      subLabel: '청구 시 보험사에 전달됩니다.',
      type: 'textarea',
      maxByte: 120,
      placeholder: '예) 렌카에서 50% 할인된 청구 금액입니다.',
      style: { height: 130 },
    },
  ],
];

export const fileUpdateInfo = {
  // 첨부파일
  key: 'attachments',
  label: '첨부파일',
  option: 'file',
  multi: true,
  outside: true,
  accept: 'image/*, application/pdf',
  placeholder:
    'PDF, JPG, JPEG, PNG, BMP, GIF 파일로 업로드 해주세요.\n(최대 10MB, 5개까지 첨부 가능)',
  limit: 10,
};

// ===========================================================//
// ===========================================================//

export const completeContractModal = [
  [
    {
      key: 'rent_car_number',
      label: '렌트 차량 번호',
      type: 'output',
    },
  ],
  [
    {
      key: 'delivered',
      label: '배차일시',
      type: 'output',
    },
  ],
];
