import { NextRouter, withRouter } from 'next/router';
import React, { ErrorInfo } from 'react';

import MobileNotFound from '../NotFound/index.jsx';

interface Props {
  router: NextRouter;
  children: React.ReactNode;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error: Error): Pick<ErrorBoundaryState, 'hasError' | 'error'> {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({ error, errorInfo });
  }

  resetError = async (): Promise<void> => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
    });

    this.props.router.back();
  };

  render() {
    if (this.state.hasError) {
      return <MobileNotFound goBack={this.resetError} />;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
