import { ConsignAvailableTimes } from 'api/consign/getConsignAvailableTimesApi';
import { ConsignTime } from 'domainComponents/consign/request/request.type';
import { makeAutoObservable } from 'mobx';

import { _setObject, BaseStore } from './BaseStore.type';

class ConsignStore implements BaseStore<ConsignStore> {
  // 출발지 정보
  departureAddressName = ''; // 출발지 별칭
  departureAddress = ''; // 출발지 주소 (도로명)
  departureAddressDetail = ''; // 출발지 주소 상세
  departureJibun = ''; // 출발지 주소 (지번)
  departureContact = ''; // 출발지 연락처
  addDepartureLocation = false; // 출발지 즐겨찾기 추가 여부
  departureLat = null;
  departureLng = null;

  // 경유지 정보
  isActiveStopOver = false;
  stopOverAddressName = ''; // 경유지 별칭
  stopOverAddress = ''; // 경유지 주소 (도로명)
  stopOverAddressDetail = ''; // 경유지 주소 상세
  stopOverJibun = ''; // 경유지 주소 (지번)
  stopOverContact = ''; // 경유지 연락처
  addStopOverLocation = false; // 경유지 즐겨찾기 추가 여부
  stopOverLat = null;
  stopOverLng = null;

  // 도착지 정보
  destinationAddressName = ''; // 도착지 별칭
  destinationAddress = ''; // 도착지 주소 (도로명)
  destinationAddressDetail = ''; // 도착지 주소 상세
  destinationJibun = ''; // 도착지 주소 (지번)
  destinationContact = ''; // 도착지 연락처
  addDestinationLocation = false; // 도착지 즐겨찾기 추가 여부
  destinationLat = null;
  destinationLng = null;

  // 상세정보
  reservedAt = ''; // 예약 날짜 및 시간
  carNumber = ''; // 선택 차량 번호
  carModel = ''; // 선택 차량 모델
  stopOverCarNumber = ''; // 경유지 선택 차량 번호
  stopOverCarModel = ''; // 경유지 선택 차량 모델
  optionCheckList: number[] = []; // 특이사항 체크 리스트
  requestConsignMemo = ''; // 상세 요청사항
  defaultPrice = 0; // 예상 가격
  expectId = ''; // 예상 가격 조회 id

  isChargeRecalculate = false; // 요금 재조회

  consingAvailableTimesResponse: ConsignAvailableTimes = null; // 탁송 요청가능한 시간 데이터
  consignTime: ConsignTime = 'now';
  isDisabledNow = false;

  constructor() {
    makeAutoObservable(this);
  }

  setObject = (data) => _setObject(this, data);

  init = (): void => {
    this.departureAddressName = '';
    this.departureAddress = '';
    this.departureAddressDetail = '';
    this.departureContact = '';
    this.stopOverAddressName = '';
    this.stopOverAddress = '';
    this.stopOverContact = '';
    this.destinationAddressName = '';
    this.destinationAddress = '';
    this.destinationAddressDetail = '';
    this.destinationContact = '';
    this.departureJibun = '';
    this.addDepartureLocation = false;
    this.isActiveStopOver = false;
    this.stopOverAddressDetail = '';
    this.stopOverJibun = '';
    this.addStopOverLocation = false;
    this.destinationJibun = '';
    this.addDestinationLocation = false;
    this.reservedAt = '';
    this.carNumber = '';
    this.carModel = '';
    this.stopOverCarNumber = '';
    this.stopOverCarModel = '';
    this.optionCheckList = [];
    this.requestConsignMemo = '';
    this.defaultPrice = 0;
    this.expectId = '';
    this.isChargeRecalculate = false;
    this.departureLat = null;
    this.departureLng = null;
    this.stopOverLat = null;
    this.stopOverLng = null;
    this.destinationLat = null;
    this.destinationLng = null;
    this.consingAvailableTimesResponse = null;
    this.consignTime = 'now';
    this.isDisabledNow = false;
  };

  setValue = (data: Partial<ConsignStore>): this => {
    Object.keys(data).forEach((key) => (this[key] = data[key]));

    return this;
  };
}

export default ConsignStore;
