/**
 * @brief 일반렌트 요청(상세) 관련 유틸리티 함수 구현
 * @author wontae Kim
 */

/* eslint-disable no-dupe-else-if */
import dayjs from 'dayjs';
import {
  CancelledStateUIInfoType,
  NoPermissionUIInfoType,
  SelectedCarInfoType,
} from 'interface/partner';
import React, { Fragment } from 'react';

import BookedCarChangeModal from '../components/ModalContents/BookedCarChangeModal';
import ConfirmChangeTime from '../components/ModalContents/ConfirmChangeTimeModal';
import ConfirmReturnModal from '../components/ModalContents/ConfirmReturnModal';
import DispatchDepartureModal from '../components/ModalContents/DispatchDepartureModal';
import DispatchGiveUpModal from '../components/ModalContents/DispatchGiveUpModal';
import DispatchReconfirmModal from '../components/ModalContents/DispatchReconfirmModal';
import FailDispatchCarModal from '../components/ModalContents/FailDispatchCarModal';
import NoshowNotAllowModal from '../components/ModalContents/NormalRequestNoShowModals/NoshowNotAllowModal';
import NoshowPreCheckModal from '../components/ModalContents/NormalRequestNoShowModals/NoshowPreCheckModal';
import NoshowReportModal from '../components/ModalContents/NormalRequestNoShowModals/NoshowReportModal';
import ResponseConfirmModal from '../components/ModalContents/ResponseConfirmModal';
import ReturnCarReconfirmModal from '../components/ModalContents/ReturnCarReconfirmModal';
import SelectCarModal from '../components/ModalContents/SelectCarModal';
import TableConfirmModal from '../components/ModalContents/TableConfirmModal';
import WebContractGuideModal from '../components/ModalContents/WebContractGuide';
import { carPriceGroupDescriptions, carPriceGroupNames } from '../constants/normalRequestData';
import { CarInfoConfirmPropsType as ResponseConfirmModalProps } from '../interface/utils/modalContents';
import { ServerRequestDetail } from './InitFetchFunctions/partner/types';

export default {
  basicInfoDateParser,
  diffDayHourMinute,
  setResponseData,
  getRequestState,
  carPriceGroupParser,
  proposalNoPermissionMsg,
  canceledMsg,
  checkCustomerAge,
  getParsedPeriod,
  openSelectCarModal,
  openResponseConfirmModal,
  openBookedCarChangeModal,
  openBookedCarChangeModalConfirm,
  openDispatchGiveUpModal,
  openSendSelfContractModal,
  openDispatchDepartureModal,
  openDispatchReconfirmModal,
  openWebContractGuideModal,
  openReSendSignatureLinkModal,
  openNoshowProcessModal,
  openNoshowReportModal,
  openConfirmReturnCarModal,
  openConfirmChangeTimeModal,
  openReconfirmReturnCarModal,
  openFailDispatchCarModal,
};

interface CarPriceGroupItemType {
  id: number;
  price: number;
}
interface CarPriceGroupDescriptionsType {
  id: number;
  desc: string;
  example: string[];
}
interface CarPriceGroupNamesType {
  key: number[];
  value: string;
}
export type SelfContractSendStateType = ServerRequestDetail['self_contract_info_contact'];
export interface DiffDayHourMinuteType {
  month: number;
  day: number;
  hour: number;
  minute: number;
  originDiff: number;
}
export interface OpenSelectCarModalPropsType {
  submitFunc: Function;
  modalStore: any;
  check: {
    isResponseState: boolean;
    isSelectedCar: boolean;
  };
  data: any;
}

async function changeToNextModal(closeCurrentModal: Function, openNextModal: Function) {
  await new Promise((resolve) => resolve(closeCurrentModal()));
  openNextModal();
}

/**
 * @brief responseData 데이터 가공
 * @author wontae Kim
 * @param data responseData.data (전체)
 * @param store authStore,
 * @returns
 */
function setResponseData(data: any, store: any) {
  // 상태 구분 명칭
  const requestState = getRequestState({
    state: data.state,
    canResponse: data.can_response,
    isSelectedCarExist: Boolean(data.response_car),
    isSelected: data.is_selected,
    isGiveUp: data.give_up,
    paidCost: data.paid_cost,
  });

  // 제안 제한 정보 - 차량 선택 후 예약확정 되기'전' 일때 유효함
  const noPermissionMsgObj = proposalNoPermissionMsg({
    canResponse: data.can_response,
    isSelectedCar: Boolean(data.response_car),
    hasRemoved: Boolean(data?.removed_at),
    isGiveup: Boolean(data?.give_up),
    responseCount: data.responses_count,
    requestState: data.state,
    permission: store?.authStore?.permission,
    is_timeout: data?.is_timeout,
  });

  // 요청 취소 정보 - 예약확정 이후
  const canceledMsgObj = canceledMsg(
    {
      // 업체포기
      giveUpByCompany: Boolean(data?.give_up),
      // 고객 예약 취소 ( 노쇼처리가 아니면서 취소 되었을 경우 )
      canceledCustomer:
        requestState === 'cancelled' && !(data?.noshow_reason && data?.noshow_report_at),
      // 노쇼 처리 후 취소
      noShow: requestState === 'cancelled' && data?.noshow_reason && data?.noshow_report_at,
    },
    data
  );

  // 총 대여 금액
  const selectedCarPrice = data.paid_cost || data?.response_car?.price;

  // 선택되어 있는 차량 정보
  const carAge = dayjs(data.response_car?.car_age);
  const carAgeText = carAge.isValid() ? carAge.format('YY.MM') : '확인 필요';
  const defaultCarInfo: SelectedCarInfoType = data.response_car
    ? data.version > 1
      ? {
          id: data.response_car.id,
          carNumber: data.response_car.car_identity,
          carName: data.response_car.car_name,
          model_year: data.response_car.model_year,
          carAge: carAgeText,
          oil_type: data.response_car.oil_type,
          engineDisplacement: data.response_car.engine_displacement,
          carGroupName: data.response_car_group?.name,
          submodel: data.response_car_group?.submodel,
          price: selectedCarPrice,
        }
      : {
          id: data.response_car.id,
          carNumber: data.response_car.car_identity,
          carName: data.response_car.car_name,
          model_year: data.response_car.model_year,
          carAge: carAgeText,
          oilInfo: {
            oil: data.response_car.oil_type,
            quantity: data.response_car.engine_displacement,
          },
          price: selectedCarPrice,
        }
    : null;

  // 차량 가격 리스트 타입 필터링한 정보
  const carGroupInfo = carPriceGroupParser(data.external_request_cars);

  // 연장 시간
  const diffExtendRentTime = dayjs(data.updated_dropoff_at).diff(data.dropoff_at, 'minute');

  return {
    ...data,
    requestState,
    carGroupInfo,
    diffExtendRentTime,
    noPermissionMsg: noPermissionMsgObj,
    canceledMsg: canceledMsgObj,
    carInfo: defaultCarInfo,
    selectCar: null,
    isAllowPermission: store?.authStore?.permission !== 0,
  };
}

/**
 * @brief 대여/반납 일시정보 파싱
 * @author wontae Kim
 * @param {String} origin ex) 2021-05-11 10:32:98
 * @returns {String} parsed string
 */
function basicInfoDateParser(origin: string) {
  const separateDate = origin?.split(' ');
  const dateArr = separateDate[0].split('-');
  const timeArr = separateDate[1].split(':');

  const week = ['일', '월', '화', '수', '목', '금', '토'];

  return `${dateArr[1]}/${dateArr[2]}/${week[new Date(separateDate[0]).getDay()]} ${timeArr[0]}:${
    timeArr[1]
  }`;
}

function diffDayHourMinute(endDate: string, startDate: string): DiffDayHourMinuteType {
  const diffMonth = dayjs(endDate).diff(startDate, 'month');
  const diff = dayjs(endDate).diff(startDate, 'minute');
  const diffMinute = diff > 0 ? diff % 60 : (diff % 60) * -1; // 시간 s단위 환산한 '나머지''분'
  const diffHour = Math.floor(diff > 0 ? diff / 60 : (diff / 60) * -1); // 60분 단위로 환산한 '시간'
  const diffDay = Math.floor(diffHour / 24); // 시간을 환산한 '일'

  const outputHour = diffHour % 24; // 24 시간을 넘어가면 1일이 되므로 따로 나머지로 반환

  return { month: diffMonth, day: diffDay, hour: outputHour, minute: diffMinute, originDiff: diff };
}

/**
 * @brief 일반렌트 조건별 상세 상태
 * (기존 waiting_response 상태에서 일부 조건에 따른 구분 작업, 관계없는 예약확정 부터는 그대로 사용)
 * @author wontae Kim
 * @param {Object}
 * @returns {String} state
 */
function getRequestState({
  state,
  canResponse,
  isSelectedCarExist,
  isSelected,
  isGiveUp,
  paidCost,
}: {
  state: string;
  canResponse: boolean;
  isSelectedCarExist: boolean;
  isSelected: boolean;
  isGiveUp: boolean;
  paidCost: number | null;
}): string {
  if (isGiveUp) {
    return 'cancelled';
  } // 절대조건 - give_up == true 라면 취소상태로 인식하는것이 맞다.

  if (state === 'waiting_response' && canResponse === true && paidCost !== null) {
    return 're_request'; // 배차포기 후 재요청된 건
  }

  if (state === 'waiting_response' && canResponse === true) {
    return 'waiting_select'; // 차량선택 전
  }

  if (state === 'waiting_response' && isSelectedCarExist === true) {
    return 'waiting_response'; // 제안 중
  }

  if (state === 'waiting_car' && isSelected === true) {
    return 'waiting_car'; // 예약확정
  }

  // using_car - 배차중
  // all_done - 반납완료
  // cancelled - 취소
  return state;
}

/**
 * @author wontae Kim
 * @param {Array<CarPriceGroupItemType>} arr
 * @returns {Object}
 */
function carPriceGroupParser(arr: Array<CarPriceGroupItemType>) {
  if (arr?.length <= 0) {
    return null;
  }

  arr.sort((a: CarPriceGroupItemType, b: CarPriceGroupItemType) => a.id - b.id);

  const begin = arr[0].id - 1;
  const end = arr.length > 1 ? arr[arr.length - 1].id : arr[0].id;

  const groupList = carPriceGroupDescriptions.slice(begin, end);

  // canProposals - 제안 가능 차량 예시 텍스트 (ex: G70, 스팅어 외)
  const canProposals = `${groupList
    .reduce((acc: CarPriceGroupDescriptionsType, cur: CarPriceGroupDescriptionsType) => {
      return { id: -1, desc: '', example: acc.example.concat(cur.example) };
    })
    .example.join(', ')} 외`;

  const groupIdArr: number[] = arr.map((item: CarPriceGroupItemType) => item.id);

  // maxRentPrice - 업그레이드 차량 제안시 대여 금액
  const maxRentPrice = arr.reduce((acc: CarPriceGroupItemType, cur: CarPriceGroupItemType) => {
    return { id: -1, price: Math.max(acc.price, cur.price) };
  }).price;

  const requestCarGroupNameFilter = carPriceGroupNames.filter((item: CarPriceGroupNamesType) => {
    if (groupIdArr.join('') === item.key.join('')) {
      return true;
    }
  });

  // requestCarGroupName - 요청 차량 그룹 이름
  const requestCarGroupName =
    requestCarGroupNameFilter?.length > 0 ? requestCarGroupNameFilter[0].value : '';

  // priceList - 대여 금액 리스트
  const priceList = groupList.map((item: CarPriceGroupDescriptionsType, i: number) => {
    return { desc: `${item.desc}(${item.example.join(', ')})`, price: arr[i].price };
  });

  // gradeList - 그룹명 리스트 (차량 모달 버튼 탭 사용)
  const gradeList = groupList.map((item: CarPriceGroupDescriptionsType) => {
    return { id: item.id, desc: item.desc };
  });

  return {
    gradeList,
    priceList,
    canProposals,
    maxRentPrice,
    requestCarGroupName,
  };
}

/**
 * @brief 제안 제한 여부 검사 및 메시지 반환
 * "cancelled" state 상태에서는 UI 디자인이 달라지므로 별개의 함수로 처리함
 * @param check 제안 권한이 있는지 검사 값
 * @returns {NoPermissionUIInfoType} 제안 제한시 메시지 및 이모지
 */
function proposalNoPermissionMsg(check: {
  canResponse: boolean; // 제안 가능 여부
  isSelectedCar: boolean; // 선택된 차량 존재 여부 제안 했는지 안했는지여부
  isGiveup: boolean; // 해당 건이 배차포기 건 인지
  hasRemoved: boolean; // 현재 접속한 업체가 포기했는지
  responseCount: number; // 요청 진행 수
  requestState: string; // 서버측 전송 상태 구분 값
  permission: number; // 권한 레벨
  is_timeout: boolean; // 제한시간 만료
}): NoPermissionUIInfoType | null {
  const {
    canResponse,
    responseCount,
    requestState,
    isSelectedCar,
    isGiveup,
    hasRemoved,
    permission,
    is_timeout,
  } = check;
  const emojiSrc = (name: string) => `/images/emoji/ic-emoji-${name}@3x.png`;

  // 배차 포기건 일 경우 '최우선'으로 배차포기 메시지 출력 하기 위해 리턴
  if (isGiveup) {
    return null;
  }

  switch (requestState) {
    case 'waiting_response':
      if (!canResponse) {
        // 제안 불가능 할 때
        if (!isSelectedCar && is_timeout) {
          return {
            emojiSrc: '/images/emoji/ic-emoji-gesturingno@3x.png',
            msg: '제안 가능 시간이 만료된 건입니다.',
          };
        }

        if (!isSelectedCar && responseCount > 9) {
          return {
            emojiSrc: emojiSrc('gesturingno-1'),
            msg: '이미 10개의 제안이 등록되어 더 이상 제안할 수 없습니다.',
          };
        }

        if (!isSelectedCar && permission === 0) {
          return { emojiSrc: emojiSrc('downcast'), msg: '일반 직원은 제안을 할 수 없습니다.' };
        }
      }

      break;
    case 'waiting_car':
    case 'using_car':
    case 'all_done':
      if (!isSelectedCar) {
        return {
          emojiSrc: emojiSrc('loudlycrying'),
          msg: '해당 요청 건은 이미 업체가 선정되었습니다.',
        };
      }

      break;
    // '취소'건 메시지는 canceledMsg() 에서 생성. 단, 타 업체에서 cancelled 상태의 취소건 열람시(알림 등) 해당 메시지 출력
    case 'cancelled':
      if (!hasRemoved) {
        return { emojiSrc: emojiSrc('gesturingno'), msg: '요청이 취소된 건입니다.' };
      }
    default:
      break;
  }

  return null;
}

function canceledMsg(
  check: {
    giveUpByCompany: boolean;
    canceledCustomer: boolean;
    noShow: boolean;
  },
  data: any
): CancelledStateUIInfoType | null {
  const { giveUpByCompany, canceledCustomer, noShow } = check;
  const emojiSrc = (name: string) => `/images/emoji/ic-emoji-${name}@3x.png`;

  if (data) {
    if (giveUpByCompany) {
      // inventory완료
      if (data.version > 1) {
        return {
          label: {
            date: '배차 포기 일시',
          },
          value: {
            date: data.give_up_at,
          },
          emoji: emojiSrc('downcast'),
          msg: '본 예약건은 배차 포기 건 입니다.',
          showMap: false,
        };
      }

      return {
        label: {
          date: '배차 포기 일시',
          penalty: '배차 포기 페널티',
        },
        value: {
          date: data.give_up_at,
          penalty: data.is_company_penalty ? '대여 금액 10%' : '없음',
        },
        emoji: emojiSrc('downcast'),
        msg: '본 예약건은 배차 포기 건 입니다.',
        showMap: false,
      };
    }

    if (canceledCustomer && data.is_selected) {
      return {
        label: {
          date: '예약 취소 일시',
          penalty: '고객 예약 취소 페널티',
        },
        value: {
          date: data.removed_at,
          penalty: data.penalty_cost ? '있음' : '없음',
        },
        emoji: emojiSrc('gesturingno'),
        msg: '본 예약건은 고객 예약 취소건 입니다.',
        showMap: false,
      };
    }

    if (canceledCustomer && !data.is_selected) {
      return {
        label: {
          date: '',
          penalty: '',
        },
        value: {
          date: data.removed_at,
          penalty: '',
        },
        emoji: emojiSrc('gesturingno'),
        msg: '요청이 취소된 건입니다.',
        showMap: false,
      };
    }

    if (noShow) {
      return {
        label: {
          date: '노쇼 처리 일시',
          reason: '노쇼 처리 내용',
          penalty: '고객 노쇼 페널티',
        },
        value: {
          date: data.noshow_report_at,
          reason: data.noshow_reason,
          penalty: '있음',
        },
        emoji: emojiSrc('gesturingno'),
        msg: '본 예약건은 고객 노쇼 취소건 입니다.',
        showMap: true,
      };
    }
  }

  return null;
}

function checkCustomerAge(age: number) {
  if (age >= 26) {
    return '만 26세 이상';
  }

  if (age >= 21) {
    return '만 21세 이상';
  }

  return `만 ${age}세`;
}

function getParsedPeriod(diff: DiffDayHourMinuteType) {
  return {
    toString() {
      return `
        ${diff.day > 0 ? `${diff.day}일` : ''}
        ${diff.hour > 0 || (diff.day > 0 && diff.minute > 0) ? `${diff.hour}시간` : ''}
        ${diff.minute > 0 ? `${diff.minute}분` : ''}
      `.trim();
    },
    toRender() {
      return (
        <Fragment>
          {diff.day > 0 && <span>{`${diff.day}일 `}</span>}
          {(diff.hour > 0 || (diff.day > 0 && diff.minute > 0)) && (
            <span>{`${diff.hour}시간 `}</span>
          )}
          {diff.minute > 0 && <span>{`${diff.minute}분`}</span>}
        </Fragment>
      );
    },
  };
}

async function openSelectCarModal({
  data,
  check,
  submitFunc,
  modalStore,
}: OpenSelectCarModalPropsType) {
  const { isResponseState } = check;
  const modalTitle = isResponseState ? '제안 차량 선택' : '예약 차량 변경';
  const modal = <SelectCarModal data={data} submitFunc={submitFunc} check={check} />;
  const _data = {
    modalComponent: modal,
    modalTitle,
    modalStyle: {
      // inventory완료
      // maxWidth: data.version > 1 ? '825px' : '688px',
      // width: '80%',
      width: '910px',
      overflow: 'hidden',
    },
    // modalBodyStyle: { overflow: 'auto' },
  };

  modalStore.modalOpen(_data);
}

function openResponseConfirmModal(
  propsData: ResponseConfirmModalProps,
  onClick: Function,
  modalStore: any,
  version: number
) {
  const modal = (
    <ResponseConfirmModal
      stateData={propsData}
      onClickConfirm={onClick}
      modalStore={modalStore}
      version={version}
    />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '제안 내용 확인',
    modalStyle: {
      width: 560,
      minHeight: 447,
      overflow: 'hidden',
    },
    modalBodyStyle: { minHeight: 342 },
  };

  modalStore.modalOpen(data);
}

// modal 연계 open 하기 위함
async function openBookedCarChangeModalConfirm(params: OpenSelectCarModalPropsType) {
  changeToNextModal(
    () => params?.modalStore.modalClose(),
    () => openSelectCarModal(params)
  );
}

function openBookedCarChangeModal(
  isDispatchStart: boolean,
  onClick: Function,
  modalStore: any,
  version: number
) {
  const modal = (
    <BookedCarChangeModal
      isDispatchStart={isDispatchStart}
      onClickConfirm={onClick}
      modalStore={modalStore}
      version={version}
    />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '예약 차량 변경',
    modalStyle: {
      width: 520,
      height: isDispatchStart ? 503 : 450,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: isDispatchStart ? 400 : 358 },
  };

  modalStore.modalOpen(data);
}

function openDispatchGiveUpModal(id: number, refreshFunc: Function, modalStore: any) {
  const modal = <DispatchGiveUpModal id={id} refreshFunc={refreshFunc} modalStore={modalStore} />;
  const data = {
    modalComponent: modal,
    modalTitle: '배차 포기 사유',
    modalStyle: {
      width: 520,
      height: 462,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 357 },
  };

  modalStore.modalOpen(data);
}

function openSendSelfContractModal(
  propsData: any,
  onClick: Function,
  modalStore: any,
  resend: boolean,
  selfContractSendState: SelfContractSendStateType,
  data: any
) {
  const modal = (
    <TableConfirmModal
      stateData={propsData}
      onClickConfirm={onClick}
      modalStore={modalStore}
      selfContractSendState={selfContractSendState}
      data={data}
    />
  );
  const modalData = {
    modalComponent: modal,
    modalTitle: resend ? '비대면 계약서 재전송' : '비대면 계약서 보내기',
    modalStyle: {
      width: 540,
      height: 520,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 420 },
  };

  modalStore.modalOpen(modalData);
}

function openDispatchDepartureModal(onClick: Function, modalStore: any) {
  const modal = <DispatchDepartureModal onClickConfirm={onClick} modalStore={modalStore} />;

  const data = {
    modalComponent: modal,
    modalTitle: '배차 출발',
    modalStyle: {
      width: 520,
      height: 390,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 306 },
  };

  modalStore.modalOpen(data);
}

function openDispatchReconfirmModalConfirm(onClick: Function, modalStore: any) {
  changeToNextModal(
    () => modalStore.modalClose(),
    () => openDispatchDepartureModal(onClick, modalStore)
  );
}

function openDispatchReconfirmModal(propsData: any, onClick: Function, modalStore: any) {
  const modal = (
    <DispatchReconfirmModal
      stateData={propsData}
      onClickConfirm={() => openDispatchReconfirmModalConfirm(onClick, modalStore)}
      modalStore={modalStore}
    />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '배차 출발 재확인',
    modalStyle: {
      width: 520,
      height: 332,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 228 },
  };

  modalStore.modalOpen(data);
}

function openWebContractGuideModal(data: any, modalStore: any) {
  const modal = <WebContractGuideModal data={data} />;

  const modalData = {
    modalComponent: modal,
    modalTitle: '웹 계약서 작성 안내',
    modalStyle: {
      width: 520,
      height: 306,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 202 },
  };

  modalStore.modalOpen(modalData);
}

function openReSendSignatureLinkModal(
  propsData: any,
  onClick: Function,
  modalStore: any,
  selfContractSendState: SelfContractSendStateType
) {
  const modal = (
    <TableConfirmModal
      stateData={propsData}
      onClickConfirm={onClick}
      modalStore={modalStore}
      selfContractSendState={selfContractSendState}
    />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '서명 링크 재전송',
    modalStyle: {
      width: 520,
      height: 420,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 317 },
  };

  modalStore.modalOpen(data);
}

function openNoshowProcessModalConfirm(isPickup: boolean, onClick: Function, modalStore: any) {
  changeToNextModal(
    () => modalStore.modalClose(),
    () => openNoshowReportModal(isPickup, onClick, modalStore)
  );
}

function openNoshowProcessModal(
  isTimeout: boolean,
  isPickup: boolean,
  onClick: Function,
  modalStore: any
) {
  const modal = isTimeout
    ? {
        modalComponent: (
          <NoshowPreCheckModal
            isPickup={isPickup}
            onClickConfirm={() => openNoshowProcessModalConfirm(isPickup, onClick, modalStore)}
            modalStore={modalStore}
          />
        ),
        title: '고객 노쇼 처리 유의사항 안내',
        modalHeight: 590,
        modalBodyHeight: isPickup ? 430 : 485,
      }
    : {
        modalComponent: <NoshowNotAllowModal isPickup={isPickup} modalStore={modalStore} />,
        title: '고객 노쇼 처리 시간이 아닙니다!',
        modalHeight: 496,
        modalBodyHeight: isPickup ? 300 : 396,
      };

  const data = {
    modalComponent: modal.modalComponent,
    modalTitle: modal.title,
    modalStyle: {
      width: 520,
      height: modal.modalHeight,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: modal.modalBodyHeight },
  };

  modalStore.modalOpen(data);
}

function openNoshowReportModal(isPickup: boolean, onClick: Function, modalStore: any) {
  const modal = (
    <NoshowReportModal isPickup={isPickup} onClickConfirm={onClick} modalStore={modalStore} />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '고객 노쇼 처리',
    modalStyle: {
      width: 520,
      height: 810,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: isPickup ? 685 : 706 },
  };

  modalStore.modalOpen(data);
}

function openReconfirmReturnCarModalConfirm(propsData: any, onClick: Function, modalStore: any) {
  changeToNextModal(
    () => modalStore.modalClose(),
    () => openConfirmReturnCarModal(propsData, onClick, modalStore)
  );
}

function openConfirmReturnCarModal(propsData: any, onClick: Function, modalStore: any) {
  const modal = (
    <ConfirmReturnModal stateData={propsData} onClickConfirm={onClick} modalStore={modalStore} />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '회차 확인',
    modalStyle: {
      width: 520,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 380 },
  };

  modalStore.modalOpen(data);
}

function openReconfirmReturnCarModal(propsData: any, onClick: Function, modalStore: any) {
  const modal = (
    <ReturnCarReconfirmModal
      stateData={propsData}
      onClickConfirm={() => openReconfirmReturnCarModalConfirm(propsData, onClick, modalStore)}
      modalStore={modalStore}
    />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '회차 재확인',
    modalStyle: {
      width: 520,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 240 },
  };

  modalStore.modalOpen(data);
}

function openConfirmChangeTimeModal(propsData: any, onClick: Function, modalStore: any) {
  const modal = (
    <ConfirmChangeTime stateData={propsData} onClickConfirm={onClick} modalStore={modalStore} />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '대여시간 변경 확인',
    modalStyle: {
      width: 520,
      overflow: 'hidden',
    },
    modalBodyStyle: { height: 328 },
  };

  modalStore.modalOpen(data);
}

function openFailDispatchCarModal(propsData: any, onClick: Function, modalStore: any) {
  const modal = (
    <FailDispatchCarModal stateData={propsData} onClickConfirm={onClick} modalStore={modalStore} />
  );

  const data = {
    modalComponent: modal,
    modalTitle: '배차 출발 불가',
    modalStyle: {
      width: 520,
      height: 420,
    },
    modalBodyStyle: { height: 253 },
  };

  modalStore.modalOpen(data);
}
