import { checkRegIdFormat, regIdFormatValidate } from '../../../helpers/registrationUtils';

export const returnSecondPlaceholder = (company) => {
  const format = checkRegIdFormat(company);

  if (company) return `숫자${format?.secondLength}자리`;

  return '';
};

export const defaultInsuranceId = (company) => {
  const format = checkRegIdFormat(company);

  if (company && format.template) {
    const presetValue = format?.template.split('-')[0];

    return presetValue;
  }
};

export const chekDash = (id) => {
  return [...id].includes('-');
};

export const insuranceResult = (data) => {
  const { id, state, company, modify } = data;
  const { firstId, secondId, singleId } = state;
  const idArr = id?.split('-');
  const result = regIdFormatValidate(company, id);
  const defaultId = defaultInsuranceId(company);

  if (modify && result?.status === 'fail' && !firstId) {
    return {
      firstId: defaultId,
      secondId: null,
      stateCompany: company,
    };
  }

  if (id && id !== '-' && chekDash(id)) {
    if (!firstId && !secondId) {
      return {
        firstId: idArr[0] || '',
        secondId: idArr[1] || '',
        stateCompany: company,
      };
    }

    if (idArr[0] && idArr[1] && (idArr[0] !== firstId || idArr[1] !== secondId)) {
      return {
        firstId: idArr[0] || '',
        secondId: idArr[1] || '',
        stateCompany: company,
      };
    }
  }

  if (id && !chekDash(id) && !singleId) return { singleId: id };
};

export const changeInsurance = (company, state) => {
  const id = defaultInsuranceId(company);

  if (company !== state.stateCompany) {
    return {
      firstId: id,
      secondId: null,
      singleId: null,
      stateCompany: company,
    };
  }
};
