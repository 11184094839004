import callApi from '../../helpers/callApi';

export default {
  startCarDispatch(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: `v2/rent-responses/${params.id}/start-delivery`,
      data: {
        unknown_delivery_user: true,
        last_four_car_number: params.carLastNumber,
      },
    });
  },
  sendResponse(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: '/v2/rent-responses',
      data: {
        request_id: params.request_id,
        car1_detail: params.car1_detail,
        car_insurance_type: params.car_insurance_type,
        indemnfication_fee: params.indemnfication_fee,
        extra: params.extra,
        car2_detail: params.car2_detail,
        superpass_id: params.superpass_id,
      },
    });
  },
  editResponse(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-responses/${params.id}`,
      data: {
        request_id: params.request_id,
        car1_detail: params.car1_detail,
        car_insurance_type: params.car_insurance_type,
        indemnfication_fee: params.indemnfication_fee,
        extra: params.extra,
        car2_detail: params.car2_detail,
        superpass_id: params.superpass_id,
      },
    });
  },
  removeResponse(params) {
    return callApi({
      method: 'DELETE',
      url: `/v2/rent-responses/${params.id}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  // giveUpResponse(params) {
  //   return callApi({
  //     method: 'POST',
  //     url: `/v2/rent-responses/${params.id}/give_up`,
  //     data: {
  //       request_id: params.requestId,
  //       reason: params.reason
  //     }
  //   })
  // }
  giveUpResponse(params) {
    return callApi({
      method: 'POST',
      url: `/v3/responses/${params.id}/giveup`,
      data: {
        giveup_reason_id: params.reasonId,
        other_giveup_reason: params.reason,
      },
    });
  },
};
