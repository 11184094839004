//회원
export const LOG_IN = '로그인';
export const JOIN_MEMBER = '회원가입';
export const FIND_ID = '아이디 찾기';
export const FIND_PASSWORD = '비밀번호 찾기';
export const ID_DUPLICATE = 'ID 중복 체크';
export const SIGNING_UP = '가입하기';
export const LOGIN_SELECT_ID = '선택한 아이디로 로그인';
export const APPLY_JOIN = '가입 신청';
export const RE_REQUEST = '재요청';
export const CERTIFY_NUMBER_REQUEST = '인증번호 요청';

//차량관리
export const VIEW_REGISTRATION = '등록증 보기';
export const VIEW_MANAGE_STEAD_CONTRACT = '관리위탁 계약서 보기';
export const REGISTER_MANAGE_STEAD_CONTRACT = '관리위탁 계약서 등록';
export const DISCONNECT = '연동 해제';
export const RECONNECT = '확인';

//요청
export const APPLY_COUPON = '쿠폰적용';
export const DOING_SUGGEST = '제안하기';
export const CANCEL_APPLY = '적용취소';
export const REVISE_SUGGESTION = '제안 수정';
export const CANCEL_SUGGESTION = '제안 취소';
export const CANCEL_ALLOCATE_CAR = '배차 포기';
export const DONE_ALLOCATE_CAR = '배차 완료';
export const DONE_RETURN = '반납 완료';
export const WRITE_CONTRACT = '계약서 작성';
export const CONNECT_CONTRACT = '계약서 연결';
export const RETURN_REQUEST_DETAIL = '요청 상세로 돌아가기';

//계정
export const TRANS_CERTIFICATION_NUMBER = '인증번호 전송';

//계약서
export const SELECT_CAR = '차량선택';
export const SETTING_CLAIM_STANDARD = '청구 기준 설정';

//계약서 상세
export const VIEW_CAR_PICTURE = '차량 사진 보기';
export const COMPLETE_REVISE = '수정완료';
export const RENT_CAR_APPEARANCE_PICTURE = '렌터카 외관 사진';
export const CUSTOMER_CAR_APPEARANCE_PICTURE = '고객차량 외관 사진';
export const DOWNLOAD_CONTRACT = '계약서 다운로드';
export const VIEW_CONTRACT = '계약서 보기';
export const NOTICE_MESSAGE = '문자통보';

//공통
export const SAVE = '저장';
export const CONFIRM = '확인';
export const CANCEL = '취소';
export const REVISE = '수정';
export const REGISTRATION = '등록';
export const DOING_REGISTRATION = '등록하기';
export const SEARCH = '검색';
export const FIND = '조회';
export const DOING_REVISE = '수정하기';
export const COMPLETE = '완료';
export const GO_TO_LIST = '목록으로';
export const SEND = '발송';
export const DO_SEND = '발송하기';
export const DOWNLOAD = '다운로드';
export const DELETE = '삭제';
export const RESEND = '재전송';
export const GO_BACK = '뒤로가기';

//사업자관리
export const REGISTER_EMPLOYEE = '직원 등록';

//엑셀 파일
export const EXCEL_DOWNLOAD = '엑셀 파일 다운로드';
export const EXCEL_UPLOAD = '엑셀 파일 업로드';
