import dayjs from 'dayjs';

export const dashFormatParser = (date) => {
  const dateFormatCheck = date.split('')[4];

  if (dateFormatCheck !== '-') {
    const returnDate = `${date.slice(0, 4)}-${date.slice(4)}`;

    return returnDate;
  }

  return date;
};

export default function dateTimeParser(info) {
  // NOTE: IE 버그 발생 방지 dayjs 사용시 YYYY-MM-DD 의 포맷형태로 넘겨서 사용해야함
  if (!info.date || info.date === '-') return null;

  if (info.date && !info.format) return info.date;

  if (typeof info.date === 'object') return dayjs(info.date).format(info.format);

  return dayjs(dashFormatParser(info.date)).format(info.format);
}

export const moduleDateTimeParser = (info) => {
  if (!info.date || info.date === '-') return null;

  if (info.date && !info.format) return info.date;

  return dayjs(info.date).format(info.format);
};
