import React from 'react';

import CustomDatePicker from '../../../atoms/CustomDatePicker/index.tsx';
import dateTimeParser from '../../../helpers/dateTimeParser';
import styles from './DateType.module.scss';

const DateType = (props) => {
  const { stateData, info, setData } = props;

  return (
    <div className={styles.full_date_wrap}>
      <CustomDatePicker
        icon
        className={styles.period_date_picker}
        inlineStyle={info.style}
        selected={stateData[`${info.key}`] && new Date(stateData[`${info.key}`])}
        onChange={(value) =>
          setData(`${info.key}`, dateTimeParser({ date: value, format: 'YYYY-MM-DD' }))
        }
        maxDate={new Date(info.maxDate)}
        minDate={new Date(info.minDate)}
      />
    </div>
  );
};

export default DateType;
