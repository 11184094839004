import * as MESSAGE from './stringTable';
export const usingListOption = [
  { label: '선택', value: '' },
  { label: '연번', value: 'claim_id' },
  { label: '배차자', value: 'manager_name' },
  { label: '대여 차종', value: 'rent_car_name' },
  { label: '대여 차량번호', value: 'rent_car_number' },
  { label: MESSAGE.CUSTOMER_NAME, value: 'customer_name' },
  { label: MESSAGE.CUSTOMER_CAR, value: 'customer_car' },
  { label: '고객차량 번호', value: 'customer_car_number' },
  { label: '고객 연락처', value: 'customer_contact' },
  { label: MESSAGE.INSURANCE_COMPANY_NAME, value: 'insurance_company' },
  { label: '보험사담당자', value: 'insurance_manager_name' },
  { label: '접수번호', value: 'registration_id' },
  { label: '수리처', value: 'industrial_company' },
  { label: '영업 직원', value: 'sales_employee' },
  { label: '소개처', value: 'recommender_name' },
];

export const claimListOption = [
  { label: '연번', value: 'claim_id' },
  { label: '계약서 번호', value: 'contract_id' },
  { label: '배차자', value: 'manager_name' },
  // { label: '대여 차종', value: 'rent_car_name' },
  { label: '대여 차종', value: 'car_model' },
  { label: '대여 차량번호', value: 'rent_car_number', default: true },
  { label: '고객명', value: 'customer_name' },
  { label: '고객 차량', value: 'customer_car' },
  { label: '고객차량 번호', value: 'customer_car_number' },
  { label: '고객 연락처', value: 'customer_contact' },
  { label: '보험사', value: 'insurance_company' },
  { label: '보험사담당자', value: 'insurance_manager_name' },
  { label: '접수번호', value: 'registration_id' },
  { label: '배차 법인', value: 'business_name' },
  { label: '수리처', value: 'industrial_company' },
  { label: '영업 직원', value: 'sales_employee' },
  { label: '소개처', value: 'recommender_name' },
];

export const normalContractListOption = [
  { label: '연번', value: 'id' },
  { label: '계약서 번호', value: 'detail_id' },
  { label: '배차자', value: 'delivered_user_name' },
  { label: '대여 차종', value: 'rent_car_name' },
  { label: '대여 차량번호', value: 'rent_car_number', default: true },
  { label: '고객명', value: 'customer_name' },
  { label: '고객 연락처', value: 'customer_contact' },
  { label: '배차 법인', value: 'business_name' },
  { label: '영업 직원', value: 'sales_employee' },
  { label: '소개처', value: 'recommender_name' },
];

export const partnerManagementListOption = (option) => [
  { label: '소개처명', value: 'partner_name', default: option === 'partner_name' },
  { label: '연락처', value: 'partner_contact', default: option === 'partner_contact' },
  { label: '담당자', value: 'partner_manager', default: option === 'partner_manager' },
];

export const setDateRangeOptionReturn = [
  { label: '대여일', value: 'delivered_date', default: true },
  { label: MESSAGE.EXPECT_RETURN_DATE, value: 'expect_return_date' },
  { label: '반납일', value: 'return_date' },
  { label: '입금일', value: 'deposit_date' },
];

export const setDateRangeOptionClaim = [
  { label: '대여일', value: 'created_at' }, //디폴트 값
  { label: '반납일', value: 'return_date' },
  { label: '청구일', value: 'claim_at' },
  { label: '입금일', value: 'claim_done_at' },
];

export const carCurrentStateOption = [
  { label: MESSAGE.ALL, value: 'all' },
  { label: '대기', value: 'waiting' },
  { label: '예약', value: 'booking' },
  { label: '수리중', value: 'repair' },
  { label: '직원사용', value: 'employee_use' },
];

export const groupContractListState = [
  { label: MESSAGE.ALL, value: 'all' },
  { label: '배차중', value: 'using_car' },
  { label: '청구대기', value: 'before_claim' },
  { label: '청구완료', value: 'send_claim' },
  { label: '계약종결', value: 'done_claim' },
];

export const normalGroupContractState = [
  { label: MESSAGE.ALL, value: 'all' },
  { label: '배차중', value: 'using_car' },
  { label: '반납예정', value: 'expect_return_date' },
  { label: '입금대기', value: 'deposit_wait' },
  { label: '계약종결', value: 'done_claim' },
];

export const receiptHistoryListOption = [
  { label: '지점명', value: 'company' },
  { label: '연번', value: 'serial_number' },
  { label: '구분', value: 'deposit_reason' },
  { label: '입금분류', value: 'payment_method_type' },
  { label: '입금경로', value: 'payment_method' },
  { label: MESSAGE.INSURANCE_COMPANY_NAME, value: 'insurance_company' },
  { label: '대여 차량번호', value: 'rent_car_number', default: true },
  { label: MESSAGE.CUSTOMER_NAME, value: 'customer_name' },
  { label: '사업자명', value: 'business_name' },
];

// TODO: API
export const washCarCouponListOption = [
  { label: '차량 번호', value: 'car_identity', default: true },
  { label: '차량명', value: 'car_name' },
  { label: '사용 직원', value: 'user_name' },
  { label: '쿠폰명', value: 'coupon_name' },
];

export const couponListOption = [
  { label: '선택', value: '', type: 'text' },
  { label: '상태', value: 'state', type: 'select' },
  { label: '구분', value: 'coupon_name', type: 'select' },
  { label: '발급요청번호', value: 'issuing_request_id', type: 'text' },
  { label: '사용요청번호', value: 'used_request_id', type: 'text' },
];

export const couponNameOption = [
  { label: '선택', value: '' },
  { label: '슈퍼패스', value: 'superpass' },
];

export const couponStateOption = [
  { label: '선택', value: 'all' },
  { label: '사용가능', value: 'available' },
  { label: '사용중', value: 'in_use' },
  { label: '사용완료', value: 'used' },
  { label: '기간만료', value: 'expired' },
  { label: '사용불가 (회수)', value: 'cancelled' },
];

export const deadlineInsuranceOption = [
  { label: '선택', value: 'all' },
  { label: '연번', value: 'claim_id' },
  { label: '대여차종', value: 'rent_car_name' },
  { label: '대여 차량번호', value: 'rent_car_number' },
  { label: MESSAGE.INSURANCE_COMPANY_NAME, value: 'insurance_company' },
  { label: '담당자', value: 'insurance_manager_name' },
];

export const deadlineGeneralOption = [
  { label: '선택', value: 'all' },
  { label: '연번', value: 'id' },
  { label: '구분', value: 'rent_type' },
  { label: '대여차종', value: 'rent_car_name' },
  { label: '대여 차량번호', value: 'rent_car_number' },
  { label: MESSAGE.CUSTOMER_NAME, value: 'customer_name' },
];

export const deadlineGeneralRentTypeOption = [
  { label: '일', value: 'daily' },
  { label: '월', value: 'monthly' },
];

export const partnerSelectOption = [
  { label: '소개처명', value: 'partner_name' },
  { label: '연락처', value: 'partner_contact' },
  { label: '담당자', value: 'partner_manager' },
];

// ======================//

export const groupContractData = {
  insurance: [
    { label: MESSAGE.ALL, value: 'all' },
    { label: '배차중', value: 'using_car' },
    { label: '청구대기', value: 'before_claim' },
    { label: '청구 검수중', value: 'pending_claim' },
    { label: '청구완료', value: 'send_claim' },
    { label: '계약종결', value: 'done_claim' },
  ],
  normal: [
    { label: MESSAGE.ALL, value: 'all' },
    { label: '배차중', value: 'using_car' },
    { label: '입금대기', value: 'deposit_wait' },
    { label: '계약종결', value: 'deposit_done' },
  ],
};

export const groupDateRangeOption = {
  insurance: [
    { label: '대여일', value: 'created_at' },
    { label: '반납일', value: 'return_date' },
    { label: '청구일', value: 'claim_at' },
    { label: '입금일', value: 'claim_done_at' },
  ],
  normal: [
    { label: '대여일', value: 'delivered_date' },
    { label: MESSAGE.EXPECT_RETURN_DATE, value: 'expect_return_date' },
    { label: '반납일', value: 'return_date' },
    { label: '입금일', value: 'deposit_date' },
  ],
};

export const groupSearchOption = {
  insurance: [
    { label: '연번', value: 'claim_id' },
    { label: '배차자', value: 'manager_name' },
    { label: '대여 차종', value: 'rent_car_name' },
    { label: '대여 차량번호', value: 'rent_car_number', default: true },
    { label: MESSAGE.CUSTOMER_NAME, value: 'customer_name' },
    { label: MESSAGE.CUSTOMER_CAR, value: 'customer_car' },
    { label: '고객차량 번호', value: 'customer_car_number' },
    { label: '고객 연락처', value: 'customer_contact' },
    { label: MESSAGE.INSURANCE_COMPANY_NAME, value: 'insurance_company' },
    { label: '보험사담당자', value: 'insurance_manager_name' },
    { label: '접수번호', value: 'registration_id' },
    { label: '배차 법인', value: 'business_name' },
    { label: '수리처', value: 'industrial_company' },
    { label: '영업 직원', value: 'sales_employee' },
    { label: '소개처', value: 'recommender_name' },
  ],
  normal: [
    { label: '연번', value: 'id' },
    { label: '배차자', value: 'delivered_user_name' },
    { label: '대여 차종', value: 'rent_car_name' },
    { label: '대여 차량번호', value: 'rent_car_number', default: true },
    { label: MESSAGE.CUSTOMER_NAME, value: 'customer_name' },
    { label: '고객 연락처', value: 'customer_contact' },
    { label: '배차 법인', value: 'business_name' },
    { label: '영업 직원', value: 'sales_employee' },
    { label: '소개처', value: 'recommender_name' },
  ],
};

export const consignSearchOption = [
  { label: '탁송지 별칭', value: 'name', default: true },
  { label: '주소', value: 'address' },
  { label: '연락처', value: 'contact' },
];

export const consignHistorySearchOption = [
  { label: '차량번호', value: 'carnum', default: true },
  { label: '차종', value: 'cartype' },
  { label: '탁송 요청번호', value: 'requestnum' },
  { label: '출발지 연락처', value: 'startcontact' },
  { label: '도착지 연락처', value: 'endcontact' },
];

export const deadlineSearchOption = {
  insurance: [
    { label: '연번', value: 'claim_id' },
    { label: '대여차종', value: 'rent_car_name' },
    { label: '대여 차량번호', value: 'rent_car_number', default: true }, //  디폴트 값
    { label: MESSAGE.INSURANCE_COMPANY_NAME, value: 'insurance_company' },
    { label: '담당자', value: 'insurance_manager_name' },
  ],
  normal: [
    { label: '연번', value: 'id' },
    { label: '구분', value: 'rent_type' },
    { label: '대여차종', value: 'rent_car_name' },
    { label: '대여 차량번호', value: 'rent_car_number', default: true },
    { label: MESSAGE.CUSTOMER_NAME, value: 'customer_name' },
  ],
};

export const penaltyDateSearchOption = [
  { label: '신청서 생성일', value: 'application_created_at', default: true },
  { label: '신청일', value: 'application_datetime' },
  { label: '위반일', value: 'penalty_datetime' },
];

export const penaltySearchOption = [
  { label: '대여 차량번호', value: 'car_number', default: true },
  { label: '발행기관', value: 'imposer' },
  { label: '명의변경대상', value: 'target' },
  { label: '연번', value: 'serial_number' },
];

export const branchManagementSearchOption = (query) => {
  return [
    {
      label: '지점명',
      value: 'company_name',
      default: query.option === 'company_name' || !query.option,
    },
    { label: '지점대표', value: 'president_name', default: query.option === 'president_name' },
  ];
};

export const claimeeSearchOption = (searchOption) => [
  { label: '청구처명', value: 'claimee', default: searchOption === 'claimee' },
  { label: '담당자명', value: 'manager', default: searchOption === 'manager' },
  { label: '담당자 연락처', value: 'contact', default: searchOption === 'contact' },
];

export const schedulerSearchOptions = (searchOption) => [
  { label: '차량번호', value: 'car_identity', default: searchOption === 'car_identity' },
  { label: '그룹명', value: 'car_group_id', default: searchOption === 'car_group_id' },
  { label: '이름', value: 'customer_name', default: searchOption === 'customer_name' },
  { label: '연락처', value: 'customer_contact', default: searchOption === 'customer_contact' },
  {
    label: '사고 차량번호',
    value: 'customer_car_number',
    default: searchOption === 'customer_car_number',
  },
];

export const setDateRangeOptionSetCarHoliday = [
  { label: '휴무일', value: 'holiday', default: true },
];

export const setCarHoliday = [
  { label: '차량번호', value: 'car_number', default: true },
  { label: '차량 등급', value: 'car_class' },
  { label: '차량명', value: 'car_name' },
  { label: '휴무사유', value: 'memo' },
];

export const bookingListSearchOption = [
  { label: '고객명', value: 'customer_name', default: true },
  { label: '스케줄번호', value: 'id' },
  { label: '차량번호', value: 'car_identity' },
  { label: '연락처', value: 'customer_contact' },
];
