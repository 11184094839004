import type { Orderers } from 'api/etc';
import { getOrderers } from 'api/etc';
import { find } from 'lodash';
import { makeAutoObservable } from 'mobx';
import { getParsedOrdererName } from 'utils/getParsedOrdererName';

import { _setObject, BaseStore } from './BaseStore.type';

class CommonStore implements BaseStore<CommonStore> {
  public static getInstance() {
    // eslint-disable-next-line no-return-assign
    return this.instance || (this.instance = new this());
  }
  prefetchSuccess = false;
  private static instance: CommonStore;
  orderers: Orderers[] = [];

  company_id: number | null = null; // AuthStore company_id로 전달하기 위해 저장

  constructor() {
    makeAutoObservable(this);
  }

  prefetch = async (jwt?: string) => {
    await this.fetchOrderers(jwt);
    this.prefetchSuccess = true;

    return this;
  };

  fetchOrderers = async (jwt: string) => {
    if (this.orderers.length === 0) {
      const result = await getOrderers({ disableErrorHandler: true }, undefined, jwt);

      if (result.status === 200) {
        this.orderers = result.data.orderers;
        this.company_id = result.data.defaultInfo?.company_id;
      }

      return result;
    }

    return this.orderers;
  };

  getOrderer = (orderer) => {
    const result = find(this.orderers, { name: getParsedOrdererName(orderer) });

    if (result) {
      return result;
    }

    return {
      brand_name: '알 수 없는 유형',
      id: 1,
      name: 'unknown',
      name_kor: '알 수 없는 유형',
    };
  };
  setObject = (data) => _setObject(this, data);
}

export default CommonStore.getInstance();
