import errorHandler from 'helpers/errorHandler';

import * as callApi2 from '../../helpers/callApi2';

export type CarOption = {
  id: number;
  name: string;
  name_kor: string;
};

type GetCarGroupOptionsResponse =
  | callApi2.GetSuccessResponse<{
      car_options: CarOption[];
      caravan_options: CarOption[];
      defaultInfo: callApi2.DefaultInfo;
    }>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;
export const getCarGroupOptionsApi = async () => {
  const response = await callApi2.get<GetCarGroupOptionsResponse>(
    '/v3/public/car-groups/options',
    {}
  );

  if (response.status === 200) {
    const { car_options, caravan_options } = response.data;

    return {
      carOptions: car_options,
      campingCarOptions: caravan_options,
    };
  }

  errorHandler(response.data, response.status);

  return {
    carOptions: [],
    campingCarOptions: [],
  };
};
