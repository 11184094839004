import callApi from '../../helpers/callApi';

export default {
  getInsuCompanies(params, needToGuaranteed = false) {
    return callApi({
      method: 'GET',
      url: 'v2/insurance-companies',
      stores: {
        menuStore: params?.menuStore,
        authStore: params?.authStore,
      },
      serverCookie: params?.cookie || null,
      params: { with_guaranteed: needToGuaranteed },
    });
  },
};
