import apiIS from '../../../api/normalContract/installmentSchedule';
import apiPaymentMethod from '../../../api/rentCompanyPaymentMethod';
import {
  FetchWrapperArg,
  FetchWrapperCBArgClient,
  FetchWrapperCBArgServer,
} from '../../../interface/utils';
import * as utils from '../../../utils/PaymentUtils';
import { simpleProcessCallApi } from '../../../utils/RequestApiUtils';
import FetchFactory from '../__fetchFactoryClass__';

class PaymentSchedulePreFetch extends FetchFactory {
  protected preFetch = (ctx: FetchWrapperArg) => {
    return this.fetchWrapper(ctx, async (params: FetchWrapperCBArgServer) => {
      const result1 = this.getPaymentMethodList(params);
      const result2 = this.getScheduleList(params);

      return await Promise.all([result1, result2]).then((results) => {
        return {
          apiResult: { ...results[0].apiResult, ...results[1].apiResult },
          defaultInfo: results[0].defaultInfo,
        };
      });
    });
  };

  private getPaymentMethodList = async (ctx: FetchWrapperCBArgServer) => {
    const { req, query } = ctx;

    const params = {
      inUse: 1,
      page: query.page || 1,
      cookie: this.isServer && req.headers.cookie,
    };

    const responseData = await simpleProcessCallApi(apiPaymentMethod.getPaymentMethodList, params);
    const apiResult = {
      paymentMethodList: responseData.data,
      totalPage: responseData.totalPage,
      currentPage: query.page,
    };
    const defaultInfo = responseData.defaultInfo;

    return { apiResult, defaultInfo };
  };

  private getScheduleList = async (ctx: FetchWrapperCBArgServer) => {
    const { req, query } = ctx;

    const params = {
      id: query.id,
      cookie: this.isServer && req.headers.cookie,
    };

    const responseData = await simpleProcessCallApi(apiIS.getScheduleList, params);
    const apiResult = {
      editMode: false,
      data: responseData,
      disabledStartIndex: utils.getDisabledStartIndex(responseData.schedule),
    };
    const defaultInfo = responseData.defaultInfo;

    return { apiResult, defaultInfo };
  };

  getInstance = () => {
    return {
      preFetch: this.preFetch,
      getScheduleList: (
        component: FetchWrapperCBArgClient,
        callBack: Function,
        refresh: boolean
      ) => {
        return this.fetchWrapper(component, async (params: FetchWrapperCBArgServer) => {
          const result1 = this.getPaymentMethodList(params);
          const result2 = this.getScheduleList(params);

          await Promise.all([result1, result2]).then((results) => {
            this.setStoreData(component.props, results[0].defaultInfo);
            component.setState({ ...results[0].apiResult, ...results[1].apiResult }, () =>
              callBack(refresh)
            );
          });
        });
      },
    };
  };
}

const paymentSchedulePreFetch = new PaymentSchedulePreFetch().getInstance();

export default paymentSchedulePreFetch;
