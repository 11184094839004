/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react';

import { getByteLength } from '../../helpers/byteUtils';
import Button from '../Button';
import styles from './TextArea.module.scss';

interface ComponentTypes extends DataObjType {
  setData?: Function;
  btn?: string; // button name
  keyName?: string;
  className?: string;
  placeholder?: string;
  value?: string | number;
  maxByte?: number;
  disabled?: boolean;
  memoStyle?: boolean; // apply memo style boolean
  hideButton?: boolean;
  submit?: Function;
  onKeyPress?: Function;
  style?: React.CSSProperties;
  btnStyle?: React.CSSProperties;
  dataset?: string;
  maxLength?: number;
}

interface DataObjType {
  dataObj?: Record<any, any>;
}

interface StateTypes {
  byte: number;
  byteWarning: boolean;
}

class TextArea extends Component<ComponentTypes, StateTypes> {
  constructor(props: ComponentTypes) {
    super(props);
    this.state = {
      byte: 0,
      byteWarning: false,
    };
  }

  onChangeFunction = async (key: Readonly<string>, value: Readonly<string>) => {
    // @ts-ignore
    await this.asyncSetState({ [`${key}_byte`]: getByteLength(value) });
  };

  onChange = (value: Readonly<string>) => {
    const { setData, maxByte, keyName, maxLength } = this.props;

    this.onChangeFunction(keyName || '', value);

    if (maxByte && getByteLength(value) > maxByte) {
      return false;
    }

    if (Boolean(maxLength) && value.length > maxLength) return;

    setData && setData(keyName || '', value);
  };

  renderByte = () => {
    const { maxByte, dataObj, keyName } = this.props;

    if (maxByte) {
      return (
        <div className={styles.byte_area}>
          (
          <span
            style={
              getByteLength(dataObj ? dataObj[keyName || ''] : '') >= Number(maxByte)
                ? { color: 'red' }
                : { color: '#c5c0bc' }
            }
          >
            {getByteLength(dataObj ? dataObj[keyName || ''] : '')}
          </span>
          /{maxByte}자 )
        </div>
      );
    }
  };

  renderBtn = () => {
    if (this.props.hideButton) return;

    const { btn, submit, btnStyle } = this.props;

    if (btn) {
      return (
        <Button
          className={styles.textarea_btn}
          btnText={btn}
          btnClick={submit}
          inlineStyle={btnStyle}
        />
      );
    }
  };
  render() {
    const { placeholder, value, style, className, memoStyle, disabled, onKeyPress, dataset } =
      this.props;

    if (memoStyle) {
      return (
        <div className={styles.memo_text_area}>
          <div className={`${styles.text_area} ${className || ''}`} style={style || {}}>
            <textarea
              placeholder={placeholder}
              onChange={(e) => this.onChange(e.target.value)}
              value={value}
              disabled={disabled}
              onKeyPress={(e) => e.key === 'Enter' && onKeyPress && onKeyPress(e)}
              data-input={dataset}
            />
            <div className={styles.textarea_bottom}>
              {this.renderByte()}
              {this.renderBtn()}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.text_area_wrap}>
        <div className={`${styles.text_area} ${className || ''}`} style={style || {}}>
          <textarea
            placeholder={placeholder}
            onChange={(e) => this.onChange(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && onKeyPress && onKeyPress(e)}
            value={value}
            disabled={disabled}
            data-input={dataset}
          />
          {this.renderByte()}
        </div>
        {this.renderBtn()}
      </div>
    );
  }
}

export default TextArea;
