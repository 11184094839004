import React from 'react';

import TextArea from '../../../atoms/TextArea';

const TextAreaType = (props) => {
  const { info, stateData, setData } = props;

  return (
    <TextArea
      btn={info.btn}
      btnStyle={info.btnStyle}
      style={info.style}
      maxByte={info.maxByte}
      placeholder={info.placeholder}
      setData={setData}
      keyName={info.key}
      dataObj={stateData}
      value={stateData[info.key] || ''}
      dataset={info.key}
      submit={info.btnFunc}
    />
  );
};

export default TextAreaType;
