import imageCompression from 'browser-image-compression';
import React, { Component, Fragment } from 'react';

import Button from '../../atoms/Button/index.tsx';
import { filePlus } from '../../constants/images';
import styles from './FileInput.module.scss';

class FileInput extends Component {
  constructor(props) {
    super(props);
    this.fileInput = null;
    this.state = {
      loading: false,
    };
  }

  _getFilename = () => {
    const { emptyFile, value } = this.props;

    if (!this.strictHasFile()) {
      return emptyFile ? emptyFile : value || '파일이 없습니다';
    }

    return this.fileInput.files[0] ? this.fileInput.files[0].name : value;
  };

  _getError = () => {
    const limit = this.props.limit ? this.props.limit : 5;

    if (
      Boolean(this.fileInput) &&
      this.fileInput.files.length !== 0 &&
      this.fileInput.files[0].size > limit * 1024 * 1024
    ) {
      return '파일 크기가 너무 큽니다';
    }

    return false;
  };

  limit500Checker = () => {
    if (
      Boolean(this.fileInput) &&
      this.fileInput.files.length !== 0 &&
      this.fileInput.files[0].size > 500 * 1024
    ) {
      return true;
    }

    return false;
  };

  getKey = () => this.props.keyName;
  limit500kb = () => this.limit500Checker();
  hasFile = () => {
    return Boolean(this.fileInput) && this.fileInput.files.length === 1;
  };
  strictHasFile = () => {
    if (
      (Boolean(this.fileInput) && this.fileInput.files[0] && this.fileInput.files[0].name) ===
      this.props.deleteFileName
    ) {
      return false;
    }

    return Boolean(this.fileInput) && this.fileInput.files.length === 1;
  };
  isValid = () => !this._getError();
  getFile = () => (this.hasFile() ? this.fileInput.files[0] : null);
  getName = () => this.props.name;
  getFileExtension = () => {
    if (this.hasFile()) {
      const extension = this.getFile().name.split(/[\s.]+/);

      return extension[extension.length - 1];
    }
  };
  getFileAsBase64 = () =>
    new Promise((resolve, reject) => {
      const file = this.getFile();
      const reader = new FileReader();
      const resolveResult = () => resolve(reader.result);

      reader.addEventListener('load', resolveResult);

      try {
        reader.readAsDataURL(file);
      } catch (error) {
        reader.removeEventListener('load', resolveResult);
        reject(error);
      }
    });

  _onTouchButton = () => {
    if (this.props.interceptOnClickEvent) {
      return this.props.interceptOnClickEvent();
    }

    this.fileInput.click();
  };

  compressionImg = async (file) => {
    this.setState({ loading: true });
    const { getData } = this.props;
    const targetFile = file;
    const options = {
      maxSizeMB: 1.5,
    };
    const filename = this.getFile().name;

    try {
      const compressedFile = await imageCompression(file, options);
      const reader = new FileReader();

      reader.readAsDataURL(compressedFile);
      reader.onloadend = () => {
        const base64data = reader.result;
        const split = base64data.split(',');
        const file = split[split.length - 1];
        const fileData = { file, file_name: filename, size: 3 * (file.length / 4) };

        getData(fileData, targetFile);
        this.setState({ loading: false });
      };
    } catch (error) {
      this.setState({ loading: false });
      console.log(error);
    }
  };

  _update = async (e) => {
    const {
      tableFile,
      getData,
      accept,
      thumbnail,
      possibleExtenstions,
      compressSize,
      limitMessage,
    } = this.props;
    let limit = this.props.limit || 5;

    if (limit) {
      if (e.target.files[0].size > limit * 1024 * 1024) {
        alert(limitMessage ? `${limitMessage}` : '파일 크기가 너무 큽니다.');
        e.target.value = null;

        return null;
      }

      this.forceUpdate();

      if (this.hasFile() && this.isValid()) {
        const filename = this.getFile().name;
        const canFormat = ['JPG', 'JPEG', 'PNG'];
        const extension = filename.split(/[\s.]+/);

        if (
          ((accept && accept === 'image/*,') || accept === 'images/*,') &&
          extension &&
          !canFormat.includes(extension[extension.length - 1].toUpperCase())
        ) {
          e.target.value = null;

          return alert('이미지 파일만 업로드 가능합니다.');
        }

        if (accept === 'application/pdf' && this.getFileExtension() !== 'pdf') {
          e.target.value = null;

          return alert('PDF 파일만 업로드 해주세요.');
        }

        if (
          accept === 'image/*, application/pdf' &&
          !possibleExtenstions.includes(this.getFileExtension().toUpperCase())
        ) {
          e.target.value = null;

          return alert(`${possibleExtenstions.join(', ')} 파일로 업로드 해주세요.`);
        }

        const isImageFile = ['JPG', 'JPEG', 'PNG'].includes(
          extension[extension.length - 1].toUpperCase()
        );

        if (isImageFile) {
          if (compressSize && e.target.files[0].size > compressSize) {
            this.compressionImg(e.target.files[0]);

            return;
          }

          //반납요청
          if (e.target.files[0].size > 1500000 && thumbnail) {
            this.compressionImg(e.target.files[0]);

            return;
          }
        }

        const getFileData = await this.getFileAsBase64();
        const split = getFileData.split(',');
        const file = split[split.length - 1];
        const fileData = { file, file_name: filename, size: 3 * (file.length / 4) };

        if (tableFile || thumbnail) {
          getData(fileData);
        }
      }
    }

    if (this.props.directUpload) {
      this.props.onSelectFile(this);
    }
  };

  renderTableFileInput = () => {
    return (
      <div className={styles.table_file_input_area} style={this.props.style}>
        <input
          {...(this.props.accept ? { accept: this.props.accept } : {})}
          ref={(r) => (this.fileInput = r)}
          name={this.props.name}
          type="file"
          style={{ display: 'none' }}
          onChange={this._update}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        {/*<RaisedButton
          label={this.props.label}
          secondary
          onMouseUp={this._onTouchButton}
        />*/}
        {!this._getError() ? (
          <div
            style={
              this.strictHasFile() || this.props.value
                ? {
                    display: 'inline-block',
                    marginLeft: 20,
                    color: '#333333',
                  }
                : {
                    display: 'inline-block',
                    marginLeft: 20,
                    color: '#c5c0bc',
                  }
            }
          >
            {this.props.value || this._getFilename()}
          </div>
        ) : (
          <Fragment>
            <div style={{ marginLeft: 10, marginRight: 10 }}>경고</div>
            <div
              style={{
                display: 'inline-block',
                color: '#ffa723',
              }}
            >
              {this._getError()}
            </div>
          </Fragment>
        )}
        {this.props.count !== 5 && (
          <Fragment>
            <Button
              loading={this.state.loading}
              className={this.props.className}
              btnClick={this._onTouchButton}
              btnText={this.hasFile() && !this.props.multi ? '파일 변경' : this.props.label}
            />
          </Fragment>
        )}
      </div>
    );
  };

  renderDirectFileInput = () => {
    return (
      <div style={this.props.style}>
        <input
          {...(this.props.accept ? { accept: this.props.accept } : {})}
          ref={(r) => (this.fileInput = r)}
          name={this.props.name}
          type="file"
          style={{ display: 'none' }}
          onChange={this._update}
          onClick={(e) => {
            e.target.value = null;
          }}
        />
        <Button
          loading={this.state.loading}
          inlineStyle={this.props.btnStyle}
          className={this.props.className}
          btnClick={this._onTouchButton}
          btnText={this.props.label}
          disabled={this.props.disabled}
        />
      </div>
    );
  };

  renderThumbnailInput = () => {
    return (
      <div className={styles.thumbnail_upload}>
        <input
          type="file"
          id="imageUpdate"
          ref={(r) => (this.fileInput = r)}
          accept="image/*"
          onChange={this._update}
          value=""
        />
        <label htmlFor="imageUpdate">
          <p>
            <img src={filePlus} />
          </p>
          <p>사진 첨부</p>
        </label>
      </div>
    );
  };

  render() {
    const { tableFile, directUpload, thumbnail } = this.props;

    if (thumbnail) {
      return this.renderThumbnailInput();
    }

    if (tableFile) {
      return this.renderTableFileInput();
    }

    if (directUpload) {
      return this.renderDirectFileInput();
    }

    return (
      <div className={styles.file_input_btn_area} style={this.props.style}>
        <input
          {...(this.props.accept ? { accept: this.props.accept } : {})}
          ref={(r) => (this.fileInput = r)}
          name={this.props.name}
          type="file"
          style={{ display: 'none' }}
          onChange={this._update}
        />
        {/*<RaisedButton
            label={this.props.label}
            secondary
            onMouseUp={this._onTouchButton}
          />*/}
        <Button
          loading={this.state.loading}
          className={this.props.className}
          btnClick={this._onTouchButton}
          btnText={this.props.label}
        />
        {!this._getError() ? (
          <div
            style={{
              display: 'inline-block',
              marginLeft: 20,
            }}
          >
            {this._getFilename()}
          </div>
        ) : (
          <Fragment>
            <div style={{ marginLeft: 10, marginRight: 10 }}>경고</div>
            <div
              style={{
                display: 'inline-block',
                color: '#ffa723',
              }}
            >
              {this._getError()}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

export default FileInput;
