import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import useStores from 'stores/UseStores';

import { StateApplyPartnerModal } from '../ModalContents/applyPartner/StateApplyPartnerModal';
import NotificationDropDown from './NotificationDropDown';
import styles from './TopMenuStyle.module.scss';
import UserDropDown from './UserDropDown';
const PartnerButton = observer(() => {
  const { authStore, applyPartnerStore, modalStore } = useStores();
  const router = useRouter();
  const { permissionUserType, logged, use_partner, companyCreatedAt } = authStore;
  const onClickApplyPartner = () => {
    let data = null;

    switch (applyPartnerStore.applyState) {
      case 'available':
        router.push('/applyPartner');
        break;
      case 'waiting':
        data = {
          modalComponent: <StateApplyPartnerModal state="waiting" />,
          modalTitle: '파트너 입점 신청 현황',
          modalStyle: { width: '460px' },
        };
        modalStore.modalOpen(data);
        break;
      case 'rejected':
        data = {
          modalComponent: <StateApplyPartnerModal state="rejected" />,
          modalTitle: '안내',
          modalStyle: { width: '460px' },
        };
        modalStore.modalOpen(data);
        break;
      default:
    }
  };

  if (
    logged &&
    !use_partner &&
    ['boss', 'manager'].includes(permissionUserType) &&
    dayjs().diff(companyCreatedAt, 'day') > 90
  ) {
    return (
      <div className={styles.apply_partner_button} onClick={onClickApplyPartner}>
        <img src="/images/apply_partner/join_partner.png" />
        <div>파트너 신청</div>
      </div>
    );
  }

  return <div className={styles.none_apply_partner_button} />;
});

const OnBoarding = () => {
  const { authStore } = useStores();
  const router = useRouter();

  return (
    <Logo isOnboarding>
      <div
        className={styles.logout_btn}
        onClick={() => {
          authStore.logout();
          router.push('/');
        }}
      >
        <p>로그아웃</p>
      </div>
    </Logo>
  );
};

const DefaultMenu = observer(() => {
  const { groupCostStore, authStore } = useStores();
  const router = useRouter();

  return (
    <Logo>
      {router.pathname !== '/notSupportedExploler' && (
        <Fragment>
          <PartnerButton />
          <div
            style={{ cursor: 'pointer' }}
            className={styles.common_header_util}
            onClick={() => {
              if (!groupCostStore.isCompareDailyCostList) {
                if (
                  confirm(
                    '페이지 이동 시 변경 사항이 저장되지 않습니다. 이동하시겠습니까?'
                  ).valueOf()
                ) {
                  router.push('/notice/guide?page=1');
                }
              } else {
                router.push('/notice/guide?page=1');
              }
            }}
          >
            <div>{authStore.logged ? '이용안내' : ''}</div>
          </div>
          <NotificationDropDown />
          <UserDropDown />
        </Fragment>
      )}
    </Logo>
  );
});

export const TopMenu = observer(() => {
  const router = useRouter();
  const isOnboarding = router.pathname.includes('onBoarding');

  if (isOnboarding) {
    return <OnBoarding />;
  }

  return <DefaultMenu />;
});

export const Logo = ({ children, isOnboarding }: { children: any; isOnboarding?: boolean }) => {
  return (
    <div className={styles.common_header_wrap}>
      <div className={`${styles.common_header_contents_area} ${styles.space_between}`}>
        <div
          className={styles.common_header_logo}
          onClick={() => {
            if (isOnboarding) {
              return;
            }

            window.location.href = '/';
          }}
          style={{ cursor: isOnboarding ? 'default' : 'pointer' }}
        >
          <img src="/images/headerImages/rencar_logo.png" />
        </div>
        {children}
      </div>
    </div>
  );
};
