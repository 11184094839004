import React, { useEffect, useRef, useState } from 'react';
import { stores } from 'utils/TestUtils/testHelperStore';

import Button from '../../../atoms/Button/index';
import Input from '../../../atoms/Input/index';
import styles from '../Address.module.scss';

interface AddressDetailProps {
  searchAddress: string;
  init: () => void;
  save: (detailAddress: string) => void;
  cancel: () => void;
}

const AddressDetail = ({ cancel, init, save, searchAddress }: AddressDetailProps) => {
  const [detailAddress, setDetailAddress] = useState<string | null>(null);

  const { modalStore } = stores;
  const detailAddressInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const focusToInput = () => {
      detailAddressInputRef.current?.focus();
    };

    focusToInput();
  }, []);

  return (
    <div className={styles.address_detail}>
      <div className={styles.research}>
        <Input type="text" propValue={searchAddress} disabled={true} />
        <Button btnText="재검색" className={styles.address_btn} btnClick={cancel} />
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();

          save(detailAddress);
        }}
        className={styles.form}
      >
        <Input
          type="text"
          placeholder="상세 주소 입력"
          className={styles.detail_address}
          handleChange={(value) => setDetailAddress(value)}
          propValue={detailAddress === null ? '' : detailAddress}
          maxLength={100}
          inputRef={detailAddressInputRef}
        />
        <div className={styles.btn_area}>
          <Button
            outline
            btnText={MESSAGE.CANCEL}
            btnClick={() => {
              init();
              modalStore.modalClose();
            }}
            type="button"
          />
          <Button btnText={MESSAGE.SAVE} type="submit" />
        </div>
      </form>
    </div>
  );
};

export default AddressDetail;
