// editPartner(params) {
//   return callApi({
//     method: 'POST',
//     url: `/v2/partners/${params.id}`,
//     data: params,
//   });
// },
import { _strEnum } from 'helpers/zodHelpers';
import { omit } from 'lodash';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
export const UpdatePartnerParam = z.object({
  id: z.number(),
  partner_name: z.string(),
  partner_contact: z.string(),
  partner_manager: z.string(),
  partner_address: z.string(),
});
export type UpdatePartnerParam = z.infer<typeof UpdatePartnerParam>;
type SuccessResponse = {
  success: true;
};
export type UpdatePartnerResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;
export async function updatePartner(params: UpdatePartnerParam) {
  const response = await callApi2.post<UpdatePartnerResponse>(
    `/v2/partners/${params.id}`,
    omit(params, ['id'])
  );

  return response;
}
