import { action, makeAutoObservable, makeObservable, observable } from 'mobx';

import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';
class ApplyPartnerStore implements BaseStore<ApplyPartnerStore> {
  applyState = null;

  setValue = (state) => {
    this.applyState = state;
  };

  constructor() {
    makeAutoObservable(this);
  }
  setObject = (data) => _setObject(this, data);
}
export default ApplyPartnerStore;
