import AuthStore from '../../stores/AuthStore';
import LoadingStore from '../../stores/LoadingStore';
import MenuStore from '../../stores/MenuStore';
import ModalStore from '../../stores/ModalStore';

export const stores = {
  authStore: new AuthStore(),
  loadingStore: new LoadingStore(),
  modalStore: new ModalStore(),
  menuStore: new MenuStore(),
};
