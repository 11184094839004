import React from 'react';
import useStores from 'stores/UseStores';

import Button from '../../../atoms/Button/index.tsx';
import Input from '../../../atoms/Input/index.tsx';
import comma from '../../../helpers/costComma';
import * as utils from '../../../utils/ContractUtils';
import RencarCheckBox from '../../RencarCheckBox/RencarCheckBox';
import Tooltip from '../../Tooltip';
import styles from './InputType.module.scss';

const _inputChange = async (props, _value) => {
  const { setData } = props;
  const { input, key, parser } = props.info;
  let value = _value;

  if (typeof parser === 'function') {
    value = parser(_value);
  }

  if (!value || (input.pattern && input.pattern.test(value))) {
    setData(key, value);
  }

  if (!input.pattern) {
    setData(key, value);
  }
};

const renderfuelDistance = (isConnect, info, stateData) => {
  if (!isConnect) {
    return null;
  }

  if (stateData.rent_car_number) {
    if (info.connectFuel) {
      const gas = stateData?.fuelRemainPct || stateData?.gas_charge || '-';

      return <p className={styles.add_small_text}>{`현재 유류량 : ${gas ? `${gas}%` : '-'}`}</p>;
    }

    if (info.connectDistance) {
      let distance = stateData?.totalMileage || stateData?.driven_distance || '-';

      if (typeof distance === 'number') {
        return <p className={styles.add_small_text}>{`현재 주행거리 : ${comma(distance)}km`}</p>;
      }
    }
  }

  return null;
};

const InputType = (props) => {
  const { info, propValue, setData, stateData } = props;
  const { input, btn, key } = props.info;
  let insuranceCompany = stateData?.insurance_company || stateData?.claim_insurance;
  let isDivided = utils.getRegInputType(propValue);

  if (insuranceCompany) {
    isDivided = utils.getRegInputType(insuranceCompany);
  }

  const { authStore } = useStores();

  const propValueParser = (value) => {
    if (value === 0) {
      return 0;
    }

    if (!value) {
      return '';
    }

    return value;
  };

  if (input) {
    return (
      <div className={[styles.input_type_wrap]} style={input.wrapStyle}>
        {info.preUnit && <p style={{ marginRight: '5px' }}>{info.preUnit}</p>}
        <Input
          comma={input.comma}
          maxLength={input.maxLength}
          type={input.type}
          disabled={input.disabled}
          className={`${styles.input}`}
          placeholder={input.placeholder}
          inlineStyle={input.style}
          propValue={propValueParser(propValue)}
          handleChange={(value) => _inputChange(props, value)}
          handleBlur={(value) => input.onBlur && input.onBlur({ key, value })}
          dataset={info.key}
        />
        {info.unit && <p>{info.unit}</p>}
        {info.addInput &&
          !isDivided &&
          InputType({
            // 재귀
            info: info.addInput,
            setData,
            propValue: stateData[info.addInput.key],
            subInput: true,
          })}
        {btn && (
          <Button
            btnText={btn.name}
            inlineStyle={btn.style ? btn.style : {}}
            btnClick={btn.func}
            disabled={btn.disabled}
          />
        )}
        {info.memoBtn && (
          <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
            <Button outline className={info.memoBtn.style} onClick={() => info.memoBtn.func()}>
              {info.memoBtn.name}
            </Button>
            {info.memoBtn.description && (
              <div
                style={{
                  fontWeight: 400,
                  fontSize: 13,
                  color: '#929292',
                  marginLeft: 10,
                }}
              >
                *고객 관리 {'>'} 메모 내용
              </div>
            )}
          </div>
        )}
        {info.checkBox && (
          <RencarCheckBox
            text={info.checkBox.text}
            value={
              info.checkBox.valueReverseForBackEndData ? !info.checkBox.value : info.checkBox.value
            }
            // valueReverseForBackEndData : 체크 Boolean이 백엔드와 다를 때 사용 (Front의 경우 체크의 의미는 true인데 backend에서 false로 설계했을 때)
            // onChange={() => setData(info.checkBox.key, !info.checkBox.value)}
            onChange={() => {
              if (info?.checkBox?.func) {
                info.checkBox.func();
              } else {
                setData(info.checkBox.key, !info.checkBox.value);
              }
            }}
            containerStyle={info.checkBox.containerStyle}
            checkStyle={info.checkBox.checkStyle}
          />
        )}
        {info.addText && <p className={styles.add_text}>{info.addText}</p>}
        {info.addSmallText && <p className={styles.add_small_text}>{info.addSmallText}</p>}
        {renderfuelDistance(authStore.use_connect, info, stateData)}
        {info.checkBox?.tooltip && (
          <Tooltip
            message={info.checkBox.tooltip.message}
            position={info.checkBox.tooltip.position || 'bottom'}
            wrapStyle={info.checkBox.tooltip.wrapStyle}
            style={info.checkBox.tooltip.style}
            childrenWrapStyle={info.checkBox.tooltip.childrenWrapStyle}
            triangle={{ top: '0px', left: '64px', ...info.checkBox.tooltip.triangleStyle }}
          >
            <img
              style={info.checkBox.tooltip.imgStyle}
              className={styles.tooltip_icon}
              src={info.checkBox.tooltip.img}
            />
          </Tooltip>
        )}
        {info.child}
      </div>
    );
  }

  return null;
};

export default InputType;
