import Cookies from 'universal-cookie';

import { cookieDomain } from '../config.js';

const errorHandler = (responseData, status) => {
  const alert = typeof window !== 'undefined' ? window.alert : console.log;
  const statusCode = status ? status : responseData?.status_code;

  if (statusCode == 401) {
    const cookies = new Cookies();

    cookies.remove('jwt', {
      domain: cookieDomain,
      path: '/',
    });
    cookies.remove(`${process.env.API_ENV}-imsform-jwt`, {
      domain: cookieDomain,
      path: '/',
    });

    if (responseData.error === 'Invalid Token') {
      location.replace('/'); // alert이후 location.replace작성시 네트워크 요청 두 번 발생하여 alert 두 번 노출
      alert('정보가 변경되어 다시 로그인이 필요합니다. 다시 로그인 해주세요.');
    } else if (responseData.error === 'Authorization Required') {
      location.replace('/');
      alert('시간이 경과하여 자동 로그아웃 되었습니다. 다시 로그인 해주세요.');
    } else {
      location.replace('/');
      alert('시간이 경과하여 자동 로그아웃 되었습니다. 다시 로그인 해주세요.');
    }

    return;
  }

  if (statusCode == 413) {
    alert('서버로 전송되는 용량이 큽니다. 파일을 확인해주세요.');
    location.reload();

    return;
    // } else if (status == 424) {
    //   // const errorMessage = responseData.error.message;
    //   // alert(`이니시스 오류입니다.\n${errorMessage}`);
    //   // if (errorMessage.indexOf("계좌성명조회오류") == 0) {
    //   //   return 'account_name_error'
    //   // } else return;
  }

  if (statusCode == 433) {
    alert('사업자는 최대 10개까지 등록 가능합니다.');
    location.reload();

    return;
  }

  let detailMsg = `올바르지 않은 요청값`;
  let error114Msg = '';
  let error888Msg = `보험사 전산 연결 오류입니다.`;
  let errorMsg = '';

  switch (responseData?.error_no) {
    case 100:
      alert('중복된 아이디가 있습니다. \r\n확인해주세요');
      break;
    case 101:
      alert('허용되지 않는 사용자 입니다.');
      break;
    case 102:
    case 103:
      alert('입력하신 정보와 일치하는 계정이 없습니다. 로그인 정보를 확인해주세요.');
      break;
    case 104:
      alert('잘못된 호출입니다.');
      break;
    case 105:
      if (Boolean(responseData.error) && Boolean(responseData.error.data)) {
        if (typeof responseData.error.data === 'string') {
          alert(responseData.error.data);
        }

        if (typeof responseData.error.data === 'object') {
          alert(JSON.stringify(responseData.error.data));
        }

        break;
      }

      alert('올바르지 않은 값이 포함되어 있습니다.');
      break;
    case 106:
      alert('항목에 올바르지 않은 타입이 포함되어있습니다');
      break;
    case 107:
      alert('필수값 확인 후, 파일을 다시 등록해 주세요.');
      break;
    case 108:
      alert('허용되지 않은 사용자 형식 입니다.');
      break;
    case 109:
      alert('필수항목이 빠졌습니다');
      break;
    case 110:
      alert('권한이 없습니다.');
      break;
    case 111:
      alert('페이지를 찾을수가 없습니다.');
      break;
    case 112:
      alert('안심번호 관련 실패 오류 입니다.');
      break;
    case 113:
      alert('working code를 찾을수 없습니다.');
      break;
    case 114:
      if (Boolean(responseData.error) && Boolean(responseData.error.data)) {
        error114Msg = ` (${responseData.error.data})`;
      }

      alert(`보험사 측의 서버 오류가 발생했습니다.${error114Msg}`);
      break;
    case 115:
      alert('PDF변환과정에 오류가 발생했습니다.');
      break;
    case 116:
      alert('이미지 파일 저장에 실패했습니다.');
      break;
    case 117:
      alert('사업자 등록 갯수 제한을 초과했습니다.');
      break;
    case 121:
      alert('제안내용 등록 갯수 제한을 초과했습니다.');
      break;
    case 128:
      alert('초기설정이 필요한 유저이므로, 초기설정 페이지로 이동합니다.');
      location.replace('/onBoarding/register');
      break; // 온보딩 전용 에러메시지
    case 132:
      alert('캐시 / 포인트가 부족합니다.\n캐시 관리 > 입금 안내를 확인해 주세요.');
      break;
    case 135:
      alert(`${responseData.error.data}`);
      break;
    case 136:
      alert(`${responseData.error.message}`);
      break;
    case 141:
      alert(
        '고객 과실이 확정되지 않았습니다. 요청자에게 과실확정을 요청해주세요. 확정이 되어야만 반납처리가 가능해 집니다. 문의사항은 렌카 운영팀에 꼭 연락 바랍니다. 1600-8348'
      );
      break;
    case 142:
      alert(
        '이미 요금이 등록되었거나 삭제된 차량 그룹이 포함되어 있습니다. 문의사항은 렌카 운영팀에 꼭 연락 바랍니다. 1600-8348'
      );
      break;
    case 148:
      alert('핸드폰 번호는 팩스번호로 사용 할 수 없습니다. 팩스 번호를 확인해주세요.');
      break;
    case 152:
      alert(responseData?.error?.value);
      break;
    case 153:
      alert('탁송지 별칭이 중복됩니다. 다시 입력해주세요');
      break;
    /**
     * 서버의 에러 생성 함수의 기본값이 154로 사용중.
     * 여러 메세지들이 154번으로 내려오고 있기 때문에 responseData?.error?.data로 수정.
     * 리뷰 반영하여 방어코드로 data 없을 경우 기존 문구 노출.
     */
    case 154:
      alert(responseData?.error?.data ?? '다른 고객과 중복됩니다.');
      break;
    case 162:
      alert('결제 가능 시간을 초과했습니다. 탁송 요금을 다시 조회 후 시도해 주세요.');
      break;
    // case 163:
    // let error163Msg = '';
    // if (!!responseData.error && !!responseData.error.data) {
    //   error163Msg = `(${responseData.error.data})`;
    // }
    // alert(`${error163Msg}`);
    // break;
    // case 202: alert('타지점 또는 현재 지점에서 기존에 등록된 차량번호가 있습니다. 중복 등록은 불가능합니다.'); break;
    case 164:
      const errorData = responseData.error;
      let apiTypeText = '삭제';

      if (errorData.from.includes('trade-logs')) {
        apiTypeText = '매각/폐차 처리';
      }

      if (errorData.count > 0 || errorData.schedules.length > 0) {
        errorMsg = `차량이 사용(예약) 중이므로 ${apiTypeText} 불가합니다.
아래 요청, 계약서, 스케줄을 확인 후 다시 시도해 주세요.
\n총 ${errorData.count}건
요청번호: `;

        if (errorData.requests.length > 0) {
          errorData.requests.map((requestId) => (errorMsg += `${requestId},`));
          errorMsg = errorMsg.slice(0, -1);
        } else {
          errorMsg += '-';
        }

        errorMsg += '\r\n연번: ';

        if (errorData.contracts?.length > 0) {
          errorData.contracts.map((item, i) => {
            if (i !== 0) {
              errorMsg += '        ';
            }

            errorMsg += `${item.type} ${item.id} (계약서# ${item.detail_id})\r`;
          });
        } else {
          errorMsg += '-';
        }

        errorMsg += '\r\n스케줄번호: ';

        if (errorData.schedules?.length > 0) {
          const scheduleList = errorData.schedules.join(', ');

          errorMsg += scheduleList;
        } else {
          errorMsg += '-';
        }
      } else {
        errorMsg = `차량이 사용 중(수리, 직원 사용)이므로 ${apiTypeText}가 불가합니다.\r\n대기 상태로 변경 후 다시 시도해 주세요.`;
      }

      alert(errorMsg);
      break;
    case 202:
      let errorMsg = '이미 등록된 차량번호 입니다.';

      if (responseData.error.data.length > 0) {
        if (responseData.error.data.includes('기존 등록 차량:')) {
          return alert(responseData.error.data);
        }

        if (responseData.error.data.includes('차량번호:')) {
          return alert(responseData.error.data);
        }

        if (responseData.error.data?.join(', ')) {
          const carNumbers = responseData.error.data?.join(', ');

          errorMsg = `기존에 등록된 차량은 등록할 수 없습니다. 신규 차량만 등록해주세요. 기존 등록차량: ${carNumbers}`;
        }
      }

      alert(errorMsg);
      break;
    case 230:
      alert('차량이 조회되지 않습니다. 차량번호를 확인해 주세요.');
      break;
    case 220:
      alert('반납시간은 배차시간보다 빠를 수 없습니다.');
      break;
    case 221:
      alert('현재시간보다 미래시간을 설정할 수 없습니다');
      break;
    case 700:
      alert('소속된 지점이 없습니다.');
      break;
    case 409:
      alert('캐시 / 포인트가 부족합니다.\n캐시 관리 > 입금 안내를 확인해 주세요.');
      break;
    case 416:
      // eslint-disable-next-line no-case-declarations
      let error416Msg = '';

      if (Boolean(responseData.error) && Boolean(responseData.error.message)) {
        error416Msg = `${responseData.error.message}`;
      }

      alert(`올바르지 않은 값이 포함되어 있습니다.\n(${error416Msg})`);
      break;
    case 417:
      alert('1개 이상의 차고지가 존재해야 합니다.');
      break;
    case 430:
      alert('올바르지 않은 엑셀 양식 입니다.');
      break;
    case 420:
      alert('렌카요청과 계약서가 연결되어 있는 건 입니다.');
      break;
    case 421:
      alert('이미 10개의 제안이 등록되어 더이상 제안할 수 없습니다.');
      break;
    case 422:
      alert('해당 요청의 상태가 변경되어 제안할 수 없습니다.');
      break;
    case 431: // 기타 계약사항 제목 중복 - 제목은 중복으로 등록할 수 없습니다.
    case 432:
    case 434: // 심사중인 건만 결과를 통보할 수 있습니다.
      alert(responseData.error?.message);
      break;
    case 437:
      alert(responseData.error?.message); // 고객 서명 대기 중인 계약서가 있습니다. 고객 서명 완료 후 다시 시도해 주세요.
      break;
    case 888:
      if (Boolean(responseData.error) && Boolean(responseData.error?.message)) {
        error888Msg = responseData.error?.message;
      }

      alert(`보험사 측의 서버 오류가 발생했습니다.${error888Msg}`);
      break;
    case 600:
      alert(`${responseData.error?.message}\r\n${responseData.error?.data}`);
      break;
    case 1001:
      if (responseData.error) {
        alert(responseData.error?.message); // 고객이 이미 결제 진행중입니다. 제안 수정 및 취소가 불가능합니다.
      }

      break;
    case 1005:
      if (responseData.error) {
        alert(responseData.error?.data); // 고객이 이미 결제 진행중입니다. 제안 수정 및 취소가 불가능합니다.
      }

      break;
    /**
     * 1009
     * 그룹 삭제시 월렌트 콜건 배차중 확인
     * 월렌트 콜건 배차중인 차량이 있어서 그룹 삭제가 불가합니다. 확인 후 다시 시도해 주세요.
     *
     * 1010
     * 차량 스케줄 월렌트 콜건 연장 확인 건 이동시 에러
     * 고객 결제가 완료되어 차량을 변경할 수 없습니다. 차량 변경이 필요한 경우, 연장 계약서 작성 후 교체계약서를 작성해 주세요.
     *
     * 1011
     * 월렌트 콜건 연장 결제 완료시 반납 불가
     * 고객 연장 결제가 완료되어 반납 처리가 불가합니다. 반납 처리가 필요하신 경우 연장계약서 작성 후 반납을 진행해 주세요.
     */
    case 1009:
    case 1010:
    case 1011:
      alert(responseData.error?.data);
      break;
    default:
      if (hasErrorMessage(responseData.error)) {
        alertNotHandledErrorMessage(responseData?.error);

        return;
      }

      alert(
        `알수없는 에러가 발생했습니다. \r\n상태:${statusCode} ${
          responseData?.error_no ? `에러코드:${responseData?.error_no}` : ''
        } `
      );
  }
};

const hasErrorMessage = (responseDataError) => {
  if (!responseDataError) {
    return false;
  }

  return (
    typeof responseDataError.data === 'string' || typeof responseDataError.message === 'string'
  );
};

/** case가 지정이 안됐지만 서버에서 지정한 에러 메세지가 있다면 노출 */
const alertNotHandledErrorMessage = (responseDataError) => {
  if (typeof responseDataError.data === 'string') {
    alert(responseDataError.data);

    return;
  }

  alert(responseDataError.message);
};

export default errorHandler;
