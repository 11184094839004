import find from 'lodash/find';
import React, { Component, Fragment } from 'react';

import { labelParser } from '../../utils/DataParser';
import Select from '../Select';
import styles from './Radio.module.scss';

interface ComponentProps {
  data: Array<any>;
  dataObj: any;
  matchData: any;
  selectRadioProps: any;
  keyName: string;
  disabled: boolean;
  isDivideData: boolean;
  dividedPoint: number;
  radioChange: Function;
  radioWrapStyle?: React.CSSProperties;
  addContent?: any;
}

class Radio extends Component<ComponentProps> {
  isEmptyCheck = (value: any) => {
    if (value === null || value === undefined) return true;

    if (typeof value === 'string' && value.trim() === '') return true;

    if (Array.isArray(value) && value.length === 0) return true;

    return false;
  };

  checked = (item: Readonly<any>) => {
    const { dataObj, keyName, matchData } = this.props;

    if (
      (keyName === 'insurance_company' && item.match === 'etc') ||
      (keyName === 'prepayment_term' && item.match === 'pre_fee1')
    ) {
      // 보험사에서 기타 셀렉트일때 활성화
      const findEtc = find(matchData, { value: dataObj[keyName] });

      if (findEtc) return true;
    }

    if (String(dataObj[keyName]) === String(item.value)) return true;

    return false;
  };

  renderRadio = (item: Readonly<any>) => {
    const { radioChange, keyName, disabled } = this.props;
    const { match, label, value, callback, isViewSelect } = item;
    const itemDisabled = item.disabled;

    return (
      <Fragment>
        <input
          type="radio"
          id={match}
          name={keyName}
          data-input={keyName}
          value={value}
          onChange={(e) => {
            this.setState({ value: e.target.value });
            radioChange(e.target.value);

            if (Boolean(callback) && typeof callback === 'function') {
              callback();
            }
          }}
          checked={this.checked(item)}
          disabled={this.checked(item) ? false : disabled || itemDisabled}
        />
        <label htmlFor={match}>{labelParser(label)}</label>
        {Boolean(isViewSelect) && Boolean(this.props.selectRadioProps) && (
          <Select {...this.props.selectRadioProps} />
        )}
        {this.props.addContent && <article>{this.props.addContent}</article>}
      </Fragment>
    );
  };

  renderDividedData = () => {
    const { data, dividedPoint, radioWrapStyle } = this.props;
    const dividedGroup1 = data.slice(0, dividedPoint);
    const dividedGroup2 = data.slice(dividedPoint);

    return (
      <>
        {
          <div className={styles.dividedGroup1_wrap}>
            {dividedGroup1.map((item, i) => (
              <div className={styles.radio_wrap} style={radioWrapStyle || {}} key={i}>
                {this.renderRadio(item)}
              </div>
            ))}
          </div>
        }
        {
          <div className={styles.dividedGroup2_wrap}>
            {dividedGroup2.map((item, i) => (
              <div
                className={`${styles.radio_wrap} ${styles.dividend_wrap}`}
                style={radioWrapStyle || {}}
                key={i}
              >
                {this.renderRadio(item)}
              </div>
            ))}
          </div>
        }
      </>
    );
  };

  render() {
    const { data, radioWrapStyle, isDivideData } = this.props;

    if (isDivideData) return this.renderDividedData();

    return data.map((item, i) => {
      return (
        <div className={styles.radio_wrap} style={radioWrapStyle || {}} key={i}>
          {this.renderRadio(item)}
        </div>
      );
    });
  }
}

export default Radio;
