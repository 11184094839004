import dayjs from 'dayjs';
import { parseKoreanMoney } from 'helpers/parseKoreanWon';
import find from 'lodash/find';
import pull from 'lodash/pull';
import React from 'react';
import CommonStore from 'stores/CommonStore';

import * as MESSAGE from '../constants/stringTable';
import costComma from '../helpers/costComma';
import dateTimeParser from '../helpers/dateTimeParser';
import { carStatusData } from './CarUtils';
import { getParsedOrdererName } from './getParsedOrdererName';
import getInsuranceArray from './InsuranceUtils';
import { getOrdererPlatformView } from './PartnerUtils';

export const getStateKorean = (type) => {
  switch (type) {
    case 'my_location':
      return MESSAGE.MY_LOCATION_CALL;
    case 'responsing':
      return MESSAGE.RESPONSING;
    case 'responded':
      return MESSAGE.RESPONSING;
    case 'selected':
      return MESSAGE.COMPLETE_RESERVATION;
    case 'car_delivered':
      return MESSAGE.USING_CAR;
    case 'finished':
      return MESSAGE.COMPLETE_RETURN;
    case 'using_car':
      return MESSAGE.USING_CAR;
    case 'before_claim':
      return MESSAGE.WAIT_CLAIM;
    case 'pending_claim':
      return MESSAGE.PENDING_CLAIM;
    case 'send_claim':
      return MESSAGE.COMPLETE_CLAIM;
    case 'done_claim':
      return MESSAGE.COMPLETE_CONTRACT;
    case 'deposit_wait':
      return MESSAGE.WAIT_DEPOSIT;
    case 'deposit_done':
      return MESSAGE.COMPLETE_CONTRACT;
    case 'wait_extend':
      return MESSAGE.WAIT_EXTEND;
    default:
      return '-';
  }
};

export const defaultParser = (value) => {
  try {
    if (value) {
      return value;
    }

    return '-';
  } catch (error) {
    return '-';
  }
};

export const dateParser = (param, option) => {
  if (!param) {
    return '-';
  }

  if (option === 'hour') {
    return dateTimeParser({ date: param, format: 'HH' });
  }

  if (option === 'minute') {
    return dateTimeParser({ date: param, format: 'mm' });
  }

  return dateTimeParser({
    date: param,
    format: `${MESSAGE.DATE_DOT_SMALL} ${MESSAGE.TIME_HOUR_24}`,
  });
};

export const doubleLineDateParser = (dateInfo) => {
  if (!dateInfo) {
    return '-';
  }

  const date = dateTimeParser({ date: dateInfo, format: 'YY.MM.DD' });
  const time = dateTimeParser({ date: dateInfo, format: 'HH:mm' });

  return (
    <div style={{ lineHeight: '16px' }}>
      {`${date}`}
      <br></br>
      {`${time}`}
    </div>
  );
};

export const doubleDataParser = (data1, data2) => {
  if (!data1) {
    return defaultParser(data2);
  }

  if (!data2) {
    return defaultParser(data1);
  }

  return (
    <div style={{ lineHeight: '16px' }}>
      {`${defaultParser(data1)}`}
      <br></br>
      {`${defaultParser(data2)}`}
    </div>
  );
};

export const carOldParser = (value) => {
  try {
    if (Boolean(value) && dayjs(value).isValid()) {
      return dateTimeParser({ date: dayjs(value), format: 'YYYY-MM' });
    }

    return '-';
  } catch (error) {
    return '-';
  }
};

//희망 차종 길이가 길 때
export const carNameParser = (value) => {
  try {
    if (value) {
      const pattern_kor = /[ㄱ-ㅎ|ㅏ-ㅣ가-힣]/;
      let carNameLength = 0;

      for (let i = 0; i < value.length; i++) {
        if (pattern_kor.test(value[i])) {
          carNameLength += 2;
        } else {
          carNameLength++;
        }

        if (carNameLength > 12) {
          return `${value.substring(0, i)}...`;
        }
      }

      return value;
    }

    return '-';
  } catch (error) {
    return '-';
  }
};

export const locationParser = (class1, class2) => {
  let retrunClass1 = '-';
  let retrunClass2 = '';

  //parameter가 object일때,
  if (typeof class1 === 'object') {
    if (class1.class1) {
      retrunClass1 = class1.class1;

      if (class1.class2) {
        retrunClass2 = ` ${class1.class2}`;
      } else {
        retrunClass2 = ` ${MESSAGE.ALL}`;
      }
    }

    return retrunClass1 + retrunClass2;
  }

  if (typeof class1 === 'string' && typeof class2 === 'string') {
    if (class1) {
      retrunClass1 = class1;

      if (class2) {
        retrunClass2 = ` ${class2}`;
      } else {
        retrunClass2 = ` ${MESSAGE.ALL}`;
      }
    }

    return retrunClass1 + retrunClass2;
  }
};

export const faultRateParser = (value, meritz) => {
  try {
    //일반
    if (!meritz) {
      if (typeof value == 'number' && value !== -1) {
        if (value === 0) {
          return MESSAGE.NONE_FAULT;
        }

        return `${value}%`;
      }

      return MESSAGE.NONE_CONFIRM;
    }

    //메리츠요청
    const num = 100 - parseInt(value);
    let result;

    if (num > 0 && num <= 100) {
      result = `${num.toString()}%`;
    } else if (num >= 101) {
      result = '미확정';
    } else {
      result = num == 0 ? MESSAGE.NONE_FAULT : MESSAGE.NONE_CONFIRM;
    }

    return result;
  } catch (error) {
    console.log(error);

    return MESSAGE.NONE_CONFIRM;
  }
};

export const contractParser = (value) => {
  if (!value) {
    console.info('contract value is undefined');

    return '-';
  }

  if (value.length === 11) {
    return value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  if (value.length === 8) {
    return value.replace(/(\d{4})(\d{4})/, '$1-$2');
  }

  if (value.length === 12) {
    return value.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  // 기존에 처리 됐던 로직인데 8, 11, 12가 아니고 02가 아닐 때는 10자리로 맞추는듯?
  if (value.indexOf('02') !== 0) {
    return value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  // 02로 시작할 때의 처리
  if (value.length === 9) {
    return value.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
  }

  if (value.length === 10) {
    return value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
  }

  // 02면서 길이가 9와 10이 아니였을 때 추가된 처리.
  if (value.length <= 6) {
    return value.replace(/(\d{2})(\d*)/, '$1-$2');
  }

  return value.replace(/(\d{2})(\d{4})(\d*)/, '$1-$2-$3');
};

export const engineTypeParser = (value) => {
  try {
    if (value) {
      return `${value}cc`;
    }

    return '-';
  } catch (error) {
    return '-';
  }
};

export const insuranceJoinAgeParser = (value) => {
  if (value) {
    switch (value) {
      case '26':
      case 26:
        return '만 26세 이상';
      case '24':
      case 24:
        return '만 24세 이상';
      case '21':
      case 21:
        return '만 21세 이상';
      default:
        return '-';
    }
  } else {
    return '-';
  }
};

export const drivenDistanceParser = (value) => {
  try {
    if (value) {
      return `${value} km`;
    }

    return '-';
  } catch (error) {
    return '-';
  }
};

export const gasChargeParser = (value) => {
  try {
    if (value) {
      return `${value} %`;
    }

    return '-';
  } catch (error) {
    return '-';
  }
};

export const selfCarInsuranceParser = (value) => {
  try {
    if (value) {
      return MESSAGE.SIGN_UP;
    }

    return MESSAGE.NO_SIGN_UP;
  } catch (error) {
    return '-';
  }
};

export const selfCarInsuranceParserForNormalContractDetail = (data) => {
  const { self_car_insurance, car_insurance } = data;
  const insuranceCategory = {
    general: '가입 / 일반자차',
    premium: '가입 / 고급자차',
    super: '가입 / 완전자차',
  };

  try {
    if (self_car_insurance) {
      /**
       * 제주도 건일 경우 가입한 자차보험이 일반자차인지 고급자차인지 구분해서 노출
       */
      if (car_insurance?.is_jeju_insurance) {
        return insuranceCategory[car_insurance.category];
      }

      return MESSAGE.SIGN_UP;
    }

    return MESSAGE.NO_SIGN_UP;
  } catch (error) {
    return '-';
  }
};

export const rentalPeriodParser = (obj) => {
  if (obj.days !== null && obj.hours !== null) {
    return `${obj.days}일 ${obj.hours}시간`;
  }

  return '-';
};

export async function insuranceCompanyParser(
  registrationId,
  requestType,
  userCompany,
  stores,
  insuCompanies = null
) {
  const insuranceCompany =
    Array.isArray(insuCompanies) && insuCompanies.length > 0
      ? insuCompanies
      : await getInsuranceArray(stores);

  if (registrationId) {
    const splitArray = registrationId.split(' ');
    let finder;

    if (splitArray[0] === '기타' && splitArray[0] + splitArray[1] === '기타손해사정') {
      finder = find(insuranceCompany, { text: splitArray[0] + splitArray[1] });

      if (finder) {
        return finder.text;
      }

      if (requestType === 'insurance_rent_request') {
        return userCompany;
      }

      return null;
    }

    finder = find(insuranceCompany, { text: splitArray[0] });

    if (finder) {
      return finder.text;
    }

    if (requestType === 'insurance_rent_request' || requestType === 'meritz_rent_request') {
      return userCompany;
    }

    return null;
  }

  return '-';
}

export async function registrationIdParser(registrationId, stores, insuCompanies = null) {
  const insuranceCompany =
    Array.isArray(insuCompanies) && insuCompanies.length > 0
      ? insuCompanies
      : await getInsuranceArray(stores);

  if (registrationId) {
    const splitArray = registrationId.split(' ');
    let finder;
    let resultArray;

    if (splitArray[0] === '기타' && splitArray[0] + splitArray[1] === '기타손해사정') {
      finder = find(insuranceCompany, { text: splitArray[0] + splitArray[1] });

      if (finder) {
        pull(splitArray, splitArray[0], splitArray[1]);
        resultArray = splitArray.join(' ');

        return resultArray;
      }

      resultArray = registrationId;

      return resultArray;
    }

    finder = find(insuranceCompany, { text: splitArray[0] });

    if (finder) {
      pull(splitArray, splitArray[0]);
      resultArray = splitArray.join(' ');

      return resultArray;
    }

    resultArray = registrationId;

    return resultArray;
  }

  return '-';
}

export function registrationInfoParser(claimInsurance, registrationNum, showDefault = false) {
  if (showDefault) {
    return '-';
  }

  if (Boolean(claimInsurance) || Boolean(registrationNum)) {
    return `${claimInsurance} ${registrationNum || '-'}`;
  }

  return '-';
}

export const repairParser = (value) => {
  try {
    if (value) {
      return `${value}일`;
    }

    return '-';
  } catch (error) {
    console.log(error);

    return '-';
  }
};

export const feeInfoParser = (
  reqType,
  requestUserBelongTo,
  totalFee,
  customer_service = false,
  dealershipKor = ''
) => {
  try {
    switch (reqType) {
      case 'rent_request_accident':
        if (Boolean(requestUserBelongTo) && requestUserBelongTo === 'plus') {
          return `해당 요청건은 공차 플랫폼의 요청으로, 수수료가 20% 차감됩니다.`;
        }

        break;
      case 'auto_mobile_request':
        if (customer_service) {
          if (dealershipKor === '선인자동차') {
            return '해당 요청 건은 CS건으로 일비 55,000원이며, 무료 수수료가 적용됩니다.';
          }
        }

        if (totalFee != undefined) {
          if (totalFee == 0) {
            return `무료`;
          }

          if (Boolean(requestUserBelongTo) && requestUserBelongTo === 'plus') {
            return `해당 요청건은 공차 플랫폼의 요청으로, 수수료가 20% 차감됩니다.`;
          }

          return `해당 요청건은 자동차관련 종사자 요청으로, 수수료가 ${totalFee}% 차감됩니다.`;
        }

        return '-';

      case 'automobile':
        return '해당 요청건은 종사자단기 요청으로 수수료가 15% 차감됩니다.';
      default:
        return '-';
    }
  } catch (error) {
    console.log(error);

    return '-';
  }
};

//차량 현황
export const carStateParser = (state) => {
  try {
    const findedValue = find(carStatusData, { key: state });

    if (findedValue) {
      return findedValue.value;
    }

    return '-';
  } catch (error) {
    console.log(error);

    return '-';
  }
};

// 탁송 현황
export const consignStateParser = (state) => {
  try {
    switch (state) {
      case 'booking':
        return '요청';
      case 'waiting_car':
        return '탁송 대기';
      case 'using':
        return '운행중';
      case 'done':
        return '탁송 완료';
      case 'cancelled':
        return '취소';
      default:
        return '-';
    }
  } catch (error) {
    console.log(error);

    return '-';
  }
};

//계약서
export const rentTypeParser = (type) => {
  switch (type) {
    case 'daily':
      return MESSAGE.DAILY_RENTAL;
    case 'monthly':
      return MESSAGE.MONTHLY_RENTAL;
    case 'long_term':
      return MESSAGE.LONG_RENTAL;
    case 'insurance':
      return MESSAGE.INSURANCE;
  }
};

export const dDayParser = (value) => {
  try {
    if (value) {
      return `${value * -1}`;
    }

    return '-';
  } catch (error) {
    console.log(error);

    return '-';
  }
};

export const moneyParser = (value) => {
  try {
    if (value == 0) {
      return '0';
    }

    if (value) {
      return `${costComma(value)}`;
    }

    return '-';
  } catch (error) {
    console.log(error);

    return '-';
  }
};

export const permissionParser = (permission) => {
  switch (permission) {
    case null:
      return MESSAGE.BOSS;
    case 0:
      return MESSAGE.NORMAL_EMPLOYEE;
    case 1:
      return MESSAGE.CLAIM_EMPLOYEE;
    case 2:
      return MESSAGE.MANAGER;
    case 3:
      return MESSAGE.CAR_MANAGER;
    case 4:
      return MESSAGE.BOSS;
    default:
  }
};

export const boolParser = (bool) => {
  return bool ? 'O' : 'X';
};

export const fromParser = (from) => {
  switch (from) {
    case 'main':
      return MESSAGE.SEARCH_AT_MAIN;
    case 'contract':
      return MESSAGE.SEARCH_AT_CONTRACT;
    case 'using_car':
      return MESSAGE.SEARCH_AT_ALLOCATE_CAR;
    case 'before_claim':
      return MESSAGE.SEARCH_AT_WAIT_CLAIM;
    default:
  }
};

export const notiTypeParser = (item) => {
  const { request_app, objectType, field_service_request, title, messageType } = item;
  // console.log(item)
  const orderersData = CommonStore.orderers.filter(
    (order) => item.title.indexOf(order.name_kor) !== -1
  );

  if (request_app === 'renchul') {
    return 'renchul_request';
  } // 렌출

  if (orderersData.length > 0) {
    const orderer = orderersData[0];

    if (orderer.name === 'kakao') {
      return 'kakao';
    }

    return 'external';
  }

  if (title?.includes('스케줄')) {
    return 'notice';
  } // 추후 라벨 정리 필요..

  if (messageType === 'login_connect') {
    return 'notice';
  } // connect 연결

  switch (objectType) {
    case 'AutoMobileRequest':
      return 'auto_mobile_request'; //종사자
    case 'InsuranceRentRequest':
      if (field_service_request) {
        return 'field_service_request';
      }

      return 'insurance_rent_request'; //보험
    case 'MeritzRequest':
      return 'meritz_rent_request'; //메리츠보험
    case 'RentCompanyRentRequest':
      return 'insurance_rent_request'; //일반
    case 'RentRequestAccident':
      return 'rent_request_accident'; //일반
    case 'RepairShopRequest':
      return 'repair_shop_request'; // 협력공업사
    case 'Article':
    case 'article':
    case 'RencarClaim':
      return 'rencar_claim'; //보험
    case 'NormalContract':
      return 'normal_contract'; //일반
    case 'Notice':
      return 'notice'; //기타
    default:
      return '';
  }
};

export const notiHeadParser = (item) => {
  const { request_app, objectType, field_service_request, title, messageType } = item;
  const orderersData = CommonStore.orderers.filter(
    (order) => item.title.indexOf(order.name_kor) !== -1
  );

  if (request_app === 'renchul') {
    return '현장<br/>배차';
  } // 렌출

  if (orderersData.length > 0) {
    const orderer = orderersData[0];

    if (orderer.name === 'kakao') {
      return orderer.name_kor;
    }

    return '외부';
  }

  if (title?.includes('스케줄')) {
    return '스케줄';
  }

  if (messageType === 'login_connect') {
    return MESSAGE.NOTICE;
  } // connect 연결

  switch (objectType) {
    case 'AutoMobileRequest':
      return MESSAGE.AUTOMOBILE_USER; //종사자
    case 'RepairShopRequest':
      return '협력<br/>공업사'; // 협력공업사
    case 'InsuranceRentRequest': // 보험
      if (field_service_request) {
        return '현장<br/>배차';
      } // 보험 중 현장배차
    case 'RencarClaim': //보험
    case 'MeritzRequest':
      return MESSAGE.INSURANCE; //메리츠보험
    case 'RentCompanyRentRequest': //일반
    case 'RentRequestAccident': //일반
    case 'NormalContract':
      return MESSAGE.NORMAL; //일반
    case 'Article':
    case 'Notice':
      return MESSAGE.NOTICE; //공지
    default:
      return MESSAGE.ETC;
  }
};

export const notiTitleParser = (item) => {
  const dateRegex =
    /(\()(\d{4}(\.|-)(0[1-9]|1[012])(\.|-)(0[1-9]|[12][0-9]|3[01]) (0[0-9]|[12][0-9]):([0-5][0-9])(:[0-5][0-9])?)(\))/;

  if (item?.title?.match(dateRegex)) {
    return item.title?.replace(item?.title?.match(dateRegex)[0], '').trimEnd();
  }

  return item.title;
};

export const notiDateParser = (item) => {
  const dateRegex =
    /(\()(\d{4}(\.|-)(0[1-9]|1[012])(\.|-)(0[1-9]|[12][0-9]|3[01]) (0[0-9]|[12][0-9]):([0-5][0-9])(:[0-5][0-9])?)(\))/;

  if (item?.title?.match(dateRegex)) {
    return item?.title?.match(dateRegex)[2];
  }

  return null;
};

export const insuranceFeeParser = (value, nullOption) => {
  if (value) {
    return getIndemnficationFee(value);
  }

  return nullOption || '-';
};

const getTenThousnadStr = (cost) => {
  return cost == '0,000' ? '' : `${cost}만`;
};

export const getIndemnficationFee = (fee) => {
  const strFee = String(fee);

  if (strFee == '-1') {
    return MESSAGE.INFINITE;
  }

  if (strFee.includes('차량가액') && strFee.includes('%')) {
    return strFee;
  }

  if (strFee.indexOf('~') >= 0) {
    if (strFee.split('~')[0] === strFee.split('~')[1]) {
      return `${strFee.split('~')[0]}${MESSAGE.TEN_THOUSAND_WON}`;
    }

    return `${strFee}${MESSAGE.TEN_THOUSAND_WON}`;
  }

  if (strFee.indexOf('-') >= 0) {
    if (strFee.split('-')[0] === strFee.split('-')[1]) {
      return `${strFee.split('-')[0]}${MESSAGE.TEN_THOUSAND_WON}`;
    }

    return `${strFee}${MESSAGE.TEN_THOUSAND_WON}`;
  }

  if (strFee.length >= 5) {
    const index = strFee.length - 4;

    return (
      `${costComma(strFee.slice(0, index))}억` +
      ` ${
        getTenThousnadStr(costComma(strFee.slice(index, strFee.length))) === '0만'
          ? ''
          : getTenThousnadStr(costComma(strFee.slice(index, strFee.length)))
      }원`
    );
  }

  if (strFee.length == 0) {
    return MESSAGE.NOTHING;
  }

  return fee ? `${fee}${MESSAGE.TEN_THOUSAND_WON}` : MESSAGE.NOTHING;
};

/**
 * @summary 사업자등록번호 파서
 * @param {string} value
 * @returns {string}
 */
export const bizNumParser = (value) => {
  try {
    const first = value.substr(0, 3);
    const second = value.substr(3, 2);
    const third = value.substr(5, 5);

    return `${first}-${second}-${third}`;
  } catch (error) {
    return '-';
  }
};

/**
 * @summary 법인등록번호 파서
 * @param {string} corporateRegistrationNumber
 */
export const corporateRegistrationNumParser = (corporateRegistrationNumber) => {
  try {
    return `${corporateRegistrationNumber.slice(0, 6)}-${corporateRegistrationNumber.slice(6)}`;
  } catch (error) {
    // 법인등록번호가 string이 아닌값을 받을 때 파싱에 실패한다.
    console.info(
      `법인 등록 번호 파싱 실패. 법인 등록 번호: ${corporateRegistrationNumber}\n${error}`
    );

    return '-';
  }
};

export const abbreviation = (value, length) => {
  try {
    if (value.length > length) {
      return `${value.substr(0, length)}...`;
    }

    return value;
  } catch (error) {
    return '';
  }
};

export const maxLengthSeven = (value) => {
  try {
    if (value.length > 7) {
      return value.substr(0, 7);
    }

    return value;
  } catch (error) {
    return '';
  }
};

export const faultRateParserForModify = (value) => {
  try {
    if (typeof value === 'number') {
      if (value === 0) {
        return MESSAGE.NONE_FAULT;
      }
    } else if (value === null) {
      return '수정을 통해 선택';
    }

    return `${value}%`;
  } catch (error) {
    return '-';
  }
};

export const depositStringParser = (value) => {
  if (value) {
    return MESSAGE.COMPLETE_DEPOSIT;
  }

  return MESSAGE.NONE_DEPOSIT;
};

export const carNamesParser = (carNumbersArr) => {
  if (carNumbersArr.length > 0) {
    let rentCarNumbers = '';

    for (let i in carNumbersArr) {
      rentCarNumbers += `${carNumbersArr[i]} / `;
    }

    return rentCarNumbers.slice(0, -2);
  }

  return '-';
};

export const paymentHistoryNameParser = (name) => {
  if (!name) {
    return '-';
  }

  if (name.length > 7) {
    return `${name.substring(0, 6)}...`;
  }

  return name;
};

export const couponNameKoreanParser = (couponName) => {
  switch (couponName) {
    case 'superpass':
      return MESSAGE.SUPERPASS;
    default:
      return '-';
  }
};

export const couponDateParser = (date) => {
  if (date) {
    return dateTimeParser({ date, format: MESSAGE.DATE_DOT });
  }

  return '-';
};

export const DdayParser = (days) => {
  if (days) {
    return `D-${days}`;
  }

  return '-';
};

export const strValidParser = (string) => {
  if (string) {
    const trimString = String(string).trim();

    if (trimString) {
      return trimString;
    }
  }

  return '-';
};

export const faxStateLabelParser = (state, result) => {
  switch (state) {
    case 0:
    case 1:
    case 2:
      return '전송중';
    case 3:
      if (result === '802') {
        return '전송성공';
      }

      return '전송실패';
    default:
      return '-';
  }
};

export const dayOfWeekParser = (value) => {
  switch (value) {
    case 0:
      return '일';
    case 1:
      return '월';
    case 2:
      return '화';
    case 3:
      return '수';
    case 4:
      return '목';
    case 5:
      return '금';
    case 6:
      return '토';
    default:
      break;
  }
};

export const returnRequestParser = (contract) => {
  const { car_return_request_consulted, car_return_request_timepoint } = contract;

  let returnRequest = '-';
  let callSuccess = 'X';

  if (car_return_request_timepoint === null) {
    return '-';
  }

  if (car_return_request_timepoint) {
    returnRequest = 'O';
  }

  if (car_return_request_consulted) {
    callSuccess = 'O';
  }

  if (callSuccess === 'X') {
    return <span style={{ color: '#ff0000' }}>{`${returnRequest} / ${callSuccess}`}</span>;
  }

  return `${returnRequest} / ${callSuccess}`;
};

export const normalRentCallParser = (key, value, sub) => {
  try {
    switch (key) {
      case 'request_id':
        return `#${value}`;
      case 'orderer':
        return getOrdererPlatformView({ orderer: value }).label;
      case 'pickup_at':
      case 'dropoff_at':
      case 'start_at':
      case 'end_at':
        if (sub === 'date') {
          return `${dayjs(value).format('M/D')}/${dayOfWeekParser(dayjs(value).day())}`;
        }

        return dayjs(value).format('HH:mm');
      case 'original_customer_contact':
        return contractParser(value);
      case 'delivery_attempt':
        return '출발완료';
      case 'self_contract_info_state':
        return '고객정보 완료';
      case 'retrieve_check':
        return '회차확인완료';
      case 'direct_pickup':
        return '고객 직접 픽업';
      case 'direct_dropoff':
        return '고객 직접 반납';
      case 'none':
        return '-';
      default:
        return value;
    }
  } catch (error) {
    console.log(error);

    return '알수없음';
  }
};

export const stringBooleanParser = (value) => {
  if (value === 'false') {
    return false;
  }

  if (value === 'true') {
    return true;
  }

  return false;
};

export const periodParser = (start, end) => {
  let calStart = start;
  let calEnd = end;

  if (start < end) {
    calStart = end;
    calEnd = start;
  }

  const diffMinutes = dayjs(calStart).diff(dayjs(calEnd), 'minutes');
  let result = '';
  let calculateMinutes = diffMinutes;
  let days = null;
  let hours = null;
  let minutes = null;

  days = Math.floor(calculateMinutes / (60 * 24));
  calculateMinutes %= 60 * 24;
  hours = Math.floor(calculateMinutes / 60);
  minutes = calculateMinutes % 60;

  if (days) {
    result += `${days}일 `;
  }

  if (Boolean(hours) || (Boolean(days) && !hours && Boolean(minutes))) {
    result += `${hours}시간`;
  }

  if (minutes) {
    result += `${minutes}분`;
  }

  return result;
};

export const stringLengthOver30Parser = (_string) => {
  const isLengthOver30 = Boolean(_string?.length > 30);

  if (isLengthOver30) {
    const stringUpTo30 = _string.slice(0, 30);

    return `${stringUpTo30}...`;
  }

  return _string || '-';
};

export const labelParser = (label) => {
  switch (label) {
    case '하나(구 더케이)':
      return (
        <div>
          하나
          <span style={{ color: '#c5c0bc' }}>(구 더케이)</span>
        </div>
      );
    default:
      return label;
  }
};

export const carControlCenterTabParser = (tab) => {
  let content = {};

  switch (tab) {
    case 'evaluation':
      content = {
        title: MESSAGE.CAR_EVALUATION_TITLE,
        message: MESSAGE.CAR_EVALUATION_CONTENT,
      };
      break;
    case 'control':
      content = {
        title: MESSAGE.CAR_CONTROL_TITLE,
        message: MESSAGE.CAR_CONTROL_CONTENT,
      };
      break;
    case 'calculate':
      content = {
        title: MESSAGE.CAR_CALCULATE_TITLE,
        message: MESSAGE.CAR_CALCULATE_CONTENT,
      };
      break;
  }

  return content;
};

export const selfCarInsuranceLimitParser = (
  self_car_insurance_limit,
  self_car_insurance_limit_ratio
) => {
  if (!self_car_insurance_limit) {
    return '-';
  }

  return self_car_insurance_limit_ratio
    ? `차량가액 ${self_car_insurance_limit}%`
    : parseKoreanMoney(self_car_insurance_limit);
};

export const requestOrdererParser = (_orderer) => {
  const orderers = CommonStore.orderers;
  const orderer = find(orderers, { name: getParsedOrdererName(_orderer) });

  if (orderer) {
    return orderer.name_kor;
  }

  return '';
};

export const parseOrdererToColor = (orderer) => {
  if (orderer === 'partner') {
    return '#B7D3FF';
  }

  return '#ffdc4a';
};

export const diffDayHourMinute = (day1, day2) => {
  const fromDate = dayjs(day1);
  const toDate = dayjs(day2);
  const diffDay = toDate.diff(fromDate, 'day');
  const diffHour = toDate.diff(fromDate, 'hour');
  const diffMinutes = toDate.diff(fromDate, 'minute');

  const diffTime =
    `${diffDay !== 0 ? `${Math.abs(diffDay)}일 ` : ''}` +
    `${diffHour !== 0 ? `${Math.abs(diffHour) % 24}시간 ` : ''}` +
    `${Math.abs(diffMinutes) % 60 !== 0 ? `${Math.abs(diffMinutes) % 60}분` : ''}`;

  return { time: diffTime, type: diffMinutes > 0 };
};

/**
 * @name arrayElementCastingAllNumber
 * @description Array 내부 원소 타입을 Number 타입으로 캐스팅 합니다.
 */
export const arrayElementCastingAllNumber = (arr) => {
  if (Array.isArray(arr)) {
    return arr.map((_) => parseInt(_));
  }

  return arr;
};

export const cardTypeParser = (value) => {
  try {
    if (value === 'personal') {
      return '개인';
    }

    return '법인';
  } catch (error) {
    return '-';
  }
};
