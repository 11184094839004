import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
interface IDetail {
  id: number;
  period_id: number;
  weekend: boolean;
  cost: number;
  d1_cost: number;
  d3_cost: number;
  d5_cost: number;
  d7_cost: number;
  h1_cost: number;
  h6_cost: number;
  h12_cost: number;
}

export interface IModifyDailyCostParams {
  id: number; // 서버로보내지는 않음. url 에 쓰임
  name?: string;
  car_group_ids?: number[];
  details?: IDetail[];
}

type SuccessResponse = {
  id: number;
  success: boolean;
};
export type IModifyDailyCostResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function modifyDailyCost(params: IModifyDailyCostParams) {
  return callApi2.put<IModifyDailyCostResponse>(
    `/v2/group-cost-tables/daily/${params.id}`,
    omit(params, ['id'])
  );
}
