import React, { Component } from 'react';

import PageHeader from '../PageHeader/PageHeader';
import styles from './ContentsWrap.module.scss';

class ContentsWrap extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      className,
      children,
      title,
      contentsClassName,
      contentsStyle,
      subTitle,
      all,
      parentContentStyle,
      tabs,
      isModal,
      customRef,
    } = this.props;

    return (
      <div
        className={isModal ? styles.modal_contents_wrap : styles.contents_wrap}
        style={
          all ? { marginLeft: 0, minWidth: 1920 } : parentContentStyle ? parentContentStyle : {}
        }
        ref={customRef}
      >
        {(this.props.title || this.props.subTitle) && (
          <PageHeader
            className={`${styles.page_header} ${className ? className : {}}`}
            customTitle={title ? <h1 className={styles.page_header_title}> {title} </h1> : ''}
            subTitle={subTitle ? subTitle : ''}
            tabs={tabs ? tabs : null}
          />
        )}
        <div
          className={`${styles.contents_wrap_contents} ${contentsClassName || ''}`}
          style={contentsStyle ? contentsStyle : {}}
        >
          {children}
        </div>
      </div>
    );
  }
}

export default ContentsWrap;
