import styled from 'styled-components';

export const Entry = () => {
  return (
    <section>
      <Title>주소 검색 TIP</Title>

      <ExampleListWrapper>
        {[
          { title: '도로명 + 건물번호', example: '(예: 아차산로 355)' },
          { title: '지역동(동/리) + 번지', example: '(예: 자양동 779)' },
          { title: '지역동(동/리) + 건물명', example: '(예: 자양동 타워더모스트광진아크로텔)' },
        ].map(({ title, example }) => (
          <li key={title}>
            <span>{title}</span>
            &nbsp;
            <Example>{example}</Example>
          </li>
        ))}
      </ExampleListWrapper>
    </section>
  );
};

const Title = styled.h2`
  margin: 0;
  margin-top: 20px;

  font-size: 16px;
  color: #0881fd;
`;

const ExampleListWrapper = styled.ul`
  margin: 0;
  padding: 0;

  display: flex;
  flex-direction: column;
  gap: 12px;

  font-size: 14px;

  list-style: none;
  padding: 12px 0 0;
`;

const Example = styled.span`
  color: #929292;
`;
