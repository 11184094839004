import { assign } from 'lodash';
import { action, makeAutoObservable, makeObservable, observable, toJS } from 'mobx';

import { getRegInputType } from '../utils/ContractUtils';
import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';

class ContractStore implements BaseStore<ContractStore> {
  claim_id = null;
  rent_request_id = null;
  schedule_id = null; // 연결할 예약건 id
  reservation_id = null; // 예약 id
  additional = false;
  customer_car = null;
  customer_car_number = null;
  customer_car_verified = true;
  customer_car_engine = null;
  customer_car_age = null;
  customer_name = null;
  customer_driver_license = null;
  customer_id = null;
  customer_id_number = null;
  customer_id_number1 = null;
  customer_id_number2 = null;
  customer_contact = null;
  customer_driver_type = null;
  customer_license_limit = null;
  customer_license_date = null;
  customer_address = null;
  second_driver_name = null;
  second_driver_contact = null;
  second_driver_license = null;
  second_driver_type = null;
  second_driver_license_limit = null;
  second_driver_license_date = null;
  second_driver_address = null;
  second_driver_id = null;
  second_driver_id_number = null;
  second_driver_id_number1 = null;
  second_driver_id_number2 = null;
  isSecondLicenseSerarched = false; //프론트에서만 씀
  rent_car_name = null;
  rent_car_number = null;
  driven_distance = null;
  self_car_insurance = true;
  indemnfication_fee = 50;
  indemnfication_fee1 = null;
  indemnfication_fee2 = null;
  self_car_insurance_limit = 3000;
  oil_type = null;
  gas_charge = 25;
  rent_company_car_id = null;
  industrial_company = null;
  industrial_company_tel = null;
  registration_id = null;
  insurance_company = null;
  has_second_driver = false;
  indemnfication_fee_button_type = true;
  delivered_date = null;
  delivered_hour = null;
  delivered_minute = null;
  partner_info = null;
  sales_employee = null;
  memo = null;
  insurance_memo = null;
  customer_memo = null;
  driver_memo = null;
  second_driver_memo = null;
  people_indemnfication_fee = null;
  object_indemnfication_fee = null;
  self_indemnification_fee = null;
  isLicenseSerarched = false; //프론트에서만 씀
  license_certificated = false;
  license_certificated_code = null;
  second_driver_license_certificated = false;
  second_driver_license_certificated_code = null;
  checked_auto_up_info = false;
  insurance_age = '26';
  insurance_person_limit = -1;
  person_limit_select_type = true;
  object_limit_select_type = true;
  insurance_object_limit = 10000;
  insurance_self_limit = 1500;
  insurance_self_dead_limit = 1500;
  self_limit_select_type = true;
  suspension_fee = null;
  duplicatedContractList = [];
  attachments = null;
  dropoff_address = null;
  // 차 교체 계약서 반납정보
  return_datetime = null;
  return_gas_charge = null;
  driven_distance_upon_return = null;
  fuelRemainPct = null;
  totalMileage = null;
  use_connect = null;
  fuel_cost = null;

  is_corporation = false;
  corporation_name = null;
  corporation_business_number = null;
  corporation_number = null;
  corporation_number1 = null;
  corporation_number2 = null;
  corporation_address = null;
  corporation_manager_name = null;
  corporation_manager_contact = null;
  is_send_recommender_message = false;

  use_self_car_insurance_percentage = false;

  isExistSelfCarInsuranceData = false;
  isExistComprehensiveInsuranceData = false;

  isSameAsFirstDriverAddress = false; // 제2운전자 주소가 제1운전자와 동일함 (체크박스)

  constructor() {
    makeAutoObservable(this);
  }

  initContractStore = () => {
    const resetedObject = Object.fromEntries(
      Object.entries(toJS(new ContractStore())).filter(
        ([key, value]) => typeof value !== 'function'
      )
    ) as ClassVariableType<ContractStore>;

    this.setObject(resetedObject);

    return this;
  };

  setValue = (data, callback?: () => void) => {
    this[data.key] = data.value === '' ? null : data.value;

    if (callback) {
      callback();
    }
  };

  setObject = (data) => _setObject(this, data);

  hasSecondDriver = () => {
    this.has_second_driver = !this.has_second_driver;
  };

  getContractData = () => {
    return {
      rent_request_id: this.rent_request_id,
      customer_car: this.customer_car,
      customer_car_number: this.customer_car_number,
      customer_car_verified: this.customer_car_verified,
      customer_car_engine: this.customer_car_engine,
      customer_car_age: this.customer_car_age,
      customer_name: this.customer_name,
      customer_contact: this.customer_contact,
      customer_address: this.customer_address,
      customer_id_number: this.customer_id_number,
      customer_id_number1: this.customer_id_number1,
      customer_id_number2: this.customer_id_number2,
      customer_driver_license: this.customer_driver_license,
      customer_driver_type: this.customer_driver_type,
      customer_license_limit: this.customer_license_limit,
      customer_license_date: this.customer_license_date,
      second_driver_name: this.second_driver_name,
      second_driver_contact: this.second_driver_contact,
      second_driver_address: this.second_driver_address,
      second_driver_id_number: this.second_driver_id_number,
      second_driver_id_number1: this.second_driver_id_number1,
      second_driver_id_number2: this.second_driver_id_number2,
      second_driver_license: this.second_driver_license,
      second_driver_type: this.second_driver_type,
      second_driver_license_limit: this.second_driver_license_limit,
      second_driver_license_date: this.second_driver_license_date,
      rent_car_name: this.rent_car_name,
      rent_car_number: this.rent_car_number,
      self_car_insurance: this.self_car_insurance,
      self_car_insurance_limit: this.self_car_insurance_limit,
      indemnfication_fee: this.indemnfication_fee,
      indemnfication_fee1: this.indemnfication_fee1,
      indemnfication_fee2: this.indemnfication_fee2,
      driven_distance: this.driven_distance,
      oil_type: this.oil_type,
      gas_charge: this.gas_charge,
      insurance_company: this.insurance_company,
      registration_id: this.registration_id,
      industrial_company: this.industrial_company,
      industrial_company_tel: this.industrial_company_tel,
      delivered_date: this.delivered_date,
      delivered_hour: this.delivered_hour,
      delivered_minute: this.delivered_minute,
      memo: this.memo,
      insurance_memo: this.insurance_memo,
      customer_memo: this.customer_memo,
      people_indemnfication_fee: this.people_indemnfication_fee,
      object_indemnfication_fee: this.object_indemnfication_fee,
      self_indemnification_fee: this.self_indemnification_fee,
      insurance_age: this.insurance_age,
      insurance_person_limit: this.insurance_person_limit,
      insurance_object_limit: this.insurance_object_limit,
      insurance_self_limit: this.insurance_self_limit,
      insurance_self_dead_limit: this.insurance_self_dead_limit,
      suspension_fee: this.suspension_fee,
      attachments: this.attachments,
      return_datetime: this.return_datetime,
      return_gas_charge: this.return_gas_charge,
      driven_distance_upon_return: this.driven_distance_upon_return,
      fuelRemainPct: this.fuelRemainPct,
      totalMileage: this.totalMileage,
      use_connect: this.use_connect,
      fuel_cost: this.fuel_cost,
      is_corporation: this.is_corporation,
      corporation_name: this.corporation_name,
      corporation_business_number: this.corporation_business_number,
      corporation_number1: this.corporation_number1,
      corporation_number2: this.corporation_number2,
      corporation_address: this.corporation_address,
      corporation_manager_name: this.corporation_manager_name,
      corporation_manager_contact: this.corporation_manager_contact,

      use_self_car_insurance_percentage: (this.use_self_car_insurance_percentage = false),
      schedule_id: this.schedule_id,
      reservation_id: this.reservation_id,
    };
  };

  initCorporationInfo = () => {
    this.corporation_name = null;
    this.corporation_business_number = null;
    this.corporation_number1 = null;
    this.corporation_number2 = null;
    this.corporation_address = null;
    this.corporation_manager_name = null;
    this.corporation_manager_contact = null;
  };

  getSubmitData = () => {
    const data = {
      rent_request_id: this.rent_request_id,
      customer_car: this.customer_car,
      customer_car_number: this.customer_car_number,
      customer_car_verified: this.customer_car_verified,
      customer_car_engine: this.customer_car_engine,
      customer_car_age: this.customer_car_age,
      customer_name: this.customer_name,
      customer_contact: this.customer_contact,
      customer_address: this.customer_address,
      customer_id_number: this.customer_id_number,
      customer_id_number1: this.customer_id_number1,
      customer_id_number2: this.customer_id_number2,
      customer_driver_license: this.customer_driver_license,
      customer_driver_type: this.customer_driver_type,
      customer_license_limit: this.customer_license_limit,
      customer_license_date: this.customer_license_date,
      fuel_cost: this.fuel_cost,
      rent_car_name: this.rent_car_name,
      rent_car_number: this.rent_car_number,
      oil_type: this.oil_type,
      gas_charge: this.gas_charge,
      rent_company_car_id: this.rent_company_car_id,
      driven_distance: this.driven_distance,
      insurance_company: this.insurance_company,
      registration_id: this.registration_id,
      industrial_company: this.industrial_company,
      industrial_company_tel: this.industrial_company_tel,
      self_car_insurance: this.self_car_insurance,
      delivered_at: `${this.delivered_date}-${this.delivered_hour}-${this.delivered_minute}-0`,
      recommender_id: this.partner_info ? this.partner_info.id : null,
      sales_employee_id: this.sales_employee ? this.sales_employee.id : null,
      web_request: true,
      people_indemnfication_fee: this.people_indemnfication_fee,
      object_indemnfication_fee: this.object_indemnfication_fee,
      self_indemnification_fee: this.self_indemnification_fee,
      indemnfication_fee: this.indemnfication_fee,
      checked_auto_up_info: this.checked_auto_up_info,
      insurance_age: this.insurance_age,
      insurance_person_limit: this.insurance_person_limit,
      insurance_object_limit: this.insurance_object_limit,
      insurance_self_limit: this.insurance_self_limit,
      insurance_self_dead_limit: this.insurance_self_dead_limit,
      suspension_fee: this.suspension_fee,
      attachments: this.attachments && this.attachments.filter((item) => Boolean(item)),
      dropoff_address: this.dropoff_address,
      is_corporation: this.is_corporation,
      self_car_insurance_limit: this.self_car_insurance_limit,
      is_send_recommender_message: this.is_send_recommender_message,

      use_self_car_insurance_percentage: this.use_self_car_insurance_percentage,
      schedule_id: this.schedule_id,
      reservation_id: this.reservation_id,
    };

    if (!this.self_car_insurance) {
      this.indemnfication_fee = null;
      this.self_car_insurance_limit = null;
      assign(data, { indemnfication_fee: null });
      data.self_car_insurance_limit = null;
    }

    if (this.self_car_insurance) {
      if (Boolean(this.indemnfication_fee1) && Boolean(this.indemnfication_fee2)) {
        assign(data, {
          indemnfication_fee: `${this.indemnfication_fee1}~${this.indemnfication_fee2}`,
        });
      }

      if (!this.indemnfication_fee1 && !this.indemnfication_fee2 && this.indemnfication_fee) {
        assign(data, { indemnfication_fee: this.indemnfication_fee });
      }
    }

    if (this.rent_request_id) {
      this.people_indemnfication_fee = null;
      this.object_indemnfication_fee = null;
      this.self_indemnification_fee = null;
    }

    if (this.is_corporation) {
      assign(data, {
        corporation_name: this.corporation_name,
        corporation_business_number: this.corporation_business_number,
        corporation_number: this.corporation_number
          ? `${this.corporation_number1}-${this.corporation_number2}`
          : null,
        corporation_address: this.corporation_address,
        corporation_manager_name: this.corporation_manager_name,
        corporation_manager_contact: this.corporation_manager_contact,
      });
    } else {
      assign(data, {
        corporation_name: null,
        corporation_business_number: null,
        corporation_number: null,
        corporation_address: null,
        corporation_manager_name: null,
        corporation_manager_contact: null,
      });
    }

    if (this.license_certificated) {
      //진위여부 했을경우

      assign(data, {
        license_certificated: this.license_certificated,
        license_certificated_code: this.license_certificated_code,
      });
    }

    if (this.has_second_driver == true) {
      assign(data, {
        second_driver_name: this.second_driver_name,
        second_driver_contact: this.second_driver_contact,
        second_driver_address: this.second_driver_address,
        second_driver_id_number1: this.second_driver_id_number1,
        second_driver_id_number2: this.second_driver_id_number2,
        second_driver_license: this.second_driver_license,
        second_driver_type: this.second_driver_type,
        second_driver_license_limit: this.second_driver_license_limit,
        second_driver_license_date: this.second_driver_license_date,
      });
    }

    if (this.second_driver_license_certificated) {
      //제 2운전자 진위여부 했을경우
      assign(data, {
        second_driver_license_certificated: this.second_driver_license_certificated,
        second_driver_license_certificated_code: this.second_driver_license_certificated_code,
      });
    }

    if (this.additional == true) {
      assign(data, {
        additional: this.additional,
        virtual_request_id: this.claim_id,
        return_datetime: this.return_datetime,
        return_gas_charge: this.return_gas_charge,
        driven_distance_upon_return: this.driven_distance_upon_return,
        fuelRemainPct: this.fuelRemainPct,
        totalMileage: this.totalMileage,
        use_connect: this.use_connect,
        fuel_cost: this.fuel_cost,
      });
    }

    if (this.memo) {
      if (this.memo.length != 0) {
        assign(data, {
          contract_memo: this.memo,
        });
      }
    }

    if (this.insurance_memo) {
      if (this.insurance_memo.length != 0) {
        assign(data, {
          insurance_memo: this.insurance_memo,
        });
      }
    }

    if (this.customer_memo) {
      if (this.customer_memo.length != 0) {
        assign(data, {
          customer_memo: this.customer_memo,
        });
      }
    }

    if (getRegInputType(this.insurance_company) && this.registration_id) {
      const firstId = this.registration_id.split('-')[0];
      const secondId = this.registration_id.split('-')[1];

      data.registration_id = `${firstId}${secondId}`;
    }

    if (this.schedule_id) {
      data.schedule_id = this.schedule_id;
    }

    return data;
  };
}

export default ContractStore;
