import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import {
  normalRentCallConfirmModalData,
  normalRentCallConfirmModalDataInventory,
} from '../../../constants/CommonRowTableData/normalRentCallConfirmModalData';
import {
  CarInfoConfirmPropsType,
  ConfirmModalDefaultType as DefaultType,
} from '../../../interface/utils/modalContents';
import ConfirmAlert from '../../Alert/ConfirmAlert';
import CommonRowTable2 from '../../CommonRowTable2';
import styles from './ResponseConfirmModal.module.scss';

interface PropsType extends DefaultType {
  stateData: CarInfoConfirmPropsType;
  version: number;
}
@inject('modalStore')
@observer
class ResponseConfirmModal extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);
  }

  getContent = () => {
    return (
      <>
        <CommonRowTable2
          viewData={
            this.props.version > 1
              ? normalRentCallConfirmModalDataInventory
              : normalRentCallConfirmModalData
          }
          stateData={{ ...this.props.stateData, car_name: this.props.stateData?.car_name_year }}
          style={{ margin: 0, padding: 0, marginBottom: 35 }}
        />
        <div className={styles.caution}>
          {/* inventory완료 */}
          {this.props.version > 1
            ? '예약확정 시 제안 수정 및 취소가 불가합니다.'
            : '제안 후 고객이 결제 진행 시 제안 수정 및 취소가 불가합니다.'}
        </div>
      </>
    );
  };

  render() {
    const cancelBtnStyle = { width: 74, padding: 0 };
    const confirmBtnStyle = { width: 108, padding: 0 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={this.props.onClickConfirm}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
      />
    );
  }
}

export default ResponseConfirmModal;
