import Image from 'next/image';
import styled, { css } from 'styled-components';
import { TEXT } from 'styles/textStyle';

import { Variant } from './Button.type';

export const Button = styled.button<{ $variant: Variant }>`
  height: 28px;
  padding: 0 12px;

  border: none;
  border-radius: 5px;

  line-height: 1;
  white-space: nowrap;

  &:disabled {
    background: var(--new-gray-2);
    color: var(--new-gray-5);
    cursor: not-allowed;
  }

  ${({ $variant }) => getVariantStyle($variant)}
`;

export const SpinnerWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SpinnerImage = styled(Image)`
  animation: spinner infinite 1s forwards;

  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
`;

const getVariantStyle = (variant: Variant) => {
  switch (variant) {
    case 'default':
      return css`
        ${TEXT.Caption3_Gray7_Regular_12px}

        background-color: var(--new-gray-2);

        &:hover {
          background-color: var(--new-gray-4);
        }
      `;
    case 'primary':
      return css`
        ${TEXT.Caption3_White_Regular_12px}

        background-color: var(--new-blue-4);
      `;
    case 'secondary':
      return css`
        ${TEXT.Caption4_Blue5_Regular_11px}

        background-color: var(--new-blue-1);

        &:hover {
          background-color: var(--new-blue-2);
        }
      `;
    case 'success':
      return css`
        ${TEXT.Caption4_Green5_Regular_11px}

        background-color: var(--new-green-1);

        &:hover {
          background-color: var(--new-green-2);
        }
      `;
    case 'danger':
      return css`
        ${TEXT.Caption4_Red5_Regular_11px}

        background-color: var(--new-red-1);

        &:hover {
          background-color: var(--new-red-2);
        }
      `;
    default:
      return null;
  }
};
