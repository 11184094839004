import { inject } from 'mobx-react';
import CheckBox from 'newDesign/atom/CheckBox';
import React, { Component } from 'react';
import Cookies from 'universal-cookie';

import { cookieDomain } from '../../../../config.js';
import depositData from '../../../../constants/deposit.json';
import * as S from './CashInfoNoticeModal.style.ts';
import styles from './CashInfoNoticeModalStyles.module.scss';
const cookies = new Cookies();

@inject('modalStore')
@inject('authStore')
class CashInfoNoticeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckNotViewAWeek: false,
    };
  }

  componentDidMount() {
    const cashInfoNoticeCookie = cookies.get('cashInfoNoticeCookie');

    if (cashInfoNoticeCookie == 'true') {
      this.setState({ isCheckNotViewAWeek: true });
    }
  }

  _getAccountData = (type) => {
    let result;

    switch (this.props.authStore.belongTo) {
      case 'rencar':
        result = {
          bank: ' 기업은행',
          name: '아이엠에스모빌리티(주)',
          account: '02-1600-8340',
        };
        break;
      case 'plus':
        result = {
          bank: '하나은행',
          name: '(주)플러스렌터카',
          account: '382-910005-19504',
        };
        break;
      default:
        result = {
          bank: '기업은행',
          name: '아이엠에스모빌리티(주)',
          account: '02-1600-8340',
        };
    }

    return result[type];
  };

  _clickCheckBox = () => {
    this.setState({ isCheckNotViewAWeek: !this.state.isCheckNotViewAWeek });
  };

  _closeModal = () => {
    if (this.state.isCheckNotViewAWeek) {
      const nowDT = new Date();
      const afterWeekDT = new Date(nowDT.setDate(nowDT.getDate() + 7));

      cookies.set('cashInfoNoticeCookie', true, {
        domain: cookieDomain,
        maxAge: 7 * 24 * 60 * 60, //TODO: 기간 정책 정해지면 수정하기 현자 1주일
        expires: afterWeekDT,
        path: '/',
      });
    } else {
      cookies.remove('cashInfoNoticeCookie', {
        domain: cookieDomain,
        path: '/',
      });
    }

    this.props.modalStore.modalClose();
  };

  render() {
    const belongToData = depositData[this.props.authStore.belongTo];

    return (
      <S.Container>
        <S.InfoWrapper marginTop="0px">
          <S.SubTitle>안내사항</S.SubTitle>
          <S.SubTitleContent>- 입금 시 10분 이내로 캐시가 충전됩니다.</S.SubTitleContent>
          <S.SubTitleContent>
            - 입금자명은 <S.SubTitleContentSpan>[업체번호]</S.SubTitleContentSpan> 으로 입금해
            주시기 바랍니다.
          </S.SubTitleContent>
          <S.SubTitleContent>- 캐시는 렌트 제안용 파트너 캐시와 연동됩니다.</S.SubTitleContent>
        </S.InfoWrapper>
        <S.DepositMethodWrapper>
          <S.DepositMethodTitleWrapper>
            <S.DepositMethodTitleWrapperTitle>입금방법</S.DepositMethodTitleWrapperTitle>
            <S.DepositMethodSubTitle>아래 지정 계좌로 계좌이체</S.DepositMethodSubTitle>
          </S.DepositMethodTitleWrapper>
          <S.DepositAccountContainer>
            <div>
              <S.DepositAccountTitle>{`${this._getAccountData('bank')} ${this._getAccountData(
                'account'
              )}`}</S.DepositAccountTitle>
              <S.DepositAccountSubTitle>
                예금주 : {this._getAccountData('name')}
              </S.DepositAccountSubTitle>
            </div>
          </S.DepositAccountContainer>
        </S.DepositMethodWrapper>
        <S.InfoWrapper marginTop="20px">
          <S.SubTitle>환불정책</S.SubTitle>
          <S.SubTitleContent>- 캐시는 환불 가능하며, 포인트는 환불 불가합니다.</S.SubTitleContent>
        </S.InfoWrapper>
        <S.InfoWrapper marginTop="20px">
          <S.SubTitle>차감순서</S.SubTitle>
          <S.SubTitleContent>- 캐시 소진 이후 포인트 소진순으로 차감됩니다.</S.SubTitleContent>
        </S.InfoWrapper>
        <S.InfoWrapper marginTop="20px">
          <S.SubTitle>입금액 정보</S.SubTitle>
          <S.SubTitleContent>- 아래 표기된 입금액 단위로만 입금 가능합니다.</S.SubTitleContent>
          <S.SubTitleContent>- 입금액은 부가세가 포함된 금액입니다.</S.SubTitleContent>
        </S.InfoWrapper>
        <S.StyledTable>
          <thead>
            <tr>
              <th>입금액</th>
              <th>총 충전액</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <S.CashKey>{belongToData['5'].deposit}</S.CashKey>
              <S.CashValue>{belongToData['5'].cash}</S.CashValue>
            </tr>
            <tr>
              <S.CashKey>{belongToData['10'].deposit}</S.CashKey>
              <S.CashValue>{belongToData['10'].cash}</S.CashValue>
            </tr>
            <tr>
              <S.CashKey>{belongToData['30'].deposit}</S.CashKey>
              <S.CashValue>{belongToData['30'].cash}</S.CashValue>
            </tr>
            <tr>
              <S.CashKey>{belongToData['50'].deposit}</S.CashKey>
              <S.CashValue>{belongToData['50'].cash}</S.CashValue>
            </tr>
            <tr>
              <S.CashKey>{belongToData['100'].deposit}</S.CashKey>
              <S.CashValue>{belongToData['100'].cash}</S.CashValue>
            </tr>
          </tbody>
        </S.StyledTable>
        <S.BtnWrap>
          {/* <S.NotViewCheckboxWrap
            isCheckNotViewAWeek={this.state.isCheckNotViewAWeek}
            onClick={this._clickCheckBox}
          >
            <S.CheckBoxImg src="/images/checkboxImage/check_icon_white.png" />
          </S.NotViewCheckboxWrap> */}
          <CheckBox
            label="일주일 동안 보지 않기"
            checked={this.state.isCheckNotViewAWeek}
            onClick={this._clickCheckBox}
          />
          <S.ConfirmBtn onClick={this._closeModal}>닫기</S.ConfirmBtn>
        </S.BtnWrap>
      </S.Container>
    );

    // return (
    //   <div className={styles.container}>
    //     <div className={styles.contents_wrap}>
    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.label}>입금방법</p>
    //         <p className={styles.contents}>다음 지정 계좌로 계좌이체를 해주세요.</p>
    //       </div>

    //       <div className={styles.center_mid}
    //       style={{width: '100%', height: '50px', backgroundColor: '#fbfbfb', marginTop: '10px', textAlign: 'center' }}>
    //         <label style={{color: '#9a9a9a', fontSize: '14px', marginRight: '10px', marginLeft: '20px'}}>입금은행</label>
    //         <span style={{color: '#282828', fontSize: '16px', fontWeight: 700, marginRight: '20px', }}>{this._getAccountData('bank')}</span>
    //         <label style={{color: '#9a9a9a', fontSize: '14px', marginRight: '10px', }}>예금주</label>
    //         <span style={{color: '#282828', fontSize: '16px', fontWeight: 700, marginRight: '20px'}}>{this._getAccountData('name')}</span>
    //         <label style={{color: '#9a9a9a', fontSize: '14px', marginRight: '10px'}}>계좌번호</label>
    //         <span style={{color: '#282828', fontSize: '16px', fontWeight: 700, marginRight: '20px'}}>{this._getAccountData('account')}</span>
    //       </div>

    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.label}>환불정책</p>
    //         <p className={styles.contents}>캐시 환불 가능. 단 포인트 환불 불가</p>
    //       </div>
    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.label}>차감순서</p>
    //         <p className={styles.contents}>캐시 소진 후 포인트 소진 순으로 차감.</p>
    //       </div>
    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.label}>입금액</p>
    //         <p className={styles.contents} style={{marginLeft: '32px'}}>아래 표기된 입금액 단위로만 입금 가능.</p>
    //       </div>

    //       <table>
    //         <thead>
    //           <tr>
    //             <th>입금액</th>
    //             <th>총 충전액</th>
    //           </tr>
    //         </thead>
    //         <tbody>
    //           <tr>
    //             <td>{belongToData["5"].deposit}</td>
    //             <td className={styles.cashValue}>{belongToData["5"].cash}</td>
    //           </tr>
    //           <tr>
    //             <td>{belongToData["10"].deposit}</td>
    //             <td className={styles.cashValue}>{belongToData["10"].cash}</td>
    //           </tr>
    //           <tr>
    //             <td>{belongToData["30"].deposit}</td>
    //             <td className={styles.cashValue}>{belongToData["30"].cash}</td>
    //           </tr>
    //           <tr>
    //             <td>{belongToData["50"].deposit}</td>
    //             <td className={styles.cashValue}>{belongToData["50"].cash}</td>
    //           </tr>
    //           <tr>
    //             <td>{belongToData["100"].deposit}</td>
    //             <td className={styles.cashValue}>{belongToData["100"].cash}</td>
    //           </tr>
    //         </tbody>
    //       </table>
    //       <div>
    //         <p style={{float: 'right', color:'#929292', fontSize:'12px' }}>※입금액은 부가세가 포함된 금액입니다.</p>
    //       </div>

    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.label}>참고사항</p>
    //         <p className={styles.referContents} style={{lineHeight: '2.3'}}>1. 입금시 10분 이내로 캐시가 충전됩니다.</p>
    //       </div>
    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.referContents} style={{marginLeft:'93px'}}>2. 입금자명은 <span style={{color: '#ffa723'}}>[업체명]</span>으로 입금해주시기 바랍니다.</p>
    //       </div>
    //       <div className={styles.deposit_guide_wrap}>
    //         <p className={styles.referContents} style={{marginLeft:'93px', marginTop: '10px'}}>3. 캐시는 렌트 제안용 파트너 캐시와 연동됩니다.</p>
    //       </div>
    //     </div>

    //     <div className={styles.btnWrap}>
    //       <div className={styles.notViewCheckboxWrap} onClick={this._clickCheckBox} style={this.state.isCheckNotViewAWeek ? {backgroundColor: '#282828'} : null }>
    //        <img
    //         className={styles.checkboxIcon}
    //         src={'/images/checkboxImage/check_icon_white.png'}
    //       />
    //       </div>
    //       <div className={styles.notViewText} onClick={this._clickCheckBox}>
    //         일주일 동안 보지 않기
    //       </div>
    //       <div className={styles.confirmBtn} onClick={this._closeModal}>닫기</div>
    //     </div>
    //   </div>
    // );
  }
}

export default CashInfoNoticeModal;
