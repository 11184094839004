import { CarInfoType } from './CarInfo/CarInfo.type';
import { ExtensionContractInfoType } from './ExtensionContractInfo/ExtensionContractInfo.type';
import { OriginContractInfoType } from './OriginContractInfo/OriginContractInfo.type';

export type SeverExtendRequestState =
  | 'request_complete'
  | 'company_fail'
  | 'payment_waiting'
  | 'payment_complete'
  | 'payment_fail'
  | 'return_done';

export type ExtendMonthlyRequest = {
  /** 연장 요청 id */
  id: number;
  /** 기존 월렌트 요청 ID */
  origin_request_id: number;
  /** 월렌트 연장 요청 후 ID */
  extend_request_id: number | null;
  /** 대여일 */
  pickup_at: string;
  /** 반납일 */
  dropoff_at: string;
  /** 월렌트 요청 상태 */
  status: SeverExtendRequestState;
  /** 예상 대여료 */
  expected_rental_cost: number | null;
};

export enum ExtensionRequestState {
  /** 계약 연장 신청 전 */
  'not-yet',
  /** 고객 요청 */
  request,
  /** 고객 결제 대기 */
  pending,
  /** 계약 연장 불가 */
  reject,
  /** 고객 결제 완료 */
  payment,
  /** 고객 미결제, 업체 승인 기간 내 미선택 */
  outstanding,
  /** 연장 종료 - 고객 요청 후 취소, 어드민에서 취소, 업체 반납 처리 */
  stop,
}

export type MonthlyExtensionInfo = {
  carInfo: CarInfoType;
  originContractInfo: OriginContractInfoType;
  extensionContractInfo: ExtensionContractInfoType;
  extensionRequestState: ExtensionRequestState;
};

export type PeriodDate = {
  days: number;
  hours: number;
  minutes: number;
};
