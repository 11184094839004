import Cookies from 'universal-cookie';

import { cookieDomain } from '../../config.js';
import { IMS_CASH, IMS_POINT } from '../../constants/stringTable/format.js';
import AuthUtils from '../../utils/AuthUtils';

export const setCashPointCookie = ({ cash, point }) => {
  const cookie = new Cookies();
  const option = {
    domain: cookieDomain,
    maxAge: 60 * 60 * 24 * 30,
    path: '/',
  };

  cookie.set(IMS_CASH, cash, option);
  cookie.set(IMS_POINT, point, option);
};

export function setStoreDefaultData(defaultInfo, mobxStore) {
  Boolean(defaultInfo) && setCashPointCookie(defaultInfo);

  if (Boolean(defaultInfo) && Boolean(mobxStore?.menuStore) && Boolean(mobxStore?.authStore)) {
    mobxStore.menuStore.setBadge(defaultInfo.notification_count);
    mobxStore.authStore.setValue({ key: 'companyCreatedAt', value: defaultInfo.created_at });
    mobxStore.authStore.setValue({ key: 'group_leader', value: defaultInfo.group_leader });
    mobxStore.authStore.setValue({
      key: 'company_id',
      value: defaultInfo.company_id ? defaultInfo.company_id : mobxStore.authStore.userId,
    });
    mobxStore.menuStore.setSelfContractPendingCount(defaultInfo.pending_signature_count);

    if (!mobxStore.authStore.permissionUserType) {
      const authUtils = new AuthUtils({ authStore: mobxStore.authStore });

      mobxStore.authStore.setValue({
        key: 'permissionUserType',
        value: authUtils.getUserType(mobxStore.authStore),
      });
    }
  }
}
