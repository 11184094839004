import { ReactNode } from 'react';
import styled from 'styled-components';

import { SearchBox } from './components/SearchBox';
import { SearchResultFactory } from './components/SearchListFactory';
import { useAddressWithTmap } from './hook';
import type { FormattedSearchResult } from './type';

export interface AddressWithTmapProps {
  onClickAddress: (addressType: string, result: FormattedSearchResult) => void;
  /** 추가로 첫 진입시 보여줄 Entry의 하단 영역 렌더 */
  renderEntryBottom?: () => ReactNode;
}

export const AddressWithTmap = ({ onClickAddress, renderEntryBottom }: AddressWithTmapProps) => {
  const { searchText, getAddress, changeSearchText, formattedSearchResult } = useAddressWithTmap();

  return (
    <Section>
      <SearchBox
        changeSearchText={changeSearchText}
        searchText={searchText}
        getAddress={getAddress}
      />

      <SearchResultFactory
        searchResult={formattedSearchResult}
        onClickAddress={onClickAddress}
        renderEntryBottom={renderEntryBottom}
      />
    </Section>
  );
};

export const Section = styled.section`
  height: 468px;

  padding: 30px 40px;
`;
