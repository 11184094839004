import dayjs from 'dayjs';
import React from 'react';

import Button from '../../../atoms/Button/index.tsx';
import CustomDatePicker from '../../../atoms/CustomDatePicker/index.tsx';
import Input from '../../../atoms/Input/index.tsx';
import Select from '../../../atoms/Select/index.tsx';
import dateTimeParser from '../../../helpers/dateTimeParser';
import RencarCheckBox from '../../RencarCheckBox/RencarCheckBox';
import styles from './SelectType.module.scss';

const paymentEndDate = (props, value) => {
  const { info, setData } = props;
  const currentMonthEndDate = dayjs().endOf('month').format('DD');

  if (Number(value) > Number(currentMonthEndDate)) {
    alert('선택하신 일자가 없는 달일 경우, 말일로 자동 지정됩니다.');
    setData(info.key, value);
  } else {
    setData(info.key, value);
  }
};

const paymentType = (props) => {
  const { stateData, info, setData } = props;

  return (
    <div className={styles.select_type_wrap}>
      <p style={{ paddingRight: 0 }}>매달</p>
      <Select
        day
        arrow
        selectData={info.data || []}
        wrapStyle={styles.select_time}
        inlineStyle={info.style || {}}
        onChange={(value) => paymentEndDate(props, value)}
        selectKey={info.key}
        dataObj={stateData}
      />
      <p style={{ marginRight: '5px', paddingRight: 0 }}>일 </p>
      <p style={{ marginRight: '5px', paddingRight: 0 }}> 최초납부일(선택) </p>
      <CustomDatePicker
        icon
        clear
        selected={
          stateData.initial_payment_date && stateData.initial_payment_date !== '-'
            ? new Date(stateData.initial_payment_date)
            : null
        }
        className={styles.period_date_picker}
        onChange={(data) =>
          setData('initial_payment_date', dateTimeParser({ date: data, format: 'YYYY-MM-DD' }))
        }
        minDate={new Date(stateData.start_datetime)}
      />
    </div>
  );
};

const renderPaymentMethod = (props) => {
  const { stateData, info, setData } = props;
  const { select, btn, label } = props.info;

  if (label === '납부 방법') {
    switch (stateData.payment_method_type) {
      case '계좌이체':
        return (
          <>
            <Input
              type="text"
              placeholder="은행명 입력"
              className={styles.payment_method}
              propValue={stateData.bank_name || ''}
              handleChange={(value) => setData('bank_name', value)}
              dataset="bank"
            />
            <Input
              type="text"
              placeholder="예금주 입력"
              className={styles.payment_method}
              propValue={stateData.account_holder || ''}
              handleChange={(value) => setData('account_holder', value)}
              dataset="account_user"
            />
            <Input
              type="number"
              placeholder="계좌번호 입력"
              className={styles.payment_method}
              propValue={stateData.account_num || ''}
              handleChange={(value) => setData('account_num', value)}
              dataset="account_num"
            />
          </>
        );
      case '기타':
        return (
          <Input
            type="text"
            placeholder="상세 납부 방법 입력"
            className={styles.payment_method}
            propValue={stateData.payment_method_detail || ''}
            handleChange={(value) => setData('payment_method_detail', value)}
            dataset="payment_method_detail"
          />
        );
      default:
        break;
    }
  }
};

const SelectType = (props) => {
  const { stateData, info, setData } = props;
  const { select, btn, checkBox, label, input, addText } = props.info;

  if (label === '납부일') return paymentType(props);

  return (
    <div className={styles.select_type_wrap}>
      <Select
        wrapStyle={styles.select_type}
        arrow={select.arrow}
        selectData={select.data || []}
        selectKey={info.key}
        dataObj={stateData}
        listView={select.listView}
        inlineStyle={select.style}
        disabled={select.disabled}
        onChange={(value) => {
          if (info.key === 'insurance_company' || info.key === 'claim_insurance') {
            setData('registration_id', null); // 보험사 변경시 접수번호 초기화
          }

          setData(info.key, value);
        }}
        onClick={info.select?.onClick}
        dataset={info.key}
      />
      {addText && <p>{addText}</p>}
      {input && (
        <Input
          type={input.type}
          placeholder={input.placeholder}
          inlineStyle={input.inlineStyle}
          dataset={input.dataset}
          propValue={stateData.insuranceNumber || ''}
          handleChange={(value) => setData('insuranceNumber', value)}
        />
      )}
      {renderPaymentMethod(props)}
      {info.unit && <p style={{ fontSize: '13px' }}>{info.unit}</p>}
      {btn && (
        <Button
          btnText={btn.name}
          inlineStyle={btn.style ? btn.style : {}}
          btnClick={btn.func}
          disabled={btn.disabled}
        />
      )}
      {info.addSmallText && <p className={styles.add_small_text}>{info.addSmallText}</p>}
      {checkBox && (
        <RencarCheckBox
          text={checkBox.text}
          value={stateData.use_self_car_insurance_percentage}
          onChange={checkBox.func}
          containerStyle={checkBox.containerStyle}
          checkStyle={checkBox.checkStyle}
        />
      )}
    </div>
  );
};

export default SelectType;
