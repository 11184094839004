type InsuranceCompany = {
  guaranteed: boolean;
  id: number;
  name: string;
  tier: number;
};

const DUPLICATED_COMPANY_NAMES = {
  GUARANTEED: ['신한마스타오토서비스', '마스타자동차관리', '현대캐피탈'],
  INSURANCE: ['신한마스타오토서비스', '마스타자동차관리', '현대'],
};

/**
 * @summary  서버에서 모든 보험사 리스트를 받아온 후 보증, 보험에 맞게 파싱.
 *
 * 보증의 보험사 리스트 순서: 중복이 제거된 보증 리스트 + 중복되는 리스트 + 중복이 제거된 보험 리스트.
 * 중복을 제거: '신한마스타오토서비스', '마스타자동차관리', '현대'.
 * 현대캐피탈 과 현대 는 다른 이름이지만 중복 처리를 하고 현대로 노출한다.
 */
export const parseTotalInsuranceCompanyList = (insuranceCompanyList: InsuranceCompany[]) => {
  const guaranteedList = insuranceCompanyList.filter((insurance) => insurance.guaranteed);
  const insuranceList = insuranceCompanyList.filter((insurance) => !insurance.guaranteed);

  // 보증 중복되는 이름 제거
  const deDuplicatedGuaranteedList = guaranteedList
    .filter((insurance) => !DUPLICATED_COMPANY_NAMES.GUARANTEED.includes(insurance.name))
    .map(convertToOption);

  // 보험 중복되는 이름 제거
  const deDuplicatedInsuranceList = insuranceList
    .filter((insurance) => !DUPLICATED_COMPANY_NAMES.INSURANCE.includes(insurance.name))
    .map(convertToOption);

  /**
   * 중복되는 보험사
   * 신한, 마스터, 현대 (모바일과 순서 맞추기)
   */
  const duplicatedList = DUPLICATED_COMPANY_NAMES.INSURANCE.map((duplicatedName) =>
    insuranceList.find(({ name }) => name === duplicatedName)
  ).map(convertToOption);

  return [...deDuplicatedGuaranteedList, ...duplicatedList, ...deDuplicatedInsuranceList];
};

const convertToOption = ({ name, guaranteed }: InsuranceCompany) => ({
  label: name,
  value: name,
  guaranteed,
});
