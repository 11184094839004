import { omit } from 'lodash';
import { defaultErrorMap, z, ZodError, ZodErrorMap, ZodInvalidEnumValueIssue } from 'zod';
type EnumLike = { [k: string]: string | number; [nu: number]: string };

function overrideEnumErrorMessage(
  message: string | ((issue: ZodInvalidEnumValueIssue) => string),
  errorMap = defaultErrorMap
): ZodErrorMap {
  return (issue, ctx) => {
    if (typeof message === 'string') {
      return {
        message,
      };
    }

    return errorMap(issue, ctx);
  };
}

export function _enum<T extends EnumLike | Array<string>>(
  values: T,
  message: string | ((issue: ZodInvalidEnumValueIssue) => string) = '',
  errorMap = defaultErrorMap
) {
  let enumnValues = values as EnumLike;

  function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
    return o.reduce((res, key) => {
      res[key] = key;

      return res;
    }, Object.create(null));
  }

  if (Array.isArray(values)) {
    enumnValues = strEnum(values);
  }

  return z.nativeEnum(enumnValues, {
    errorMap: overrideEnumErrorMessage(message, errorMap),
  });
}

export function _strEnum<T extends string>(
  values: T[],
  message: string | ((issue: ZodInvalidEnumValueIssue) => string) = '',
  errorMap = defaultErrorMap
) {
  function strEnum<T extends string>(o: T[]): { [K in T]: K } {
    return o.reduce((res, key) => {
      res[key] = key;

      return res;
    }, Object.create(null));
  }

  const enumn = strEnum(values);

  return z.nativeEnum(enumn, {
    errorMap: overrideEnumErrorMessage(message, errorMap),
  });
}

export function showErrorMessage(error: ZodError) {
  const formatedError = omit(error.format(), ['_errors']);

  if (Array.isArray(Object.keys(formatedError))) {
    const firstKey = Object.keys(formatedError)[0];

    if (Array.isArray(formatedError[firstKey]._errors)) {
      formatedError[firstKey]._errors.some((message) => {
        alert(message);

        return true;
      });
    }
  }
}
