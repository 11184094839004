import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

export function diffDate(date1: string, date2: string) {
  const result = dayjs.duration(dayjs(date1).diff(date2));

  return {
    second: result.seconds(),
    minute: result.minutes(),
    hour: result.hours(),
    day: result.days(),
    month: result.months(),
    year: result.years(),
  };
}
