import { makeAutoObservable } from 'mobx';

import { BaseStore } from './BaseStore.type';

class ModalStore implements BaseStore<ModalStore> {
  modalOn = false;
  hideCloseBtn = false;
  headerCenter = false;
  modalTitle = '';
  subTitle = '';
  modalComponent: JSX.Element = null;
  modalStyle = {};
  modalBodyStyle = {};
  modalTitleStyle = {};
  subNotice = '';
  resetError: (() => void) | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  modalOpen = ({
    modalComponent = this.modalComponent,
    hideCloseBtn = this.hideCloseBtn,
    headerCenter = this.headerCenter,
    modalTitle = this.modalTitle,
    subTitle = this.subTitle,
    subNotice = this.subNotice,
    modalStyle = this.modalStyle,
    modalBodyStyle = this.modalBodyStyle,
    modalTitleStyle = this.modalTitleStyle,
    resetError = this.resetError,
  } = {}) => {
    this.modalOn = true;
    this.hideCloseBtn = hideCloseBtn;
    this.headerCenter = headerCenter;
    this.modalTitle = modalTitle;
    this.subTitle = subTitle;
    this.subNotice = subNotice;
    this.modalComponent = modalComponent;
    this.modalStyle = modalStyle;
    this.modalBodyStyle = modalBodyStyle;
    this.modalTitleStyle = modalTitleStyle;
    this.resetError = resetError;
  };

  modalClose = () => {
    this.modalOn = false;
    this.hideCloseBtn = false;
    this.headerCenter = false;
    this.modalTitle = '';
    this.subTitle = '';
    this.subNotice = '';
    this.modalComponent = null;
    this.modalStyle = {};
    this.modalBodyStyle = {};
    this.hideCloseBtn = false;
    this.modalTitleStyle = {};
  };

  setObject = undefined;
}

export default ModalStore;
