import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 30px 40px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 640px;
`;

export const NoticeWrap = styled.div`
  width: 450px;
  display: flex;
  justify-content: space-between;
`;

export const Notice = styled.div`
  font-size: 13px;
  margin-bottom: 24px;
`;

export const AddContractButton = styled.button`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 180px;
  height: 36px;
  border-radius: 3px;
  border: 1px solid #575757;
  background-color: #575757;
  color: white;
  font-size: 14px;
  cursor: pointer;
`;

export const TableWrap = styled.div`
  width: 640px;
  overflow-y: auto;
  display: flex;
  max-height: 280px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 40px;
`;

export const CloseButton = styled.button`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 74px;
  height: 42px;
  border-radius: 3px;
  background-color: white;
  color: #575757;
  border: 1px solid #575757;
  cursor: pointer;
`;
