/* eslint-disable no-confusing-arrow */
import styled from 'styled-components';
import { TEXT } from 'styles/textStyle';

export const CashKey = styled.td`
  ${TEXT.Caption3_Gray8_Regular_12px}
`;

export const CashValue = styled.td`
  ${TEXT.Caption3_Blue5_Regular_12px}
`;

export const CheckBoxImg = styled.img`
  width: 12px;
  height: 12px;
  margin: 3px;
`;
export const Container = styled.div`
  width: 600px;
  padding: 0px 24px 24px;
`;

export const InfoWrapper = styled.div<{ marginTop?: string }>`
  margin-top: ${(props) => props.marginTop || '20px'};
`;

export const SubTitle = styled.h4`
  ${TEXT.Body2_Gray7_Bold_15px}
  margin: 5px 0;
`;

export const SubTitleContent = styled.p`
  margin: 8px 0;
  ${TEXT.Caption3_Gray8_Regular_12px}
`;

export const SubTitleContentSpan = styled.span`
  color: var(--new-blue-5);
`;
export const DepositMethodWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const DepositMethodTitleWrapper = styled.div`
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
`;

export const DepositMethodTitleWrapperTitle = styled.h4`
  ${TEXT.Body2_Gray7_Bold_15px}
  margin: 0;
`;

export const DepositMethodSubTitle = styled.p`
  ${TEXT.Caption3_Gray6_Regular_12px}
  line-height: 18px;
  margin: 0 0 0 8px;
`;

export const DepositAccountContainer = styled.div`
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  display: -webkit-flex;
  margin-top: 12px;
  background-color: var(--new-gray-1);
  padding: 16px;
`;

export const DepositAccountTitle = styled.h4`
  ${TEXT.Body2_Gray7_Bold_15px}
  margin: 0;
`;

export const DepositAccountSubTitle = styled.p`
  ${TEXT.Caption3_Gray6_Regular_12px}
  margin: 10px 0 0 0;
  text-align: center;
`;

export const StyledTable = styled.table`
  margin-top: 20px;

  thead > tr > th {
    ${TEXT.Caption4_Gray7_Medium_11px}
    border-top: 1px solid var(--new-gray-1);
    background-color: var(--new-gray-1);
    height: 28px;
    border-right: none;
    border-left: none;
  }

  tbody > tr > td {
    border-right: none;
    border-left: none;
  }
`;

export const BtnWrap = styled.div`
  display: flex;
  display: -webkit-flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
`;

export const NotViewCheckboxWrap = styled.div<{ isCheckNotViewAWeek?: boolean }>`
  width: 20px;
  height: 20px;
  display: inline-block;
  border: 1px solid var(--new-gray-4);
  cursor: pointer;
  border-radius: 3px;
  background-color: ${(props) => (props.isCheckNotViewAWeek ? '#282828' : null)};
`;

export const NotViewText = styled.span`
  ${TEXT.Caption3_Gray8_Regular_12px}

  margin-right: 20px;

  cursor: pointer;
`;

export const ConfirmBtn = styled.div`
  ${TEXT.Caption1_White_Regular_14px}

  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  line-height: 20px;
  background-color: var(--new-blue-4);
  padding: 10px 22px;
  cursor: pointer;
`;
