import { DeliveryCostItem } from 'api/deliveryArea';
import type { AreaForDelivery, CityData, VillageData } from 'interface/delivery';
import { cloneDeep } from 'lodash';
import { makeAutoObservable, toJS } from 'mobx';
import { getSelectedVillageIds } from 'utils/DeliveryAreaUtils';

import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';

class DeliveryAreaStore implements BaseStore<DeliveryAreaStore> {
  rawDefaultAreaData: CityData[] = null; // 서버에서 보내주는 전체 행정구역 데이터
  rawSelectedAreaData: DeliveryCostItem[] = null; // 서버에서 보내주는 딜리버리 구역 데이터
  defaultArea: AreaForDelivery = null; // 전체 행정구역을 프론트에서 활용하는 형태로 변경한 데이터
  selectedArea: AreaForDelivery = {}; // 딜리버리 구역을 프론트에서 활용하는 형태로 변경한 데이터
  activatedCity = '서울특별시'; // 현재 탭 선택된 도시
  activatedTown = ''; // 현재 클릭되어 있는 시/군/구
  deliveryAreaName = '';
  oneWayCost: number = null;
  roundTripCost: number = null;
  isEdit = false;
  disabledAreas: Array<AreaForDelivery> = []; // 비활성화 처리 되어야 하는 지역 데이터,(구역 추가/수정 화면에서 이미 딜리버리 구역으로 지정되어 있는 지역은 비활성화 처리 되어야 함)

  constructor() {
    makeAutoObservable(this);
  }

  setObject = (data) => _setObject(this, data);

  get isEmptySelectedArea(): boolean {
    return getSelectedVillageIds(this.selectedArea).length === 0;
  }

  init = (): void => {
    this.rawDefaultAreaData = null;
    this.rawSelectedAreaData = null;
    this.defaultArea = null;
    this.selectedArea = {};
    this.activatedCity = '서울특별시';
    this.activatedTown = '';
    this.deliveryAreaName = '';
    this.oneWayCost = null;
    this.roundTripCost = null;
    this.isEdit = false;
    this.disabledAreas = [];
  };

  setValue = (data: Partial<DeliveryAreaStore>): this => {
    Object.keys(data).forEach((key) => (this[key] = data[key]));

    return this;
  };

  selectAllTown = (): void => {
    const copyedSelectedArea = cloneDeep(this.selectedArea);
    const allTowns = Object.keys(this.defaultArea[this.activatedCity]);
    const disabledTowns = toJS(this.disabledAreas)
      .filter((item) => item[this.activatedCity])
      .map((i) => Object.keys(i[this.activatedCity]))
      .flat();

    if (disabledTowns.length >= allTowns.length)
      return alert(
        `[${this.activatedCity}] 는 이미 다른 구역에 포함된 지역이므로 선택이 불가합니다. 확인 후 다시 시도해 주세요.`
      );

    allTowns.forEach((town) => {
      const allVillageExceptDisabled = this.defaultArea[this.activatedCity][town].filter(
        (village: VillageData) => {
          return !this.disabledAreas.some((data) =>
            data?.[this.activatedCity]?.[town]
              ?.map((item: VillageData) => item.id)
              ?.includes(village.id)
          );
        }
      );

      if (allVillageExceptDisabled.length === 0) {
        return; // 모든 village가 disabled 상태이면 early return 하여 아무것도 선택되지 않게 한다.
      }

      copyedSelectedArea[this.activatedCity] = {
        ...copyedSelectedArea[this.activatedCity],
        [town]: allVillageExceptDisabled,
      };
    });

    this.selectedArea = { ...copyedSelectedArea };
  };

  clearAllTown = (): void => {
    const copyedSelectedArea = cloneDeep(this.selectedArea);

    this.selectedArea = {
      ...copyedSelectedArea,
      [this.activatedCity]: {},
    };
  };

  onClickVillage = (village: VillageData): void => {
    const isAlreadySelected = this.selectedArea?.[this.activatedCity]?.[this.activatedTown]?.some(
      (_village) => _village.id === village.id
    );

    if (isAlreadySelected) {
      let index: number;

      this.selectedArea[this.activatedCity][this.activatedTown].forEach((_village, _index) => {
        if (_village.id === village.id) {
          return (index = _index);
        }
      });
      this.selectedArea[this.activatedCity][this.activatedTown].splice(index, 1);
    } else if (this.selectedArea?.[this.activatedCity]?.[this.activatedTown]) {
      this.selectedArea[this.activatedCity][this.activatedTown].push(village);
    } else {
      this.selectedArea[this.activatedCity] = {
        ...this.selectedArea[this.activatedCity],
        [this.activatedTown]: [village],
      };
    }
  };

  selectAllVillage = (): void => {
    const allVillageExceptDisabled = this.defaultArea[this.activatedCity][
      this.activatedTown
    ].filter((village: VillageData) => {
      return !this.disabledAreas.some((data) =>
        data?.[this.activatedCity]?.[this.activatedTown]
          ?.map((item: VillageData) => item.id)
          ?.includes(village.id)
      );
    });

    if (allVillageExceptDisabled.length === 0)
      alert(
        `[${this.activatedTown}] 는 이미 다른 구역에 포함된 지역이므로 선택이 불가합니다. 확인 후 다시 시도해 주세요.`
      );

    this.selectedArea[this.activatedCity] = {
      ...this.selectedArea[this.activatedCity],
      [this.activatedTown]: allVillageExceptDisabled,
    };
  };

  clearAllVillage = (): void => {
    delete this.selectedArea[this.activatedCity][this.activatedTown];
  };

  isAllSelected = (city: string): boolean => {
    const defaultTownNames = Object.keys(this.defaultArea[city]);
    const selectedTownNames = Object.keys(this.selectedArea[city]);
    const allTownSelected = defaultTownNames.every((town) => selectedTownNames.includes(town)); // 또는 length로
    const allVillageSelected = selectedTownNames.every(
      (town) => this.selectedArea[city][town].length === this.defaultArea[city][town].length
    );

    return allTownSelected && allVillageSelected;
  };

  allClear = (): void => {
    this.selectedArea = {};
  };
}

export default DeliveryAreaStore;
