import callApi from '../../helpers/callApi';

export default {
  getCarGroupNameList(params: any) {
    const param = params.param || {};

    return callApi({
      method: 'GET',
      url: '/v2/car-groups/names',
      params: {
        car_size: params.car_size,
        ...param,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    } as any);
  },
};
