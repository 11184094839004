import { Bank, getBanksApi } from 'api/banks/getBanksApi';
import { makeAutoObservable } from 'mobx';

import { _setObject, BaseStore } from './BaseStore.type';
import LoadingStore from './LoadingStore';

class BankStore implements BaseStore<BankStore> {
  private isLoading = false;
  private loadingStore: LoadingStore = null;
  private banks: Bank[] | null = null;

  constructor(loadingStore: LoadingStore) {
    this.loadingStore = loadingStore;

    makeAutoObservable(this);
  }

  hasBanks() {
    return Boolean(this.banks);
  }

  getBanks() {
    if (!this.banks) {
      return [];
    }

    return this.banks;
  }

  async getBanksFromApi() {
    /**
     * api 호출중일 때 재호출 방지 ex.) api 호출중일 때 getBanks가 재호출 된 경우.
     * loadingStore는 다른 api호출에도 영향을 받기 때문에 별도의 멤버변수로 해당 api만 확인하도록 제어.
     */
    if (this.isLoading) {
      return;
    }

    try {
      this.isLoading = true;
      this.loadingStore.on();
      const response = await getBanksApi();

      if (response.status === 200) {
        this.banks = response.data.banks;

        return;
      }
    } catch (error) {
      console.info(error);
    } finally {
      this.isLoading = false;
      this.loadingStore.off();
    }
  }

  setObject = (data) => _setObject(this, data);
}

export default BankStore;
