import { AddressWithTmap } from 'components/AddressWithTmap';
import { MemoModal } from 'newDesign/components/modals/MemoModal';
// import SearchDriverModal from '../components/ModalContents/SearchDriverModal';
import SearchDriverModal from 'newDesign/components/modals/SearchDriverModal/index.tsx';
import React, { Fragment } from 'react';

import FindAddress from '../atoms/FindAddress';
import AddressDetail from '../components/Address/AddressDetail';
import DuplicatedContractModal from '../components/ModalContents/DuplicatedContractModal';
import ReturnRequestDetailModal from '../pages/contract/detail/ClaimDetailModals/ReturnRequestDetailModal';
import ClaimSetReturnCarModal from '../pages/contract/detail/ClaimDetailModals/SetReturnCarModal';
import NormalConfirmReturnCarModal from '../pages/normalContract/detail/ClaimDetailModals/NormalConfirmReturnCarModal';
import NormalSetReturnCarModal from '../pages/normalContract/detail/ClaimDetailModals/SetReturnCarModal';
import CashInfoNoticeModal from '../pages/paymentMethod/cash/Modals/CashInfoNoticeModal';

export const openNormalSetReturnCarModal = (props, ModalStore) => {
  const modal = <NormalSetReturnCarModal {...props} />;
  const data = {
    modalComponent: modal,
    modalTitle: props.title,
    modalStyle: { maxWidth: 800, width: '90%' },
    modalBodyStyle: { overflow: 'auto' },
  };

  ModalStore.modalOpen(data);
};

export const openClaimSetReturnCarModal = (props, ModalStore) => {
  const modal = <ClaimSetReturnCarModal {...props} />;
  const data = {
    modalComponent: modal,
    modalTitle: props.title,
    modalStyle: { maxWidth: 800, width: '90%' },
    modalBodyStyle: {},
  };

  ModalStore.modalOpen(data);
};

export const openCashInfoNoticeModal = (ModalStore) => {
  const cashInfoNoticeModal = <CashInfoNoticeModal />;
  const data = {
    modalComponent: cashInfoNoticeModal,
    modalTitle: '캐시 입금 안내',
    modalStyle: { width: 600 },
    modalBodyStyle: { overflowY: 'auto', maxHeight: '85vh' },
  };

  ModalStore.modalOpen(data);
};

export const openDuplicatedContractModal = (props, ModalStore) => {
  const duplicatedContractModal = <DuplicatedContractModal {...props} />;
  const data = {
    modalComponent: duplicatedContractModal,
    hideCloseBtn: true,
    modalTitle: '중복 계약 안내',
    modalStyle: { maxWidth: 620, width: '90%' },
    modalBodyStyle: { overflow: 'auto' },
  };

  ModalStore.modalOpen(data);
};

export const returnRequestDetailModalOpen = (props, ModalStore) => {
  const connectRencarRequestModal = (
    <ReturnRequestDetailModal {...props} closeModal={ModalStore.modalClose} />
  );
  const modalData = {
    modalComponent: connectRencarRequestModal,
    modalTitle: '반납 요청 상세',
    modalStyle: { width: '520px', textAlign: 'center' },
  };

  ModalStore.modalOpen(modalData);
};

export const openConfirmReturnModal = (props, ModalStore) => {
  const modal = (
    <NormalConfirmReturnCarModal
      {...props}
      openNormalSetReturnCarModal={() => openNormalSetReturnCarModal(props, ModalStore)}
    />
  );
  const data = {
    modalComponent: modal,
    modalTitle: props.title,
    modalStyle: { width: '520px' },
  };

  ModalStore.modalOpen(data);
};

export const openSearchDriverModal = (
  modalStore,
  callback,
  selectedId,
  keyName = 'searchDriver',
  usingCustomerSearch,
  isContract
) => {
  const data = {
    modalComponent: (
      <SearchDriverModal
        setDriverInfo={callback}
        keyName={keyName}
        selectedId={selectedId}
        usingCustomerSearch={usingCustomerSearch}
        isContract={isContract}
      />
    ),
    modalTitle: `${usingCustomerSearch ? '고객' : '운전자'} 검색`,
    modalStyle: {
      width: '598px',
    },
  };

  modalStore.modalOpen(data);
};

export const openAddressModal = ({ modalStore, showDetail, submit }) => {
  const showDetailModal = ({ basicAddress, addressData }) => {
    const modal = (
      <AddressDetail
        searchAddress={basicAddress}
        init={() => {
          this();
        }}
        save={(detailAddress) => {
          submit(`${basicAddress}${detailAddress ? ` ${detailAddress}` : ''}`, addressData);
          modalStore.modalClose();
        }}
        cancel={() => {
          modalStore.modalOpen({
            modalComponent: searchModal,
            modalTitle: '주소 검색',
            modalStyle: {
              maxWidth: '500px',
              width: '80%',
              maxHeight: '700px',
              overflow: 'hidden',
              minHeight: '700px',
            },
            modalBodyStyle: { overflow: 'auto' },
          });
        }}
      />
    );

    modalStore.modalOpen({
      modalComponent: modal,
      modalTitle: '주소 검색',
      modalStyle: {
        maxWidth: '500px',
        width: '80%',
        maxHeight: '700px',
        overflow: 'hidden',
      },
      modalBodyStyle: { overflow: 'auto' },
    });
  };

  const searchModal = (
    <Fragment>
      <FindAddress
        height={500}
        getData={(selectAddress, data) => {
          const addressName = data.buildingName
            ? `${selectAddress} (${data.buildingName})`
            : selectAddress;

          if (showDetail) {
            showDetailModal({
              basicAddress: addressName,
              addressData: data,
            });
          } else {
            submit(addressName, data);
            modalStore.closeModal();
          }
        }}
      />
    </Fragment>
  );

  modalStore.modalOpen({
    modalComponent: searchModal,
    modalTitle: '주소 검색',
    modalStyle: {
      maxWidth: '500px',
      width: '80%',
      maxHeight: '700px',
      overflow: 'hidden',
      minHeight: '700px',
    },
    modalBodyStyle: { overflow: 'auto' },
  });
};

export const openUnsetAddressDetailModal = ({ modalStore, showDetail, submit }) => {
  const showDetailModal = ({ basicAddress, addressData }) => {
    const modal = (
      <AddressDetail
        searchAddress={basicAddress}
        init={() => {
          modalStore.modalClose();
        }}
        save={(detailAddress) => {
          submit(basicAddress, detailAddress, addressData);
          modalStore.modalClose();
        }}
        // 취소 로직이 아닌 재검색 로직
        cancel={() =>
          modalStore.modalOpen({
            modalComponent: searchModal,
            modalTitle: '주소 검색',
            modalStyle: {
              maxWidth: '533px',
              width: '80%',
              maxHeight: '700px',
              overflow: 'hidden',
              minHeight: '700px',
            },
          })
        }
      />
    );

    modalStore.modalOpen({
      modalComponent: modal,
      modalTitle: '주소 검색',
      modalStyle: {
        maxWidth: '500px',
        width: '80%',
        maxHeight: '700px',
        overflow: 'hidden',
      },
    });
  };

  const searchModal = (
    <AddressWithTmap
      onClickAddress={(addressType, address) => {
        if (showDetail) {
          showDetailModal({
            basicAddress: address.roadNameAddress,
            addressData: address,
          });

          return;
        }

        submit(addressType, address);
        modalStore.modalClose();
      }}
    />
  );

  modalStore.modalOpen({
    modalComponent: searchModal,
    modalTitle: '주소 검색',
    modalStyle: {
      maxWidth: '533px',
      width: '80%',
      maxHeight: '700px',
      overflow: 'hidden',
      minHeight: '700px',
    },
  });
};

export const openMemoModal = (modalStore, modalTitle, props) => {
  const data = {
    modalComponent: <MemoModal {...props} />,
    modalTitle,
    modalStyle: { width: 448, height: 319 },
    modalBodyStyle: { paddingInline: 20, paddingBottom: 20 },
  };

  modalStore.modalOpen(data);
};
