import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
export interface IMonthlyCostListParams {
  search?: string;
  page?: number;
  per_page?: number;
  car_group_id?: Array<number>;
  period_id?: number;
}

export interface IMonthlyCostItem {
  car_groups: CarGroup[];
  d15_daily_cost: number;
  d15_security_deposit: number;
  d15_total_cost: number;
  id: number;
  m1_daily_cost: number;
  m1_security_deposit: number;
  m1_total_cost: number;
  name: string;
}

interface CarGroup {
  id: string;
  name: string;
}

type SuccessResponse = {
  monthly_cost_tables: Array<IMonthlyCostItem>;
  total_page: number;
  defaultInfo: callApi2.DefaultInfo;
};
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type IMonthlyCostListResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getMonthlyCostList(params: IMonthlyCostListParams, store: StoreType) {
  const response = await callApi2.get<IMonthlyCostListResponse>(
    `/v2/group-cost-tables/monthly`,
    params
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
