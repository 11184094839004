import { FormattedSearchResult } from 'components/AddressWithTmap/type';
import styled from 'styled-components';

import { AddressItem } from './AddressItem';

interface RenderListProps {
  searchResult: Array<FormattedSearchResult>;
  onClickAddress: (addressType: string, result: FormattedSearchResult) => void;
}

export const SearchedList = ({ searchResult, onClickAddress }: RenderListProps) => {
  return (
    <div>
      <TotalCount>
        총 <CountNumber>{searchResult.length}</CountNumber>건
      </TotalCount>

      <AddressListWrapper>
        {searchResult.map((response, i) => (
          <li
            key={response.roadNameAddress + response.name + i}
            style={{ borderTop: i === 0 && '1px solid #efefef' }}
          >
            <AddressItem
              key={response.name}
              address={response.roadNameAddress}
              jibunAddress={response.jibunAddress}
              name={response.name}
              onClick={(addressType) => {
                onClickAddress(addressType, response);
              }}
            />
          </li>
        ))}
      </AddressListWrapper>
    </div>
  );
};

const TotalCount = styled.h2`
  font-size: 16px;
  font-weight: bold;

  margin-top: 20px;
  margin-bottom: 13px;
`;

const CountNumber = styled.span`
  color: #0088fd;
`;

const AddressListWrapper = styled.ul`
  max-height: 304px;
  width: 453px;

  overflow-y: auto;
  overscroll-behavior: contain;

  list-style: none;
  padding: 0;

  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b2b2b2;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: #f8f8f8;
  }
`;
