import 'dayjs/locale/ko';

import dayjs from 'dayjs';
import { isNil } from 'lodash';
import find from 'lodash/find';
import { toJS } from 'mobx';

import driver_type from '../constants/driver_type.json';
import { lotteCostData } from '../constants/lotteCostData';
import { GUARANTEED_CLAIM_COST_NOTICE } from '../constants/stringTable';
import { diffDate } from '../helpers/dateManipulation';
import { phoneNumberHyphen } from '../helpers/phoneNumberHyphen';
import * as contractUtils from '../utils/ContractUtils';
import { dayOfWeekParser } from '../utils/DataParser';
import * as parser from './DataParser';

const question = '/images/question/question-white.png';
const s3 = 'https://s3.ap-northeast-2.amazonaws.com/rencar/signatureImages/';

const inputWidth = '140px';

dayjs.locale('ko');

export const industrialParser = (type, info) => {
  if (info) {
    if (info.contracts) {
      if (info.contracts[info.contracts.length - 1][type]) {
        return info.contracts[info.contracts.length - 1][type];
      }

      return '';
    }

    return '';
  }

  return '';
};

export const findItem = (tableData, key) => {
  let result = null;

  tableData.forEach((item) => {
    if (find(item, { key })) {
      result = find(item, { key });
    }

    if (Array.isArray(item)) {
      item.forEach((item2) => {
        if (find(item2, { key })) {
          result = find(item2, { key });
        }
      });
    }
  });

  return result;
};

const getFaultRate = (faultRate) => {
  if (faultRate) {
    if (faultRate == '0') {
      return '무과실';
    }

    return `${faultRate}%`;
  }

  return '-';
};

export const getConnectRentCallTableData = (btnFunc) => {
  return [
    [
      {
        label: MESSAGE.CONNECT_RENT_CALL,
        value: '렌카콜 연결이 되어있지 않습니다. 렌카콜 건일 경우 꼭 연결해주세요.',
        option: 'output',
        btn: '연결하기',
        btnFunc,
      },
    ],
  ];
};

export const getBasicInfoTableData = (info, btnFunc) => {
  let notifyInsurance = {};
  let searchCarOld = {};

  switch (info.notifyInsurance) {
    case 'already':
      notifyInsurance = {
        label: '통보완료',
        func: null,
        style: {
          backgroundColor: '#bebdbd',
          color: 'white',
          border: 'none',
        },
      };
      break;
    case 'none':
      notifyInsurance = {
        label: '미통보',
        func: null,
        style: {
          backgroundColor: '#bebdbd',
          color: 'white',
          border: 'none',
        },
      };
      break;
    case 'notify':
      notifyInsurance = {
        label: MESSAGE.NOTICE_MESSAGE,
        func: btnFunc.notifyInsurance,
        style: {},
      };
      break;
    default:
  }

  if (info.checkedAutoUpInfo) {
    searchCarOld = {
      label: '조회완료',
      func: null,
      style: {
        backgroundColor: '#bebdbd',
        color: 'white',
        border: 'none',
      },
    };
  } else if (info.claimState === 'using_car' || info.claimState === 'before_claim') {
    searchCarOld = {
      label: '연식조회',
      func: btnFunc.searchCarOld,
      style: {},
    };
  } else {
    searchCarOld = {
      label: null,
      func: null,
      style: {},
    };
  }

  return [
    [
      {
        label: '고객차량 번호',
        value: parser.abbreviation(info.customer_car_number, 15),
        option: 'output',
        btn: searchCarOld.label,
        btnFunc: searchCarOld.func,
        btnStyle: searchCarOld.style,
      },
      {
        label: MESSAGE.INSURANCE_COMPANY_NAME,
        value: parser.defaultParser(info.claim_insurance),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.CUSTOMER_CAR,
        value: parser.abbreviation(info.customer_car, 15),
        option: 'output',
      },
      {
        label: '접수번호',
        value: parser.defaultParser(info.registration_id),
        option: 'output',
      },
    ],
    [
      {
        label: '고객 과실',
        value: getFaultRate(info.fault_rate),
        option: 'output',
      },
      {
        label: '담당자명',
        value: parser.defaultParser(info.claim_insurance_manager),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.ENGINE_TYPE,
        value: parser.defaultParser(info.customer_car_engine),
        option: 'output',
      },
      {
        label: '연식',
        value: parser.defaultParser(info.customer_car_age),
        option: 'output',
      },
      {
        label: '담당자 연락처',
        value: parser.contractParser(info.claim_insurance_contact),
        option: 'output',
        btn: notifyInsurance.label,
        btnFunc: notifyInsurance.func,
        btnStyle: notifyInsurance.style,
        flex: 2,
      },
    ],
    [
      {
        label: '공업사',
        value: parser.defaultParser(info.industrial_company),
        option: 'output',
      },
      {
        label: '공업사 연락처',
        value: parser.contractParser(info.industrial_company_tel),
        option: 'output',
      },
      {
        label: '담당자 팩스',
        value: parser.contractParser(info.claim_fax),
        option: 'output',
        flex: 2,
      },
    ],
  ];
};

export const modifyBasicInfoTableData = (info, searchFunc) => {
  return [
    [
      {
        key: 'customer_car_number',
        label: '고객차량 번호',
        inputType: 'text',
        style: { width: inputWidth },
      },
      {
        key: 'claim_insurance',
        label: MESSAGE.INSURANCE_COMPANY_NAME,
        inputType: 'select',
        option: 'select',
        data: info.insuranceList,
        arrow: true,
        style: { marginRight: '10px' },
      },
    ],
    [
      {
        key: 'customer_car',
        label: MESSAGE.CUSTOMER_CAR,
        inputType: 'text',
        style: { width: inputWidth },
      },

      {
        key: 'registration_id',
        label: '접수번호',
        inputType: 'text',
        style: { width: '150px' },
      },
    ],
    [
      {
        key: 'fault_rate',
        label: '고객 과실',
        inputType: 'select',
        option: 'select',
        data: info.faultRateList,
        arrow: true,
        style: { width: '140px' },
      },
      {
        key: 'claim_insurance_manager',
        label: '담당자명',
        inputType: 'text',
        style: { width: '150px' },
        btn: '찾기',
        btnStyle: { marginLeft: '10px' },
        btnFunc: searchFunc,
      },
    ],
    [
      {
        key: 'customer_car_engine',
        label: '배기량',
        placeholder: '배기량 숫자만 입력',
        inputType: 'number',
        style: { width: inputWidth, marginRight: '10px' },
      },
      {
        key: 'customer_car_age',
        label: '연식',
        placeholder: '연식 숫자만 입력',
        inputType: 'number',
        style: { width: '150px', marginRight: '10px' },
      },
      {
        key: 'claim_insurance_contact',
        label: '담당자 연락처',
        placeholder: "'-'를 제외한 숫자만 입력",
        inputType: 'number',
        flex: 2,
        style: { width: '150px' },
      },
    ],
    [
      {
        key: 'industrial_company',
        label: '공업사',
        inputType: 'text',
        style: { width: inputWidth, marginRight: '10px' },
      },
      {
        key: 'industrial_company_tel',
        label: '공업사 연락처',
        placeholder: "'-'를 제외한 숫자만 입력",
        inputType: 'text',
        style: { width: '150px', marginRight: '10px' },
      },
      {
        key: 'claim_fax',
        label: '담당자 팩스',
        placeholder: "'-'를 제외한 숫자만 입력",
        inputType: 'text',
        flex: 2,
        style: { width: '150px' },
      },
    ],
  ];
};

export const getCustomerInfoTableData = (info, buttonFunc) => {
  let changeButton = null;

  if (info.canModify) {
    if (info.is_corporation) {
      changeButton = '개인 전환';
    } else {
      changeButton = '법인 전환';
    }
  }

  return [
    [
      {
        label: MESSAGE.CUSTOMER_NAME,
        value: parser.defaultParser(info.customer_name),
        option: 'output',
        flex: 1,
      },
      {
        label: '고객구분',
        value: info.is_corporation ? '법인' : '개인',
        option: 'output',
        flex: 1,
        btn: changeButton,
        btnFunc: info.is_corporation
          ? buttonFunc.changeToPersonal
          : buttonFunc.openCorporationInfoModal,
      },
      {
        label: '고객 연락처',
        value: parser.contractParser(info.customer_contact),
        option: 'output',
        flex: 2,
      },
    ],
    [
      {
        label: '생년월일',
        value: info.customer_id_number1
          ? `${info.customer_id_number1}-${info.customer_id_number2}`
          : '',
        option: 'output',
      },
      {
        label: '주소',
        value: parser.defaultParser(info.customer_address),
        option: 'output',
      },
    ],
  ];
};

export const getPartnerInfoTableData = (info) => {
  return [
    [
      {
        label: '소개처 명',
        value: parser.defaultParser(info.recommender_name),
        option: 'output',
      },
      {
        label: '소개처 번호',
        value: parser.contractParser(info.recommender_contact),
        option: 'output',
      },
    ],
    [
      {
        label: '소개처 담당자',
        value: parser.defaultParser(info.recommender_manager),
        option: 'output',
      },
      {
        label: '소개처 알림톡',
        value: info.is_send_recommender_message ? '발송' : '미발송',
        option: 'output',
        alimtalk: info.is_send_recommender_message,
        tooltip: question,
        tooltipMessage:
          '배차, 반납, 계약유형전환(보험→일반, 되돌리기) 안내 알림톡이 소개처 담당자에게 발송됩니다.',
        tooltipStyle: { left: '150px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
      },
    ],
  ];
};

export const getSalesEmployeeInfoTableData = (info) => {
  return [
    [
      {
        label: '영업 직원',
        value: parser.defaultParser(info.sales_employee_name),
        option: 'output',
      },
    ],
  ];
};

export const getBusinessInfoTableData = (info) => {
  return [
    [
      {
        label: '사업자 명',
        value: parser.defaultParser(info.business_name),
        option: 'output',
      },
    ],
    [
      {
        label: '사업자 번호',
        value: parser.bizNumParser(info.registration_num),
        option: 'output',
      },
      {
        label: '사업자 주소',
        value: parser.abbreviation(parser.defaultParser(info.company_address)),
        option: 'output',
      },
    ],
  ];
};

export const modifyContractInfoTableData = (info) => {
  return [
    [
      {
        label: '대여차종',
        value: parser.defaultParser(parser.abbreviation(info.rent_car_name, 15)),
        option: 'output',
      },
      {
        key: 'delivered',
        label: '배차일시',
        option: 'date_time',
        calcDate: false,
      },
    ],
    [
      {
        label: MESSAGE.RENTAL_CAR_NUMBER,
        value: parser.defaultParser(info.rent_car_number),
        option: 'output',
      },
      {
        key: 'return',
        label: '반납일시',
        value: info.return_date_time ? null : '-',
        option: info.return_date_time ? 'date_time' : 'output',
        calcDate: false,
      },
    ],
  ];
};

export const getInsuranceRemarkTableData = (info) => {
  return [
    [
      {
        label: '보험사 전달사항',
        value: info.insurance_memo
          ? parser.defaultParser(info.insurance_memo)
          : MESSAGE.REGISTER_INSURANCE_TRANS_POINT,
        option: 'output',
        valueStyle: { fontSize: '13px', color: '#d6d6d6' },
      },
    ],
  ];
};

export const modifyInsuranceMemoTableData = () => {
  return [
    [
      {
        key: 'memo',
        label: '보험사 전달사항',
        type: 'textarea',
        maxByte: 150,
        placeholder: '내용을 입력하세요.',
        style: {
          width: '367px',
          height: '112px',
        },
      },
    ],
  ];
};

export const getModifyDepositCost = (info) => {
  return [
    [
      {
        key: 'paymentDate',
        label: '입금일',
        type: 'date',
        necessary: true,
      },
    ],
    [
      {
        key: 'paymentCostType',
        label: '구분',
        type: 'select',
        select: {
          data: info.paymentHistoryList,
          arrow: true,
          style: { width: '150px' },
        },
        necessary: true,
      },
    ],
    [
      {
        key: 'paymentCost',
        label: '입금액',
        input: {
          type: 'text',
          disabled: info.paymentCostType ? info.paymentCostType.includes('선수금') : false,
          comma: true,
          style: { width: '150px' },
        },
        necessary: true,
      },
    ],
    [
      {
        key: 'paymentMethodId',
        label: '입금경로',
        type: 'select',
        select: {
          data: info.paymentMethodList,
          arrow: true,
          style: { width: 250 },
        },
      },
    ],
    [
      {
        key: 'memo',
        label: MESSAGE.ESPECIAL_POINT,
        type: 'textarea',
        maxByte: 120,
        placeholder: '특이사항이 있을 경우 입력 해주세요.',
        style: { height: 120 },
      },
    ],
  ];
};

//// 계약서 상세
export const getContractPasswordTableData = (info, btnFunc) => {
  return [
    [
      {
        key: 'contractId',
        label: '계약서 번호',
        type: 'output',
      },
      {
        key: 'password',
        label: info.label,
        value: parser.defaultParser(String(info.password)),
        type: 'output',
        btn: {
          name: MESSAGE.VIEW_CONTRACT,
          func: btnFunc,
        },
      },
    ],
  ];
};

export const getContractSwitchToNormalTableData = (info) => {
  const radioData = [{ label: '일 렌트', value: 'daily', match: 'day', default: true }];

  return [
    [
      {
        key: 'rent_type',
        label: '렌트 종류',
        type: 'radio',
        radio: {
          data: radioData,
        },
        tooltip: {
          img: question,
          style: { marginLeft: '68px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
          message: '보험에서 일반으로 계약서 변경시에는 일렌트로만 가능합니다.',
        },
      },
    ],
    [
      {
        key: 'delivered_at',
        label: '배차 일시',
        type: 'fullDate',
        necessary: true,
        calendarOption: 'start',
      },
    ],
    [
      {
        key: 'expect_return_date',
        label: info.claimState === 'using_car' ? MESSAGE.EXPECT_RETURN_DATE : '반납 일시',
        type: 'fullDate',
        necessary: true,
        calendarOption: 'end',
      },
    ],
    [
      {
        key: 'total_cost',
        label: '총 요금',
        unit: '원',
        input: {
          type: 'number',
          comma: true,
          placeholder: '예) 120,000',
          style: { width: '150px' },
        },
        necessary: true,
      },
    ],
    [
      {
        key: 'contract_memo',
        label: '계약서 메모',
        type: 'textarea',
        style: { height: 80 },
        maxByte: 120,
        placeholder: '예) 특이사항으로 일반으로 전환함.',
      },
    ],
  ];
};

export const getContractSwitchDayMonthTableData = (info) => {
  const deposit = [
    {
      key: 'security_deposit',
      label: '보증금',
      unit: '원',
      input: {
        type: 'number',
        comma: true,
        placeholder: '예) 100,000',
        style: { width: '150px' },
      },
      necessary: false,
    },
  ];
  const expectReturnDate = [
    {
      key: 'expect_return_date',
      label: info.state === 'using_car' ? MESSAGE.EXPECT_RETURN_DATE : '반납일',
      type: 'fullDate',
      necessary: true,
    },
  ];
  const totalCost = [
    {
      key: 'total_cost',
      label: '총 요금',
      unit: '원',
      input: {
        type: 'number',
        comma: true,
        placeholder: '예) 120,000',
        style: { width: '150px' },
      },
      necessary: true,
    },
  ];
  const contractMemo = [
    {
      key: 'contract_memo',
      label: '계약서 메모',
      type: 'textarea',
      maxByte: 120,
      placeholder: `예) 특이사항 발생으로 ${info.isMonth ? '월' : '일'} 렌트로 전환함.`,
      subLabel: '계약서 메모에 공유됩니다.',
      necessary: false,
      style: {
        height: '112px',
      },
    },
  ];

  if (info.isConvertedDayToMonthly) {
    return [expectReturnDate, totalCost, deposit, contractMemo];
  }

  return [expectReturnDate, totalCost, contractMemo];
};

export const getContractBasicInfoTableData = (info) => {
  return [
    [
      {
        label: '업체명',
        value: parser.defaultParser(info.rent_company_name),
        option: 'output',
      },
      {
        label: '연락처',
        value: parser.contractParser(info.rent_manager_contact),
        option: 'output',
      },
    ],
    [
      {
        label: '대여 차종',
        value: parser.defaultParser(info.rent_car_name),
        option: 'output',
      },
      {
        label: '차량번호',
        value: parser.defaultParser(info.rent_car_number),
        option: 'output',
      },
      {
        label: '유종',
        value: parser.defaultParser(info.oil_type),
        option: 'output',
        flex: 2,
      },
    ],
    [
      {
        label: '배차 유류량',
        value: parser.gasChargeParser(info.gas_charge),
        option: 'output',
      },
      {
        label: '반납 유류량',
        value: parser.gasChargeParser(info.return_gas_charge),
        option: 'output',
      },
    ],
    [
      {
        label: '배차 시 km',
        value: parser.drivenDistanceParser(info.driven_distance),
        option: 'output',
      },
      {
        label: '반납 시 km',
        value: parser.drivenDistanceParser(info.driven_distance_upon_return),
        option: 'output',
      },
    ],
  ];
};

export const getContractBaicInfoTableData = (info) => {
  return [
    [
      {
        label: '고객차량 번호',
        value: parser.defaultParser(info.customer_car_number),
        option: 'output',
      },
      {
        label: MESSAGE.INSURANCE_COMPANY_NAME,
        value: parser.defaultParser(info.insurance_company),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.CUSTOMER_CAR,
        value: parser.defaultParser(info.customer_car),
        option: 'output',
      },
      {
        label: '접수번호',
        value: parser.defaultParser(info.registration_id),
        option: 'output',
      },
    ],
    [
      {
        label: '고객 과실',
        value: info.fault_rate ? `${info.fault_rate}%` : '-',
        option: 'output',
      },
      {
        label: '담당자명',
        value: parser.defaultParser(info.insurance_manager_name),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.ENGINE_TYPE,
        value: parser.engineTypeParser(info.customer_car_engine),
        option: 'output',
      },
      {
        label: '담당자 연락처',
        value: parser.contractParser(info.insurance_manager_contact),
        option: 'output',
      },
    ],
    [
      {
        label: '연식',
        value: parser.defaultParser(info.customer_car_age),
        option: 'output',
      },
      {
        label: '담당자 팩스',
        value: parser.contractParser(info.insurance_fax),
        option: 'output',
      },
    ],
  ];
};

export const getContractInsuranceInfoTableData = (info) => {
  return [
    [
      {
        label: '보험 가입 연령',
        value: parser.insuranceJoinAgeParser(info.insurance_age),
        option: 'output',
      },
      {
        label: MESSAGE.SELF_INSURANCE,
        value: parser.selfCarInsuranceParser(info.self_car_insurance),
        option: 'output',
      },
    ],
    [
      {
        label: '자차 한도(만원)',
        value: parser.insuranceFeeParser(info.self_car_insurance_limit),
        option: 'output',
      },
      {
        label: MESSAGE.SELF_CAR_INDEMNFICATION_COST,
        value: parser.insuranceFeeParser(info.indemnfication_fee),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.INSURANCE_OPPONENT_LIMIT,
        value: parser.insuranceFeeParser(info.insurance_person_limit),
        option: 'output',
      },
      {
        label: '대인 면책금(인당)',
        value: parser.insuranceFeeParser(info.people_indemnfication_fee, '없음'),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.INSURANCE_OBJECT_LIMIT,
        value: parser.insuranceFeeParser(info.insurance_object_limit),
        option: 'output',
      },
      {
        label: '대물 면책금(건당)',
        value: parser.insuranceFeeParser(info.object_indemnfication_fee, '없음'),
        option: 'output',
      },
    ],
    [
      {
        label: MESSAGE.INSURANCE_ONESELF_LIMIT,
        value: parser.insuranceFeeParser(info.insurance_self_limit),
        option: 'output',
      },
      {
        label: MESSAGE.ONESELF_INDEMNFICATION_COST,
        value: parser.insuranceFeeParser(info.self_indemnification_fee, '없음'),
        option: 'output',
      },
    ],
  ];
};

export const modifyContractBasicInfoTableData = (info, searchFunc) => {
  return [
    [
      {
        key: 'customer_car_number',
        label: '고객차량 번호',
        inputType: 'text',
        style: { width: inputWidth },
      },
      {
        key: 'insurance_company',
        label: MESSAGE.INSURANCE_COMPANY_NAME,
        inputType: 'select',
        option: 'select',
        data: info.insuranceList,
        arrow: true,
        style: { marginRight: '5px', width: '155px' },
      },
    ],
    [
      {
        key: 'customer_car',
        label: MESSAGE.CUSTOMER_CAR,
        inputType: 'text',
        style: { width: inputWidth },
      },
      {
        key: 'registration_id',
        label: '접수번호',
        inputType: 'text',
        style: { width: '200px' },
      },
    ],
    [
      {
        key: 'fault_rate',
        label: '고객 과실',
        inputType: 'select',
        option: 'select',
        data: info.faultRateList,
        arrow: true,
        style: { width: inputWidth },
      },
      {
        key: 'insurance_manager_name',
        label: '담당자명',
        btn: '찾기',
        btnStyle: { marginLeft: '10px' },
        btnFunc: searchFunc,
        inputType: 'text',
        style: { width: inputWidth },
      },
    ],
    [
      {
        key: 'customer_car_engine',
        label: MESSAGE.ENGINE_TYPE,
        inputType: 'number',
        placeholder: '배기량 숫자만 입력',
        style: { width: inputWidth },
      },
      {
        key: 'insurance_manager_contact',
        label: '담당자 연락처',
        inputType: 'number',
        style: { width: inputWidth },
      },
    ],
    [
      {
        key: 'customer_car_age',
        label: '연식',
        inputType: 'number',
        placeholder: '연식 숫자만 입력',
        style: { width: inputWidth },
      },
      {
        key: 'insurance_fax',
        label: '담당자 팩스',
        inputType: 'number',
        style: { width: inputWidth },
      },
    ],
  ];
};

export const getContractCustomerInfoTableData = (info) => {
  return [
    [
      {
        label: MESSAGE.CUSTOMER_NAME,
        value: parser.defaultParser(info.customer_name),
        option: 'output',
      },
      {
        label: '고객 연락처',
        value: parser.contractParser(info.customer_contact),
        option: 'output',
      },
    ],
    [
      {
        label: '생년월일',
        value: parser.defaultParser(
          info.customer_id_number2
            ? `${info.customer_id_number1}-${info.customer_id_number2}`
            : info.customer_id_number1
        ),
        option: 'output',
      },
      {
        label: '주소',
        value: parser.defaultParser(info.customer_address),
        option: 'output',
      },
    ],
    [
      'column',
      [
        {
          label: '면허번호',
          value: parser.defaultParser(info.customer_driver_license),
          option: 'output',
        },
        {
          label: '면허 종류',
          value: parser.defaultParser(info.customer_driver_type),
          option: 'output',
        },
        {
          label: '만기일',
          value: parser.defaultParser(info.customer_license_limit),
          option: 'output',
        },
      ],
      {
        label: '운전자서명',
        value: info.signature_image ? `${s3}${info.signature_image}` : null,
        valueType: 'img',
        imgStyle: { width: '100px', height: '100px' },
        option: 'output',
        column: 3,
      },
    ],
  ];
};

export const getLicenseButtonName = (info, isSecond) => {
  //defaultInfo-> 작성당시의 초기상태,초기상태에따라 진위여부 했는지 안했는지를 판단한다.
  const init_license_certificated = isSecond
    ? info.defaultInfo?.second_driver_license_certificated
    : info.defaultInfo.license_certificated;
  const license_certificated = isSecond
    ? info.second_driver_license_certificated
    : info.license_certificated;
  const license_certificated_code = isSecond
    ? info.second_driver_license_certificated_code
    : info.license_certificated_code;
  const isSuccess = ['00', '02', '03', '04', '11', '12', '13', '14'].includes(
    license_certificated_code
  );

  if (init_license_certificated === true) {
    //작성시 진위여부조회 한것
    if (license_certificated) {
      if (!isSecond) {
        //제1운전자
        if (isSuccess) {
          return '조회완료';
        }

        return '수정';
      }

      //제2운전자
      if (isSuccess) {
        return '초기화';
      }

      return '수정';
    }

    return '면허 진위 조회';
  }

  ///작성시 진위여부조회 하지 않은것
  if (license_certificated) {
    if (!isSecond) {
      //제1운전자
      if (isSuccess) {
        return '초기화';
      }

      return '수정';
    }

    //제2운전자
    if (isSuccess) {
      return '초기화';
    }

    return '수정';
  }

  return '면허 진위 조회';
};

export const modifyContractInsuranceInfoTableData = (info, saveLicenseResult, store) => {
  const licenseCheckBtnName = getLicenseButtonName(info);

  return [
    [
      {
        key: 'customer_name',
        label: MESSAGE.CUSTOMER_NAME,
        inputType: 'text',
        style: { width: inputWidth },
        disabled: info.license_certificated,
      },
      {
        key: 'customer_contact',
        label: '고객 연락처',
        inputType: 'number',
        style: { width: '150px' },
      },
    ],
    [
      {
        key: 'customer_id_number',
        label: '생년월일',
        inputType: 'number',
        placeholder: '-',
        option: 'double',
        maxLength: [6, 7],
        disabled: info.license_certificated,
      },
      {
        key: 'customer_address',
        label: '주소',
        option: 'address',
        inputType: 'text',
      },
    ],
    [
      'column',
      [
        {
          key: 'customer_driver_license',
          label: '면허 번호',
          inputType: 'text',
          disabled: info.license_certificated,
          style: { width: '150px' },
        },
        {
          key: 'customer_driver_type',
          label: '면허 종류',
          inputType: 'select',
          option: 'select',
          data: driver_type,
          arrow: true,
          disabled: info.license_certificated,
          btn: licenseCheckBtnName,
          btnStyle:
            licenseCheckBtnName === '조회완료'
              ? { backgroundColor: '#bebdbd', border: 'none', margin: '0 5px' }
              : { margin: '0 5px' },
          btnFunc: async () => {
            switch (licenseCheckBtnName) {
              case '수정':
                saveLicenseResult({
                  license_certificated: false,
                  license_certificated_code: null,
                });
                break;
              case '초기화':
                if (confirm('면허 정보를 초기화하시겠습니까?')) {
                  saveLicenseResult('clear');
                }

                break;
              case '면허 진위 조회': //do not use
                const result = await contractUtils.checkLicense('customer', info, store);

                saveLicenseResult(result);
                break;
              default:
                break;
            }
          },
        },
        {
          key: 'customer_license_limit',
          label: '만기일',
          inputType: 'text',
          style: { width: inputWidth },
        },
      ],
      {
        label: '운전자서명',
        value: info.signature_image ? `${s3}${info.signature_image}` : null,
        valueType: 'img',
        imgStyle: { width: '100px', height: '100px' },
        option: 'output',
        column: 3,
      },
    ],
  ];
};

export const getSecondDriverInfoTableData = (info) => {
  return [
    [
      {
        label: '운전자명',
        value: parser.defaultParser(info.second_driver_name),
        option: 'output',
      },
      {
        label: '연락처',
        value: parser.contractParser(info.second_driver_contact),
        option: 'output',
      },
    ],
    [
      {
        label: '생년월일',
        value: parser.defaultParser(
          info.second_driver_id_number2
            ? `${info.second_driver_id_number1}-${info.second_driver_id_number2}`
            : info.second_driver_id_number1
        ),
        option: 'output',
      },
      {
        label: '주소',
        value: parser.defaultParser(info.second_driver_address),
        option: 'output',
      },
    ],
    [
      {
        label: '면허번호',
        value: parser.defaultParser(info.second_driver_license),
        option: 'output',
      },
      {
        label: '면허 종류',
        value: parser.defaultParser(info.second_driver_type),
        option: 'output',
      },
      {
        label: '만기일',
        value: parser.defaultParser(info.second_driver_license_limit),
        option: 'output',
      },
    ],
  ];
};

export const modifySecondDriverInfoTableData = (info, saveLicenseResult, store) => {
  const licenseCheckBtnName = getLicenseButtonName(info, 'second_driver');

  return [
    [
      {
        key: 'second_driver_name',
        label: '제 2운전자명',
        disabled: info.second_driver_license_certificated,
        inputType: 'text',
        necessary: true,
        style: { width: inputWidth },
      },
      {
        key: 'second_driver_contact',
        label: '제 2운전자 연락처',
        inputType: 'number',
        maxLength: 11,
        necessary: true,
        style: { width: '150px' },
      },
    ],
    [
      {
        key: 'second_driver_id_number',
        label: '생년월일',
        inputType: 'number',
        disabled: info.second_driver_license_certificated,
        placeholder: '-',
        option: 'double',
        maxLength: [6, 7],
        necessary: true,
      },
      {
        key: 'second_driver_address',
        label: '주소',
        option: 'address',
        inputType: 'text',
        necessary: true,
      },
    ],
    [
      {
        key: 'second_driver_license',
        label: '면허 번호',
        disabled: info.second_driver_license_certificated,
        inputType: 'text',
        style: { width: '150px' },
      },
      {
        key: 'second_driver_license_limit',
        label: '만기일',
        placeholder: '예) 20201231',
        inputType: 'text',
      },
    ],
    [
      {
        key: 'second_driver_type',
        label: '면허 종류',
        inputType: 'select',
        option: 'select',
        data: driver_type,
        arrow: true,
        listView: 'up',
        btn: licenseCheckBtnName,
        btnStyle:
          licenseCheckBtnName === '조회완료'
            ? { backgroundColor: '#bebdbd', border: 'none', margin: '0 5px' }
            : { margin: '0 5px' },
        btnFunc: async () => {
          switch (licenseCheckBtnName) {
            case '수정':
              saveLicenseResult({
                second_driver_license_certificated: false,
                second_driver_license_certificated_code: null,
              });
              break;
            case '초기화':
              if (confirm('면허 정보를 초기화하시겠습니까?')) {
                saveLicenseResult('clear');
              }

              break;
            case '면허 진위 조회': //do not use
              const result = await contractUtils.checkLicense('second_driver', info, store);

              saveLicenseResult(result);
              break;
            default:
              break;
          }
        },
      },
    ],
  ];
};

export const changeCarInfoTableData = (info) => {
  return [
    [
      {
        label: '배차일시',
        value: info.delivered_date,
        option: 'output',
      },
    ],
    [
      {
        key: 'expect_return',
        label: '반납 일시',
        option: 'date_time',
        optionHeight: '145px',
        calcDate: false,
        necessary: true,
        calendarOption: 'end',
        minDate: 'current',
      },
    ],
    [
      {
        key: 'return_gas_charge',
        label: '반납 유류량',
        necessary: true,
        inputType: 'select',
        option: 'select',
        optionHeight: '115px',
        data: info.gasChargeSelectOptions,
        arrow: true,
      },
      {
        key: 'return_distance',
        label: '반납 시 km',
        comma: true,
        inputType: 'number',
        style: { width: inputWidth },
      },
    ],
  ];
};

export const setReturnInfoTableData = (info) => {
  return [
    [
      {
        key: 'expect_return',
        label: '반납 일시',
        option: 'date_time',
        optionHeight: '185px', // 시 select option height
        calcDate: false,
        necessary: true,
        calendarOption: 'end',
        minDate: 'current',
      },
    ],
    [
      {
        key: 'delivery_gas_charge',
        label: '배차 유류량',
        inputType: 'text',
        disabled: true,
        placeholder: '-',
        style: { width: inputWidth },
      },
      {
        key: 'return_gas_charge',
        label: '반납 유류량',
        necessary: true,
        inputType: 'select',
        option: 'select',
        optionHeight: '145px',
        data: info.gasChargeSelectOptions,
        arrow: true,
      },
    ],
    [
      {
        key: 'delivery_distance',
        label: '배차 시 km',
        inputType: 'text',
        disabled: true,
        style: { width: inputWidth },
      },
      {
        key: 'return_distance',
        label: '반납 시 km',
        comma: true,
        inputType: 'number',
        style: { width: inputWidth },
      },
    ],
  ];
};

export const getEtcContractTableData = [
  [
    {
      key: 'memo',
      label: '내용',
      type: 'output',
      style: { padding: 10 },
    },
  ],
];

export const setPreClaimTableData = () => {
  return [
    [
      {
        key: 'expect_return',
        label: '반납 일시',
        option: 'date_time',
        calcDate: false,
        calendarOption: 'end',
        minDate: 'current',
      },
    ],
  ];
};

export const corporationTableData = (info) => {
  return [
    [
      {
        key: 'corporation_name',
        label: '법인명',
        type: 'output',
      },
      {
        key: 'business_number',
        label: '사업자번호',
        type: 'output',
      },
    ],
    [
      {
        key: 'corporation_number',
        label: '법인번호',
        type: 'output',
      },
      {
        key: 'address',
        label: '주소',
        type: 'output',
      },
    ],
    [
      {
        key: 'manager_name',
        label: '담당자명',
        type: 'output',
      },
      {
        key: 'manager_contact',
        label: '담당자 연락처',
        type: 'output',
      },
    ],
  ];
};

export const modifyCorporationTableData = (state) => {
  return [
    [
      {
        key: 'corporation_name',
        label: '법인명',
        input: {
          type: 'text',
          style: { width: 150 },
        },
        necessary: true,
      },
      {
        key: 'business_number',
        label: '사업자번호',
        input: {
          type: 'number',
          maxLength: 10,
          style: { width: 150 },
        },
      },
    ],
    [
      {
        key: 'corporation_number',
        label: '법인번호',
        type: 'double',
        input: {
          type: 'number',
          placeholder: '-',
          style: { width: '100px' },
          maxLength: [6, 7],
        },
      },
      {
        key: 'address',
        label: '주소',
        input: {
          type: 'text',
          placeholder: '주소를 입력해주세요',
          style: { width: '100%' },
        },
        // type: 'address',
      },
    ],
    [
      {
        key: 'manager_name',
        label: '담당자명',
        input: {
          type: 'text',
          style: { width: '150px' },
        },
      },
      {
        key: 'manager_contact',
        label: '담당자 연락처',
        input: {
          type: 'number',
          placeholder: MESSAGE.PLACEHOLDER_CONTRACT,
          maxLength: 11,
          style: { width: '200px' },
        },
      },
    ],
  ];
};

// info(reservations: Array<Object>) [0]
// ㄴschedules: Array<Object> [0]
//  ㄴcar: Object
export const checkConnectBookingCommonTableData = (info, additional) => {
  const schedules = toJS(info.schedules)[0];
  const rentCarNumber = schedules?.car?.identity;

  const viewData = [
    [
      {
        key: 'rent_car_number',
        label: '대여 차량번호',
        type: 'output',
      },
    ],
    [
      {
        key: 'driver_name',
        label: '운전자명',
        type: 'output',
      },
    ],
    [
      {
        key: 'driver_contact',
        label: '운전자 연락처',
        type: 'output',
      },
    ],
  ].concat(additional.viewData);

  let stateData = {
    rent_car_number: rentCarNumber,
    driver_name: info.driver_name,
    driver_contact: phoneNumberHyphen(info.driver_contact),
  };

  stateData = Object.assign(stateData, additional.stateData);

  return { viewData, stateData };
};

export const checkConnectBookingInsuranceTableData = (info) => {
  const schedules = toJS(info.schedules)[0];
  const bookingAt = dayjs(schedules?.start_at);
  const bookingAtFormatted = bookingAt.isValid()
    ? `${bookingAt.format('MM/DD/')}${dayOfWeekParser(bookingAt.get('day'))} ${bookingAt.format(
        'HH:mm'
      )}`
    : '-';
  const viewData = [
    [
      {
        key: 'customer_car_number',
        label: '사고 차량번호',
        type: 'output',
      },
    ],
    [
      {
        key: 'registration_num',
        label: '접수번호',
        type: 'output',
      },
    ],
    [
      {
        key: 'booking_at',
        label: '예약 대여일시',
        type: 'output',
      },
    ],
  ];

  const stateData = {
    customer_car_number: info.customer_car_number,
    registration_num: info.insurance_company
      ? `${info.insurance_company} ${contractUtils.insuranceDevide(
          info.insurance_company,
          info.registration_num
        )}`
      : '-',
    booking_at: bookingAtFormatted,
  };

  return { viewData, stateData };
};

export const checkConnectBookingNormalTableData = (info) => {
  const schedules = toJS(info.schedules)[0];
  const bookingStartAt = dayjs(schedules?.start_at);
  const bookingEndAt = dayjs(schedules?.end_at);
  const bookingDateDiff = diffDate(bookingEndAt, bookingStartAt);

  if (bookingDateDiff.year > 0) {
    bookingDateDiff.month += bookingDateDiff.year * 12;
  }

  const bookingAtFormatted =
    bookingStartAt.isValid() && bookingEndAt.isValid()
      ? `${bookingStartAt.format('MM/DD/dd HH:mm')} ~ ${bookingEndAt.format('MM/DD/dd HH:mm')} (${
          bookingDateDiff.month > 0 ? `${bookingDateDiff.month}개월` : ''
        } ${bookingDateDiff.day}일 ${bookingDateDiff.hour}시간)`
      : '-';

  const viewData = [
    [
      {
        key: 'booking_at',
        label: '예약 대여기간',
        type: 'output',
        style: { width: 220, lineHeight: 1.4, height: 60 },
      },
    ],
  ];

  const stateData = {
    booking_at: bookingAtFormatted,
  };

  return { viewData, stateData };
};

export const isSelectedAfter220714 = (createAt) => {
  const selectedDate = dayjs(createAt, 'YYYY-MM-DD HH:mm:ss');
  const referanceDate = dayjs('2022-07-14 00:00:00', 'YYYY-MM-DD HH:mm:ss');

  return selectedDate.isAfter(referanceDate);
};
