import isPlainObject from 'lodash/isPlainObject';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import apiAutoUp from '../../../api/autoUp';
import { setCashPointCookie } from '../../../helpers/callApi';
import costComma from '../../../helpers/costComma';
import styles from './CarInfoSearchModalStyle.module.scss';
@inject('modalStore')
@inject('authStore')
@inject('menuStore')
@observer
class CarInfoSearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  validate = () => {
    if (this.props.data.auto_up_free_coupon !== 0) {
      return this.submit();
    }

    if (this.props.data.cash + this.props.data.point < 500) {
      return alert('캐시 / 포인트가 부족합니다.\n캐시 관리 > 입금 안내를 확인해 주세요.');
    }

    return this.submit();
  };

  submit = () =>
    catchAsync(async () => {
      let sendData = {
        car_number: this.props.data.car_number,
        from_location: this.props.data.from,
      };

      if (this.props.data.claim_id) {
        sendData.claim_id = this.props.data.claim_id;
      }

      const response = await apiAutoUp.setAutoUpCash(sendData);
      const responseData = response.data;

      if (response.status === 200) {
        if (this.props.setCarInfo) {
          this.props.setCarInfo(responseData);
        }

        if (this.props.refreshFunc) {
          this.props.refreshFunc();
        }

        if (isPlainObject(responseData?.user_info)) {
          this.props.authStore.setValue({ key: 'cash', value: responseData.user_info.cash });
          this.props.authStore.setValue({ key: 'point', value: responseData.user_info.point });
          setCashPointCookie(responseData.user_info);
        }

        this.props.closeModal();
      }
    });

  render() {
    return (
      <div className={styles.search_car_info_modal_wrap}>
        <div className={styles.car_name_wrap}>
          <div className={styles.car_name_label}>차량명</div>
          <div className={styles.car_name}>{this.props.data.car_name}</div>
        </div>
        <div className={styles.car_info_container}>
          <div className={styles.car_info_wrap}>
            <div className={styles.car_info_label}>보유 캐시</div>
            <div className={styles.car_info_content}>{costComma(this.props.data.cash)}</div>
          </div>
          <div className={styles.car_info_wrap}>
            <div className={styles.car_info_label}>보유 포인트</div>
            <div className={styles.car_info_content}>{costComma(this.props.data.point)}</div>
          </div>
          <div className={styles.car_info_wrap}>
            <div className={styles.car_info_label}>연식조회 무료 쿠폰</div>
            <div className={styles.car_info_content}>{this.props.data.auto_up_free_coupon}</div>
          </div>
        </div>
        <div className={styles.notice_text_wrap}>
          <div className={styles.notice_text}>조회된 차종이 실제 차종과 같은 차종인가요?</div>
          <div className={styles.notice_text}>
            &apos;확인&apos; 버튼을 누르시면&nbsp;<span>500</span> 캐시/포인트가 차감됨과 동시에
          </div>
          <div className={styles.notice_text}>조회 차량의 배기량과 연식이 제공됩니다.</div>
        </div>
        <div className={styles.button_area}>
          <div className={styles.cancel_button} onClick={() => this.props.modalStore.modalClose()}>
            {MESSAGE.CANCEL}
          </div>
          <div className={styles.complete_button} onClick={this.validate}>
            {MESSAGE.CONFIRM}
          </div>
        </div>
      </div>
    );
  }
}

export default CarInfoSearchModal;
