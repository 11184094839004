import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
@inject('modalStore')
@observer
class FindAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search: false,
    };
  }

  componentDidMount() {
    const url = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js';

    if (typeof window !== 'undefined') {
      const $script = require('scriptjs');

      $script(url, () => {
        this.execDaumPostcode();
      });
    }
  }

  execDaumPostcode = () => {
    const { height, getData } = this.props;

    if (typeof window !== 'undefined') {
      const elementWrap = document.getElementById('find_address_wrap');

      if (elementWrap) {
        if (height) elementWrap.style.height = `${height}px`;

        let currentScroll = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

        // eslint-disable-next-line no-undef
        new daum.Postcode({
          oncomplete(data) {
            try {
              if (typeof window.AndroidInterface !== 'undefined') {
                window.AndroidInterface.getAddress(data.zonecode, data.roadAddress);
              }

              if (getData) {
                // if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
                getData(data.roadAddress, data);
                // } else { // 사용자가 지번 주소를 선택했을 경우(J)
                //   getData(data.jibunAddress, data)
                // }
                elementWrap.style.height = 0;
                elementWrap.style.overflow = 'hidden';
              }
            } catch (e) {
              console.log(e);
            }

            document.body.scrollTop = currentScroll;
          },
          onresize(size) {
            elementWrap.style.height = `${height}px` || `${size.height}px`;
          },
          width: '100%',
          height: '100%',
        }).embed(elementWrap);
      }
    }
  };

  render() {
    return <div id="find_address_wrap" />;
  }
}

export default FindAddress;
