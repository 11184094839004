import { makeAutoObservable } from 'mobx';

import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';

class InsuranceSwitchStore implements BaseStore<InsuranceSwitchStore> {
  claim_id = null;
  contract_id = null;
  personal_number1 = null;
  claim_state = null;
  car_name = null;
  car_number = null;
  return_request_date = null;
  return_request_location = null;
  return_request_timepoint = null;
  delivered_date = null;
  return_date = null;
  insurance = null;
  company_contact = null;

  setValues = (obj) => {
    for (const [key, value] of Object.entries(obj)) {
      this[key] = value;
    }
  };

  setObject = (data) => _setObject(this, data);

  constructor() {
    makeAutoObservable(this);
  }
}

export default InsuranceSwitchStore;
