import { css } from 'styled-components';

export const TEXT = {
  Display2_Gray8_Bold_28px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 28px;
  `,
  Display2_Blue5_Bold_28px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-blue-5);
    font-size: 28px;
  `,
  Title1_Gray8_Bold_24px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 24px;
  `,
  Title1_Gray8_Regular_24px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 24px;
  `,
  Title2_Gray8_Bold_22px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 22px;
  `,
  Title2_Gray8_Regular_22px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 22px;
  `,
  Title3_Gray7_Bold_22px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-7);
    font-size: 22px;
  `,

  Title3_Gray8_Bold_22px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 22px;
  `,
  Title3_Blue4_Bold_22px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-blue-4);
    font-size: 22px;
  `,
  Title3_Red4_Bold_22px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-red-4);
    font-size: 22px;
  `,
  Headline1_Gray8_Bold_20px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 20px;
  `,
  Headline1_Gray8_Regular_20px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 20px;
  `,
  Headline2_Blue4_Medium_18px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-blue-4);
    font-size: 18px;
  `,
  Headline2_Gray8_Bold_18px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 18px;
  `,
  Headline2_Gray8_Medium_18px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-gray-8);
    font-size: 18px;
  `,
  Headline2_Gray8_Regular_18px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 18px;
  `,
  Headline2_Gray7_Bold_18px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 18px;
  `,
  Headline2_White_Medium_18px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--white);
    font-size: 18px;
  `,
  Body1_Gray5_Bold_16px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-5);
    font-size: 16px;
  `,
  Body1_Gray6_Regular_16px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-6);
    font-size: 16px;
  `,
  Body1_Gray7_Bold_16px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-7);
    font-size: 16px;
  `,
  Body1_Gray7_Regular_16px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-7);
    font-size: 16px;
  `,
  Body1_Gray8_Bold_16px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 16px;
  `,
  Body1_Gray8_Regular_16px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 16px;
  `,
  Body2_Blue5_Bold_15px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-blue-5);
    font-size: 15px;
  `,
  Body2_Gray6_Regular_15px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-6);
    font-size: 15px;
  `,
  Body2_Gray7_Bold_15px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-7);
    font-size: 15px;
  `,
  Body2_Gray8_Bold_15px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 15px;
  `,
  Body2_Gray8_Regular_15px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 15px;
  `,
  Caption1_Gray8_Bold_14px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 14px;
  `,
  Caption1_Gray8_Regular_14px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 14px;
  `,
  Caption1_Gray5_Regular_14px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-5);
    font-size: 14px;
  `,
  Caption1_Red4_Regular_14px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-red-4);
    font-size: 14px;
  `,
  Caption1_Blue4_Regular_14px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-blue-4);
    font-size: 14px;
  `,
  Caption1_White_Regular_14px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--white);
    font-size: 14px;
  `,
  Caption2_Gray5_Regular_13px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-5);
    font-size: 13px;
  `,
  Caption2_Gray6_Regular_13px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-6);
    font-size: 13px;
  `,
  Caption2_Gray7_Bold_13px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-7);
    font-size: 13px;
  `,
  Caption2_Gray7_Regular_13px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-7);
    font-size: 13px;
  `,
  Caption2_Gray8_Bold_13px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 13px;
  `,
  Caption2_Gray8_Regular_13px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 13px;
  `,
  Caption2_Blue4_Bold_13px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-blue-4);
    font-size: 13px;
  `,
  Caption2_Red5_Regular_13px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-red-5);
    font-size: 13px;
  `,
  Caption2_White_Regular_13px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--white);
    font-size: 13px;
  `,
  Caption3_Gray8_Bold_12px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-8);
    font-size: 12px;
  `,
  Caption3_Gray8_Medium_12px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-gray-8);
    font-size: 12px;
  `,
  Caption3_Gray7_Medium_12px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-gray-7);
    font-size: 12px;
  `,
  Caption3_Blue4_Medium_12px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-blue-4);
    font-size: 12px;
  `,
  Caption3_Blue5_Medium_12px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-blue-5);
    font-size: 12px;
  `,
  Caption3_Blue5_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-blue-5);
    font-size: 12px;
  `,
  Caption3_Red5_Medium_12px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-red-5);
    font-size: 12px;
  `,
  Caption3_Red5_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-red-5);
    font-size: 12px;
  `,
  Caption3_Gray5_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-5);
    font-size: 12px;
  `,
  Caption3_Gray6_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-6);
    font-size: 12px;
  `,
  Caption3_Gray7_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-7);
    font-size: 12px;
  `,
  Caption3_Gray8_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 12px;
  `,
  Caption3_White_Regular_12px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--white);
    font-size: 12px;
  `,
  Caption4_Gray7_Bold_11px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-7);
    font-size: 11px;
  `,
  Caption4_Gray7_Medium_11px: css`
    font-family: 'Noto Sans';
    font-weight: 500;
    color: var(--new-gray-7);
    font-size: 11px;
  `,
  Caption4_Gray7_Regular_11px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-7);
    font-size: 11px;
  `,
  Caption4_Gray8_Regular_11px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-gray-8);
    font-size: 11px;
  `,
  Caption4_Gray5_Bold_11px: css`
    font-family: 'Noto Sans';
    font-weight: 700;
    color: var(--new-gray-5);
    font-size: 11px;
  `,
  Caption4_Blue5_Regular_11px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-blue-5);
    font-size: 11px;
  `,
  Caption4_Green5_Regular_11px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-green-5);
    font-size: 11px;
  `,
  Caption4_Red5_Regular_11px: css`
    font-family: 'Noto Sans';
    font-weight: 400;
    color: var(--new-red-5);
    font-size: 11px;
  `,
  TossFaceFontMac_Regular: css`
    font-family: 'TossFaceFontMac';
    color: var(--new-gray-7);
    font-weight: 400;
    font-size: 12;
  `,
} as const;

export type TextKey = keyof typeof TEXT;
