import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';

export interface ICopyPeriodPostParams {
  target_period_id: number;
  source_id: number;
}
type SuccessResponse = {
  id: number;
  success: boolean;
};
export type ICopyPeriodPostResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function copyPeriodPost(params: ICopyPeriodPostParams) {
  return callApi2.post<ICopyPeriodPostResponse>(
    `/v2/cost-table-periods/${params.target_period_id}/copy`,
    omit(params, ['target_period_id'])
  );
}
