import { Input } from 'antd';
import { putCustomerMemo } from 'api/customerManagement/putCustomerMemo';
import Button from 'newDesign/atom/Button';
import { useState } from 'react';

export const MemoModal = ({ customerId, memo, closeModal, isEdit }) => {
  const [memoValue, setMemoValue] = useState(memo);

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Input.TextArea
          style={{ resize: 'none', height: 180, color: 'var(--new-gray-7)' }}
          value={memoValue}
          disabled={!isEdit}
          onChange={(e) => {
            setMemoValue(e.target.value);
          }}
        />
      </div>
      {isEdit && (
        <div style={{ display: 'flex', justifyContent: 'end', margin: '12px 0 0' }}>
          <Button
            style={{ height: 32, width: 48 }}
            onClick={() => {
              if (confirm('메모를 저장하시겠습니까?')) {
                putCustomerMemo({ customer_id: customerId, memo: memoValue }).then(() => {
                  closeModal();
                });
              }
            }}
          >
            저장
          </Button>
        </div>
      )}
    </>
  );
};
