import React, { Component, ReactNode } from 'react';

import styles from './Input.module.scss';

interface ComponentProps {
  type: string;

  inputRef?: any;
  placeholder?: string;
  disabled?: boolean;
  comma?: boolean;
  minus?: boolean;
  maxLength?: number;
  handleBlur?: Function;
  handleFocus?: Function;
  handleChange?: Function;
  propValue?: string | number;
  dataset?: string;

  inlineStyle?: React.CSSProperties;
  textStyle?: React.CSSProperties;
  clearButton?: string; // 'on'
  addText?: string;
  className?: string;
  pattern?: string;
  inputMode?: React.HTMLAttributes<Input>['inputMode'];
  inputClick?: React.MouseEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  parser?: any;
  unitInInputComponent?: boolean;
  unit?: string | ReactNode;
  autoFocus?: boolean;
}

class Input extends Component<ComponentProps, { clearBtn: boolean }> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      clearBtn: false,
    };
  }

  _handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { handleBlur } = this.props;
    const value = e.target.value;

    handleBlur && handleBlur(value);
  };

  _handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { handleFocus, inputRef } = this.props;
    const value = inputRef || e.target.value;

    handleFocus && handleFocus(value);
  };

  _handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { handleChange, comma, maxLength, minus, type } = this.props;
    const minusReg = /[^-\d|]+/g;
    const numberReg = /[^0-9]+/g;
    let value = e.target.value;

    if (type === 'number') {
      if (comma) value = value.split(',').join('');

      if (minus) value = value.replace(minusReg, '');

      if (!minus) value = value.replace(numberReg, '');
    }

    if (maxLength) value = value.slice(0, maxLength);

    handleChange && handleChange(value);
  };

  valueParser = () => {
    const { propValue, comma, type, maxLength, parser } = this.props;

    if (parser) {
      return parser(propValue);
    }

    if (propValue === null || propValue === undefined) {
      return '';
    }

    const commaReg = /\B(?=(\d{3})+(?!\d))/g;

    if (comma && type === 'number') return String(propValue).replace(commaReg, ',');

    if (maxLength && propValue && typeof propValue === 'string')
      return propValue.slice(0, maxLength);

    return propValue;
  };

  getType = () => {
    const { type } = this.props;

    if (['password', 'text', 'tel'].includes(type)) {
      return type;
    }

    return 'text';
  };

  inputValueClear = () => {
    const { handleChange } = this.props;

    handleChange && handleChange('');
  };

  inputClearButtonStyle = (placeholder: Readonly<string>) => {
    if (placeholder === '차고지 별칭 입력 (최대 7자)') {
      return styles.clear_button;
    }

    if (placeholder === '차고지 주소 검색') {
      return styles.clear_button_address;
    }
  };

  render() {
    const {
      onKeyPress,
      onKeyDown,
      inputClick,
      inputMode,
      inputRef,
      className,
      inlineStyle,
      placeholder,
      disabled,
      pattern,
      maxLength,
      clearButton,
      handleChange,
      addText,
      textStyle,
      dataset,
      unitInInputComponent,
      unit,
      autoFocus,
    } = this.props;

    return (
      <>
        <input
          className={`${styles.input} ${className || ''}`}
          style={inlineStyle || {}}
          type={this.getType()}
          placeholder={placeholder}
          disabled={disabled}
          pattern={pattern}
          inputMode={inputMode}
          maxLength={maxLength}
          onBlur={this._handleBlur}
          onFocus={this._handleFocus}
          onChange={this._handleChange}
          onKeyPress={onKeyPress}
          onClick={inputClick}
          readOnly={Boolean(inputClick)}
          onKeyDown={onKeyDown}
          value={this.valueParser()}
          ref={inputRef || null}
          data-input={dataset}
          autoFocus={autoFocus}
        />
        {unitInInputComponent && unit && <span>{unit}</span>}
        {clearButton === 'on' && this.valueParser() && (
          <img
            src="/images/group-1557.png"
            className={this.inputClearButtonStyle(placeholder || '')}
            onClick={() => this.inputValueClear()}
          />
        )}
        {addText && <p style={textStyle || {}}>{addText}</p>}
      </>
    );
  }
}

export default Input;
