import includes from 'lodash/includes';
import { inject } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import apiNotice from '../../api/notifications';
import * as parser from '../../utils/DataParser';
import * as utils from '../../utils/NotificationUtils';
import BadgeIcon from './BadgeIcon';
import styles from './TopMenuStyle.module.scss';

@withRouter
@inject('authStore')
@inject('menuStore')
@inject('groupCostStore')
class NotificationDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      width: 0,
      height: 0,
      notificationList: [],
    };
  }

  componentDidMount() {
    this.updateDimensions();
    document.addEventListener('mouseup', this.handleClickOutside);
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdown: false });
    }
  };

  rightMenuStyle = () => {
    if (this.state.width < 1100) {
      return `${1120 - this.state.width}px`;
    }
  };

  getListData = () => {
    // const request = {
    //   method: 'GET',
    //   path: '/v2/notifications/1',
    //   menuStore: this.props.menuStore,
    //   authStore: this.props.authStore
    // }
    catchAsync(async () => {
      const params = {
        menuStore: this.props.menuStore,
        authStore: this.props.authStore,
        page: 1,
      };
      const response = await apiNotice.getNotifications(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.setState(
          {
            notificationList: utils.makeNotificationObjectList(responseData.notifications),
          },
          () => this.props.menuStore.setBadge(0)
        );
      }
    });
  };

  moveDetail = (item) => {
    if (!this.props.groupCostStore.isCompareDailyCostList) {
      if (!confirm('페이지 이동 시 변경 사항이 저장되지 않습니다. 이동하시겠습니까?').valueOf()) {
        return;
      }
    }

    if (includes(this.props.router.pathname, '/rentRequestDetail/')) {
      // url이 같을 경우 새로고침
      const originRequestId = this.props.router.pathname.replace(/[^0-9]/g, '');

      if (originRequestId == item.objectId) return location.reload();
    }

    this.setState({ dropdown: false }, () => {
      utils.moveRequestDetailPage(item, this.props);
    });
  };

  render() {
    if (this.props.authStore.logged) {
      return (
        <div style={{ position: 'relative', marginLeft: 20 }} ref={this.setWrapperRef}>
          <div
            className={styles.right_menu}
            onClick={() =>
              this.setState({ dropdown: !this.state.dropdown }, () => {
                if (this.state.dropdown === true) {
                  this.getListData();
                  document.getElementById('dropdown').focus();
                }
              })
            }
          >
            <div className={styles.right_menu_button}>
              <BadgeIcon status={this.state.dropdown} />
            </div>
          </div>
          {Boolean(this.state.dropdown) && (
            <div className={styles.noti_dropdown_area}>
              <div className={styles.noti_dropdown_menu}>
                <div className={styles.noti_dropdown_list}>
                  <div className={styles.noti_dropdown_triangle} id="dropdown" />
                  <div style={{ backgroundColor: '#f2f2f2' }}>
                    <div
                      style={{
                        backgroundColor: '#fff',
                        padding: 15,
                        fontWeight: 700,
                        fontSize: 16,
                        color: '#000000',
                      }}
                    >
                      {MESSAGE.ALARM}
                    </div>
                    <div>
                      {this.state.notificationList &&
                        this.state.notificationList.map((item, idx) => {
                          if (idx < 9) {
                            return (
                              <div
                                className={`${styles.noti_dropdown_item} ${
                                  item.checked ? styles.check : styles.no_check
                                }`}
                                key={idx}
                                onClick={() => this.moveDetail(item)}
                              >
                                <div
                                  className={`${styles.dropdown_item_badege} ${
                                    styles[`${parser.notiTypeParser(item)}_text`]
                                  }`}
                                  data-request-type={parser.notiTypeParser(item)}
                                  dangerouslySetInnerHTML={{ __html: parser.notiHeadParser(item) }}
                                ></div>
                                <div className={styles.notiTitleWrap}>
                                  <div className={styles.notiTitle}>
                                    {parser.notiTitleParser(item)}
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        })}
                    </div>
                    <div
                      className={styles.center_mid}
                      style={{ cursor: 'pointer', padding: 15, fontSize: 14, color: '#737373' }}
                      onClick={() => {
                        this.setState(
                          {
                            dropdown: false,
                          },
                          () => {
                            this.props.router.push('/notificationList?page=1');
                          }
                        );
                      }}
                    >
                      {'더보기 >'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

export default NotificationDropDown;
