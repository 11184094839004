import { reaction } from 'mobx';
import { enableStaticRendering } from 'mobx-react';
import BottomSheetStore from 'stores/BottomSheetStore';

import ApplyPartnerStore from './ApplyPartnerStore';
import AuthStore from './AuthStore';
import BankStore from './BankStore';
import type { BaseStore } from './BaseStore.type';
import BranchStore from './BranchStore';
import CarAuctionStore from './CarAuctionStore';
import CarOptionStore from './CarOptionStore';
import CommonStore from './CommonStore';
import ConsignStore from './ConsignStore';
import ContractStore from './ContractStore';
import DeliveryAreaStore from './DeliveryAreaStore';
import GroupCostStore from './GroupCostStore';
import InsuranceSwitchStore from './InsuranceSwitchStore';
import LoadingStore from './LoadingStore';
import MenuStore from './MenuStore';
import ModalStore from './ModalStore';
import NormalContractStore from './NormalContractStore';

const isServer = typeof window === 'undefined';

enableStaticRendering(isServer);

type PickKeys<T> = {
  [K in keyof T]: T[K] extends BaseStore<unknown> ? K : never;
}[keyof T];

export class AppStore {
  static instance: AppStore;
  authStore: AuthStore;
  applyPartnerStore: ApplyPartnerStore;
  contractStore: ContractStore;
  normalContractStore: NormalContractStore;
  loadingStore: LoadingStore;
  menuStore: MenuStore;
  modalStore: ModalStore;
  insuranceSwitchStore: InsuranceSwitchStore;
  groupCostStore: GroupCostStore;
  deliveryAreaStore: DeliveryAreaStore;
  consignStore: ConsignStore;
  carAuctionStore: CarAuctionStore;
  commonStore: typeof CommonStore;
  branchStore: BranchStore;
  bottomSheetStore: BottomSheetStore;
  carOptionStore: CarOptionStore;
  bankStore: BankStore;

  public static getInstance() {
    // eslint-disable-next-line no-return-assign
    return this.instance || (this.instance = new this());
  }

  constructor() {
    this.authStore = new AuthStore();
    this.applyPartnerStore = new ApplyPartnerStore();
    this.contractStore = new ContractStore();
    this.normalContractStore = new NormalContractStore();
    this.loadingStore = new LoadingStore();
    this.menuStore = new MenuStore();
    this.modalStore = new ModalStore();
    this.insuranceSwitchStore = new InsuranceSwitchStore();
    this.groupCostStore = new GroupCostStore(this.authStore, this.menuStore, this.loadingStore);
    this.deliveryAreaStore = new DeliveryAreaStore();
    this.consignStore = new ConsignStore();
    this.carAuctionStore = new CarAuctionStore();
    this.commonStore = CommonStore;
    this.branchStore = new BranchStore(this.authStore);
    this.bottomSheetStore = new BottomSheetStore();
    this.carOptionStore = new CarOptionStore(this.loadingStore);
    this.bankStore = new BankStore(this.loadingStore);

    reaction(
      () => this.authStore.logged,
      async (logined) => {
        if (logined === false) {
          this.clear();
        }
      }
    );
  }

  hydrate(stores: Record<string, any>) {
    Object.keys(stores).forEach((storeName: PickKeys<AppStore>) => {
      const store = stores[storeName];

      if (this[storeName]) {
        this[storeName]?.setObject?.(store);
      }
    });

    return this;
  }
  clear() {
    const defaultStore = new AppStore();

    Object.keys(defaultStore).forEach((storeName) => {
      defaultStore[storeName] = JSON.parse(JSON.stringify(defaultStore[storeName]));
    });

    return this.hydrate(defaultStore);
  }
}

export const createStore = (isServer?: boolean) => {
  if (isServer === true) {
    return new AppStore();
  }

  return AppStore.getInstance();
};
