import callApi from '../../helpers/callApi';

export default {
  getReturnRequestCount(params) {
    return callApi({
      method: 'GET',
      url: '/v2/car-return-request/count',
      params: {
        kind: 'insurance',
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getReturnRequestDetail(params) {
    return callApi({
      method: 'GET',
      url: '/v2/car-return-request/contract',
      params: {
        contract_id: params.id,
        kind: params.kind,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  returnRequest(params) {
    return callApi({
      method: 'POST',
      url: '/v2/car-return-request/request',
      data: {
        contract_id: params.contract_id,
        birth: params.birth,
        kind: params.kind,
        timepoint: params.timepoint,
        location: params.location,
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  cancelRequest(params) {
    return callApi({
      method: 'POST',
      url: '/v2/car-return-request/cancel',
      data: {
        birth: params.birth,
        contract_id: params.contract_id,
        kind: params.kind,
        reason: params.reason,
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  customerConsult(params) {
    return callApi({
      method: 'POST',
      url: '/v2/car-return-request/consult',
      data: {
        contract_id: params.contract_id,
        kind: params.kind,
      },
    });
  },
  cancelCustomerConsult(params) {
    return callApi({
      method: 'POST',
      url: '/v2/car-return-request/cancel-consult',
      data: {
        contract_id: params.contract_id,
        kind: params.kind,
      },
    });
  },
  sendMemo(params) {
    return callApi({
      method: 'POST',
      url: '/v2/car-return-request/memo',
      data: {
        contract_id: params.contract_id,
        kind: params.kind,
        memo: params.memo,
      },
    });
  },
};
