import { DeferredComponent } from 'components/DefferedComponent/DefferedComponent';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import { CarSpinner } from './component/CarSpinner';

@inject('loadingStore')
@observer
class Loading extends Component {
  render() {
    if (this.props.loadingStore.display) {
      return (
        <DeferredComponent>
          <CarSpinner />
        </DeferredComponent>
      );
    }

    return <Fragment />;
  }
}

export default Loading;
