import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 21px 40px 30px 40px;
`;

export const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputLabel = styled.div`
  width: 7%;
  height: 17px;
  font-size: 15px;
  font-weight: bold;
  padding-top: 5px;
`;

export const InputSubLabelWrap = styled.div`
  width: 100%;
`;

export const SubText = styled.p`
  font-size: 14px;
  color: #575757;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ButtonWrap = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SubmitButton = styled.button`
  justify-content: center;
  align-items: center;
  display: flex;
  width: 108px;
  height: 42px;
  border-radius: 3px;
  border: 1px solid #575757;
  background-color: #575757;
  color: white;
  font-size: 15px;
  cursor: pointer;
`;
