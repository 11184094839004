import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
export interface IDailyCostListParams {
  search?: string;
  page?: number;
  per_page?: number;
  car_group_id?: any;
  // car_group_id?: Array<number>;
  period_id?: number;
  exclude_period_id?: number; // 해당 period id 가 연결되지않은 요금표 목록
}

type SuccessResponse = {
  daily_cost_tables: Array<any>;
  total_page: number;
  defaultInfo: callApi2.DefaultInfo;
  default_period: any;
};
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type IDailyCostListResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getDailyCostList(params: IDailyCostListParams, store: StoreType) {
  const response = await callApi2.get<IDailyCostListResponse>(
    `/v3/group-cost-tables/daily`,
    params
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
