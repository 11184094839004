import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { _strEnum } from 'helpers/zodHelpers';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const GetAutoResponseAgreeStateParam = z.object({});
export type GetAutoResponseAgreeStateParam = z.infer<typeof GetAutoResponseAgreeStateParam>;

export interface SuccessResponse {
  agree: boolean;
  end_hour: number;
  policy_agreement: boolean;
  policy_list: PolicyList[];
  start_hour: number;
  defaultInfo: callApi2.DefaultInfo;
}

export interface PolicyList {
  agree: boolean;
  agree_at: string;
  apply_at: string;
  id: number;
}

export type GetAutoResponseAgreeStateResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getAutoResponseAgreeState(
  store: StoreType
): Promise<GetAutoResponseAgreeStateResponse> {
  const response = await callApi2.get<GetAutoResponseAgreeStateResponse>(
    `/v2/rent-company/auto-response/agree`,
    {}
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
