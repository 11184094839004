import dayjs from 'dayjs';

export const PASSWORD = '비밀번호';
export const USER_PHONE_NUMBER = '휴대폰번호';
export const ID = '아이디';

export const AUTOMOBILE_REGISTER_SUCCESS = '가입이 완료되었습니다. 관리자의 승인을 기다려주세요.';
export const NORMAL_REGISTER_SUCCESS = '가입이 완료되었습니다.';
export const INSURANCE_REGISTER_SUCCESS =
  '가입 신청이 정상적으로 완료되었습니다.\n신청 결과는 순차적으로 검토 후 1~3일 이내\n연락드리겠습니다.';
export const INSURANCE_REGISTER_SUCCESS_RENCAR =
  '환영합니다!\n회원가입이 완료되었습니다. 로그인 후 이용해주세요.';
export const REGISTER_SUCCESS = '가입이 완료되었습니다. 관리자의 승인을 기다려주세요.';

export const ALERT_CAN_ACCESS_ONLY_MOBILE = '모바일 링크를 통해서만 접근 가능한 페이지 입니다.';
export const ALERT_CAN_NOT_ACCESS_PAGE_LOGOUT =
  '해당 로그인 계정은 접근 할 수 없는 페이지입니다. 로그아웃 후 이용하세요.';
export const ALERT_CAN_NOT_ACCESS_PAGE = '접근할 수 없는 페이지입니다.';
export const CHECK_TERM_WARNING = '기간설정을 확인해주세요.';
export const CHECK_SEARCH_REQUIREMENT = '검색조건을 설정해 주세요.';
export const SEARCH_DATA_RESET = '초기화 되었습니다.';

export const CAN_UPLOAD_SIZE_WARNING = 'MB 이하의 하나의 파일만 업로드 가능합니다.';
export const OVER_MAX_BYTE = (byte) => {
  return `최대 ${byte}byte 를 초과하였습니다.`;
};
export const PASSWORD_EDIT_SUCCESS = `${PASSWORD}가 변경되었습니다.`;
export const CHECK_REG_ID_TEMPLATE = (insCompany) => {
  const YY = dayjs().format('YY');
  const YYYY = dayjs().format('YYYY');
  const YYYYMM = dayjs().format('YYYYMM');
  const YYYYMMDD = dayjs().format('YYYYMMDD');
  const MM = dayjs().format('MM');
  const DD = dayjs().format('DD');
  let template = '';

  switch (insCompany) {
    case 'AXA':
    case 'DB':
      template = `${YY}-00000001`;
      break;
    case '롯데':
    case '한화':
      template = `${YYYYMM}-00001`;
      break;
    case '삼성':
      template = `${YYYYMMDD}-00001`;
      break;
    case 'KB':
      template = `${YYYYMMDD}-000001`;
      break;
    case '흥국':
      template = `${YYYYMMDD}-00001`;
      break;
    case '더케이':
      template = `${YY}${MM}-0000001`;
      break;
    case '현대':
      template = `${YY}${MM}-000001`;
      break;
    case '메리츠':
      template = `${YYYY}-0000001`;
      break;
    case 'MG':
      template = `${YYYY}-0000000001`;
      break;
    case '전세버스공제':
    case '택시공제':
      template = `${YYYY}-00001`;
      break;
    case '렌트카공제':
      template = `${YY}${MM}${DD}-0001`;
      break;
    default:
      return '접수번호 형식을 확인해주세요.';
  }

  return `접수번호 형식을 확인해주세요. 예) ${template}`;
};
export const CHANGE_MOBILE_SUCCESS = `${USER_PHONE_NUMBER}가 변경되었습니다.`;
export const CHANGE_PROFILE_SUCCESS = '프로필 변경사항이 적용되었습니다.';
export const CHECK_LOGIN_ID_WARNING = `${ID}를 입력하세요.`;
export const CHECK_LOGIN_PASSWORD_WARNING = `${PASSWORD}를 입력하세요.`;
export const CHOICE_SUGGSTION_AUTOMOBILE_SUCCESS =
  '선택하신 렌트업체의 배차시간과 반납시간이 틀리다면 해당 업체에 확인하시기 바랍니다.';
export const ALREADY_PARTICIPATE_WARNING = '이미 참여하였습니다.';
export const MODIFY_FAULT_RATE_SUCCESS = '고객예상과실이 수정이 되었습니다.';

export const REVIEW_RENTSERVICE_WARNING = '직원 친절도를 선택해주세요';
export const REVIEW_TIMESERVICE_WARNING = '배차 서비스를 선택해주세요';
export const REVIEW_REPLYFAST_WARNING = '댓글 반응도를 선택해주세요';
export const REVIEW_CAR_CLEAR_WARNING = '차량 상태를 선택해주세요';
export const REVIEW_TEXT_WARNING = '리뷰를 작성해 주세요';
export const REVIEW_WRITE_SUCCESS = '리뷰가 작성되었습니다.';
export const REVIEW_MODIFY_SUCCESS = '리뷰가 수정되었습니다.';
export const REVIEW_DELETE_SUCCESS = '리뷰가 삭제되었습니다.';
export const REQUEST_CANCEL_NOREASON_WARNING = '취소 사유를 입력해주세요.';
export const REQUEST_CANCEL_SUCCESS = '취소되었습니다.';
export const COPY_SUCCESS = '복사되었습니다.';
export const REPORT_NOREASON_WARNING = '신고사유를 선택해주세요.';
export const REPORT_SUCCESS = '신고가 접수되었습니다.';
export const CANNOT_MODIFY_REQUEST_WARNING = '수정할 수 없는 요청입니다.';
export const INSURANCE_LOCATION_WARNING = '관리지역을 선택해주세요.';
export const COMMENT_REMOVE_SUCCESS = '댓글이 삭제되었습니다.';
export const COMMENT_MODIFY_SUCCESS = '댓글이 수정되었습니다.';

export const BOARD_WRITE_SUCCESS = '게시글이 등록되었습니다.';
export const BOARD_MODIFY_SUCCESS = '게시글이 수정되었습니다.';
export const WRONG_ACCESS_WARNING = '잘못된 접근입니다.';

export const GUARANTEE_WARNING = '담보 구분을 선택해주세요.';
export const PECKING_ORDER_WARNING = '서열을 확인하세요.';

export const INSUFFICIENT_BALANCE_WARNING = '잔액이 부족합니다.';
export const HAS_WATING_POINT_WARNING = '입급 대기중인 캐시가 있습니다.';
export const CHECK_BANK_ACCOUNT_WARNING = '계좌를 확인해주세요.';

export const WANT_LOGOUT_WARNING = '로그아웃 하시겠습니까?';
export const WANT_DELETE_WARNING = '정말 삭제하시겠습니까?';
export const WANT_CHOICE_SUGGEST_WARNING = '해당 제안을 선택하시겠습니까?';
export const WANT_CANCEL_WARNING = '정말로 취소하시겠습니까?';
export const WANT_LEAVE_SERVICE_WARNING =
  '서비스 탈퇴를 진행하시겠습니까? \n탈퇴 후 계정 복구가 불가능합니다.';

export const EDIT_COMFIRM_SUCCESS = '요청이 수정되었습니다.';
export const NOT_FOUND_CAR_WARNING = '차량조회가 되지 않습니다. 차량명을 직접 입력해 주세요.';
export const CAR_NUMBER_VALIDATOR = '차량번호 형식을 확인해주세요.';
export const PHONE_NUMBER_WARNING = `올바른 ${USER_PHONE_NUMBER} 형식이 아닙니다.`;
export const NUMBER_WARNING = '올바른 연락처 형식이 아닙니다.';
export const IS_VALID_ID = '사용가능한 아이디 입니다.';
export const OVERLAP_ID_WARNING = '중복된 아이디 입니다.';
export const NOT_FOUND_REFERRER_ID_WARNING = '검색결과가 없습니다. 추천인 아이디를 확인해주세요.';
export const PLEASE_CHECK_INFO_WARNING = '입력하신 정보를 확인해주세요.';
export const CAN_USE_ID_SUCCESS = '사용 가능한 아이디 입니다.';
export const COMPANY_NAME_WARNING = '업체명은 공백을 제외하고 입력해주세요.';
export const COMPANY_ADDRESS_WARNING = '업체 주소지는 공백을 제외하고 입력해주세요.';

export const BIRTHDAY_WARNING = '생년월일 6자리를 확인해주세요.';
export const CHECKOUT_DATE_WARNING = '발급일을 확인해주세요.';
export const EXPIRED_DATE_WARNING = '갱신 만료일을 확인해주세요.';

export const NO_HISTORY_WARNING = '히스토리가 없습니다.';
export const SUGGEST_LIMIT_WARNING = '업체의 제안은 최대 10개까지 생성될 수 있습니다.';
export const NO_CHOICE_SUGGEST_WARNING = '아직 선택된 제안이 없습니다.';
export const CANT_SEE_DETAIL_WARNING =
  '요청이 삭제되었거나 이미 제안이 채택되어 내용을 볼 수 없습니다.';
export const RESERVATION_FINISH_SUCCESS = '예약이 완료되었습니다.';
export const NO_ADDITIONAL_SUGGEST_WARNING = '추가 제안 사항이 없습니다.';

export const RENT_REQUEST_SUCCESS = '렌트 요청이 접수되었습니다.';
export const RENT_REQUEST_SUCCESSFUL_SUCCESS = '성공적으로 렌트 요청이 접수되었습니다.';

export const DELIVERY_LOCATION_WARNING = '대여 위치를 입력해주세요.';
export const ALREADY_PAYMENT_WARNING = '이미 결제를 완료한 요청건입니다.';

export const SUCCESS_PENDING_PAYMENT_SUCCESS = '결제대기 상태가 취소되었습니다.';

export const DELIVERY_DATE_WARNING = '예상 대여 일시를 선택해 주세요';
export const ALREADY_CANCEL_REQUEST_WARNING = '취소된 요청 입니다.';
export const NO_SUGGEST_YET_WARNING = '아직 등록된 제안이 없습니다.';
export const NO_INTRODUCE_WARNING = '소개가 없습니다.';
export const NO_REVIEW = '작성된 리뷰가 없습니다.';
export const CANCELED_REQUEST_WARNING = '취소된 요청입니다.';
export const DONOT_DECIDED_FEE_WARNING =
  '희망 수수료가 정해지지 않았습니다.\n과실을 확정지어야 수수료 지급이 가능 합니다.';
export const EXPIRED_LINK_WARNING =
  '결제 링크가 만료되었습니다.\n요청목록 > 해당 요청상세로 가셔서\n 새로운 제안을 선택하시거나\n요청을 취소해주세요.';
export const EXPIRED_PENDING_PAYMENT_WARNING = '결제 대기 시간이 만료되었습니다.';
export const EXPIRED_PENDING_PAYMENT_WARNING_DESC =
  '종사자에게 연락하셔서 새로운 제안을 선택하시거나\n요청을 취소해주세요.';
export const NOSHOW_WARNING_DESC =
  '*노쇼는 예상 대여 일시 30분이 지난 후에도 고객님과 업체가 연락이 되지 않을 경우 업체측에서 요청하여 진행됩니다.';

export const UNDEFINED_ERROR = `잠시 오류가 발생하였습니다.\n확인 버튼을 누르신 후 다시 진행해주세요.\n문제가 지속될 경우\nIMS form 운영팀(1600-8348)에 문의해주세요.\n`;

export const CAR_STANDARD_CONTRACT_TERMS_ALERT =
  '자동차대여약관을 불러오면 기존 내용은 삭제됩니다.\n불러오시겠습니까?';
export const TERMS_RESET_ALERT = '약관을 초기화시키시겠습니까? 입력된 모든 내용이 삭제됩니다.';
export const TERMS_BEFOREUNLOAD_ALERT = '저장하지않고 나가시겠습니까?';
export const TERMS_EMPTY_SAVE_ALERT =
  '약관내용을 입력하지 않고 저장하시겠습니까?\n약관이 미등록 상태로 변경됩니다.';
export const TERMS_SAVE_ALERT = '약관을 등록하시겠습니까?';
export const TERMS_SAVE_SUCCESS_ALERT = '약관이 저장되었습니다.';
export const REMAIN_DELIVERY_TIME_LESS_THAN_1_HOUR =
  '배차 시간이 1시간 미만 남았습니다. 배차 포기를 해야 하는 긴급 상황인 경우 운영팀에 연락해주세요. (TEL: 1600-8348)';

export const PENDING_CLAIM_STATE_CANT_EDIT =
  "'청구 검수중' 상태일 경우 정보 수정이 불가합니다. 수정을 원하시면 하단 '청구 취소' 후 가능합니다.";
export const PENDING_CLAIM_STATE_CANT_EDIT_CONTRACT =
  "'청구 검수중' 상태일 경우 정보 수정이 불가합니다. 수정을 원하시면 '청구 취소' 후 가능합니다.";
