import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { ConfirmModalDefaultType as DefaultType } from '../../../../interface/utils/modalContents';
import ConfirmAlert from '../../../Alert/ConfirmAlert';
import styles from './NoshowNotAllowModal.module.scss';

interface PropsType extends Omit<DefaultType, 'onClickConfirm'> {
  isPickup: boolean;
}

@inject('modalStore')
@observer
class NoshowNotAllowModal extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);
  }

  getContent = () => {
    if (this.props.isPickup) {
      return (
        <div className={styles.text}>
          <article>
            <span>{'1. '}</span>
            <span style={{ color: '#f84b4b' }}>{'예약고객'}</span>
            <span>{'에게 '}</span>
            <span style={{ color: '#f84b4b' }}>{'연락'}</span>
            <span>{'을 충분히 시도해 주세요.'}</span>
          </article>
          <article style={{ paddingTop: 20 }}>
            <span>
              {
                '2. 제주도의 경우 비행기 결항, 지연 등의 상황으로 고객과 통화가 어려울 수 있으니 1시간 후 연락을 다시 시도해주세요.'
              }
            </span>
          </article>
          <article style={{ paddingTop: 20 }}>
            <span>{'3. 예약시간 6시간이 지나도 고객 연락 두절 시 노쇼 처리를 진행해주세요.'}</span>
          </article>
        </div>
      );
    }

    return (
      <div className={styles.text}>
        <article>
          <span>{'1. 지금 계신 장소가 고객과 '}</span>
          <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'약속 장소'}</span>
          <span>{'가 맞는지 확인해주세요.'}</span>
        </article>
        <article style={{ paddingTop: 20 }}>
          <span>{'2. 장소가 맞다면, '}</span>
          <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'예약 고객'}</span>
          <span>{'에게 '}</span>
          <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'연락'}</span>
          <span>{'을 충분히 시도해 주세요.'}</span>
        </article>
        <article style={{ paddingTop: 20 }}>
          <span>{'3. 고객과 통화연결이 되지 않더라도 반드시 배차 장소에서 '}</span>
          <span style={{ color: '#f84b4b', fontWeight: 'bold' }}>{'20분 이상 대기'}</span>
          <span>{'해야 합니다.'}</span>
          <article style={{ paddingTop: 10 }}>
            <span>
              {
                '(* 배차 장소가 공항일 경우 비행기 결항, 지연 등의 상황이 발생할 수 있으니 반드시 고객과 통화 후 처리해주세요.)'
              }
            </span>
          </article>
        </article>
        <article style={{ paddingTop: 20 }}>
          {'4. 약속시간 20분이 지나도 고객 연락 두절 시 노쇼 처리를 진행해주세요.'}
        </article>
      </div>
    );
  };
  render() {
    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={this.props.modalStore.modalClose}
        confirmButtonText={'확인'}
        hideCancelBtn={true}
      />
    );
  }
}

export default NoshowNotAllowModal;
