import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import apiIS from '../../../api/normalContract/installmentSchedule';
import CommonRowTable from '../../../components/CommonRowTable2';
import ContentsWrap from '../../../components/ContentsWrap';
import withPrivateRoute from '../../../components/withPrivateRoute';
import { setStoreDefaultData } from '../../../helpers/callApi';
import comma from '../../../helpers/costComma';
import paymentSchedulePreFetch from '../../../utils/InitFetchFunctions/normalContract/paymentSchedule';
import * as utils from '../../../utils/PaymentUtils';
import styles from './PaymentSchedule.module.scss';
import ScheduleTable from './ScheduleTable';
import TerminateModal from './TerminateModal.js';

@withPrivateRoute
@inject('authStore')
@inject('menuStore')
@inject('loadingStore')
@inject('modalStore')
@inject('normalContractStore')
@observer
class PaymentSchedule extends Component {
  constructor(props) {
    super(props);
    this.scheduleList = null;
    this.state = {
      editMode: false,
      data: null,
      paymentMethodList: [],
      paymentSchedultData: {
        deduct_deposit: false,
        payment_date: null,
        payment_method_id: null,
        payment_amount: null,
        installment_no: null,
        remark: null,
      },
    };
  }

  static async getInitialProps(ctx) {
    return paymentSchedulePreFetch.preFetch(ctx);
  }

  componentDidMount() {
    if (!this.props.preview) {
      window.scrollTo(0, 0);

      if (this.props.apiResult) {
        this.setState(this.props.apiResult);
        setStoreDefaultData(this.props.defaultInfo, {
          menuStore: this.props.menuStore,
          authStore: this.props.authStore,
        });
      } else {
        this.getData();
      }
    } else {
      this.getPreviewData();
    }
  }

  getPreviewData = async () => {
    catchAsync(
      async () => {
        const { menuStore, authStore, normalContractStore } = this.props;
        const { paymentInfo } = normalContractStore;
        // let data = {
        //   start_datetime: normalContractStore.start_datetime,
        //   end_datetime: normalContractStore.end_datetime,
        //   payment_day: normalContractStore.payment_day,
        //   prepaid_amount: normalContractStore.prepaid_amount
        //     ? normalContractStore.prepaid_amount
        //     : 0,
        //   emi: normalContractStore.monthly_fee,
        //   prepayment_term: normalContractStore.prepayment_term
        //     ? normalContractStore.prepayment_term
        //     : 0,
        // };
        let data = {
          start_datetime: paymentInfo.delivered_date,
          end_datetime: paymentInfo.expect_return_date,
          payment_day: paymentInfo.payment_day,
          prepaid_amount: paymentInfo.prepaid_amount ? paymentInfo.prepaid_amount : 0,
          emi: paymentInfo.monthly_fee,
          prepayment_term: paymentInfo.prepayment_term ? paymentInfo.prepayment_term : 0,
        };

        // if (
        //   normalContractStore.initial_payment_date &&
        //   normalContractStore.initial_payment_date !== '-'
        // ) {
        //   data.initial_payment_date = normalContractStore.initial_payment_date;
        // }

        if (paymentInfo.initial_payment_date && paymentInfo.initial_payment_date !== '-') {
          data.initial_payment_date = paymentInfo.initial_payment_date;
        }

        const params = {
          ...data,
          menuStore,
          authStore,
        };

        this.props.loadingStore.on();
        const response = await apiIS.getPreview(params);
        const responseData = response.data;

        if (response.status === 200) {
          this.setState({
            editMode: false,
            data: {
              rent_car_name: normalContractStore.rent_car_name,
              rent_car_number: normalContractStore.rent_car_number,
              rent_company_car_id: normalContractStore.rent_company_car_id,
              monthly_fee: responseData.emi,
              // current_deposit_amount: JSON.parse(normalContractStore.deposit_amount),
              current_deposit_amount: JSON.parse(normalContractStore.paymentInfo.deposit_amount),
              amount: 0,
              amount_due: responseData.total_cost - responseData.prepaid_amount,
              current_installment_no: 0,
              total_count: responseData.schedule.length,
              unpaid_amount: 0,
              completed_count: 0,
              ...responseData,
            },
          });
        }
      },
      () => this.props.loadingStore.off()
    );
  };

  getDataCallBack = (refresh) => {
    //납부회차가 1개만 있을경우, select 가 없어지므로 default로 데이터가 박혀있어야한다.
    const paymentTimes = utils.getPaymentTimes(this.state);

    if (Array.isArray(paymentTimes) && paymentTimes.length === 1) {
      this.setState((prevState) => ({
        // ...prevState,
        paymentSchedultData: {
          ...prevState.paymentSchedultData,
          installment_no: paymentTimes[0].value,
        },
      }));
    }

    if (refresh) {
      this.scheduleList.refresh(this.state.data);
    }
  };

  getData = (refresh) =>
    paymentSchedulePreFetch.getScheduleList(this, this.getDataCallBack, refresh);

  save = async () => {
    catchAsync(async () => {
      this.props.loadingStore.on();
      const { data } = this.state;
      const { id } = this.props.router.query;
      const defaultSchedultList = data.schedule;
      const changedScheduleList = this.scheduleList.getData();
      let sum_prepaid = 0;

      for (const i in changedScheduleList) {
        sum_prepaid += changedScheduleList[i].prepaid_amount_due;
      }

      if (sum_prepaid - data.prepaid_amount > 0) {
        return alert('계약 선수금보다 예정 선수금이 더 많습니다. 금액을 확인해 주세요.');
      }

      const validateResult = utils.validateEditData(defaultSchedultList, changedScheduleList);

      if (!validateResult.result) {
        this.scheduleList.focus(validateResult.key, validateResult.insNo);

        return alert(validateResult.message);
      }

      const params = {
        schedule: changedScheduleList,
        id,
      };
      const response = await apiIS.updateSchedule(params);

      if (response.status == 200) {
        this.setState({ editMode: false }, () => {
          alert('저장이 완료되었습니다.');
          this.getData();
        });
      }
    }, this.props.loadingStore.off);
  };

  addDeposit = async () => {
    catchAsync(
      async () => {
        const { id } = this.props.router.query;
        const { paymentSchedultData, data } = this.state;
        const { current_deposit_amount, schedule } = data;
        const {
          deduct_deposit,
          payment_date,
          payment_method_id,
          payment_amount,
          installment_no,
          remark,
        } = paymentSchedultData;

        const bodyData = {
          deduct_deposit: Number(deduct_deposit),
          payment_date,
          payment_amount,
          installment_no,
          remark,
        };

        if (payment_method_id) {
          bodyData.payment_method_id = payment_method_id;
        }

        if (!payment_date) {
          return alert('입금일자를 선택해 주세요.');
        }

        if (!payment_amount) {
          return alert('입금액을 입력해 주세요.');
        }

        if (Number(payment_amount) < 0) {
          return alert('입금액을 확인해 주세요.');
        }

        if (!installment_no) {
          return alert('납부회차를 선택해 주세요.');
        }

        if (deduct_deposit && payment_amount > current_deposit_amount) {
          return alert('입력 금액이 잔여 보증금보다 많습니다. 금액을 확인해 주세요.');
        }

        const estimatedPaymentItem = schedule.find(
          (item) => item.installment_no === installment_no
        );

        if (
          !deduct_deposit &&
          estimatedPaymentItem.amount_due - estimatedPaymentItem.amount < payment_amount
        ) {
          return alert(
            '납부 예정금액보다 큰 금액을 입금할 수 없습니다. 납부 예정금액을 확인해 주세요.'
          );
        }

        const params = {
          ...bodyData,
          id,
        };

        this.props.loadingStore.on();
        const response = await apiIS.addDeposit(params);

        if (response.status == 200) {
          alert('납부가 완료되었습니다.');
          this.setState(
            {
              paymentSchedultData: {
                deduct_deposit: false,
                payment_date: null,
                payment_method_id: null,
                payment_amount: null,
                installment_no: null,
                remark: null,
              },
            },
            () => {
              this.getData(true);
            }
          );
        }
      },
      () => this.props.loadingStore.off()
    );
  };

  openTerminateModal = (isDisabled) => {
    const { id } = this.props.router.query;
    const modal = (
      <TerminateModal
        isDisabled={isDisabled}
        data={this.state.data}
        id={id}
        refresh={this.getData}
        state={this.props.router.query.state}
      />
    );
    const data = {
      modalComponent: modal,
      modalTitle: isDisabled ? '중도해지취소' : '중도해지',
      modalStyle: { width: '650px' },
    };

    this.props.modalStore.modalOpen(data);
  };

  goBack = () => {
    if (this.props.preview) {
      this.props.modalStore.modalClose();
    } else {
      this.props.router.back();
    }
  };

  // render section

  renderHeaderContent = () => {
    const { data, disabledStartIndex } = this.state;

    return (
      <div className={styles.header_container}>
        <div className={styles.header}>
          <div className={styles.car_name}>{data.rent_car_name}</div>
          <div className={styles.car_number}>{data.rent_car_number}</div>
          {Number.isInteger(disabledStartIndex) && (
            <div className={styles.terminated}>
              {`${disabledStartIndex + 1}회차 중도해지 / 실제 납부금액 ${comma(data.amount)}원`}
            </div>
          )}
        </div>
      </div>
    );
  };

  renderTitle = () => {
    const { disabledStartIndex, editMode } = this.state;
    const { id } = this.props.router.query;
    const isDisabled = Number.isInteger(disabledStartIndex);

    if (this.props.preview) {
      return null;
    }

    if (this.props.router.query.state === 'deposit_done') {
      return (
        <div className={styles.deposit_done_text}>
          계약 종결 상태에서 대여료 관리는 불가합니다. ‘입금대기 되돌리기’ 로 계약 상태를 변경하신
          뒤 추가 관리해주세요.
        </div>
      );
    }

    if (!editMode) {
      return (
        <div className={styles.table_header}>
          <div
            onClick={() => this.openTerminateModal(isDisabled)}
            className={styles.outline_btn}
            style={{ width: 90, marginRight: 10 }}
          >
            {isDisabled ? '중도해지취소' : '중도해지'}
          </div>
          <div
            style={{ marginRight: 15 }}
            className={styles.fill_btn}
            onClick={() => this.setState({ editMode: true })}
          >
            수정
          </div>
          <div className={styles.desc}>
            *납부 예정일, 선수금, 납부 예정금액 및 납부 회차 추가는 수정을 통해 변경하실 수
            있습니다.
          </div>
        </div>
      );
    }

    return (
      <div className={styles.table_header_right}>
        {!Number.isInteger(disabledStartIndex) && (
          <div
            className={styles.fill_btn}
            style={{ width: 80, marginRight: 10 }}
            onClick={() => this.scheduleList?.add(id)}
          >
            회차추가
          </div>
        )}
        <div
          style={{ marginRight: 10 }}
          className={styles.outline_btn}
          onClick={() => this.getData(true)}
        >
          {MESSAGE.CANCEL}
        </div>
        <div className={styles.fill_btn} onClick={this.save}>
          {MESSAGE.SAVE}
        </div>
      </div>
    );
  };

  setInputDespositData = (key, value) => {
    let parsedValue = null;

    switch (key) {
      case 'installment_no':
      case 'payment_method_id':
        if (value) {
          parsedValue = JSON.parse(value);
        }

        break;
      case 'payment_amount':
        parsedValue = Number(value);
        break;
      case 'deduct_deposit':
        value ? (parsedValue = JSON.parse(value)) : (parsedValue = false);
        break;
      case 'payment_date':
        parsedValue = value ? dayjs(new Date(value)).format('YYYY-MM-DD') : null;
        break;
      case 'remark':
        parsedValue = value;
        break;
      default:
        parsedValue = value;
        break;
    }

    this.setState((prevState) => ({
      // ...prevState,
      paymentSchedultData: {
        ...prevState.paymentSchedultData,
        [key]: parsedValue,
      },
    }));
  };

  renderInputDeposit = () => {
    if (this.props.router.query.state === 'deposit_done') {
      return null;
    }

    if (!this.props.preview && !this.state.editMode) {
      const { paymentSchedultData } = this.state;

      return (
        <CommonRowTable
          viewData={utils.getDepositTableData(this.state, this.addDeposit)}
          setData={this.setInputDespositData}
          stateData={paymentSchedultData}
          labelStyle={{ minWidth: '70px' }}
        />
      );
    }

    return null;
  };

  renderPaymentInfo = () => {
    const { data } = this.state;
    const { preview } = this.props;
    const {
      amount, //실제납부금
      amount_due, //총납부예정금
      completed_count, //납부완료회차
      current_deposit_amount, //현재보증금
      monthly_fee, //월납부금
      payment_day, //월납부일
      prepaid_amount, //선수금
      unpaid_amount, //미납금
      prepaid_amount_due, // 납부 선수금
      service_amount, //차액서비스
    } = this.state.data;

    return (
      <div className={styles.header_info}>
        <div className={styles.header_table_wrap}>
          <div style={{ width: preview ? '100%' : '57%' }}>
            <CommonRowTable
              stateData={{
                amount_due: comma(amount_due),
                current_deposit_amount: `${comma(current_deposit_amount)}`,
                // prepaid_amount: `${comma(prepaid_amount)}원 / ${preview ? ' 0원' : `${comma(prepaid_amount_due - prepaid_amount)}원`}`,
                prepaid_amount: (
                  <>
                    <span>{comma(prepaid_amount)}원 / </span>
                    <span style={{ color: 'red' }}>
                      {preview ? ' 0원' : `${comma(prepaid_amount_due - prepaid_amount)}원`}
                    </span>
                  </>
                ),
                monthly_fee: `${comma(monthly_fee)}원 / 매달 ${payment_day}일`,
                total_fee: comma(amount_due + prepaid_amount),
                current_fee: `${comma(amount)}원 (${Number(completed_count)}회/${
                  data.total_count
                }회)`,
                unpaid_amount: (
                  <>
                    <p>{comma(amount_due - (amount + service_amount))}원 / </p>
                    <p style={{ color: 'red' }}>{comma(unpaid_amount)}원</p>
                  </>
                ),
              }}
              labelStyle={{ minWidth: '114px' }}
              viewData={utils.getFeeTableData(data, preview)}
              customLabel="test"
            />
          </div>
          <div style={{ width: '42%' }}>{this.renderInputDeposit()}</div>
        </div>
        {!preview && !this.state.editMode && this.props.router.query.state !== 'deposit_done' && (
          <div className={styles.header_bottom_text}>
            * 입금된 내용은 &apos;입금내역&apos; 에서 수정하실 수 있습니다.
          </div>
        )}
      </div>
    );
  };

  renderScheduleTable = () => {
    const { data, editMode, disabledStartIndex } = this.state;
    const { preview } = this.props;

    return (
      <div className={styles.table_container}>
        {this.renderTitle()}
        <ScheduleTable
          disabledStartIndex={disabledStartIndex}
          currentPaymentTimes={data.current_installment_no}
          scheduleListRef={(r) => (this.scheduleList = r)}
          editMode={editMode}
          data={data.schedule}
        />
        {preview && (
          <div className={styles.btn_area}>
            <button className={styles.close_btn} onClick={() => this.props.modalStore.modalClose()}>
              {MESSAGE.CONFIRM}
            </button>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { data } = this.state;
    const { preview } = this.props;

    if (data) {
      return (
        <ContentsWrap
          parentContentStyle={preview ? { minWidth: 1100, height: '100%' } : {}}
          contentsStyle={!preview ? { width: 1160 } : {}}
          title={preview ? undefined : '납부 스케줄 정보'}
          isModal={preview}
        >
          {this.renderHeaderContent()}
          {this.renderPaymentInfo()}
          {this.renderScheduleTable()}
        </ContentsWrap>
      );
    }

    return null;
  }
}

export default withRouter(PaymentSchedule);
