import React from 'react';

interface UseControlledProps<T> {
  controlled: T;
  defaultValue: T;
}

const useControlled = <T>({
  controlled,
  defaultValue,
}: UseControlledProps<T>): [value: T, setValueIfUnControlled: (arg: T) => void] => {
  const { current: isControlled } = React.useRef(controlled !== undefined);
  const [valueState, setValueState] = React.useState(defaultValue);
  const value = isControlled ? controlled : valueState;

  const setValueIfUncontrolled = React.useCallback((newValue: T) => {
    if (!isControlled) {
      setValueState(newValue);
    }
  }, []);

  return [value, setValueIfUncontrolled];
};

export default useControlled;
