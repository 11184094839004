import { FormattedSearchResult } from 'components/AddressWithTmap/type';
import { ReactNode } from 'react';

import { Entry } from '../Entry';
import { NonResult } from '../NonResult';
import { SearchedList } from '../SearchedList';

interface SearchResultFactoryProps {
  searchResult: Array<FormattedSearchResult>;
  onClickAddress: (addressType: string, result: FormattedSearchResult) => void;
  renderEntryBottom?: () => ReactNode;
}

export const SearchResultFactory = ({
  searchResult,
  onClickAddress,
  renderEntryBottom,
}: SearchResultFactoryProps) => {
  // 검색 결과 ❌
  if (searchResult?.length === 0) {
    return <NonResult />;
  }

  // 검색 결과 ✅
  if (searchResult?.length > 0) {
    return <SearchedList searchResult={searchResult} onClickAddress={onClickAddress} />;
  }

  // 처음 진입시
  return (
    <>
      <Entry />
      {renderEntryBottom && renderEntryBottom()}
    </>
  );
};
