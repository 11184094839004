import dayjs from 'dayjs';
import React from 'react';

import Button from '../../../atoms/Button/index.tsx';
import CustomDatePicker from '../../../atoms/CustomDatePicker/index.tsx';
import Input from '../../../atoms/Input/index.tsx';
import Select from '../../../atoms/Select/index.tsx';
import dateTimeParser from '../../../helpers/dateTimeParser';
import { autoReturnDate, zeroParser } from '../../../utils/ContractUtils';
import styles from './FullDateType.module.scss';

const selectedParser = (props) => {
  const { stateData, info } = props;

  if (info.key === 'start_datetime' || info.key === 'end_datetime') {
    return stateData[info.key] && new Date(stateData[info.key]);
  }

  return stateData[`${info.key}_date`] && new Date(stateData[`${info.key}_date`]);
};

const keyParser = (props, value) => {
  const { setData, stateData, info } = props;

  if (info.key === 'start_datetime' || info.key === 'end_datetime') {
    setData(info.key, dateTimeParser({ date: value, format: 'YYYY-MM-DD' }));

    if (!stateData.end_datetime) {
      // setData('end_datetime', autoReturnDate(info.key, stateData).date)
    }

    return;
  }

  return setData(`${info.key}_date`, dateTimeParser({ date: value, format: 'YYYY-MM-DD' }));

  // const { longTermMonth } = this.state;
  // if (key === 'end_datetime') {
  //   if (dataObj.end_datetime !== dateTimeParser({ date: data, format: 'YYYY-MM-DD' })) {
  //     const start = dataObj.start_datetime;
  //     const end = dateTimeParser({ date: data, format: 'YYYY-MM-DD' })
  //     this.setState({ longTermMonth: dayjs(end).diff(start, 'month') })
  //   }
  // }
  // if (key === 'start_datetime' && dataObj.end_datetime) {
  //   if (dataObj.start_datetime !== dateTimeParser({ date: data, format: 'YYYY-MM-DD' })) {
  //     const start = dateTimeParser({ date: data, format: 'YYYY-MM-DD' })
  //     const end = dataObj.end_datetime
  //     this.setState({ longTermMonth: dayjs(end).diff(start, 'month') })
  //   }
  // }
  // if (key === 'start_datetime' || key === 'end_datetime') {
  //   if (key === 'start_datetime' && !dataObj.end_datetime) {
  //     setData(key, dateTimeParser({ date: data, format: 'YYYY-MM-DD' }))
  //     return autoSetReturnDate(key, setData, dataObj, longTermMonth)
  //   }
  //   return setData(key, dateTimeParser({ date: data, format: 'YYYY-MM-DD' }))
  // }
  // return setData(`${key}_date`, dateTimeParser({ date: data, format: 'YYYY-MM-DD' }))
};

const minuteChange = (props, value) => {
  const { setData, stateData, info } = props;

  // const { longTermMonth } = this.state;
  if (value >= 60 || value.length > 2) {
    alert('0~59 사이의 숫자만 입력해주세요.');
    setData(`${info.key}_minute`, '');

    // autoSetReturnDate(info.key, setData, stateData, longTermMonth)
    return;
  }

  setData(`${info.key}_minute`, value.replace(/0?[^0-9]/g, ''));
};

const minuteBlur = (props, value) => {
  // FIXME: 일월 / 장기렌트일때 date 키값 형식이 다름
  // 일월 : xxx_date / 장기: xxx
  const { setData, stateData, info } = props;
  const returnData = autoReturnDate(info.key, stateData);

  if (value === '') return;

  if (value.length > 2) {
    const sliceValue = value.slice(0, 2);

    setData(`${info.key}_minute`, sliceValue);

    return;
  }

  if (value >= 60) {
    setData(`${info.key}_minute`, '');

    return;
  }

  if (info.key === 'delivered') {
    // 일/월렌트
    setData('expect_return_date', returnData?.date);
    setData('expect_return_hour', returnData?.hour);
    setData('expect_return_minute', returnData?.min);
  }

  if (info.key === 'start_datetime') {
    // 장기렌트
    setData('end_datetime', returnData?.date);
    setData('end_datetime_hour', returnData?.hour);
    setData('end_datetime_minute', returnData?.min);
  }
};

const minDate = (props) => {
  const { info, stateData } = props;

  if (info.disabledMinDate) return '';

  if (info.calendarOption === 'end') {
    if (stateData.start_datetime) return new Date(stateData.start_datetime);

    if (stateData.delivered_date) return new Date(stateData.delivered_date);

    if (stateData.delivered_at_date) return new Date(stateData.delivered_at_date);

    if (stateData.end_at) return new Date(stateData.start_at);
  }

  if (info.calendarOption === 'current') {
    return new Date();
  }

  return '';
};

const maxDate = (props) => {
  const { info, stateData } = props;

  if (info?.disabledMaxDate) return '';

  if (info.calendarOption === 'start') {
    if (stateData.return_date) return new Date(stateData.return_date);

    if (stateData.end_datetime) return new Date(stateData.end_datetime);

    if (stateData.expect_return_date) return new Date(stateData.expect_return_date);

    if (stateData.expect_return_date_date) return new Date(stateData.expect_return_date_date);
  }

  return '';
};

const renderTimeSelect = (props) => {
  const { setData, stateData, info } = props;

  return (
    <div className={styles.time_group}>
      <Select
        hour
        arrow
        wrapStyle={styles.select_time}
        dataObj={stateData}
        selectKey={`${info.key}_hour`}
        onChange={(value) => {
          setData(`${info.key}_hour`, zeroParser(value));

          if (stateData.expect_return_hour !== zeroParser(value)) {
            setData('end_datetime_hour', zeroParser(value));
          }
        }}
        disabled={info.disabled}
        dataset={`${info.key}_hour`}
      />
      <span>시</span>
      {info.minInterval ? (
        <Select
          minute
          selectData={[
            { label: '00분', value: '00' },
            { label: '30분', value: '30' },
          ]}
          arrow
          wrapStyle={styles.select_time}
          dataObj={stateData}
          selectKey={`${info.key}_minute`}
          onChange={(value) => {
            minuteChange(props, `${value}`.padStart(2, '0'));
          }}
          disabled={info.disabled}
          dataset={`${info.key}_minute`}
        />
      ) : (
        <Input
          type="number"
          className={styles.minutes}
          propValue={
            stateData[`${info.key}_minute`] === null ? '' : stateData[`${info.key}_minute`]
          }
          handleChange={(value) => {
            minuteChange(props, value);
          }}
          handleBlur={(value) => {
            if (!stateData.orderer) minuteBlur(props, value);

            setData(`${info.key}_minute`, zeroParser(value));
          }}
          disabled={info.disabled}
          dataset={info.key}
        />
      )}
      <span>분</span>
    </div>
  );
};

const renderPlusBtn = (props) => {
  // FIXME: 일반계약서 작성 일렌트 전용....
  const { info } = props;

  if (info.plusDay) {
    return info.plusDay.map((day) => {
      return (
        <Button
          outline
          key={day}
          btnText={`+${day}일`}
          btnClick={() => info.func(day)}
          inlineStyle={info.plusDayStyle}
        />
      );
    });
  }
};

const renderPeriodResult = (props) => {
  const { info } = props;

  if (info.calcResult) {
    return (
      <>
        <div className={styles.calc_result}>{info.calcResult}</div>
        {info.connectType === 'daily' && !info.external && (
          <Button outline btnText="초기화" btnClick={info.func} inlineStyle={info.plusDayStyle} />
        )}
      </>
    );
  }
};

const changeMonth = (...args) => {
  const [value, props] = args;
  const { info, setData, stateData } = props;
  const isStartDate =
    stateData.start_datetime && stateData.start_datetime_hour && stateData.start_datetime_minute;
  const carbookingDate =
    stateData.delivered?.date && stateData.delivered?.hour && stateData.delivered?.minute;

  if (value > 72) {
    alert('기간설정은 72개월을 초과할 수 없습니다.');
    setData('longTermMonth', null);

    return;
  }

  if (isStartDate) {
    const addMonth = dayjs(stateData.start_datetime).add(value, 'month').format('YYYY-MM-DD');

    setData('end_datetime', addMonth);
  }

  if (carbookingDate) {
    const addMonth = dayjs(stateData.delivered.date).add(value, 'month').format('YYYY-MM-DD');

    setData('expect_return_date', addMonth);
  }
};

const renderLongInputChange = (props) => {
  const { info, setData, stateData, monthValue } = props;

  if (info.longStartOption) {
    return (
      <>
        <Input
          type="number"
          inlineStyle={{ width: '50px', height: '28px', marginRight: 5, marginLeft: 10 }}
          propValue={stateData.longTermMonth ?? monthValue}
          handleChange={(value) => setData('longTermMonth', value)}
          handleBlur={(value) => changeMonth(value, props)}
          dataset={info.key}
        />
        <p> 개월</p>
      </>
    );
  }
};

const FullDateType = (props) => {
  return (
    <div className={styles.full_date_wrap}>
      <CustomDatePicker
        icon
        className={styles.period_date_picker}
        selected={selectedParser(props)}
        onChange={(value) => keyParser(props, value)}
        maxDate={maxDate(props)}
        minDate={minDate(props)}
        disabled={props.info.disabled}
        dataset={props.info.dataset}
      />
      {renderTimeSelect(props)}
      {renderLongInputChange(props)}
      {renderPeriodResult(props)}
      {renderPlusBtn(props)}
    </div>
  );
};

export default FullDateType;
