import find from 'lodash/find';
import React, { Component } from 'react';

import FileInput from '../../FileInput';

class FileType extends Component {
  constructor(props) {
    super(props);
    this.fileInput = null;
    this.state = {
      fileData: null,
    };
  }

  render() {
    const { info, setData, styles } = this.props;

    return (
      <FileInput
        tableFile
        compressSize={info.compressSize}
        possibleExtenstions={info.possibleExtenstions}
        limit={info.limit}
        multi={info.multi}
        value={info.fileName}
        deleteFileName={this.state.deleteFileName}
        keyName={info.key}
        className={info.outside ? styles.file_btn : ''}
        accept={info.accept}
        label={info.fileName ? '파일 변경' : '파일 첨부'}
        emptyFile={info.placeholder}
        propFileData={this.state.fileData}
        getData={(data) => {
          if (this.state.fileData && find(this.state.fileData, data)) {
            return alert('이미 등록된 파일입니다.');
          }

          setData(info.key, data);
        }}
        count={this.state.fileData && this.state.fileData.length}
        ref={info.key}
      />
    );
  }
}

export default FileType;
