import React, { Component } from 'react';

import styles from './Toast.module.scss';

let remove;

class RencarToast extends Component {
  /**
   * 
   window.toast({
    text: '안녕하세요?',
    type: 'success',
  })
   */
  constructor(props) {
    super(props);
    this.state = {
      prevTimestamp: null,
    };
  }

  componentDidMount() {
    window.toast = this.toast;
  }

  removeToast = (key, item) => {
    try {
      const target = document.getElementById(key);

      document.getElementById('toast').removeChild(target);
    } catch (e) {
      return null;
    }
  };

  toast = (item) => {
    const timestamp = new Date().getTime();

    item.timestamp = timestamp;
    item.delay = 4000;
    this.webToast(item);
  };

  webToast = (item) => {
    if (this.state.prevTimestamp) {
      this.removeToast(this.state.prevTimestamp, item);
    }

    const toast = document.getElementById('toast');

    toast.setAttribute(
      'class',
      item.mobileWeb ? styles.mobile_web_toast_container : styles.toast_container
    );
    const node = document.createElement('div');

    node.setAttribute('id', item.timestamp);
    this.setText(node, item);
    node.onmouseenter = () => this.stopRemoveToast();
    node.onmouseleave = () => {
      node.setAttribute('data-mouseevent', 'leave');
      remove = setTimeout(() => {
        this.removeToast(item.timestamp, item);
      }, item.delay);
    };
    this.setState({ prevTimestamp: item.timestamp });
    toast.appendChild(node);

    if (item.callback) item.callback();

    remove = setTimeout(() => {
      this.removeToast(item.timestamp, item);
    }, item.delay);
  };

  stopRemoveToast = () => {
    clearTimeout(remove);
  };

  setText = (node, item) => {
    const closeButton = document.createElement('div');

    closeButton.setAttribute('data-wrap', 'button');
    const closeButtonImage = document.createElement('div');

    closeButton.onclick = () => this.removeToast(item.timestamp, item);
    closeButton.appendChild(closeButtonImage);
    const colorBar = document.createElement('span');
    const textDiv = document.createElement('div');
    const text = document.createTextNode(`${item.text}`);

    node.setAttribute('data-type', item.type);
    colorBar.setAttribute('data-type', item.type);
    textDiv.appendChild(text);
    textDiv.setAttribute('data-type', 'text');
    node.appendChild(colorBar);
    node.appendChild(textDiv);
    node.appendChild(closeButton);
  };

  renderToastItem = () => {
    if (this.state.toatArray.length === 0) return null;
  };

  render() {
    return <div id="toast" />;
  }
}

export default RencarToast;
