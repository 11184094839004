import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const IGetOrdererListParam = z.object({});
export type IGetOrdererListParam = z.infer<typeof IGetOrdererListParam>;
type Orderers = {
  id: number;
  name: string;
  name_kor: string;
  brand_name: string;
};
type SuccessResponse = {
  orderers: Array<Orderers>;
  defaultInfo: callApi2.DefaultInfo;
};

export type IGetOrdererListResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getOrdererList(
  params: IGetOrdererListParam,
  store: StoreType
): Promise<IGetOrdererListResponse> {
  const response = await callApi2.get<IGetOrdererListResponse>(`/v2/users/orderers`, params);

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
