const question = '/images/question/question-white.png';

export const normalRentCallConfirmModalData = [
  [
    {
      key: 'car_number',
      label: '차량 번호',
      type: 'output',
    },
  ],
  [
    {
      key: 'car_name',
      label: '차량명 / 연식',
      type: 'output',
      tooltip: {
        img: question,
        message: '최초 등록일',
        style: { marginLeft: '-8px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
        triangleStyle: { left: '30px' },
      },
    },
  ],
  [
    {
      key: 'loan_amount',
      label: '대여금액',
      type: 'output',
      comma: true,
    },
  ],
  [
    {
      key: 'loan_period',
      label: '대여기간',
      type: 'output',
    },
  ],
];

// inventory완료
export const normalRentCallConfirmModalDataInventory = [
  [
    {
      key: 'car_number',
      label: '차량 번호',
      type: 'output',
    },
  ],
  [
    {
      key: 'car_group_name_year',
      label: '그룹명 / 연식',
      type: 'output',
      tooltip: {
        img: question,
        message: '최초 등록일',
        style: { marginLeft: '-8px', fontSize: '14px', fontWeight: 'normal', top: '15px' },
        triangleStyle: { left: '30px' },
      },
    },
  ],
  [
    {
      key: 'loan_amount',
      label: '대여금액',
      type: 'output',
      comma: true,
    },
  ],
  [
    {
      key: 'loan_period',
      label: '대여기간',
      type: 'output',
    },
  ],
];
export const selfContractSendingModalData = [
  [
    {
      key: 'original_customer_contact',
      label: '예약 고객 연락처',
      type: 'output',
    },
  ],
  [
    {
      key: 'self_contract_info_contact',
      label: '운전자 연락처',
      type: 'output',
      tooltip: {
        img: question,
        message:
          '비대면 계약서가 전송되는 연락처입니다. 예약 고객과\n실제 운전자가 다를 경우 수정 후 전송해주세요.',
        style: { marginLeft: '48px', fontSize: '12px', fontWeight: 'normal' },
      },
      btn: {
        name: '수정',
        style: { marginLeft: 10 },
      },
      style: { justifyContent: 'flex-start' },
    },
  ],
  [
    {
      key: 'car_number',
      label: '차량 번호',
      type: 'output',
    },
  ],
  [
    {
      key: 'car_name',
      label: '차량명 / 연식',
      type: 'output',
    },
  ],
  [
    {
      key: 'loan_amount',
      label: '대여금액',
      type: 'output',
      comma: true,
    },
  ],
  [
    {
      key: 'loan_period',
      label: '대여기간',
      type: 'output',
    },
  ],
];
export const selfContractSendingModalModifyData = [
  [
    {
      key: 'original_customer_contact',
      label: '예약 고객 연락처',
      type: 'output',
    },
  ],
  [
    {
      key: 'self_contract_info_contact',
      label: '운전자 연락처',
      tooltip: {
        img: question,
        message:
          '비대면 계약서가 전송되는 연락처입니다. 예약 고객과\n실제 운전자가 다를 경우 수정 후 전송해주세요.',
        style: { marginLeft: '48px', fontSize: '12px', fontWeight: 'normal' },
      },
      type: 'input',
      input: {
        type: 'number',
        placeholder: '- 없이 숫자만 입력',
      },
      btn: {
        name: '완료',
      },
      style: { justifyContent: 'flex-start' },
    },
  ],
  [
    {
      key: 'car_number',
      label: '차량 번호',
      type: 'output',
    },
  ],
  [
    {
      key: 'car_name',
      label: '차량명 / 연식',
      type: 'output',
    },
  ],
  [
    {
      key: 'loan_amount',
      label: '대여금액',
      type: 'output',
      comma: true,
    },
  ],
  [
    {
      key: 'loan_period',
      label: '대여기간',
      type: 'output',
    },
  ],
];
