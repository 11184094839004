import { DailyCostTable, Details } from 'components/DailyCostListTable/type';

import * as callApi2 from '../../helpers/callApi2';

interface PutDailyGroupCostParams {
  id: number;
  daily_cost_tables: (Pick<DailyCostTable, 'id' | 'name'> & {
    car_group_ids: number[];
    period_detail: {
      weekday: Omit<Details, 'period_id'>;
      weekend: Omit<Details, 'period_id'>;
    } | null;
  })[];
}

type PutDailyGroupCostResponse = {
  id: number;
  success: boolean;
};

export const putDailyGroupCost = (params: PutDailyGroupCostParams) => {
  const { id, daily_cost_tables } = params;

  return callApi2.put<PutDailyGroupCostResponse>(`/v3/group-cost-tables/daily/periods/${id}`, {
    daily_cost_tables,
  });
};
