/** 무한 */
export const UNLIMITED = -1;

// TODO: 보험 계약서에도 적용하기.
export const COMPREHENSIVE_INSURANCE_INITIAL_DATA = {
  /** 대인 한도: 무한 */
  INSURANCE_PERSON_LIMIT: UNLIMITED,
  /** 대인 면책금 */
  PEOPLE_INDEMNFICATION_FEE: null,
  /** 대물 한도: 1억 */
  INSURANCE_OBJECT_LIMIT: 10000,
  /** 대물 면책금 */
  OBJECT_INDEMNFICATION_FEE: null,
  /** 자손 한도(부상): 1500만 */
  INSURANCE_SELF_LIMIT: 1500,
  /** 자손 한도(사망): 1500만 */
  INSURANCE_SELF_DEAD_LIMIT: 1500,
  /** 자손 면책금 */
  SELF_INDEMNIFICATION_FEE: null,
  /** input 노출 여부 */
  IS_EXIST_COMPREHENSIVE_INSURANCE: false,
} as const;
