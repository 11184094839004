import { recommandPDFformURL } from 'constants/external_url';
import React from 'react';

import styles from './StateApplyPartnerModal.module.scss';

export const StateApplyPartnerModal = (props: { state: 'waiting' | 'rejected' }) => {
  if (props.state === 'waiting') {
    return (
      <div className={styles.evaluating_apply_partner_modal_container}>
        <div className={styles.content}>현재 입점 신청 심사중입니다.</div>
        <div className={styles.sub_content}>심사 기간은 5영업일 정도 소요됩니다.</div>
        <div className={styles.button_wrap}>
          <div className={styles.button} onClick={() => window.open(recommandPDFformURL)}>
            추천서 양식 다운로드
          </div>
        </div>
      </div>
    );
  }

  if (props.state === 'rejected') {
    return (
      <div className={styles.cant_apply_partner_modal_container}>
        <div className={styles.content}>지금은 파트너 재신청 기간이 아닙니다.</div>
        <div className={styles.sub_content}>파트너 입점 재신청은 심사 결과</div>
        <div className={styles.sub_content}>통지로부터 30일 경과 후 가능합니다.</div>
      </div>
    );
  }

  return null;
};
