import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import { ConfirmModalDefaultType as DefaultType } from '../../../interface/utils/modalContents';
import ConfirmAlert from '../../Alert/ConfirmAlert';
import styles from './BookedCarChangeModal.module.scss';

interface PropsType extends DefaultType {
  isDispatchStart?: boolean;
  isRegister?: boolean;
  version: number;
}
@inject('modalStore')
@observer
class BookedCarChangeModal extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {};
  }

  getContent = () => {
    if (this.props?.isDispatchStart) {
      return (
        <div className={styles.text}>
          <article>
            이미 배차 출발한 건입니다.
            <br />
            배차에 문제가 있으신 경우 예약 차량을 변경해주세요.
          </article>
          <article style={{ marginTop: 22 }}>
            차량 변경은 동종 차종(추천)으로 변경을 권장 드립니다.
            <br />
            예약 확정 차종과 다른 차종으로 변경하실 경우 꼭 고객과 먼저 협의 후 진행해 주세요.
          </article>
          <article style={{ marginTop: 22 }}>
            다른 차종으로 변경 시 고객이 예약 취소 또는 변경 차량을 거절할 수 있습니다.
          </article>
        </div>
      );
    }

    if (this.props?.isRegister) {
      return (
        <div className={styles.text}>
          <article>업그레이드 차량으로 변경 시 고객이 예약 취소 또는</article>
          <article>변경 차량을 거절할 수 있습니다.</article>
          <article style={{ marginTop: 22 }}> 동종차량(추천)으로 변경을 권장드립니다.</article>
        </div>
      );
    }

    return (
      <div className={styles.text}>
        <article style={{ fontWeight: 'bold' }}>고객과 먼저 협의 하셨나요?</article>
        <article style={{ marginTop: 22 }}>
          차량 변경은 동종 차종(추천)으로 변경을 권장 드립니다.
          <br />
          예약 확정 차종과 다른 차종으로 변경하실 경우 꼭 고객과 먼저 협의 후 진행해 주세요.
        </article>
        <article style={{ marginTop: 22 }}>
          다른 차종으로 변경 시 고객이 예약 취소 또는 변경 차량을 거절할 수 있습니다.
        </article>
      </div>
    );
  };
  render() {
    const cancelBtnStyle = { width: 64, padding: 0 };
    const confirmBtnStyle = { width: 108 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={this.props.onClickConfirm}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
      />
    );
  }
}

export default BookedCarChangeModal;
