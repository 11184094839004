import styled from 'styled-components';

export const Mask = styled.div<{ visible: boolean; zIndex?: number; isFixed?: boolean }>`
  background-color: rgba(0, 0, 0, 0.4);
  position: ${(props) => (props.isFixed ? 'fixed' : 'absolute')};
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => (zIndex === undefined ? 1000 : zIndex)};
  display: ${(props) => (props.visible ? 'block' : 'none')};
  width: 100%;
  height: 100%;
`;
