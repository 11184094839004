import errorHandler from 'helpers/errorHandler';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';

export type PutCustomerMemoParams = {
  customer_id: number;
  memo: string;
};

type SuccessResponse = {
  defaultInfo: callApi2.DefaultInfo;
  success: boolean;
};

export type PutCustomerMemoResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function putCustomerMemo(
  params?: PutCustomerMemoParams
): Promise<PutCustomerMemoResponse> {
  const response = await callApi2.put<PutCustomerMemoResponse>(
    `v2/customers/${params.customer_id}/memo`,
    omit(params, ['customer_id'])
  );

  if (response.status === 200) {
    return response;
  }

  errorHandler(response.data, response.status);
}
