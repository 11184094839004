type IsBenzCallParams = {
  type: 'requestUserWorkerType' | 'importedCarDealershipKor';
  value: string;
};

export const isBenzCall = (option: IsBenzCallParams) => {
  if (option.type === 'requestUserWorkerType') {
    return option.value?.includes('mbfsk');
  }

  return option.value === '벤츠';
};
