import find from 'lodash/find';

import apiEtc from '../api/etc';
import apiRentContract from '../api/rentContract';
import errorHandler from '../helpers/errorHandler';
import * as modalUtils from '../utils/CommonOpenModalUtils';

const licenseCode = [
  { code: '00', message: '정상', alertMessage: '해당 면허는 정상입니다.' },
  {
    code: '01',
    message: '면허번호 불일치',
    alertMessage: '일치하는 면허 번호가 없습니다. 정보를 확인해주세요.',
  },
  { code: '02', message: '재발급된 면허', alertMessage: '해당 면허는 재발급된 면허입니다.' },
  { code: '03', message: '분실', alertMessage: '해당 면허는 분실된 면허입니다.' },
  { code: '04', message: '사망 취소', alertMessage: '해당 면허는 사망 취소된 면허입니다.' },
  { code: '11', message: '취소', alertMessage: '해당 면허는 취소된 면허입니다.' },
  { code: '12', message: '정지', alertMessage: '해당 면허는 정지된 면허입니다.' },
  { code: '13', message: '기간 중 취소', alertMessage: '해당 면허는 기간 중 취소된 면허입니다.' },
  { code: '14', message: '기간 중 정지', alertMessage: '해당 면허는 기간 중 정지된 면허입니다.' },
  {
    code: '21',
    message: '운전자명 불일치',
    alertMessage: '운전자명이 일치하지 않습니다. 정보를 확인해주세요.',
  },
  {
    code: '22',
    message: '생년월일 불일치',
    alertMessage: '생년월일이 일치하지 않습니다. 정보를 확인해주세요.',
  },
  {
    code: '23',
    message: '암호 일렬번호 불일치',
    alertMessage: '암호 일렬번호가 일치하지 않습니다. 정보를 확인해주세요. ',
  },
  {
    code: '24',
    message: '면허 구분 불일치',
    alertMessage: '면허 구분이 일치하지 않습니다. 정보를 확인해주세요.',
  },
];

export const returnLisenceCodeMessage = (code) => {
  if (!code) {
    return '-';
  }

  const result = licenseCode.find((item) => code === item.code);

  return result.message;
};

export const certificateLicense = (data, store) => {
  return new Promise(async (resolve, reject) => {
    // const request = {
    //   method: 'POST',
    //   path: '/certificate_user_license',
    //   body: data
    // }
    const response = await apiRentContract.certificateLicense({
      disableErrorHandler: true,
      ...data,
    });
    const responseData = response.data;

    store.loadingStore.off();

    if (response.status == 200) {
      let response = responseData.response.resultCode;

      showAlertdriverLicenseResult(response);
      resolve(response);
    } else if (responseData.error_no == 400) {
      alert('데이터 타입이 잘못되었습니다.');
      resolve(false);
    } else if (responseData.error_no == 402) {
      alert('면허번호를 확인하세요.');
      resolve(false);
    } else if (responseData.error_no === 500) {
      alert(
        '현재 국토교통부(RIMS) 서버 장애로 인해 면허 진위 조회 및 운전자격확인 서비스가 원활하지 않습니다. 지속적으로 서비스 오류 시 계약서를 먼저 작성 후 계약서 상세에서 운전자격검증을 진행 부탁드립니다. 이용에 불편을 드려 죄송합니다.'
      );
      resolve(false);
    } else {
      errorHandler(responseData, response.status);
      resolve(false);
    }

    if (data.type === 'customer' && Boolean(data.setValue)) {
      const conflictContractList = await getConflictContractList(data, store);

      if (conflictContractList) {
        data.setValue({ key: 'duplicatedContractList', value: conflictContractList });
        modalUtils.openDuplicatedContractModal({ list: conflictContractList }, store.modalStore);
      }
    }
  });
};

const getConflictContractList = async (data, store) => {
  try {
    const { customer_birth, customer_name, customer_driver_license } = data;

    if (!customer_birth) {
      return;
    }

    if (!customer_name) {
      return;
    }

    if (!customer_driver_license) {
      return;
    }

    // - customer_name, customer_id_number1, customer_driver_license
    // const request = {
    //   method: 'GET',
    //   path: `/v2/conflict-contracts?customer_name=${customer_name}&customer_id_number1=${customer_birth}&customer_driver_license=${customer_driver_license}`,
    // }
    const params = {
      customer_name,
      customer_driver_license,
      customer_id_number1: customer_birth,
      menuStore: store.MenuStore,
      authStore: store.AuthStore,
    };
    const response = await apiEtc.normalContractReturnGasChange(params);
    const responseData = response.data;

    if (responseData) {
      if (
        Array.isArray(responseData.conflict_contracts) &&
        responseData.conflict_contracts.length > 0
      ) {
        return responseData.conflict_contracts;
      }

      return null;
    }

    return null;
  } catch (error) {
    return null;
  }
};

const showAlertdriverLicenseResult = (code) => {
  try {
    const message = find(licenseCode, { code }).alertMessage;

    alert(message);

    if (code === '00') {
      return true;
    }

    return false;
  } catch (error) {
    alert('잘못된 결과값입니다.');

    return false;
  }
};

export const driverLicenseCheck = (code) => {
  if (!code) {
    return '비정상';
  }

  let result;

  try {
    result = find(licenseCode, { code }).message;
  } catch (e) {
    result = '';
  }

  return result;
};

export const addHyphenToLicenseNumber = (licenseNumber, isKor) => {
  if (!licenseNumber) {
    return '-';
  }

  if (isKor) {
    return `${licenseNumber.slice(0, 2)}-${licenseNumber.slice(2, 8)}-${licenseNumber.slice(
      8,
      10
    )}`;
  }

  return `${licenseNumber.slice(0, 2)}-${licenseNumber.slice(2, 4)}-${licenseNumber.slice(
    4,
    10
  )}-${licenseNumber.slice(10, 12)}`;
};
