// 모바일과 웹 동일한 화면이 보이는 경우 특정 스타일 지정
const DESKTOP_LAYOUT_MOBILE_PAGES = [
  '/mobile/selfInfoRegister',
  '/mobile/selfInfoSignature',
  '/mobile/selfInfoSignature/agree',
  '/mobile/selfInfoSignature/expire',
  '/mobile/selfInfoRegister/complete',
  '/mobile/selfInfoSignature/preview',
  '/mobile/extension',
  '/mobile/extension/finishRent',
  '/mobile/extension/expired',
];

export { DESKTOP_LAYOUT_MOBILE_PAGES };
