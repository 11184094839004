import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
export interface IModifyMonthlyCostParams {
  id: number; // 서버로보내지는 않음. url 에 쓰임
  name?: string;
  car_group_ids?: number[];
  d15_total_cost?: number;
  d15_daily_cost?: number;
  d15_security_deposit?: number;
  d30_total_cost?: number;
  d30_daily_cost?: number;
  d30_security_deposit?: number;
}

type SuccessResponse = {
  id: number;
  success: boolean;
};
export type IModifyMonthlyCostResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function modifyMonthlyCost(params: IModifyMonthlyCostParams) {
  return callApi2.put<IModifyMonthlyCostResponse>(
    `/v2/group-cost-tables/monthly/${params.id}`,
    omit(params, ['id'])
  );
}
