export function phoneNumberHyphen(value: string) {
  try {
    if (!value) {
      throw new Error('this value is invalid');
    }

    let formatNum = '';

    if (value.length === 11) {
      formatNum = value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (value.length === 8) {
      formatNum = value.replace(/(\d{4})(\d{4})/, '$1-$2');
    } else if (value.length === 12) {
      formatNum = value.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    } else if (value.indexOf('02') === 0) {
      if (value.length === 9) {
        formatNum = value.replace(/(\d{2})(\d{3})(\d{4})/, '$1-$2-$3');
      } else {
        formatNum = value.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      formatNum = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    return formatNum;
  } catch (error) {
    return '-';
  }
}

export default phoneNumberHyphen;
