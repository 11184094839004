import styled from 'styled-components';
import { TEXT } from 'styles/textStyle';

export const ModalWrap = styled.div<{ modalOn: boolean }>`
  position: fixed;
  z-index: 18000;
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: ${({ modalOn }) => {
    return modalOn ? 'block' : 'none';
  }};
`;

export const Modal = styled.div`
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  position: absolute;
  z-index: 18000;
  top: 50%;
  left: 50%;
  width: 50%;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ModalHeader = styled.div`
  padding-inline: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  position: relative;
  top: 1px;
  background-color: var(--white);
  line-height: 64px;
  border-radius: 16px 16px 0 0;
`;

export const ModalTitle = styled.span`
  ${TEXT.Headline2_Gray8_Bold_18px}
  margin-right: 9px;
`;

export const ModalSubTitle = styled.span`
  font-size: 18px;
`;

export const ModalClose = styled.div`
  display: flex;
  align-items: center;
  background-color: transparent;
`;

export const ModalCloseImg = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

export const ModalSubNotice = styled.div`
  color: red;
  font-size: 12px;
  background-color: $white;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
`;

export const ModalBody = styled.div`
  background-color: var(--white);
  border-radius: 0 0 16px 16px;
`;
