export const serverBaseUrl = process.env.apiBaseUrl;
export const cookieDomain = process.env.cookieDomain;
export const carApiBaseUrl = process.env.carApiBaseUrl;
export const fileUploadHost = process.env.fileUploadHost;
export const holidaysHost = process.env.holidaysHost;
export const API_ENV = process.env.API_ENV;

if (process.env.API_ENV !== 'production') {
  console.log('-----------------------');
  console.log(`domain         - ${cookieDomain}`);
  console.log(`api server     - ${serverBaseUrl}`);
  console.log(`car api server - ${carApiBaseUrl}`);
  console.log(`fileUploadHost - ${fileUploadHost}`);
  console.log(`holidaysHost   - ${holidaysHost}`);
  console.log('-----------------------');
}
