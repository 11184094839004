import callApi from '../../helpers/callApi';

export * from './getOrdererList';
export default {
  checkOnBoarding(params) {
    return callApi({
      method: 'POST',
      url: '/v2/onboarding',
      serverCookie: params.cookie || null,
      jwt: params.jwt || null,
    });
  },
  certifyNumber(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: '/v2/users/verification-code',
      data: {
        username: params.username,
        phone_number: params.phone_number,
        realname: params.name,
      },
    });
  },
  checkCertNumber(params) {
    return callApi({
      method: 'POST',
      url: '/v2/users/verify-code',
      data: {
        username: params.username,
        phone_number: params.phone_number,
        verification_code: params.verification_code,
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  certifyPhoneNumber(params) {
    return callApi({
      method: 'POST',
      url: '/v2/users/verification-code-for-phone',
      data: {
        phone_number: params.phoneNumber,
      },
    });
  },
  certifyPhoneNumberRealname(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: '/v2/users/verification-code-for-phone-realname',
      data: {
        realname: params.realname,
        phone_number: params.phone_number,
      },
    });
  },
  checkCertPhoneNumber(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: '/v2/users/verify-phone',
      data: {
        phone_number: params.phoneNumber,
        verification_code: params.certifyNum,
      },
    });
  },
  checkCertPhoneNumberRealname(params) {
    return callApi({
      method: 'POST',
      url: '/v2/users/find-ims-username',
      data: {
        realname: params.realname,
        contact: params.contact,
        verification_code: params.verification_code,
        platform: 'imsform',
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  editUserInfo(params) {
    return callApi({
      method: 'POST',
      url: `/v2/users/${params.userId}`,
      data: params.data,
    });
  },
  getUserInfo(params) {
    return callApi({
      method: 'GET',
      url: `/v2/users/${params.userId}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getSalesEmpolyees(params) {
    let endPoint = `/v2/sales-employees?page=${params.page}`;

    if (params.searchOption && params.searchValue) {
      endPoint += `&option=${params.searchOption}&value=${encodeURIComponent(params.searchValue)}`;
    }

    return callApi({
      method: 'GET',
      url: endPoint,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  idDuplicateCheck(params) {
    return callApi({
      method: 'GET',
      url: 'v2/users/duplicate-check',
      params: { username: params.username },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  removeUser(params) {
    return callApi({
      method: 'DELETE',
      url: `/v2/users/${params.id}`,
    });
  },
  registerUser(params) {
    return callApi({
      method: 'POST',
      url: '/v2/users',
      data: params,
    });
  },
  registerRentCompany(params) {
    return callApi({
      method: 'POST',
      url: '/register_rent_company',
      data: params,
    });
  },
  resetPassword(params) {
    return callApi({
      method: 'POST',
      url: '/v2/users/reset-password',
      data: {
        username: params.username,
        password: params.password,
      },
    });
  },
  getSamsungRequestDetail(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'GET',
      url: '/v2/public/samsung-request',
      params: { request_id: params.request_id },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  getUpdateLocation(params) {
    return callApi({
      method: 'GET',
      url: `${params.carApiBaseUrl}/api/v1/location/${params.requestId}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },

  withdrawalCheck() {
    return callApi({
      method: 'GET',
      url: '/v2/users/withdrawal-check',
    });
  },

  withdrawal(params) {
    return callApi({
      method: 'PUT',
      url: '/v2/users/withdrawal',
      data: {
        withdrawal_reason: params.withdrawal_reason,
        withdrawal_reason_detail: params.withdrawal_reason_detail,
        password: params.password,
      },
    });
  },
};
