import checkbox from 'antd/es/checkbox';
import { max, min, size } from 'lodash';
import Button from 'newDesign/atom/Button';
import styled, { createGlobalStyle } from 'styled-components';
import { TEXT } from 'styles/textStyle';

export const CustomerTableStyle = createGlobalStyle`
    .ant-table-cell {
    border: none;
    text-align: left;
    
    ${TEXT.Caption4_Gray7_Regular_11px}
  }
  
  .ant-table-placeholder {
    .ant-table-cell {
      text-align: center;
    }
  }

  .ant-table-header  {
    border-radius: 0px !important;
  }

  .ant-table-thead {
    th {
      border-radius: 0px !important;
      font-size: 11px;
      font-weight: 500 !important;
      font-family: 'Noto Sans';
      color: var(--new-gray-7) !important;
      background-color: var(--new-gray-1) !important;
      padding: 5px 8px !important;
      height: 28px;
    }
  }

  .ant-pagination-item {
    ${TEXT.Caption4_Gray7_Bold_11px}
    border-radius: 30px !important;
  }

  .ant-pagination-item-active {
    a {
      font-weight: bold !important;
      color: var(---new-gray-7) !important;
    }

    font-weight: bold !important;
    border: none !important;
    background: var(--new-gray-1) !important;
  }
  .ant-pagination-next, .ant-pagination-prev {
    display: none !important;
  }
  :where(.css-dev-only-do-not-override-zjzpde).ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }

  .ant-pagination {
    margin: 20px 0 0 !important;
  }

  .ant-table-cell {
    padding: 8px !important;
  }
`;

export const CustomCheckBoxStyle = createGlobalStyle`
  .ant-checkbox-inner {
    width: 20px !important;
    height: 20px !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner::after {
    height: 11px !important;
  }
`;

export const ModalWrap = styled.div`
  padding: 0px 24px;
`;

export const Description = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0 13px;
  align-items: center;
`;

export const BlakcListInfoWrap = styled.div<{ blackListHover: boolean }>`
  display: flex;
  gap: 4px;
  background: ${({ blackListHover }) => {
    return blackListHover ? 'var(--new-gray-4)' : 'var(--new-gray-2)';
  }};
  padding: 4px 8px;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;

  .text {
    ${TEXT.Caption3_Gray7_Regular_12px}
    line-height: 1;
  }
`;

export const SearchDescription = styled.div`
  ${TEXT.Caption3_Gray7_Regular_12px}
`;

export const TableContainer = styled.div`
  overflow: auto;
  width: 550px;
  max-height: 500px;
  min-height: 400px;
  margin-top: 16px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 30px;
`;

export const SelectButton = styled(Button)`
  ${TEXT.Caption1_White_Regular_14px}
  width: 70px;
  height: 40px;
  margin-top: 8px;
  padding: 0;
`;

export const ResetButton = styled(Button)`
  width: 52px;
  height: 36px;
  padding: 0;
  ${TEXT.Caption2_Gray7_Regular_13px}
  background-color: var(--new-gray-2);
`;
