const s3 = 'https://rencar-resource.s3.ap-northeast-2.amazonaws.com/imsform/images/';

export const imsformLogo = `${s3}main/main_logo.png`;
export const btnAppstore = `${s3}main/btn_appstore.png`;
export const btnGoogleplay = `${s3}main/btn_googleplay.png`;
export const btnSnsBlog = `${s3}main/btn_sns_blog.png`;
export const btnSnsCafe = `${s3}main/btn_sns_cafe.png`;
export const introBgImg = `${s3}main/intro_bg_img.png`;
export const outroBgImg = `${s3}main/outro_bg_img.png`;
export const serviceImg1 = `${s3}main/service_img1.png`;
export const serviceImg2 = `${s3}main/service_img2.png`;
export const serviceImg3 = `${s3}main/service_img3.png`;
export const serviceImg4 = `${s3}main/service_img4.png`;
export const serviceImg5 = `${s3}main/service_img5.png`;
export const serviceImg6 = `${s3}main/service_img6.png`;

// ie 지원 중단 페이지
export const chromeLogo = `${s3}ie/chrome.png`;
export const edgeLogo = `${s3}ie/edge.png`;
export const whaleLogo = `${s3}ie/whale.png`;

// 패널티
export const penaltyQr = `${s3}penalty/ic_QRcode.png`;
export const penaltyGoogleplay = `${s3}penalty/ic_googlepaly.png`;
export const penaltyIms = `${s3}penalty/ic_ims_penalty.png`;
export const penaltyStep1 = `${s3}penalty/step1.png`;
export const penaltyStep2 = `${s3}penalty/step2.png`;
export const penaltyStep3 = `${s3}penalty/step3.png`;
export const penaltyStep4 = `${s3}penalty/step4.png`;
export const penaltyStep5 = `${s3}penalty/step5.png`;
export const penaltyStep6 = `${s3}penalty/step6.png`;
export const nextArrow = `${s3}penalty/ic_right.png`;
export const prevArrow = `${s3}penalty/ic_left.png`;

// 리뷰
export const twoPeople = `${s3}review/img01.png`;
export const onePeople = `${s3}review/img02.png`;
export const starBucksBanner = `${s3}review/img03.png`;
export const offStar = `${s3}review/img04.png`;
export const onStar = `${s3}review/img05.png`;
export const filePlus = `${s3}review/plus.png`;

// 게시판 카테고리
export const downloadFileIcon = `${s3}common/ic_download.png`;

// 비대면 계약서
export const untactPeople = `${s3}contract/img_untact_01.png`;
export const diffentPhoneNumber = `${s3}contract/img_untact_02.png`;
export const contract = `${s3}contract/ic_untact_contract.png`;
export const untactCallGray = `${s3}contract/ic_untact_call_gray.png`;

// 온-보딩
export const onBoardingMain = `${s3}common/img_onboardingmain.png`;
export const onBoardingArrowDown = `${s3}common/ic_arrow_down_white.png`;
export const onBoardingArrowUp = `${s3}common/ic_arrow_up_white.png`;
export const onBoardingHalfArrow = `${s3}common/ic_arrow_right_half_black.png`;
export const onBoardingBulb = `${s3}common/img_bulb.png`;
export const onBoardingActiveStepNum1 = `${s3}common/img_first_black.png`;
export const onBoardingActiveStepNum2 = `${s3}common/img_second_black.png`;
export const onBoardingActiveStepNum3 = `${s3}common/img_third_black.png`;
export const onBoardingDisableStepNum1 = `${s3}common/img_first_gray.png`;
export const onBoardingDisableStepNum2 = `${s3}common/img_second_gray.png`;
export const onBoardingDisableStepNum3 = `${s3}common/img_third_gray.png`;
export const onBoardingPassedStamp = `${s3}common/img_stamp_passed.png`;

// 소개처 알람톡
export const recommendInvalid = `${s3}main/img_character_120x100.png`;
