import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import apiConnect from '../../../../api/imsConnect';
import CommonRowTable from '../../../../components/CommonRowTable2';
import { onBoardingBulb } from '../../../../constants/images';
import * as MESSAGE from '../../../../constants/stringTable';
import comma from '../../../../helpers/costComma';
import { isSelectedAfter220714 } from '../../../../utils/ClaimDetailUtils';
import * as utils from '../../../../utils/NormalContractDetailUtils';
import styles from './ClaimModalStyles.module.scss';
@inject('modalStore')
@inject('menuStore')
@inject('authStore')
@inject('loadingStore')
@observer
class SetReturnCarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      returnGasCharge: null,
      expect_return: null,
      expect_return_date: null,
      expect_return_hour: null,
      expect_return_minute: null,
      delivered_date: null,
      delivery_gas_charge: null,
      delivery_distance: null,
      return_gas_charge: null,
      return_distance: null,
      gasChargeSelectOptions: [],
      rentType: props.rentType,
    };
  }

  componentDidMount() {
    const { use_connect } = this.props.contractInfo;

    if (use_connect) {
      this.getCarDataList();
    }

    this.returnGasChargeOption();
    this.init();
  }

  getCarDataList = async () => {
    const params = {
      carId: this.props.contractInfo?.rent_car_number,
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
    };

    try {
      this.props.loadingStore.on();
      const response = await apiConnect.getCarData(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.props.loadingStore.off();
        this.setState({
          fuelType: responseData.fuelType,
          fuelRemainPct:
            typeof responseData.fuelRemainPct === 'string' ? null : responseData.fuelRemainPct,
          fuelRemainVol:
            typeof responseData.fuelRemainVol === 'string' ? null : responseData.fuelRemainVol,
          gasPrice: responseData.gas_price,
          totalMileage:
            typeof responseData.totalMileage === 'string'
              ? null
              : Math.round(responseData.totalMileage),
        });
      } else {
        this.props.loadingStore.off();
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.loadingStore.off();
    }
  };

  init = () => {
    const info = this.props.contractInfo;

    if (this.props.returnType === 'return') {
      this.setState({
        expect_return: dayjs().format('YYYY-MM-DD HH:mm'),
        expect_return_date: dayjs().format('YYYY-MM-DD'),
        expect_return_hour: dayjs().format('HH'),
        expect_return_minute: dayjs().format('mm'),
        delivery_gas_charge: info.gas_charge ? `${info.gas_charge}%` : '-',
        delivery_distance: info.driven_distance ? info.driven_distance : '-',
        delivered_date: info.delivered_date,
      });
    } else if (this.props.returnType === 'change') {
      this.setState({
        delivery_gas_charge: info.gas_charge ? `${info.gas_charge}%` : '-',
        delivery_distance: info.driven_distance ? info.driven_distance : '-',
        delivered_date: dayjs(info.delivered_date).format('YYYY-MM-DD HH:mm'),
      });
    }
  };

  returnGasChargeOption = () => {
    let data = [];

    for (let i = 1; i <= 100; i++) {
      if (i % 5 === 0) {
        data.push({ label: `${i}%`, value: i });
      }
    }

    this.setState({ gasChargeSelectOptions: data });
  };

  validate = () => {
    const { gas_charge, use_connect } = this.props.contractInfo;
    const { fuelType, fuelRemainPct, fuelRemainVol, gasPrice, return_gas_charge } = this.state;
    let usedOil = null;
    let fuelCost = 0;
    const returnDateTime = `${this.state.expect_return_date}-${this.state.expect_return_hour}-${this.state.expect_return_minute}`;
    const returnDistance = Number(this.state.return_distance);
    let data = {};

    if (
      !this.state.expect_return_date ||
      !this.state.expect_return_hour ||
      !this.state.expect_return_minute
    ) {
      return alert('반납 일시를 확인해주세요.');
    }

    if (typeof return_gas_charge !== 'number' && !return_gas_charge)
      return alert('유류량을 입력해주세요.');

    if (return_gas_charge < 0 || return_gas_charge > 100)
      return alert('1~100 사이의 숫자만 입력해 주세요.');

    if (Boolean(this.state.return_distance) && isNaN(returnDistance)) {
      return alert('반납 시 km는 숫자만 입력해주세요.');
    }

    if (
      fuelRemainVol &&
      fuelRemainPct &&
      gasPrice &&
      !(this.props.orderer === 'kakao') &&
      fuelType !== '전기' &&
      use_connect
    ) {
      if (return_gas_charge) {
        usedOil = ((gas_charge - return_gas_charge) * fuelRemainVol) / fuelRemainPct;
      } else {
        usedOil = ((gas_charge - fuelRemainPct) * fuelRemainVol) / fuelRemainPct;
      }

      fuelCost = Math.round((usedOil * gasPrice) / 10) * 10;
    }

    data = {
      deliveredDate: this.state.delivered_date,
      done_at: returnDateTime,
      return_gas_charge: this.state.return_gas_charge,
      driven_distance_upon_return: this.state.return_distance,
    };

    if (usedOil !== null) {
      data.fuel_cost = fuelCost;
    }
    // timeData = {
    //   deliveredDate: this.state.delivered_date,
    //   returnDate:
    // }

    if (this.props.returnType === 'change') {
      this.props.setChangeCarContract(data);
    } else if (this.props.returnType === 'return') {
      this.props.setDone(data);
    }
  };

  returnTableInfo = () => {
    const { use_connect } = this.props.contractInfo;

    switch (this.props.returnType) {
      case 'return':
        return use_connect
          ? utils.connectSetReturnInfoTableData(this.state)
          : utils.setReturnInfoTableData(this.state);
      case 'change':
        return use_connect
          ? utils.connectChangeCarInfoTableData(this.state)
          : utils.changeCarInfoTableData(this.state);
      default:
    }
  };

  tableFootnote = () => {
    return (
      <p className={styles.footnote_wrap}>
        IMS.connect 연동 차량은 현재 유류량/주행거리 데이터를 참고해주세요. (통신 오류 시 데이터가
        표시되지 않을 수 있습니다.)
      </p>
    );
  };

  renderOilCostCalculation = () => {
    const { gas_charge } = this.props.contractInfo;
    const { fuelType, fuelRemainPct, fuelRemainVol, gasPrice, return_gas_charge } = this.state;
    let usedOil = 0;
    // if (this.props.orderer === 'kakao' || fuelType === '전기') {
    //   return <div style={{ marginBottom: '30px' }} />;
    // }

    // 커넥트 api가 에러 혹은 미지원 데이터일 경우
    const isConnectError = [gasPrice, fuelRemainVol, fuelRemainPct].some((item) => !item);

    if (isConnectError) {
      return (
        <>
          <p className={styles.oil_title}>유류비 자동 정산</p>
          <div className={styles.oil_cost_wrap}>
            <div className={styles.fail_content_wrapper}>
              <span>통신오류로 인해 유류비 데이터를 불러오지 못했습니다.</span>
              <span>유류량을 직접 확인 후 유류비 정산을 진행해 주세요.</span>
            </div>
          </div>
          <div className={styles.oil_cost_table_footnote}>
            <img src={onBoardingBulb} />
            <span className={styles.oil_cost_table_footnote_text}>{MESSAGE.OIL_COST_FOOTNOTE}</span>
          </div>
        </>
      );
    }

    if (fuelRemainVol && fuelRemainPct && gasPrice) {
      if (return_gas_charge) {
        usedOil = ((gas_charge - return_gas_charge) * fuelRemainVol) / fuelRemainPct;
      } else {
        usedOil = ((gas_charge - fuelRemainPct) * fuelRemainVol) / fuelRemainPct;
      }
    } else {
      return <div style={{ marginBottom: '30px' }} />;
    }

    const fuelCost = usedOil * gasPrice;

    return (
      <Fragment>
        <p className={styles.oil_title}>유류비 자동 정산</p>
        <div className={styles.oil_cost_wrap}>
          <div className={styles.oil_description_wrap}>
            <div className={styles.oil_type}>
              <div>유종</div>
              <div>사용 유류량</div>
              <div>유가 (전국 평가 기준가)</div>
            </div>
            <div className={styles.oil_value}>
              <div>{fuelType}</div>
              <div>{`${Math.round(usedOil)}L`}</div>
              <div>{`${gasPrice}원`}</div>
            </div>
          </div>
          <div className={styles.oil_cost_content_wrap}>
            <div className={styles.oil_cost_title}>최종 유류비</div>
            {fuelCost >= 0 ? (
              <div className={styles.final_oil_cost_wrap}>
                <span className={styles.positive_oil_cost}>{`${comma(
                  Math.round(fuelCost / 10) * 10
                )}원`}</span>
                <span className={styles.oil_cost_text}>(고객님께 추가 요금을 받아주세요.)</span>
              </div>
            ) : (
              <div className={styles.final_oil_cost_wrap}>
                <span className={styles.negative_oil_cost}>{`${comma(
                  Math.round(fuelCost / 10) * 10
                )}원`}</span>
                <span className={styles.oil_cost_text}>(고객님께 지급해 주세요.)</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.oil_cost_table_footnote}>
          <img src={onBoardingBulb} />
          <span className={styles.oil_cost_table_footnote_text}>{MESSAGE.OIL_COST_FOOTNOTE}</span>
        </div>
      </Fragment>
    );
  };

  renderConnectInterlock = () => {
    // if (this.props.orderer === 'kakao' || this.state.fuelType === '전기') {
    //   return <div style={{ marginBottom: '30px' }} />;
    // }

    return (
      <div
        className={styles.description_wrap}
        style={{
          marginTop: this.props.orderer === 'kakao' || this.state.fuelType === '전기' ? '30px' : 0,
        }}
      >
        <div className={styles.description_title_wrap}>
          <img src={onBoardingBulb} />
          <span className={styles.title_text}>{MESSAGE.CONNECT_DESCRIPTION_TITLE}</span>
        </div>
        <div className={styles.connect_description}>
          <div>{MESSAGE.CONNECT_DESCRIPTION1}</div>
          <div>{MESSAGE.CONNECT_DESCRIPTION2}</div>
        </div>
        <div className={styles.connect_announcement}>{MESSAGE.CONNECT_DESCRIPTION_NOTICE}</div>
      </div>
    );
  };

  renderPaymentInfo = () => {
    const { createAt, returnType, orderer } = this.props;

    if (returnType !== 'change' && orderer === 'kakao') {
      // 차량 변경이아니고 차량 반납일경우에만 그리고 콜 연결건일경우에만 (카카오라는 조건도 붙어야하는데 props가 없다.)
      if (isSelectedAfter220714(createAt)) {
        return (
          <div style={{ marginTop: '10px' }}>
            <span style={{ color: 'red', fontSize: '13px' }}>[카카오 자동정산 제외 건] </span>
            <span style={{ fontSize: '13px' }}>
              주유비, 연장요금 등 추가 비용을 고객에게 직접 받으세요!
            </span>
          </div>
        );
      }

      return (
        <div style={{ marginTop: '10px' }}>
          <span style={{ color: 'blue', fontSize: '13px' }}>[카카오 자동 정산 건] </span>
          <span style={{ fontSize: '13px' }}>
            주유비, 연장요금 등 추가 비용을 고객에게 직접 받지 마세요!
          </span>
        </div>
      );
    }

    return <div style={{ height: 20, width: '100%' }} />;
  };

  render() {
    const { use_connect } = this.props.contractInfo;

    return (
      <div
        className={styles.common_modal_wrap}
        style={{
          maxWidth: 800,
          minWidth: this.props.returnType === 'return' ? 697 : 655,
          minHeight: 350,
        }}
      >
        <CommonRowTable
          connect={use_connect}
          viewData={this.returnTableInfo()}
          setData={(key, value) => this.setState({ [key]: value })}
          stateData={this.state}
          selectKey={this.state}
          labelStyle={{ maxWidth: '120px' }}
          // tableStyle={{ marginBottom: 0 }}
          style={{ marginBottom: 0 }}
        />
        {this.renderPaymentInfo()}
        {use_connect && this.tableFootnote()}
        <div>{use_connect ? this.renderOilCostCalculation() : this.renderConnectInterlock()}</div>

        <div className={styles.button_area} style={{ margin: 0 }}>
          <div
            className={`${styles.button} ${styles.cancel_button}`}
            onClick={() => this.props.modalStore.modalClose()}
          >
            {MESSAGE.CANCEL}
          </div>
          <div className={`${styles.button} ${styles.complete_button}`} onClick={this.validate}>
            반납완료
          </div>
        </div>
      </div>
    );
  }
}

export default SetReturnCarModal;
