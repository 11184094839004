import { useRouter } from 'next/router';
import { useEffect } from 'react';

type ScrollToTopProps = {
  disabledScrollList?: Array<PathType | QueryType>;
  children: React.ReactElement;
};

type PathType = {
  banType: 'path';
  path: string;
  banQueryList?: never;
};

type QueryType = {
  banType: 'query';
  path: string;
  banQueryList: Array<string>;
};

const ScrollToTop = ({ disabledScrollList, children }: ScrollToTopProps) => {
  const router = useRouter();

  useEffect(() => {
    const checkDisableScroll = () => {
      const { pathname: currentPagePathName, query: currentPageQuery } = router;

      return disabledScrollList.some((item) => {
        if (!item.path.includes(currentPagePathName)) {
          return false;
        }

        if (item.banType === 'path') {
          return true;
        }

        if (item.banType === 'query') {
          return item.banQueryList.some((queryKey) => queryKey in currentPageQuery);
        }

        return false;
      });
    };

    if (!disabledScrollList || checkDisableScroll()) {
      return;
    }

    window.scrollTo(0, 0);
  }, [router.asPath, disabledScrollList]);

  return children;
};

export default ScrollToTop;
