import { makeAutoObservable } from 'mobx';

import { BaseStore } from './BaseStore.type';

/** 차량 등록증 file */
type CarRegistrationPhotoFile = {
  /** base64 binary 파일 */
  file: string;
  /** 파일명 */
  name: string;
};

/** 경매 신청할 차량 */
export type SelectedCar = {
  car_number: string;
  model: string;
};

type CarAuctionRequestState = {
  /** 폼에 등록된 차량 여부 */
  isRegisteredCar: boolean;
  /** 담당자명 */
  name: string;
  /** 담당자 연락처 */
  contact: string;
  /** 차량 진단 방문 장소 */
  address: string;
  /** 차량 진단 방문 장소 상세 */
  addressDetail: string;
  /** 법정동 코드 10자리. ex) 1121510500 */
  legalDongCode: string;
  /** 모델명 */
  carName: string;
  /** 선택한 경매 차량 */
  selectedCars: SelectedCar[];
  /** 차량 번호 */
  carNumber: string;
  /** 메모 */
  memo: string;
  /** 제3자 정보제공 및 조회 동의 여부 */
  isAgree: boolean;
  /** 차량등록증 파일 정보 */
  carRegistrationPhoto: CarRegistrationPhotoFile;
};

type CarAuctionRequestStateName = keyof CarAuctionRequestState;

const INITIAL_CAR_AUCTION_REQUEST_STATE: CarAuctionRequestState = {
  isRegisteredCar: true,
  name: '',
  contact: '',
  address: '',
  addressDetail: '',
  legalDongCode: '',
  carName: '',
  selectedCars: [],
  carNumber: '',
  memo: '',
  isAgree: false,
  carRegistrationPhoto: {
    file: '',
    name: '',
  },
};

class CarAuctionStore implements BaseStore<CarAuctionStore> {
  isRegisteredCar = INITIAL_CAR_AUCTION_REQUEST_STATE.isRegisteredCar;
  name = INITIAL_CAR_AUCTION_REQUEST_STATE.name;
  contact = INITIAL_CAR_AUCTION_REQUEST_STATE.contact;
  address = INITIAL_CAR_AUCTION_REQUEST_STATE.address;
  addressDetail = INITIAL_CAR_AUCTION_REQUEST_STATE.addressDetail;
  /** 법정동 코드 */
  legalDongCode = INITIAL_CAR_AUCTION_REQUEST_STATE.legalDongCode;
  carName = INITIAL_CAR_AUCTION_REQUEST_STATE.carName;
  selectedCars = INITIAL_CAR_AUCTION_REQUEST_STATE.selectedCars;
  carNumber = INITIAL_CAR_AUCTION_REQUEST_STATE.carNumber;
  memo = INITIAL_CAR_AUCTION_REQUEST_STATE.memo;
  isAgree = INITIAL_CAR_AUCTION_REQUEST_STATE.isAgree;
  carRegistrationPhoto = INITIAL_CAR_AUCTION_REQUEST_STATE.carRegistrationPhoto;

  constructor() {
    makeAutoObservable(this);
  }

  setObject = undefined;

  init = (params?: { exclusionStateNames: CarAuctionRequestStateName[] }): void => {
    Object.keys(INITIAL_CAR_AUCTION_REQUEST_STATE).forEach((stateName) => {
      /**
       * 초기화 제외할 키 값들이 넘어오면 해당 키 값들은 초기화 하지 않음.
       * ex). 담당자명, 담당자 연락처, 동의 여부, 폼 등록 여부
       */
      if (params?.exclusionStateNames?.includes(stateName as CarAuctionRequestStateName)) {
        return;
      }

      this[stateName] = INITIAL_CAR_AUCTION_REQUEST_STATE[stateName];
    });
  };

  setValue = (data: Partial<CarAuctionStore>) => {
    Object.keys(data).forEach((key) => (this[key] = data[key]));
  };
}

export default CarAuctionStore;
