import axios from 'axios';
import Cookies from 'universal-cookie';

import { fileUploadHost } from '../config.js';
import AuthUtils from '../utils/AuthUtils';
import { setCashPointCookie } from './callApi.js';

async function getJWT(serverCookie) {
  let cookies;

  if (serverCookie) {
    cookies = new Cookies(serverCookie);
  } else {
    cookies = new Cookies();
  }

  return await cookies.get(`${process.env.API_ENV}-imsform-jwt`);
}

const instance = axios.create({
  baseURL: fileUploadHost,
  headers: { ContentType: 'multipart/form-data' },
  timeout: 20000,
});

instance.interceptors.request.use(
  async (config) => {
    const jwt = await getJWT(config.serverCookie);

    if (jwt) {
      config.headers.Authorization = `JWT ${jwt}`;
    }

    if (config.authorization) {
      config.headers.Authorization = config.authorization;
    }

    return config;
  },
  (error) => {
    console.log(error);

    return Promise.reject(error);
  }
);

const setCashAndPointStore = (response) => {
  response.config.stores.authStore.setValue({ key: 'cash', value: response.data.defaultInfo.cash });
  response.config.stores.setValue({ key: 'point', value: response.data.defaultInfo.point });
};

instance.interceptors.response.use(
  (response) => {
    if (response.config.method.toLowerCase() === 'GET'.toLowerCase()) {
      if (
        Boolean(response.data.defaultInfo) &&
        Boolean(response.config.stores.menuStore) &&
        Boolean(response.config.stores.authStore)
      ) {
        setCashPointCookie(response.data.defaultInfo);
        setCashAndPointStore(response);
        response.config.stores.menuStore.setBadge(response.data.defaultInfo.notification_count);
        response.config.stores.authStore.setValue({
          key: 'companyCreatedAt',
          value: response.data.defaultInfo.created_at,
        });

        if (!response.config.stores.authStore.permissionUserType) {
          const authUtils = new AuthUtils({ authStore: response.config.stores.authStore });

          response.config.stores.authStore.setValue({
            key: 'permissionUserType',
            value: authUtils.getUserType(response.config.stores.authStore),
          });
        }
      }
    }

    return response.data;
  },
  (error) => {
    return error.response;
  }
);

export default instance;
