import React from 'react';

import Button from '../../../atoms/Button/index.tsx';
import styles from './DeliveredTimeChangeModal.module.scss';

const DeliveredTimeChangeModal = (props) => {
  return (
    <div className={styles.normal_car_change_info}>
      {props.option === 'rately' ? (
        <>
          <p>차량 배차 시간이 고객 예약 대여시간보다 늦어지면,</p>
          <p>반납 시간이 자동 연장됩니다.</p>
        </>
      ) : (
        <>
          <p>고객 예약 대여시간 보다 30분 이상 빠른 시간입니다.</p>
          <p>조기 배차를 진행하셔도 반납 시간은 고객 예약반납</p>
          <p>시간과 동일합니다.</p>
        </>
      )}

      <div className={styles.btn_area}>
        <Button btnText="확인" btnClick={props.success} />
      </div>
    </div>
  );
};

export default DeliveredTimeChangeModal;
