import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

// import callApi from '../../../helpers/callApi';
import apiIS from '../../../api/normalContract/installmentSchedule';
import CommonRowTable from '../../../components/CommonRowTable2';
import { getByteLength } from '../../../helpers/byteUtils';
import errorHandler from '../../../helpers/errorHandler';
import styles from './TerminateModal.module.scss';
@inject('loadingStore')
@inject('modalStore')
class TerminateModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startInstallmentNo: null,
      memo: null,
    };
  }

  submit = () => {
    if (this.props.state === 'using_car') return alert('반납완료 이후 중도해지 가능합니다.');

    if (this.props.isDisabled) {
      this.cancel();
    } else {
      this.terminate();
    }
  };

  terminate = async () => {
    catchAsync(
      async () => {
        const { id } = this.props;
        const { startInstallmentNo, memo } = this.state;

        if (!startInstallmentNo) return alert('납부회차를 선택해주세요');

        if (memo && getByteLength(memo) > 120)
          return alert('해지사유는 120byte를 넘을수 없습니다.');

        const data = {
          start_installment_no: startInstallmentNo,
          memo: memo ? `${memo}`.trim() : '',
        };
        const params = {
          ...data,
          id,
        };

        this.props.loadingStore.on();
        const response = await apiIS.terminateSchedule(params);
        const responseData = response.data;

        if (response.status == 200) {
          if (responseData.success === true) {
            alert('중도해지가 완료되었습니다.');
            this.props.refresh();
            this.props.modalStore.modalClose();
          }
        }
      },
      () => this.props.loadingStore.off()
    );
  };

  cancel = async () => {
    catchAsync(
      async () => {
        this.props.loadingStore.on();
        const { id } = this.props;
        const { memo } = this.state;

        if (memo && getByteLength(memo) > 120)
          return alert('해지 취소 사유는 120byte를 넘을수 없습니다.');

        const data = {
          memo: memo ? `${memo}`.trim() : '',
        };

        const params = {
          memo: data.memo,
          id,
        };

        const response = await apiIS.undoTerminateSchedule(params);
        const responseData = response.data;

        if (response.status == 200) {
          if (responseData.success === true) {
            alert('중도 해지 취소가 완료되었습니다.');
            this.props.refresh();
            this.props.modalStore.modalClose();
          }
        }
      },
      () => this.props.loadingStore.off()
    );
  };

  getAllPaymentTimes = () => {
    const { schedule } = this.props.data;

    //obserble array to normal array
    return schedule.slice().map((item) => {
      return { label: `${item.installment_no}회차`, value: item.installment_no };
    });
  };

  getTableInputData = () => {
    const allPaymentTimes = this.getAllPaymentTimes();
    const { isDisabled } = this.props;
    const tableData = [
      [
        {
          necessary: true,
          key: 'startInstallmentNo',
          label: '납부 회차',
          type: 'select',
          select: {
            data: allPaymentTimes,
            arrow: true,
            style: { width: 95 },
          },
          addText: '중도해지 (선택 회차까지 정산)',
        },
      ],
      [
        {
          key: 'memo',
          label: isDisabled ? '해지 취소 사유' : '해지 사유',
          type: 'textarea',
          subLabel: '계약서 메모에 공유됩니다.',
          maxByte: 120,
          placeholder: isDisabled
            ? '예)차량번호 혼동으로 잘못 해지함'
            : '예)고객이 차량에 대한 불만으로 중도해지 요청',
          style: { width: '100%', height: 120 },
        },
      ],
    ];

    if (this.props.isDisabled) {
      return [tableData[1]];
    }

    return tableData;
  };

  render() {
    const { isDisabled } = this.props;
    const { startInstallmentNo, memo } = this.state;
    const { rent_car_name, rent_car_number, customer_name } = this.props.data;

    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content_header}>
            <div>{rent_car_number}</div>
            <div className={styles.right_pillar} />
            <div>{rent_car_name}</div>
            <div className={styles.right_pillar} />
            <div>{customer_name}</div>
          </div>
          <div className={styles.content_body}>
            <CommonRowTable
              labelStyle={{ minWidth: '170px' }}
              viewData={this.getTableInputData()}
              stateData={{
                startInstallmentNo,
                memo,
              }}
              setData={(key, value) => {
                this.setState({
                  [key]: value,
                });
              }}
            />
            {isDisabled && (
              <div className={styles.cancel_terminate_desc}>
                <span>
                  {
                    '* 중도해지를 취소하실 경우, 중도해지 이후의 입금 및 환불 내역이 자동으로 취소되지 않습니다.'
                  }
                </span>
                <span style={{ marginLeft: 10 }}>{'꼭 입금 내역을 확인해주세요.'}</span>
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.cancel} onClick={this.props.modalStore.modalClose}>
            {MESSAGE.CANCEL}
          </div>
          <div className={styles.submit} onClick={this.submit}>
            {MESSAGE.CONFIRM}
          </div>
        </div>
      </div>
    );
  }
}
export default TerminateModal;
