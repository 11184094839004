export const PASSWORD_KOR = '비밀번호';
export const CONFIRM_PASSWORD = '비밀번호 확인';

export const NUM_ENG_UNION_20CAHR = '숫자영문조합 최대 20자';
export const EXCLUDE_HYPHEN_ONLY_NUM = '"-"를 제외한 숫자만 입력';
export const ENGINE_TYPE_ONLY_NUM = '배기량 숫자만 입력';
export const CAR_OLD_ONLY_NUM = '연식 숫자만 입력';

export const EXAMPLE_NAME = '예) 홍길동';
export const EXAMPLE_COMPANY_NAME = '예) ○○렌터카';
export const EXAMPLE_PHONE = '예) 01012345678';
export const EXAMPLE_JOB_GRADE = '예) 대리';
export const EXAMPLE_JOB_TITLE = '예) 직함';
export const EXAMPLE_NICKNAME = '예) 김렌카';
export const EXAMPLE_EMAIL = '예) abc@xxx.com';
export const EXAMPLE_DATE = '예) 20201231';
export const EXAMPLE_CAR_NUMBER = '예) 서울01가1234, 01가1234';
export const EXAMPLE_CAR = '예) 아우디S8';
export const EXAMPLE_ENGINE_TYPE = '예) 5000';
export const EXAMPLE_DATE_MONTH = '예) 201701';
export const EXAMPLE_ESPECIAL_POINT = '예) 고객 성향 까칠함. 주유비 민감함.';
export const EXAMPLE_INSURANCE_TRANS_POINT = '예) 렌카에서 50% 할인된 청구 금액입니다.';
export const EXAMPLE_ETC_CONTRACT_POINT = '예) 계약자 외 운행금지.';
export const EXAMPLE_DRIVE_DISTANCE_KM = '예) 10000';
export const EXAMPLE_RESIDENT_NUMBER = '예)880101';
export const EXAMPLE_DOMESTIC_CAR_COUNT = '예) 30';
export const EXAMPLE_IMPORTED_CAR_COUNT = '예) 10';
export const EXAMPLE_COMPANY_DESCRIPTION = '예) 업체소개를 자유롭게 해보세요!';
export const EXAMPLE_ID = '예) abc1234';

export const PLACEHOLDER_CAR_NAME = '아우디S8';
export const PLACEHOLDER_CAR_NUMBER = '예) 12가1234, 서울01가1234';
export const PLACEHOLDER_ENGINE_TYPE_CC = '5000';
export const PLACEHOLDER_DATE = '20170101';
export const PLACEHOLDER_INPUT_STANDARD_FEE = '기준가를 입력해주세요.';
export const PLACEHOLDER_ONLY_NUMBER = '숫자만 입력 (예 50000)';

export const PLACEHOLDER_ID = '아이디 (영문 혹은 숫자, 4자 이상)';
export const PLACEHOLDER_PASSWORD = '비밀번호 (영문+숫자, 8자 이상)';
export const PLACEHOLDER_RESETTING_PASSWORD = '비밀번호 재설정 (영문+숫자, 8자 이상)';
export const PLACEHOLDER_REALNAME = '이름 (실명, 최대 20자)';
export const PLACEHOLDER_PHONE = '연락처 (숫자만 입력)';
export const PLACEHOLDER_JOB_TITLE = '직함 (최대 20자)';
export const PLACEHOLDER_BIRTHDAY = '생년월일 (숫자 6자리, 예) 801230)';
export const PLACEHOLDER_CONTRACT = '"-" 없이 숫자만 입력해주세요.';

export const INPUT_SEARCH_DATA = '검색 내용을 입력해주세요.';

export const COUNT_DOMESTIC_CAR = '국산차량 대수';
export const COUNT_IMPORTED_CAR = '수입차량 대수';

//계정
export const PHONE_NUMBER = '휴대폰 번호';

//요청상세
export const ENGINE_TYPE_FIRST_INSU_BOTH_CHECK = '배기량/연식 확인이 필요합니다.';
export const ENGINE_TYPE_CC_CHECK = '배기량 확인이 필요합니다.';
export const FIRST_INSU_CHECK = '연식 확인이 필요합니다.';

//인증
export const USER_PHONE_NUMBER = '휴대폰번호';
export const CERTIFY_NUMBER = '인증번호';

//파일 첨부 안내문구
export const FILE_UPLOAD_TEXT = '해당 영역에 파일을 ‘드래그’하거나 ‘클릭’하여 파일을 첨부해주세요.';
