import React, { useEffect, useState } from 'react';
import useStores from 'stores/UseStores';

import { EtcContractItem, getEtcContractList } from '../../../api/etcContract/getEtcContractList';
import RegisterContractModal from '../../../pages/businessManagement/setting/contract/RegisterContractModal';
import Table from '../../Table';
import * as S from './ContractModal.style';

const EtcContractListModal = (props: {
  selectContract: (data: string) => void;
  getList: () => void;
}) => {
  const { modalStore, loadingStore } = useStores();
  const [selectedId, setSelectedId] = useState<number>();
  const [contractList, setContractList] = useState<EtcContractItem[]>([]);

  useEffect(() => {
    getContractList();
  }, []);

  const getContractList = async () => {
    loadingStore.on();

    try {
      const response = await getEtcContractList();

      if (response.status === 200) {
        const responseData = response.data;

        setContractList(responseData.other_contract_details);
      }
    } catch (error) {
      console.info(error);
    } finally {
      loadingStore.off();
    }
  };

  const openRegisterModal = (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    event.stopPropagation();
    const modal = <RegisterContractModal refresh={props.getList} isListModalAgain />;
    const data = {
      modalComponent: modal,
      modalTitle: '기타 계약사항 등록',
      modalStyle: { width: 720 },
    };

    modalStore.modalOpen(data);
  };

  const selectCheck = (item: EtcContractItem) => {
    if (selectedId === item.id) {
      return setSelectedId(null);
    }

    setSelectedId(item.id);
    props.selectContract(item.content);
  };

  const tbodyData = () => {
    if (contractList.length === 0) {
      return null;
    }

    return contractList.map((item, i) => {
      return (
        <tr
          key={i}
          style={{ cursor: 'pointer' }}
          onClick={() => {
            selectCheck(item);
          }}
        >
          <td>{item.title}</td>
        </tr>
      );
    });
  };

  return (
    <S.Wrapper>
      <S.Header>
        <S.NoticeWrap>
          <img src="/images/img_bulb_60x96.png" width={18} height={28} />
          <S.Notice>
            등록된 기타 계약사항 관리는 <b>사업자 관리 &gt; 사업자 설정 &gt; 기타 계약사항 목록</b>
            에서
            <br />
            하실 수 있으며, 등록은 <b>최대 20개</b>까지 가능합니다.
          </S.Notice>
        </S.NoticeWrap>
        {contractList.length < 20 && (
          <S.AddContractButton onClick={openRegisterModal}>
            기타 계약사항 등록하기
          </S.AddContractButton>
        )}
      </S.Header>
      <S.TableWrap>
        <Table
          headFix
          theadWidth={[100]}
          theadData={['제목']}
          tbodyData={tbodyData()}
          emptyTable={{
            text: '등록된 기타 계약사항이 없습니다.',
            style: { fontSize: '13px' },
          }}
        />
      </S.TableWrap>

      <S.Footer>
        <S.CloseButton onClick={modalStore.modalClose}>닫기</S.CloseButton>
      </S.Footer>
    </S.Wrapper>
  );
};

export default EtcContractListModal;
