import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import styles from './TopMenuStyle.module.scss';

@inject('authStore')
@observer
class TopDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: false,
      width: 0,
      height: 0,
    };
  }

  componentDidMount() {
    if (this.state.width) {
      this.updateDimensions();
    }

    document.addEventListener('mouseup', this.handleClickOutside);
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    document.removeEventListener('mouseup', this.handleClickOutside);
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({
      width: Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      height: Math.max(document.documentElement.clientHeight, window.innerHeight || 0),
    });
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ dropdown: false });
    }
  };

  rightMenuStyle = () => {
    if (this.state.width < 1100) {
      return `${1120 - this.state.width}px`;
    }
  };
  selectDropdownItem = (callback) => {
    this.setState(
      {
        dropdown: false,
      },
      () => {
        callback();
      }
    );
  };
  logout = () => {
    this.props.authStore.logout();
    this.props.router.push('/');
  };

  showTopName = () => {
    const realname = this.props.authStore.realname;

    if (realname) {
      if (realname.length > 4) {
        return `${realname.substring(0, 4)}..`;
      }

      return realname;
    }

    return '';
  };
  render() {
    const right_menu = [
      {
        key: 'my_info',
        func: () => this.selectDropdownItem(() => this.props.router.push('/myInfo')),
        text: '내 정보',
      },
      {
        key: 'logout',
        func: () => this.selectDropdownItem(this.logout),
        text: '로그아웃',
      },
    ];

    if (this.props.authStore.logged) {
      return (
        <div style={{ position: 'relative', marginLeft: 20 }} ref={this.setWrapperRef}>
          <div
            className={styles.right_menu}
            style={{ padding: '0 10px' }}
            onClick={() =>
              this.setState({ dropdown: !this.state.dropdown }, () => {
                if (this.state.dropdown === true) {
                  document.getElementById('dropdown').focus();
                }
              })
            }
          >
            <div className={`${styles.right_menu_button} ${styles.set_hover_underline}`}>
              {this.showTopName()}
              <span>님</span>
            </div>
          </div>
          {Boolean(this.state.dropdown) && (
            <div className={styles.top_menu_dropdown_area}>
              <div className={styles.top_menu_dropdown_menu}>
                <div className={styles.top_menu_dropdown_list}>
                  <div className={styles.top_menu_dropdown_triangle} id="dropdown" />
                  {right_menu.map((item, i) => {
                    return (
                      <div
                        key={i}
                        className={styles.top_menu_dropdown_list_item_row}
                        onClick={item.func}
                      >
                        <span className={styles.top_menu_dropdown_list_item_text}>{item.text}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}

export default withRouter(TopDropDown);
