import { makeAutoObservable } from 'mobx';

import apiGroup from '../api/rentCompanyGroup';
import AuthStore from './AuthStore';
import { _setObject } from './BaseStore.type';
type Branch = {
  company: string;
  id: number;
};
class BranchStore {
  prefetchSuccess = false;
  group_leader = false;
  has_group = false;
  share_car_data = false;
  group_name: string;
  group_joined = false;
  leader = false;
  branchList = [] as Branch[];
  authStore: AuthStore;

  setObject = (data: Partial<BranchStore>) => _setObject(this, data);

  constructor(authStore: AuthStore) {
    this.prefetchSuccess = false;
    this.authStore = authStore;
    makeAutoObservable(this);
  }
  prefetch = async (jwt?: string): Promise<any> => {
    if (jwt) {
      const response = await apiGroup.getGroupUsers({
        jwt,
        disableErrorHandler: true,
        only_joined: 1,
      });
      const response2 = await apiGroup.getGroupLeader({ jwt, disableErrorHandler: true });

      if (response.status === 200 && response2.status === 200) {
        // get 요청이 없는 페이지에서 처음 렌더링 시 authstore값을 사용하기 위함
        this.authStore.setValue({
          key: 'companyCreatedAt',
          value: response2.data.defaultInfo.created_at,
        });

        return this.setObject({
          prefetchSuccess: true,
          group_name: response.data.group_name,
          has_group: response.data.has_group,
          share_car_data: response.data.share_car_data,
          branchList: response.data.list,
          group_joined: response2.data.group_info.group_joined,
          leader: response2.data.group_info.leader,
          group_leader: response2.data.defaultInfo.group_leader,
        });
      }

      console.info('error');

      return this.setObject({
        prefetchSuccess: true,
        group_name: null,
        has_group: false,
        group_joined: false,
        group_leader: false,
        share_car_data: false,
        branchList: [],
        leader: false,
      });
    }
  };
}

export default BranchStore;
