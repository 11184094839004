export const INTRO_MOBILE_CONTRACT_CONTENT1 = '보험대차 뿐만 아니라 일반렌트까지 현장에서';
export const INTRO_MOBILE_CONTRACT_CONTENT2 = '간편하고 빠르게  계약서를 작성할 수 있고,';
export const INTRO_MOBILE_CONTRACT_CONTENT3 = '고객에게 미리 비대면 계약서를 보내 기다림 없이';
export const INTRO_MOBILE_CONTRACT_CONTENT4 = '빠른 배차가 가능합니다.';
export const INTRO_CAR_LICENSE_CONTANT1 = '면허증 촬영으로 간편하게 진위 여부를 조회하여';
export const INTRO_CAR_LICENSE_CONTANT2 = '불법 면허증을 가려낼 수 있습니다.';
export const INTRO_CAR_OLD_CONTANT1 = '차량번호만 입력하면 차량명, 배기량, 연식을';
export const INTRO_CAR_OLD_CONTANT2 = '빠르고 정확하게 확인 가능하여';
export const INTRO_CAR_OLD_CONTANT3 = '연식초과로 인한 손해를 줄일 수 있습니다.';
export const INTRO_CLAIM_SYSTEM_CONTACT1 = '복잡하고 번거로웠던 청구 업무를';
export const INTRO_CLAIM_SYSTEM_CONTACT2 = '버튼 하나로 3초 안에 완료할 수 있습니다.';

export const NOT_FOUND_RESULT = '조회 결과가 존재하지 않습니다.';
export const NOT_FOUND_CONTRACT_SEARCHED_CAR_NUMBER =
  '해당 차량번호로 조회된 계약서가 존재하지 않습니다.';
export const NOT_FOUND_CONNECT_CONTRACT = '연결할 계약서가 존재하지 않습니다.';
export const NOT_FOUND_SEARCH_HISTORY = '조회이력이 없습니다.';
export const NOT_FOUND_HISTORY = '이력이 없습니다.';

export const SUGGEST_TO_CUSTOMER = '고객에게 제안하세요.';
export const REVISE_SUGGETION = '제안을 수정하세요.';
export const REGISTER_INSURANCE_TRANS_POINT =
  '보험사에 전달할 내용이 있다면 수정을 통해 등록해 주세요. 청구시 담당자에게 전달됩니다.';
export const SELECT_TO_CHANGE_CAR_REGISTRATION = '*차량등록증을 변경 하고자 할 시에 선택하세요.';
export const CAR_REGISTRATION_UNDER_5MB = '*차량등록증은 5MB 이하 jpg/jpeg/png/pdf를 선택하세요.';
export const SELECT_TO_CHANGE_MANAGE_STEAD_CONTRACT =
  '*관리위탁 계약서를 변경 하고자 할 시에 선택하세요.';
export const MANAGE_STEAD_CONTRACT_UNDER_5MB =
  '*관리위탁 계약서는 5MB 이하 jpg/jpeg/png/pdf를 선택하세요.';
export const CAN_CLAIM_UPLOAD_REGISTERATION = '*등록증을 업로드 해야 청구가 가능 합니다';
export const CAN_CALIM_UPLOAD_MANAGE_STEAD_CONTRACT =
  '*해당 차량이 관리위탁 차량일 경우 관리위탁 계약서를 업로드 해야 정상적인 청구가 진행됩니다.';
export const CAN_CHANGE_COMPANY_FEE = '업체 요금표 기준가로 변경이 가능합니다.';
export const TRANS_COMPANY_FEE_REVISE_STANDARD_FEE =
  '기준가 변경 요금표를 사업자등록 수정에서 업로드 해야 해당 요금표가 전송 됩니다.';
export const INPUT_COST_INCLUDE_DELIBERY_SELF_CAR_INSURANCE =
  '* 딜리버리와 자차 보험을 포함한 금액을 입력해주세요.';
export const CORRESPOND_ID_WITH_INFO = '입력하신 정보와 일치하는 아이디입니다.';
export const LOGIN_UNDER_ID = '아래 아이디 중에서 로그인 해주세요.';
export const CONFIRM_NAME_OR_PHONE_NUMBER = '이름 또는 휴대폰번호를 확인해주세요.';
export const CONFIRM_INPUT_INFO = '입력하신 정보를 확인해주세요.';
export const AGREE_ALL_TERM = '전체 약관에 동의합니다.';
export const AGREE_ESSENTIAL_USE_TERM = '[필수] 이용 약관에 동의합니다.';
export const AGREE_ESSENTIAL_PERSONAL_INFO_TERM = '[필수] 개인정보 수집∙이용에 동의합니다.';
export const AGREE_ESSENTIAL_LOCATION_SERVICE_TERM = '[필수] 위치기반서비스 이용약관에 동의합니다.';
export const CAR_ORIGIN_ETC_INFO =
  '* 기타 항목에는 오토바이, 트레일러, 캠핑카, 보트 등이 포함됩니다.';
export const CAR_ORIGIN_PLEASE_INPUT = '하단 ‘수정하기’ 버튼을 통해 차량 구분을 선택해주세요.';

export const TERMS_OF_SERVICE = '서비스 이용약관';
export const PRIVACY = '개인정보 취급 방침';
export const LOCATION_SERVICE = '위치기반 서비스';
export const LOCATION_SERVICE_VALIDATION = `${LOCATION_SERVICE}에 동의해 주세요.`;
export const TERMS_OF_SERVICE_VALIDATION = `${TERMS_OF_SERVICE}에 동의해 주세요.`;
export const PRIVACY_VALIDATION = `${PRIVACY}에 동의해 주세요.`;

// title
export const INPUT_EMPLOYEE_INFO_ESSENTIAL = '직원 정보 입력(필수)';
export const SETTING_EMPLOYEE_AUTHORITY_ESSENTIAL = '직원 권한 설정 (필수)';
export const EMPLOYEE_DRIVE_LICENSE_INFO = '직원 면허 정보 (선택)';
export const CAR_CONNECT_FAIL = 'IMS.connect 연결 실패';
export const CAR_EVALUATION_TITLE = '국내 최초 고객 운전 습관 평가';
export const CAR_CONTROL_TITLE = 'IMS.form에서 바로 시동 · 도어 제어';
export const CAR_CALCULATE_TITLE = '복잡한 계산 없이 최종 유류비 자동 정산';
export const CONNECT_LOGIN_TITLE = 'IMS.connect 연동하기';

export const REVIEW_EXISTENCE = `
이미 작성 완료된 리뷰입니다.
이용해 주셔서 감사합니다.
`;

// 업무일지 페이지
export const COMPANY_HISTORY_CONTENT_EXPLANATION =
  'IMS form을 사용하는 임직원의 당일 활동 내용이 자동 입력됩니다.';
export const SEARCH_HISTORY_ROOT = '조회 경로';
export const SEARCH_HISTORY_TIME = '조회 시간';

// 차량 등록 페이지
export const CAR_STATUS_EXCEL_EXPLANATION = '여러대의 차량을 한번에 관리 등록하세요.';
export const CAR_STATUS_EXCEL_DETAIL_EXPLANATION =
  '신규 차량 등록 및 기존 등록된 차량 정보 수정 업데이트가 가능합니다.';

// 사업자 설정 / 약관관리 페이지
export const BUSSINESS_MANAGEMENT_TERM_CONTENT_EXPLANTION =
  '사업자의 신고된 약관을 꼭 등록해 주세요. 약관 미등록 시 고객과의 분쟁이 발생될 수 있습니다.';
export const CAR_STANDARD_CONTRACT_TERMS = '자동차대여약관 불러오기';

// 카카오 차량 제안
export const RENT_CAR_NOTICE_KAKAO_INVEN =
  '모델 및 연식이 요청 차량과 동일한 차량을 제안하시면 매칭될 확률이 높아집니다.';
// inventory완료
export const RENT_CAR_NOTICE_KAKAO =
  '일반렌트는 제안시 실제 배차 가능한 차량으로 제안을 진행하셔야 하며, 예약확정 후 배차포기 시 페널티가 발생 할 수 있으므로 신중하게 제안을 넣어주세요.';
export const NOTICE_PROPOSAL = '고객이 결제를 진행중인 경우 제안 수정 및 취소가 불가합니다.';
// inventory완료
export const NOTICE_PROPOSAL_INVEN = '결제 대기 상태입니다. 고객 결제 완료 시 예약이 확정됩니다.';
export const PLEASE_SELECT_CAR = '제안 차량을 선택해주세요.';
export const NORMAL_REQUEST_NOTICE_SELECTED_1_PART1 =
  '배차전 고객통화 및 배차 출발 버튼을 꼭 눌러주세요.';
export const NORMAL_REQUEST_NOTICE_SELECTED_1_PART2 =
  '비대면 계약서를 전송하시면 배차 시 시간을 절약 하실 수 있습니다.';
export const CHECK_NOTICE_MSG = '알림메세지를 확인해주세요.';
export const CHECK_NOTICE_MSG_SUB = '차량 또는 고객님과 이슈가 생기면 운영팀에 꼭 연락해주세요.';
export const NORMAL_REQUEST_CONTACT_US_MSG_PART1 = '문의사항이 있으시면';
export const NORMAL_REQUEST_CONTACT_US_MSG_PART2 = '어드민 운영팀에 채팅으로 문의하세요.';
export const NO_SHOW_REPORT_PLEASE = '고객님께서 연락이 안되고 배차 장소에 오지 않으셨나요?';
export const NO_SHOW_REPOTED_INFO =
  '고객이 연락두절로 20분 정도 약속 장소에 기다렸지만 배차 할 수 없어 신고합니다.';
export const CAR_SELECT_REJECTED_INFO_PART1 =
  '변경 차량을 고객님께서 거절하였습니다. 운영팀에서 연락 드리겠습니다.';
export const CAR_SELECT_REJECTED_INFO_PART2 =
  '혹시 최초 예약차량과 동일차종이 있다면 지금 차량을 변경해주세요.';
export const NORMAL_REQUEST_NOTICE_SELECTED_1 =
  '배차전 고객통화 및 배차 출발 버튼을 꼭 눌러주세요.';
export const NORMAL_REQUEST_NOTICE_SELECTED_2 =
  '비대면 계약서를 전송하시면 배차 시 시간을 절약 하실 수 있습니다.';
export const NORMAL_REQUEST_PROPOSAL_AGREE =
  '보험 및 주의사항을 모두 확인하였으며 법적 책임에 대해 동의합니다.';
// export const NORMAL_REQUEST_KAKAO_EDIT_PROPOSAL_NOTICE = "제안 차량은 카카오콜 일반렌트 보험 범위를 모두 보장하며, 배차 후 사고 발생 시 자차 면책금과 휴차료 외의 금액을 고객에게 지불 요청할 수 없습니다.";

// 렌트요청 콜>예약확정 페이지
export const RENT_REQUEST_CALL_SELECTED_LIST_CAUTION = (_time) =>
  `* 실제 배차 후 ${_time}시간 이내에 계약서를 작성해주세요. 미작성시 페널티로 인해 보험콜 제안이 제한됩니다.`;

export const GUARANTEED_CLAIM_COST_NOTICE = '청구요금을 직접 입력해 주세요.';

// 거래처 관리> 청구처
export const INPUT_CLAIMEE_NAME = '청구처명을 입력해 주세요.';
export const INPUT_FAX = '팩스번호를 입력해 주세요.';

// 상세 계약서
export const CONTRACT_GUARANTEED_CHANGE_CAUTION_ABOUT_PRE_CLAIM = `선청구 준비 이후 대차 구분을 변경하실 수 없습니다.\n'선청구 준비 취소' 후 다시 시도해 주세요.`;

// 툴팁 메시지
export const DELETE_CAR_TOOLTIP =
  '정보를 잘못 입력하셨을 경우에만 차량 삭제를 진행해 주시고,\n차량 상태 변경(매각/폐차)일 경우 [매각], [폐차] 버튼을 눌러주세요.';
export const UNREGISTERED_CAR_TOOLTIP =
  '차량 등록증을 등록하지 않으신 경우, 보험사 청구가 불가능합니다. 원활한 청구를 위해 꼭 등록 후 배차를 진행해주세요.';

// 차량 관리 페이지
export const CONNECT_FAIL_CONTENT = `통신 음영 지역 또는 통신이 불안정할 경우 연결이 지연될 수 있습니다.\n잠시 후 다시 시도해 주세요.`;
export const DISCONNECT_CONTENT = `* OBD 단말기를 탈거하셨다면\n  연동 해제 버튼으로 해당 차량 IMS.connect 연동을 해제해주세요.`;
export const CONNECT_FAIL_NOTICE =
  '연결 실패가 지속될 경우 1600-8348 전화 연결 후 3번을 눌러 문의해주세요.';
export const OBD_CONNECT = 'OBD 결합으로\n한 번 더 진화한 IMS.form';
export const CAR_EVALUATION_CONTENT =
  '계약서 작성 전 고객의 지난 운전 습관 평가 점수를 확인하고\n사고 위험 고객의 계약을 방지할 수 있습니다.\n반납 후엔 대여기간 동안 고객의 운전 습관을 확인할 수 있습니다.';
export const CAR_CONTROL_CONTENT =
  '별도의 차량관제 App 설치 없이\nIMS.form에서 바로 차량을 제어할 수 있습니다.\n위험 상황 혹은 계약서 작성 시 차량을 제어해보세요.';
export const CAR_CALCULATE_CONTENT =
  '고객이 실제로 사용한 유류량을 OBD로 체크하여 반납 시\n최종 유류비를 자동으로 정산해주며, 전국 평균 기준가로\n투명한 유류비 정산 체계를 만들어 나갑니다.';

// 차량 관제센터
export const CONNECT_LOGIN_CONTENT =
  '이미 설치하셨다면 사용중이신 카봄 계정으로 로그인 해주세요.\n로그인 후 IMS.form과 IMS.connect가 자동으로 연동됩니다.';
export const ID_PASSWORD_SEARCH =
  '계정을 잊으셨다면 카봄 홈페이지에서\n아이디/비밀번호 찾기를 진행해주세요.';
export const CARBOM_HOMEPAGE_LINK_TEXT = '카봄 홈페이지 바로가기';
export const CUSTOMER_CENTER_TEXT = 'IMS.connect 가입 · 설치 및 계정 연동 문의 1600-8348';

// 차량 반납정보 설정 모달
export const CONNECT_DESCRIPTION_TITLE = 'IMS.connect를 연동하시면?';
export const CONNECT_DESCRIPTION1 = '1. 배차/반납 시 유류량과 주행거리가 자동으로 입력됩니다.';
export const CONNECT_DESCRIPTION2 =
  '2. 반납 시 최종 사용 유류비가 자동으로 정산됩니다. (카카오 콜건, IMS 콜건, 전기차, 수소차 제외)';
export const CONNECT_DESCRIPTION_NOTICE =
  '* IMS.connect 연동 안내는 차량 현황 > 차량 관제센터 페이지에서 확인해주세요.';
export const OIL_COST_FOOTNOTE =
  'OBD 데이터가 실제와 상이할 수 있으며 시동을 껐다 켜주시면 더욱 정확한 유류량/주행거리 데이터를 불러올 수 있습니다.';

// 계약서
export const CONNECT_DESCRIPTION =
  '배차/반납 시 유류량과 주행거리가 자동으로 입력되며, 반납 시 최종 사용 유류비가 자동으로 정산됩니다. (카카오 콜건, IMS콜 건, 전기차, 수소차 제외)';
export const OIL_DISTANCE_TIP = '유류량/주행거리 입력 Tip';
export const OIL_DISTANCE_REFERENCE =
  '현재 유류량/주행거리 데이터를 참고해 입력해주세요. (통신 오류 시 데이터가 표시되지 않을 수 있습니다.)';

// 지점 변경 모달
export const BRANCH_CHANGE_NOTICE =
  '* IMS.connect 연동 차량을 지점 변경하실 경우, 동기화를 위해\n  카봄 페이지에서도 꼭 지점을 변경해주세요.';

// 자동제안 관리
export const KAKAO_RENTCAR_POLICY_0_TITLE = '차량 제안 중지 안내';
export const KAKAO_RENTCAR_POLICY_0_0 =
  '변경된 카카오T 렌터카 정책에 동의하지 않아 차량 제안이 중지되었습니다.';
export const KAKAO_RENTCAR_POLICY_0_1 = '정책 동의 후 카카오 요청에 제안하실 수 있습니다.';
export const KAKAO_RENTCAR_POLICY_EXPIRE_NOTICE_TITLE = '차량 노출 중지 예정 안내';
export const KAKAO_RENTCAR_POLICY_EXPIRE_NOTICE = (date) =>
  `카카오T 렌터카 정책이 ${date} 기준으로 변경될 예정입니다. 변경된 정책에 동의하지 않으면 차량 제안이 중지되오니 정책 확인 및 동의해주세요.`;
