export default function corporationNumCheck(corNum) {
  let value;

  value = `${corNum[0]}${corNum[1]}`.trim();
  const checkID = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
  let sum = 0;

  if (value.length !== 13) {
    return false;
  }

  for (let i = 0; i < 12; i++) {
    sum += checkID[i] * value.charAt(i);
  }

  if ((10 - (sum % 10)) % 10 != value.charAt(12)) {
    return false;
  }

  return true;
}
