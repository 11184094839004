import useControlled from 'hooks/useControlled';
import { MouseEvent } from 'react';

import { CheckBoxProps } from './CheckBox.type';

type Props = {
  checked: CheckBoxProps['checked'];
  defaultChecked: CheckBoxProps['defaultChecked'];
  onClick: CheckBoxProps['onClick'];
};

export const useCheckBox = ({ checked, defaultChecked, onClick }: Props) => {
  /**
   * checked가 전달이 안됐을 경우에는 setIsChecked로 자체적으로 true, false 관리
   * checked가 전달 됐을 경우에는 setIsChecked는 동작시 영향이 없으며 전달 받은 onClick으로 제어됨.
   */
  const [isChecked, setIsChecked] = useControlled({
    controlled: checked,
    defaultValue: defaultChecked ?? false,
  });

  const handleClick = (event: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => {
    setIsChecked(!isChecked);

    if (onClick) {
      onClick(event);
    }
  };

  return {
    isChecked,
    handleClick,
  };
};
