import find from 'lodash/find';
import { observer } from 'mobx-react-lite';
import React from 'react';

import Button from '../../../atoms/Button/index.tsx';
import Radio from '../../../atoms/Radio/index.tsx';
import Select from '../../../atoms/Select/index.tsx';
import RencarCheckBox from '../../RencarCheckBox/RencarCheckBox';
import * as modules from '../Modules';
import styles from './RadioType.module.scss';

const radioSelectDisabled = (props) => {
  const { info, setData, stateData } = props;

  const value = stateData[info.key];
  const findValue = find(info.radio.data, { value });
  const checkNumberValue = !isNaN(Number(value)) ? Number(value) : value;

  if (props.info?.select?.disabled) {
    return true;
  }

  if (!stateData.additional && find(info.select.data, { value: checkNumberValue })) {
    return false;
  }

  if (!stateData.additional && findValue) {
    if (find(info.select.data, { match: findValue.match })) {
      return false;
    }
  }

  return true;
};

const radioSelectValue = (props) => {
  const { info, setData, stateData } = props;
  const value = stateData[info.key];
  const checkNumberValue = !isNaN(Number(value)) ? Number(value) : value;

  if (find(info.select.data, { value: checkNumberValue })) {
    return checkNumberValue;
  }
};

const RadioType = (props) => {
  const { info, setData, stateData } = props;
  const { btn, checkBox, style } = props.info;

  let selectRadioProps;

  if (info.select) {
    selectRadioProps = {
      arrow: true,
      common: true,
      selectData: info.select.data || [],
      wrapStyle: styles.select,
      dataObj: stateData,
      selectKey: info.key,
      onChange: (value) => setData(info.key, value),
      disabled: radioSelectDisabled(props),
      value: radioSelectValue(props),
      inlineStyle: info.select.style,
    };
  }

  return (
    <div
      style={style}
      className={`${styles.radio_type_wrap} ${info.radio?.isDivideData ? '' : styles.wrap_flex}`}
    >
      <Radio
        isDivideData={Boolean(info.radio?.isDivideData)}
        dividedPoint={info.radio?.dividedPoint}
        keyName={info.key}
        data={info.radio.data}
        dataObj={stateData}
        matchData={(info.select && info.select.data) || null}
        radioChange={(value) => {
          if (info.key === 'self_car_insurance') {
            modules.selfCarInsurance(setData, stateData);
          }

          if (info.radio.func) {
            info.radio.func();
          }

          if (info.key === 'insurance_company' && stateData.registration_id) {
            setData('registration_id', null);
          }

          if (info.describeOptions?.length > 0) {
            info.describeText = null;
            info.describeOptions.some(({ value: targetValue, describeText }) => {
              if (value !== targetValue) {
                return false;
              }

              info.describeText = describeText;

              return true;
            });
          }

          setData(info.key, value);
        }}
        radioWrapStyle={info.radio.style}
        disabled={info.radio.disabled}
        selectRadioProps={selectRadioProps && { ...selectRadioProps }}
      />
      {info.select && !info.select.isInRadioComponent && <Select {...selectRadioProps} />}

      {info.text && <p>{info.text}</p>}

      {btn && btn.func && (
        <Button btnText={btn.name} inlineStyle={btn.style ? btn.style : {}} btnClick={btn.func} />
      )}
      {checkBox && (
        <RencarCheckBox
          text={checkBox.text}
          value={stateData.use_self_car_insurance_percentage}
          onChange={checkBox.func}
          containerStyle={checkBox.containerStyle}
          checkStyle={checkBox.checkStyle}
        />
      )}
      {info.describeText && <div className={styles.describe}>{info.describeText}</div>}
    </div>
  );
};

export default observer(RadioType);
