// base
// atom
import Button from 'atoms/Button';
// component
import CommonRowTable2 from 'components/CommonRowTable2';
import { alimtalkInfo } from 'constants/carBookingRegisterData';
import { toJS } from 'mobx';
// type
import { RegisterState } from 'pages/carBooking/register/index.page';
import React from 'react';

// style
import styles from './style.module.scss';

interface AlimTalkSendConfirmModalProps {
  data: RegisterState;
  submit: Function;
  close: () => void;
}

export const AlimTalkSendConfirmModal = (props: AlimTalkSendConfirmModalProps) => {
  const { submit, close, data } = props;

  return (
    <div className={styles.modal_wrap}>
      <CommonRowTable2 viewData={alimtalkInfo(data.rent_type)} stateData={data} />
      <div className={styles.modal_text_container}>
        <div>예약 고객에게 알림톡을 발송하시겠습니까?</div>
        <div>고객 연락처와 예약 정보가 정확한지 확인해 주세요.</div>
      </div>
      <div className={styles.modal_button_container}>
        <Button className={styles.modal_close} btnClick={close} outline>
          취소
        </Button>
        <Button className={styles.modal_confirm} btnClick={submit}>
          확인
        </Button>
      </div>
    </div>
  );
};
