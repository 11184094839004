import JsonWebToken from 'jsonwebtoken';
import { action, makeAutoObservable } from 'mobx';
import Cookies from 'universal-cookie';

import { API_ENV } from '../config';
import { cookieDomain } from '../config.js';
import { channelIOKey } from '../constants/apiKey';
import { IMS_CASH, IMS_POINT } from '../constants/stringTable';
import ChannelService from '../helpers/ChannelService';
import * as Sentry from '../helpers/sentry';
import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';

/**
 * boss: 대표,
 * rent_company_for_contract: userType === rent_company_for_contract
 * normaler: 일반 직원,
 * biller: 청구직원,
 * manager: 매니저,
 * car_manager: 차량 매니저
 */
export type PermissionUserType =
  | 'boss'
  | 'rent_company_for_contract'
  | 'normaler'
  | 'biller'
  | 'manager'
  | 'car_manager';

const cookies = new Cookies();

class AuthStore implements BaseStore<AuthStore> {
  jwt = null;
  userType = null;
  userId = null;
  permission = null;
  realname = null;
  company = null;
  contact = null;
  use_partner = null;
  use_form = null;
  use_external = false;
  use_connect = false;
  belongTo = null;
  logged = false;
  permissionUserType: PermissionUserType | null = null;
  companyCreatedAt = null;
  company_id = null;
  cash = null;
  point = null;
  jeju_company = false;

  constructor() {
    makeAutoObservable(this);
  }

  // prefetch = async (jwt?: string): Promise<AuthStore> => {
  //   if (jwt) {
  //     console.log(this.userId);
  //     const response = await apiGroup.getGroupUsers({ jwt, disableErrorHandler: true });
  //     const response2 = await apiGroup.getGroupLeader({ jwt, disableErrorHandler: true });
  //     console.log(response.data, response2.data);
  //   }
  //   return;
  // };
  setObject = (data) => _setObject(this, data);
  setValue = (data) => {
    this[data.key] = data.value === '' ? null : data.value;
  };

  login = async (jwt) => {
    const afterOneYear = new Date();

    afterOneYear.setFullYear(afterOneYear.getFullYear() + 1);
    cookies.set(`${process.env.API_ENV}-imsform-jwt`, jwt, {
      domain: cookieDomain,
      maxAge: 12 * 60 * 60,
      expires: afterOneYear,
      path: '/',
    });
    const decodedToken = JsonWebToken.decode(jwt);

    this.jwt = jwt;
    this.userType = decodedToken.user_type;
    this.userId = decodedToken.identity;
    this.permission = decodedToken.permission;
    this.realname = decodedToken.realname;
    this.company = decodedToken.company;
    this.contact = decodedToken.contact;
    this.use_partner = decodedToken.use_partner;
    this.use_form = decodedToken.use_form;
    this.use_external = Boolean(decodedToken?.use_external);
    this.use_connect = Boolean(decodedToken?.use_connect);
    this.belongTo = decodedToken.belong_to;
    this.logged = true;
    this.permissionUserType = getUserType({
      userType: decodedToken.user_type,
      permission: decodedToken.permission,
    });
    this.jeju_company = Boolean(decodedToken?.jeju_company);

    if (process.env.API_ENV === 'production') {
      ChannelService.updateUser({
        pluginKey: channelIOKey.plugin_key,
        profile: {
          name: `${this.realname} - ${this.company}`,
          userId: this.userId,
          mobileNumber: this.contact,
          company: this.company,
          imsform_ID: this.userId,
          belongTo: this.belongTo,
          imsform_env: `${
            API_ENV !== 'production' ? (API_ENV === 'staging' ? '테스트' : '개발') : '실환경'
          }`,
        },
      });
    }

    Sentry.setUser({
      id: decodedToken.identity,
      permissionUserType: getUserType({
        userType: decodedToken.user_type,
        permission: decodedToken.permission,
      }),
      realname: decodedToken.realname,
      company: decodedToken.company,
      contact: decodedToken.contact,
      use_partner: decodedToken.use_partner,
      use_form: decodedToken.use_form,
      use_external: Boolean(decodedToken?.use_external),
      belongTo: decodedToken.belong_to,
      ip_address: '{{auto}}',
    });
  };

  logout = () => {
    const option = {
      domain: cookieDomain,
      path: '/',
    };

    cookies.remove('jwt', option);
    cookies.remove(`${process.env.API_ENV}-imsform-jwt`, option);
    cookies.remove(IMS_CASH, option);
    cookies.remove(IMS_POINT, option);

    this.jwt = null;
    this.userType = null;
    this.userId = null;
    this.permission = null;
    this.realname = null;
    this.company = null;
    this.contact = null;
    this.use_partner = null;
    this.use_form = null;
    this.use_external = false;
    this.use_connect = false;
    this.belongTo = null;
    this.logged = false;
    this.permissionUserType = null;
    this.companyCreatedAt = null;
    this.company_id = null;
    this.cash = null;
    this.point = null;
    this.jeju_company = false;

    if (process.env.API_ENV === 'production') {
      ChannelService.initUser();
    }
    // Sentry.logout();
  };
  toString() {
    const userType = this.userType;
    const jwt = this.jwt;

    return JSON.stringify(
      {
        userType,
        jwt,
      },
      null,
      2
    );
  }
}

// TODO: computed로 변경 가능
const getUserType = ({ userType, permission }): PermissionUserType | undefined => {
  if (Boolean(userType) && (Number.isInteger(permission) || permission == null)) {
    if (permission === null && userType === 'rent_company_user') {
      return 'boss';
    }

    /** 옛날에 계약서만 쓰던 업체를 의미, 현재는 미사용중이고, 사용하는 유저도 없다. */
    if (userType === 'rent_company_for_contract') {
      return 'rent_company_for_contract';
    }

    if (permission === 0) {
      return 'normaler';
    }

    if (permission === 1) {
      return 'biller';
    }

    if (permission === 2) {
      return 'manager';
    }

    if (permission === 3) {
      return 'car_manager';
    }
  }
};

export default AuthStore;
