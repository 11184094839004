import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import {
  normalRentCallConfirmModalData,
  selfContractSendingModalData,
  selfContractSendingModalModifyData,
} from '../../../constants/CommonRowTableData/normalRentCallConfirmModalData';
import phoneNumberHyphen from '../../../helpers/phoneNumberHyphen';
import { validator } from '../../../helpers/validator';
import { ConfirmModalDefaultType as DefaultType } from '../../../interface/utils/modalContents';
import * as utils from '../../../utils/ClaimDetailUtils';
import { normalRentCallParser, periodParser } from '../../../utils/DataParser';
import { SelfContractSendStateType } from '../../../utils/PartnerNormalRequestUtils';
import ConfirmAlert from '../../Alert/ConfirmAlert';
import CommonRowTable2 from '../../CommonRowTable2';
import styles from './TableConfirmModal.module.scss';

interface TableConfirmModalPropsType extends DefaultType {
  stateData: any;
  selfContractSendState?: SelfContractSendStateType;
  data?: any;
}
interface TableConfirmModalStateType {
  editing: boolean;
  original_customer_contact: string;
  self_contract_info_contact: string;
  viewData: any[][];
  data?: any;
}
@inject('modalStore')
@observer
class TableConfirmModal extends Component<TableConfirmModalPropsType, TableConfirmModalStateType> {
  constructor(props: TableConfirmModalPropsType) {
    super(props);
    this.state = {
      editing: false,
      original_customer_contact: props.data?.original_customer_contact,
      self_contract_info_contact:
        props.data?.self_contract_info_contact || props.data?.original_customer_contact,
      viewData:
        !props.selfContractSendState || props.selfContractSendState === 'sent'
          ? cloneDeep(selfContractSendingModalData)
          : cloneDeep(normalRentCallConfirmModalData),
      data: props.data || null,
    };
  }

  returnRentalDays = (dropAt: string, pickupAt: string) => {
    const dropDay = normalRentCallParser('dropoff_at', dropAt, 'date');
    const dropTime = normalRentCallParser('dropoff_at', dropAt);
    const pickUpDay = normalRentCallParser('pickup_at', pickupAt, 'date');
    const pickUpTime = normalRentCallParser('pickup_at', pickupAt);
    const period = periodParser(dropAt, pickupAt);

    return `${dropDay} ${dropTime} ~ ${pickUpDay} ${pickUpTime} (${period})`;
  };

  getContent = () => {
    const { stateData, selfContractSendState } = this.props;
    const { viewData, editing, self_contract_info_contact, original_customer_contact } = this.state;
    const carAge = dayjs(stateData.car_age);
    const carAgeText = carAge.isValid() ? carAge.format('YY.MM') : '확인 필요';
    const isSelfContractSendingModal = !selfContractSendState || selfContractSendState === 'sent';
    const tabledata = {
      original_customer_contact: phoneNumberHyphen(original_customer_contact),
      self_contract_info_contact: editing
        ? self_contract_info_contact
        : phoneNumberHyphen(self_contract_info_contact),
      car_number: stateData.car_identity,
      car_name: `${stateData.car_name} / ${carAgeText}`,
      loan_amount: stateData.paid_cost,
      loan_period: this.returnRentalDays(stateData.start_at, stateData.end_at),
    };

    //비대면 계약서 보내기 일 경우에 car_name_year 이라는 값이 온다.
    if (stateData?.car_name_year) {
      tabledata.car_name = stateData.car_name_year;
    }

    if (isSelfContractSendingModal) {
      this.setSelfContractSendingModalData();
    }

    return (
      <>
        <CommonRowTable2
          viewData={viewData}
          stateData={tabledata}
          style={{ margin: 0, padding: 0, marginBottom: isSelfContractSendingModal ? 0 : 60 }}
          setData={(key: string, value: any) =>
            this.setState({ [key]: value } as Pick<
              TableConfirmModalStateType,
              keyof TableConfirmModalStateType
            >)
          }
        />
        {isSelfContractSendingModal ? (
          <p className={styles.self_contract_guide}>
            예약 고객과 통화해 실제 운전자가 맞는지 확인 후 전송해주세요.
          </p>
        ) : null}
      </>
    );
  };

  setSelfContractSendingModalData = () => {
    const { data, viewData, editing, self_contract_info_contact } = this.state;
    const item: any = utils.findItem(viewData, 'self_contract_info_contact');

    if (item) {
      if (!editing) {
        item.btn.func = () => {
          this.setState({
            editing: true,
            viewData: selfContractSendingModalModifyData,
          });
        };
      } else {
        item.btn.func = () => {
          const copyData = { ...data };

          if (!validator(self_contract_info_contact, 'phoneNumber')) {
            alert('연락처 형식을 확인해주세요.');

            return;
          }

          copyData.self_contract_info_contact = self_contract_info_contact;
          this.setState({
            editing: false,
            viewData: selfContractSendingModalData,
            data: copyData,
          });
        };
      }
    }
  };

  render() {
    const cancelBtnStyle = { width: 74, padding: 0 };
    const confirmBtnStyle = { width: 108 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={() => this.props.onClickConfirm(this.state)}
        confirmButtonText={'보내기'}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
      />
    );
  }
}

export default TableConfirmModal;
