/**
 * @summary 변환이 필요한 orderer map
 * orderer 변환이 필요한 값은 여기에 추가.
 * key: 변경된 orderer 명. (tmoneygoAPI)
 * value: 기존 사용중인 orderer 명. (tmoneygo)
 */
const PARSE_ORDERER_MAPS = {
  tmoneygoAPI: 'tmoneygo',
  kakaoWL: 'premove',
} as const;

/**
 * @summary 추가된 orderer 명 대신 기존 orderer 명 반환.
 */
type NeedToParseOrdererName = keyof typeof PARSE_ORDERER_MAPS;

export const getParsedOrdererName = (ordererName: NeedToParseOrdererName & string) => {
  switch (ordererName) {
    case 'tmoneygoAPI':
      return PARSE_ORDERER_MAPS['tmoneygoAPI'];
    case 'kakaoWL':
      return PARSE_ORDERER_MAPS['kakaoWL'];
    default:
      return ordererName;
  }
};
