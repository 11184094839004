export function getByteLength(s, b, i, c) {
  if (s == '' || s == undefined || s == null) {
    return 0;
  }

  return s.length;
}

export function getByte(s, b, i, c) {
  if (s == '' || s == undefined || s == null) {
    return 0;
  }

  // eslint-disable-next-line no-cond-assign
  for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 2 : c >> 7 ? 2 : 1) {}

  return b;
}

export function bytesToSize(bytes) {
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  if (bytes == 0) {
    return '0 Byte';
  }

  let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`;
}

export const getSlicedStringByMaxByte = ({ string, maxByte }) => {
  const originByte = getByte(string);

  if (originByte <= maxByte) {
    return string;
  }

  let slicedByte = originByte;
  let lastDeletedIndex = string.length - 1;

  for (; lastDeletedIndex >= 0; lastDeletedIndex--) {
    const unicode = string.charCodeAt(lastDeletedIndex);

    if (unicode >> 11 || unicode >> 7) {
      slicedByte -= 2;
    } else {
      slicedByte -= 1;
    }

    if (slicedByte <= maxByte) {
      break;
    }
  }

  return string.slice(0, lastDeletedIndex);
};
