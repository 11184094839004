import callApi from '../../helpers/callApi';
// import { getGroupUsers } from './getGroupUsers';
export * from './getGroupUsers';
export default {
  // callApi2 개선 전까지 기존 api 사용(SSR시 cookie 처리가 안되고 있음);
  getGroupUsers(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'GET',
      url: '/v2/group/users',
      params: {
        option: params.option,
        search: params.search,
        page: params.page,
        only_joined: params.only_joined,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      jwt: params.jwt,
    });
  },
  setShareCarData() {
    return callApi({
      method: 'POST',
      url: '/v2/group/share_car_data',
      data: {},
    });
  },
  setCarMoveToAnotherBranch(params) {
    return callApi({
      method: 'POST',
      url: '/v2/group/cars',
      data: {
        car_id: params.carId,
        company_id: params.companyId,
      },
    });
  },
  getCarList(params) {
    const query = params.query;
    const serializeForm = { page: query.page };

    if (query.search) {
      serializeForm.search = decodeURIComponent(query.search);
    }

    if (query.company_id) {
      serializeForm.company_id = query.company_id;
    }

    serializeForm.state = query.state || 'all';

    return callApi({
      method: 'GET',
      url: '/v2/group/cars',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  getBranchContractList(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'GET',
      url: '/v2/group/claims',
      params: params.query,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getStatisticDatas(params) {
    const serializeForm = { type: params.rentType, year: params.year };

    if (params.userId !== 'all') {
      serializeForm.userId = params.userId;
    }

    return callApi({
      method: 'GET',
      url: '/v2/group/statistics',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  createGroup(params) {
    return callApi({
      method: 'POST',
      url: 'v2/groups',
      data: {
        group_name: params.corpName,
      },
    });
  },
  searchGroup(params) {
    return callApi({
      method: 'GET',
      url: '/v2/groups',
      params: {
        searchValue: decodeURIComponent(params.searchValue),
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  joinGroup(params) {
    return callApi({
      method: 'POST',
      url: '/v2/group/join',
      data: { group_id: params.group_id },
    });
  },
  leaveGroup() {
    return callApi({
      method: 'POST',
      url: 'v2/group/leave',
      data: {},
    });
  },
  getGroupLeader(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'GET',
      url: '/v2/group',
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      jwt: params.jwt,
    });
  },
  disconnectGroup(params) {
    return callApi({
      method: 'POST',
      url: '/v2/group/disconnect',
      data: params,
    });
  },
  selectGroupInvolve(params) {
    return callApi({
      method: 'POST',
      url: `v2/group/rent-company/${params.company_id}/approve`,
      data: { update_type: params.update_type },
    });
  },
};
