import { Input, Table } from 'antd';
import Tooltip from 'components/Tooltip';
import { useEffect, useRef, useState } from 'react';
import useStores from 'stores/UseStores';

import apiRentContract from '../../../../api/rentContract';
import { TABLE_COLUMNS } from './const';
import { BLACKLIST_ICON, SEARCH_ICON } from './const';
import * as S from './style';
const SearchDriverModal = ({
  usingCustomerSearch,
  setDriverInfo,
  keyName,
  selectedId,
  isContract,
}) => {
  const { authStore, menuStore, modalStore } = useStores();
  const [searchValue, setSearchValue] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [totalData, setTotalData] = useState([]);
  const [selectRowKeys, setSelectRowKeys] = useState<string>(null);
  const [selectedItem, setSelectedItem] = useState<any>();
  const [blackListHover, setBlackListHover] = useState(false);

  const getDriverList = async (searchValue?: string, page?: number) => {
    const params = {
      menuStore,
      authStore,
      searchValue,
      page: page ? page : currentPage,
    };

    const response = await apiRentContract.getDriverList(params);
    const responseData = response.data;

    if (response.status === 200) {
      const customersData = responseData.customers.map((data, i) => {
        return { ...data };
      });

      setTotalData(customersData);
      setTotalPage(response.data.total_page);
      document.getElementsByClassName('ant-table-body')[0].scrollTo(0, 0);
    }
  };

  const search = () => {
    getDriverList(searchValue, 1);
  };

  const select = () => {
    if (selectedItem) {
      if (selectedItem.is_black) {
        if (
          confirm(
            `해당 운전자는 주의고객으로 등록되어 있습니다. ${
              isContract ? '계약서 작성' : '차량 예약'
            }을 진행하시겠습니까? (사유: ${selectedItem.black_list_reason})`
          )
        ) {
          setDriverInfo(selectedItem, keyName);
          modalStore.modalClose();
        }
      } else {
        setDriverInfo(selectedItem, keyName);
        modalStore.modalClose();
      }
    } else {
      setDriverInfo(selectedItem, keyName);
      modalStore.modalClose();
    }
  };

  useEffect(() => {
    getDriverList();
  }, [currentPage]);

  useEffect(() => {
    if (selectedId) {
      setSelectRowKeys(selectedId);
    }
  }, [selectedId]);

  return (
    <S.ModalWrap>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          search();
        }}
      >
        <div style={{ display: 'flex', gap: 10 }}>
          <Input
            suffix={
              <img src={SEARCH_ICON} onClick={search} width={20} style={{ cursor: 'pointer' }} />
            }
            placeholder={`${usingCustomerSearch ? '고객' : '운전자'}명 또는 연락처 검색`}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
          />
          <S.ResetButton
            type="button"
            onClick={() => {
              getDriverList();
              setSearchValue('');
              setCurrentPage(1);
            }}
          >
            초기화
          </S.ResetButton>
        </div>
      </form>
      <S.Description>
        <div
          onMouseOver={() => setBlackListHover(true)}
          onMouseLeave={() => setBlackListHover(false)}
        >
          <Tooltip
            position="bottom"
            message={
              '주의고객으로 등록된 고객은 운전자명 우측에 표시되며,\n주의고객 관리는 사업자 관리 - 고객관리에서 가능합니다.'
            }
            style={{ top: '20px', left: '135px' }}
            triangle={{ top: '0px', left: '10px' }}
          >
            <S.BlakcListInfoWrap blackListHover={blackListHover}>
              <img src={BLACKLIST_ICON} width={16} height={16} />
              <div className="text">주의고객</div>
            </S.BlakcListInfoWrap>
          </Tooltip>
        </div>
        <S.SearchDescription>
          {`* 계약서 작성 이력이 있는 ${usingCustomerSearch ? '고객' : '운전자'}만 검색됩니다.`}
        </S.SearchDescription>
      </S.Description>
      <S.TableContainer>
        <S.CustomerTableStyle />
        <S.CustomCheckBoxStyle />
        <Table
          columns={TABLE_COLUMNS({ selectRowKeys, setSelectRowKeys, setSelectedItem })}
          dataSource={totalData}
          locale={{
            emptyText: (
              <div>
                {usingCustomerSearch ? '검색된 결과가 없습니다.' : '선택 가능한 운전자가 없습니다.'}
              </div>
            ),
          }}
          pagination={{
            pageSize: 20,
            position: ['bottomCenter'],
            total: totalPage * 20,
            current: currentPage,
            showSizeChanger: false,
            onChange: (e) => {
              setCurrentPage(e);
            },
            nextIcon: <></>,
            prevIcon: <></>,
          }}
          scroll={{
            scrollToFirstRowOnChange: false,
            y: 320,
          }}
        />
      </S.TableContainer>
      <S.ButtonWrap>
        <S.SelectButton onClick={select}>선택 완료</S.SelectButton>
      </S.ButtonWrap>
    </S.ModalWrap>
  );
};

export default SearchDriverModal;
