import dayjs from 'dayjs';

import * as reg from '../constants/regularExpression';
import carNumberCheck from './carNumberCheck';
import bizNumCheck from './checkBizID';
import corporationNumberCheck from './corporationNumberCheck';
import { isNewHanaRegIdForm, regIdFormatValidateForHana } from './registrationUtils';

const idNumberCheck = (val) => {
  try {
    const regExp = reg.idNumber;

    if (!regExp.test(val)) {
      throw new Error('_');
    }

    return true;
  } catch (error) {
    return false;
  }
  // 생년월일의 형태와 7번째 자리(성별) 유효성 검사
};

export function validator(value, type, returnEmpty) {
  //
  // 넘어온 값이 빈값인지 체크합니다.
  // !value 하면 생기는 논리적 오류를 제거하기 위해
  // 명시적으로 value == 사용
  // [], {} 도 빈값으로 처리
  if (
    value === ' ' ||
    value === '' ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === 'object' && !Object.keys(value).length)
  ) {
    if (returnEmpty) {
      return 'empty';
    }

    return false;
  }

  switch (type) {
    // 정규식
    case 'email':
      return reg.email.test(value);
    case 'mobile':
      return reg.mobile.test(value);
    case 'userId':
    case 'username':
      return reg.userId.test(value);
    case 'realname':
      return reg.userName.test(value);
    case 'password':
      return reg.password.test(value);
    case 'telephone':
      return reg.telephone.test(value);
    case 'phoneNumber':
      return reg.phoneNumber.test(value);
    case 'contact':
      return reg.telephone.test(value) || reg.phoneNumber.test(value);
    case 'fax':
      return reg.fax.test(value);
    case 'companyName':
      return reg.companyName.test(value);
    case 'companyAddress':
      return reg.companyAddress.test(value);
    case 'companyRegisterNumber':
      return reg.companyRegisterNumber.test(value);
    case 'integer':
      return reg.integer.test(value);
    case 'certify':
      return reg.certify.test(value);
    case 'idLicenseNumber':
      return idNumberCheck(value);
    case 'car_number':
      return carNumberCheck(value);
    case 'ownCar':
      return reg.ownCar.test(value);
    case 'birthday':
      return reg.birthday.test(value);
    case 'advisor':
      return reg.checkRencarPartnerAdvisor.test(value);
    case 'dateYYYYMMDD':
      return reg.dateYYYYMMDD.test(value);
    case 'bizNumber':
      return bizNumCheck(value);
    case 'corpNumber':
      return corporationNumberCheck(value);
    case 'carAge':
      return !(Number(value) < 1900 || Number(value) > 3000);
    case 'carRegisterDate':
      return reg.dateYYYYMMDD.test(value);
    case 'businessType':
      return reg.businessType.test(value);
    case 'number':
      if (typeof value === 'number') {
        return true;
      }

      if (typeof value === 'string' && !isNaN(Number(value))) {
        return true;
      }

      return false;
    case 'base64':
      return reg.base64.test(value);
    case 'driverLicense':
      if (value?.length !== 12) {
        return false;
      }

      const firstWord = value.substr(0, 2);

      if (isNaN(Number(firstWord))) {
        //앞두글자가 숫자가 아니면 체크
        if (
          [
            '서울',
            '부산',
            '경기',
            '강원',
            '충북',
            '충남',
            '전북',
            '전남',
            '경북',
            '경남',
            '제주',
            '대구',
            '인천',
            '광주',
            '대전',
            '울산',
          ].includes(firstWord)
        ) {
          return true;
        }

        return false;
      }

      return reg.integer.test(value);
    default:
      return Boolean(value);
    // just logic
  }
}

// -- start check[] 형태의 검증을 위한 클래스
// ex) const check = [{ key: string , value | label : string , reg_exp: RegExp | () => boolean, text: string , necessary: boolean }, ...];
class CheckItem {
  constructor(data, state) {
    this._key = data.key;
    this._value = data.value || data.label;
    this._reg_exp = data.reg_exp;
    this._text = data.text;
    this._necessary = Boolean(data.necessary);
    this._stateValue = state[data.key];
  }

  alertEmptyCaution = () => {
    return alert(`${this.value} 값이 비어있습니다.`);
  };

  alertRegExpCaution = () => {
    return alert(this.text || `${this.value} 값이 올바른지 다시 확인해주세요.`);
  };

  get key() {
    return this._key;
  }
  get text() {
    return this._text;
  }
  get regExp() {
    return this._reg_exp;
  }
  get value() {
    return this._value;
  }
  get deleteStateValueSpace() {
    return String(this.stateValue).trim();
  }
  get isEmpty() {
    if (!this.stateValue || !this.deleteStateValueSpace.length) return true;

    return false;
  }
  get stateValue() {
    return this._stateValue;
  }
  get isNecessary() {
    return this._necessary;
  }
  get hasRegExp() {
    return Boolean(this.regExp);
  }
  get isRegExpPass() {
    if (typeof this.regExp === 'function') {
      const regExpFunc = this.regExp;

      return regExpFunc(this?.stateValue || '');
    }

    return this.regExp.test(this.stateValue);
  }
}
export class RegistrationIdItem extends CheckItem {
  constructor(data, state) {
    super(data, state);
    this._insurance_company = state.insurance_company || state.claim_insurance;
  }

  // 오버라이드
  alertRegExpCaution = () => {
    if (this.isDivided) {
      return alert(
        this.template
          ? `접수번호 형식을 확인해주세요. 예) ${this.template}`
          : `접수번호 형식을 확인해주세요.`
      );
    }

    return alert(`접수번호 형식을 확인해주세요.`);
  };
  get hasRegExp() {
    return true;
  }
  get isRegExpPass() {
    if (this.isDivided) {
      if (isNewHanaRegIdForm(this.insurance_company, this.stateValue)) {
        return true;
      }

      return this.isDividendCasePass;
    }

    return true;
  }
  // 새로 작성
  get firstId() {
    if (this.stateValue?.includes('-')) {
      if (this.stateValue[0] === '-' && this.stateValue.length > 1) {
        return null;
      }

      const IdArr = this.stateValue?.split('-')?.filter((val) => Boolean(val));

      return IdArr ? IdArr[0] : null;
    }

    return this.stateValue?.substring(0, this.firstLength) || '';
  }
  get secondId() {
    if (this.stateValue?.includes('-')) {
      if (this.stateValue[0] === '-' && this.stateValue.length > 1) {
        return this.stateValue.substring(1);
      }

      const secondId =
        this.stateValue
          ?.split('-')
          ?.filter((val) => Boolean(val))
          ?.filter((val, idx) => idx !== 0)
          .join('') || null;

      // 보험사가 '하나'이고 접수번호 앞자리가 YYYY일 경우, 접수번호 뒷자리를 0으로 채우지 않는다.
      return isNewHanaRegIdForm(this.insurance_company, this.stateValue)
        ? secondId
        : this.secondIdParser(secondId);
    }

    const secondId = this.stateValue?.substring(this.firstLength) || '';

    return isNewHanaRegIdForm(this.insurance_company, this.stateValue)
      ? secondId
      : this.secondIdParser(secondId);
  }
  get registration_id() {
    if (this.isDivided) {
      if (!this.secondId) return '';

      return `${this.firstId}${this.secondId}`;
    }

    return this.stateValue;
  }
  get insurance_company() {
    return this._insurance_company || '없음';
  }
  get divided_registration_id_company_list() {
    return [
      '삼성',
      'DB',
      '현대',
      'KB',
      '메리츠',
      '한화',
      'AXA',
      '하나',
      '흥국',
      '롯데',
      'MG',
      '캐롯',
      '택시공제',
      '전세버스공제',
      '렌트카공제',
    ];
  }
  get template() {
    return this.dividedIdDefaultFormat.template;
  }
  get firstLength() {
    return this.dividedIdDefaultFormat.firstLength;
  }
  get secondLength() {
    return this.dividedIdDefaultFormat.secondLength;
  }
  get firstIdRegx() {
    return this.dividedIdDefaultFormat.firstIdRegx;
  }
  get isDivided() {
    return Boolean(this.divided_registration_id_company_list.includes(this.insurance_company));
  }
  get isDividendCasePass() {
    const { firstId, secondId, firstIdRegx, secondLength } = this;

    /*
    FIXME: 나누어진 접수번호 조건 정리 ( 계약서 작성 가능 : isDividendCasePass === true )
    1. 앞 뒤 값이 모두 비었으면 null - 계약서 작성 가능 
    2. 뒷자리값이 비었으면 null - 계약서 작성 가능
    3. 앞자리값이 날짜 형식에 맞지않으면 - 계약서 작성 불가
    4. 앞자리값이 맞아도 뒷자리값이 형식이 맞지않으면 - 계약서 작성 불가
    5. 앞자리값 없이 뒷자리만 있을 경우도 - 계약서 작성 불가
    6. 앞자리값에서 년은 2010-2099의 범위로 지정, 월은 01~12 일은 01~31 일의 범위로 지정 
  */
    if (!firstId && !secondId) return true;

    if (firstId && !secondId) return true;

    if (firstId && secondId) {
      if (firstIdRegx.test(firstId) && secondId.length === secondLength) return true;

      return false;
    }

    return false;
  }
  get dividedIdDefaultFormat() {
    const YY = dayjs().format('YY');
    const YYYY = dayjs().format('YYYY');
    const YYYYMM = dayjs().format('YYYYMM');
    const YYYYMMDD = dayjs().format('YYYYMMDD');
    const MM = dayjs().format('MM');
    const DD = dayjs().format('DD');
    let format = { firstLength: null, secondLength: null, regExp: null, template: null };

    switch (this.insurance_company) {
      case '삼성':
      case '흥국':
        format = {
          firstLength: 8,
          secondLength: 5,
          firstIdRegx: new RegExp('^(20[1-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])', 'g'),
          template: `${YYYYMMDD}-12345`,
        };
        break;
      case 'AXA':
      case 'DB':
        format = {
          firstLength: 2,
          secondLength: 8,
          firstIdRegx: new RegExp('^([1-9][0-9])', 'g'),
          template: `${YY}-12345678`,
        };
        break;
      case '롯데':
      case '한화':
      case '캐롯':
        format = {
          firstLength: 6,
          secondLength: 5,
          firstIdRegx: new RegExp('^(20[1-9][0-9])(0[1-9]|1[012])', 'g'),
          template: `${YYYYMM}-12345`,
        };
        break;
      case 'KB':
        format = {
          firstLength: 8,
          secondLength: 6,
          firstIdRegx: new RegExp('^(20[1-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])', 'g'),
          template: `${YYYYMMDD}-123456`,
        };
        break;
      case '현대':
        format = {
          firstLength: 4,
          secondLength: 6,
          firstIdRegx: new RegExp('^([1-9][0-9])(0[1-9]|1[012])', 'g'),
          template: `${YY}${MM}-123456`,
        };
        break;
      case '메리츠':
        format = {
          firstLength: 4,
          secondLength: 7,
          firstIdRegx: new RegExp('^(20[1-9][0-9])', 'g'),
          template: `${YYYY}-1234567`,
        };
        break;
      case 'MG':
        format = {
          firstLength: 4,
          secondLength: 10,
          firstIdRegx: new RegExp('^(20[1-9][0-9])', 'g'),
          template: `${YYYY}-1234567890`,
        };
        break;
      case '하나':
        format = {
          firstLength: 4,
          secondLength: 7,
          firstIdRegx: new RegExp('^([1-9][0-9])(0[1-9]|1[012])', 'g'),
          template: `${YY}${MM}-1234567`,
        };
        break;
      case '전세버스공제':
      case '택시공제':
        format = {
          firstLength: 4,
          secondLength: 5,
          firstIdRegx: new RegExp('^(20[1-9][0-9])', 'g'),
          template: `${YYYY}-12345`,
        };
        break;
      case '렌트카공제':
        format = {
          firstLength: 6,
          secondLength: 4,
          firstIdRegx: new RegExp('^([1-9][0-9])(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])', 'g'),
          template: `${YY}${MM}${DD}-1234`,
        };
        break;
      default:
        return format;
    }

    return format;
  }
  secondIdParser(_id) {
    if (!_id) return null;

    const difference = this.secondLength - _id.length;

    if (difference > 0) {
      let zeroStr = '';

      for (let i = 0; i < difference; i++) {
        zeroStr += '0';
      }

      return `${zeroStr}${_id}`;
    }

    return _id;
  }
}

class ClaimeeNameItem extends CheckItem {
  constructor(data, state) {
    super(data, state);
    this._guaranteed = state.guaranteed;
    this._claimState = state.claimState;
    this._claim_request_type = state.claim_request_type;
  }

  // 오버라이드
  get isEmpty() {
    if (this.isUseCondition) {
      if (!this.isGuaranteed) return false;

      if (!this.stateValue || !this.deleteStateValueSpace.length) return true;

      return false;
    }

    return false; // 애초에 보증대차 설정 조건이 아니면 [비웠다/채웠다] 개념이 없으므로 false
  }

  // 새로 작성
  get isUseCondition() {
    return !this.isComputerConnectionCase;
  }
  get isComputerConnectionCase() {
    return (
      this.claim_request_type === 'insurance_rent_request' ||
      this.claim_request_type === 'meritz_rent_request'
    );
  }
  get claimState() {
    return this._claimState;
  }
  get claim_request_type() {
    return this._claim_request_type;
  }
  get isGuaranteed() {
    return this._guaranteed;
  }
}

export const createCheckItem = (data, state) => {
  // 검증 조건이 다른 checkItem의 경우 CheckItem을 상속하여 추가
  switch (data.key) {
    case 'claimee_name':
      return new ClaimeeNameItem(data, state);
    case 'registration_id':
      return new RegistrationIdItem(data, state);
    default:
      return new CheckItem(data, state);
  }
};

// -- End check[] 형태의 검증을 위한 클래스
