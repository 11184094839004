import callApi from '../callApi';
export type DefaultInfo = {
  notification_count: number;
  cash: number;
  point: number;
  company_id: number;
  permission: any;
  restricted_from: string;
  restricted_to: string;
  penalty: boolean;
  delivery_time_max_hours: number;
  group_leader: boolean;
  created_at: string;
  pending_signature_count: number;
  user_login_id: string;
};

export type SuccessResponse<TData, THeaders = any> = {
  data: TData;
  status: 200;
  statusText: string;
  headers: THeaders;
};

export type GetSuccessResponse<TData, THeaders = any> = {
  data: TData & {
    defaultInfo: DefaultInfo;
  };
  status: 200;
  statusText: string;
  headers: THeaders;
};

export type DuplicatedContract = {
  claim_id: number;
  contract_id: number;
  delivered_at: string;
  return_date: string;
  type: 'normal' | 'contract';
};

export type ErrorResponse<
  TErrorNo extends number,
  TErrorName extends string = string,
  TStatusCode extends Exclude<number, 200> = Exclude<number, 200>,
  TData extends {} = {},
  TAdditionalData extends {} = {},
  TMessageToUser extends { [key: string]: any } = {},
  THeaders extends {} = {}
> = {
  data: {
    error: {
      additional_data: TAdditionalData;
      data: TData;
      message: string;
      message_to_user: TMessageToUser;
      name: TErrorName;
      value?: any;
      duplicated_values?: any;
      duplicate_contracts?: DuplicatedContract[];
    };
    error_name: TErrorName;
    error_no: TErrorNo;
    message: string;
    success: false;
  };
  status: TStatusCode;
  headers: THeaders;
};

export function isErrorResponse(
  resp: SuccessResponse<any> | ErrorResponse<any>
): resp is ErrorResponse<any> {
  return Boolean(resp.data.error);
}

export async function get<
  TResponse extends SuccessResponse<any, any> | ErrorResponse<any, any>,
  TRequest = {} | { disableErrorHandler?: boolean }
>(url: string, params: TRequest, jwt?: string) {
  const response = await callApi.get<any, TResponse>(url, {
    params,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    jwt,
  });

  return response;
}

export async function post<TResponse, TRequest = {}>(
  url: string,
  params: TRequest,
  disableErrorHandler?: boolean
) {
  const response = await callApi.post<unknown, TResponse>(url, params, {
    disableErrorHandler,
  });

  return response;
}

export async function put<TResponse, TRequest = {}>(
  url: string,
  params: TRequest,
  disableErrorHandler?: boolean
) {
  const response = await callApi.put<unknown, TResponse>(url, params, {
    disableErrorHandler,
  });

  return response;
}

export async function _delete<TResponse, TRequest = {}>(url: string, params?: TRequest) {
  const response = await callApi.delete<unknown, TResponse>(url, params);

  return response;
}
