export default {
  filterSearchValues(params) {
    // search states to object
    const { query, state } = params;
    const keys = Object.keys(query);

    if (keys?.length > 0) {
      return Object.assign(
        {},
        ...keys.map((key) => {
          // encodeURLComponent 사용 금지 - axios 는 "params" 항목에 값을 넣으면 url parameter 를 알아서 인코딩 함
          if (state && state[key]) {
            return { [key]: state[key] };
          }

          return { [key]: query[key] };
        })
      );
    }

    return null;
  },
};
