import dayjs from "dayjs";
import find from "lodash/find";

import driver_type from "../constants/driver_type.json";
import costComma from "../helpers/costComma";
import * as contractUtils from "../utils/ContractUtils";
import * as parser from "./DataParser";

const s3 = "https://s3.ap-northeast-2.amazonaws.com/rencar/signatureImages/";
const question = "/images/question/question-white.png";

const inputWidth = 150;

export const howToPayment = [
  { label: "계좌이체", value: "계좌이체" },
  { label: "카드결제", value: "카드결제" },
  { label: "기타", value: "기타" },
];

export const findItem = (tableData, key) => {
  let result = null;

  tableData.forEach((item) => {
    if (find(item, { key })) {
      result = find(item, { key });
    }

    if (Array.isArray(item)) {
      item.forEach((item2) => {
        if (find(item2, { key })) {
          result = find(item2, { key });
        }
      });
    }
  });

  return result;
};

//// 계약서 정보
export const getBasicInfoTableData = (info) => {
  return [
    [
      {
        label: "연번",
        value: String(parser.defaultParser(info.id)),
        option: "output",
      },
      {
        label: "진행 상태",
        value: parser.getStateKorean(info.state),
        option: "output",
      },
    ],
    [
      {
        label: "구분",
        value: parser.rentTypeParser(info.rent_type),
        option: "output",
      },
      {
        label: MESSAGE.EXPECT_RETURN_DATE,
        value: parser.dateParser(info.expect_return_date_time),
        option: "output",
      },
    ],
  ];
};

export const modifyBasicInfoTableData = (info) => {
  return [
    [
      {
        label: "연번",
        value: String(parser.defaultParser(info.id)),
        option: "output",
      },
      {
        label: "진행 상태",
        value: parser.getStateKorean(info.state),
        option: "output",
      },
    ],
    [
      {
        label: "구분",
        value: parser.rentTypeParser(info.rent_type),
        option: "output",
      },
      {
        key: "expect_return",
        label: MESSAGE.EXPECT_RETURN_DATE,
        option: "date_time",
        calcDate: false,
        connectType: info.rent_type, // 렌트 종류에 따라 value 와 일치하는 데이터만 출력
        calendarOption: "end",
      },
    ],
  ];
};

export const getPartnerInfoTableData = (info) => {
  return [
    [
      {
        label: "소개처 명",
        value: parser.defaultParser(info.recommender_name),
        option: "output",
      },
      {
        label: "소개처 번호",
        value: parser.contractParser(info.recommender_contact),
        option: "output",
      },
    ],
    [
      {
        label: "소개처 담당자",
        value: parser.defaultParser(info.recommender_manager),
        option: "output",
      },
      {
        label: "소개처 알림톡",
        value: info.is_send_recommender_message ? "발송" : "미발송",
        option: "output",
        alimtalk: info.is_send_recommender_message,
        tooltip: question,
        tooltipMessage:
          "배차, 반납, 계약유형전환(보험→일반, 되돌리기) 안내 알림톡이 소개처 담당자에게 발송됩니다.",
        tooltipStyle: {
          left: "150px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
      },
    ],
  ];
};

export const getSalesEmployeeInfoTableData = (info) => {
  return [
    [
      {
        label: "영업 직원",
        value: parser.defaultParser(info.sales_employee_name),
        option: "output",
      },
    ],
  ];
};

export const getBusinessInfoTableData = (info) => {
  return [
    [
      {
        label: "사업자 명",
        value: parser.defaultParser(info.business_name),
        option: "output",
      },
    ],
    [
      {
        label: "사업자 번호",
        value: parser.bizNumParser(info.registration_num),
        option: "output",
      },
      {
        label: "사업자 주소",
        value: parser.abbreviation(parser.defaultParser(info.company_address)),
        option: "output",
      },
    ],
  ];
};

export const getContractInfoTableData = (info, btnFunc, carPhoto) => {
  return [
    [
      {
        label: "대여차종",
        value: parser.defaultParser(
          parser.abbreviation(info.rent_car_name, 15)
        ),
        option: "output",
      },
      {
        label: MESSAGE.ALLOCATE_CAR_DATE,
        value: parser.dateParser(info.delivered_date_time),
        option: "output",
      },
    ],
    [
      {
        label: MESSAGE.RENTAL_CAR_NUMBER,
        value: parser.defaultParser(info.rent_car_number),
        option: "output",
        btn: carPhoto > 1 ? MESSAGE.VIEW_CAR_PICTURE : null,
        btnFunc: btnFunc.showCarPicture,
      },
      {
        label: "반납일",
        value: parser.dateParser(info.return_date_time),
        option: "output",
      },
    ],
  ];
};

export const modifyContractInfoTableData = (info) => {
  return [
    [
      {
        label: "대여차종",
        value: parser.defaultParser(
          parser.abbreviation(info.rent_car_name, 15)
        ),
        option: "output",
      },
      {
        key: "delivered",
        label: MESSAGE.ALLOCATE_CAR_DATE,
        option: "date_time",
        calcDate: false,
        connectType: "daily", // 렌트 종류에 따라 value 와 일치하는 데이터만 출력
        calendarOption: "start",
      },
    ],
    [
      {
        label: MESSAGE.RENTAL_CAR_NUMBER,
        value: parser.defaultParser(info.rent_car_number),
        option: "output",
      },
      {
        key: "return",
        label: "반납일",
        value: info.return_date_time ? null : "-",
        option: info.return_date_time ? "date_time" : "output",
        calcDate: false,
        connectType: info.rent_type, // 렌트 종류에 따라 value 와 일치하는 데이터만 출력
        calendarOption: "end",
      },
    ],
  ];
};

export const getLongTermPaymentInfoTableData = (info) => {
  const paymentMethod = info.payment_method_type;
  let paymentMethodText = parser.defaultParser(paymentMethod);
  let depositMount = null;

  switch (paymentMethod) {
    case "계좌이체":
      paymentMethodText += ` / ${parser.strValidParser(
        info.bank_name
      )} / ${parser.strValidParser(
        info.account_holder
      )} / ${parser.strValidParser(info.account_num)}`;
      break;
    case "기타":
      if (info.payment_method_detail) {
        paymentMethodText += ` / ${parser.strValidParser(
          info.payment_method_detail
        )}`;
      } else {
        paymentMethodText += " / -";
      }

      break;
    default:
      break;
  }

  if (info.total_deposit_amount === 0) {
    depositMount = "0원";
  } else {
    depositMount = `${costComma(info.total_deposit_amount)}원`;
  }

  if (info.total_deposit_amount !== info.deposit_amount) {
    depositMount += ` (잔여 보증금: ${costComma(info.deposit_amount)}원)`;
  }

  let start = null;
  let end = null;

  if (info.start) {
    start = `${info.start.split(" ")[0].split(".").join("-")} ${
      info.start && info.start.split(" ")[1]
    }`;
  }

  if (info.end) {
    end = `${info.end.split(" ")[0].split(".").join("-")} ${
      info.end && info.end.split(" ")[1]
    }`;
  }

  return [
    [
      {
        label: "계약 시작일",
        value: info.start,
        value2: `${dayjs(end).diff(start, "month")}개월`,
        style: {
          marginLeft: "10px",
          background: "#f6f6f6",
          borderRadius: "3px",
          padding: "6px 16px",
        },
        option: "output",
      },
    ],
    [
      {
        label: "계약 종료일",
        value: info.end,
        value2: `기간: ${dayjs(end).diff(start, "day")}일`,
        style: {
          marginLeft: "10px",
          background: "#f6f6f6",
          borderRadius: "3px",
          padding: "6px 16px",
        },
        option: "output",
      },
    ],
    [
      {
        label: "월 납부금",
        value: parser.defaultParser(info.monthly_fee),
        option: "output",
        unit: "원",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "0px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "납부스케줄에서 수정 가능합니다.",
      },
      {
        label: "납부일",
        value: `매달 ${info.payment_day}일`,
        value2: info.initial_payment_date
          ? `최초납부일: ${info.initial_payment_date}`
          : null,
        style: {
          marginLeft: "10px",
          background: "#f6f6f6",
          borderRadius: "3px",
          padding: "6px 16px",
        },
        option: "output",
      },
    ],
    [
      {
        label: "선수금",
        option: "output",
        value: info.prepaid_amount === 0 ? "0" : info.prepaid_amount,
        unit: "원",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "-5px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "입금내역에서 수정 가능합니다.",
      },
      {
        label: "선수금 분할 처리",
        value:
          info.prepaid_num === 0
            ? "-"
            : info.prepaid_num === 1
            ? "일괄처리"
            : `분할처리 ${info.prepaid_num}회`,
        option: "output",
      },
    ],
    [
      {
        label: "보증금",
        value: depositMount,
        option: "output",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "-5px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "입금내역에서 수정 가능합니다.",
      },
      {
        label: "총 요금",
        value: parser.defaultParser(info.totalCost),
        option: "output",
        unit: "원",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "-10px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "(월 납부금 * 회차) + 선수금",
      },
    ],
    [
      {
        label: "결제 안내 알림톡",
        value: info.alimtalk ? MESSAGE.SEND : "미발송",
        option: "output",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "45px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "납부일 5일 전(영업일 기준) 고객에게 알림톡 자동발송",
      },
    ],
    [
      {
        label: "납부 방법",
        value: paymentMethodText,
        option: "output",
      },
    ],
  ];
};

export const modifyLongTermPaymentInfoTableData = (info) => {
  let depositMount = null;

  if (info.total_deposit_amount === 0) {
    depositMount = "0원";
  } else {
    depositMount = `${costComma(info.total_deposit_amount)}원`;
  }

  if (info.total_deposit_amount !== info.deposit_amount) {
    depositMount += ` (잔여 보증금: ${costComma(info.deposit_amount)}원)`;
  }

  return [
    [
      {
        key: "start_datetime",
        label: "계약 시작일",
        option: "date_time",
        connectType: "long_term",
        calendarOption: "start",
        necessary: true,
        longStartOption: true,
      },
    ],
    [
      {
        key: "end_datetime",
        label: "계약 종료일",
        option: "date_time",
        connectType: "long_term",
        calendarOption: "end",
        necessary: true,
        longEndOption: true,
        disabled: true,
      },
    ],
    [
      {
        label: "월 납부금",
        value: parser.defaultParser(info.monthly_fee),
        option: "output",
        unit: "원",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "0px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "납부스케줄에서 수정 가능합니다.",
      },
      {
        key: "payment_day",
        label: "납부일",
        necessary: true,
        option: "payment_date",
        unit: "원",
        connectType: "long_term",
      },
    ],
    [
      {
        label: "선수금",
        option: "output",
        value: info.prepaid_amount === 0 ? "0" : info.prepaid_amount,
        unit: "원",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "-5px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "입금내역에서 수정 가능합니다.",
      },
      {
        label: "선수금 분할 처리",
        value:
          info.prepaid_num === 0
            ? "-"
            : info.prepaid_num === 1
            ? "일괄처리"
            : `분할처리 ${info.prepaid_num}회`,
        option: "output",
      },
    ],
    [
      {
        label: "보증금",
        value: depositMount,
        option: "output",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "-5px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "입금내역에서 수정 가능합니다.",
      },
      {
        label: "총 요금",
        value: parser.defaultParser(info.totalCost),
        option: "output",
        unit: "원",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "-10px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "(월 납부금 * 회차) + 선수금",
      },
    ],
    [
      {
        key: "alimtalk",
        label: "결제 안내 알림톡",
        inputType: "radio",
        option: "radio",
        necessary: true,
        radio: [
          { value: true, label: MESSAGE.SEND, match: "send" },
          { value: false, label: "미발송", match: "send_not", default: true },
        ],
        connectType: "long_term",
        tooltip: question,
        tooltipStyle: {
          marginLeft: "45px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "납부일 5일 전(영업일 기준) 고객에게 알림톡 자동발송",
      },
    ],
    [
      {
        key: "payment_method_type",
        label: "납부 방법",
        inputType: "select",
        option: "select",
        connectType: "long_term", // 렌트 종류에 따라 value 와 일치하는 데이터만 출력
        data: howToPayment,
        arrow: true,
        style: { width: "125px" },
      },
    ],
  ];
};

export const getPaymentInfoModifyTableData = () => {
  return [
    [
      {
        key: "alimtalk",
        label: "결제 안내 알림톡",
        necessary: true,
        type: "radio",
        radio: {
          data: [
            { value: 1, label: MESSAGE.SEND, match: "send" },
            { value: 0, label: "미발송", match: "not_send", default: true },
          ],
        },
        tooltip: question,
        tooltipStyle: {
          marginLeft: "45px",
          fontSize: "14px",
          fontWeight: "normal",
          top: "15px",
        },
        tooltipMessage: "납부일 5일 전(영업일 기준) 고객에게 알림톡 자동발송",
      },
    ],
    [
      {
        key: "payment_method_type",
        label: "납부 방법",
        type: "select",
        select: {
          data: howToPayment,
          arrow: true,
          style: { width: "150px" },
        },
        connectType: "long_term",
      },
    ],
  ];
};

export const getModifyDepositCost = (info) => {
  const paymentCostTypeDisabled =
    (info.paymentCostType === "대여료" && info.installment_no) ||
    info.paymentCostType === "선수금" ||
    info.paymentCostType === "보증금";

  return [
    [
      {
        key: "paymentDate",
        label: "입금일",
        type: "date",
        necessary: true,
      },
    ],
    [
      {
        key: "paymentCostType",
        label: "구분",
        inputType: paymentCostTypeDisabled ? "input" : "select",
        type: paymentCostTypeDisabled ? undefined : "select",
        [paymentCostTypeDisabled && "input"]: {
          type: "text",
          disabled: paymentCostTypeDisabled,
          style: { width: "150px" },
        },
        [!paymentCostTypeDisabled && "select"]: {
          data: info.paymentHistoryList,
          arrow: paymentCostTypeDisabled ? undefined : true,
          style: { width: "150px" },
        },
        disabled: paymentCostTypeDisabled,
        necessary: true,
      },
    ],
    [
      {
        key: "paymentCost",
        label: "입금액",
        input: {
          type: "text",
          comma: true,
          style: { width: "150px" },
        },
        necessary: true,
      },
    ],
    [
      {
        key: "paymentMethodId",
        label: "입금경로",
        select: {
          arrow:
            Array.isArray(info.paymentMethodList) &&
            info.paymentMethodList.length > 1,
          data: info.paymentMethodList,
          style: { width: 300 },
        },
        type:
          Array.isArray(info.paymentMethodList) &&
          info.paymentMethodList.length > 0
            ? "select"
            : "output",
        arrow:
          Array.isArray(info.paymentMethodList) &&
          info.paymentMethodList.length > 1,
        inputType: "select",
        option:
          Array.isArray(info.paymentMethodList) &&
          info.paymentMethodList.length > 0
            ? "select"
            : "output",
        style: { maxWidth: 250 },
        outputBtn: {
          label: "선택해 주세요.",
          style: {
            minWidth: "150px",
            maxWidth: "250px",
            border: "1px solid #dddddd",
            height: "28px",
            textAlign: "center",
            fontSize: 12,
            backgroundColor: "#fff",
            color: "black",
          },
          func: () => {
            if (
              confirm(
                "입금경로는 사업자설정>입금경로관리 에서 설정하실 수 있습니다.\n지금 설정하시겠습니까?"
              )
            ) {
              location.href = "/businessManagement/setting/depositPath?page=1";
            } else {
            }
          },
        },
      },
    ],
    [
      {
        key: "memo",
        label: MESSAGE.ESPECIAL_POINT,
        type: "textarea",
        style: { height: 120 },
        maxByte: 120,
        placeholder: "특이사항이 있을 경우 입력 해주세요.",
      },
    ],
  ];
};

//// 계약서 상세
export const getContractPasswordTableData = (info, btnFunc) => {
  return [
    [
      {
        label: "계약서 번호",
        value: parser.defaultParser(String(info.contractId)),
        option: "output",
      },
      {
        label: info.label,
        value: parser.defaultParser(String(info.password)),
        option: "output",
        btn: MESSAGE.VIEW_CONTRACT,
        btnFunc,
      },
    ],
  ];
};

export const getContractBasicInfoTableData = (info) => {
  return [
    [
      {
        label: "업체명",
        value: parser.defaultParser(info.rent_company_name),
        option: "output",
      },
      {
        label: "연락처",
        value: parser.contractParser(info.rent_company_contact),
        option: "output",
      },
    ],
    [
      {
        label: "대여 차종",
        value: parser.defaultParser(info.rent_car_name),
        option: "output",
      },
      {
        label: "차량번호",
        value: parser.defaultParser(info.rent_car_number),
        option: "output",
      },
      {
        label: "유종",
        value: parser.defaultParser(info.oil_type),
        option: "output",
        flex: 2,
      },
    ],
    [
      {
        label: "배차 유류량",
        value: parser.gasChargeParser(info.gas_charge),
        option: "output",
      },
      {
        label: "반납 유류량",
        value: parser.gasChargeParser(info.return_gas_charge),
        option: "output",
      },
    ],
    [
      {
        label: "배차 시 km",
        value: parser.drivenDistanceParser(info.driven_distance),
        option: "output",
      },
      {
        label: "반납 시 km",
        value: parser.drivenDistanceParser(info.driven_distance_upon_return),
        option: "output",
      },
    ],
  ];
};

export const getCustomerInfoTableData = (info, buttonFunc) => {
  let changeButton = null;

  if (info.canModify) {
    if (info.is_corporation) changeButton = "개인 전환";
    else changeButton = "법인 전환";
  }

  return [
    [
      {
        label: MESSAGE.CUSTOMER_NAME,
        value: parser.defaultParser(info.customer_name),
        option: "output",
        flex: 1,
      },
      {
        label: "고객구분",
        value: info.is_corporation ? "법인" : "개인",
        option: "output",
        flex: 1,
        btn: changeButton,
        btnFunc: info.is_corporation
          ? buttonFunc.changeToPersonal
          : buttonFunc.openCorporationInfoModal,
      },
      {
        label: "고객 연락처",
        value: parser.contractParser(info.customer_contact),
        option: "output",
        flex: 2,
      },
    ],
    [
      {
        label: "생년월일",
        value: info.customer_id_number1
          ? `${info.customer_id_number1}-${info.customer_id_number2}`
          : "",
        option: "output",
      },
      {
        label: "주소",
        value: parser.defaultParser(info.customer_address),
        option: "output",
      },
    ],
  ];
};

export const getContractInsuranceInfoTableData = (info) => {
  return [
    [
      {
        label: "보험 가입 연령",
        value: parser.insuranceJoinAgeParser(info.insurance_age),
        option: "output",
      },
      {
        label: MESSAGE.SELF_INSURANCE,
        value: parser.selfCarInsuranceParser(info.self_car_insurance),
        option: "output",
      },
    ],
    [
      {
        label: "자차 한도(만원)",
        value: parser.insuranceFeeParser(info.self_car_insurance_limit),
        option: "output",
      },
      {
        label: MESSAGE.SELF_CAR_INDEMNFICATION_COST,
        value: parser.insuranceFeeParser(info.self_car_indemnfication_fee),
        option: "output",
      },
    ],
    [
      {
        label: MESSAGE.INSURANCE_OPPONENT_LIMIT,
        value: parser.insuranceFeeParser(info.insurance_person_limit),
        option: "output",
      },
      {
        label: "대인 면책금(인당)",
        value: parser.insuranceFeeParser(info.people_indemnfication_fee),
        option: "output",
      },
    ],
    [
      {
        label: MESSAGE.INSURANCE_OBJECT_LIMIT,
        value: parser.insuranceFeeParser(info.insurance_object_limit),
        option: "output",
      },
      {
        label: "대물 면책금(건당)",
        value: parser.insuranceFeeParser(info.object_indemnfication_fee),
        option: "output",
      },
    ],
    [
      {
        label: MESSAGE.INSURANCE_ONESELF_LIMIT,
        value: parser.insuranceFeeParser(info.insurance_self_limit),
        option: "output",
      },
      {
        label: MESSAGE.ONESELF_INDEMNFICATION_COST,
        value: parser.insuranceFeeParser(info.self_indemnification_fee),
        option: "output",
      },
    ],
  ];
};

export const getContractCustomerInfoTableData = (info) => {
  return [
    [
      {
        label: MESSAGE.CUSTOMER_NAME,
        value: parser.defaultParser(info.customer_name),
        option: "output",
      },
      {
        label: "고객 연락처",
        value: parser.contractParser(info.customer_contact),
        option: "output",
      },
    ],
    [
      {
        label: "생년월일",
        value: parser.defaultParser(
          info.customer_id_number2
            ? `${info.customer_id_number1}-${info.customer_id_number2}`
            : info.customer_id_number1
        ),
        option: "output",
      },
      {
        label: "주소",
        value: parser.defaultParser(info.customer_address),
        option: "output",
      },
    ],
    [
      "column",
      [
        {
          label: "면허번호",
          value: parser.defaultParser(info.customer_driver_license),
          option: "output",
        },
        {
          label: "면허 종류",
          value: parser.defaultParser(info.customer_driver_type),
          option: "output",
        },
        {
          label: "만기일",
          value: parser.defaultParser(info.customer_license_limit),
          option: "output",
        },
      ],
      {
        label: "운전자서명",
        value: info.signature_image ? `${s3}${info.signature_image}` : null,
        valueType: "img",
        imgStyle: { width: "100px", height: "100px" },
        option: "output",
        column: 3,
      },
    ],
  ];
};

export const getLicenseButtonName = (info, isSecond) => {
  //defaultInfo-> 작성당시의 초기상태,초기상태에따라 진위여부 했는지 안했는지를 판단한다.
  const init_license_certificated = isSecond
    ? info.defaultInfo?.second_driver_license_certificated
    : info.defaultInfo.license_certificated;
  const license_certificated = isSecond
    ? info.second_driver_license_certificated
    : info.license_certificated;
  const license_certificated_code = isSecond
    ? info.second_driver_license_certificated_code
    : info.license_certificated_code;
  const isSuccess = ["00", "02", "03", "04", "11", "12", "13", "14"].includes(
    license_certificated_code
  );

  if (init_license_certificated === true) {
    //작성시 진위여부조회 한것
    if (license_certificated) {
      if (!isSecond) {
        //제1운전자
        if (isSuccess) return "조회완료";

        return "수정";
      }

      //제2운전자
      if (isSuccess) return "초기화";

      return "수정";
    }

    return "면허 진위 조회";
  }

  ///작성시 진위여부조회 하지 않은것
  if (license_certificated) {
    if (!isSecond) {
      //제1운전자
      if (isSuccess) return "초기화";

      return "수정";
    }

    //제2운전자
    if (isSuccess) return "초기화";

    return "수정";
  }

  return "면허 진위 조회";
};

export const modifyContractInsuranceInfoTableData = (
  info,
  saveLicenseResult,
  store
) => {
  const licenseCheckBtnName = getLicenseButtonName(info);

  return [
    [
      {
        key: "customer_name",
        label: MESSAGE.CUSTOMER_NAME,
        inputType: "text",
        disabled: info.license_certificated,
        style: { width: inputWidth },
      },

      {
        key: "customer_contact",
        label: "고객연락처",
        inputType: "number",
        style: { width: inputWidth },
      },
    ],
    [
      {
        key: "customer_id_number",
        label: "생년월일",
        inputType: "number",
        placeholder: "-",
        option: "double",
        maxLength: [6, 7],
        disabled: info.license_certificated,
      },
      {
        key: "customer_address",
        label: "주소",
        option: "address",
        inputType: "text",
      },
    ],
    [
      "column",
      [
        {
          key: "customer_driver_license",
          label: "면허 번호",
          inputType: "text",
          disabled: info.license_certificated,
          style: { width: inputWidth },
        },
        {
          key: "customer_driver_type",
          label: "면허 종류",
          inputType: "select",
          option: "select",
          data: driver_type,
          arrow: true,
          disabled: info.license_certificated,
          btn: licenseCheckBtnName,
          btnStyle:
            licenseCheckBtnName === "조회완료"
              ? { backgroundColor: "#bebdbd", border: "none", margin: "0 5px" }
              : { margin: "0 5px" },
          btnFunc: async () => {
            switch (licenseCheckBtnName) {
              case "수정":
                saveLicenseResult({
                  license_certificated: false,
                  license_certificated_code: null,
                });
                break;
              case "초기화":
                if (confirm("면허 정보를 초기화하시겠습니까?")) {
                  saveLicenseResult("clear");
                }

                break;
              case "면허 진위 조회": //do not use
                saveLicenseResult(
                  await contractUtils.checkLicense("customer", info, store)
                );
                break;
              default:
                break;
            }
          },
        },
        {
          key: "customer_license_limit",
          label: "만기일",
          inputType: "text",
          style: { width: inputWidth },
        },
      ],
      {
        label: "운전자서명",
        value: info.signature_image ? `${s3}${info.signature_image}` : null,
        valueType: "img",
        imgStyle: { width: "100px", height: "100px" },
        option: "output",
        column: 3,
      },
    ],
  ];
};

export const getSecondDriverInfoTableData = (info) => {
  return [
    [
      {
        label: "운전자명",
        value: parser.defaultParser(info.second_driver_name),
        option: "output",
      },
      {
        label: "연락처",
        value: parser.contractParser(info.second_driver_contact),
        option: "output",
      },
    ],
    [
      {
        label: "생년월일",
        value: parser.defaultParser(
          info.second_driver_id_number2
            ? `${info.second_driver_id_number1}-${info.second_driver_id_number2}`
            : info.second_driver_id_number1
        ),
        option: "output",
      },
      {
        label: "주소",
        value: parser.defaultParser(info.second_driver_address),
        option: "output",
      },
    ],
    [
      {
        label: "면허번호",
        value: parser.defaultParser(info.second_driver_license),
        option: "output",
      },
      {
        label: "면허 종류",
        value: parser.defaultParser(info.second_driver_type),
        option: "output",
      },
      {
        label: "만기일",
        value: parser.defaultParser(info.second_driver_license_limit),
        option: "output",
      },
    ],
  ];
};

export const getETCMemoInfoTableData = (info) => {
  return [
    [
      {
        label: "기타",
        value: parser.defaultParser(info.value),
        option: "output",
      },
    ],
  ];
};

export const modifySecondDriverInfoTableData = (
  info,
  saveLicenseResult,
  store
) => {
  const licenseCheckBtnName = getLicenseButtonName(info, "second_driver");

  return [
    [
      {
        key: "second_driver_name",
        label: "제 2운전자명",
        disabled: info.second_driver_license_certificated,
        inputType: "text",
        necessary: true,
        style: { width: inputWidth },
      },
      {
        key: "second_driver_contact",
        label: "제 2운전자 연락처",
        inputType: "number",
        maxLength: 11,
        necessary: true,
        style: { width: inputWidth },
      },
    ],
    [
      {
        key: "second_driver_id_number",
        label: "생년월일",
        inputType: "number",
        disabled: info.second_driver_license_certificated,
        placeholder: "-",
        option: "double",
        maxLength: [6, 7],
        necessary: true,
      },
      {
        key: "second_driver_address",
        label: "주소",
        option: "address",
        inputType: "text",
        necessary: true,
      },
    ],
    [
      {
        key: "second_driver_license",
        label: "면허 번호",
        disabled: info.second_driver_license_certificated,
        inputType: "text",
        style: { width: inputWidth },
      },
      {
        key: "second_driver_license_limit",
        label: "만기일",
        inputType: "text",
      },
    ],
    [
      {
        key: "second_driver_type",
        label: "면허 종류",
        inputType: "select",
        option: "select",
        data: driver_type,
        arrow: true,
        listView: "up",
        btn: licenseCheckBtnName,
        btnStyle:
          licenseCheckBtnName === "조회완료"
            ? { backgroundColor: "#bebdbd", border: "none", margin: "0 5px" }
            : { margin: "0 5px" },
        btnFunc: async () => {
          switch (licenseCheckBtnName) {
            case "수정":
              saveLicenseResult({
                second_driver_license_certificated: false,
                second_driver_license_certificated_code: null,
              });
              break;
            case "초기화":
              if (confirm("면허 정보를 초기화하시겠습니까?")) {
                saveLicenseResult("clear");
              }

              break;
            case "면허 진위 조회": //do not use
              const result = await contractUtils.checkLicense(
                "second_driver",
                info,
                store
              );

              saveLicenseResult(result);
              break;
            default:
              break;
          }
        },
      },
    ],
  ];
};

export const changeCarInfoTableData = (info) => {
  return [
    [
      {
        key: "delivered_date",
        label: "배차일시",
        value: parser.defaultParser(info.delivered_date),
        type: "output",
      },
    ],
    [
      {
        key: "expect_return",
        label: "반납 일시",
        option: "fullDate",
        optionHeight: "165px", // 시 select option height
        necessary: true,
        calcDate: false,
        connectType: info.rentType,
        calendarOption: "end",
        type: "fullDate",
      },
    ],
    [
      {
        key: "delivery_gas_charge",
        label: "배차 유류량",
        input: {
          type: "text",
          disabled: true,
          placeholder: "-",
          style: { width: inputWidth },
        },
      },
      {
        key: "return_gas_charge",
        label: "반납 유류량",
        necessary: true,
        unit: "%",
        input: {
          type: "number",
          placeholder: "1~100 사이의 숫자 입력",
          style: { width: "73%" },
        },
        parser: (value) => {
          if (value >= 1 && value <= 999) return value;

          return "";
        },
      },
    ],
    [
      {
        key: "delivery_distance",
        label: "배차 시 km",
        input: {
          type: "text",
          disabled: true,
          style: { width: inputWidth },
        },
      },
      {
        key: "return_distance",
        label: "반납 시 km",
        input: {
          comma: true,
          type: "number",
          style: { width: inputWidth },
          placeholder: "예) 1000",
        },
      },
    ],
  ];
};

export const connectChangeCarInfoTableData = (info) => {
  return [
    [
      {
        label: "배차일시",
        value: parser.defaultParser(info.delivered_date),
        type: "output",
      },
    ],
    [
      {
        key: "expect_return",
        label: "반납 일시",
        type: "fullDate",
        optionHeight: "165px", // 시 select option height
        necessary: true,
        calcDate: false,
        connectType: info.rentType,
        calendarOption: "end",
      },
    ],
    [
      {
        key: "delivery_gas_charge",
        label: "배차 유류량",
        input: {
          type: "text",
          disabled: true,
          placeholder: "-",
          style: { width: 110 },
        },
        lowerFlex: true,
      },
      {
        key: "return_gas_charge",
        label: "반납 유류량",
        necessary: true,
        input: {
          type: "number",
          style: { width: "150px" },
          placeholder: "1~100 사이의 숫자 입력",
        },
        unit: "%",
        parser: (value) => {
          if (value >= 1 && value <= 999) return value;

          return "";
        },
        addText: `현재 유류량 : ${
          info.fuelRemainPct ? `${info.fuelRemainPct}%` : "-"
        }`,
        textStyle: {
          fontSize: "12px",
          color: "#282828",
          marginLeft: "10px",
          padding: 0,
        },
        unitInInputComponent: true,
      },
    ],
    [
      {
        key: "delivery_distance",
        label: "배차 시 km",
        input: {
          type: "text",
          disabled: true,
          style: { width: 110 },
        },
        lowerFlex: true,
      },
      {
        key: "return_distance",
        label: "반납 시 km",
        input: {
          comma: true,
          type: "number",
          style: { width: 110 },
          placeholder: "예) 1000",
        },
        addText: `현재 주행거리 : ${
          info.totalMileage ? `${costComma(info.totalMileage)}km` : "-"
        }`,
        textStyle: {
          fontSize: "12px",
          color: "#282828",
          marginLeft: "10px",
          padding: 0,
        },
      },
    ],
  ];
};

export const setReturnInfoTableData = (info) => {
  return [
    [
      {
        key: "expect_return",
        label: "반납 일시",
        type: "fullDate",
        optionHeight: "185px", // 시 select option height
        necessary: true,
        calcDate: false,
        connectType: info.rentType,
        calendarOption: "end",
      },
    ],
    [
      {
        key: "delivery_gas_charge",
        label: "배차 유류량",
        input: {
          type: "text",
          disabled: true,
          placeholder: "-",
          style: { width: inputWidth },
        },
      },
      {
        key: "return_gas_charge",
        label: "반납 유류량",
        necessary: true,
        input: {
          type: "number",
          placeholder: "1~100 사이의 숫자 입력",
          style: { width: "73%" },
        },
        unit: "%",
        parser: (value) => {
          if (value >= 1 && value <= 999) return value;

          return "";
        },
      },
    ],
    [
      {
        key: "delivery_distance",
        label: "배차 시 km",
        input: {
          type: "text",
          disabled: true,
          style: { width: inputWidth },
        },
      },
      {
        key: "return_distance",
        label: "반납 시 km",
        input: {
          type: "number",
          style: { width: inputWidth },
        },
      },
    ],
  ];
};

export const connectSetReturnInfoTableData = (info) => {
  return [
    [
      {
        key: "expect_return",
        label: "반납 일시",
        type: "fullDate",
        optionHeight: "185px", // 시 select option height
        necessary: true,
        calcDate: false,
        connectType: info.rentType,
        calendarOption: "end",
      },
    ],
    [
      {
        key: "delivery_gas_charge",
        label: "배차 유류량",
        input: {
          type: "text",
          disabled: true,
          placeholder: "-",
          style: { width: "73%" },
        },
        lowerFlex: true,
      },
      {
        key: "return_gas_charge",
        label: "반납 유류량",
        necessary: true,
        input: {
          type: "number",
          placeholder: "1~100 사이의 숫자 입력",
          style: { width: "150px" },
        },
        unit: "%",
        parser: (value) => {
          if (value >= 1 && value <= 999) return value;

          return "";
        },
        addText: `현재 유류량 : ${
          info.fuelRemainPct ? `${info.fuelRemainPct}%` : "-"
        }`,
        textStyle: {
          fontSize: "12px",
          color: "#282828",
          marginLeft: "10px",
          padding: 0,
        },
        electricCar: info.fuelType === "전기",
        unitInInputComponent: true,
      },
    ],
    [
      {
        key: "delivery_distance",
        label: "배차 시 km",
        input: {
          type: "text",
          disabled: true,
          style: { width: "73%" },
        },
        lowerFlex: true,
      },
      {
        key: "return_distance",
        label: "반납 시 km",
        input: {
          type: "number",
          comma: true,
          style: { width: "150px" },
        },
        addText: `현재 주행거리 : ${
          info.totalMileage ? `${costComma(info.totalMileage)}km` : "-"
        }`,
        textStyle: {
          fontSize: "12px",
          color: "#282828",
          marginLeft: "10px",
          padding: 0,
        },
      },
    ],
  ];
};

export const corporationTableData = (info) => {
  return [
    [
      {
        label: "법인명",
        value: info.corporation_name,
        option: "output",
      },
      {
        label: "사업자번호",
        value: info.business_number,
        option: "output",
      },
    ],
    [
      {
        label: "법인번호",
        value: info.corporation_number ? info.corporation_number : null,
        option: "output",
      },
      {
        label: "주소",
        value: info.address,
        option: "output",
      },
    ],
    [
      {
        label: "담당자명",
        value: info.manager_name,
        option: "output",
      },
      {
        label: "담당자 연락처",
        value: parser.contractParser(info.manager_contact),
        option: "output",
      },
    ],
  ];
};

export const modifyCorporationTableData = () => {
  return [
    [
      {
        key: "corporation_name",
        label: "법인명",
        inputType: "text",
        necessary: true,
        style: { width: "150px" },
      },
      {
        key: "business_number",
        label: "사업자번호",
        inputType: "number",
        maxLength: 10,
        style: { width: "150px" },
      },
    ],
    [
      {
        key: "corporation_number",
        label: "법인번호",
        inputType: "number",
        placeholder: "-",
        option: "double",
        style: { width: "100px" },
        maxLength: [6, 7],
      },
      {
        key: "address",
        label: "주소",
        option: "address",
        inputType: "text",
      },
    ],
    [
      {
        key: "manager_name",
        label: "담당자명",
        inputType: "text",
        style: { width: "150px" },
      },
      {
        key: "manager_contact",
        label: "담당자 연락처",
        inputType: "number",
        placeholder: MESSAGE.PLACEHOLDER_CONTRACT,
      },
    ],
  ];
};

export const returnChangedExpectedReturnTime = (
  dropOffTime,
  updatedDropOffTime
) => {
  const dropOff = dayjs(dropOffTime);
  const updatedDropOff = dayjs(updatedDropOffTime);
  const changeDay = updatedDropOff.diff(dropOff, "day");
  const changeHour = updatedDropOff.diff(dropOff, "hour");
  const changeMinutes = updatedDropOff.diff(dropOff, "minute");

  const changeTime =
    `${changeDay !== 0 ? `${Math.abs(changeDay)}일 ` : ""}` +
    `${changeHour !== 0 ? `${Math.abs(changeHour) % 24}시간 ` : ""}` +
    `${
      Math.abs(changeMinutes) % 60 !== 0
        ? `${Math.abs(changeMinutes) % 60}분`
        : ""
    }`;

  return {
    time: changeTime,
    type: changeMinutes > 0 ? "연장" : "단축",
  };
};
