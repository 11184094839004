import dayjs from 'dayjs';
import { CarGroupOptionState } from 'interface/carGroup';
import { StringDefault } from 'interface/utils';
import find from 'lodash/find';
import AuthStore from 'stores/AuthStore';
import CarOptionStore, { ETC_LABEL, ETC_STATE_NAME } from 'stores/CarOptionStore';

import oilTypeData from '../constants/oilTypeData.json';
import { carNumRegExp } from '../constants/regularExpression';
import type { EditCarGroupAction, EditCarGroupState } from '../interface/carGroup/carGroupEdit';
import type { Action, CarGroupRegisterState } from '../interface/carGroup/CarGroupRegister';
import {
  BodyStyle,
  CarImageType,
  CarOptionState,
  CarSize,
  OptionObject,
} from '../interface/carStatus';

export const carStatusData = [
  { key: 'waiting', value: '대기' },
  { key: 'booking', value: '예약' },
  { key: 'employee_use', value: '직원' },
  { key: 'using', value: '배차' },
  { key: 'repair', value: '수리' },
  { key: 'is_day_off', value: '휴무' },
];

export const searchContractUtil = (path: any, data: any, props: any) => {
  const serializeForm: any = { page: props.router.query.page };

  if (data.searchState) {
    serializeForm.state = data.searchState;
  }

  if (data.searchValue) {
    serializeForm.value = encodeURIComponent(data.searchValue);
  }

  props.router.push(
    {
      pathname: path,
      query: serializeForm,
    },
    undefined,
    { shallow: true }
  );
};

export const searchManagementUtil = (path: any, data: any) => {
  const serializeForm: any = { page: 1 };
  const state = data.state;
  const props = data.props;

  if (state.searchValue) {
    serializeForm.value = state.searchValue;
  }

  if (state.carStatus) {
    serializeForm.state = state.carStatus;
  }

  if (state.businessId) {
    serializeForm.business_id = state.businessId;
  }

  if (state.carRegisterStatus && state.carRegisterStatus !== 'all') {
    serializeForm.carRegisterStatus = state.carRegisterStatus;
  }

  if (state.carType) {
    serializeForm.carType = state.carType;
  }

  if (state.carSize) {
    serializeForm.carSize = state.carSize;
  }

  if (state.listNum) {
    serializeForm.listNum = state.listNum;
  }

  if (state.onlyNoGroup) {
    serializeForm.onlyNoGroup = state.onlyNoGroup;
  }

  if (state.carGroupId) {
    serializeForm.carGroupId = state.carGroupId;
  }

  // serializeForm.tab = props.router.query.tab;
  props.router.push(
    {
      pathname: path,
      query: serializeForm,
    },
    undefined,
    { shallow: true }
  );
};

export const getCarColors = [
  { label: '화이트', value: '화이트' },
  { label: '블랙', value: '블랙' },
  { label: '실버', value: '실버' },
  { label: '베이지', value: '베이지' },
  { label: '레드', value: '레드' },
  { label: '블루', value: '블루' },
  { label: '핑크', value: '핑크' },
  { label: '브라운', value: '브라운' },
  { label: '그레이', value: '그레이' },
  { label: '옐로우', value: '옐로우' },
  { label: '와인', value: '와인' },
  { label: '네이비', value: '네이비' },
  { label: '기타', value: '기타' },
];

export const getTableDatas = (tableClass: any, carOptionStore: CarOptionStore) => {
  const {
    carType,
    brandList,
    modelList,
    carGroupList,
    group,
    brand,
    model,
    carName,
    carNumber,
    carNumbers,
    businessId,
    carCC,
    businessSelectData,
    carAge,
    registerDate,
    oilType,
    carRegisterImage,
    capacity,
    carColor,
    lease_contract_file,
    disposeInfoCompany,
    disposeInfoCost,
    disposeInfoDate,
    disposeInfoMemo,
    isDispose,
    submodel,
    submodelList,
    drivingYears,
    insuranceAge,
    carGroupNames,
    searchValue,
    options,
    licenseType,
  } = tableClass.state;

  const carRegisterInfo = [
    tableClass.isEdit &&
      !isDispose && {
        label: '차량 그룹',
        necessary: false,
        type: 'viewWithButton',
        value: group
          ? carGroupNames.find((item: { id: number; name: string }) => item.id === group)?.name
          : null,
        disabled: Boolean(group),
        placeholder: '그룹을 연결해 주세요.',
        onChange: () =>
          group ? tableClass.disconnectCarGroupTemp() : tableClass.openCarGroupConnectionModal(),
      },
    !tableClass.isEdit && {
      label: '차량 그룹',
      necessary: !tableClass.isEdit,
      type: 'searchSelect',
      searchValue,
      onSearch: (v: string) => tableClass.setData('searchValue', v),
      value: group,
      disabled: tableClass.isCarGroupId,
      option: carGroupList,
      placeholder: '차량 그룹을 선택해 주세요.',
      onChange: (v: number) => tableClass.setData('group', v),
      width: 415,
      height: 42,
    },
    {
      label: '차량 구분',
      necessary: !tableClass.isEdit,
      type: tableClass.isEdit || group ? 'text' : 'divide',
      value: carType,
      disabled: true,
      option: [
        { label: '국산차', value: '국산차' },
        { label: '수입차', value: '수입차' },
      ],
      onChange: (v: string) => {
        const defaultBrand = v === '국산차' ? '현대' : '벤츠';
        const defaultModel = v === '국산차' ? 1 : 8;

        tableClass.setData('carType', v);
        tableClass.setData('brand', defaultBrand);
        tableClass.setData('model', {
          key: '',
          value: '',
          type: '',
        });
        tableClass.getBrandList(v);
        tableClass.getModelList(defaultModel);
      },
    },
    (tableClass.isEdit || group) && [
      {
        label: '브랜드',
        necessary: !tableClass.isEdit,
        type: 'text',
        value: brand,
        disabled: true,
        option: brandList,
        placeholder: '브랜드를 선택해 주세요.',
        onChange: (v: any) => {
          tableClass.setData('brand', v.label);
          tableClass.setData('brandId', v.value);
          tableClass.getModelList(v.value);
          tableClass.setData('model', {
            key: '',
            value: v.label === '기타브랜드' ? v.value : '',
            type: '',
          });
        },
      },
      {
        label: '모델',
        necessary: !tableClass.isEdit,
        type: 'text',
        value: model.label,
        disabled: true,
        option: modelList,
        placeholder: '선택',
        onChange: (v: OptionObject) => tableClass.setData('model', v),
      },
    ],
    !(tableClass.isEdit || group) && [
      {
        label: '브랜드',
        necessary: !tableClass.isEdit,
        type: tableClass.isEdit || group ? 'text' : 'brand',
        value: brand,
        disabled: !carType || tableClass.isEdit || group,
        option: brandList,
        placeholder: '브랜드를 선택해 주세요.',
        listWrapStyle: { width: '415px', borderTop: '1px solid #d7d7d7', marginTop: '5px' },
        onChange: (v: any) => {
          tableClass.setData('brand', v.label);
          tableClass.setData('brandId', v.value);
          tableClass.getModelList(v.value);
          tableClass.setData('model', {
            key: '',
            value: v.label === '기타브랜드' ? v.value : '',
            type: '',
          });
        },
      },
      {
        label: '모델',
        necessary: !tableClass.isEdit,
        type: tableClass.isEdit || group ? 'text' : 'model',
        value: tableClass.isEdit || group ? model.label : model.value,
        disabled: String(brand).includes('기타') || tableClass.isEdit || group,
        option: modelList,
        placeholder: '선택',
        listWrapStyle: { width: '415px', position: 'relative', right: '275px', marginTop: '5px' },
        onChange: (v: OptionObject) => tableClass.setData('model', v),
      },
    ],
    !(tableClass.isEdit && !group) && {
      label: '서브모델',
      necessary: !tableClass.isEdit,
      type: tableClass.isEdit || group || !model.value ? 'text' : 'select',
      value: tableClass.isEdit || group || !model.value ? submodel.label : submodel.value,
      disabled: String(brand).includes('기타') || tableClass.isEdit || group || !model.value,
      option: submodelList,
      placeholder: model.value ? '선택' : '모델을 선택해 주세요',
      onChange: (v: OptionObject) => tableClass.setData('submodel', { label: '', value: v }),
    },
    {
      label: '차량명',
      necessary: !(tableClass.isEdit && isDispose),
      value: carName,
      disabled: tableClass.isEdit && isDispose,
      placeholder: '업체에서 관리하기 쉽게 차량명을 등록하세요.',
      onChange: (v: string) => tableClass.setData('carName', v),
    },
    {
      label: '차량번호',
      disabled: tableClass.isEdit,
      necessary: !tableClass.isEdit,
      type: tableClass.isEdit ? 'text' : 'enterPushInput',
      valueArray: !tableClass.isEdit && carNumbers,
      value: tableClass.isEdit && carNumber,
      validateRex: carNumRegExp,
      limitLength: 30,
      alertMsg: '최대 30대까지 한번에 등록할 수 있습니다.',
      duplicateMsg: '중복되는 차량 번호가 있습니다.',
      placeholder: '서울01호1234, 123호1234',
      onChange: (v: string[]) => tableClass.setData('carNumbers', v),
      desc:
        !(isDispose || tableClass.isEdit) &&
        '* 번호 사이에 콤마(,)를 입력하시면 여러 차량을 동시에 등록하실 수 있습니다.\n입력 후 엔터를 눌러주세요. (최대 30대)',
    },
    {
      label: '사업자 연결',
      necessary: !(tableClass.isEdit && isDispose),
      type: tableClass.isEdit && isDispose ? 'text' : 'select',
      value:
        tableClass.isEdit && isDispose
          ? businessSelectData.find((item: any) => item.value === businessId)?.label
          : businessId,
      disabled: isDispose,
      placeholder: '선택',
      option: businessSelectData,
      onChange: (v: string) => tableClass.setData('businessId', v),
    },
    tableClass.isEdit &&
      typeof lease_contract_file === 'string' && {
        label: '관리 위탁 계약서',
        type: 'file',
        btnTitle: '관리 위탁 계약서 보기',
        value: lease_contract_file,
        onChange: (v: string) => tableClass.setData('lease_contract_file', v),
      },
    (() => {
      if (carNumbers?.length > 1) {
        return {
          label: '차량 등록증',
          necessary: false,
          type: 'disabledFile',
          value: carRegisterImage,
          disabled: true,
          inputValue: '차량 등록증 파일을 첨부해 주세요.',
          placeholder: '차량 등록증 파일을 첨부해 주세요.',
          onChange: (v: CarImageType) => tableClass.setData('carRegisterImage', v),
          desc: '* 여러 차량 등록 시, 차량 상세 페이지에서 차량 등록증을 첨부하실 수 있습니다.',
        };
      }

      return {
        label: '차량 등록증',
        necessary: false,
        type: 'file',
        value: carRegisterImage,
        placeholder: '차량 등록증 파일을 첨부해 주세요.',
        onChange: (v: CarImageType) => tableClass.setData('carRegisterImage', v),
        desc: '<div style="color: red;">차량등록증을 등록해야 보험사 청구가 가능합니다.</div>\n파일은 5MB 이하 jpg / jpeg / png / pdf 확장자만 등록 가능합니다. ',
      };
    })(),
  ];

  const carOptionNames = carOptionStore.getCarOptionNamesByState(options);
  const carGroupOptionNames = carOptionStore.getCarGroupOptionNamesByState(options);
  const campingCarOptionNames = carOptionStore.getCampingCarOptionNamesByState(options);

  const isCampingCar = model.type === 'suv' && model.size === 'H';

  const carRegisterEtcInfo = [
    [
      {
        label: '연식',
        necessary: !(tableClass.isEdit && isDispose),
        type: 'number',
        value: carAge,
        disabled: tableClass.isEdit && isDispose,
        placeholder: '2020',
        maxLength: 4,
        onChange: (v: number) => tableClass.setData('carAge', v),
      },
      {
        label: '최초등록일',
        necessary: !(tableClass.isEdit && isDispose),
        value: registerDate,
        disabled: tableClass.isEdit && isDispose,
        placeholder: 'YYYYMMDD',
        type: 'number',
        maxLength: 8,
        onChange: (v: string) => tableClass.setData('registerDate', v),
      },
    ],
    [
      {
        label: '유종',
        necessary: !(tableClass.isEdit && isDispose) && !(tableClass.isEdit && group),
        type: (tableClass.isEdit && isDispose) || group ? 'text' : 'select',
        value: oilType,
        disabled: isDispose || group,
        placeholder: '선택',
        option: oilTypeData.map((i) => {
          return { label: i.text, value: i.value };
        }),
        onChange: (v: string) => {
          tableClass.setData('oilType', v);
          tableClass.setData('carCC', null);
        },
      },
      {
        label: '배기량(cc)',
        disabled:
          ['수소', '전기'].includes(oilType ? oilType : '') || (tableClass.isEdit && isDispose),
        necessary: !(tableClass.isEdit && isDispose),
        value: carCC,
        placeholder: '1998',
        type: 'number',
        maxLength: 4,
        onChange: (v: number) => tableClass.setData('carCC', v),
      },
    ],
    !(tableClass.isEdit && !group) && [
      {
        label: '운전 경력',
        disabled: tableClass.isEdit || group,
        necessary: !(tableClass.isEdit && isDispose) && !(tableClass.isEdit && group),
        type: 'inputWithText',
        inputType: 'number',
        value: drivingYears,
        placeholder: '2',
        maxLength: 1,
        addTextWithUnit: '년 이상',
        inputWidth: 50,
        onChange: (v: number) => tableClass.setData('drivingYears', v),
      },
      {
        label: '대여 가능 면허',
        necessary: !(tableClass.isEdit || isDispose),
        type: group ? 'text' : 'select',
        value: licenseType || driverLicenseOption[0].value,
        placeholder: '선택',
        option: driverLicenseOption,
        disabled: group,
        onChange: (value: string) => tableClass.setData('licenseType', value),
      },
    ],
    [
      {
        label: '차량 색상',
        type: isDispose ? 'text' : 'select',
        value: carColor,
        disabled: tableClass.isEdit && isDispose,
        placeholder: '선택',
        option: getCarColors,
        onChange: (v: string) => tableClass.setData('carColor', v),
      },
      {
        label: '승차 인원',
        necessary: !tableClass.isEdit || model.type === 'suv',
        value: capacity,
        disabled: (tableClass.isEdit && isDispose) || group,
        placeholder: '숫자를 입력해주세요.',
        type: 'number',
        maxLength: 2,
        onChange: (v: number) => tableClass.setData('capacity', v),
      },
    ],
    Boolean(tableClass.isEdit) &&
      !group && {
        label: '차량 옵션',
        type: 'checkbox',
        value: options,
        hide: isDispose,
        option: [
          ...carOptionStore
            .getCarOptions()
            .map(({ name, name_kor }) => ({ label: name_kor, value: name })),
          { label: ETC_LABEL, value: ETC_STATE_NAME },
        ],
        inputWidth: 104,
        placeholder: '예) 선루프, 전동시트, 4륜구동...',
        onChange: (key: keyof CarOptionState, value: boolean | string) => {
          tableClass.setData('options', { ...options, [key]: value });
          key === ETC_STATE_NAME && tableClass.setData(ETC_STATE_NAME, value);
        },
      },
    !tableClass.isEdit &&
      !group && {
        label: '차량 옵션',
        type: 'arrayView',
        value: '차량 그룹을 선택해주세요.',
      },

    group && {
      label: '차량 옵션',
      type: 'optionArrayView',
      value: carGroupOptionNames.length > 0 ? carGroupOptionNames.join(', ') : '옵션없음',
      desc: !isCampingCar
        ? '* 그룹이 연결된 차량의 옵션은 차량 그룹 상세에서 수정하실 수 있습니다.'
        : '',
    },

    group &&
      isCampingCar && {
        label: '캠핑카 옵션',
        type: 'optionArrayView',
        value: campingCarOptionNames.length > 0 ? campingCarOptionNames.join(', ') : '옵션없음',
        desc: '* 그룹이 연결된 차량의 옵션은 차량 그룹 상세에서 수정하실 수 있습니다.',
      },
    Boolean(tableClass.isEdit) &&
      !group && {
        label: '차량 옵션',
        type: 'arrayView',
        value: carOptionNames.length > 0 ? carOptionNames : '옵션없음',
      },
    {
      label: '보험 연령',
      type: group ? 'arrayView' : 'doubleButton',
      value: group
        ? insuranceAge === '21'
          ? '만 21세 이상'
          : '만 26세 이상'
        : tableClass.state.insuranceAge,
      option: [
        { label: '만 21세 이상', value: '21' },
        { label: '만 26세 이상', value: '26' },
      ],
      onChange: (value: string) => {
        tableClass.setData('insuranceAge', value);
      },
      disabled: isDispose,
    },
  ];

  const getCarDisposeInfo = (type: string) => {
    const typeText = type === 'trade' ? '매각' : '폐차';

    return [
      {
        label: `${typeText} 업체`,
        value: disposeInfoCompany,
        placeholder: `${typeText} 업체를 입력해주세요.`,
        maxLength: 20,
        onChange: (v: string) => tableClass.setData('disposeInfoCompany', v),
      },
      [
        {
          label: `${typeText}금액(원)`,
          type: 'number',
          value: disposeInfoCost,
          placeholder: '숫자를 입력해주세요.',
          comma: true,
          onChange: (v: number) => tableClass.setData('disposeInfoCost', v),
        },
        {
          label: `${typeText}일자`,
          type: 'date',
          necessary: true,
          value: disposeInfoDate,
          placeholder: 'YYYYMMDD',
          onChange: (v: any) =>
            tableClass.setData('disposeInfoDate', dayjs(v).format('YYYY-MM-DD')),
        },
      ],
      {
        label: '메모',
        type: 'textarea',
        value: disposeInfoMemo || '',
        keyName: 'disposeInfoMemo',
        dataObj: tableClass.state,
        placeholder: '메모를 입력해주세요',
        maxByte: 120,
        onChange: (k: string, v: string) => tableClass.setData(k, v),
      },
    ];
  };

  return {
    carRegisterInfo,
    carRegisterEtcInfo,
    getCarDisposeInfo,
  };
};

export const getOriginTableDatas = (tableClass: any, carOptionStore: CarOptionStore) => {
  const {
    carType,
    brandList,
    modelList,
    brand,
    model,
    carName,
    carNumbers,
    businessId,
    carCC,
    businessSelectData,
    carAge,
    registerDate,
    oilType,
    carRegisterImage,
    capacity,
    carColor,
    lease_contract_file,
    disposeInfoCompany,
    disposeInfoCost,
    disposeInfoDate,
    disposeInfoMemo,
    isDispose,
    options,
  } = tableClass.state;

  const carRegisterInfo = [
    {
      label: '차량 구분',
      necessary: !tableClass.isEdit,
      type: tableClass.isEdit ? 'text' : 'divide',
      value: carType,
      disabled: true,
      option: [
        { label: '국산차', value: '국산차' },
        { label: '수입차', value: '수입차' },
      ],
      onChange: (v: string) => {
        const defaultBrand = v === '국산차' ? '현대' : '벤츠';
        const defaultModel = v === '국산차' ? 1 : 8;

        tableClass.setData('carType', v);
        tableClass.setData('brand', defaultBrand);
        tableClass.setData('model', {
          key: '',
          value: '',
          type: '',
        });
        tableClass.getBrandList(v);
        tableClass.getModelList(defaultModel);
      },
    },
    {
      label: '브랜드',
      necessary: !tableClass.isEdit,
      type: tableClass.isEdit ? 'text' : 'brand',
      value: brand,
      disabled: !carType || tableClass.isEdit,
      option: brandList,
      placeholder: '브랜드를 선택해 주세요.',
      onChange: (v: any) => {
        tableClass.setData('brand', v.label);
        tableClass.setData('brandId', v.value);
        tableClass.getModelList(v.value);
        tableClass.setData('model', {
          key: '',
          value: v.label === '기타브랜드' ? v.value : '',
          type: '',
        });
      },
    },
    {
      label: '모델',
      necessary: !tableClass.isEdit,
      type: tableClass.isEdit ? 'text' : 'model',
      value: tableClass.isEdit ? model.label : model.value,
      disabled: String(brand).includes('기타') || tableClass.isEdit,
      option: modelList,
      placeholder: '모델을 선택해 주세요.',
      onChange: (v: OptionObject) => tableClass.setData('model', v),
    },
    {
      label: '차량명',
      necessary: !(tableClass.isEdit && isDispose),
      value: carName,
      disabled: tableClass.isEdit && isDispose,
      placeholder: '업체에서 관리하기 쉽게 차량명을 등록하세요.',
      onChange: (v: string) => tableClass.setData('carName', v),
    },
    {
      label: '차량번호',
      disabled: tableClass.isEdit,
      necessary: !tableClass.isEdit,
      type: 'text',
      value: carNumbers,
      placeholder: '서울01호1234, 123호1234',
      onChange: (v: string) => tableClass.setData('carNumbers', new Array(v)),
    },
    {
      label: '사업자 연결',
      necessary: !(tableClass.isEdit && isDispose),
      type: tableClass.isEdit && isDispose ? 'text' : 'select',
      value:
        tableClass.isEdit && isDispose
          ? businessSelectData.find((item: any) => item.value === businessId)?.label
          : businessId,
      disabled: isDispose,
      placeholder: '선택',
      option: businessSelectData,
      onChange: (v: string) => tableClass.setData('businessId', v),
    },
    [
      {
        label: '연식',
        necessary: !(tableClass.isEdit && isDispose),
        type: 'number',
        value: carAge,
        disabled: tableClass.isEdit && isDispose,
        placeholder: '2020',
        maxLength: 4,
        onChange: (v: number) => tableClass.setData('carAge', v),
      },
      {
        label: '최초등록일',
        necessary: !(tableClass.isEdit && isDispose),
        value: registerDate,
        disabled: tableClass.isEdit && isDispose,
        placeholder: 'YYYYMMDD',
        type: 'number',
        maxLength: 8,
        onChange: (v: string) => tableClass.setData('registerDate', v),
      },
    ],
    [
      {
        label: '유종',
        necessary: !(tableClass.isEdit && isDispose),
        type: tableClass.isEdit && isDispose ? 'text' : 'select',
        value: oilType,
        disabled: isDispose,
        placeholder: '선택해 주세요.',
        option: oilTypeData.map((i) => {
          return { label: i.text, value: i.value };
        }),
        onChange: (v: string) => {
          tableClass.setData('oilType', v);
          tableClass.setData('carCC', null);
        },
      },
      {
        label: '배기량(cc)',
        disabled:
          ['수소', '전기'].includes(oilType ? oilType : '') || (tableClass.isEdit && isDispose),
        necessary: !(tableClass.isEdit && isDispose),
        value: carCC,
        placeholder: '1998',
        type: 'number',
        maxLength: 4,
        onChange: (v: number) => tableClass.setData('carCC', v),
      },
    ],
    tableClass.isEdit &&
      typeof lease_contract_file === 'string' && {
        label: '관리 위탁 계약서',
        type: 'file',
        btnTitle: '관리 위탁 계약서 보기',
        value: lease_contract_file,
        onChange: (v: string) => tableClass.setData('lease_contract_file', v),
      },
    tableClass.isEdit && isDispose
      ? {
          label: '차량 등록증',
          type: 'fileView',
          btnTitle: '차량 등록증 보기',
          value: carRegisterImage ? `/carRegistration/${carRegisterImage}` : carRegisterImage,
          btnFunction: tableClass.download,
        }
      : {
          label: '차량 등록증',
          necessary: !(tableClass.isEdit && isDispose),
          type: 'file',
          value: carRegisterImage,
          placeholder: '차량 등록증 파일을 첨부해 주세요.',
          onChange: (v: CarImageType) => tableClass.setData('carRegisterImage', v),
          desc:
            !isDispose &&
            `<div style="color: red;"}}>차량등록증을 등록해야 보험사 청구가 가능합니다.</div>\n파일은 5MB 이하 jpg / jpeg / png / pdf 확장자만 등록 가능합니다. `,
        },
  ];

  const carOptionNames = carOptionStore.getCarOptionNamesByState(options);
  const carRegisterEtcInfo = [
    [
      {
        label: '차량색상',
        type: isDispose ? 'text' : 'select',
        value: carColor,
        disabled: tableClass.isEdit && isDispose,
        placeholder: '선택',
        option: getCarColors,
        onChange: (v: string) => tableClass.setData('carColor', v),
      },
      {
        label: '승차 인원',
        necessary: model?.type === 'suv',
        value: capacity,
        disabled: tableClass.isEdit && isDispose,
        placeholder: '숫자를 입력해주세요.',
        type: 'number',
        maxLength: 2,
        onChange: (v: number) => tableClass.setData('capacity', v),
      },
    ],
    {
      label: '차량 옵션',
      type: 'checkbox',
      value: tableClass.state.options,
      hide: isDispose,
      option: [
        ...carOptionStore
          .getCarOptions()
          .map(({ name, name_kor }) => ({ label: name_kor, value: name })),
        { label: ETC_LABEL, value: ETC_STATE_NAME },
      ],
      placeholder: '예) 선루프, 전동시트, 4륜구동...',
      onChange: (key: keyof CarOptionState, value: boolean | string) => {
        tableClass.setData('options', { ...options, [key]: value });
        key === ETC_STATE_NAME && tableClass.setData(ETC_STATE_NAME, value);
      },
    },
    {
      label: '차량 옵션',
      type: 'arrayView',
      value: carOptionNames.length > 0 ? carOptionNames.join(',') : '옵션없음',
    },
    {
      label: '보험 연령',
      type: 'doubleButton',
      value: tableClass.state.insuranceAge,
      option: [
        { label: '만 21세 이상', value: '21' },
        { label: '만 26세 이상', value: '26' },
      ],
      onChange: (value: string) => {
        tableClass.setData('insuranceAge', value);
      },
    },
  ];

  const getCarDisposeInfo = (type: string) => {
    const typeText = type === 'trade' ? '매각' : '폐차';

    return [
      {
        label: `${typeText} 업체`,
        value: disposeInfoCompany,
        placeholder: `${typeText} 업체를 입력해주세요.`,
        maxLength: 20,
        onChange: (v: string) => tableClass.setData('disposeInfoCompany', v),
      },
      [
        {
          label: `${typeText}금액(원)`,
          type: 'number',
          value: disposeInfoCost,
          placeholder: '숫자를 입력해주세요.',
          comma: true,
          onChange: (v: number) => tableClass.setData('disposeInfoCost', v),
        },
        {
          label: `${typeText}일자`,
          type: 'date',
          necessary: true,
          value: disposeInfoDate,
          placeholder: 'YYYYMMDD',
          onChange: (v: any) =>
            tableClass.setData('disposeInfoDate', dayjs(v).format('YYYY-MM-DD')),
        },
      ],
      {
        label: '메모',
        type: 'textarea',
        value: disposeInfoMemo || '',
        keyName: 'disposeInfoMemo',
        dataObj: tableClass.state,
        placeholder: '메모를 입력해주세요',
        maxByte: 120,
        onChange: (k: string, v: string) => tableClass.setData(k, v),
      },
    ];
  };

  return {
    carRegisterInfo,
    carRegisterEtcInfo,
    getCarDisposeInfo,
  };
};

export const removeUUID = (value: string) => {
  try {
    const tail = value.split(/_+/).pop();

    if (tail) {
      const extention = tail.split('.').pop();

      return `${value.replace(tail, '').slice(0, -1)}.${extention}`;
    }

    return value;
  } catch (error) {
    return value;
  }
};

export const carDisposeModalData = (type: StringDefault, info: any) => {
  const typeText = type === 'trade' ? '매각' : '폐차';
  const preCarNumber = info.carNumber.slice(0, info.carNumber.length - 4);

  return [
    [
      {
        key: 'carNumber',
        label: `${typeText}차량번호`,
        necessary: true,
        input: {
          type: 'number',
          placeholder: info.carNumber.slice(-4),
          style: { width: 167 },
          maxLength: 4,
        },
        preUnit: preCarNumber,
      },
    ],
    [
      {
        key: 'date',
        label: `${typeText}일자`,
        necessary: true,
        type: 'date',
        style: { width: 200 },
      },
    ],
    [
      {
        key: 'company',
        label: `${typeText}업체`,
        input: {
          type: 'text',
          style: { width: 200 },
          maxLength: 20,
          placeholder: `${typeText}업체를 입력해주세요.`,
        },
      },
    ],
    [
      {
        key: 'cost',
        label: `${typeText}금액 (원)`,
        input: {
          type: 'number',
          comma: true,
          style: { width: 200 },
          placeholder: '숫자를 입력해주세요.',
        },
        unit: '원',
        connectType: 'daily', // 렌트 종류에 따라 value 와 일치하는 데이터만 출력
      },
    ],
    [
      {
        key: 'memo',
        label: '메모',
        type: 'textarea',
        placeholder: '메모를 입력해주세요.',
        maxByte: 120,
        style: { height: 92 },
      },
    ],
  ];
};

export const searchCarHolidayUtil = (path: any, data: any) => {
  const serializeForm: any = { page: 1 };
  const state = data.state;
  const props = data.props;

  serializeForm.periodOption = state.periodOption ? state.periodOption : 'holiday';

  if (state.periodOption) {
    serializeForm.periodOption = state.periodOption;
  }

  if (state.startDate && state.endDate) {
    serializeForm.start = state.startDate;
    serializeForm.end = state.endDate;
  }

  if (state.searchOption) {
    serializeForm.option = state.searchOption;
  }

  if (state.searchValue) {
    serializeForm.value = state.searchValue;
  }

  if (state.excludePast) {
    serializeForm.excludePast = 1;
  } else {
    serializeForm.excludePast = 0;
  }

  props.router.push(
    {
      pathname: path,
      query: serializeForm,
    },
    undefined,
    { shallow: true }
  );
};

export const carSizeParser = (type: CarSize) => {
  switch (type) {
    case 'A':
      return '경형';
    case 'B':
      return '소형';
    case 'C':
      return '준중형';
    case 'D':
      return '중형';
    case 'E':
      return '준대형';
    case 'F':
      return '대형';
    case 'S':
      return '슈퍼카';
    case 'G':
      return 'RV';
    case 'H':
      return '캠핑카';
    case 'Z':
      return '기타';
    default:
      return '';
  }
};

const DEFAULT_OPTIONS = [
  { label: MESSAGE.ALL, value: 'all' },
  { label: '경형', value: 'A' },
  { label: '소형', value: 'B' },
  { label: '준중형', value: 'C' },
  { label: '중형', value: 'D' },
  { label: '준대형', value: 'E' },
  { label: '대형', value: 'F' },
] as const;

export const getCarSizeOptions = (bodyStyle: BodyStyle) => {
  if (bodyStyle !== 'sedan' && bodyStyle !== 'suv') {
    return null;
  }

  if (bodyStyle === 'sedan') {
    return [...DEFAULT_OPTIONS, { label: '슈퍼카', value: 'S' }] as const;
  }

  return [
    ...DEFAULT_OPTIONS,
    { label: 'RV', value: 'G' },
    { label: '캠핑카', value: 'H' },
  ] as const;
};

export const getAllCarSizeOptions = () => {
  return [
    ...DEFAULT_OPTIONS,
    { label: 'RV', value: 'G' },
    { label: '슈퍼카', value: 'S' },
    { label: '캠핑카', value: 'H' },
  ] as const;
};

export const holidayCarModalData = (length: number) => {
  return [
    [
      {
        key: 'carNumber',
        label: length > 1 ? `차량번호 (${length}대)` : '차량번호',
        type: 'output',
        style: { padding: '5px 35px 5px 0', lineHeight: '20px' },
      },
    ],
    [
      {
        key: 'holidayReason',
        label: '휴무 사유',
        input: {
          type: 'text',
          placeholder: '예) 명절 휴무, 차량 마감 등',
          style: { width: '100%' },
        },
      },
    ],
    [
      {
        key: 'holidayPeriod',
        label: '적용 기간',
        type: 'term',
      },
    ],
  ];
};

export const driverLicenseOption = [
  { label: '2종 보통(자동)', value: '2종자동' },
  { label: '2종 보통(수동)', value: '2종보통' },
  { label: '1종 보통', value: '1종보통' },
  { label: '1종 대형', value: '1종대형' },
];

export const getRegisterCarGroupTableData = (
  state: CarGroupRegisterState,
  dispatch: (action: Action) => void,
  carOptionStore: CarOptionStore,
  authStore?: AuthStore
) => {
  const selectedSubmodel = find(state.submodelList, { value: state.submodelId });
  const submodelHasJejuSeater =
    Boolean(authStore?.jeju_company) && selectedSubmodel?.jeju_seater?.length > 0;

  const carGroupRegisterTableData1 = [
    {
      label: '차량 구분',
      necessary: true,
      type: 'divide',
      value: state.carType,
      option: [
        { label: '국산차', value: '국산차' },
        { label: '수입차', value: '수입차' },
      ],
      onChange: (value: CarGroupRegisterState['carType']) => {
        const defaultBrand = value === '국산차' ? '현대' : '벤츠';
        const defaultModel = value === '국산차' ? 1 : 8;

        dispatch({ key: 'setData', payload: { carType: value } });
        dispatch({ key: 'setData', payload: { brand: defaultBrand } });
        dispatch({ key: 'setData', payload: { brandId: defaultModel } });
        dispatch({
          key: 'setData',
          payload: { model: { label: '', value: null, type: '', size: '' } },
        });
      },
    },
    [
      {
        label: '브랜드',
        necessary: true,
        type: 'brand',
        value: state.brand,
        option: state.brandList,
        placeholder: '선택',
        listWrapStyle: { width: '430px', borderTop: '1px solid #d7d7d7', marginTop: '5px' },
        onChange: (value: { value: number; label: string }) => {
          dispatch({ key: 'setData', payload: { brand: value.label } });
          dispatch({ key: 'setData', payload: { brandId: value.value } });
          dispatch({
            key: 'setData',
            payload: {
              model: {
                label: '',
                value: value.label === '기타브랜드' ? value.value : null,
                type: '',
                size: '',
              },
            },
          });
        },
      },
      {
        label: '모델',
        necessary: true,
        type: 'model',
        value: state.model.value,
        disabled: String(state.brand).includes('기타') || state.isAlreadyEnrolledCar,
        placeholder: '선택',
        listWrapStyle: { width: '430px', position: 'relative', right: '275px', marginTop: '5px' },
        option: state.modelList,
        onChange: (value: CarGroupRegisterState['model']) => {
          dispatch({ key: 'setData', payload: { model: value } });
        },
      },
    ],
    {
      label: '서브모델',
      necessary: true,
      type: 'select',
      selectKey: 'submodel',
      value: state.submodelId,
      disabled: !state.model.value || String(state.brand).includes('기타'),
      placeholder: '선택',
      disabledPlaceholder: '모델을 선택해주세요.',
      option: state.submodelList,
      onChange: (value: CarGroupRegisterState['submodelId']) =>
        dispatch({ key: 'setData', payload: { submodelId: value } }),
    },
    {
      label: '차량번호',
      necessary: true,
      type: 'enterPushInput',
      placeholder: '서울01호1234, 123호1234',
      valueArray: state.carNumbers,
      validateRex: carNumRegExp,
      limitLength: 30,
      alertMsg: '최대 30대까지 한번에 등록할 수 있습니다.',
      duplicateMsg: '차량번호는 중복으로 입력할 수 없습니다.',
      onChange: (carNumbers: CarGroupRegisterState['carNumbers']) => {
        dispatch({ key: 'setData', payload: { carNumbers } });
      },
      desc: '* 번호 사이에 콤마(,)를 입력하시면 여러 차량을 동시에 등록하실 수 있습니다.\n \u00A0 입력 후 엔터를 눌러주세요.(최대 30대)\n* 신규 차량번호만 등록 가능하며, 차량등록증은 차량 상세에서 첨부하실 수 있습니다.',
    },
    [
      {
        label: '연식',
        necessary: true,
        type: 'number',
        value: state.carAge,
        placeholder: '2020',
        maxLength: 4,
        onChange: (value: CarGroupRegisterState['carAge']) =>
          dispatch({ key: 'setData', payload: { carAge: value } }),
      },
      {
        label: '최초등록일',
        necessary: true,
        type: 'number',
        maxLength: 8,
        value: state.registerDate,
        placeholder: 'YYYYMMDD',
        onChange: (value: CarGroupRegisterState['registerDate']) =>
          dispatch({ key: 'setData', payload: { registerDate: value } }),
      },
    ],
    [
      {
        label: '유종',
        necessary: true,
        type: 'select',
        value: state.oilType,
        placeholder: '선택',
        option: oilTypeData.map((i) => {
          return { label: i.text, value: i.value };
        }),
        onChange: (value: CarGroupRegisterState['oilType']) => {
          dispatch({ key: 'setData', payload: { oilType: value } });
          dispatch({ key: 'setData', payload: { carCC: null } });
        },
      },
      {
        label: '배기량(cc)',
        necessary: !['수소', '전기'].includes(state.oilType ? state.oilType : ''),
        type: 'number',
        maxLength: 4,
        value: state.carCC,
        disabled: ['수소', '전기'].includes(state.oilType ? state.oilType : ''),
        placeholder: '1998',
        onChange: (value: CarGroupRegisterState['carCC']) =>
          dispatch({ key: 'setData', payload: { carCC: value } }),
      },
    ],
    [
      {
        label: '차량 색상',
        type: 'select',
        value: state.carColor,
        placeholder: '선택',
        option: getCarColors,
        onChange: (value: CarGroupRegisterState['carColor']) =>
          dispatch({ key: 'setData', payload: { carColor: value } }),
      },
      {
        label: '승차 인원',
        necessary: true,
        type: submodelHasJejuSeater ? 'select' : 'number',
        option:
          submodelHasJejuSeater &&
          selectedSubmodel?.jeju_seater?.map((seater) => {
            return { value: seater, label: seater };
          }),
        value: state.capacity,
        placeholder: submodelHasJejuSeater ? '선택' : '5',
        maxLength: 2,
        onChange: (value: CarGroupRegisterState['capacity']) =>
          dispatch({ key: 'setData', payload: { capacity: value } }),
      },
    ],
    [
      {
        label: '운전 경력',
        necessary: true,
        type: 'textWithUnit',
        value: state.drivingExperience,
        inputType: 'number',
        maxLength: 1,
        placeholder: 1,
        unit: '년 이상',
        inputWidth: '50px',
        onChange: (value: CarGroupRegisterState['drivingExperience']) =>
          dispatch({ key: 'setData', payload: { drivingExperience: value } }),
      },
      {
        label: '대여 가능 면허',
        necessary: true,
        type: 'select',
        option: driverLicenseOption,
        value: state.licenseType,
        onChange: (value: CarGroupRegisterState['licenseType']) =>
          dispatch({ key: 'setData', payload: { licenseType: value } }),
      },
    ],
  ];

  const carGroupRegisterTableData2 = [
    {
      label: '사업자 연결',
      necessary: true,
      type: 'select',
      value: state.businessId,
      placeholder: '선택',
      option: state.businessSelectData,
      onChange: (value: CarGroupRegisterState['businessId']) =>
        dispatch({ key: 'setData', payload: { businessId: value } }),
    },
    {
      label: '차량 그룹명',
      necessary: true,
      type: 'text',
      value: state.groupName,
      maxLength: 50,
      placeholder: '차량 그룹명을 입력해 주세요.',
      onChange: (value: CarGroupRegisterState['groupName']) => {
        dispatch({ key: 'setData', payload: { groupName: value } });
      },
    },
    {
      label: '차량명',
      necessary: true,
      type: 'text',
      value: state.carName,
      maxLength: 50,
      placeholder: '차량명을 입력해 주세요.',
      onChange: (value: CarGroupRegisterState['carName']) =>
        dispatch({ key: 'setData', payload: { carName: value } }),
    },
    {
      label: '보험 연령',
      type: 'doubleButton',
      value: state.insuranceAge,
      option: [
        { label: '만 21세 이상', value: '21' },
        { label: '만 26세 이상', value: '26' },
      ],
      onChange: (value: CarGroupRegisterState['insuranceAge']) => {
        dispatch({ key: 'setData', payload: { insuranceAge: value } });
      },
    },
    {
      label: '차량 옵션',
      type: 'checkbox',
      value: state.options,
      option: carOptionStore.getCarGroupOptions().map(({ name, name_kor }) => {
        const option: { label: string; value: string; tooltip?: string } = {
          label: name_kor,
          value: name,
        };

        if (name === 'foreigners') {
          option.tooltip =
            '<b>한국운전면허증</b> 또는 <b>국제운전면허증(1년 이내) + 여권</b>\n을 소지한 외국인 고객에게 대여 가능한 차량일 경우 \n체크 해 주세요.\n\n위 조건 외에 한국어, 한국인 동승자, 보증인, 한국 거주\n여부 등의 다른 조건은 요구할 수 없습니다.';
        }

        return option;
      }),
      onChange: (optionName: keyof CarGroupOptionState, isChecked: boolean) => {
        dispatch({ key: 'setOptions', payload: { [optionName]: isChecked } });
      },
    },
  ];

  if (state.model.type === 'suv' && state.model.size === 'H') {
    carGroupRegisterTableData2.push({
      label: '캠핑카 옵션',
      type: 'checkbox',
      value: state.options,
      option: carOptionStore.getCampingCarOptions().map(({ name, name_kor }) => ({
        label: name_kor,
        value: name,
      })),
      onChange: (optionName: keyof CarGroupOptionState, isChecked: boolean) => {
        dispatch({ key: 'setOptions', payload: { [optionName]: isChecked } });
      },
    });
  }

  return {
    carGroupRegisterTableData1,
    carGroupRegisterTableData2,
  };
};

export const getCarGroupEditTableData = (
  // state: CarGroupRegisterState,
  state: EditCarGroupState,
  dispatch: (action: EditCarGroupAction) => void,
  carOptionStore: CarOptionStore,
  authStore?: any
): any => {
  const selectedSubmodel = find(state.submodelList, { value: state.submodelId });
  const submodelHasJejuSeater =
    Boolean(authStore?.jeju_company) && selectedSubmodel?.jeju_seater?.length > 0;

  const carGroupEditTableData1 = [
    {
      label: '차량 구분',
      necessary: true,
      type: 'text',
      value: state.carType,
      disabled: true,
    },
    {
      label: '브랜드',
      necessary: true,
      type: 'text',
      value: state.brand,
      disabled: true,
    },
    {
      label: '모델',
      necessary: true,
      type: 'text',
      value: state.model.label,
      disabled: true,
    },
    {
      label: '서브모델',
      necessary: true,
      type: 'select',
      value: state.submodelId,
      placeholder: state.model.value ? '선택' : '모델을 선택해 주세요',
      option: state.submodelList,
      onChange: (value: CarGroupRegisterState['submodelId']) =>
        dispatch({ key: 'setData', payload: { submodelId: value } }),
    },
    [
      {
        label: '연식',
        necessary: true,
        type: 'text',
        value: state.carAge,
        disabled: true,
      },
      {
        label: '유종',
        necessary: true,
        type: 'select',
        value: state.oilType,
        placeholder: '선택',
        option: oilTypeData.map((i) => {
          return { label: i.text, value: i.value };
        }),
        onChange: (value: CarGroupRegisterState['oilType']) => {
          dispatch({ key: 'setData', payload: { oilType: value } });
          dispatch({ key: 'setData', payload: { carCC: null } });
        },
      },
    ],
    [
      {
        label: '승차 인원',
        necessary: true,
        type: submodelHasJejuSeater ? 'select' : 'number',
        value: state.capacity,
        placeholder: submodelHasJejuSeater ? '선택' : '5',
        option:
          submodelHasJejuSeater &&
          selectedSubmodel?.jeju_seater?.map((seater) => {
            return { value: seater, label: seater };
          }),
        maxLength: 2,
        onChange: (value: CarGroupRegisterState['capacity']) =>
          dispatch({ key: 'setData', payload: { capacity: value } }),
      },
      {
        label: '운전 경력',
        necessary: true,
        type: 'textWithUnit',
        inputType: 'number',
        maxLength: 1,
        placeholder: 1,
        unit: '년 이상',
        inputWidth: '50px',
        value: state.drivingExperience,
        onChange: (value: CarGroupRegisterState['drivingExperience']) =>
          dispatch({ key: 'setData', payload: { drivingExperience: value } }),
      },
    ],
    [
      {
        label: '대여 가능 면허',
        necessary: true,
        type: 'select',
        option: driverLicenseOption,
        value: state.licenseType,
        onChange: (value: CarGroupRegisterState['licenseType']) =>
          dispatch({ key: 'setData', payload: { licenseType: value } }),
      },
      {
        type: 'arrayView',
      },
    ],
  ];

  const carGroupEditTableData2 = [
    {
      label: '차량 그룹명',
      necessary: true,
      type: 'text',
      value: state.groupName,
      placeholder: '차량 그룹명을 입력해 주세요.',
      onChange: (value: CarGroupRegisterState['groupName']) => {
        dispatch({ key: 'setData', payload: { groupName: value } });
      },
    },
    {
      label: '보험 연령',
      type: 'doubleButton',
      value: state.insuranceAge,
      option: [
        { label: '만 21세 이상', value: '21' },
        { label: '만 26세 이상', value: '26' },
      ],
      onChange: (value: CarGroupRegisterState['insuranceAge']) => {
        dispatch({ key: 'setData', payload: { insuranceAge: value } });
      },
    },
    {
      label: '차량 옵션',
      type: 'checkbox',
      value: state.options,
      option: carOptionStore.getCarGroupOptions().map(({ name, name_kor }) => {
        const option: { label: string; value: string; tooltip?: string } = {
          label: name_kor,
          value: name,
        };

        if (name === 'foreigners') {
          option.tooltip =
            '<b>운전면허증</b> 또는 <b>국제운전면허증(1년 이내) + 여권</b>\n을 소지한 외국인 고객에게 대여 가능한 차량일 경우 \n체크 해 주세요.\n\n위 조건 외에 한국어, 한국인 동승자, 보증인, 한국 거주\n여부 등의 다른 조건은 요구할 수 없습니다.';
        }

        return option;
      }),
      onChange: (optionName: keyof CarGroupOptionState, isChecked: boolean) =>
        dispatch({ key: 'setOptions', payload: { [optionName]: isChecked } }),
    },
  ];

  if (state.model.type === 'suv' && state.model.size === 'H') {
    carGroupEditTableData2.push({
      label: '캠핑카 옵션',
      type: 'checkbox',
      value: state.options,
      option: carOptionStore
        .getCampingCarOptions()
        .map(({ name, name_kor }) => ({ label: name_kor, value: name })),
      onChange: (optionName: keyof CarGroupOptionState, isChecked: boolean) =>
        dispatch({ key: 'setOptions', payload: { [optionName]: isChecked } }),
    });
  }

  return {
    carGroupEditTableData1,
    carGroupEditTableData2,
  };
};

export const getCarGroupRegisterTableDataWithEnrolledCar = (
  state: CarGroupRegisterState,
  dispatch: (action: Action) => void,
  carOptionStore: CarOptionStore,
  authStore?: AuthStore
) => {
  const selectedSubmodel = find(state.submodelList, { value: state.submodelId });
  const submodelHasJejuSeater =
    Boolean(authStore?.jeju_company) && selectedSubmodel?.jeju_seater?.length > 0;

  const leftTableData = [
    {
      label: '차량 구분',
      necessary: true,
      type: 'input',
      value: state.carType,
      disabled: state.isAlreadyEnrolledCar,
    },
    [
      {
        label: '브랜드',
        necessary: true,
        type: 'brand',
        value: state.brand,
        option: state.brandList,
        placeholder: '선택',
        listWrapStyle: { width: '430px', borderTop: '1px solid #d7d7d7', marginTop: '5px' },
        disabled: state.isAlreadyEnrolledCar,
        onChange: (value: { value: number; label: string }) => {
          dispatch({ key: 'setData', payload: { brand: value.label } });
          dispatch({ key: 'setData', payload: { brandId: value.value } });
          dispatch({
            key: 'setData',
            payload: {
              model: {
                label: '',
                value: value.label === '기타브랜드' ? value.value : null,
                type: '',
                size: '',
              },
            },
          });
        },
      },
      {
        label: '모델',
        necessary: true,
        type: 'model',
        value: state.model.value,
        disabled: String(state.brand).includes('기타') || state.isAlreadyEnrolledCar,
        placeholder: '선택',
        listWrapStyle: { width: '430px', position: 'relative', right: '275px', marginTop: '5px' },
        option: state.modelList,
        onChange: (value: CarGroupRegisterState['model']) =>
          dispatch({ key: 'setData', payload: { model: value } }),
      },
    ],
    {
      label: '서브모델',
      necessary: true,
      type: 'select',
      selectKey: 'submodel',
      value: state.submodelId,
      disabled: !state.model.value || String(state.brand).includes('기타'),
      placeholder: '선택',
      disabledPlaceholder: '모델을 선택해주세요.',
      option: state.submodelList,
      onChange: (value: CarGroupRegisterState['submodelId']) =>
        dispatch({ key: 'setData', payload: { submodelId: value } }),
    },
    {
      label: '차량번호',
      necessary: true,
      type: 'labelTextarea',
      valueArray: state.enrolledCars,
      cantDeleteCondition: state.enrolledCars.length === 1,
      cantDeleteMsg: '차량 그룹 등록을 위해 최소 1개 이상의 차량번호를 유지해 주세요.',
      // limitLength: 30,
      onChange: (enrolledCars: CarGroupRegisterState['enrolledCars']) => {
        dispatch({ key: 'setData', payload: { enrolledCars } });
      },
    },
    [
      {
        label: '유종',
        necessary: true,
        type: 'select',
        value: state.oilType,
        placeholder: '선택',
        option: oilTypeData.map((i) => {
          return { label: i.text, value: i.value };
        }),
        onChange: (value: CarGroupRegisterState['oilType']) => {
          dispatch({ key: 'setData', payload: { oilType: value } });
          dispatch({ key: 'setData', payload: { carCC: null } });
        },
      },
      {
        label: '대여 가능 면허',
        necessary: true,
        type: 'select',
        value: state.licenseType || driverLicenseOption[0].value,
        placeholder: '선택',
        option: driverLicenseOption,
        onChange: (value: CarGroupRegisterState['licenseType']) => {
          dispatch({ key: 'setData', payload: { licenseType: value } });
        },
      },
    ],
    [
      {
        label: '운전 경력',
        necessary: true,
        type: 'textWithUnit',
        value: state.drivingExperience,
        inputType: 'number',
        maxLength: 1,
        placeholder: 1,
        unit: '년 이상',
        inputWidth: '50px',
        onChange: (value: CarGroupRegisterState['drivingExperience']) =>
          dispatch({ key: 'setData', payload: { drivingExperience: value } }),
      },
      {
        label: '승차 인원',
        necessary: true,
        type: 'number',
        value: state.capacity,
        placeholder: '5',
        maxLength: 2,
        onChange: (value: CarGroupRegisterState['capacity']) =>
          dispatch({ key: 'setData', payload: { capacity: value } }),
      },
    ],
  ];

  const rightTableData = [
    {
      label: '차량 그룹명',
      necessary: true,
      type: 'text',
      value: state.groupName,
      maxLength: 30,
      placeholder: '차량 그룹명을 입력해 주세요.',
      onChange: (value: CarGroupRegisterState['groupName']) => {
        dispatch({ key: 'setData', payload: { groupName: value } });
      },
    },
    {
      label: '보험 연령',
      type: 'doubleButton',
      value: state.insuranceAge,
      option: [
        { label: '만 21세 이상', value: '21' },
        { label: '만 26세 이상', value: '26' },
      ],
      onChange: (value: CarGroupRegisterState['insuranceAge']) => {
        dispatch({ key: 'setData', payload: { insuranceAge: value } });
      },
    },
    {
      label: '차량 옵션',
      type: 'checkbox',
      value: state.options,
      option: carOptionStore
        .getCarGroupOptions()
        .map(({ name, name_kor }) => ({ label: name_kor, value: name })),
      onChange: (optionName: keyof CarGroupOptionState, isChecked: boolean) => {
        dispatch({ key: 'setOptions', payload: { [optionName]: isChecked } });
      },
    },
  ];

  if (state.model.type === 'suv' && state.model.size === 'H') {
    rightTableData.push({
      label: '캠핑카 옵션',
      type: 'checkbox',
      value: state.options,
      option: carOptionStore.getCampingCarOptions().map(({ name, name_kor }) => ({
        label: name_kor,
        value: name,
      })),
      onChange: (optionName: keyof CarGroupOptionState, isChecked: boolean) => {
        dispatch({ key: 'setOptions', payload: { [optionName]: isChecked } });
      },
    });
  }

  return { leftTableData, rightTableData };
};

export function submodelParser(item) {
  // 시작연도 끝연도 둘다 없을뗴
  if (!item.start_year && !item.end_year) {
    return '기타';
  }

  // 시작연도 끝연도가 둘다 있고 끝연도가 현재연도일때
  if (
    Boolean(item.start_year) &&
    Boolean(item.end_year) &&
    item.end_year === new Date().getFullYear()
  ) {
    return `${item.name} (${item.start_year}년~현재)`;
  }

  // 시작 연도 끝연도 둘다 있을때
  if (Boolean(item.start_year) && Boolean(item.end_year)) {
    return `${item.name} (${item.start_year}년~${String(item.end_year).substring(2, 4)}년)`;
  }

  // 끝연도가 없을때
  if (!item.end_year) {
    return `${item.name} (${item.start_year}년)`;
  }

  return '기타';
}
