import { Checkbox, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import hyphen from 'helpers/phoneNumberHyphen';

export const SEARCH_ICON = '/images/searchImages/ic_search_blue_20.png';
export const BLACKLIST_ICON = '/images/ic_blacklist_red_16@3x.png';
export const TABLE_COLUMNS = ({ selectRowKeys, setSelectRowKeys, setSelectedItem }) =>
  [
    {
      title: '선택',
      width: 40,
      dataIndex: '_id',
      key: '_id',
      render: (_, record: any) => {
        if (record.id === selectRowKeys) {
          setSelectedItem(record);
        }

        return (
          <Checkbox
            style={{
              position: 'relative',
              top: -2,
            }}
            checked={record.id === selectRowKeys}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedItem(record);
              } else {
                setSelectedItem();
              }

              setSelectRowKeys((prev) => {
                return prev === record.id ? null : record.id;
              });
            }}
          />
        );
      },
    },
    {
      title: '운전자명',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (value, record: any) => {
        return (
          <div style={{ display: 'flex', gap: 4 }}>
            <div>{value}</div>
            {record.is_black && <img src={BLACKLIST_ICON} width={16} />}
          </div>
        );
      },
    },
    {
      title: '법인명',
      dataIndex: 'corporation_name',
      key: 'corporation_name',
      width: 150,
      render: (value) => {
        if (!value) {
          return '-';
        }

        return value.length > 10 ? `${value.substring(0, 10)}...` : value;
      },
    },
    {
      title: '생년월일',
      dataIndex: 'id_number1',
      align: 'center',
      key: 'id_number1',
      width: 70,
    },
    {
      title: '연락처',
      dataIndex: 'contact',
      key: 'contact',
      width: 110,
      render: (value) => hyphen(value),
    },
    {
      title: '배차일',
      dataIndex: 'latest_delivered_datetime',
      key: 'latest_delivered_datetimes',
      width: 80,
      render: (value) => {
        return value ? dayjs(value).format('YY.MM.DD') : '-';
      },
    },
  ] as TableColumnsType;
