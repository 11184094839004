import dayjs from 'dayjs';

import {
  ExtensionRequestState,
  PeriodDate,
  SeverExtendRequestState,
} from './MonthlyExtension.type';

export const parseServerExtensionState = (
  serverState?: SeverExtendRequestState
): ExtensionRequestState => {
  switch (serverState) {
    /** 고객 요청 */
    case 'request_complete':
      return ExtensionRequestState.request;
    /** 고객 결제 대기 */
    case 'payment_waiting':
      return ExtensionRequestState.pending;
    /** 계약 연장 불가 */
    case 'company_fail':
      return ExtensionRequestState.reject;
    /** 고객 결제 완료 */
    case 'payment_complete':
      return ExtensionRequestState.payment;
    /** 고객 미결제, 업체 승인 기간 내 미선택 */
    case 'payment_fail':
      return ExtensionRequestState.outstanding;
    /** 업체 반납 처리, 어드민에서 취소, 고객 요청 후 취소 */
    case 'return_done':
      return ExtensionRequestState.stop;
    /** 계약 연장 신청 전 */
    default:
      return ExtensionRequestState['not-yet'];
  }
};

export const getExtensionDateFormat = (date: string) => dayjs(date).format('YY.MM.DD(ddd) HH:mm');

export const getPeriodDate = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): PeriodDate => {
  const date = dayjs(endDate);
  const diffMinutes = date.diff(startDate, 'minute');
  const days = Math.floor(diffMinutes / (60 * 24));
  const hours = Math.floor((diffMinutes - days * 24 * 60) / 60);
  const minutes = Math.floor(diffMinutes - days * 24 * 60 - hours * 60);

  return { days, hours, minutes };
};

export const getFormattedPeriodDate = ({ days, hours, minutes }: PeriodDate) => {
  const formattedDays = days ? `${days}일 ` : '';
  const formattedHours = hours ? `${hours}시간 ` : '';
  const formattedMinutes = minutes ? `${minutes}분` : '';

  return `${formattedDays}${formattedHours}${formattedMinutes}`;
};
