import callApi from '../../helpers/callApi';
export * from './getOrderers';
export * from './getPrivacyPolicy';
export default {
  getCurrentImsformStatus(params) {
    return callApi({
      method: 'GET',
      url: '/v2/public/current-imsform-status',
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  getClaimAppMain(params) {
    return callApi({
      method: 'GET',
      url: '/claim_app_main',
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getFormHistory(params) {
    let endPoint = `/get_form_history?page=${params.page || 1}`;

    if (params.targetDate) {
      endPoint += `&target_date=${params.targetDate}`;
    }

    if (params.search) {
      endPoint += `&search=${encodeURIComponent(params.search)}`;
    }

    return callApi({
      method: 'GET',
      url: endPoint,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getLocation1(params) {
    return callApi({
      method: 'GET',
      url: '/get_location1',
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  getLocation2(params) {
    const serializeForm = {};

    if (params.class1Value) {
      serializeForm.class1 = params.class1Value;
    }

    return callApi({
      method: 'GET',
      url: '/get_location2',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  setAgreePrivatePolyce() {
    return callApi({
      method: 'POST',
      url: '/v2/agree-to-privacy-policy',
      data: {},
    });
  },
  normalContractReturnGasChange(params) {
    const serializeForm = {
      customer_name: params.customer_name,
      customer_id_number1: params.customer_id_number1,
      customer_driver_license: params.customer_driver_license,
    };

    return callApi({
      method: 'GET',
      url: '/v2/conflict-contracts',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  getGiveupReasons(params) {
    return callApi({
      method: 'GET',
      url: '/v3/responses/giveup-reasons',
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
};
