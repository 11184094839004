import { useRouter } from 'next/router';
import React from 'react';

import Button from '../../atoms/Button/index.tsx';
import styles from './ErrorPageComponent.module.scss';
const ErrorPageComponent = ({ errorName }) => {
  const router = useRouter();
  const goBack = () => {
    router.back();
  };
  const goMain = () => {
    router.replace('/home');
  };

  if (errorName === 'forbidden') {
    return (
      <div className={styles.wrapper}>
        <img src="/images/img_lockpage.png" className={styles.image} />
        <p className={styles.text}>요청하신 페이지에 접근 권한이 없습니다.</p>
        <Button btnText={'이전 페이지'} outline className={styles.backButton} btnClick={goBack} />
      </div>
    );
  }

  if (errorName === 'alreadyConfirmPolicyKakao') {
    return (
      <div className={styles.already_confirm_policy_kakao_wrapper}>
        <img src="/images/img_agree.svg" className={styles.image} />
        <p className={styles.text_main}>변경된 정책에 이미 동의하셨습니다.</p>
        <p className={styles.text}>카카오T 요청에 정상 제안 가능합니다.</p>
        <Button btnText={'확인'} outline className={styles.mainButton} btnClick={goMain} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <img src="/images/img_lockpage.png" className={styles.image} />
      <p className={styles.text}>요청하신 페이지에 접근 권한이 없습니다.</p>
      <Button btnText={'이전 페이지'} outline className={styles.backButton} btnClick={goBack} />
    </div>
  );
};

export default ErrorPageComponent;
