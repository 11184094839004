/* eslint-disable @typescript-eslint/ban-types */
import React, { Component } from 'react';

import Select from '../../atoms/Select';
import SearchInput from '../SearchInput';
import styles from './SearchSelectInput.module.scss';

interface ComponentTypes {
  searchSelect: Function;
  searchInputOption: Array<any>;

  onClick?: Function;
  searchInput?: Function;
  searchTitle?: string;
  searchOptionValue?: any;
  searchInputValue?: string | number;
  type?: string;
  placeholder?: string;
}

class SearchSelectInput extends Component<ComponentTypes> {
  render() {
    const {
      searchTitle,
      searchInputOption,
      searchSelect,
      searchOptionValue,
      placeholder,
      searchInput,
      searchInputValue,
      type,
    } = this.props;

    return (
      <div className={styles.search_select_input_wrap}>
        <p> {searchTitle ? searchTitle : '검색조건'} </p>
        <Select
          arrow
          common
          wrapStyle={styles.search_input_select}
          listStyle={{ top: '40px' }}
          selectData={searchInputOption}
          selectKey="select"
          onChange={(value: string) => searchSelect({ value })}
          value={searchOptionValue ? searchOptionValue.value : searchInputOption[0].value}
        />
        <SearchInput
          type={type}
          placeholder={placeholder}
          onChange={searchInput}
          value={searchInputValue ? searchInputValue : ''}
        />
      </div>
    );
  }
}

export default SearchSelectInput;
