import React, { Component } from 'react';

import Input from '../../../atoms/Input/index.tsx';
import Select from '../../../atoms/Select/index.tsx';
import pad from '../../../helpers/pad';
import { checkRegIdFormat } from '../../../helpers/registrationUtils';
import * as utils from '../../../utils/ContractUtils';
import * as helper from './InsuranceHelper';
import styles from './InsuranceType.module.scss';

class InsuranceType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstId: null,
      secondId: null,
      stateCompany: null,
      singleId: null,
    };
  }

  componentDidMount() {
    const { stateData } = this.props;

    if (stateData.isModify) {
      const company = stateData.claim_insurance || stateData.insurance_company;
      const id = stateData.registration_id || helper.defaultInsuranceId(company);
      const rencarCallData = {
        id,
        company,
        state: this.state,
        modify: stateData.isModify,
      };
      const resultData = helper.insuranceResult(rencarCallData);

      this.setState(resultData);
    }
  }

  componentDidUpdate() {
    this.insuranceEvent();
  }

  insuranceEvent = async () => {
    const { stateData } = this.props;
    const company = stateData.insurance_company || stateData.claim_insurance;
    const id = stateData.registration_id || helper.defaultInsuranceId(company);
    const rencarCallData = {
      id,
      company,
      state: this.state,
    };

    if (id === '--' || id === '-') return;

    const rencarCallResult = helper.insuranceResult(rencarCallData);

    if (rencarCallResult) return this.setState(rencarCallResult);

    const changeInsurance = helper.changeInsurance(company, this.state);

    if (changeInsurance) return this.setState(changeInsurance);
  };

  blur = (value) => {
    const { stateData, setData } = this.props;
    const { firstId } = this.state;
    const company = stateData.insurance_company || stateData.claim_insurance;
    const length = checkRegIdFormat(company)?.secondLength;
    const yearRegx = new RegExp('^(20[1-9][0-9])', 'g');

    if (stateData.isNoUseBlur) return;

    if (company === '하나' && yearRegx.test(firstId)) {
      return;
    }

    if (value.length !== 0) {
      const secondId = pad(value.substr(0, length), length, 0);

      this.setState({ secondId });
      setData('registration_id', `${firstId}-${secondId}`);
    }
  };

  AddInput = () => {
    // FIXME: 보험계약서 작성할때 접수번호
    const { info, stateData, setData } = this.props;
    const { input } = info;

    if (info.select) {
      return (
        <Select
          arrow
          selectData={info.select.data || []}
          inlineStyle={info.select.style || {}}
          selectKey={info.key}
          dataObj={stateData}
          onChange={(value) => setData(info.key, value)}
          placeholder={info.select.placeholder || ''}
          value={stateData.insurance_company || ''}
        />
      );
    }

    if (info.addInput) {
      return (
        <Input
          // inlineStyle={input.style}
          disabled={true}
          propValue={stateData.insurance_company}
          inlineStyle={{ marginRight: '5px', width: '150px' }}
        />
      );
    }
  };

  DefaultInput = (isDivided) => {
    const { info, setData, stateData } = this.props;
    const { singleId } = this.state;
    const { addInput } = info;

    if (!isDivided) {
      return (
        <Input
          type="number"
          className={styles.single_input}
          placeholder={addInput?.input.placeholder}
          propValue={singleId}
          handleChange={(value) => {
            this.setState({ singleId: value || ' ' });
            setData('registration_id', value);
          }}
          disabled={stateData.additional}
        />
      );
    }
  };

  doubleInputDisabled = () => {
    const { info, stateData } = this.props;

    if (stateData.additional) return true;

    return info.input.disabled;
  };

  DoubleInput = (isDivided) => {
    // FIXME: 보험계약서 접수번호 두개 생성되는 경우
    const { info, stateData, setData } = this.props;
    const { firstId, secondId } = this.state;
    const company = stateData.insurance_company || stateData.claim_insurance;
    const format = checkRegIdFormat(company);

    if (isDivided) {
      return (
        <div className={styles.double_input}>
          <Input
            type="number"
            propValue={firstId}
            handleChange={(value) => {
              this.setState({ firstId: value });
              setData('registration_id', `${value}-${secondId || ''}`);
            }}
            maxLength={format.firstLength}
            disabled={this.doubleInputDisabled()}
            inlineStyle={{ width: '45%' }}
          />
          <span className={styles.dash}>-</span>
          <Input
            type="number"
            propValue={secondId}
            handleChange={(value) => {
              this.setState({ secondId: value });
              setData('registration_id', `${firstId}-${value}`);
            }}
            handleBlur={(value) => this.blur(value)}
            placeholder={helper.returnSecondPlaceholder(company)}
            maxLength={format.secondLength}
            disabled={this.doubleInputDisabled()}
            inlineStyle={{ width: '45%' }}
          />
        </div>
      );
    }
  };

  render() {
    const { stateData } = this.props;
    const selectedCompany = stateData.insurance_company || stateData.claim_insurance;
    let isDivided = utils.getRegInputType(selectedCompany);

    return (
      <div className={styles.insurance_input_wrap}>
        {this.AddInput(isDivided)}
        {this.DoubleInput(isDivided)}
        {this.DefaultInput(isDivided)}
      </div>
    );
  }
}

export default InsuranceType;
