import dayjs from 'dayjs';
import orderBy from 'lodash/orderBy';

import comma from '../helpers/costComma';

const QUESTION_ICON = '/images/question/question-white.png';
const QUESTION_GREY = '/images/question/question-gray.png';
const CIRCLE_FIRST = '/images/number_circle_first.png';
const CIRCLE_SECOND = '/images/number_circle_second.png';
const CIRCLE_ONE_TWO = '/images/number_circle_onetwo.png';

export const getFeeTableData = (data, preview) => {
  const {
    current_deposit_amount, //현재보증금
    total_deposit_amount, //총보증금
  } = data;

  return [
    [
      {
        key: 'amount_due',
        label: '총 납부예정금',
        tooltip: {
          img: QUESTION_ICON,
          message: '납부 예정금액을 모두 더한 금액입니다.',
          style: { left: '32px', fontSize: '14px', fontWeight: 'normal', top: '18px' },
        },
        type: 'output',
        unit: '원',
        labelImgData: {
          img: CIRCLE_FIRST,
          position: 'left',
        },
      },
      {
        key: 'current_deposit_amount',
        label:
          (total_deposit_amount || 0) === (current_deposit_amount || 0)
            ? '보증금'
            : preview
            ? '보증금'
            : '잔여 보증금',
        type: 'output',
        unit: '원',
      },
    ],
    [
      {
        key: 'prepaid_amount',
        label: '선수금(납부/예정)',
        type: 'output',
        labelImgData: {
          img: CIRCLE_SECOND,
          position: 'left',
        },
      },
      {
        key: 'monthly_fee',
        label: '월 납부금',
        type: 'output',
      },
    ],
    [
      {
        key: 'total_fee',
        label: `총 요금`,
        labelStyle: { fontWeight: 'bold' },
        valueStyle: { fontWeight: 'bold' },
        type: 'output',
        tooltipStyle: { fontSize: '14px', fontWeight: 'normal', top: '17px', left: '42px' },
        labelImgData: {
          img: CIRCLE_ONE_TWO,
          position: 'bottom',
          style: { width: '35px', marginLeft: '-17px' },
        },
        column: 2,
        unit: '원',
      },
      'column',
      [
        {
          key: 'current_fee',
          label: '실제 납부금',
          type: 'output',
        },
        {
          key: 'unpaid_amount',
          label: '잔금/미납',
          type: 'output',
        },
      ],
    ],
  ];
};

export const getDepositTableData = (data, submit) => {
  const {
    paymentMethodList,
    current_deposit_amount,
    deposit_amount,
    paymentSchedultData,
    disabledStartIndex,
  } = data;
  const { deduct_deposit } = paymentSchedultData;
  let paymentTimes = getPaymentTimes(data);

  if (disabledStartIndex) {
    paymentTimes = paymentTimes.filter((i) => i.idx <= disabledStartIndex);
  }

  const payRootList = [];

  if (paymentMethodList.length > 0) {
    payRootList.push({ label: '선택', value: null });
    paymentMethodList.map((item) => {
      payRootList.push({ label: item.detail, value: item.id });
    });
  }

  return [
    [
      {
        key: 'payment_date',
        necessary: true,
        label: '입금일자',
        type: 'date',
        // style: { backgroundColor: 'red' },
        maxDate: new Date(),
        calcWidth: true,
      },
      {
        key: 'payment_method_id',
        arrow: true,
        type:
          Array.isArray(paymentMethodList) && paymentMethodList.length > 0 ? 'select' : 'output',
        [Array.isArray(paymentMethodList) && paymentMethodList.length > 0 && 'select']: {
          arrow: true,
          data: payRootList,
          style: { width: '100%', minWidth: '140px' },
        },
        outputBtn: {
          label: '선택해 주세요',
          style: {
            minWidth: '138px',
            maxWidth: '200px',
            border: '1px solid #dddddd',
            height: '28px',
            fontSize: 12,
            textAlign: 'center',
          },
          func: () => {
            if (
              confirm(
                '입금경로는 사업자설정>입금경로관리 에서 설정하실 수 있습니다.\n지금 설정하시겠습니까?'
              )
            ) {
              location.href = '/businessManagement/setting/depositPath?page=1';
            } else {
            }
          },
        },
        label: '입금경로',
      },
    ],
    [
      {
        key: 'payment_amount',
        necessary: true,
        label: '입금액',
        input: {
          type: 'text',
          style: { minWidth: '92px', width: '92px' },
          comma: true,
        },
        flex: 2,
        checkBox: {
          key: 'deduct_deposit',
          text: '보증금차감',
          value: deduct_deposit,
          tooltip: {
            img: QUESTION_GREY,
            message: `체크 후 입금 시 보증금이 차감됩니다.`,
            style: { left: '28px', fontSize: '14px', fontWeight: 'normal', top: '18px' },
          },
        },
        // check: [
        //   {
        //     key: 'deduct_deposit',
        //     label: '보증금차감',
        //     match: 'deduct_deposit',
        //     value: deduct_deposit,
        //     tooltip: QUESTION_GREY,
        //     tooltipMessage: `체크 후 입금 시 보증금이 차감됩니다.`,
        //     tooltipStyle: { left: '28px', fontSize: '14px', fontWeight: 'normal', top: '18px' },
        //   },
        // ],
      },
      {
        key: 'installment_no',
        necessary: true,
        label: '납부 회차',
        type: 'select',
        select: {
          data: paymentTimes,
          arrow: paymentTimes.length > 2,
          style: { minWidth: '71px' },
        },
        flex: 1,
      },
    ],
    [
      {
        key: 'remark',
        label: MESSAGE.ESPECIAL_POINT,
        type: 'textarea',
        maxByte: 120,
        placeholder: '예) 입금 완료',
        btn: MESSAGE.CONFIRM,
        btnFunc: submit,
        calcWidth: true,
        btnStyle: { minWidth: '80px', marginLeft: '5px' },
        style: { height: '58px' },
      },
    ],
  ];
};

export const isUnPaid = (item) => {
  const isPast = dayjs(item.due_date).isBefore(dayjs());

  return item.amount_due - item.amount > 0 && isPast;
};

export const getPaymentTimes = ({ data }) => {
  const resultArray = orderBy(
    data.schedule.flatMap((item) => {
      if (data.current_installment_no === item.installment_no || isUnPaid(item)) {
        return {
          idx: item.installment_no,
          label: `${item.installment_no}회차`,
          value: item.installment_no,
        };
      }

      return [];
    }),
    ['idx'],
    ['asc']
  );

  if (
    resultArray.length === 1 && //현재 납부회차까지 모두 완납된 경우 다음 회차 노출
    resultArray[0].amount_due === resultArray[0].amount
  ) {
    for (const i in data.schedule) {
      if (data.schedule[i].amount_due !== data.schedule[i].amount) {
        return [
          {
            idx: data.schedule[i].installment_no,
            label: `${data.schedule[i].installment_no}회차`,
            value: data.schedule[i].installment_no,
          },
        ];
      }
    }
  }

  return resultArray;
};

export const getDisabledStartIndex = (data) => {
  try {
    for (const index in data) {
      if (data[index].cancelled) {
        return Number(index);
      }
    }

    return null;
  } catch (error) {
    return null;
  }
};

export const validateEditData = (defaultScheduleList, changedScheduleList) => {
  let returnObj = {
    // result: false,
    // key: 'prepaid_amount_due',
    // insNo: 1,
    // message: '납부 예정금액을 실제 납부금액보다 작게 수정할 수 없습니다.',
    result: true,
  };

  for (let i = 0; i < changedScheduleList.length; i++) {
    const defaultItem = defaultScheduleList[i];
    const changedItem = changedScheduleList[i];

    // 이미[완전 납부]된 회차의
    // 납부 예정금액을 수정한 경우
    // (기존 금액 > 변경 후 금액)
    // 수정 불가 얼럿 띄움.단, 수정한 값은 유지
    // 확인 클릭 시 수정한 필드(납부 예정금액)에 자동으로 커서 이동
    if (changedItem.completed) {
      if (defaultItem.amount_due > changedItem.amount_due) {
        returnObj = {
          result: false,
          key: 'amount_due',
          insNo: 1,
          message: '납부 예정금액을 실제 납부금액보다 작게 수정할 수 없습니다.',
        };
        break;
      }
    }
  }

  return returnObj;
};
