import dayjs from 'dayjs';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';

import CustomDatePicker from '../../../../atoms/CustomDatePicker/index.tsx';
import CommaInput from '../../../../components/CommaInput';
import * as utils from '../../../../utils/PaymentUtils';
import PureTable from './PureTable';
import styles from './ScheduleTable.module.scss';
class ScheduleTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
    };
    props.scheduleListRef(this);
    this.prepaid_amount_due = [];
    this.amount_due = [];
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.data === nextState.data &&
      this.props.disabledStartIndex === nextProps.disabledStartIndex &&
      this.props.editMode === nextProps.editMode
    ) {
      return false;
    }

    return true;
  }

  refresh = (data) => {
    this.setState({
      data: data.schedule,
    });
  };

  focus = (key, insNo) => {
    const { editMode } = this.props;

    if (editMode && key && insNo) {
      switch (key) {
        case 'prepaid_amount_due':
          this.prepaid_amount_due[insNo].focus();
          break;
        case 'amount_due':
          this.amount_due[insNo].focus();
          break;
        default:
          break;
      }
    }
  };

  overlay = () => (
    <div className={styles.overlay}>
      <span className={styles.overlay_title}>중도해지</span>
      <span className={styles.overlay_content}>
        보증금 및 선수금, 대여료는 &apos;입금 내역&apos;에서 환불 처리할 수 있습니다.
      </span>
    </div>
  );

  setData = (index, key, value) => {
    const { data } = this.state;
    let parsedValue = value;

    switch (key) {
      case 'prepaid_amount_due':
      case 'amount_due':
        if (typeof value === 'string') parsedValue = Number(value);

        break;
      default:
        break;
    }

    const changedData = cloneDeep(data);

    changedData[index][key] = parsedValue;
    this.setState({
      data: changedData,
    });
  };

  add = (id) => {
    const schedule = clone(this.state.data);
    const installmentNo = this.state.data.length + 1;
    const lastItem = schedule[schedule.length - 1];

    schedule.push({
      additional: true,
      due_date: dayjs(lastItem.due_date).add('1', 'month').format(MESSAGE.DATE_DASH),
      prepaid_amount_due: 0,
      amount_due: 0,
      installment_no: installmentNo,
      amount: 0,
      cancelled: 0,
      completed: 0,
      created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
      deposit_amount: 0,
      extra: 1,
      is_enabled: 1,
      normal_contract_id: JSON.parse(id),
      payment_date: null,
      remark: null,
      removed_at: null,
    });
    this.setState(
      (prevState) => ({
        data: {
          ...prevState.data,
          schedule,
        },
      }),
      () => {
        this.refresh(this.state.data);
        this.focusNewRow();
      }
    );
  };

  remove = (index) => {
    if (confirm('회차를 삭제하시겠습니까? 입력된 내용이 삭제됩니다.')) {
      const { data } = this.state;
      const changedData = cloneDeep(data);

      changedData.splice(index, 1);

      for (let idx = index; idx < changedData.length; idx++) {
        changedData[idx].installment_no = changedData[idx].installment_no - 1;
      }

      this.setState({
        data: changedData,
      });
    }
  };

  getData = () => {
    return this.state.data;
  };

  focusNewRow = () => {
    const height = this.tbody.clientHeight;

    this.tbody.scrollTop = height > 0 ? height : 0;

    if (this.amount_due) {
      const lastItem = this.amount_due[this.amount_due.length - 1];

      if (lastItem) {
        lastItem.scrollIntoView();
      }
    }
  };

  renderDeleteButton = (item, i) => {
    const { editMode } = this.props;

    if (item.extra && editMode) {
      return (
        <img
          src={'/images/ic-delete.png'}
          className={styles.close}
          onClick={() => this.remove(i)}
        />
      );
    }

    return null;
  };

  isDisabled = (index) => {
    const { editMode, disabledStartIndex } = this.props;

    if (Number.isInteger(disabledStartIndex) && index >= disabledStartIndex) {
      return false;
    }

    if (!editMode) {
      return false;
    }

    return true;
  };

  getMinMaxDate = (index, type, date) => {
    const { data } = this.state;

    switch (type) {
      case 'min':
        //index 바로전 건보다는 커야한다.
        const prevItem = data[index - 1];

        if (prevItem) {
          return new Date(dayjs(prevItem.due_date).add('1', 'day'));
        }

        return undefined;
      case 'max':
        const nextItem = data[index + 1];

        if (nextItem) {
          return new Date(dayjs(nextItem.due_date).subtract('1', 'day'));
        }

        return undefined;
      default:
        return new Date();
    }
  };

  renderRow = () => {
    const { currentPaymentTimes } = this.props;
    const { data } = this.state;

    if (Array.isArray(data)) {
      return data.map((item, i) => {
        const isUnPaid = utils.isUnPaid(item);
        const isActive = currentPaymentTimes === item.installment_no;
        const editMode = this.isDisabled(i);

        return (
          <tr
            key={i}
            style={{
              height: '50px',
              cursor: 'pointer',
              width: '100%',
              position: 'relative',
            }}
          >
            <td className={styles.td} style={{ width: '5%' }}>
              {item.installment_no}
            </td>
            <td className={styles.td} style={{ width: '10%' }}>
              <CustomDatePicker
                selected={new Date(item.due_date)}
                className={styles.due_date}
                onChange={(date) => this.setData(i, 'due_date', dayjs(date).format('YYYY-MM-DD'))}
                disabled={!editMode}
                maxDate={this.getMinMaxDate(i, 'max', item.due_date)}
                minDate={this.getMinMaxDate(i, 'min', item.due_date)}
              />
            </td>
            <td className={styles.td} style={{ width: '10%' }}>
              <CommaInput
                inputRef={(r) => {
                  if (r) this.prepaid_amount_due[item.installment_no] = r;
                }}
                className={styles.number_input}
                disabled={!editMode}
                type="number"
                onChange={(value) => this.setData(i, 'prepaid_amount_due', value)}
                value={item.prepaid_amount_due}
              />
            </td>
            <td className={styles.td} style={{ width: '10%' }}>
              <CommaInput
                inputRef={(r) => {
                  if (r) this.amount_due[item.installment_no] = r;
                }}
                className={styles.number_input}
                disabled={!editMode}
                type="number"
                onChange={(value) => this.setData(i, 'amount_due', value)}
                value={item.amount_due}
              />
            </td>
            <td className={styles.td} style={{ width: '10%' }}>
              <Input value={item.payment_date} />
            </td>
            <td className={styles.td} style={{ width: '10%' }}>
              <CommaInput
                disabled
                className={this.getActiveBorderStyle(isUnPaid, isActive)}
                value={item.amount}
              />
            </td>
            <td>
              <div className={styles.remark}>
                <Input value={item.remark} />
                {this.renderDeleteButton(item, i)}
              </div>
            </td>
          </tr>
        );
      });
    }

    return null;
  };

  getActiveBorderStyle = (isUnPaid, isActive) => {
    if (isUnPaid) {
      return styles.unpaid;
    }

    if (isActive) {
      return styles.active;
    }

    return styles.number_input;
  };

  render() {
    const { disabledStartIndex } = this.props;

    return (
      <PureTable
        overlay={{
          start: disabledStartIndex,
          children: this.overlay(),
        }}
        tbodyRef={(r) => {
          this.tbody = r;
        }}
        theadWidth={[5, 10, 10, 10, 10, 10, 45]}
        theadData={[
          '회차',
          '납부 예정 일자',
          '선수금',
          '납부 예정 금액',
          '실제 납부 일자',
          '실제납부금액',
          MESSAGE.ESPECIAL_POINT,
        ]}
        tbodyData={this.renderRow()}
      />
    );
  }
}

const Input = (props) => {
  const { editMode, value, inputRef, className } = props;

  return (
    <input
      className={`${styles.number_input} ${className && className} `}
      ref={inputRef}
      onChange={props.onChange}
      disabled={!editMode}
      type="text"
      value={value === null ? '' : value}
    />
  );
};

export default ScheduleTable;
