import React, { Component } from 'react';

import Button from '../../../atoms/Button/index.tsx';
import Input from '../../../atoms/Input/index.tsx';
import styles from './DoubleType.module.scss';

class DoubleType extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _inputChange = (value) => {
    const { stateData, info, setData } = this.props;
    const { input } = this.props.info;
    const isId = info.key === 'customer_id_number' || info.key === 'second_driver_id_number';

    if (isId && value.length === input.maxLength[0]) {
      if (typeof info.handleBlur1 === 'function') {
        info.handleBlur1(value);
      }

      this.refs.secondInput.refs.secondInput.focus();
    }

    setData(`${info.key}1`, value);
  };

  render() {
    const { info, stateData, setData } = this.props;
    const { input, btn } = this.props.info;
    const placeholder1 = input.placeholder ? input.placeholder.split('-')[0] : null;
    const placeholder2 = input.placeholder ? input.placeholder.split('-')[1] : null;

    return (
      <div className={styles.double_type_wrap}>
        <Input
          comma={input.comma}
          type={input.type}
          disabled={input.disabled}
          placeholder={placeholder1}
          inlineStyle={input.style}
          maxLength={input.maxLength && input.maxLength[0]}
          propValue={stateData[`${info.key}1`] || ''}
          handleChange={(value) => this._inputChange(value)}
          dataset={`${info.key}1`}
          handleBlur={
            typeof info.handleBlur1 === 'function' && ((value) => info.handleBlur1(value))
          }
        />
        {info.unit && <span className={styles.unit}>{info.unit}</span>}
        <span>-</span>
        <Input
          ref="secondInput"
          inputRef="secondInput"
          comma={input.comma}
          type={input.type}
          disabled={info.key === 'corporation_number' && input.disabled}
          placeholder={placeholder2}
          inlineStyle={input.style}
          maxLength={input.maxLength && input.maxLength[1]}
          propValue={stateData[`${info.key}2`] || ''}
          handleChange={(value) => setData(`${info.key}2`, value)}
          dataset={`${info.key}2`}
        />
        {info.unit && <span className={styles.unit}>{info.unit}</span>}
        {btn && (
          <Button btnText={btn.name} inlineStyle={btn.style ? btn.style : {}} btnClick={btn.func} />
        )}
        {info.addText && <p className={styles.add_text}>{info.addText}</p>}
      </div>
    );
  }
}

export default DoubleType;
