import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import ConfirmAlert from '../../Alert/ConfirmAlert';
import styles from './ReturnCarReconfirmModal.module.scss';

interface PropsType {
  stateData: any;

  onClickConfirm: Function;
  modalStore: any;
}
@inject('modalStore')
@observer
class ReturnCarReconfirmModal extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);
  }

  getContent = () => {
    const { stateData } = this.props;

    return (
      <>
        <div className={styles.info_text}>
          <div>{dayjs(stateData.end_at).format('MM/DD HH:mm')}</div>
          <div style={{ marginLeft: 18 }}>{`[${stateData.car_identity}]`}</div>
        </div>
        <div className={styles.reconfirm_text}>
          <div>반납 시간까지 2시간 이상의 시간이 남아 있습니다.</div>
          <div>회차를 진행하실 예정이신가요?</div>
        </div>
      </>
    );
  };

  render() {
    const cancelBtnStyle = { width: 64, padding: 0 };
    const confirmBtnStyle = { width: 108 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={this.props.onClickConfirm}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
      />
    );
  }
}

export default ReturnCarReconfirmModal;
