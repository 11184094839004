import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import apiRentResponse from '../../../api/rentResponse';
import TextArea from '../../../atoms/TextArea';
import { ConfirmModalDefaultType as DefaultType } from '../../../interface/utils/modalContents';
import ConfirmAlert from '../../Alert/ConfirmAlert';
import styles from './DispatchGiveUpModal.module.scss';

interface PropsType extends Omit<DefaultType, 'onClickConfirm'> {
  id: number;

  refreshFunc: Function;
}

interface StatesType {
  value: string;
}

@inject('modalStore')
@observer
class DispatchGiveUpModal extends Component<PropsType, StatesType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      value: '',
    };
  }

  cancelRequest = () =>
    catchAsync(async () => {
      const params = {
        id: this.props.id,
        reasonId: 99,
        reason: this.state.value,
      };
      const response = await apiRentResponse.giveUpResponse(params);

      if (response.status === 200) {
        alert('배차 포기 작업이 완료되었습니다.');
        this.props.refreshFunc();
        this.props.modalStore.modalClose();
      }
    });

  getContent = () => {
    return (
      <>
        <div className={styles.text}>
          <div>배차 포기 사유를 입력해주세요.</div>
          <div>미작성 / 거짓 사유 작성은 페널티 대상이 됩니다.</div>
          <div>(고객 취소 요청건의 경우, 고객이 직접 취소해야 합니다.)</div>
          <TextArea
            placeholder={'배차 포기 사유를 입력해주세요.'}
            className={styles.text_area}
            style={{ marginRight: 0 }}
            maxByte={100}
            keyName={'value'}
            dataObj={this.state}
            value={this.state.value}
            setData={(_key: string, value: string) => this.setState({ value })}
          />
        </div>
      </>
    );
  };
  render() {
    const cancelBtnStyle = { width: 64, padding: 0 };
    const confirmBtnStyle = { width: 108 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={() => {
          if (this.state.value?.length === 0) {
            return alert('배차 포기 사유를 입력해주세요.');
          }

          this.cancelRequest();
        }}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
      />
    );
  }
}

export default DispatchGiveUpModal;
