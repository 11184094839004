import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import React, { Component } from 'react';

import styles from './TopMenuStyle.module.scss';

@inject('menuStore')
@observer
class BadgeIcon extends Component {
  getImage = () => {
    if (this.props.status) {
      return '/images/headerImages/notification_active.png';
    }

    return '/images/headerImages/notification_inactive.png';
  };

  render() {
    const { notification_badge_count } = this.props.menuStore;
    const cnt = notification_badge_count > 99 ? '99+' : notification_badge_count;

    return (
      <div className={styles.alert_icon}>
        <img src={this.getImage()} style={{ width: 19, height: 24 }} />
        {cnt == 0 ? null : (
          <span
            style={{
              display: 'inline-block',
              position: 'absolute',
              minWidth: '10px',
              padding: '2px 5px',
              fontSize: '10px',
              lineHeight: '1',
              color: '#fff',
              textAlign: 'center',
              whiteSpace: 'nowrap',
              verticalAlign: 'baseline',
              backgroundColor: '#d41301',
              borderRadius: '10px',
              top: '8px',
              left: '13px',
            }}
          >
            {cnt}
          </span>
        )}
      </div>
    );
  }
}
export default withRouter(BadgeIcon);
