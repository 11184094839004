import React from 'react';
import NumberFormat from 'react-number-format';
class CommaInput extends React.Component {
  constructor(props) {
    super(props);
  }

  getType = () => {
    const { type } = this.props;

    if (type === 'number') {
      return 'tel';
    }

    if (type) return type;

    return 'text';
  };

  onChange = (e) => {
    const { suffix, placeholder, value, onChange, onKeyDown, onMouseUp, onFocus, onBlur } =
      this.props;

    if (this.getType() === 'tel') {
      const value = e.target.value.replace(/[^0-9]/g, '');

      this.props.onChange(value);
    }

    // if (e.target.value.split('-')[0] === '-') {
    //   return this.props.onChange(e.target.value.replace(/^-?[\d\,?]+$/g, ''));
    // }
    this.props.onChange(e.target.value.replace(/[^0-9]/g, ''));
  };

  render() {
    const {
      inputRef,
      disabled,
      suffix,
      placeholder,
      value,
      className,
      onChange,
      onKeyDown,
      onMouseUp,
      onFocus,
      onBlur,
      readOnly,
      style,
      maxLength,
    } = this.props;
    const inputProps = {
      className,
      disabled,
      style,
      type: this.getType(),
      value,
      onChange: this.onChange,
      onKeyDown,
      onMouseUp,
      onFocus,
      onBlur,
      thousandSeparator: true,
      placeholder,
      readOnly,
      // getInputRef: inputRef,
      suffix,
      maxLength,
    };

    return <NumberFormat getInputRef={inputRef} {...inputProps} />;
  }
}
export default CommaInput;
