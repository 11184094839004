import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import Button from '../../../atoms/Button';
import Input from '../../../atoms/Input';
import { ConfirmModalDefaultType as DefaultType } from '../../../interface/utils/modalContents';
import styles from './DispatchDepartureModal.module.scss';

type PropsType = DefaultType;

interface StatesType {
  value: string;
}
@inject('modalStore')
@observer
class DispatchDepartureModal extends Component<PropsType, StatesType> {
  constructor(props: PropsType) {
    super(props);
    this.state = {
      value: '',
    };
  }

  submit = () => this.props.onClickConfirm(this.state.value);

  render() {
    return (
      <div className={styles.modalWrap}>
        <div className={styles.confirmText}>
          <div>탁송 기사가 출발 시 현장에서 모바일 계약서 작성이 어려우시면,</div>
          <div>비대면 계약서로 작성해주세요.</div>
        </div>
        <div className={styles.input_label}>예약 차량 번호 뒤 네자리</div>
        <div className={styles.input_container}>
          <Input
            className={styles.input}
            placeholder={'예) 1234'}
            maxLength={4}
            type={'number'}
            propValue={this.state.value}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.key === 'Enter' && this.submit();
            }}
            handleChange={(value: string) => this.setState({ value })}
          />
          <Button
            className={styles.confirm_button}
            btnText={'탁송 기사 출발'}
            btnClick={this.submit}
          />
        </div>
        <div className={styles.caution}>
          * 직원 직접 출발 시 모바일에서 출발 직원이 직접 배차출발을 눌러주셔야합니다.
        </div>
      </div>
    );
  }
}

export default DispatchDepartureModal;
