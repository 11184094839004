import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const IGetOrdererListParam = z.object({});
export type GetOrdererListParam = z.infer<typeof IGetOrdererListParam>;
export type Orderers = {
  id: number;
  name: string;
  name_kor: string;
  brand_name: string;
};
type SuccessResponse = {
  orderers: Array<Orderers>;
  defaultInfo: callApi2.DefaultInfo;
};

export type GetOrdererListResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getOrderers(
  params: GetOrdererListParam,
  store?: StoreType,
  jwt?: string
): Promise<GetOrdererListResponse> {
  const response = await callApi2.get<GetOrdererListResponse>(`/v2/public/orderers`, params, jwt);

  if (!callApi2.isErrorResponse(response)) {
    if (store) {
      setStoreDefaultData(response.data.defaultInfo, store);
    }
  }

  return response;
}
