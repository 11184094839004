import React, { Component } from 'react';
class PageItem extends Component {
  getStyle = (type) => {
    switch (type) {
      case 'color':
        return this.props.isActive ? '#000000' : '#b0b0b0';
      case 'fontWeight':
        return this.props.isActive ? 400 : 200;
      default:
        break;
    }
  };

  renderTextOrImage = () => {
    if (this.props.image) {
      return <img style={{ height: 15 }} src={this.props.image} />;
    }

    return this.props.pageText;
  };

  render() {
    return (
      <span
        style={{
          padding: '0px 5px',
          color: this.getStyle('color'),
          fontWeight: this.getStyle('fontWeight'),
          cursor: 'pointer',
        }}
        onClick={() => this.props.onClick(this.props.pageNumber)}
      >
        {this.renderTextOrImage()}
      </span>
    );
  }
}

export default PageItem;
