import { useRouter } from 'next/router';
import React, { useState } from 'react';
import useStores from 'stores/UseStores';

import { addEtcContractApi } from '../../../../api/etcContract/addEtcContractApi';
import { putEtcContractApi } from '../../../../api/etcContract/putEtcContractApi';
import Input from '../../../../atoms/Input';
import TextArea from '../../../../atoms/TextArea';
import * as utils from '../../../../utils/ContractUtils';
import * as S from './RegisterContractModal.style';

const RegisterContractModal = (props: {
  editItem?: { id: number };
  title?: string;
  content?: string;
  isListModalAgain?: boolean;
  refresh: () => void;
}) => {
  const router = useRouter();
  const { loadingStore, modalStore, normalContractStore, contractStore } = useStores();
  const [title, setTitle] = useState<string>(props.title || '');
  const [content, setContent] = useState<string>(props.content || '');
  const { isListModalAgain } = props;

  const openRegisterModal = () => {
    // 해당 페이지에 맞는 스토어 불러오기
    const splittedArray = router.pathname.split('/');
    const store = splittedArray[1] === 'contract' ? contractStore : normalContractStore;

    utils.selectContractModalOpen(store, modalStore);
  };

  const submit = async () => {
    if (!title) {
      alert('제목을 입력해 주세요.');

      return;
    }

    if (!content) {
      alert('내용을 입력해 주세요.');

      return;
    }

    catchAsync(async () => {
      const { editItem } = props; // 기타 계약사항 수정인지 등록인지 확인
      const id = editItem ? editItem.id : null;
      const data = { title, content };

      const requestFunc = editItem ? putEtcContractApi(data, id) : addEtcContractApi(data);

      loadingStore.on();
      const response = await requestFunc;

      if (response.status === 200) {
        if (editItem) {
          alert('수정이 완료되었습니다.');
          props.refresh();

          return modalStore.modalClose();
        }

        // 계약서 작성 페이지에서 계약사항 등록 한 경우 다시 계약사항 리스트 모달 노출
        if (isListModalAgain) {
          alert('저장이 완료되었습니다.');

          return openRegisterModal();
        }

        alert('저장이 완료되었습니다.');
        props.refresh();
        modalStore.modalClose();
      }
    }, loadingStore.off);
  };

  return (
    <S.Wrapper>
      <S.InputWrap>
        <S.InputLabel>제목</S.InputLabel>
        <S.InputSubLabelWrap>
          <Input
            type="text"
            placeholder="예) 일반렌트 기타 계약사항"
            handleChange={(value: string) => setTitle(value)}
            propValue={title ? title : ''}
            maxLength={20}
            inlineStyle={{
              width: '600px',
              height: '47px',
              marginTop: '5px',
              paddingLeft: '10px',
            }}
          />
          <S.SubText>* 제목은 기타 계약사항 내용에 포함되지 않습니다.</S.SubText>
        </S.InputSubLabelWrap>
      </S.InputWrap>
      <S.InputWrap>
        <S.InputLabel>내용</S.InputLabel>
        <TextArea
          placeholder={`예)\r-차량 내 흡연 금지\r-계약자 외 운전 금지`}
          keyName="content"
          dataObj={{ content }}
          value={content ?? ''}
          setData={(key, value: string) => {
            setContent(value);
          }}
          maxByte={400}
          style={{ height: '255px', fontSize: '14px', marginRight: '0' }}
        />
      </S.InputWrap>
      <S.Footer>
        <S.ButtonWrap>
          <S.SubmitButton onClick={submit}>저장</S.SubmitButton>
        </S.ButtonWrap>
      </S.Footer>
    </S.Wrapper>
  );
};

export default RegisterContractModal;
