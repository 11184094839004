// registerPartner(params) {
//   return callApi({
//     method: 'POST',
//     url: '/v2/partners',
//     data: params,
//     disableErrorHandler: params.disableErrorHandler,
//   });
// },
import { _strEnum } from 'helpers/zodHelpers';
import { omit } from 'lodash';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
export const InsertPartnerParam = z.object({
  id: z.number(),
});
export type InsertPartnerParam = z.infer<typeof InsertPartnerParam>;

type SuccessResponse = {
  success: true;
};
export type InsertPartnerResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;
export async function insertPartner(params: InsertPartnerParam) {
  const response = await callApi2.post<InsertPartnerResponse>(`/v2/partners`, omit(params, ['id']));

  return response;
}
