/* eslint-disable @typescript-eslint/ban-types */
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import Button from '../../../atoms/Button';
import { returnChangedExpectedReturnTime } from '../../../utils/NormalContractDetailUtils';
import styles from './ConfirmChangeTimeModal.module.scss';

interface PropsType {
  stateData: any;
  onClickConfirm: Function;
  modalStore: any;
}

@inject('modalStore')
@observer
class ConfirmChangeTimeModal extends Component<PropsType> {
  private confirmRentTimeChange: React.RefObject<HTMLDivElement>;
  constructor(props: PropsType) {
    super(props);
    this.confirmRentTimeChange = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  confirmExtend = () => {
    this.props.onClickConfirm();
    this.props.modalStore.modalClose();
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event: any) {
    if (this.confirmRentTimeChange && !this.confirmRentTimeChange.current?.contains(event.target)) {
      this.props.modalStore.modalClose();
    }
  }

  render() {
    const { stateData, onClickConfirm } = this.props;
    const changedValue = returnChangedExpectedReturnTime(
      stateData.original_dropoff_at,
      stateData.updated_dropoff_at
    );

    return (
      <div className={styles.container} ref={this.confirmRentTimeChange}>
        <div className={styles.content_wrap}>
          <div className={styles.car_number}>{`[${stateData.car_identity}]`}</div>
          <div className={styles.extend_time_info}>
            <div className={styles.date_info_wrap}>
              <span>{`${dayjs(stateData.original_dropoff_at).format('YYYY-MM-DD')}`}</span>
              <span>{`${dayjs(stateData.original_dropoff_at).format('HH:mm')}`}</span>
            </div>
            <div className={styles.request_extend_time}>
              <span>{`${changedValue.time}`}</span>
              <span style={{ fontSize: '18px' }}>{`${changedValue.type}`}</span>
            </div>
            <div className={styles.date_info_wrap}>
              <span style={{ fontWeight: 'bold' }}>{`${dayjs(stateData.updated_dropoff_at).format(
                'YYYY-MM-DD'
              )}`}</span>
              <span style={{ fontWeight: 'bold' }}>{`${dayjs(stateData.updated_dropoff_at).format(
                'HH:mm'
              )}`}</span>
            </div>
          </div>
          <p className={styles.rent_extend_text}>
            {`고객요청으로 대여시간이 ${changedValue.time} ${changedValue.type}되었습니다. ${changedValue.type} 요금은`}
          </p>
          <p className={styles.rent_extend_text}>
            추가 정산됩니다. 반납 관련하여 고객님과 통화를 진행해주세요.
          </p>
          <div>
            <Button
              className={styles.confirm_rent_extend_button}
              btnText="대여 시간 변경을 확인했습니다."
              btnClick={onClickConfirm}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmChangeTimeModal;
