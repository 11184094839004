export default function costComma(strParams: string | number) {
  let str = strParams;

  if (str === null || str === undefined) {
    return '-';
  }

  if (Number(str) === 0) {
    return '0';
  }

  str = String(str);

  if (str.length === 0) {
    return '-';
  }

  return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export const comma = costComma;
