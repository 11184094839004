import callApi from '../../helpers/callApi';

export function getPeriodsCost(params: any): any {
  return callApi({
    method: 'GET',
    url: '/v2/group-cost-tables/periods',
    params: { page: params.page || 1, per_page: params.per_page || null, ...params?.param },
    stores: {
      menuStore: params.menuStore,
      authStore: params.authStore,
    },
    serverCookie: params.cookie,
  } as any);
}
