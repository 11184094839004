import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

// import callApi from '../../../helpers/callApi';
import apiUser from '../../../api/user';
import Button from '../../../atoms/Button';
import Paging from '../../../components/Paging';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import * as parser from '../../../utils/DataParser';
import styles from './SalesEmployeeModalContentStyle.module.scss';

@inject('modalStore')
@inject('authStore')
@inject('menuStore')
@observer
class SalesEmployeeModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      selectedId: null,
      list: [],
      currentPage: 1,
      totalPage: 1,
      searchOption: 'name',
      searchValue: null,
    };
  }

  componentDidMount() {
    this.getList(1);
  }

  getList = async (page) => {
    // const request = {
    //   method: 'GET',
    //   path: `/v2/sales-employees?page=${page}`,
    //   menuStore: this.props.menuStore,
    //   authStore: this.props.authStore
    // }
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
      searchOption: this.state.searchOption,
      searchValue: this.state.searchValue,
      page,
    };
    const response = await apiUser.getSalesEmpolyees(params);
    const responseData = response.data;

    if (response.status == 200) {
      this.setState(
        {
          list: responseData.list,
          currentPage: page,
          totalPage: parseInt(responseData.totalPage),
        },
        () => {
          if (this.props.defaultEmployId) {
            this.setState({
              selectedId: this.props.defaultEmployId,
              selectedItem: this.state.list.find((elem) => elem.id === this.props.defaultEmployId),
            });
          }
        }
      );
    }
  };

  getSearchList = async (e, newPage = 1) => {
    e.preventDefault();

    if (this.state.searchValue === null) {
      return alert('검색어를 입력해주세요.');
    }

    const page = newPage;
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
      searchOption: this.state.searchOption,
      searchValue: this.state.searchValue,
      page,
    };

    try {
      const response = await apiUser.getSalesEmpolyees(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.setState({
          currentPage: page,
          list: responseData.list,
          totalPage: parseInt(responseData.totalPage),
        });
      }
    } catch (error) {
      alert('오류가 발생했습니다');
    }
  };

  movePage = (page) => {
    if (this.state.currentPage != page) {
      this.getList(page);
    }
  };

  selectCheck = (item) => {
    if (this.state.selectedId == item.id) {
      return this.setState({ selectedId: null, selectedItem: null });
    }

    this.setState({ selectedId: item.id, selectedItem: item });
  };

  tbodyData = () => {
    if (this.state.list.length > 0) {
      return this.state.list.map((item, i) => {
        let checkboxStyle;

        if (item.id === this.state.selectedId) {
          checkboxStyle = styles.active_checkbox;
        } else {
          checkboxStyle = styles.inactive_checkbox;
        }

        return (
          <tr
            key={i}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.selectCheck(item);
            }}
          >
            <td key={i}>
              <div className={`${styles.checkbox_div} ${checkboxStyle}`}>
                <img src={'/images/checkboxImage/check_icon_white_thin.png'} />
              </div>
            </td>
            <td>{parser.abbreviation(parser.defaultParser(item.realname), 10)}</td>
            <td>{parser.abbreviation(parser.contractParser(item.contact), 15)}</td>
            <td>{parser.abbreviation(parser.defaultParser(item.company), 10)}</td>
          </tr>
        );
      });
    }

    // else {
    //   return (
    //     <tr>
    //       <td colSpan="4" className={styles.td_none_attachment} style={{ fontSize: '13px' }}>선택 가능한 영업 직원이 없습니다.</td>
    //     </tr>
    //   )
    // }
  };

  resetSearch = () => {
    this.setState({ searchValue: '' }, () => this.getList(1));
  };

  render() {
    return (
      <div className={styles.sales_employee_modal_wrap}>
        <div className={styles.search_input_wrap}>
          <form onSubmit={this.getSearchList}>
            <SearchInput
              type="text"
              placeholder="직원명 검색"
              onChange={(value) => this.setState({ searchValue: value })}
              value={this.state.searchValue}
            />
          </form>
          <Button
            btnText="초기화"
            btnClick={this.resetSearch}
            className={styles.reset_button}
            outline={true}
          />
        </div>
        <div className={styles.table_area}>
          <Table
            headFix
            theadWidth={[10, 30, 30, 30]}
            theadData={['선택', '직원명', '연락처', '업체명']}
            tbodyData={this.tbodyData()}
            emptyTable={{ text: '선택 가능한 영업 직원이 없습니다.', style: { fontSize: '13px' } }}
          />
        </div>
        <Paging
          currentPage={this.state.currentPage}
          totalPage={this.state.totalPage}
          movePage={this.movePage}
        />

        <div className={styles.button_area}>
          <div className={styles.cancel_button} onClick={() => this.props.modalStore.modalClose()}>
            {MESSAGE.CANCEL}
          </div>
          <div
            className={styles.complete_button}
            onClick={() => this.props.selectEmployee(this.state.selectedItem)}
          >
            {MESSAGE.SAVE}
          </div>
        </div>
      </div>
    );
  }
}

export default SalesEmployeeModalContent;
