import dayjs from 'dayjs';
import { BAN_USER_TYPES_IN_CAR_AUCTIONS } from 'domainComponents/carAuction/carAuction.const';

import useTypeSelector from '../helpers/useTypeSelector';

const useFormPathnames = [
  'contract',
  'normalContract',
  'selfInfo',
  'partnerManagement',
  'carStatus',
  'branchManagement',
];
const usePartnerPathnames = ['partner'];
const useExternalPathnames = ['normalRentCallManage', 'calculateManagement'];

const getPaegFilterOption = ({ pathname, groupLeader, query }) => {
  let filterOption = {};
  const currentYear = dayjs().year();

  if (useFormPathnames.includes(pathname.split('/')[1])) {
    filterOption.useForm = true;
  }

  if (usePartnerPathnames.includes(pathname.split('/')[1])) {
    filterOption.usePartner = true;
  }

  if (useExternalPathnames.some((item) => pathname.includes(item))) {
    filterOption.useOnlyExternal = true;
  }

  if ('financialService' === pathname.split('/')[1]) {
    filterOption.banUsers = ['normaler', 'car_manager', 'biller'];
  }

  switch (pathname) {
    // case '/partner/responseExtraList':
    //   filterOption.banUsers = ['normaler'];
    //   break;
    case '/partner/setting':
      filterOption.banUsers = ['normaler'];

      if (query.tab === 'followingCompanyCallManagement') {
        filterOption.pageBelongTo = ['rencar'];
        filterOption.banUsers = ['normaler', 'car_manager'];
      }

      break;
    case '/partner/blackListedDined':
      filterOption.usePartner = true;
      break;
    case '/carStatus/management':
      filterOption.banUsers = ['normaler'];
      break;
    case '/carStatus/disposed':
      filterOption.banUsers = ['normaler'];
      break;
    case '/carStatus/register':
      filterOption.banUsers = ['normaler'];
      break;
    case '/carStatus/excel':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/contract/list/all':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/contract/list/send':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/contract/list/done':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/normalContract/list/all':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/normalContract/list/done':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/accounting/deadlineManagement':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/accounting/receiptHistory':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/paymentMethod/cash':
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/paymentMethod/coupon':
      // filterOption.usePartner = true; // 해당 페이지 전체 노출로 수정
      // filterOption.banUsers = ['normaler'];
      break;
    case '/businessManagement/setting/business':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler'];
      break;
    case '/businessManagement/setting/contract':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler'];
      break;
    case '/businessManagement/terms':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler', 'car_manager', 'biller'];
      break;
    case '/businessManagement/empolyee':
      filterOption.banUsers = ['normaler', 'car_manager', 'biller'];
      break;
    case '/businessManagement/statistics':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler', 'car_manager', 'biller'];
      break;
    case '/businessManagement/setting/depositPath':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/businessManagement/setting/garage':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/businessManagement/setting/business/register':
      filterOption.useForm = true;
      filterOption.banUsers = ['normaler', 'car_manager'];
      break;
    case '/branch/myGroup':
      filterOption.banUsers = ['normaler', 'car_manager', 'biller', groupLeader ? '' : 'manager'];
      break;
    case '/branch/car':
      filterOption.banUsers = ['normaler', 'biller'];
      break;
    case '/branch/contract/insurance':
      filterOption.banUsers = [
        'normaler',
        'car_manager',
        groupLeader ? '' : 'boss',
        groupLeader ? '' : 'manager',
      ];
      break;
    case '/branch/contract/normal':
      filterOption.banUsers = [
        'normaler',
        'car_manager',
        groupLeader ? '' : 'boss',
        groupLeader ? '' : 'manager',
      ];
      break;
    case `/branch/statistics?year=${currentYear}`:
      filterOption.banUsers = [
        'normaler',
        'car_manager',
        'biller',
        groupLeader ? '' : 'manager',
        groupLeader ? '' : 'boss',
      ];
      break;
    case '/notice/imsform':
      filterOption.useForm = true;
      break;
    case '/notice/partner':
      filterOption.pageBelongTo = ['rencar'];
      filterOption.usePartner = true;
      break;
    // case '/notice/company/register':
    //   filterOption.banUsers = ['normaler', 'car_manager', 'biller'];
    //   break;
    case '/applyPartner':
      filterOption.useOnlyForm = true;
      filterOption.banUsers = ['normaler', 'car_manager', 'biller'];
      break;
    case '/carSearching':
      filterOption.banUsers = ['normaler'];
      break;
    // 중고차 경매 권한 타입 확인 (요청, 리스트 상세 3가지)
    case '/carAuction/request':
    case '/carAuction/management/list':
    case '/carAuction/management/detail':
      filterOption.banUsers = BAN_USER_TYPES_IN_CAR_AUCTIONS;
      break;
    default:
      break;
  }

  return filterOption;
};

const isBanUser = ({ banUsers, permissionUserType }) => {
  return (
    banUsers &&
    Array.isArray(banUsers) &&
    permissionUserType &&
    banUsers.includes(permissionUserType)
  );
};

const typeNotMatch = ({
  useForm,
  usePartner,
  useOnlyForm,
  useOnlyPartner,
  use_form,
  use_partner,
  use_external,
  pathname,
  useOnlyExternal,
}) => {
  const userUseType = useTypeSelector({
    use_form,
    use_partner,
    use_external,
  }).result;

  switch (userUseType) {
    case 'all':
    case 'formExternal':
      // if (pathname === '/applyPartner' && !use_partner) {
      if (pathname === '/applyPartner') {
        return false;
      }

      return useOnlyForm || useOnlyPartner;
    case 'formPartner':
      return useOnlyExternal;
    case 'partner':
      return useForm || useOnlyForm || useOnlyExternal;
    case 'form':
      return usePartner || useOnlyPartner || useOnlyExternal;
    case 'dined':
      return true;
    default:
      return false;
  }
};

const notBelong = ({ pageBelongTo, belongTo }) => {
  if (pageBelongTo && Array.isArray(pageBelongTo) && belongTo) {
    return !pageBelongTo.includes(belongTo);
  }
};

export const routeFiltering = ({
  pathname,
  permissionUserType,
  use_form,
  use_partner,
  belongTo,
  groupLeader,
  use_external,
  query,
}) => {
  const {
    banUsers,
    useForm,
    usePartner,
    useOnlyForm,
    useOnlyPartner,
    pageBelongTo,
    useOnlyExternal,
  } = getPaegFilterOption({ pathname, groupLeader, query });

  if (isBanUser({ banUsers, permissionUserType })) {
    return 'forbidden';
  }

  if (
    typeNotMatch({
      useForm,
      usePartner,
      useOnlyForm,
      useOnlyPartner,
      use_form,
      use_partner,
      use_external,
      pathname,
      useOnlyExternal,
    })
  ) {
    return 'forbidden';
  }

  if (notBelong({ pageBelongTo, belongTo })) {
    return 'forbidden';
  }

  return undefined;
};
