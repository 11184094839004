/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import styles from './DropdownSearchInput.module.scss';

type DropdownSearchInputProps = {
  onChange: Function;
  value: string;
  emptyText?: string;
  noSearchResultText?: string;
  list: { label: string; value: number | string }[];
  notSelectedText?: string;
  width?: number | string;
  height?: number | string;
  listHeight?: number | string;
  disabled?: boolean;
  fontSize?: number;
};

/**
 *
 * @param list { label: 노출되는 텍스트, value: 실제 서버로 보내는 값 } 형식
 */
const DropdownSearchInput = ({
  onChange,
  value,
  emptyText,
  noSearchResultText,
  list,
  notSelectedText,
  width,
  height,
  listHeight,
  disabled,
  fontSize,
}: DropdownSearchInputProps) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [searchList, setSearchList] = useState<DropdownSearchInputProps['list']>([]);

  const searchInputRef = useRef(null);

  useEffect(() => {
    moveToSelectedPosition();
  }, [open]);

  useEffect(() => {
    setFilteredList();
  }, [searchValue, open]);

  useEffect(() => {
    searchInputRef.current?.focus();
  }, [searchInputRef.current]);

  const onClickDropdown = useCallback(() => {
    setOpen(!open);
    setSearchValue('');
  }, [open, searchValue]);

  const onBlurSearchInput = useCallback(() => {
    setOpen(false);
    setSearchValue('');
  }, [open, searchValue]);

  const trimList = useMemo(
    () =>
      list.map((e) => {
        const trimLabel = e.label.replaceAll(/\s/g, '').toLowerCase();

        return { label: trimLabel, value: e.value };
      }),
    [list]
  );

  const filtering = useCallback(
    (v: string) => {
      const trimSearchValue = v.replaceAll(/\s/g, '').toLowerCase();
      const filteredList = list.filter((e, i) => {
        if (trimList[i].label.includes(trimSearchValue)) {
          return e;
        }
      });

      return filteredList;
    },
    [open, searchValue, list, trimList]
  );

  const setFilteredList = useCallback(() => {
    const filteredList = filtering(searchValue);

    setSearchList(filteredList);
  }, [searchValue, list, searchList]);

  const onClickItem = useCallback(
    (v: string | number) => {
      onChange(v);
      setOpen(false);
    },
    [onChange, open]
  );

  const moveToSelectedPosition = useCallback(() => {
    const selectedListDiv = document.querySelector('#dropdown_search_list_area');
    let selectedElement;

    if (selectedListDiv) {
      selectedListDiv.childNodes.forEach((node: Element, index: number) => {
        if (node?.className?.includes('DropdownSearchInput_selected')) {
          selectedElement = { node, index };
        }
      });

      if (selectedElement) {
        selectedListDiv.scrollTop = selectedElement?.node.scrollHeight * selectedElement.index;
      }
    }
  }, []);

  const getInputvalue = useCallback(() => {
    const item = list.find((e) => e.value === Number(value));

    if (item) {
      return item.label || notSelectedText || '선택';
    }

    return notSelectedText || '선택';
  }, [list, value]);

  const getUlWrapperClassName = useCallback(
    (v: number | string) => {
      // 숫자가 아닌 문자열key가 들어올 수도 있고 유연하게 사용하기 위해 타입 비교는 제외
      return value == v ? `${styles.ul_wrapper} ${styles.selected}` : styles.ul_wrapper;
    },
    [value]
  );

  return (
    <div style={{ position: 'relative' }} className={styles.stop_dragging}>
      <div
        className={styles.select_input}
        onMouseDown={!disabled ? onClickDropdown : null}
        style={{
          color: value ? '#282828' : '#c5c0bc',
          width,
          height,
          backgroundColor: disabled ? '#efefee' : '#ffffff',
          cursor: disabled ? 'default' : 'pointer',
          fontSize,
        }}
      >
        <div className={styles.input_value}>{getInputvalue()}</div>
        <img src={'/images/searchAreaImages/select_arrow_icon_black2.png'} style={{ width: 10 }} />
      </div>
      {open && (
        <div className={styles.dropdown} style={{ width }}>
          <div className={styles.input_wrap}>
            <input
              ref={searchInputRef}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={'그룹명 검색'}
              className={styles.search_input}
              style={{ height, fontSize }}
              onBlur={onBlurSearchInput}
            />
          </div>
          <div
            className={styles.list}
            style={{ maxHeight: listHeight }}
            id="dropdown_search_list_area"
          >
            {list.length === 0 ? (
              <div className={styles.list_no_data} style={{ height }}>
                {emptyText || '등록된 그룹이 없습니다.'}
              </div>
            ) : searchList.length === 0 ? (
              <div className={styles.list_no_data} style={{ height }}>
                {noSearchResultText || '검색 결과가 없습니다.'}
              </div>
            ) : (
              searchList.map((e) => {
                return (
                  <div
                    key={`item_wrapper_${e.value}`}
                    className={getUlWrapperClassName(e.value)}
                    style={{ height, fontSize }}
                    onMouseDown={() => onClickItem(e.value)}
                  >
                    <ul key={`item_${e.value}`}>{e.label}</ul>
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSearchInput;
