import * as callApi2 from '../../helpers/callApi2';

export type Bank = {
  id: number;
  /** 01 형태 */
  inicis_code: string;
  /** 은행명 */
  name_kor: string;
};

export type GetBanksSuccessResponse = {
  banks: Bank[];
};

type GetCarAuctionDetailResponse =
  | callApi2.GetSuccessResponse<GetBanksSuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export const getBanksApi = () => callApi2.get<GetCarAuctionDetailResponse>('/v2/banks', {});
