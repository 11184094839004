import { CarSpinner } from 'components/Loading/component/CarSpinner';
import { Skeleton } from 'components/Skeleton';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

const SkeletonForSSR = () => {
  const [userAgent, setUserAgent] = useState<'web' | 'mobile' | null>(null);

  const NON_DEFAULT_SPINNER_PATHS_FOR_MOBILE = [
    '/serviceTerms/termOfService',
    '/serviceTerms/privacyTreat',
    '/serviceTerms/termOfLocationService',
    '/serviceTerms/termForCustomer',
  ];
  const NON_DEFAULT_SKELETON_PATHS_FOR_WEB = [
    '/',
    '/serviceTerms/termOfService',
    '/serviceTerms/privacyTreat',
    '/serviceTerms/termOfLocationService',
    '/serviceTerms/termForCustomer',
  ];

  const HIDDEN_SKELETON_PATHS_FOR_WEB = ['/partner/requestList'];

  useEffect(() => {
    const setRealUserAgent = () => {
      const ua = new UAParser();

      const isMobile =
        Router.pathname.split('/')[1] === 'mobile' || ua.getResult().device.type === 'mobile';

      isMobile ? setUserAgent('mobile') : setUserAgent('web');
    };

    setRealUserAgent();
  }, []);

  if (userAgent === 'mobile') {
    if (NON_DEFAULT_SPINNER_PATHS_FOR_MOBILE.includes(Router.pathname)) {
      //폼 앱에서 웹뷰로 보여주는 화면
      return null;
    }

    return <CarSpinner />;
  }

  if (userAgent === 'web') {
    if (HIDDEN_SKELETON_PATHS_FOR_WEB.includes(Router.pathname)) {
      return null;
    }

    if (NON_DEFAULT_SKELETON_PATHS_FOR_WEB.includes(Router.pathname)) {
      return <CarSpinner />;
    }

    return <Skeleton type="page" />;
  }

  return null;
};

export default SkeletonForSSR;
