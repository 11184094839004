import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
const isProduction = ['production'].includes(process.env.API_ENV);

export const initialize = () => {
  if (isProduction) {
    Sentry.init({
      enabled: isProduction,
      dsn: 'https://0deeedaf0caa4b47a1feda8a3ea01fe0@o382064.ingest.sentry.io/5646492',
      environment: process.env.API_ENV,
      integrations: [new Integrations.BrowserTracing()],
      release: process.env.npm_package_versions,
      tracesSampleRate: 0.2, //성능모니터링시 샘플의 percentage 20%
      ignoreErrors: [/^Cannot read property 'month_total_delivered' of null$/],
    });
  }
};

export const withProfiler = (Component) => {
  if (isProduction) {
    return Sentry.withProfiler(Component);
  }

  return Component;
};

export const setTag = Sentry.setTag;
export const setUser = Sentry.setUser;
export const logout = () => Sentry.configureScope((scope) => scope.setUser(null));

export const captureException = (error) => {
  console.log(error);
  Sentry.captureException(error, {
    tags: {
      serverSide: typeof window === 'undefined',
    },
    extra: error?.data,
  });
};

export const sentryLog = (category, message) => {
  Sentry.addBreadcrumb({
    category,
    message,
    level: Sentry.Severity.Info,
  });
};
