import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { ModelBrand, ModelSegment, Submodel } from 'interface/carStatus';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
export interface GetInventoryCarListParams {
  id: number;
  car_size?: string;
  page: number;
  car_group_id?: number;
  search?: string;
}
export interface Car {
  car_age: string;
  car_group_id: number;
  car_group_name: string;
  car_identity: string;
  car_name: string;
  engine_displacement: number;
  id: number;
  is_day_off: boolean;
  oil_type: string;
  recommend: boolean;
  submodel: Submodel;
  using_state: string;
}

export interface Model {
  brand: ModelBrand;
  id: number;
  name: string;
  segment: ModelSegment;
}

type SuccessResponse = {
  cars: Car[];
  total_page: number;
  defaultInfo: callApi2.DefaultInfo;
};
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type GetInventoryCarListResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getInventoryCarList(params: GetInventoryCarListParams, store?: StoreType) {
  const response = await callApi2.get<GetInventoryCarListResponse>(
    `/v2/rental-company-cars/requests-with-group/${params.id}`,
    omit(params, ['id'])
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
