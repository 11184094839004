import callApi from '../../helpers/callApi';
import utils from '../apiUtils';

export default {
  getSwitchInfo({ id, personalNum, disableErrorHandler }) {
    return callApi({
      method: 'POST',
      url: `/v2/public/rencar-claims/${id}/ss-switch`,
      data: { id_number1: personalNum },
      disableErrorHandler,
    });
  },
  certificateLicense(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: '/v2/validate-driver-license',
      data: params,
    });
  },
  getClaimListUsing(params) {
    // unused, move to ../rencarClaim > getClaimList
    const serializeForm =
      utils.filterSearchValues({ query: params.query, state: params.state }) || {};

    return callApi({
      method: 'GET',
      url: `/get_using_car_list/${params.page}`,
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  editContractTime(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'POST',
      url: `/v2/rent-contracts/${params.id}/contract-time`,
      data: {
        delivered_at: params.delivered_at,
        return_date: params.return_date,
      },
    });
  },
  selectCostType(params) {
    let data = { costType: params.costType };

    if (params.cost_table_id) {
      data.cost_table_id = params.cost_table_id;
    }

    if (params.rencar_cost_table_use) {
      data.rencar_cost_table_use = true;
    }

    return callApi({
      method: 'POST',
      url: `/v2/rent-contracts/${params.id}/cost-type`,
      data,
    });
  },
  setReturnGasCharge(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-contracts/${params.contractId}/return-gas-charge`,
      data: {
        done_at: params.done_at,
        return_gas_charge: params.return_gas_charge,
        driven_distance_upon_return: params.driven_distance_upon_return,
      },
    });
  },
  modifyDoneCustomer(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-contracts/${params.id}/customer`,
      data: {
        customer_address: params.customer_address,
        customer_contact: params.customer_contact,
        customer_driver_license: params.customer_driver_license,
        customer_driver_type: params.customer_driver_type,
        customer_id_number1: params.customer_id_number1,
        customer_id_number2: params.customer_id_number2,
        customer_license_limit: params.customer_license_limit,
        customer_license_date: params.customer_license_date,
        customer_name: params.customer_name,
        license_certificated_code: params.license_certificated_code,
        license_certificated: params.license_certificated,
        customer_contact_verified: params.customer_contact_verified,
      },
    });
  },
  downloadPhoto(params) {
    return callApi({
      method: 'GET',
      url: `/v2/rent-contracts/${params.contractId}/photos`,
      responseType: 'blob',
      params: { photo_type: params.photo_type },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  sendCustomerContract(params) {
    return callApi({
      method: 'POST',
      url: '/send_to_customer_contract_info',
      data: { id: params.id },
    });
  },
  getRequestDetail(params) {
    return callApi({
      method: 'GET',
      url: `/v2/rent-contracts/${params.id}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getRequestDetailPublic(params) {
    return callApi({
      method: 'POST',
      url: `/v2/public/rent-contracts/${params.id}`,
      data: {
        certificate_num: params.certificate_num,
        code_type: params.code_type,
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  modifyDoneSecondDriver(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-contracts/${params.id}/second-driver`,
      data: {
        second_driver_name: params.second_driver_name,
        second_driver_contact: params.second_driver_contact,
        second_driver_id_number1: params.second_driver_id_number1,
        second_driver_id_number2: params.second_driver_id_number2,
        second_driver_address: params.second_driver_address,
        second_driver_license: params.second_driver_license,
        second_driver_type: params.second_driver_type,
        second_driver_license_limit: params.second_driver_license_limit,
        second_driver_license_date: params.second_driver_license_date,
        second_driver_license_certificated: params.second_driver_license_certificated,
        second_driver_license_certificated_code: params.second_driver_license_certificated_code,
      },
    });
  },
  removeSecondDriver(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-contracts/${params.id}/second-driver/delete`,
      data: {},
    });
  },
  openInsurancePdf(params) {
    return callApi({
      method: 'GET',
      url: `/v2/rent-contracts/${params.contract_detail_id}/pdf`,
    });
  },
  getDriverList(params) {
    return callApi({
      method: 'GET',
      url: `/v2/customers?page=${params.page}${
        params.searchValue ? `&search=${params.searchValue}` : ''
      }`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
};
