import { AxiosPromise } from 'axios';

import callApi from '../../helpers/callApi';
import { GetDailyCostTableInfoParams, GetDailyCostTableInfoResponse } from './types';

export function getDailyCostTableInfo(
  params: GetDailyCostTableInfoParams
): AxiosPromise<GetDailyCostTableInfoResponse> {
  return callApi({
    method: 'GET',
    url: `/v2/group-cost-tables/daily/${params.id}`,
    stores: {
      menuStore: params.menuStore,
      authStore: params.authStore,
    },
  });
}
