import styled from 'styled-components';

export const RootWrapper = styled.div`
  display: flex;
  gap: 6px;
  flex-direction: column;

  border-bottom: 1px solid #efefef;
  margin: 12px 0;
  padding-bottom: 12px;

  cursor: pointer;
`;

export const Summary = styled.summary`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
`;

export const RoadName = styled.span`
  width: 38px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d7d7d7;
  border-radius: 3px;
  font-size: 11px;
  color: #929292;
  padding-top: 1px;
`;

export const SpecificAddress = styled.span`
  font-size: 14px;
  line-height: normal;
  margin-left: 6px;
  position: relative;
  top: 1px;

  &:hover {
    text-decoration: underline;
  }
`;
