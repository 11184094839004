import { NextRouter } from 'next/router';

/**
 * @brief router.push 사용시에 로딩을 걸고, 페이지 로딩이 완료되면 자동으로 로딩이 해제된다.
 * @author wontae Kim
 * @param #(Object, boolean) {url / router / loadingStore}, useReplace
 */
export function routerLoadingAndPush(
  param: { url: string; router: NextRouter; loadingStore: any },
  useReplace = false
) {
  param.loadingStore?.on();

  if (useReplace) {
    param.router.replace(param.url).then((result) => result && param.loadingStore?.off());
  }

  param.router.push(param.url).then((result) => result && param.loadingStore?.off());
}
