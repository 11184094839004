import { channelIOKey } from '../constants/apiKey';

class ChannelService {
  constructor() {
    if (typeof window !== 'undefined') {
      this.loadScript();
    }
  }

  loadScript() {
    let w = window;

    if (w.ChannelIO) {
      return (window.console.error || window.console.log || function () {})(
        'ChannelIO script included twice.'
      );
    }

    let ch = function () {
      ch.c(arguments);
    };

    ch.q = [];
    ch.c = function (args) {
      ch.q.push(args);
    };
    w.ChannelIO = ch;

    function l() {
      if (w.ChannelIOInitialized) {
        return;
      }

      w.ChannelIOInitialized = true;
      let s = document.createElement('script');

      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
      s.charset = 'UTF-8';
      let x = document.getElementsByTagName('script')[0];

      x.parentNode.insertBefore(s, x);
    }

    if (document.readyState === 'complete') {
      l();
    } else if (window.attachEvent) {
      window.attachEvent('onload', l);
    } else {
      window.addEventListener('DOMContentLoaded', l, false);
      window.addEventListener('load', l, false);
    }
  }

  boot() {
    if (typeof window !== 'undefined') {
      window.ChannelIO('boot', {
        pluginKey: channelIOKey.plugin_key,
      });
    }
  }

  shutdown() {
    if (typeof window !== 'undefined') {
      window.ChannelIO('shutdown');
    }
  }

  initUser() {
    if (typeof window !== 'undefined') {
      window.ChannelIO('updateUser', {
        pluginKey: channelIOKey.plugin_key,
        profile: null,
      });
    }
  }

  updateUser(settings) {
    this.boot();

    if (typeof window !== 'undefined') {
      window.ChannelIO('updateUser', {
        ...settings,
      });
    }
  }
}

export default new ChannelService();
