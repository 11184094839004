import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
export interface IGroupNameListParams {
  body_style?: 'sedan' | 'suv' | 'electric' | 'etc';
  car_size?: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'S' | 'G';
  oil_type?: '가솔린' | '디젤' | 'LPG' | '전기' | '하이브리드' | '수소';
  exclude_daily_cost_table?: boolean;
  exclude_monthly_cost_table?: boolean;
  exclude_daily_car_insurance?: boolean;
  exclude_monthly_car_insurance?: boolean;
}
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type SuccessResponse = {
  car_groups: Array<{
    id: number;
    name: string;
  }>;
  defaultInfo: callApi2.DefaultInfo;
};

export type IGroupNameListResponse =
  | callApi2.GetSuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getGroupNameList(params: IGroupNameListParams, store: StoreType) {
  const response = await callApi2.get<IGroupNameListResponse>(`/v2/car-groups/names`, params);

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
