import { DeliveryCostItem } from 'api/deliveryArea';
import type {
  AreaData,
  AreaForDelivery,
  AreaForPresenter,
  CityData,
  TownData,
  TownForDelivery,
  VillageData,
} from 'interface/delivery';

const getVillages = (data: AreaData, city: string, town: string) => {
  const villages = data.states
    .filter((_city) => _city.name === city)[0]
    .cities.filter((_town) => _town.name === town)[0]
    .dongs.map((dong) => {
      return { name: dong.name, id: dong.id };
    });

  return villages;
};

const getTowns = (data: AreaData, city: string) => {
  const towns = {};

  data.states
    .filter((_city) => _city.name === city)[0]
    .cities.forEach((town) => {
      const villages = getVillages(data, city, town.name);

      towns[town.name] = villages;
    });

  return towns;
};

export const getArea = (data: { states: Array<CityData> | null }): AreaForDelivery => {
  if (!data?.states) return;

  const defaultAreaData = {};
  const cities = data.states?.map((city: CityData) => city.name);

  cities?.forEach((city: string) => {
    defaultAreaData[city] = getTowns(data, city);
  });

  return defaultAreaData;
};

export const getSelectedVillageIds = (selectedArea: AreaForDelivery): Array<number> => {
  const ids: Array<number> = [];
  const cities = Object.keys(selectedArea);

  cities.forEach((city) => {
    Object.values(selectedArea[city]).forEach((town: TownForDelivery) => {
      town.forEach((village) => ids.push(village.id));
    });
  });

  return ids;
};

const getTownsForPresenter = (data: DeliveryCostItem, cityName: string) => {
  const towns = {};

  data.states
    .filter((city: CityData) => city.name === cityName)[0]
    .cities.forEach((town: TownData) => {
      const villages = getVillages(data, cityName, town.name);

      if (town.is_all) {
        return (towns[town.name] = '전체');
      }

      return (towns[town.name] = villages);
    });

  return towns;
};

export const getAreaForPresenter = (data: DeliveryCostItem): AreaForPresenter => {
  if (!data.states) return {};

  const areaData = {};

  data.states.forEach((cityData: CityData) => {
    if (cityData.is_all) {
      return (areaData[cityData.name] = '전체');
    }

    return (areaData[cityData.name] = getTownsForPresenter(data, cityData.name));
  });

  return areaData;
};

export const getSelectedVillageIdsExceptDisabled = (
  selectedIds: number[],
  disabledIds: number[]
): number[] => {
  const selectedIdsExceptDisabled = selectedIds.filter((id) => !disabledIds.includes(id));

  return selectedIdsExceptDisabled;
};

export const getAreaById = (
  defaultArea: AreaForDelivery,
  ids: string[] | string | undefined
): AreaForDelivery => {
  if (ids === undefined) return {};

  if (typeof ids === 'string') {
    ids = [ids];
  }

  const cities = Object.keys(defaultArea);
  const areaData = {};

  cities.forEach((city) => {
    const towns = Object.keys(defaultArea[city]);

    towns.forEach((town) => {
      const test = defaultArea[city][town].filter((village: VillageData) =>
        ids.includes(String(village.id))
      );

      if (test.length > 0) {
        areaData[city] = {
          ...areaData[city],
          [town]: test,
        };
      }
    });
  });

  return areaData;
};
