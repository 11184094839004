import '@imsmobility/react-ims-ui/antdStyles/css/antdDateRangePicker.css';

import DateRangePicker from '@imsmobility/react-ims-ui/dist/DateRangePicker.js';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import Address from '../../components/Address';
import Tooltip from '../Tooltip';
import styles from './CommonRowTable.module.scss';
import DateType from './DateType';
import DoubleType from './DoubleType';
import FileType from './FileType';
import FullDateType from './FullDateType';
import InputType from './InputType';
import InsuranceType from './InsuranceType';
import * as modules from './Modules';
import OutputType from './OutputType';
import RadioType from './RadioType';
import SelectType from './SelectType';
import TextAreaType from './TextAreaType';
import TimeType from './TimeType';

const NECESSARY_ICON = '/images/input_necessary.png';

@inject('modalStore')
@inject('loadingStore')
@inject('authStore')
@inject('menuStore')
@observer
class CommonRowTable2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labelHeight: null,
      startDate: null,
      endDate: null,
    };
  }

  columnStyle = (boolean) => {
    // 컬럼 통합시
    if (boolean) {
      return styles.table_column;
    }
  };

  rowInlineStyle = (info, option) => {
    // 컬럼 통합시
    if (info instanceof Array) {
      const size = info.map((item) => item.column).reduce((column, acc) => (column || 1) + acc);

      return {
        borderBottom: '1px solid #cccccc',
        width: '100%',
        height: `${36 * size + (size - 1)}px`,
      };
    }

    if (option === 'column' && info.column) {
      return {
        borderBottom: '1px solid #cccccc',
        width: '100%',
        height: `${36 * info.column}px`,
      };
    }

    if (option === 'column') {
      return { borderBottom: '1px solid #cccccc', width: '100%' };
    }

    if (info.column) {
      return { height: `${36 * info.column + (info.column - 1)}px` };
    }

    return {};
  };

  emptyCheck = (value) => {
    const stringValue = String(value).trim();

    if (
      stringValue === 'undefined' ||
      stringValue === 'null' ||
      stringValue === '' ||
      stringValue === 'false' ||
      stringValue === 'NaN'
    ) {
      return true;
    }
  };

  inputValueParser = (info) => {
    const stateData = toJS(this.props.stateData);
    const propsValue = info && stateData[info.key];

    if (this.emptyCheck(propsValue)) {
      return '';
    }

    if (info.key === 'partner_info') {
      return stateData.partner_info.partner_name;
    }

    if (info.key === 'sales_employee') {
      return stateData.sales_employee.realname;
    }

    return propsValue;
  };

  makeAddress = (item) => {
    const filteredRoadAddress = item.roadAddress.replace(`${item.sido} ${item.sigungu}`, '').trim();
    const buildingName = item.buildingName ? ` (${item.buildingName})` : '';

    return filteredRoadAddress + buildingName;
  };

  renderType = (info) => {
    const stateData = toJS(this.props.stateData);
    const monthValue = toJS(this.props.monthValue);
    const { setData, modalStore, loadingStore, menuStore, authStore } = this.props;
    const eventData = {
      info,
      stateData,
      modalStore,
      loadingStore,
      menuStore,
      authStore,
      setData,
    };

    if (eventData.info.type !== 'output') {
      modules.radioDefaultSet(eventData);
      modules.disabledSet(eventData);
    }

    switch (info.type) {
      case 'term':
        const { startDate, endDate } = this.state;
        const dateRangePickerProps = {
          dates: [startDate, endDate],
          onChange: (dates) => {
            this.setState(
              {
                startDate: dates[0],
                endDate: dates[1],
              },
              () => setData(info.key, dates)
            );
          },
          inputStyle: { padding: '3px 11px' },
          dropdownClassName: styles.picker,
        };

        return <DateRangePicker {...dateRangePickerProps} />;
      case 'date':
        return (
          <DateType
            info={info}
            stateData={stateData}
            setData={(key, value) => {
              setData(key, value);
            }}
            monthValue={monthValue}
          />
        );
      case 'fullDate':
        return (
          <FullDateType
            info={info}
            stateData={stateData}
            setData={(key, value) => {
              setData(key, value);
            }}
            monthValue={monthValue}
          />
        );
      case 'time':
        return (
          <TimeType
            info={info}
            stateData={stateData}
            setData={(key, value) => {
              setData(key, value);
            }}
            monthValue={monthValue}
          />
        );
      case 'radio':
        return (
          <RadioType
            info={info}
            stateData={stateData}
            setData={(key, value) => {
              setData(key, value);
            }}
          />
        );
      case 'select':
        return (
          <SelectType
            info={info}
            stateData={stateData}
            setData={(key, value) => setData(key, value)}
          />
        );
      case 'double':
        return (
          <DoubleType
            info={info}
            propValue={stateData[info.key]}
            stateData={stateData}
            setData={(key, value) => setData(key, value)}
          />
        );
      case 'address':
        return (
          <Address
            info={info}
            getAddress={(value) => setData(info.key, value)}
            value={stateData[info.key] || ''}
            searchGarageAddress={(item, detailAddress) => {
              setData(
                `${info.key}`,
                `${item.address}${
                  item.buildingName ? ` (${item.buildingName})` : ''
                } ${detailAddress}`
              );
              setData(`${info.key}_address1`, `${item.sido} ${item.sigungu}`.trim());
              setData(`${info.key}_address2`, this.makeAddress(item));
              setData(`${info.key}_address3`, detailAddress);
              setData(`${info.key}_jibunAddress`, item.jibunAddress || item.autoJibunAddress);
            }}
          />
        );
      case 'textarea':
        return (
          <TextAreaType
            info={info}
            propValue={stateData[info.key]}
            stateData={stateData}
            setData={(key, value) => setData(key, value)}
          />
        );
      case 'output':
        return <OutputType info={info} stateData={stateData} />;
      case 'insuranceType':
        // FIXME: 접수번호 전용 컴포넌트
        return (
          <InsuranceType
            info={info}
            stateData={stateData}
            setData={(key, value) => setData(key, value)}
          />
        );
      case 'file':
        return (
          <FileType
            ref={(r) => {
              if (typeof this.props?.fileRef === 'function') {
                this.props?.fileRef(info.key, r?.refs[info?.key]);
              }
            }}
            info={info}
            stateData={stateData}
            setData={(key, value) => setData(key, value)}
          />
        );

      default:
        return (
          <InputType
            info={info}
            propValue={this.inputValueParser(info)}
            stateData={stateData}
            setData={(key, value) => setData(key, value)}
          />
        );
    }
  };

  renderNecessary = (data) => {
    if (data) {
      return (
        <p className={styles.necessary}>
          <img src={NECESSARY_ICON} />
        </p>
      );
    }
  };

  renderTooltip = (data) => {
    if (data) {
      return (
        <Tooltip
          message={data.message}
          position="bottom"
          style={data.style}
          triangle={{ top: '0px', left: '64px', ...data.triangleStyle }}
        >
          <img className={styles.tooltip_icon} src={data.img} />
        </Tooltip>
      );
    }
  };

  renderSubLabel = (info) => {
    if (info.subLabel) {
      return <p style={info.labelStyle}>{info.subLabel}</p>;
    }
  };

  circlePosition = (position) => {
    switch (position) {
      case 'left':
        return styles.left;
      case 'bottom':
        return styles.bottom;
      default:
        break;
    }
  };

  RenderCircleNumber = (item) => {
    if (item.labelImgData) {
      return (
        <div
          className={`${styles.circle_number} ${this.circlePosition(item.labelImgData.position)}`}
          style={item.labelImgData.style || {}}
        >
          <img src={item.labelImgData.img} />
        </div>
      );
    }

    return '';
  };

  tableRowView = (data, option) => {
    // 테이블의 컬럼을 나누는 뷰
    const stateData = toJS(this.props.stateData);
    let column = null;

    return data.map((info, i) => {
      if (info.type === 'hide') {
        return;
      }

      if (info === 'column') {
        column = true;

        return;
      }

      if (info === 'clear') {
        column = null;

        return;
      }

      if (stateData.rent_request_id && info.key === 'insurance_age') {
        return;
      }

      if (stateData.rent_type && info.connectType && stateData.rent_type !== info.connectType) {
        return;
      }

      return (
        <div
          className={`${styles.row} ${this.columnStyle(column)} ${
            info.flex === 2 ? styles.flex_double : ''
          } ${info.lowerFlex ? styles.lower_flex : ''}`}
          key={i}
          style={this.rowInlineStyle(info, option)}
        >
          {info instanceof Array && this.tableRowView(info, 'column')}
          {!column && (
            <Fragment>
              <div
                className={styles.label}
                style={
                  (info.type === 'textarea' ? { height: info.style.height + 10 } : {},
                  this.props.connect ? { minWidth: '120px' } : this.props.labelStyle)
                }
              >
                <div className={styles.text} style={info.labelStyle}>
                  <div className={styles.necessary_area}>
                    {this.renderNecessary(info.necessary)}
                    {this.RenderCircleNumber(info)}
                    {info.label}
                    {/* TODO : 툴팁 출력 위치 및 사이즈 확인 */}
                    {this.renderTooltip(info.tooltip)}
                  </div>
                  {this.renderSubLabel(info)}
                </div>
                {/* {this.renderTooltip(info.tooltip)} */}
              </div>
              <div className={styles.row_contents_wrapper}>
                <div
                  style={info.valueStyle}
                  ref={(ref) => (this.labelHeight = ref?.clientHeight)}
                  className={styles.row_contents}
                >
                  {this.renderType(info)}
                </div>

                {info.notification && (
                  <div className={styles.row_notification} style={info.notification?.style}>
                    {info.notification?.text}
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </div>
      );
    });
  };

  dataView = (viewData) => {
    // 테이블의 라인을 나누는 뷰
    const stateData = toJS(this.props.stateData);

    if (viewData) {
      return viewData.map((tableInfo, i) => {
        if (!stateData?.self_car_insurance) {
          // 자차보험 미가입시
          if (
            tableInfo[0].key === 'self_car_insurance_limit' &&
            tableInfo[1].key === 'indemnfication_fee'
          ) {
            return;
          }
        }

        return (
          <div className={styles.row_wrap} key={i}>
            {this.tableRowView(tableInfo)}
          </div>
        );
      });
    }
  };

  renderBtnArea = () => {
    const tableBtn = toJS(this.props.tableBtn);

    return (
      <div className={styles.btn_area}>
        {tableBtn.map((item, i) => {
          return (
            <button
              style={item.style || {}}
              className={`${styles.table_btn} ${item.className || ''}`}
              key={i}
              onClick={() => {
                item.onClick();
                this.setState({ test: null });
              }}
            >
              {item.label}
            </button>
          );
        })}
      </div>
    );
  };

  render() {
    const { title, style } = this.props;
    const viewData = toJS(this.props.viewData);
    const tableBtn = toJS(this.props.tableBtn);

    return (
      <div className={styles.common_row_table_wrap} style={style || {}}>
        {title && <h3>{title}</h3>}
        {tableBtn && this.renderBtnArea()}
        <div className={styles.table_wrap}>{this.dataView(viewData)}</div>
      </div>
    );
  }
}
export default CommonRowTable2;
