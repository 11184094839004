import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';

export interface IModifyPeriodCostParams {
  id: number;
  start_at: string;
  end_at: string;
  is_basic: boolean;
  name: string;
  friday: boolean;
  monday: boolean;
  saturday: boolean;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  wednesday: boolean;
}

type SuccessResponse = {
  id: number;
  success: boolean;
};
export type IModifyPeriodCostResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function modifyPeriodCost(params: IModifyPeriodCostParams) {
  return callApi2.put<IModifyPeriodCostResponse>(
    `/v2/group-cost-tables/periods/${params.id}`,
    omit(params, ['id'])
  );
}
