import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import Table from '../../../components/Table';
import styles from './DuplicatedContractModal.module.scss';
@inject('modalStore')
@observer
class DuplicatedContractModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckNotViewAWeek: false,
    };
  }

  renderTbody = () => {
    // contract_type: "보험대차"
    // delivered_at: "2020.07.17"
    // id: 966
    // is_group_contract: 1
    // rent_car_name: "원렌트38번차"
    return this.props.list.map((item, i) => (
      <tr key={i}>
        <td>{item.contract_type}</td>
        <td>{item.delivered_at}</td>
        <td>{item.rent_car_name}</td>
        <td className={item.is_group_contract ? styles.is_group_contract : ''}>{item.id}</td>
      </tr>
    ));
  };
  render() {
    const { list } = this.props;
    const thead = ['계약유형', MESSAGE.ALLOCATE_CAR_DATE, '차량명', '연번'];

    return (
      <div className={styles.container}>
        <img
          onClick={this.props.modalStore.modalClose}
          className={styles.close}
          src={'/images/ic_close_44x44.png'}
        />
        <div className={styles.header}>
          <div>IMS form에 현재 배차중인 계약이</div>
          <div style={{ marginTop: 5 }}>
            <span className={styles.total}>{`총 ${list.length} 건`}</span>있습니다.
          </div>
        </div>
        <div className={styles.body}>
          <Table
            fixHeader
            theadWidth={[1, 1, 2, 1]}
            theadData={thead}
            tbodyData={this.renderTbody()}
          />
        </div>
        <div className={styles.desc}>
          <span style={{ color: '#0b55f9' }}>{`■ 자사 계약(본사, 지점 포함)`}</span>
          <span style={{ color: '#282828', marginLeft: 10 }}>{`■ 타사 계약`}</span>
        </div>
        <div className={styles.footer}>
          <div>중복 계약 건이 있으니, 계약을 신중히 진행해 주세요.</div>
          <div>렌카 고객센터 (1600-8348)</div>
        </div>
        <div className={styles.footer_button_wrap}>
          <div className={styles.footer_button} onClick={this.props.modalStore.modalClose}>
            {MESSAGE.CONFIRM}
          </div>
        </div>
      </div>
    );
  }
}

export default DuplicatedContractModal;
