import React, { Component, Fragment } from 'react';

import styles from './PageHeaderStyle.module.scss';

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }

  renderSubtitle = () => {
    let compo = [];

    if (this.props.subTitle) {
      this.props.subTitle.split('\\n').map((item, idx) => {
        compo.push(
          <div key={idx}>
            <span>{item}</span>
          </div>
        );
      });

      return (
        <Fragment>
          <div
            style={{
              padding: '0 30px 9px 30px',
              fontSize: 14,
              color: '#7f7e7e',
            }}
          >
            {compo}
          </div>
        </Fragment>
      );
    }

    return null;
  };

  renderTab = () => {
    const tabObj = this.props.tabs;

    return (
      <div className={styles.tab_container}>
        {tabObj.tabItems.map((tab, idx) => {
          let renderTab = null;

          if (tab.render) {
            renderTab = (
              <div
                key={idx}
                className={`${styles.tab} ${tab.key === tabObj.selectedTab && styles.selected_tab}`}
                onClick={() => tab.onClick(tab.key)}
              >
                {tab.label}
                {Number(tab.noti) > 0 && (
                  <span className={styles.tab_notification}>{tab.noti}</span>
                )}
              </div>
            );
          }

          return renderTab;
        })}
      </div>
    );
  };

  render() {
    return (
      <Fragment>
        <div
          className={`${styles.page_header_wrap} ${
            this.props.className ? this.props.className : ''
          }`}
        >
          <div className={styles.page_header_container}>
            {this.props.customTitle ? (
              this.props.customTitle
            ) : (
              <h1 className={styles.pageTitle}>{this.props.title}</h1>
            )}
            {this.props.tabs && this.renderTab()}
          </div>
          {this.renderSubtitle()}
        </div>
      </Fragment>
    );
  }
}

export default PageHeader;
