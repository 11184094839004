export const email = /^([a-z0-9_.-]+)@([\da-z.-]+)\.([a-z.]{2,6})$/;
export const mobile = /(01[016-9])-?([0-9]{3,4})-?([0-9]{4})$/;
export const userId = /^\w{4,20}$/;
export const userName = /^[ㄱ-ㅎ|가-힣a-zA-Z_+\s]{1,20}$/; //20210825 운전자명 공백만 허용, 그 외 실명(내정보수정, 회원가입 등)은 _, 공백 허용
export const driverName = /^[ㄱ-ㅎ|가-힣a-zA-Z\s*]{1,20}$/; //20210825 운전자명 공백만 허용, 그 외 실명(내정보수정, 회원가입 등)은 _, 공백 허용
export const companyName = /^[\w|ㄱ-ㅎ가-힣\s!@#$%^&*()+\-=[\]{};':"\\,.<>/?]{1,20}$/; //20200907 업체명 유효성 검사 조건 수정(20자 이내 모든 문자 허용)
export const carNumRegExp =
  /(^\d{2}[가-힣\x20]\d{4}$)|(^[가-힣\x20]{2}\d{1,2}[가-힣\x20]\d{4}$)|(^\d{3}[가-힣\x20]\d{4}$)/;
export const etcCarNumRegExp = /^[a-zA-Zㄱ-힣0-9|]*$/;
export const ownCar = /^[0-9|ㄱ-ㅎ가-힣a-zA-Z*]+$/;
export const companyAddress = /^[0-9|ㄱ-ㅎ가-힣a-zA-Z*]+$/;
export const password = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,20}$/;
export const alltelephone = /^[0-9_]{8,12}$/;
export const telephone = /^[0-9_]{8,12}$/;
export const phoneNumber = /^01([0|16-9]?)([0-9]{8})$/;
export const fax = /^[0-9_]{8,12}$/;
export const companyRegisterNumber = /^[0-9]{10}$/;
export const integer = /^[0-9]*$/;
export const certify = /^([0-9]){6}$/;
export const idNumber = /[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1])[1-4][0-9]{6}$/;
export const dateFormat = /(\(\d{4}\/\d{2}\/\d{1,2} \d{2}:\d{2})\).*/;
export const birthday = /^([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))$/;
export const base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)?$/;
export const dateYYYYMMDD = /^(19|20)\d{2}(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/;
export const businessType = /^[ㄱ-ㅎ|가-힣a-zA-Z\s()]{1,20}$/; // 사업자 등록의 업태, 종목에 적용 (영어, 한글, 공백, 괄호() 허용)

export const onlyKor = /^[ㄱ-ㅎ|가-힣]+$/; //한글만 입력
export const numberAndHypen = /^[0-9\-]+$/; // 숫자와 하이픈만 입력
export const checkRencarPartnerAdvisor = /(?=_)(.*advisor)/g;

/** 제안 내용에 허용되지 않은 특수 문자가 있는지 확인. */
export const extraResponseDisallowRegExp = /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z\d\s~!@#$%^&*,./\-+=<>[\]()?]+/g;
