import AtomInput from 'atoms/Input';
import Image from 'next/image';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
// import styled from 'styled-components';

interface SearchBoxProps {
  searchText: string;
  changeSearchText: (v: string) => void;
  getAddress: () => void;
}
export const SearchBox = ({ changeSearchText, searchText, getAddress }: SearchBoxProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const focusToInput = () => {
      if (!inputRef.current) {
        return;
      }

      inputRef.current.focus();
    };

    focusToInput();
  }, []);

  return (
    <SearchForm
      onSubmit={(e) => {
        e.preventDefault();

        getAddress();
      }}
    >
      <Input
        inputRef={inputRef}
        type="text"
        propValue={searchText}
        handleChange={(v: string) => changeSearchText(v)}
        placeholder="건물명 또는 도로명 혹은 지번 주소를 입력해 주세요."
        inlineStyle={{ fontSize: '14px' }}
      />
      <SearchButton type="submit">
        <Image src="/images/searchImages/search_black_icon.png" width="20px" height="20px" />
      </SearchButton>
    </SearchForm>
  );
};

export const SearchForm = styled.form`
  grid-template-columns: 1fr 40px;
  display: grid;
`;

export const Input = styled(AtomInput)`
  height: 42px;

  border-radius: 0;
  border-right: 0;

  padding-left: 12px;
  padding-right: 12px;
`;

export const SearchButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 42px;

  background-color: #efefef;
  border-radius: 0;
  border: 1px solid #d7d7d7;

  padding: 0;
`;
