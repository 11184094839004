import { DailyCostTable, Details } from 'components/DailyCostListTable/type';
import { cloneDeep, find, findIndex, omit, update } from 'lodash';
import { action, computed, makeAutoObservable, observable, toJS } from 'mobx';
import { createStore } from 'stores';

import * as apiGroupCost from '../api/groupCost';
import AuthStore from './AuthStore';
import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';
import LoadingStore from './LoadingStore';
import MenuStore from './MenuStore';

class GroupCostStore implements BaseStore<GroupCostStore> {
  groupNameList = [];
  totalPage = 1;
  dailyCostList: DailyCostTable[] = [];
  monthlyCostList = [];
  periodList: Array<{
    id: number;
    name: string;
    end_at: string;
    is_basic: true;
    start_at: string;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
    sunday?: boolean;
  }> = [];
  selectedPeriod: {
    id?: number;
    name?: string;
    is_basic?: boolean;
    monday?: boolean;
    tuesday?: boolean;
    wednesday?: boolean;
    thursday?: boolean;
    friday?: boolean;
    saturday?: boolean;
    sunday?: boolean;
    end_at?: string;
    start_at?: string;
  } = {};

  dailyCostDetail: {
    car_groups: Array<{
      id: number;
      name: string;
    }>;
    company_id: number;
    details: Array<any>;
    id: number;
    name: string;
    removed_at?: null;
    updated_at?: null;
  } = {
    company_id: null,
    details: [],
    id: null,
    name: null,
    removed_at: null,
    updated_at: null,
    car_groups: [],
  };
  monthlyCostDetail: {};
  backupDailyCostList = [];
  isCompareDailyCostList = true;
  dailyCostListTableHeaderWeekPercentage = {
    weekday: null,
    weekend: null,
  };
  dailyCostListSearchInputText = '';
  authStore: AuthStore;
  menuStore: MenuStore;
  loadingStore: LoadingStore;

  constructor(authStore: AuthStore, menuStore: MenuStore, loadingStore: LoadingStore) {
    this.authStore = authStore;
    this.menuStore = menuStore;
    this.loadingStore = loadingStore;
    this.init();
    makeAutoObservable(this);
  }

  setObject = (data) => _setObject(this, data);

  init = () => {
    this.groupNameList = [];
    this.totalPage = 1;
    this.dailyCostList = [];
    this.monthlyCostList = [];
    this.periodList = [];
    this.selectedPeriod = {};
    this.dailyCostDetail = {
      car_groups: [],
      company_id: null,
      details: [],
      id: null,
      name: null,
      removed_at: null,
      updated_at: null,
    };
    this.monthlyCostDetail = {};
    this.isCompareDailyCostList = true;
    this.backupDailyCostList = [];
    this.dailyCostListTableHeaderWeekPercentage = {
      weekday: null,
      weekend: null,
    };
    this.dailyCostListSearchInputText = '';
  };

  getDailyAllList = (query?) => {
    this.getGroupNameList();
    this.getPeriodList(query, (periodList) => {
      if (query.period_id) {
        const selectedPeriod = find(periodList, { id: Number(query.period_id) });

        this.setSelectedPeriod(selectedPeriod);
        this.getDailyCostList({ period_id: selectedPeriod.id, ...query });
      } else if (periodList[0]) {
        this.setSelectedPeriod(periodList[0]);
        this.getDailyCostList({ period_id: periodList[0].id, ...query });
      }
    });
  };

  setDailyCostListTableHeaderWeekPercentage = (week, value) => {
    this.dailyCostListTableHeaderWeekPercentage[week] = value;
  };

  /*  
    테이블 input change 함수 
    put 업데이트시 구간요금이든 기본요금이든 period_detail 객체에 담아 보내기떄문에
    period_detail값만 변경 한다.
  */
  setDailyListTable = ({ id, key, value }) => {
    const setIndex = findIndex(this.dailyCostList, { id });
    const replaceKey = key.replace('.', '_');
    const $costName = document.getElementsByClassName(`cost_name`)[setIndex];
    const $cost = document.getElementsByClassName(`weekend_${key}`)[setIndex];
    const $other = document.getElementsByClassName(`${replaceKey}`)[setIndex];
    const regExp = /._cost/;
    const perRegExp = /._percentage/;

    this.dailyCostList[setIndex].isDefault = false;

    if (perRegExp.test(replaceKey)) {
      this.dailyCostList[setIndex].isBatch = true;

      if (!value) {
        this.dailyCostList[setIndex].isBatch = false;
      }
    }

    if (key === 'cost') {
      this.dailyCostList[setIndex].period_detail.weekday[key] =
        value === '' ? value : Number(value);
      this.dailyCostList[setIndex].period_detail.weekend[key] =
        value === '' ? value : Number(value);

      if ($cost.classList.contains('empty_error')) {
        $cost.classList.remove('empty_error');
      }

      return;
    }

    if (key === 'name') {
      this.dailyCostList[setIndex].name = value;

      if ($costName.classList.contains('empty_error')) {
        $costName.classList.remove('empty_error');
      }

      return;
    }

    if ($other.classList.contains('empty_error')) {
      $other.classList.remove('empty_error');
    }

    if (regExp.test(key)) {
      this.dailyCostList[setIndex].period_detail.weekday.percentage = null;
      this.dailyCostList[setIndex].period_detail.weekend.percentage = null;
    }

    update(this.dailyCostList[setIndex], `period_detail.${key}`, () => {
      return value === '' || value === null ? value : Number(value);
    });
  };

  // 요금표 리스트 업데이트 validation 체크
  dailyCostListTableValidation = () => {
    const costBoolArray = [];
    const nameBoolArray = [];
    const overlapIndexArray = [];
    const percentageArray = [];
    const regExp = /h6_cost|h12_cost|percentage/;
    const names = Object.values(this.dailyCostList).map((arr) => {
      return arr.name;
    });

    this.dailyCostList.forEach((arr, idx) => {
      // weekday 체크 후 border 색변경
      Object.keys(arr.period_detail.weekday).forEach((key) => {
        if (key === 'percentage') {
          if (arr.period_detail.weekday[key] < 10 && arr.period_detail.weekday[key] > 100) {
            percentageArray.push(false);
          } else {
            percentageArray.push(true);
          }
        } else if (arr.period_detail.weekday[key] === '') {
          if (document.getElementsByClassName(`weekday_${key}`).length > 0) {
            if (!regExp.test(key)) {
              costBoolArray.push(false);
              document.getElementsByClassName(`weekday_${key}`)[idx].classList.add('empty_error');
            }
          }
        } else {
          costBoolArray.push(true);
        }
      });
      // weekend 체크 후 border 색변경
      Object.keys(arr.period_detail.weekend).forEach((key) => {
        if (key === 'percentage') {
          if (arr.period_detail.weekday[key] < 10 && arr.period_detail.weekday[key] > 100) {
            percentageArray.push(false);
          } else {
            percentageArray.push(true);
          }
        } else if (arr.period_detail.weekend[key] === '') {
          if (document.getElementsByClassName(`weekend_${key}`).length > 0) {
            if (!regExp.test(key)) {
              costBoolArray.push(false);
              document.getElementsByClassName(`weekend_${key}`)[idx].classList.add('empty_error');
            }
          }
        } else {
          costBoolArray.push(true);
        }
      });

      if (arr.name === '') {
        nameBoolArray.push(false);
        document.getElementsByClassName(`cost_name`)[idx].classList.add('empty_error');
      } else {
        nameBoolArray.push(true);
      }
    });

    names.forEach((arr, idx) => {
      if (names.indexOf(arr) !== idx) {
        overlapIndexArray.push(idx);
      }
    });

    if (overlapIndexArray.length > 0) {
      overlapIndexArray.forEach((arr) => {
        document
          .getElementsByClassName('cost_name')
          [arr].setAttribute('style', 'border: 1px solid red');
      });
      alert('동일한 요금표 이름이 있습니다. 요금표 이름을 변경해주세요.');

      return false;
    }

    if (nameBoolArray.filter((arr) => !arr).length > 0) {
      alert('입력되지 않은 요금표 이름이 있습니다. 확인 후 다시 시도해주세요.');

      return false;
    }

    if (costBoolArray.filter((arr) => !arr).length > 0) {
      alert('입력되지 않은 요금이 있습니다. 확인 후 다시 시도해주세요.');

      return false;
    }

    if (percentageArray.filter((arr) => !arr).length > 0) {
      alert('적용할 판매가 비율은 10부터 100까지의 숫자만 입력할 수 있습니다.');

      return false;
    }

    return true;
  };

  // 일요금표 업데이트
  submitDailyCostList = async (query?) => {
    if (!this.dailyCostListTableValidation()) {
      return;
    }

    const periodId = this.selectedPeriod.id;
    const reArray = this.dailyCostList.map((arr: DailyCostTable) => {
      return {
        id: arr.id,
        name: arr.name,
        period_detail: !arr.isDefault
          ? {
              weekday: {
                ...omit(arr.period_detail.weekday, ['period_id', 'h6_cost', 'h12_cost']),
                percentage: arr.isBatch
                  ? arr.batchPercentage.weekday
                  : !arr.period_detail.weekday.percentage
                  ? null
                  : arr.period_detail.weekday.percentage,
                h6_cost: arr.period_detail.weekday.h6_cost ? arr.period_detail.weekday.h6_cost : 0,
                h12_cost: arr.period_detail.weekday.h12_cost
                  ? arr.period_detail.weekday.h12_cost
                  : 0,
              },
              weekend: {
                ...omit(arr.period_detail.weekend, ['period_id', 'h6_cost', 'h12_cost']),
                percentage: arr.isBatch
                  ? arr.batchPercentage.weekend
                  : !arr.period_detail.weekend.percentage
                  ? null
                  : arr.period_detail.weekend.percentage,
                h6_cost: arr.period_detail.weekend.h6_cost ? arr.period_detail.weekend.h6_cost : 0,
                h12_cost: arr.period_detail.weekend.h12_cost
                  ? arr.period_detail.weekend.h12_cost
                  : 0,
              },
            }
          : null,
        car_group_ids: arr.car_groups.map((item) => item.id),
      };
    });

    const params: {
      id: number;
      daily_cost_tables: (Pick<DailyCostTable, 'id' | 'name'> & {
        car_group_ids: number[];
        period_detail: {
          weekday: Omit<Details, 'period_id'>;
          weekend: Omit<Details, 'period_id'>;
        } | null;
      })[];
    } = {
      id: periodId,
      daily_cost_tables: reArray,
    };

    try {
      await apiGroupCost.putDailyGroupCost(params);
      this.isCompareDailyCostList = true;
      this.getDailyAllList(query);
    } catch (error) {
      console.log(error);
    } finally {
      alert('요금 정보가 저장되었습니다.');
    }
  };

  // 테이블 chekbox 에따라 이전 데이터 업데이트 함수
  setChangeDefaultDailyList = (id: number, bool: boolean) => {
    const setIndex = findIndex(this.dailyCostList, { id });

    const defaultDailyCost = bool
      ? this.backupDailyCostList[setIndex].default_detail
      : this.backupDailyCostList[setIndex].period_detail;

    this.dailyCostList[setIndex].isDefault = bool;

    this.dailyCostList[setIndex].period_detail = cloneDeep(defaultDailyCost);
  };
  setDailyCostListSearchInputText = (value: string) => {
    this.dailyCostListSearchInputText = value ? value : '';
  };

  // 1일기준가에서 주중 주말 비율에따른 요금 계산 함수
  setBatch = (
    id: number,
    value: Record<
      'weekday' | 'weekend',
      Record<'d1_cost' | 'd3_cost' | 'd5_cost' | 'd7_cost' | 'h1_cost', number>
    >
  ) => {
    const setIndex = findIndex(this.dailyCostList, { id });

    this.dailyCostList[setIndex].isBatch = false;
    this.dailyCostList[setIndex].batchPercentage = {
      weekday: this.dailyCostList[setIndex].period_detail.weekday.percentage,
      weekend: this.dailyCostList[setIndex].period_detail.weekend.percentage,
    };

    Object.keys(value).forEach((arr) => {
      update(this.dailyCostList[setIndex], `period_detail.${arr}`, (n) => {
        return { ...n, ...value[arr] };
      });
    });
  };

  // 전체 리스트 setBatch
  setBatchBulk = (
    percentage: { weekday: number | null; weekend: number | null },
    costWeekCalcArray: (cost: number, percentage: number) => any
  ) => {
    if (!percentage.weekday && !percentage.weekend) {
      return;
    }

    this.dailyCostList = this.dailyCostList.map((arr, idx) => {
      const weekday = percentage.weekday
        ? {
            ...arr.period_detail.weekday,
            ...costWeekCalcArray(arr.period_detail.weekday.cost, percentage.weekday),
            percentage: percentage.weekday,
          }
        : arr.period_detail.weekday;
      const weekend = percentage.weekend
        ? {
            ...arr.period_detail.weekend,
            ...costWeekCalcArray(arr.period_detail.weekday.cost, percentage.weekend),
            percentage: percentage.weekend,
          }
        : arr.period_detail.weekend;

      this.dailyCostList[idx].isDefault = false;

      arr.period_detail.weekday = weekday;
      arr.period_detail.weekend = weekend;

      return arr;
    });
  };

  // 요금표 리스트 페이지 떠날때 수정된겂이 있는지 없는지 체크
  setIsCompareDailyCostList = (bool: boolean) => {
    this.isCompareDailyCostList = bool;
  };

  getMonthlyAllList = (query?) => {
    this.loadingStore.on();
    this.getGroupNameList();
    this.getMonthlyCostList(query, () => {
      this.loadingStore.off();
    });
  };

  getGroupNameList = () => {
    this.loadingStore.on();
    apiGroupCost
      .getGroupNameList(
        {},
        {
          authStore: this.authStore,
          menuStore: this.menuStore,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          this.groupNameList = data.car_groups;
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.loadingStore.off();
      });
  };
  getDailyCostList = (query?: any) => {
    this.loadingStore.on();
    let parsedQuery = query.car_group_id === 'all' ? omit(query, ['car_group_id']) : query;

    parsedQuery = parsedQuery.period_id === 0 ? omit(parsedQuery, ['period_id']) : parsedQuery;

    this.dailyCostList = [];

    //period
    apiGroupCost
      .getDailyCostList(parsedQuery, { authStore: this.authStore, menuStore: this.menuStore })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          this.dailyCostList = data.daily_cost_tables.map((arr) => {
            const period_detail = arr.period_detail ? arr.period_detail : arr.default_detail;
            const isDefault = !arr.period_detail;

            return {
              ...arr,
              period_detail,
              isBatch: false,
              isDefault,
              batchPercentage: {
                weekday: period_detail.weekday.percentage,
                weekend: period_detail.weekend.percentage,
              },
            };
          });
          this.dailyCostListTableHeaderWeekPercentage = {
            weekday: null,
            weekend: null,
          };
          this.isCompareDailyCostList = true;
          this.backupDailyCostList = cloneDeep(this.dailyCostList);

          this.totalPage = data.total_page;
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        this.loadingStore.off();
      });
  };

  getMonthlyCostList = (query?: any, callback?: Function) => {
    this.loadingStore.on();
    const parsedQuery = query.car_group_id === 'all' ? omit(query, ['car_group_id']) : query;

    apiGroupCost
      .getMonthlyCostList(parsedQuery, this)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          this.monthlyCostList = data.monthly_cost_tables;
          this.totalPage = data.total_page;
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  };

  getPeriodList = (query?: any, callback?: Function) => {
    const parsedQuery = {
      authStore: this.authStore,
      menuStore: this.menuStore,
      ...query,
    };

    apiGroupCost
      .getPeriodsCost(parsedQuery)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          if (data.periods.filter((i) => i.is_basic).length === 0) {
            //기본요금 구간이 없을때는 임의로 생성한다.
            data.periods.unshift({
              end_at: '2200-12-31',
              friday: false,
              id: 0,
              is_basic: true,
              monday: false,
              name: '기본 요금',
              saturday: false,
              start_at: '1900-01-01',
              sunday: false,
              thursday: false,
              tuesday: false,
              wednesday: false,
            });
          }

          this.periodList = data.periods;

          if (callback) {
            callback(data.periods);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  setSelectedPeriod = (item) => {
    this.selectedPeriod = item;
  };

  getDailyCostDetail = (query?: any) => {
    apiGroupCost
      .getDailyCostDetail(query, this)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          this.dailyCostDetail = data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  getMonthlyCostDetail = (query?: any) => {
    apiGroupCost
      .getMonthlyCostDetail(query, this)
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;

          this.monthlyCostDetail = data;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

export default GroupCostStore;
