import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import styles from './RencarCheckBox.module.scss';

const defaultCheck = '/images/ic-check-terms@3x.png';

const RencarCheckBox = (props) => {
  return (
    <div
      className={classNames(props.containerClass || {}, styles.container)}
      style={props.containerStyle}
    >
      <div
        // className={classNames(props.checkClass || {}, styles.check)}
        className={`${styles.check} ${props.checkClass || {}} ${props.disabled && styles.disabled}`}
        style={props.checkStyle}
        data-active={String(Boolean(props.value))}
        onClick={() => {
          if (props.disabled) {
            props.disabledAction && props.disabledAction();

            return;
          }

          return props.onChange();
        }}
      >
        {props.value ? (
          <img className={props.imgClass} src={props.activeSrc || defaultCheck} />
        ) : props.deactiveSrc ? (
          <img className={props.imgClass} src={props.deactiveSrc} />
        ) : null}
      </div>
      <div
        className={classNames(props.textClass || {}, styles.text)}
        style={props.isOnClickAllArea ? { ...props.textStyle, cursor: 'pointer' } : props.textStyle}
        // onClick={props.isOnClickAllArea ? props.onChange : () => void 0}
        onClick={
          props.isOnClickAllArea
            ? () => {
                if (props.disabled) {
                  props.disabledAction && props.disabledAction();

                  return;
                }

                return props.onChange();
              }
            : () => void 0
        }
      >
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export default observer(RencarCheckBox);
