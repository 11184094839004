import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import apiRentCompanyPartner from '../../../api/rentCompanyPartner';
import Button from '../../../atoms/Button';
import Paging from '../../../components/Paging';
import SearchSelectInput from '../../../components/SearchSelectInput/index.tsx';
import Table from '../../../components/Table';
import Tooltip from '../../../components/Tooltip';
import { partnerSelectOption } from '../../../constants/searchOptions';
import { validator } from '../../../helpers/validator';
import * as parser from '../../../utils/DataParser';
import styles from './PartnerModalContentStyle.module.scss';

@inject('modalStore')
@inject('authStore')
@inject('menuStore')
@observer
class PartnerModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      partnerList: [],
      currentPage: 1,
      totalPage: null,
      searchOption: partnerSelectOption[0],
      searchValue: null,
      alimtalk: Boolean(props.alimtalk),
    };
  }

  componentDidMount() {
    this.getPartnerList(1);
  }

  getPartnerList = async (page) => {
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
      searchValue: this.state.searchValue,
      searchOptionValue: this.state.searchOption.value,
      page,
    };

    try {
      const response = await apiRentCompanyPartner.getPartnerList(params);
      const responseData = response.data;

      if (response.status == 200) {
        this.setState(
          {
            partnerList: responseData.list,
            currentPage: page,
            totalPage: responseData.totalPage,
          },
          () => {
            if (this.props.defaultPartner) {
              this.setState({
                selectedItem: this.props.defaultPartner,
              });
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  getSearchList = async (e, newPage = 1) => {
    e.preventDefault();
    catchAsync(async () => {
      if (this.state.searchValue === null) {
        return alert('검색어를 입력해주세요.');
      }

      const page = newPage;
      const params = {
        menuStore: this.props.menuStore,
        authStore: this.props.authStore,
        searchValue: this.state.searchValue,
        searchOptionValue: this.state.searchOption.value,
        page,
      };

      const response = await apiRentCompanyPartner.getPartnerList(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.setState({
          currentPage: page,
          partnerList: responseData.list,
          totalPage: responseData.totalPage,
        });
      }
    });
  };

  movePage = (page) => {
    if (this.state.currentPage != page) {
      this.getPartnerList(page);
    }
  };

  submit = () => {
    this.props.selectPartner({
      ...this.state.selectedItem,
      is_send_recommender_message: this.state.alimtalk,
    });
  };

  selectCheck = (item) => {
    if (this.state.selectedItem?.id == item.id) {
      return this.setState({ selectedItem: null, alimtalk: false });
    }

    this.setState({
      selectedItem: item,
      alimtalk: validator(item.partner_contact, 'mobile'),
    });
  };

  tbodyData = () => {
    if (this.state.partnerList.length > 0) {
      return this.state.partnerList.map((item, i) => {
        let checkboxStyle;

        if (item.id === this.state.selectedItem?.id) {
          checkboxStyle = styles.active_checkbox;
        } else {
          checkboxStyle = styles.inactive_checkbox;
        }

        return (
          <tr
            key={i}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              this.selectCheck(item);
            }}
          >
            <td key={i}>
              <div className={`${styles.checkbox_div} ${checkboxStyle}`}>
                <img src={'/images/checkboxImage/check_icon_white_thin.png'} />
              </div>
            </td>
            <td>{parser.abbreviation(parser.defaultParser(item.partner_name), 8)}</td>
            <td>{parser.abbreviation(parser.defaultParser(item.partner_manager), 3)}</td>
            <td>{parser.abbreviation(parser.contractParser(item.partner_contact), 15)}</td>
          </tr>
        );
      });
    }
  };

  renderAlimtalkArea = () => {
    const { selectedItem } = this.state;

    if (this.props.hideAlimtalk || !selectedItem) return <div />;

    if (!selectedItem.partner_contact || !validator(selectedItem.partner_contact, 'phoneNumber')) {
      return (
        <div className={styles.alimtalk_wrap}>
          <div className={styles.cant_alimtalk_label}>소개처 담당자 알림톡 미발송</div>
          <p className={styles.alimtalk_notice_text}>
            {"알림톡 발송이 필요하시면 '소개처 관리'에서 담당자 휴대폰 번호를 등록해 주세요."}
          </p>
        </div>
      );
    }

    return (
      <div
        className={styles.alimtalk_wrap}
        onClick={() => this.setState({ alimtalk: !this.state.alimtalk })}
      >
        <div className={styles.alimtalk_notice_title_wrap}>
          <div
            className={`${styles.alimtalk_checkbox_div}
              ${this.state.alimtalk ? styles.active_checkbox : styles.inactive_checkbox}`}
          >
            <img
              className={styles.alimtalk_check_img}
              src={'/images/checkboxImage/check_icon_white_thin.png'}
            />
          </div>
          <div className={styles.alimtalk_label}>알림톡 발송</div>
        </div>
        <p className={styles.alimtalk_notice_text}>
          배차, 반납, 연장(월렌트), 계약 유형 전환(보험→일반, 되돌리기)시 소개처 담당자에게
          <br />
          안내 알림톡이 발송됩니다.
        </p>
      </div>
    );
  };

  resetSearch = () => {
    this.setState({ searchValue: '', searchOption: partnerSelectOption[0] }, () =>
      this.getPartnerList(1)
    );
  };

  render() {
    return (
      <div className={styles.partner_modal_wrap}>
        <div className={styles.search_input_wrap}>
          <form onSubmit={this.getSearchList}>
            <SearchSelectInput
              type="text"
              searchTitle="검색조건"
              placeholder="검색어를 입력하세요."
              searchInputOption={partnerSelectOption}
              searchSelect={(option) => this.setState({ searchOption: option })}
              searchInput={(value) => this.setState({ searchValue: value })}
              onClick={this.getSearchList}
              searchOptionValue={this.state.searchOption}
              searchInputValue={this.state.searchValue}
            />
          </form>
          <Button
            btnText="초기화"
            btnClick={this.resetSearch}
            className={styles.reset_button}
            outline={true}
          />
        </div>
        <div className={styles.table_area}>
          <Table
            headFix
            theadWidth={[10, 30, 30, 30]}
            theadData={['선택', '소개처명', '담당자', '연락처']}
            tbodyData={this.tbodyData()}
            emptyTable={{ text: '검색된 내역이 없습니다.', style: { fontSize: '13px' } }}
          />
        </div>
        <Paging
          currentPage={this.state.currentPage}
          totalPage={this.state.totalPage}
          movePage={this.movePage}
        />

        <div className={styles.footer}>
          {this.renderAlimtalkArea()}
          <div className={styles.button_wrap}>
            <div
              className={styles.cancel_button}
              onClick={() => this.props.modalStore.modalClose()}
            >
              {MESSAGE.CANCEL}
            </div>
            <div className={styles.complete_button} onClick={this.submit}>
              {MESSAGE.SAVE}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerModalContent;
