import * as callApi2 from '../../helpers/callApi2';

/**
 * @summary tmap에 주소 요청
 * @link https://tmapapi.sktelecom.com/main.html#webservice/docs/tmapPoiSearch
 */
export const getAddressWithTmapApi = async (searchValue: string) => {
  const path = `/v2/map/poi?&searchKeyword=${searchValue}&count=10`;

  const response = await callApi2.get<any>(path, null);

  return response;
};
