import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import apiCarGroupNameList from '../../../api/groupList';
import apiConnect from '../../../api/imsConnect';
import apiRentCompanyCar from '../../../api/rentCompanyCar';
import Button from '../../../atoms/Button/index.tsx';
import DropdownSearchInput from '../../../components/DropdownSearchInput';
import Paging from '../../../components/Paging';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import styles from './CarModalContentStyle.module.scss';
const CONNECTED_CAR_IMAGE = '/images/carControlImages/ic-carcontrol-twotone.png';
const theadData = [
  '선택',
  '',
  '차량번호',
  '그룹명',
  '차량명',
  {
    type: 'text',
    label: '연식',
    showTooltip: true,
    tooltipMessage: '최초 등록일',
    theadPartStyle: {
      textAlign: 'right',
      paddingRight: 10,
      position: 'sticky',
      zIndex: 9,
      top: 0,
    },
  },
  '유종',
  '차량상태',
];

@inject('modalStore')
@inject('authStore')
@inject('menuStore')
@inject('loadingStore')
@observer
class CarModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: null,
      selectedId: null,
      carList: [],
      currentPage: 1,
      totalPage: null,
      searchValue: '',
      carGroupNames: [],
      groupNameSearchValue: '',
    };
  }

  componentDidMount() {
    this.getCompanyCarList(1);
    this.getCarGroupNameList();
  }

  getCarGroupNameList = async () => {
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
    };

    catchAsync(async () => {
      this.props.loadingStore.on();
      const response = await apiCarGroupNameList.getCarGroupNameList(params);
      const responseData = response.data;

      if (response.status === 200) {
        const groupNameList = responseData.car_groups.map((item) => {
          return { value: item.id, label: item.name };
        });

        this.setState({
          carGroupNames: groupNameList,
        });
      }
    }, this.props.loadingStore.off);
  };
  getCompanyCarList = async (page) => {
    this.setState({ callApi: true });

    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
      normalContractId: this.props.normalContractId,
      page,
    };
    const response = await apiRentCompanyCar.getCarList(params);
    const responseData = response.data;

    if (response.status === 200) {
      this.setState(
        {
          carList: responseData.list,
          currentPage: page,
          totalPage: responseData.totalPage,
          selectedId: this.props.defaultCarId,
        },
        () => {
          if (this.state.carList) {
            this.setState({
              selectedItem: this.state.carList.find((row) => row.id === this.state.selectedId),
            });
          }
        }
      );
    }

    setTimeout(() => this.setState({ callApi: false }), 1000);
  };

  checkImage = (infoId) => {
    let check;

    if (this.state.selectedItem) {
      if (this.state.selectedItem.id == infoId) {
        check = (
          <img
            width="15"
            height="15"
            style={{ position: 'absolute', left: '1px', top: '1px' }}
            src="/images/checkboxImage/check_icon.png"
          />
        );
      } else {
        check = <div style={{ width: '15px', height: '15px' }}></div>;
      }

      return check;
    }
  };

  choiceCar = (info) => {
    if (this.state.selectedId == info.id) {
      return this.setState({ selectedId: null, selectedItem: null });
    }

    if (info.use_connect) {
      this.getConnectCarData(info);
    } else if (info.using_state !== 'waiting') {
      if (
        confirm(
          `현재 ${this.usingStateParser(info.using_state)}인 차량입니다. 그래도 선택 하시겠습니까?`
        )
      ) {
        this.setState({ selectedItem: info, selectedId: info.id });
      } else {
      }
    } else {
      this.setState({ selectedItem: info, selectedId: info.id });
    }
  };

  usingStateParser = (state) => {
    switch (state) {
      case 'using':
        return '대여중';
      case 'employee_use':
        return '직원 사용중';
      case 'repair':
        return '수리중';
      case 'waiting':
        return '대기';
      case 'is_day_off':
        return '휴무';
      default:
        return '';
    }
  };

  movePage = (page) => {
    if (this.state.currentPage != page) {
      this.getSearchList(page);
    }
  };

  submit = (e) => {
    e.preventDefault();

    if (this.state.searchValue && !this.state.callApi) {
      this.getSearchList();
    }
  };

  getSearchList = (newPage = 1) => {
    this.setState({ callApi: true });
    catchAsync(async () => {
      const page = newPage;
      const value = this.state.searchValue;
      const params = {
        menuStore: this.props.menuStore,
        authStore: this.props.authStore,
        normalContractId: this.props.normalContractId,
        searchValue: value,
        page,
      };

      if (this.state.groupNameSearchValue) {
        params.carGroupId = this.state.groupNameSearchValue;
      }

      const response = await apiRentCompanyCar.getCarList(params);
      const responseData = response.data;

      if (response.status == 200) {
        this.setState(
          {
            carList: responseData.list,
            currentPage: page,
            totalPage: responseData.totalPage,
          },
          () => {
            if (this.state.carList) {
              this.setState({
                selectedItem: this.state.carList.find((row) => row.id === this.state.selectedId),
              });
            }
          }
        );
      }
    });

    setTimeout(() => this.setState({ callApi: false }), 1000);
  };

  tbodyData = () => {
    if (this.state.carList.length > 0) {
      return this.state.carList.map((item, i) => {
        const carAge = dayjs(item.car_age);
        const carAgeText = carAge.isValid() ? carAge.format('YY.MM') : '확인 필요';
        const getCarGroupName = (groupName) => {
          if (!groupName) {
            return '-';
          }

          if (groupName.length > 15) {
            return `${groupName.substring(0, 15)}...`;
          }

          return groupName;
        };

        let checkboxStyle;

        if (item.id === this.state.selectedId) {
          checkboxStyle = styles.active_checkbox;
        } else {
          checkboxStyle = styles.inactive_checkbox;
        }

        return (
          <tr key={i} style={{ cursor: 'pointer' }} onClick={() => this.choiceCar(item)}>
            <td key={i}>
              <div className={`${styles.checkbox_div} ${checkboxStyle}`}>
                <img src="/images/checkboxImage/check_icon_white_thin.png" />
              </div>
            </td>
            <td>
              {this.props.authStore.use_connect && item.use_connect ? (
                <div className={styles.connected_car_image_wrap}>
                  <img style={{ width: 16, height: 16 }} src={CONNECTED_CAR_IMAGE} />
                </div>
              ) : null}
            </td>
            <td>
              <span>{item.car_identity}</span>
            </td>
            <td>{getCarGroupName(item.car_group_name)}</td>
            <td>{item.car_name}</td>
            <td>{carAgeText}</td>
            <td>{item.oil_type}</td>
            <td>
              {this.usingStateParser(
                item.is_day_off && item.using_state !== 'using' ? 'is_day_off' : item.using_state
              )}
            </td>
          </tr>
        );
      });
    }
  };

  init = () => {
    if (this.state.searchValue !== null && !this.state.callApi) {
      this.setState({ searchValue: '', groupNameSearchValue: '' });
      this.getCompanyCarList(1);
    }
  };

  getConnectCarData = async (info) => {
    const params = {
      carId: info.car_identity,
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
    };

    try {
      this.props.loadingStore.on();
      const response = await apiConnect.getCarData(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.props.loadingStore.off();
        info.fuelRemainPct = responseData.fuelRemainPct;
        info.totalMileage = Math.round(responseData.totalMileage);

        if (info.using_state !== 'waiting') {
          if (
            confirm(
              `현재 ${this.usingStateParser(
                info.using_state
              )}인 차량입니다. 그래도 선택 하시겠습니까?`
            )
          ) {
            this.setState({ selectedItem: info, selectedId: info.id });
          } else {
            return;
          }
        } else {
          this.setState({ selectedItem: info, selectedId: info.id });
        }
      } else {
        this.props.loadingStore.off();

        if (info.using_state !== 'waiting') {
          if (
            confirm(
              `현재 ${this.usingStateParser(
                info.using_state
              )}인 차량입니다. 그래도 선택 하시겠습니까?`
            )
          ) {
            this.setState({ selectedItem: info, selectedId: info.id });
          } else {
            return;
          }
        } else {
          this.setState({ selectedItem: info, selectedId: info.id });
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.props.loadingStore.off();
    }
  };

  render() {
    const { use_connect } = this.props.authStore;

    return (
      <div className={styles.select_car_modal_wrap}>
        <div className={styles.search_line_wrap}>
          {use_connect && (
            <div className={styles.connected_car}>
              <div className={styles.connect_image_wrap}>
                <img src={CONNECTED_CAR_IMAGE} />
              </div>
              <div className={styles.connect_text}>IMS.connect 연동 차량</div>
            </div>
          )}

          <div className={styles.search_wrap}>
            <span className={styles.group_name}>그룹명</span>
            <DropdownSearchInput
              width={200}
              height={36}
              list={this.state.carGroupNames}
              value={this.state.groupNameSearchValue}
              onChange={(value) => {
                this.setState({ groupNameSearchValue: value }, this.getSearchList);
              }}
              notSelectedText="전체"
            />

            <form onSubmit={this.submit}>
              <div className={styles.search_input_wrap}>
                <SearchInput
                  type="text"
                  placeholder="차량명, 차량번호 검색"
                  style={{ width: '250px', marginLeft: '13px' }}
                  onChange={(value) => this.setState({ searchValue: value })}
                  value={this.state.searchValue}
                />
                <Button
                  btnText="초기화"
                  outline
                  inlineStyle={{
                    marginLeft: 10,
                    color: '#575757',
                    fontSize: 13,
                    padding: '10px 12px',
                    lineHeight: 0,
                    backgroundColor: '#ffffff',
                  }}
                  btnClick={() => this.init()}
                />
              </div>
            </form>
          </div>
        </div>
        <div className={styles.table_area}>
          <Table
            headFix
            theadWidth={[8, 2, 12, 28, 20, 10, 10, 10]}
            theadData={theadData}
            tbodyData={this.tbodyData()}
            emptyTable={{
              text: this.state.searchValue
                ? '검색된 내역이 없습니다.'
                : '선택 가능한 차량이 없습니다.',
              style: { fontSize: '13px' },
            }}
          />
        </div>
        <Paging
          currentPage={this.state.currentPage}
          totalPage={this.state.totalPage}
          movePage={this.movePage}
        />

        <div className={styles.button_area}>
          <div className={styles.cancel_button} onClick={() => this.props.modalStore.modalClose()}>
            {MESSAGE.CANCEL}
          </div>
          <div
            className={styles.complete_button}
            onClick={() => this.props.selectCar(this.state.selectedItem)}
          >
            선택 완료
          </div>
        </div>
      </div>
    );
  }
}

export default CarModalContent;
