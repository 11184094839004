export default function checkBizID(bizID) {
  let checkID = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
  let tmpBizID;
  let i;
  let chkSum = 0;
  let c2;
  let remander;

  bizID = bizID.replace(/-/g, '');

  for (i = 0; i <= 7; i++) chkSum += checkID[i] * bizID.charAt(i);

  c2 = `0${checkID[8] * bizID.charAt(8)}`;
  c2 = c2.substring(c2.length - 2, c2.length);
  chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
  remander = (10 - (chkSum % 10)) % 10;

  if (Math.floor(bizID.charAt(9)) == remander) return true;

  return false;
}
