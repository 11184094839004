import Button from 'atoms/Button';
import { useRouter } from 'next/router';
import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  contractId: number;
  children?: ReactNode;
} & HTMLAttributes<HTMLButtonElement>;

const WriteExtensionContractButton = ({ contractId, children, ...rest }: Props) => {
  const router = useRouter();

  return (
    <StyledButton
      onClick={(event) => {
        event.stopPropagation();
        router
          .push(`/normalContract/detail?id=${contractId}`, null)
          .then(() => window.scrollTo(0, document.body.scrollHeight));
      }}
      {...rest}
    >
      {children ?? '연장 계약서 작성'}
    </StyledButton>
  );
};

export default WriteExtensionContractButton;

const StyledButton = styled(Button)`
  width: 100%;
  height: 50px;

  background-color: #282828;
  font-size: 15px;
`;
