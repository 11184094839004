import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { _strEnum } from 'helpers/zodHelpers';
import { z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const GetPartnerDetailParam = z.object({
  option: _strEnum(['partner_name', 'partner_contact', 'partner_manager']),
  value: z.string(),
  page: z.number(),
});
export type GetPartnerDetailParam = z.infer<typeof GetPartnerDetailParam>;

export interface SuccessResponse {
  list: List[];
  totalCount: number;
  totalPage: number;
  defaultInfo: callApi2.DefaultInfo;
}
export interface List {
  claim_count: number;
  id: number;
  partner_address: string;
  partner_contact: string;
  partner_manager: string;
  partner_name: string;
  using_car_count: number;
}
export type GetPartnerDetailResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getPartnerList(
  params: GetPartnerDetailParam,
  store: StoreType
): Promise<GetPartnerDetailResponse> {
  const response = await callApi2.get<GetPartnerDetailResponse>(`/v2/partners`, params);

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
