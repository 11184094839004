import { createStore } from 'stores';

export type BaseStore<State> = {
  // merge ssr prefetched data
  setObject?: (state: State) => void;
  prefetch?: () => Promise<State>;
  prefetchSuccess?: boolean;
  // provide ssr prefetched data
  // dehydra(): State | undefined;
};

export type ClassVariableType<T> = Partial<
  Pick<T, { [K in keyof T]: T[K] extends Function ? never : K }[keyof T]>
>;

export const _setObject = (_this, data) => {
  Object.keys(data).forEach((key) => {
    if (key.indexOf('Store') !== -1) {
      const storeName = key;

      // serverside에서 실행될 수도 있기때문에..
      if (typeof _this[storeName]?.setObject === 'function') {
        _this[storeName] = createStore()[storeName].setObject(data[key]);
      } else {
        _this[key] = data[key];
      }
    } else {
      _this[key] = data[key];
    }
  });

  return _this;
};
