import filter from 'lodash/filter';
import find from 'lodash/find';
import React, { Component, CSSProperties, Fragment } from 'react';

import styles from './Select.module.scss';
import { ListItemNode, ListItemsElement, SelectedItem } from './selectType';

const ARROW = '/images/searchAreaImages/select_arrow_icon_black2.png';
const NECESSARY = '/images/searchAreaImages/ic-input-necessary@3x.png';
const CLEAR = '/images/ic_close_30x30.png';

interface ComponentProps {
  selectKey: string;
  emptyText?: string;
  onClick?: Function;
  onChange?: Function; // check
  clear?: React.MouseEventHandler<HTMLDivElement>; // check
  day?: boolean;
  hour?: boolean;
  minute?: boolean;
  arrow?: boolean; // check
  common?: boolean;
  disabled?: boolean; // check
  isNecessary?: boolean; // check
  listView?: string;
  wrapStyle?: string; // check
  placeholder?: string; // check
  disabledPlaceholder?: string; // check
  value?: string | number; // check
  dataObj?: any;
  selectData?: Array<any> | ReadonlyArray<any>; // check
  listStyle?: React.CSSProperties;
  inlineStyle?: React.CSSProperties; // check
  dataset?: string;
  placeholderStyle?: React.CSSProperties;
  allowHours?: Array<number>;
  arrowImgSource?: string; // check
  arrowImgStyle?: React.CSSProperties; // check
  getSelectOpenStatus?: Function;
}

interface ComponentStates {
  hours: { label: string | number; value: string | number }[] | null;
  days: { label: string | number; value: string | number }[] | null;
  selectListView: boolean;
}

class Select extends Component<ComponentProps, ComponentStates> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      hours: null,
      days: null,
      selectListView: false,
    };
  }

  componentDidMount() {
    const { day, hour } = this.props;

    window.addEventListener('click', this.selectListViewControl);
    hour && this.setHours();
    day && this.setMonthDay();
  }

  componentDidUpdate(prevProps: ComponentProps, prevState: ComponentStates) {
    if (prevState.selectListView !== this.state.selectListView) {
      Boolean(document) && this.moveToSelectdScrollPosition(document);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.selectListViewControl);
  }

  moveToSelectdScrollPosition = (_document: Document) => {
    const selectedListDiv: ListItemsElement = document.querySelector(
      '#select_list_area_default_id'
    );
    let selectedElement: SelectedItem;

    if (selectedListDiv) {
      selectedListDiv?.firstChild?.childNodes.forEach((node: ListItemNode, index: number) => {
        if (node.className.includes('Select_selected_on')) {
          selectedElement = { node, index };
        }
      });

      if (selectedElement) {
        selectedListDiv.scrollTop = selectedElement?.node.scrollHeight * selectedElement.index;
      }
    }
  };

  setHours = () => {
    const { hour, allowHours } = this.props;
    let hours: { label: string; value: number }[] = [];

    if (hour) {
      if (Array.isArray(allowHours) && allowHours.length > 0) {
        hours = allowHours.map((i) => ({
          label: String(i).length === 1 ? `0${i}` : String(i),
          value: i,
        }));
      } else {
        for (let i = 0; i < 24; i++) {
          hours.push({ label: String(i).length === 1 ? `0${i}` : String(i), value: i });
        }
      }

      this.setState({ hours });
    }
  };

  setMonthDay = () => {
    const { day } = this.props;
    const days = [];

    if (day) {
      for (let i = 1; i < 32; i++) {
        days.push({ value: i, label: i });
      }

      this.setState({ days });
    }
  };

  selectListViewControl = (e: any) => {
    const { selectKey, getSelectOpenStatus } = this.props;
    const selectTitle = e.target.title;

    if (e.target.nodeName !== 'LI' && selectKey !== selectTitle) {
      if (getSelectOpenStatus) {
        getSelectOpenStatus(false);
      }

      this.setState({
        selectListView: false,
      });
    }
  };

  onSelectToggleList = (_e: React.MouseEvent<HTMLDivElement>) => {
    const { selectListView } = this.state;
    const { disabled, onClick, getSelectOpenStatus } = this.props;

    if (!disabled) {
      if (getSelectOpenStatus) {
        getSelectOpenStatus(!selectListView);
      }

      this.setState({
        selectListView: !selectListView,
      });
    }

    if (disabled) {
      onClick ? onClick() : null;
    }
  };

  onSelectItem = (e: any /*React.MouseEvent<HTMLLIElement>*/) => {
    const { onChange, selectKey } = this.props;
    let value: number | string | null = e.target.title;

    e.preventDefault();

    if (onChange) {
      if (selectKey === 'bank') {
        return onChange(value);
      }

      if (!isNaN(Number(e.target.title))) {
        value = Number(e.target.title);
      }

      if (value === '-') {
        value = null;
      }

      onChange(value);
    }
  };

  isEmptyCheck = (value: unknown) => {
    if (value === null || value === undefined || value === false) return true;

    if (typeof value === 'string' && value.trim() === '') return true;

    if (Array.isArray(value) && value.length === 0) return true;

    return false;
  };

  selectStyle = (list: Readonly<{ value: unknown }>) => {
    const { dataObj, selectKey, hour, day, common, value } = this.props;

    if (common) {
      if (String(list.value) === String(value)) return styles.selected_on;

      return '';
    }

    if (
      (hour || day || selectKey === 'prepayment_term') &&
      dataObj[selectKey] &&
      Number(dataObj[selectKey]) === list.value
    )
      return styles.selected_on;

    if (String(dataObj[selectKey]) === String(list.value)) return styles.selected_on;

    return '';
  };

  renderRoofLi = (data: Readonly<ComponentStates['hours']>) => {
    return data?.map((list, i) => {
      return (
        <li
          key={i}
          id={list?.value ? (list?.value as string) : undefined}
          title={list?.value ? (list?.value as string) : undefined}
          onClick={this.onSelectItem}
          className={this.selectStyle(list)}
        >
          {list.label}
        </li>
      );
    });
  };

  renderSelectList = () => {
    const { selectData, listView, listStyle, emptyText } = this.props;
    const { selectListView, hours, days } = this.state;

    if (selectListView) {
      if (hours) {
        return (
          <div
            id={'select_list_area_default_id'}
            className={`${styles.list_area} ${listView === 'up' ? styles.up : ''}`}
            style={listStyle ? listStyle : {}}
          >
            <ul>{this.renderRoofLi(hours)}</ul>
          </div>
        );
      }

      if (days) {
        return (
          <div
            id={'select_list_area_default_id'}
            className={`${styles.list_area} ${listView === 'up' ? styles.up : ''}`}
          >
            <ul>{this.renderRoofLi(days)}</ul>
          </div>
        );
      }

      if (this.isEmptyCheck(selectData)) {
        return (
          <div
            id={'select_list_area_default_id'}
            className={styles.list_area}
            style={listStyle ? listStyle : {}}
          >
            <ul>
              <li className={styles.empty_text}>{emptyText ?? '선택할 값이 없습니다.'}</li>
            </ul>
          </div>
        );
      }

      return (
        <div
          id={'select_list_area_default_id'}
          className={`${styles.list_area} ${listView === 'up' ? styles.up : ''}`}
          style={listStyle ? listStyle : {}}
        >
          <ul>{selectData && this.renderRoofLi(selectData)}</ul>
        </div>
      );
    }
  };

  renderArrowImg = () => {
    const { arrow, selectKey, disabled, arrowImgSource, arrowImgStyle } = this.props;

    if (arrow && !disabled) {
      return (
        <div className={styles.arrow_area}>
          <img
            src={arrowImgSource ?? ARROW}
            title={selectKey}
            style={arrowImgStyle ?? { width: 10 }}
          />
        </div>
      );
    }
  };

  renderClearImg = () => {
    const { clear, disabled, value } = this.props;

    if (clear && !disabled && value) {
      return (
        <div className={styles.clear_area} onClick={clear}>
          <img src={CLEAR} title="clear" />
        </div>
      );
    }
  };

  renderSelectedValue = () => {
    const {
      dataObj,
      selectKey,
      selectData,
      hour,
      day,
      disabled,
      common,
      value,
      placeholder,
      disabledPlaceholder,
      minute,
    } = this.props;
    const defaultData = filter(selectData, { default: true });
    const selectValue = !common && selectKey && dataObj[selectKey];

    if (selectKey === 'insuranceTable') {
      const data = find(selectData, { value });

      return data ? data.label : '선택';
    }

    if (hour || day || minute) {
      if (selectValue) return selectValue;
    }

    if (common && value && Array.isArray(selectData) && selectData.length > 0) {
      const parseValue = isNaN(Number(value)) ? value : Number(value);
      let data = find(selectData, {
        value: Number(parseValue) < 10 ? `0${parseValue}` : parseValue,
      });

      // if (selectKey === 'paymentMethod') {
      //   data = find(selectData, { value: parseValue });
      // }
      if (['paymentMethod', 'basicSelect'].includes(selectKey)) {
        data = find(selectData, { value: parseValue });
      }

      if (selectKey === 'prepayment_term') {
        // NOTE: 선수금 매출처리 예외처리
        const data = find(selectData, { value: Number(parseValue) });

        return data ? data.label : '선택';
      }

      if (!data) return null;

      return data.label;
    }

    if (selectKey === 'paymentCostType') {
      // NOTE: 입금액 수정 입금
      const data = find(selectData, { value: selectValue });

      return data ? data.label : '선택';
    }

    if (!this.isEmptyCheck(defaultData) && !selectValue) return defaultData[0].label;

    if (placeholder && this.isEmptyCheck(selectValue) && !disabled) return placeholder;

    if (Boolean(disabledPlaceholder) && this.isEmptyCheck(selectValue)) return disabledPlaceholder;

    if (this.isEmptyCheck(selectValue) && !disabled) return '선택';

    if (!this.isEmptyCheck(selectValue)) {
      if (selectKey === 'bank') {
        // 은행 코드가 02 처럼 시작하는게 있어서 예외처리
        const value =
          String(selectValue).length === 1 ? `0${String(selectValue)}` : String(selectValue);

        return find(selectData, { value })?.label;
      }

      const selectedData = find(selectData, {
        value: isNaN(Number(selectValue))
          ? selectValue === '더케이'
            ? '하나'
            : selectValue
          : Number(selectValue),
      });

      if (selectedData) {
        return selectedData.label;
      }
    }

    return '';
  };

  inlineStyle = () => {
    const { disabled, inlineStyle } = this.props;

    if (disabled && inlineStyle) {
      return { background: '#eeeeee', textAlign: 'center', cursor: 'initial', ...inlineStyle };
    }

    if (disabled) {
      return { background: '#eeeeee', textAlign: 'center', cursor: 'initial' };
    }

    if (inlineStyle) {
      return inlineStyle;
    }

    return {};
  };

  render() {
    const { wrapStyle, selectKey, placeholder, value, isNecessary, dataset, placeholderStyle } =
      this.props;
    const isEmptyValue = this.isEmptyCheck(value);

    return (
      <Fragment>
        {isNecessary && (
          <div className={styles.necessary_icon}>
            <img src={NECESSARY} />
          </div>
        )}
        <div
          className={`${styles.select_wrap} ${wrapStyle || ''}`}
          onClick={this.onSelectToggleList}
          title={selectKey}
          style={this.inlineStyle() as CSSProperties}
        >
          <p
            className={`${styles.view_selected} ${
              placeholder && isEmptyValue ? styles.placeholder : ''
            }`}
            title={selectKey}
            style={placeholderStyle}
            data-input={dataset}
          >
            {this.renderSelectedValue()}
          </p>
          {this.renderClearImg()}
          {this.renderArrowImg()}
          {this.renderSelectList()}
        </div>
      </Fragment>
    );
  }
}

export default Select;
