import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';

import costComma from '../../../helpers/costComma';
import Table from '../../Table';
import styles from './SelectCarModal.module.scss';

const CONNECTED_CAR_IMAGE = '/images/carControlImages/ic-carcontrol-twotone.png';
// inventory완료
const selectCarTableData = {
  thead: [
    { type: 'text', label: '선택' },
    { type: 'text', label: '' },
    { type: 'text', label: '차량번호' },
    { type: 'text', label: '그룹명' },
    { type: 'text', label: '차량명' },
    {
      type: 'text',
      label: '연식',
      showTooltip: true,
      tooltipMessage: '최초 등록일',
      theadPartStyle: {
        textAlign: 'right',
        paddingRight: 12,
      },
    },
    { type: 'text', label: '유종' },
    { type: 'text', label: '차량상태' },
    { type: 'text', label: '대여금액' },
  ],
  tbody: [
    { key: 'id', type: 'checkbox' },
    { key: 'use_connect', type: 'img' },
    { key: 'car_identity', type: 'text' },
    { key: 'car_group_name', type: 'text' },
    { key: 'car_name', type: 'text' },
    { key: 'car_age', type: 'text' }, // { key: 'model_year', type: 'text' },
    { key: 'oil_type', type: 'text' },
    { key: 'using_state', type: 'text' },
    { key: 'rental_cost', type: 'text' },
  ],
};
// inventory완료
const selectCarTableDataInventory = {
  thead: [
    { type: 'text', label: '선택' },
    { type: 'text', label: '' },
    { type: 'text', label: '차량번호' },
    // inventory완료
    { type: 'text', label: '그룹명' },
    { type: 'text', label: '차량명' },
    {
      type: 'text',
      label: '연식',
      showTooltip: true,
      tooltipMessage: '최초 등록일',
      theadPartStyle: {
        textAlign: 'right',
        paddingRight: 12,
      },
    },
    { type: 'text', label: '유종' },
    { type: 'text', label: '차량상태' },
  ],
  tbody: [
    { key: 'id', type: 'checkbox' },
    { key: 'use_connect', type: 'img' },
    { key: 'car_identity', type: 'text' },
    // inventory완료
    { key: 'car_group_name', type: 'text' },
    { key: 'car_name', type: 'text' },
    { key: 'car_age', type: 'text' }, // { key: 'model_year', type: 'text' },
    { key: 'oil_type', type: 'text' },
    { key: 'using_state', type: 'text' },
  ],
};

class SelectCarTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isChange: props.isChange,
      selectedItem: null,
      selectCarTableData:
        props.version > 1 ? cloneDeep(selectCarTableDataInventory) : cloneDeep(selectCarTableData),
    };

    if (props.isChange || props.version > 1) {
      this.state.selectCarTableData.tbody = this.state.selectCarTableData.tbody.concat([
        { key: 'recommend', type: 'recommend' },
      ]);
      this.state.selectCarTableData.thead = this.state.selectCarTableData.thead.concat([
        { type: 'text', label: '' },
      ]);
    }
  }

  selectItem = (item) => {
    this.props.onChange(item);
  };

  stateParser = (state) => {
    return state === 'waiting'
      ? '대기중'
      : state === 'using'
      ? '배차중'
      : state === 'repair'
      ? '수리'
      : state === 'employee_use'
      ? '직원사용'
      : state === 'holiday'
      ? '휴무'
      : state;
  };

  tbodyData = () => {
    const { selectCarTableData } = this.state;
    const { list, selectedItem } = this.props;

    if (list.length > 0) {
      return list.map((item, i) => {
        return (
          <tr key={i} style={{ cursor: 'pointer' }} onClick={() => this.selectItem(item)}>
            {selectCarTableData.tbody.map((list, ii) => {
              const carAge = dayjs(item[list.key]);
              const carAgeText = carAge.isValid() ? carAge.format('YY.MM') : '확인 필요';

              let checkboxStyle;

              switch (list.type) {
                case 'text':
                  return (
                    <td key={`${ii}_text`} className={styles._td}>
                      {list.key === 'using_state'
                        ? this.stateParser(item[list.key])
                        : list.key === 'rental_cost'
                        ? costComma(item[list.key])
                        : list.key === 'car_age'
                        ? carAgeText
                        : list.key === 'car_group_name' && item[list.key]?.length > 15
                        ? `${item[list.key].substring(0, 15)}...`
                        : item[list.key]
                        ? item[list.key]
                        : '-'}
                    </td>
                  );
                case 'checkbox':
                  if (selectedItem?.id === item.id) {
                    checkboxStyle = styles.active_checkbox;
                  } else {
                    checkboxStyle = styles.inactive_checkbox;
                  }

                  return (
                    <td key={`${ii}_check`} className={styles._td}>
                      <div className={styles.checkBox_wrap}>
                        <div className={`${checkboxStyle}`}>
                          <img src={'/images/checkboxImage/check_icon_white_thin.png'} />
                        </div>
                      </div>
                    </td>
                  );
                case 'img':
                  let imagePath = null;

                  if (list.key === 'use_connect') imagePath = CONNECTED_CAR_IMAGE;

                  if (item[list.key]) {
                    return (
                      <td key={`${ii}_img`} className={styles._td}>
                        <img style={{ width: 16, height: 16 }} src={imagePath} />
                      </td>
                    );
                  }

                  return <td key="none" />;

                case 'recommend':
                  if (item.recommend) {
                    return (
                      <td key={`${ii}_recomm`} className={styles._td}>
                        <div className={`${styles.recommend}`}>추천</div>
                      </td>
                    );
                  }

                  return <td key={`${ii}_unknown`} />;
                default:
              }
            })}
          </tr>
        );
      });
    }
  };

  theadWidth = () => {
    // inventory완료
    if (this.props.version > 1) {
      return [6, 5.5, 12.5, 25, 16, 8, 9.6, 9.1, 7];
    }

    if (this.props.isChange) {
      return [6, 4, 10, 20, 12, 6, 7, 10, 10, 8];
    }

    return [7, 5, 10, 20, 12, 10, 10, 10, 10];
  };

  theadData = () => {
    const { selectCarTableData } = this.state;
    let data = selectCarTableData.thead;

    if (this.props.change) {
      data = data.concat([{ type: 'text', label: '' }]);
    }

    return data;
  };

  emptyTableInfo = () => {
    const { isChange } = this.props;

    return {
      text: isChange ? '변경 가능한 차량이 없습니다.' : '제안 가능한 차량이 없습니다.',
      style: { borderBottom: 'none', paddingTop: '53px' },
    };
  };

  render() {
    return (
      <Table
        theadWidth={this.theadWidth()}
        theadData={this.theadData()}
        tbodyData={this.tbodyData()}
        emptyTable={this.emptyTableInfo()}
      />
    );
  }
}

export default SelectCarTable;
