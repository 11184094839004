import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

// import callApi from '../../../helpers/callApi';
import apiRentRequest from '../../../api/rentRequest';
import * as parser from '../../../utils/DataParser';
import * as utils from '../../../utils/PartnerUtils';
import Paging from '../../Paging';
import Table from '../../Table';
import styles from './ConnectRencarRequestStyles.module.scss';

@inject('loadingStore')
@inject('authStore')
@inject('menuStore')
@inject('modalStore')
@observer
class ConnectRencarRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      selectedItem: null,
      selectedId: null,
      currentPage: 1,
      totlaPage: 1,
      loding: false,
    };
  }

  componentDidMount() {
    this._getList();
  }

  connectContract = () => {
    const selectedCall = this.state.selectedItem;

    if (!selectedCall) return alert('렌카콜을 선택해주세요.');

    if (confirm(`${selectedCall.id}번 요청과 연결하시겠습니까?`)) {
      this.props.connectRencarRequest(selectedCall);
      this.props.callback && this.props.callback();
    }
  };

  _getList = (page = 1) => {
    catchAsync(async () => {
      const type = 'selected';
      // let endPoint = `/partner/${type}/${page}`;
      // const request = {
      //   method: 'GET',
      //   path: endPoint,
      //   menuStore: this.props.menuStore,
      //   authStore: this.props.authStore
      // }
      const params = {
        menuStore: this.props.menuStore,
        authStore: this.props.authStore,
        type,
        page,
      };

      this.props.loadingStore.on();
      const response = await apiRentRequest.getRencarRequestList(params);
      const responseData = response.data;

      if (response.status === 200) {
        this.setState({
          list: responseData.rentRequestList,
          currentPage: page,
          totalPage: responseData.total_page,
        });
      }

      this.props.loadingStore.off();
    });
  };

  movePage = (page) => {
    if (this.state.currentPage != page) {
      this._getList(page);
    }
  };

  getShowString = (item, type) => {
    switch (type) {
      case 'request_type':
        return (
          <div className={styles.requestTypeWrap}>
            <div
              className={styles.requestTypeColor}
              data-request-type={utils.getRequestType(item)}
            />
            {utils.getRequestTypeText(item)}
          </div>
        );
      case 'request_date':
        return parser.dateParser(item.createdAt);
      case 'return_date':
        return parser.dateParser(item.doneAt);
      case 'customer_car':
        return parser.defaultParser(item.customerCar);
      case 'rent_car':
        return parser.defaultParser(item.selected_car);
      case 'location':
        return parser.locationParser(item.location);
      case 'extra':
        if (item.requestType == 'auto_mobile_request') {
          return item.total_fee === 0 ? '무료' : '-';
        }

        return item.free_request ? '무료' : '-';

      default:
        return '-';
    }
  };

  renderHaveNoList = () => {
    return (
      <tr>
        <td colSpan="14">연결할 렌카콜이 존재하지 않습니다.</td>
      </tr>
    );
  };

  hadleSelectClick = (item) => {
    const { guaranteed } = this.props;

    const isComputerConnectionCase = (requestType) => {
      return requestType === 'insurance_rent_request' || requestType === 'meritz_rent_request';
    };

    if (guaranteed) {
      if (isComputerConnectionCase(item.requestType)) {
        alert('보증대차 계약서는 보험사 콜과 연결할 수 없습니다.');

        return this.props.modalStore.modalClose();
      }
    }

    return this.setState({ selectedItem: item, selectedId: item.id });
  };

  tableRow = () => {
    return this.state.list.length > 0
      ? this.state.list.map((item, i) => (
          <tr key={i} style={{ cursor: 'pointer' }} onClick={() => this.connectContract(item)}>
            <td>{item.id}</td>
            <td>{this.getShowString(item, 'request_type')}</td>
            <td>{this.getShowString(item, 'request_date')}</td>
            <td>{this.getShowString(item, 'delivered_date')}</td>
            <td>{this.getShowString(item, 'return_date')}</td>
            <td>{this.getShowString(item, 'customer_car')}</td>
            <td>{this.getShowString(item, 'rent_car')}</td>
            <td>{this.getShowString(item, 'location')}</td>
            <td>{this.getShowString(item, 'extra')}</td>
          </tr>
        ))
      : this.renderHaveNoList();
  };

  tbodyData = () => {
    if (this.state.list.length > 0) {
      return this.state.list.map((item, i) => {
        let checkboxStyle;

        if (item.id === this.state.selectedId) {
          checkboxStyle = styles.active_checkbox;
        } else {
          checkboxStyle = styles.inactive_checkbox;
        }

        return (
          <tr key={i} style={{ cursor: 'pointer' }} onClick={() => this.hadleSelectClick(item)}>
            <td key={i}>
              <div className={`${styles.checkbox_div} ${checkboxStyle}`}>
                <img src={'/images/checkboxImage/check_icon_white_thin.png'} />
              </div>
            </td>
            <td>{item.id}</td>
            <td>{this.getShowString(item, 'request_type')}</td>
            <td>{this.getShowString(item, 'request_date')}</td>
            <td>{this.getShowString(item, 'delivered_date')}</td>
            <td>{this.getShowString(item, 'return_date')}</td>
            <td>{this.getShowString(item, 'customer_car')}</td>
            <td>{this.getShowString(item, 'rent_car')}</td>
            <td>{this.getShowString(item, 'location')}</td>
            <td>{this.getShowString(item, 'extra')}</td>
          </tr>
        );
      });
    }
  };

  render() {
    return (
      <div className={styles.connect_rencar_call_modal_wrap}>
        <div className={styles.table_area}>
          <Table
            headFix
            theadWidth={[5, 5, 10, 13, 13, 13, 10, 10, 10, 10]}
            theadData={[
              '선택',
              '요청번호',
              '요청타입',
              MESSAGE.REQUEST_DATE,
              MESSAGE.ALLOCATE_CAR_DATE,
              '반납일',
              MESSAGE.CUSTOMER_CAR,
              MESSAGE.BORROW_CAR,
              '지역',
              '특이사항',
            ]}
            tbodyData={this.tbodyData()}
            emptyTable={{ text: '연결할 렌카콜이 존재하지 않습니다.', style: { fontSize: '13px' } }}
          />
        </div>
        <Paging
          currentPage={this.state.currentPage}
          totalPage={this.state.totalPage}
          movePage={this.movePage}
        />
        <div className={styles.button_area}>
          <div className={styles.cancel_button} onClick={() => this.props.modalStore.modalClose()}>
            {MESSAGE.CANCEL}
          </div>
          <div className={styles.complete_button} onClick={() => this.connectContract()}>
            선택 완료
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectRencarRequestModal;
