import * as alertMessage from './alertMessage';
import * as button from './button';
import * as description from './description';
import * as format from './format';
import * as label from './label';
import * as placeHolder from './placeHolder';
import * as title from './title';
import * as validation from './validation';

export * from './alertMessage';
export * from './button';
export * from './description';
export * from './format';
export * from './label';
export * from './placeHolder';
export * from './title';
export * from './validation';

global.MESSAGE = {
  ...format,
  ...title,
  ...button,
  ...description,
  ...validation,
  ...label,
  ...placeHolder,
};

global.ALERT_MESSAGE = alertMessage;
global.KAKAO_CHANGE_INSURANCE_OPEN_DATE = '2022-02-21 11:00:00';
