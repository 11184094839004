import '../styles/globals.scss';
import '../constants/stringTable';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { BottomSheetList } from 'components/BottomSheet';
import SkeletonForSSR from 'components/SkeletonForSSR/SkeletonForSSR';
import { DESKTOP_LAYOUT_MOBILE_PAGES } from 'constants/desktopLayoutMobilePages';
import { isMobile } from 'helpers/userAgent';
import { Provider } from 'mobx-react';
import { initializeMSW } from 'mocks/initializeMSW';
import App from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Router from 'next/router';
import React, { Fragment } from 'react';
import { render } from 'react-dom';
import { UAParser } from 'ua-parser-js';
import Cookies from 'universal-cookie';

import ErrorBoundary from '../components/ErrorBoundary';
import ErrorPageComponent from '../components/ErrorPageComponent';
import Loading from '../components/Loading';
import Modal from '../components/Modal';
import ModalErrorBoundary from '../components/ModalErrorBoundary';
import ScrollToTop from '../components/ScrollToTop';
import Toast from '../components/Toast';
import { setLoadingStore } from '../helpers/extendType';
import * as Sentry from '../helpers/sentry';
import { withProfiler } from '../helpers/sentry';
import type { AppStore } from '../stores';
import { createStore } from '../stores';
const Header = dynamic(import('../components/Header'), {
  ssr: false,
});
const CSRInitial = dynamic(import('../components/CSRInitial'), {
  ssr: false,
});
const Footer = dynamic(import('../components/Footer'), {
  ssr: false,
});

if (process.env.API_ENV === 'development' && process.env.MSW_ENV) {
  initializeMSW();
}

const parser = new UAParser();

Sentry.initialize();

interface Context {
  pageProps: {
    errorName?: string;
    redirectErrorName?: string;
  };
  ctx: any;
  Component: () => JSX.Element;
}
interface AppPageState {
  loading: boolean;
}
const findJwt = (context?: Context) => {
  const isServer = Boolean(context?.ctx?.req);
  const cookies = isServer ? new Cookies(context?.ctx?.req.headers.cookie) : new Cookies();
  const jwt = cookies.get(`${process.env.API_ENV}-imsform-jwt`);

  return jwt;
};

const getRedirectPath = (appContext, stores) => {
  const currentPath = appContext?.pathname || appContext?.ctx?.req?.url;
  const uaParser = new UAParser(appContext?.ctx?.req?.headers['user-agent']);

  //  1순위 # IE인지 확인한다.
  const notAllowedIE =
    uaParser.getBrowser().name === 'IE' && currentPath !== '/notSupportedExploler';

  if (notAllowedIE) {
    return '/notSupportedExploler';
  }

  //  2순위 # 모바일 기기 일경우 모바일 인트로로 보낸다! (다만 /와 intro 일경우에만)
  const notAllowedMobile = (() => {
    return (
      ['/', '/intro', '/home'].includes(currentPath) &&
      ['mobile', 'tablet'].includes(uaParser.getResult().device.type) &&
      currentPath !== '/mobile/intro'
    );
  })();

  if (notAllowedMobile) {
    return '/mobile/intro';
  }

  // 3순위 # 로그인 되어있는상태에서 인트로 화면이나, 루트일경우 홈화면으로 보낸다!
  const allowLogin = (() => {
    return (
      ['/', '/intro'].includes(currentPath) &&
      Boolean(stores.authStore.logged) &&
      currentPath !== '/home'
    );
  })();

  if (allowLogin) {
    return '/home';
  }

  // 4순위 # 모바일 디바이스가 아닌데 모바일 인트로페이지일경우 루트로 보낸다!
  const isWebIntro = (() => {
    return (
      ['/mobile/intro'].includes(currentPath) &&
      uaParser.getResult().device.type === undefined &&
      currentPath !== '/'
    );
  })();

  if (isWebIntro) {
    return '/';
  }

  return null;
};

const title = (title) => {
  switch (process.env.API_ENV) {
    case 'development':
      return `로컬환경 ${title}`;
    case 'staging':
      return `테스트환경 ${title}`;
    default:
      return title;
  }
};

class CustomApp extends App<Context, AppPageState> {
  stores: AppStore;

  static getInitialProps = async (context) => {
    // 서버side or client
    // store dataset을 만드는것이 목적이고 여기서 생성한 스토어를 사용하지는 않는다.
    const stores = createStore(typeof window === 'undefined');
    const jwt = findJwt(context);

    if (jwt) {
      if (!stores.authStore.logged) {
        stores.authStore.login(jwt);
      }
    }

    // TODO: 페이지의 getInitialProps 함수에서 prefetch 실행시 mobxStore가 필요
    context.ctx.mobxStore = stores;
    context.ctx.stores = stores;
    context.ctx.isServer = typeof window === 'undefined';

    // 리다이렉트 로그인이 되어있는 상태이거나 , IE일경우 , 모바일화면일경우 리다이렉트하는 로직이 필요한경우에
    // 아래에 코드를 작성한다.
    const redirectPath = getRedirectPath(context, stores);

    if (redirectPath) {
      if (context.ctx.res) {
        context.ctx.res.writeHead(307, { Location: redirectPath });
        context.ctx.res.end();

        return;
      }

      Router.replace(redirectPath);

      return;
    }

    // 페이지의 getInitialProps 실행
    // 만약 페이지가 PrivateRoute로 감싸져 있는경우에는 withPrivateRoute.js가 먼저수행되고
    // 페이지의 InitialProps가 수행됨.
    const appProps = await App.getInitialProps(context);

    return { stores, ...appProps };
  };

  constructor(props) {
    super(props);
    this.stores = createStore(typeof window === 'undefined').hydrate(props.stores);
    setLoadingStore(this.stores.loadingStore);
    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    const jwt = findJwt();
    const isMobile = Router.pathname.split('/')[1] === 'mobile';
    const mobileRootStyle = document.getElementById('__next');
    const bodyStyle = document.body;

    // 각스토어에 initialize 함수가있다면 실행
    Promise.allSettled(
      Object.keys(this.stores).map((key) => {
        if (typeof this.stores[key]?.prefetch === 'function') {
          return this.stores[key]?.prefetch(jwt);
        }

        return null;
      })
    )
      .then(() => {
        this.stores.authStore.setValue({
          key: 'company_id',
          value: this.stores.commonStore.company_id,
        }); // 처음부터 사이드 메뉴에 업체번호가 노출해야 하기 때문에 orderer 저장할 때 같이 저장해서 사용.
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    const currentPath = window.location.pathname;

    // 모바일과 웹 동일한 화면이 보이는 경우 특정 스타일 지정
    if (isMobile && DESKTOP_LAYOUT_MOBILE_PAGES.includes(currentPath)) {
      mobileRootStyle.setAttribute(
        'style',
        'width: 100%; height: 100%; max-width: 430px; display: flex; justify-content: center; background-color: #f6f6f6;'
      );
      bodyStyle.setAttribute(
        'style',
        'display: flex; justify-content: center; background-color: #f6f6f6;'
      );
    }
  }

  renderPageComponent = () => {
    const { Component, pageProps } = this.props;
    const { errorName } = pageProps;

    if (errorName) {
      return <ErrorPageComponent errorName={errorName} />;
    }

    return (
      <ScrollToTop disabledScrollList={[]}>
        <Component {...pageProps} ua={parser.getResult()} />
      </ScrollToTop>
    );
  };

  render() {
    const { pageProps } = this.props;
    const { errorName } = pageProps;

    // @ts-ignore
    if (this.state.loading === true) {
      return <SkeletonForSSR />;
    }

    return (
      <Provider {...this.stores}>
        <Fragment>
          {/* google GA , kakaomap script 등이 아래 컴포넌트에서 실행됨 */}
          <CSRInitial />
          <Head>
            <meta
              name="viewport"
              id="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <meta name="google" content="notranslate" />
            <title>{title('IMS form-모바일 사고대차,보험대차,일반렌트 전산시스템')}</title>
          </Head>
          <Toast />
          <ModalErrorBoundary>
            <Modal />
          </ModalErrorBoundary>
          <ErrorBoundary>
            <Loading />
            <BottomSheetList />
            <Header ua={parser.getResult()} errorName={errorName} {...pageProps} />
            {this.renderPageComponent()}
            <Footer errorName={errorName} />
          </ErrorBoundary>
        </Fragment>
      </Provider>
    );
  }
}

export default withProfiler(CustomApp);
