import * as callApi2 from '../../helpers/callApi2';

export interface IDeleteDailyCostTableParams {
  cost_table_id: number;
}

type SuccessResponse = {
  success: true;
};

export type IDeleteDailyCostTableResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function deleteDailyCostTable(params: IDeleteDailyCostTableParams) {
  const response = await callApi2._delete<IDeleteDailyCostTableResponse>(
    `/v2/group-cost-tables/daily/${params.cost_table_id}`,
    params
  );

  return response;
}
