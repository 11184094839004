import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker';

import Select from '../Select';
import styles from './CustomDatePicker.module.scss';

registerLocale('ko', ko);
setDefaultLocale('ko');

interface ComponentProps {
  placeholder?: ReactDatePickerProps['placeholderText'];
  selected?: ReactDatePickerProps['selected'];
  maxDate?: ReactDatePickerProps['maxDate'];
  minDate?: ReactDatePickerProps['minDate'];
  disabled?: ReactDatePickerProps['disabled'];
  readOnly?: ReactDatePickerProps['readOnly'];
  icon?: boolean;
  clear?: boolean;
  inlineStyle?: React.CSSProperties;
  iconStyle?: React.CSSProperties;
  dateFormat?: string;
  btnAreaClass?: string;
  className?: string;
  dataset?: string;
  onChange: Function;
  minYear?: number;
}

interface ComponentState {
  date: Date | [Date | null, Date | null] | /* for selectsRange */ null;
}

const CALENDAR_ICON = '/images/ic-calendar.png';
const ARROW = '/images/ic_arrow_right_terms_6x10.png';

class CustomDatePicker extends Component<ComponentProps, ComponentState> {
  constructor(props: ComponentProps) {
    super(props);
    this.state = {
      date: null,
    };
  }

  shouldComponentUpdate(nextProps: unknown) {
    if (JSON.stringify(nextProps) !== JSON.stringify(this.props)) return true;

    return false;
  }

  render() {
    const {
      dateFormat,
      className,
      placeholder,
      selected,
      maxDate,
      minDate,
      disabled,
      onChange,
      readOnly,
      icon,
      clear,
      btnAreaClass,
      inlineStyle,
      iconStyle,
      dataset,
    } = this.props;
    const CustomDate = React.forwardRef(
      ({ value, onClick }: { value?: string | number; onClick?: Function }, ref) => (
        <div ref={ref as any} className={`${styles.calendar_btn_area} ${btnAreaClass}`}>
          <button
            ref={ref as any}
            style={!value ? { color: '#999999', ...inlineStyle } : { ...inlineStyle }}
            className={`${styles.custom_input} ${className}`}
            onClick={onClick as any}
            disabled={disabled || false}
            data-input={dataset}
          >
            {value}
          </button>
          {icon && (
            <img
              onClick={onClick as any}
              className={`${styles.calendar_icon} ${disabled ? styles.disabled : ''}`}
              src={CALENDAR_ICON}
              style={iconStyle}
            />
          )}
        </div>
      )
    );

    CustomDate.displayName = 'CustomDate';

    const currentYear = dayjs().format('YYYY');
    const years: { label: string; value: number }[] = [];
    const months = Array.from({ length: 12 }, (v, i) => {
      return {
        label: `${i < 9 ? `0${i + 1}` : i + 1}월`,
        value: i + 1 < 10 ? `0${i + 1}` : i + 1,
      };
    });

    for (
      let i = this.props.minYear ?? Number(currentYear) - 10;
      i <= Number(currentYear) + 100;
      i++
    ) {
      years.push({ label: `${i}년`, value: i });
    }

    return (
      <div className={styles.date_picker_wrap}>
        {clear ? (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={styles.calendar_header_wrap}>
                <button
                  className={styles.prev_month}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img src={ARROW} />
                </button>
                <Select
                  arrow
                  common
                  selectData={years}
                  value={Number(dayjs(date).format('YYYY'))}
                  onChange={(value: number) => changeYear(value)}
                  inlineStyle={{ width: '34%' }}
                  selectKey="year"
                />
                <Select
                  arrow
                  common
                  selectData={months}
                  value={dayjs(date).format('MM')}
                  onChange={(value: number) => changeMonth(value - 1)}
                  inlineStyle={{ width: '30%' }}
                  selectKey="month"
                />
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <img src={ARROW} />
                </button>
              </div>
            )}
            tabIndex={1}
            isClearable
            showYearDropdown
            showDisabledMonthNavigation
            closeOnScroll={true}
            className={`${styles.common_date_input} ${className}`}
            placeholderText={placeholder || ''}
            selected={(this.state.date as Date | null) || selected || null}
            onChange={(date) => {
              this.setState({ date });
              onChange(date);
            }}
            dateFormat={dateFormat || 'yyyy-MM-dd'}
            customInput={<CustomDate />}
            maxDate={maxDate || null}
            minDate={minDate || null}
          />
        ) : (
          <DatePicker
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div className={styles.calendar_header_wrap}>
                <button
                  className={styles.prev_month}
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  <img src={ARROW} />
                </button>
                <Select
                  arrow
                  common
                  selectData={years}
                  value={Number(dayjs(date).format('YYYY'))}
                  onChange={(value: number) => changeYear(value)}
                  inlineStyle={{ width: '34%' }}
                  selectKey="year"
                />
                <Select
                  arrow
                  common
                  selectData={months}
                  value={dayjs(date).format('MM')}
                  onChange={(value: number) => changeMonth(value - 1)}
                  inlineStyle={{ width: '30%' }}
                  selectKey="month"
                />
                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                  <img src={ARROW} />
                </button>
              </div>
            )}
            tabIndex={1}
            closeOnScroll={true}
            className={`${styles.common_date_input} ${className}`}
            popperClassName={styles.calendar_style}
            dateFormat={dateFormat || 'yyyy-MM-dd'}
            selected={selected || null}
            maxDate={maxDate || null}
            minDate={minDate || null}
            placeholderText={placeholder || ''}
            disabled={disabled || false}
            readOnly={readOnly || false}
            onChange={(date) => onChange(date)}
            customInput={<CustomDate />}
            showDisabledMonthNavigation
            showYearDropdown
          />
        )}
      </div>
    );
  }
}

export default CustomDatePicker;
