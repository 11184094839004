import { AxiosPromise } from 'axios';

import callApi from '../../helpers/callApi';
import { GetMonthlyCostTableInfoParams, GetMonthlyCostTableInfoResponse } from './types';

export function getMonthlyCostTableInfo(
  params: GetMonthlyCostTableInfoParams
): AxiosPromise<GetMonthlyCostTableInfoResponse> {
  return callApi({
    method: 'GET',
    url: `/v2/group-cost-tables/monthly/${params.id}`,
    stores: {
      menuStore: params.menuStore,
      authStore: params.authStore,
    },
  });
}
