import * as callApi2 from '../../helpers/callApi2';

export interface IDeletePeriodFromCostTableParams {
  cost_table_id: number;
  period_id: number;
}

type SuccessResponse = {
  success: true;
};

export type IDeletePeriodFromCostTableResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function deletePeriodFromCostTable(params: IDeletePeriodFromCostTableParams) {
  const { cost_table_id, period_id } = params;
  const response = await callApi2._delete<IDeletePeriodFromCostTableResponse>(
    `/v2/group-cost-tables/daily/${cost_table_id}/periods/${period_id}`,
    {}
  );

  return response;
}
