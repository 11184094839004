import callApi from '../../../helpers/callApi';

export default {
  getPreview(params) {
    const serializeForm = {
      start_datetime: params.start_datetime,
      end_datetime: params.end_datetime,
      payment_day: params.payment_day,
      prepaid_amount: params.prepaid_amount,
      emi: params.emi,
      prepayment_term: params.prepayment_term,
      initial_payment_date: params.initial_payment_date,
    };

    return callApi({
      method: 'GET',
      url: '/v2/installment-schedule/preview',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  getScheduleList(params) {
    return callApi({
      method: 'GET',
      url: `/v2/normal-contracts/${params.id}/installment-schedule`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  addDeposit(params) {
    return callApi({
      method: 'POST',
      url: `/v2/normal-contracts/${params.id}/installment-schedule`,
      data: {
        deduct_deposit: params.deduct_deposit,
        payment_method_id: params.payment_method_id,
        payment_date: params.payment_date,
        payment_amount: params.payment_amount,
        installment_no: params.installment_no,
        remark: params.remark,
      },
    });
  },
  updateSchedule(params) {
    return callApi({
      method: 'POST',
      url: `/v2/normal-contracts/${params.id}/installment-schedule/update`,
      data: { schedule: params.schedule },
      /* 
      schedule: [
        { due_date, prepaid_amount_due, amount_due, installment_no }, # row form
        ...other row forms of same structure
      ]
      */
    });
  },
  terminateSchedule(params) {
    return callApi({
      method: 'POST',
      url: `/v2/normal-contracts/${params.id}/installment-schedule/cancel`,
      data: {
        start_installment_no: params.start_installment_no,
        memo: params.memo,
      },
    });
  },
  undoTerminateSchedule(params) {
    return callApi({
      method: 'POST',
      url: `/v2/normal-contracts/${params.id}/installment-schedule/undo-cancel`,
      data: { memo: params.memo },
    });
  },
};
