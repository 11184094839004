class AuthUtils {
  constructor(stores) {
    this.authStore = stores?.authStore;
  }

  getUserType = (authStore) => {
    const store = authStore ? authStore : this.authStore;

    if (store) {
      const userType = store.userType;
      const permission = store.permission;

      if (permission === null && userType === 'rent_company_user') {
        return 'boss';
      }

      if (userType === 'rent_company_for_contract') {
        return 'rent_company_for_contract';
      }

      if (permission === 0) {
        return 'normaler';
      }

      if (permission === 1) {
        return 'biller';
      }

      if (permission === 2) {
        return 'manager';
      }

      if (permission === 3) {
        return 'car_manager';
      }
    }

    return null;
  };

  getCompanyId = (authStore) => {
    const store = authStore ? authStore : this.authStore;

    if (store) {
      return store.company_id;
    }

    return null;
  };

  getUserTypeSimpleKorean = (permission) => {
    switch (permission) {
      case null:
        return '대표';
      case 0:
        return '일반직원';
      case 1:
        return '청구직원';
      case 2:
        return '매니저';
      case 3:
        return '카매니저';
      default:
        return '알수없음';
    }
  };

  getUserTypeKorean = (type) => {
    switch (type) {
      case 'rent_employee_user':
        return '렌터카업체 직원';
      case 'rent_company_user':
        return '렌터카업체 대표';
      case 'insurance_company_user':
        return '보험사';
      case 'init_insurance_user':
        return '보험상담사';
      case 'auto_mobile_workers':
        return '자동차 관련 종사자';
      case 'normal_user':
        return '일반고객';
      default:
        return '일치하는 타입이 없습니다.';
    }
  };

  getBelongToKorean = (type) => {
    switch (type) {
      case 'rencar':
        return '렌카';
      case 'plus':
        return '공차';
      default:
        return '알 수 없음';
    }
  };
}

export default AuthUtils;
