import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';

export interface Root {
  privacy_policy_history: PrivacyPolicyHistory[];
  defaultInfo: callApi2.DefaultInfo;
}

export interface PrivacyPolicyHistory {
  applied_at: string;
  content: string;
  created_at: string;
  id: number;
  policy_type: PolicyType;
  title: string;
}

export interface PolicyType {
  id: number;
  type: string;
}

type SuccessResponse = {
  privacy_policy_history: PrivacyPolicyHistory[];
  defaultInfo: callApi2.DefaultInfo;
};
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type GetPrivacyPolicySuccess =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getPrivacyPolicy(store: StoreType) {
  const response = await callApi2.get<GetPrivacyPolicySuccess>(`/v2/privacy-policy`, {});

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
