export default function useTypeSelector(auth) {
  const { use_form, use_partner, use_external } = auth;
  let result = null;
  // const use_form = auth.use_form;
  // const use_partner = auth.use_partner
  // const use_external = auth.use_external

  // if ( use_form && (use_partner||use_external) ) {
  //   return { use_form: true, use_partner: true, result: 'both' }
  // }

  // if ( use_form && !(use_partner||use_external)) {
  //   return { use_form: true, use_partner: false, result: 'form' }
  // }

  // if ( !use_form && (use_partner||use_external)) {
  //   return { use_form: false, use_partner: true, result: 'partner' }
  // }

  // if (!use_form && !(use_partner||use_external)) {
  //   return { use_form: false, use_partner: false, result: 'denied' }
  // }

  if (use_form && use_partner && use_external) {
    result = 'all';
  } else if (use_form && !use_partner && !use_external) {
    result = 'form';
  } else if (use_form && use_partner && !use_external) {
    result = 'formPartner';
  } else if (use_form && !use_partner && use_external) {
    result = 'formExternal';
  } else if (!use_form) {
    result = 'partner';
  }

  return { use_form, use_partner, use_external, result };
}
