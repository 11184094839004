export const DATE_DASH = 'YYYY-MM-DD';
export const DATE_DOT = 'YYYY.MM.DD';
export const DATE_SLASH = 'YYYY/MM/DD';
export const DATE_KO = 'YYYY년 MM월 DD일';
export const DATE_DASH_SMALL = 'YY-MM-DD';
export const DATE_DOT_SMALL = 'YY.MM.DD';
export const DATE_SLASH_SMALL = 'YY/MM/DD';
export const DATE_KO_SMALL = 'YY년 MM월 DD일';
export const TIME_HOUR_24 = 'HH:mm';
export const TIME_HOUR_12 = 'hh:mm';
export const TIME_HOUR_24_KO = 'HH시 mm분';
export const TIME_HOUR_12_KO = 'hh시 mm분';
export const FULL_TIME_24 = 'HH:mm:ss';
export const FULL_TIME_12 = 'hh:mm:ss';
export const FULL_TIME_24_KO = 'HH시 mm분 ss초';
export const FULL_TIME_12_KO = 'hh시 mm분 ss초';
export const IMS_CASH =
  process.env.API_ENV !== 'production' ? `${process.env.API_ENV}_ims_cash` : 'ims_cash';
export const IMS_POINT =
  process.env.API_ENV !== 'production' ? `${process.env.API_ENV}_ims_point` : 'ims_point';
