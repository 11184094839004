import dayjs from 'dayjs';
import React from 'react';

import Button from '../../../atoms/Button/index.tsx';
import Input from '../../../atoms/Input/index.tsx';
import Select from '../../../atoms/Select/index.tsx';
import { autoReturnDate, zeroParser } from '../../../utils/ContractUtils';
import styles from './TimeType.module.scss';

const minuteChange = (props, value) => {
  const { setData, stateData, info } = props;

  // const { longTermMonth } = this.state;
  if (value >= 60) {
    alert('0~59 사이의 숫자만 입력해주세요.');
    setData(`${info.key}_minute`, '');

    // autoSetReturnDate(info.key, setData, stateData, longTermMonth)
    return;
  }

  setData(`${info.key}_minute`, value.replace(/0?[^0-9]/g, ''));
};

const minuteBlur = (props, value) => {
  // FIXME: 일월 / 장기렌트일때 date 키값 형식이 다름
  // 일월 : xxx_date / 장기: xxx
  const { setData, stateData, info } = props;
  const returnData = autoReturnDate(info.key, stateData);

  if (value === '') return;

  if (value.length > 2) {
    const sliceValue = value.slice(0, 2);

    setData(`${info.key}_minute`, sliceValue);

    return;
  }

  if (value >= 60) {
    setData(`${info.key}_minute`, '');

    return;
  }

  if (info.key === 'delivered') {
    // 일/월렌트
    setData('expect_return_date', returnData?.date);
    setData('expect_return_hour', returnData?.hour);
    setData('expect_return_minute', returnData?.min);
  }

  if (info.key === 'start_datetime') {
    // 장기렌트
    setData('end_datetime', returnData?.date);
    setData('end_datetime_hour', returnData?.hour);
    setData('end_datetime_minute', returnData?.min);
  }
};

const minDate = (props) => {
  const { info, stateData } = props;

  if (info.calendarOption === 'end') {
    if (stateData.start_datetime) return new Date(stateData.start_datetime);

    if (stateData.delivered_date) return new Date(stateData.delivered_date);

    if (stateData.delivered_at_date) return new Date(stateData.delivered_at_date);
  }

  if (info.calendarOption === 'current') {
    return new Date();
  }

  return '';
};

const maxDate = (props) => {
  const { info, stateData } = props;

  if (info.calendarOption === 'start') {
    if (stateData.end_datetime) return new Date(stateData.end_datetime);

    if (stateData.expect_return_date) return new Date(stateData.expect_return_date);

    if (stateData.expect_return_date_date) return new Date(stateData.expect_return_date_date);
  }

  return '';
};

const renderTimeSelect = (props) => {
  const { setData, stateData, info } = props;

  return (
    <div className={styles.time_group}>
      <Select
        hour
        arrow
        wrapStyle={styles.select_time}
        dataObj={stateData}
        selectKey={`${info.key}_hour`}
        onChange={(value) => {
          setData(`${info.key}_hour`, zeroParser(value));
        }}
        disabled={info.disabled}
        dataset={`${info.key}_hour`}
        allowHours={info?.allowHours}
      />
      <span>시</span>
      <Input
        type="number"
        className={styles.minutes}
        propValue={stateData[`${info.key}_minute`] === null ? '' : stateData[`${info.key}_minute`]}
        handleChange={(value) => minuteChange(props, value)}
        handleBlur={(value) => {
          if (!stateData.orderer) minuteBlur(props, value);

          setData(`${info.key}_minute`, zeroParser(value));
        }}
        disabled={info.disabled}
        dataset={info.key}
      />
      <span>분</span>
    </div>
  );
};

const renderPeriodResult = (props) => {
  const { info } = props;

  if (info.calcResult) {
    return (
      <>
        <div className={styles.calc_result}>{info.calcResult}</div>
        {info.connectType === 'daily' && !info.external && (
          <Button outline btnText="초기화" btnClick={info.func} inlineStyle={info.plusDayStyle} />
        )}
      </>
    );
  }
};

const changeMonth = (...args) => {
  const [value, props] = args;
  const { info, setData, stateData } = props;
  const isStartDate =
    stateData.start_datetime && stateData.start_datetime_hour && stateData.start_datetime_minute;

  if (value > 72) {
    alert('기간설정은 72개월을 초과할 수 없습니다.');
    setData('longTermMonth', null);

    return;
  }

  if (isStartDate) {
    const addMonth = dayjs(stateData.start_datetime).add(value, 'month').format('YYYY-MM-DD');

    setData('end_datetime', addMonth);
  }
};

const renderLongInputChange = (props) => {
  const { info, setData, stateData, monthValue } = props;

  if (info.longStartOption) {
    return (
      <>
        <Input
          type="number"
          inlineStyle={{ width: '50px', height: '28px', marginRight: 5, marginLeft: 10 }}
          propValue={stateData.longTermMonth ?? monthValue}
          handleChange={(value) => setData('longTermMonth', value)}
          handleBlur={(value) => changeMonth(value, props)}
          dataset={info.key}
        />
        <p> 개월</p>
      </>
    );
  }
};

const FullDateType = (props) => {
  return (
    <div className={styles.full_date_wrap}>
      {renderTimeSelect(props)}
      {renderLongInputChange(props)}
      {renderPeriodResult(props)}
    </div>
  );
};

export default FullDateType;
