import styled, { css } from 'styled-components';
import { TEXT } from 'styles/textStyle';

import { DEFAULT_GAP_WITH_LABEL } from './CheckBox.const';

export const Wrapper = styled.label<{ $gap?: number; $disabled: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: ${({ $gap }) => {
    return $gap !== undefined ? `${$gap}px` : `${DEFAULT_GAP_WITH_LABEL}px`;
  }};

  ${TEXT.Caption3_Gray8_Regular_12px}

  line-height: 1;

  cursor: ${({ $disabled }) => {
    return $disabled ? 'not-allowed' : 'pointer';
  }};
`;

export const CheckImageWrapper = styled.div<{ $checked: boolean; $disabled: boolean }>`
  width: 20px;
  height: 20px;

  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $checked, $disabled }) => {
    if ($disabled) {
      return css`
        border: 1px solid var(--new-gray-4);
        background-color: var(--new-gray-3);
      `;
    }

    return $checked
      ? css`
          border: none;
          background-color: var(--new-blue-4);
        `
      : css`
          border: 1px solid var(--new-gray-4);
          background-color: var(--white);
        `;
  }}
`;
