import find from 'lodash/find';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';
import { render } from 'react-dom';
import { getCarSizeOptions } from 'utils/CarUtils';

import apiCarGroupNameList from '../../../api/groupList';
import apiConnect from '../../../api/imsConnect';
import * as apiRentCompanyCars from '../../../api/rentCompanyCar';
import Button from '../../../atoms/Button';
// import Select from '../../../atoms/Select';
import Select from '../../../atoms/Select_V2';
import DropdownSearchInput from '../../../components/DropdownSearchInput';
import Paging from '../../../components/Paging';
import SearchInput from '../../../components/SearchInput';
import { RENT_CAR_NOTICE_KAKAO_INVEN } from '../../../constants/stringTable/description';
import costComma from '../../../helpers/costComma';
import styles from './SelectCarModal.module.scss';
import SelectCarTable from './SelectCarTable';
const CONNECTED_CAR_IMAGE = '/images/carControlImages/ic-carcontrol-twotone.png';

interface PropsType {
  submitFunc?: Function; // partner detail
  carChangeFunc?: Function; // register
  data: any;
  check?: {
    isResponseState: boolean;
    isSelectedCar: boolean;
  };
  modalStore?: any;
  loadingStore?: any;
  authStore?: any;
  menuStore?: any;
  normalContractStore?: any;
  version?: number;
}

interface StatesType {
  list: Array<any>;
  option: string;
  searchValue: string | null;
  selectedItem: any | null;
  totalPage: number;
  currentPage: number;
  requestId: number | null;
  paidCost: number | null;
  currentCarId: number | null;
  carGroupNames: Array<{ value: number; label: string }>;
  groupIdSearchValue: string;
  // carSize?: 'all' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'S' | 'G';
  carSize?: { label: string; value: 'all' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'S' | 'G' };
}

/**
 * @brief 제안 차량 선택 / 예약 차량 변경 모달
 * @author wontae Kim, seokhwan Won
 */
@inject('loadingStore')
@inject('modalStore')
@inject('normalContractStore')
@inject('authStore')
@observer
class SelectCarModal extends Component<PropsType, StatesType> {
  private isRenderingTextForWaitingCarState: boolean;
  constructor(props: PropsType) {
    super(props);
    this.state = {
      list: [],
      option: 'all',
      searchValue: null,
      selectedItem: null,
      totalPage: 1,
      currentPage: 1,
      requestId: props.data ? Number(props.data.id) : null,
      paidCost: props.data ? props.data.paid_cost : null,
      currentCarId: props.data ? (props.data.selectCar || props.data.carInfo)?.id : null,
      carGroupNames: [],
      groupIdSearchValue: null,
      carSize: null,
    };
    // 예약확정 시 문구 렌더 토글링 변수
    this.isRenderingTextForWaitingCarState =
      Boolean(props.carChangeFunc) || !props.check?.isResponseState;
  }

  componentDidMount() {
    this.getListProposalCar();
    this.getCarGroupNameList();
  }

  // 제안 차량 리스트
  getListProposalCar = async (page?: number) =>
    catchAsync(async () => {
      const params = {
        page: page || 1,
        id: this.state.requestId,
        search: this.state.searchValue,
        car_size: this.state.carSize?.value,
        carPriceGroupId: this.state.option,
      };

      if (this.state.currentCarId) {
        Object.assign(params, { focusCarId: this.state.currentCarId });
      }

      if (this.state.groupIdSearchValue) {
        Object.assign(params, { car_group_id: this.state.groupIdSearchValue });
      }

      this.props.loadingStore?.on();
      // inventory완료
      const response = await apiRentCompanyCars.getInventoryCarList(params);

      if (response.status === 200) {
        const responseData = response.data;
        const currentCar = find(responseData?.cars, { id: this.state.currentCarId });

        return this.setState({
          list: responseData?.cars,
          selectedItem: currentCar ? currentCar : null,
          currentPage: params.page || 1,
          totalPage: responseData?.total_page,
        });
      }
    });

  getCarGroupNameList = async () => {
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
      car_size: this.state.carSize?.value,
    };

    catchAsync(async () => {
      this.props.loadingStore.on();
      const response = await apiCarGroupNameList.getCarGroupNameList(params);
      const responseData = response.data;

      if (response.status === 200) {
        const groupNameList = responseData.car_groups.map((item) => {
          return { value: item.id, label: item.name };
        });

        this.setState({
          carGroupNames: groupNameList,
        });
      }
    }, this.props.loadingStore.off);
  };

  movePage = (page: number) => {
    this.getListProposalCar(page);
  };

  checkConnectCar = async (carIdentity) => {
    if (this.props.authStore.use_connect) {
      this.props.loadingStore?.on();
      const result = await apiConnect.getCarData({ carId: carIdentity, disableErrorHandler: true });

      this.props.loadingStore?.off();

      if (result.status === 200) {
        return {
          totalMileage: Math.round(result.data.totalMileage),
          fuelRemainPct: Math.round(result.data.fuelRemainPct),
        };
      }

      return {};
    }

    return {};
  };

  submit = async () => {
    if (!this.state.selectedItem) {
      return alert('제안 차량을 선택해주세요.');
    }

    if (this.state.selectedItem.using_state === 'using') {
      if (!confirm(`현재 대여중인 차량입니다. 그래도 선택 하시겠습니까?`)) {
        return;
      }
    }

    const connectedData = {};

    if (!this.state.selectedItem?.use_connect) {
      // connectedData = await this.checkConnectCar(this.state.selectedItem.car_identity);
    }

    this.props.submitFunc &&
      this.props.submitFunc({ ...this.state.selectedItem, ...connectedData });
  };

  submitForNormalContractRegister = async () => {
    const { selectedItem } = this.state;
    const { normalContractStore, carChangeFunc } = this.props;

    const setCarData = () => {
      const setData: { [x: string]: string } = {
        rent_car_name: selectedItem.car_name,
        rent_car_number: selectedItem.car_identity,
        oil_type: selectedItem.oil_type,
        rent_company_car_id: selectedItem.id,
      };
      const keys = Object.keys(setData);

      keys.map((key) => {
        normalContractStore.setValue({
          key,
          value: setData[key],
        });
      });
    };

    if (!carChangeFunc) {
      setCarData();
    }

    let connectedData = {};

    // 추가로직.... 커넥트 연결되었을경우........ 데이터 가지고오기.
    if (!selectedItem?.use_connect) {
      connectedData = await this.checkConnectCar(selectedItem.car_identity);
    }

    carChangeFunc && carChangeFunc({ ...selectedItem, ...connectedData }, setCarData);
    this.props.modalStore.modalClose();
  };

  selectItem = (item: any) => {
    this.setState({ selectedItem: item });
  };

  search = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.getListProposalCar();
  };

  clearSearchInput = () => {
    this.setState(
      {
        option: 'all',
        searchValue: null,
        groupIdSearchValue: null,
        carSize: null,
      },
      this.getListProposalCar
    );
  };

  // 예약확정 이후 나오는 차량 선택 안내 문구
  // 재요청 건도 제안 시작 ~ 제안 중 까지 나온다. (이미 확정된 이후 건 이므로)
  renderCarSelectInfo = () => {
    if ('waiting_response' === this.props.data.state && this.isCancelledIventory()) {
      return (
        <div className={styles.change_warning}>
          <article style={{ lineHeight: 1.46, width: 280, wordBreak: 'break-all' }}>
            {/* inventory완료 */}
            {this.props.data.version > 1
              ? RENT_CAR_NOTICE_KAKAO_INVEN
              : '추천 차량으로 제안하시면 제안 채택률이 높아집니다.'}
          </article>
        </div>
      );
    }

    if (this.props?.check) {
      const { isSelectedCar, isResponseState } = this.props?.check;

      // 예약확정 이전 상태 & 재요청건
      if (isResponseState && isSelectedCar) {
        return (
          <div className={styles.change_warning}>
            <article style={{ lineHeight: 1.46, width: 280, wordBreak: 'break-all' }}>
              {/* inventory완료 */}
              {this.props.data.version > 1
                ? RENT_CAR_NOTICE_KAKAO_INVEN
                : '추천 차량으로 제안하시면 제안 채택률이 높아집니다.'}
            </article>
          </div>
        );
      }
    }

    // if (this.props.data?.orderer !== 'kakao') {
    return (
      <div className={styles.change_warning}>
        <article>고객과 협의 후 차량을 변경해 주세요. 차량 임의 변경 후</article>
        <article>
          고객 컴플레인 발생 시 <span style={{ color: '#e05656' }}>예약취소</span> 될 수 있습니다.
        </article>
      </div>
    );
    // }

    // 예약차량 변경
    // return (
    //   <div className={styles.change_warning}>
    //     <article>동일 모델/연식으로 변경을 추천드리며, 업그레이드</article>
    //     <article>
    //       차량 제안 시 고객 승인 거절로 <span style={{ color: '#e05656' }}>예약취소</span> 될 수
    //       있습니다.
    //     </article>
    //   </div>
    // );
  };

  renderCarGroupTabBtn = () => {
    if ('waiting_response' === this.props.data.state && this.isCancelledIventory()) {
      return null;
    }

    const { option } = this.state;
    const { data } = this.props;
    const carGradeList = data.carGroupInfo?.gradeList || [];
    const tabBtnData = [{ label: '전체', value: 'all' }].concat(
      carGradeList.map((item: { id: number; desc: string }) => {
        return { label: item.desc, value: String(item.id) };
      })
    );

    return (
      <div className={styles.option_wrap}>
        {tabBtnData.map((item, i) => {
          const isSelected = option === item.value;

          return (
            <div
              key={i}
              className={`${styles.option_btn} ${isSelected ? styles.active_option_btn : ''}`}
              onClick={() => {
                this.setState({ option: item.value }, this.getListProposalCar);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    );
  };

  isCancelledIventory = () => {
    return this.props.data.version > 1 && this.props.data.get_paid;
  };

  getCarSizeList = () => {
    const { body_style, size } = this.props.data.request_car_group.submodel.model.segment;
    const carSizeOptions = getCarSizeOptions(body_style);

    if (!carSizeOptions) {
      return [];
    }

    const index = carSizeOptions.findIndex(({ value }) => value === size);

    return carSizeOptions.slice(index);
  };

  renderOptions = () => {
    console.log(this.isRenderingTextForWaitingCarState);

    return (
      <div
        className={styles.header}
        style={{
          justifyContent:
            // 'waiting_response' === this.props.data.state && this.isCancelledIventory()
            // ? 'flex-end':
            'space-between',
        }}
      >
        {this.isRenderingTextForWaitingCarState ||
        ('waiting_response' === this.props.data.state && this.isCancelledIventory())
          ? this.renderCarSelectInfo()
          : this.renderCarGroupTabBtn()}
        <div className={styles.search_input_wrap}>
          {/* {'waiting_response' === this.props.data.state && this.isCancelledIventory() ? (
            <div style={{ marginRight: 10 }}>
              <Select // Select Compound
                arrow
                placeholder={'차량 등급 전체'}
                optionData={this.getCarSizeList()}
                selectedOption={this.state.carSize}
                onChange={(option: StatesType['carSize']) =>
                  this.setState({ carSize: option, groupIdSearchValue: null }, () => {
                    this.getListProposalCar();
                    this.getCarGroupNameList();
                  })
                }
                inlineStyle={{ width: 200, height: 36, fontSize: 12 }}
                optionContainerStyle={{ fontSize: 12 }}
                placeholderStyle={{ fontSize: 12 }}
              />
            </div>
          ) : null} */}
          <div className={styles.group_search_wrap}>
            <DropdownSearchInput
              width={200}
              height={36}
              onChange={(value) => {
                this.setState({ groupIdSearchValue: value }, this.getListProposalCar);
              }}
              value={this.state.groupIdSearchValue}
              list={this.state.carGroupNames}
              notSelectedText="그룹명 전체"
            />
          </div>
          <div className={styles.search_input}>
            <form onSubmit={this.search}>
              <SearchInput
                style={{ width: 220 }}
                type="text"
                placeholder="차량명 또는 차량번호 검색"
                onChange={(value: string) => this.setState({ searchValue: value })}
                value={this.state.searchValue}
              />
            </form>
          </div>
          <div className={styles.clear_btn}>
            <Button
              outline={true}
              btnClick={this.clearSearchInput}
              btnText="초기화"
              inlineStyle={{ height: 36, padding: '10px 10px', backgroundColor: '#fff' }}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { paidCost } = this.state;
    const { selectedItem } = this.state;

    return (
      <div style={{ padding: '30px 40px' }}>
        {this.renderOptions()}
        <div className={styles.table}>
          <SelectCarTable
            selectedItem={selectedItem}
            isChange={this.isRenderingTextForWaitingCarState}
            list={this.state.list}
            onChange={this.selectItem}
            version={this.props.data.version}
          />
          <Paging
            currentPage={this.state.currentPage}
            totalPage={this.state.totalPage}
            movePage={this.movePage}
          />
        </div>
        <div className={styles.footer}>
          <p className={styles.desc}>
            {
              // 예약확정 이후 혹은 재요청 건 일 경우(재요청 건은 이미 요청차량 정보가 있으므로)
              this.isRenderingTextForWaitingCarState
                ? `총 대여 금액이 [${
                    paidCost ? costComma(paidCost) : '???'
                  }]원으로 확정된 건입니다.`
                : `* 월/장기 렌트 배차중인 차량은 노출되지 않습니다.`
            }
          </p>
          <div>
            <Button
              btnText="취소"
              btnClick={() => this.props.modalStore.modalClose()}
              className={styles.cancel_button}
              outline={true}
            />
            {this.props.carChangeFunc ? (
              <Button
                btnText="변경하기"
                disabled={!selectedItem}
                className={styles.select_button}
                btnClick={this.submitForNormalContractRegister}
              />
            ) : (
              <Button btnText="선택 완료" className={styles.select_button} btnClick={this.submit} />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SelectCarModal;
