import Button from 'atoms/Button';
import { ExtensionRequestState } from 'components/MonthlyExtension/MonthlyExtension.type';
import WriteExtensionContractButton from 'components/WriteExtensionContractButton';
import { useRouter } from 'next/router';
import styled from 'styled-components';

type Props = {
  requestId?: number;
  contractId?: number;
  extensionRequestState?: ExtensionRequestState;
};

const ExtensionRequestRowValue = ({ requestId, contractId, extensionRequestState }: Props) => {
  const router = useRouter();

  switch (extensionRequestState) {
    case ExtensionRequestState.request:
      return (
        <ExtensionConfirmButton
          onClick={(event) => {
            event.stopPropagation();
            router.push(`/partner/detail/normalRequest?id=${requestId}`);
          }}
        >
          연장 확인
        </ExtensionConfirmButton>
      );
    case ExtensionRequestState.pending:
      return <>연장 결제 대기</>;
    case ExtensionRequestState.reject:
      return <>연장 불가</>;
    case ExtensionRequestState.payment:
      return <ExtensionContractButton contractId={contractId}>연장 계약서</ExtensionContractButton>;
    default:
      return <>-</>;
  }
};

export default ExtensionRequestRowValue;

const ExtensionContractButton = styled(WriteExtensionContractButton)`
  width: 76px;
  height: 26px;
  padding: 0;

  border: solid 1px #282828;
  background-color: #fff;

  color: #282828;
  font-size: 12px;
`;

const ExtensionConfirmButton = styled(Button)`
  width: 76px;
  height: 26px;
  padding: 0;

  border: solid 1px #f84b4b;
  background-color: #fff;

  color: #f84b4b;
  font-size: 12px;
`;
