import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
export interface IDailyCostDetailParams {
  cost_table_id?: string;
}

interface Detail {
  id: number;
  weekend: boolean;
  period_id: number;
  cost: number;
  d1_cost: number;
  d3_cost: number;
  d5_cost: number;
  d7_cost: number;
  h1_cost: number;
  h6_cost: number;
  h12_cost: number;
  period: Period;
}

interface Period {
  end_at: string;
  friday: boolean;
  id: number;
  is_basic: boolean;
  monday: boolean;
  name: string;
  saturday: boolean;
  start_at: string;
  sunday: boolean;
  thursday: boolean;
  tuesday: boolean;
  wednesday: boolean;
}

type SuccessResponse = {
  car_groups: Array<{ id: number; name: string }>;
  id: number;
  name: string;
  details: Detail[];
  defaultInfo: callApi2.DefaultInfo;
  company_id: number;
  created_at: string;
  removed_at: null;
  updated_at: null;
};
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type IDailyCostDetailResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getDailyCostDetail(params: IDailyCostDetailParams, store: StoreType) {
  const response = await callApi2.get<IDailyCostDetailResponse>(
    `/v2/group-cost-tables/daily/${params.cost_table_id}`,
    omit(params, ['cost_table_id'])
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
