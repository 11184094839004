import React from 'react';

import styles from './Skeleton.module.scss';

export const CallCardSkeleton = () => {
  return (
    <>
      <div className={styles.call_card_line1} />
      <div className={styles.call_card_line2} />
      <div className={styles.call_card_block}>
        <div />
        <div />
      </div>
      <div className={styles.call_card_line3} />
      <div className={styles.call_card_line3} />
      <div className={styles.call_card_line3} />
    </>
  );
};
