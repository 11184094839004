import { action, autorun, computed, makeAutoObservable, makeObservable, observable } from 'mobx';

import { _setObject, BaseStore, ClassVariableType } from './BaseStore.type';

class LoadingStore implements BaseStore<LoadingStore> {
  display = false;
  instanceId = 0;

  on = () => {
    this.display = true;
  };

  off = () => {
    this.display = false;
  };

  loadingState = () => {
    return this.display;
  };

  setObject = (data) => _setObject(this, data);

  constructor() {
    makeAutoObservable(this);
    this.instanceId = Math.round(Math.random() * 1000000000);
  }
}

export default LoadingStore;
