export const ID = '아이디';
export const PASSWORD = '비밀번호';
export const REQUEST_ID = '요청번호';
export const REQUEST_TYPE = '요청타입';
export const RENT_TYPE = '유형';

//차량
export const CAR_NUMBER = '차량번호';
export const CAR_ORIGIN = '차량 구분';
export const OIL_TYPE = '유종';
export const REGISTER_CAR = '차량등록';
export const CAR_REGISTRATION = '차량 등록증';
export const CONNECT_BUSSINESS = '사업자 연결';
export const MANAGE_STEAD_CONTRACT = '관리위탁 계약서';
export const MANAGE_STEAD_CONTRACT_SELECT = '관리위탁 계약서(선택)';
export const REGISTER_REGISTRATION = '등록증 등록';
export const USE_FEE_COUPON = '무료쿠폰사용';
export const INIT_INSURANCE_JOIN_DATE = '최초보험가입일';
export const RENT_CAR = '렌트차량';

//요청
export const CONTRACT_ID = '연번';
export const RENTAL_CAR = '대여 차종';
export const SUGGESTION_CAR = '제안 차량';
export const SUGGESTION_CAR1 = '제안 차량 1';
export const SUGGESTION_CAR2 = '제안 차량 2';
export const CUSTOMER_CAR = '고객 차량';
export const WISH_CAR = '희망 차종';
export const DAILY_PER = '1일당';
export const FEE_FREE = '수수료 무료';
export const ACCIDENT_CAR_TYPE = '사고 차종';
export const BORROW_CAR = '대차차량';
export const EXPECT_RETURN_DATE = '반납 예정일';
export const REQUEST_DATE = '요청일';
export const ENGINE_TYPE = '배기량';
export const ENGINE_TYPE_CC = '배기량 (cc)';
export const CAR_OLD = '연식';
export const DRIVER_AGE = '운전자 나이';
export const LOCATION = '지역';
export const ONESELF_FAULT_RATE = '본인 예상과실';
export const INSURANCE_COMPANY_NAME = '보험사';
export const RESERVATION_DATE = '희망배차시간';
export const REGISTRATION_ID = '가해 보험 접수번호';
export const REPAIR_PERIOD = '예상 수리기간';
export const EXTRA = '기타 요청사항';
export const FEE_INFO = '수수료 정보';
export const CAR_CENTER_ADDRESS = '요청자 주소';
export const REQUEST_GARAGE = '입고 공업사';
export const CLAIM_RATE = '청구율';
export const ADDITIONAL_SUGGEST_SUBJECT = '추가 제안 사항';
export const SELF_INSURANCE = '자차보험';
export const SELF_CAR_INSURANCE = `자기부담금(${SELF_INSURANCE})`;
export const REQUEST_LOCATION = `요청 지역`;
export const RESPONSE_TYPE = '제안 구분';
export const SELF_CONTRACT = '비대면계약서';

export const INSURANCE_OPPONENT_LIMIT = '대인 한도';
export const INSURANCE_OBJECT_LIMIT = '대물 한도';
export const INSURANCE_ONESELF_LIMIT = '자손 한도';
export const INDEMNFICATION_COST = '면책금';
export const INDEMNFICATION_COST_WON = '면책금(만원)';
export const SELF_CAR_INDEMNFICATION_COST = '자차 면책금(만원)';

export const OPPONENT_INDEMNFICATION_COST = '대인 면책금(인당)';
export const OBJECT_INDEMNFICATION_COST = '대물 면책금(건당)';
export const ONESELF_INDEMNFICATION_COST = '자손 면책금';
export const ONESELF_CAR_INDEMNFICATION_COST = '자차 면책금';
export const SUPPLY_SERVICE = '서비스제공';

//계정
export const NICKNAME = '닉네임';
export const REALNAME = '실명';
export const PHONE = '연락처';
export const FAX = '팩스';
export const ADDRESS = '주소';
export const CHARGER = '담당자';
export const CAR_TYPE = '차종';
export const COST = '요금';
export const TOTAL_COST = '총요금';
export const EMAIL = '이메일';
export const JOB_GRADE = '직급';
export const JOB_TITLE = '직함';

export const AUTOMOBILE_USER = '종사자';
export const COMPANY_NAME = '업체명';
export const COMPANY_CONTACT = '업체 대표 번호';
export const DELIVERY_USER_NAME = '배차직원명';
export const RESIDENT_NUMBER = '생년월일';
export const DRIVER_LICENSE_NUMBER = '면허번호';
export const DRIVER_LICENSE_TYPE = '면허 구분';
export const DRIVER_LICENSE_STATUS = '면허 상태';
export const DRIVER_LICENSE_EXPIRE_DAY = '만기일';

//계약서작성
export const USER = '사용자';
export const BASIC_INFO = '기본정보';
export const ETC_INFO = '기타 정보';
export const RENTAL_INFO = '렌트 정보';
export const EMPLOYER_INFO = '사업자 정보';
export const CUSTOMER_INFO = '고객 정보';
export const SUGGESTION_CONTENT = '제안내용';
export const CAR_INFO = '차량 정보';
export const CAR_NAME = '차량이름';
export const CUSTOMER_CAR_INFO = '고객 차량정보';
export const RENT_CAR_INFO = '대차정보';
export const BUSSINESS_NAME = '사업자명';
export const EMPLOYER_NAME = '대표자명';
export const BUSSINESS_ADDRESS = '사업자 주소';
export const DRIVER_INFO = '운전자 정보';
export const SECOND_DRIVER_INFO = '제 2운전자 정보';
export const BUSSINESS_INFO = '사업정보';
export const ACCOUNT_INFO = '계정정보';
export const SEARCH_DRIVER_LICENSE_YN = '면허증 진위여부 조회';
export const DRIVER_NAME = '운전자명';
export const SECOND_DRIVER = '제 2운전자';
export const SECOND_DRIVER_NAME = '제 2운전자명';
export const SECOND_DRIVER_PHONE = '제 2운전자 연락처';
export const SECOND_DRIVER_ADDRESS = '제 2운전자 주소';
export const SECOND_DRIVER_RESIDENT_NUMBER = '제 2운전자 생년월일';
export const SECOND_DRIVER_LICENSE_NUMBER = '제 2운전자 면허번호';
export const SECOND_DRIVER_LICENSE_TYPE = '제 2운전자 면허구분';
export const SECOND_DRIVER_LICENSE_EXPIRE_DAY = '제 2운전자 만기일';
export const INTRODUCE_PLACE_INFO = '소개처 정보';
export const INTRODUCE_PLACE_NAME = '소개처 명';
export const INTRODUCE_PLACE_CHARGER = '소개처 담당자';
export const INTRODUCE_PLACE_PHONE = '소개처 번호';
export const BUSSINESS_EMPLOYEE_INFO = '영업 직원 정보';
export const ESPECIAL_POINT = '특이사항';
export const ALLOCATE_CAR_DATE = '배차일';
export const RETURN_DATE = '반납일';
export const RETURN_REQUEST = '반납 요청';
export const CALL_COMPLETE = '통화 완료';
export const ACCIDENT_CAR_NUMBER = '사고 차량번호';
export const CLAIM_TOTAL_COST = '청구금';

//계약서 상태
export const INSURANCE = '보험';
export const NORMAL = '일반';
export const WAIT_CLAIM = '청구대기';
export const PENDING_CLAIM = '청구 검수중';
export const COMPLETE_CLAIM = '청구완료';
export const WAIT_DEPOSIT = '입금대기';
export const COMPLETE_DEPOSIT = '입금완료';
export const COMPLETE_CONTRACT = '계약종결';
export const WAIT_EXTEND = '연장대기';

export const DAILY_RENTAL = '일 렌트';
export const MONTHLY_RENTAL = '월 렌트';
export const LONG_RENTAL = '장기 렌트';

//계약서 상세
export const CONNECT_RENT_CALL = '렌카콜 연결';
export const BOOKING_DETAIL_VIEW = '예약 상세 보기';
export const CUSTOMER_CAR_NUMBER = '고객차량 번호';
export const RECEIPT_NUMBER = '접수번호';
export const INSURANCE_COMPANY_CHARGER = '보험사 담당자';
export const CHARGER_PHONE = '담당자 연락처';
export const CHARGER_NAME = '담당자 이름';
export const GARAGE = '공업사';
export const GARAGE_PHONE = '공업사 연락처';
export const INSURANCE_COMPANY_FAX_NUMBER = '보험사 팩스번호';
export const REQUESTER_PHONE = '요청자 연락처';
export const REQUESTER_INSURANCE_NUMBER = '요청자 보험사명';
export const INSURANCE_TRANS_POINT = '보험사 전달사항';
export const COPY_BANKBOOK = '통장 사본';
export const BUSSINESS_REGISTRATION = '사업자 등록증';
export const RENTAL_CONTRACT = '임대차 계약서';
export const STATEMENT = '명세서';
export const PRICE_LIST = '요금표';
export const CONTRACT_PASSWORD = '계약서 암호';
export const ALLOCATE_CAR_TIME = '배차시간';
export const RETURN_TIME = '반납시간';
export const RETURN_DATETIME = '반납 일시';
export const RENTAL_DATE = `대여일시`;
export const RENTAL_FEE = `대여금액`;
export const ALLOCATE_CAR_LOCATION = '배차장소';
export const BOOKING_PROCESS_CONFIRM = '예약 진행 확인';
export const MEMO = '메모';
export const CANCEL_DATE = '취소일';
export const CANCEL_REASON = '취소사유';

//계약서 목록
export const RENTAL_CAR_NUMBER = '대여 차량 번호';
export const CUSTOMER_NAME = '고객명';

//요청상세
export const CUSTOMER_PHONE = '고객 연락처';
export const REST_CAR_COST = '휴차료';
export const CAR_USE_SUPERPASS = '슈퍼패스 사용가능';
export const SEARCH_CAR_NUMBER = '차량번호 조회';
export const MY_LOCATION_CALL = '내 지역 콜';
export const RESPONSING = '제안중';
export const COMPLETE_RESERVATION = '예약확정';
export const USING_CAR = '배차중';
export const ALLOCATING_CAR = '배차중';
export const COMPLETE_RETURN = '반납완료';

//비대면계약서
export const BUSSINESS_EMPLOYEE = '영업 직원';
export const INTRODUCE_PLACE = '소개처';
export const NOTICE_POINT = '안내사항';
export const AGREE_ALL_TERMS = '약관 전체 동의';
export const AGREE_RENTAL_TERM = '[필수] 대여약관 및 주요 고지사항에 대한 동의';
export const AGREE_PRIVACY_TERM = '[필수] 개인 정보 수집 및 이용동의';
export const AGREE_SERVICE_TERM = '[필수] 제3자 정보제공 및 조회 동의';
export const AGREE_LOCATION_TERM = '[필수] 개인위치정보 조회 및 이용동의';

//캐쉬
export const DEDUCT_COST = '차감액';
export const COMMISSION = '수수료';
export const BEFORE_TAX_COST = '세전금액';
export const PAYMENT_COST = '결제금액';
export const DEPOSIT_YN = '입금여부';

//약관동의
export const TERMS_OF_SERVICE = '서비스 이용약관';
export const PRIVACY = '개인정보 취급 방침';
export const LOCATION_SERVICE = '위치기반 서비스';
export const LOCATION_SERVICE_VALIDATION = `${LOCATION_SERVICE}에 동의해 주세요.`;
export const TERMS_OF_SERVICE_VALIDATION = `${TERMS_OF_SERVICE}에 동의해 주세요.`;
export const PRIVACY_VALIDATION = `${PRIVACY}에 동의해 주세요.`;

//사업자관리
export const BUSSINESS_REGISTRATION_NUMBER = '사업자 등록번호';
export const BUSSINESS_PRICE_LIST = '사업자 요금표';
export const BUSSINESS_TYPE = '업태';
export const SUBJECT = '종목';
export const MANAGE_CERTIFICATE = '공인인증서 관리';
export const BANK = '은행';
export const ACCOUNT_NUMBER = '계좌번호';
export const ACCOUNT_HOLDER = '예금주';
export const TEL_NUMBER = '전화번호';
export const EMPLOYEE_LIST = '직원목록';
export const BOSS = '대표';
export const EMPLOYEE = '직원';
export const NORMAL_EMPLOYEE = '일반 직원';
export const MANAGER = '매니저';
export const CLAIM_EMPLOYEE = '청구 직원';
export const CAR_MANAGER = '카매니저';

//내정보
export const DOMESTIC_CAR_COUNT = '국산 차량대수';
export const IMPORTED_CAR_COUNT = '수입 차량대수';
export const COMPANY_DESCRIPTION = '업체 소개';

//공통
export const DIVISION = '구분';
export const ALL = '전체';
export const NONE_REGISTRATION = '미등록';
export const NOTICE = '공지';
export const ETC = '기타';
export const NONE_FAULT = '무과실';
export const NONE_CONFIRM = '미확정';
export const NONE_DEPOSIT = '미입금';
export const NOTHING = '없음';
export const NOTHING_TO_DO = '상관없음';
export const TEN_THOUSAND_WON = '만원';
export const TEN_THOUSAND = '만';
export const WON = '원';
export const INFINITE = '무한';
export const SIGN_UP = '가입';
export const NO_SIGN_UP = '미가입';
export const DATETIME = '일시';
export const NAME = '이름';
export const USE_LOCATION = '이용지역';

//게시판
export const TITLE = '제목';
export const CONTENT = '내용';
export const WRITER = '작성자';
export const REGISTER_DATE = '등록일';

//알림
export const CLASSIFICATION = '분류';

//내정보
export const REVISE_MY_INFO = '내 정보 수정';

export const EXCEPT_PAST_DATE = '지난 날짜 제외';
