import React from 'react';

import Button from '../../../atoms/Button/index.tsx';
import * as parser from '../../../utils/DataParser';
import styles from './OutputType.module.scss';

const s3 = 'https://s3.ap-northeast-2.amazonaws.com/rencar/signatureImages/';

const valueParser = (props) => {
  const { info, stateData, style } = props;
  let result = stateData[info.key];
  const commaReg = /\B(?=(\d{3})+(?!\d))/g;

  if (info.key === 'delivered') {
    return `${stateData.delivered_date} ${stateData.delivered_hour}:${stateData.delivered_minute}`;
  }

  if (info.valueType === 'img' && result) {
    return <img style={info.imgStyle || {}} src={`${s3}${result}`} />;
  }

  if (info.comma) result = String(result).replace(commaReg, ',');

  if (info.contractParser) result = parser.contractParser(result);

  if (info.insuranceFeeParser) result = parser.insuranceFeeParser(result);

  if (info.key === 'customer_id_number' || info.key === 'second_driver_id_number') {
    if (stateData[`${info.key}1`] && !stateData[`${info.key}2`]) {
      return stateData[`${info.key}1`];
    }

    if (stateData[`${info.key}1`] && stateData[`${info.key}2`]) {
      result = `${stateData[`${info.key}1`]} - ${stateData[`${info.key}2`]}`;
    }
  }

  if (info.key === 'drivingHabit') {
    const score = stateData.driving_score;
    let grade = null;

    if (score > 70) {
      grade = (
        <div>
          <span className={styles.grade_good}>{`우수 `}</span>
          <span>{`(${score}점)`}</span>
        </div>
      );
    } else if (score >= 50) {
      grade = (
        <div>
          <span className={styles.grade_good}>{`양호 `}</span>
          <span>{`(${score}점)`}</span>
        </div>
      );
    } else if (score >= 35) {
      grade = (
        <div>
          <span className={styles.grade_bad}>{`주의 `}</span>
          <span>{`(${score}점)`}</span>
        </div>
      );
    } else {
      grade = (
        <div>
          <span className={styles.grade_bad}>{`위험 `}</span> <span>{`(${score}점)`}</span>
        </div>
      );
    }

    return grade;
  }

  if (info?.textFromValue) result = info.textFromValue; // value값을 기반으로 원하는 표시 값을 만들고 싶을 때

  return (
    <p>
      {(result !== '-1' && result) || '-'}
      {info.unit && result && result !== '-1' && info.unit}
    </p>
  );
};

const OutputType = (props) => {
  const { info, stateData } = props;

  return (
    <div className={styles.output_area} style={info.style}>
      {valueParser(props)}
      {info.addText && <p className={styles.add_text}>{info.addText.text}</p>}
      {info.btn?.name && (
        <Button
          className={styles.outpu_btn}
          btnText={info.btn.name}
          inlineStyle={info.btn.style ? info.btn.style : {}}
          btnClick={info.btn.func}
          disabled={info.btn.disabled}
        />
      )}
      {info?.badge && (
        <div style={info.badge.style ? info.badge.style : {}}>{info?.badge?.text || ''}</div>
      )}
      {info.child}
    </div>
  );
};

export default OutputType;
