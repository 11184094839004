import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { string, z } from 'zod';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};
export const IGetGroupUsersParam = z.object({
  option: z.string().optional(),
  search: z.string().optional(),
  page: z.number().optional(),
  only_joined: z.number(),
  disableErrorHandler: z.boolean().optional(),
});
export type IGetGroupUsersParam = z.infer<typeof IGetGroupUsersParam>;

type SuccessResponse = {
  group_name: string;
  has_group: boolean;
  list: List[];
  share_car_data: boolean;
  totalPage: number;
  defaultInfo: callApi2.DefaultInfo;
};
export interface List {
  company: string;
  contact: string;
  group_joined: number;
  id: number;
  leader: number;
  president_name: string;
}

export type IGetGroupUsersResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getGroupUsers(
  params: IGetGroupUsersParam,
  store: StoreType
): Promise<IGetGroupUsersResponse> {
  const response = await callApi2.get<IGetGroupUsersResponse>(`/v2/group/users`, params);

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
