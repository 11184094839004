import callApi from '../../helpers/callApi';

export default {
  getNotifications(params) {
    return callApi({
      method: 'GET',
      url: `/v2/notifications/${params.page}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
};
