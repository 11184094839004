/**
 * @brief Pre-fetch parent class / 설명 및 사용법은 Readme.md 참고
 * @author wontae Kim
 */

import { setStoreDefaultData } from '../../helpers/callApi.js';
import { FetchWrapperArg, FetchWrapperParams } from '../../interface/utils';

interface FetchFactoryInterface {
  isServer: boolean;
  loadingState(store: any, isOn: boolean): void;
  fetchWrapper(arg: FetchWrapperArg, callback: Function, useLoading: boolean): Promise<unknown>;
  setStoreData(store: any, defaultInfo: any): void;
}

/**
 * callApi & pre-fetch Factory
 */
export default abstract class FetchFactory implements FetchFactoryInterface {
  readonly isServer = typeof window === 'undefined';
  /**
   * @brief loadingStore On Off
   * @author wontae Kim
   *
   * @param {Object} store
   * @param {Boolean} isOn
   * true or false
   */
  loadingState = (store: any, isOn: boolean) => {
    if (!this.isServer && store) {
      if (isOn) {
        store.on();
      } else {
        store.off();
      }
    }
  };

  /**
   * @brief interface - SSR 이 필요한 경우 '필수' override
   * 데이터 사전 요청 및 전달용
   * @author wontae Kim
   *
   * @param {FetchWrapperArg} ctx
   * @returns {Object}
   */
  protected abstract preFetch(ctx: FetchWrapperArg): Promise<any>;

  /**
   * @brief 서버사이드와 클라이언트사이드의 api 호출 파라미터 객체가 다르므로 구성을 맞춤.
   * 추가적으로 단순화 시킴
   * @author wontae Kim
   *
   * @param {FetchWrapperParams} arg ctx - SSR or this[Component] - CSR
   * @param {Function} callback
   * @param {Boolean} useLoading
   * @returns
   */
  fetchWrapper = async (arg: FetchWrapperParams, callback: Function, useLoading = true) => {
    const params: FetchWrapperParams = {
      req: this.isServer && arg.req, // only SSR
      res: this.isServer && arg.res, // only SSR
      auth: !this.isServer && arg.auth, // only CSR (withPrivateRoute)
      mobxStore: this.isServer || arg.mobxStore ? arg.mobxStore : arg.props,
      query: this.isServer || arg.query ? arg.query : arg.props?.router?.query,
      /**
       * isServer = false 라도, 서버사이드 단계의 객체가 들어올수 있다. (router.push 등의 경우 getInitialProps 경유함)
       * 이 경우, 서버사이드 에서 들어오는 객체를 이용하여 처리한다.
       *  */
    };

    const loadingStore =
      this.isServer || arg.mobxStore ? arg.mobxStore?.loadingStore : arg.props.loadingStore;

    return catchAsync(
      async () => {
        useLoading && this.loadingState(loadingStore, true);

        return await callback(params);
      },
      () => {
        useLoading && this.loadingState(loadingStore, false);
      }
    );
  };

  // @Builder
  fetchWrapperBuilder = (arg: FetchWrapperParams) => {
    let callback: Function = () => void 0;
    let useLoading = true;

    return {
      setCallback(cb: Function) {
        callback = cb;

        return this;
      },
      setUseLoading(_useLoading: boolean) {
        useLoading = _useLoading;

        return this;
      },
      build: () => this.fetchWrapper(arg, callback, useLoading),
    };
  };

  /**
   * @brief api 호출 후 store 의 defaultData setting
   * @author wontae Kim
   *
   * @param {Object} store stores object
   * @param {Object} defaultInfo
   */
  setStoreData = (store: any, defaultInfo: any) => {
    setStoreDefaultData(defaultInfo, {
      menuStore: store?.menuStore,
      authStore: store?.authStore,
    });
  };
}
