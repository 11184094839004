import find from 'lodash/find';

import { insuranceInfo } from '../../constants/CommonRowTableData/contractRegisterData';

/* 
  FIXME:
  partner/selected
  에서 자차면책금 키값이
  indemnification_fee
  이렇게 보내주고있는데
  v2/rencar-claims 로 계약서 제출할때 키값은
  indemnfication_fee
  이렇게 되어있습니다.
*/

export const changeType = (info, setData) => {
  if (info.type === 'radio' && info.btn.name === '선택입력') {
    if (info.key === 'indemnfication_fee') {
      info.type = 'double';
      info.unit = '만원';
      info.input = {
        comma: true,
        type: 'number',
        style: { width: 100 },
      };
    }

    if (info.key !== 'indemnfication_fee') {
      info.input = {
        comma: true,
        type: 'number',
        placeholder: '숫자만 입력하시오.',
      };
      delete info.type;
    }
  }

  if (info.btn.name === '직접입력') {
    if (info.key === 'indemnfication_fee') {
      setData('indemnfication_fee1', null);
      setData('indemnfication_fee2', null);
    }

    info.type = 'radio';
    delete info.input;
  }
};

const driverDisabled = (info, certificated, list) => {
  if (certificated) {
    list.map((key) => {
      if (info.key === key) {
        if (info.type === 'select') {
          info.select.disabled = true;
        } else {
          info.input.disabled = true;
        }
      }
    });
  } else {
    list.map((key) => {
      if (info.key === key) {
        if (info.type === 'select') {
          // 면허 진위 여부 조회시 조회 전에 기본값은 false이지만
          // true라는건 일부러 만든 경우이기에 그 경우가 바뀌는 것은 방지 (추후 해당 로직 최상위 컴포넌트로 리팩터링 필요)
          if (info?.select?.disabled) return;

          info.select.disabled = false;
        } else if (info.input) {
          // 면허 진위 여부 조회시 조회 전에 기본값은 false이지만
          // true라는건 일부러 만든 경우이기에 그 경우가 바뀌는 것은 방지 (추후 해당 로직 최상위 컴포넌트로 리팩터링 필요)
          if (info?.input?.disabled) return;

          info.input.disabled = false;
        }
      }
    });
  }
};

export const radioDefaultSet = (data) => {
  const { info, setData, stateData } = data;

  if (info.radio && info.type === 'radio') {
    const isDefault = find(info.radio.data, { default: true });

    if (isDefault && stateData[info.key] === null) {
      setData(info.key, isDefault?.value);
    }
  }
};

export const disabledSet = (data) => {
  const { info, stateData } = data;
  const driverDisabledList = ['customer_name', 'customer_driver_license', 'customer_driver_type'];
  const secondDriverDisabledList = [
    'second_driver_name',
    'second_driver_license',
    'second_driver_type',
  ];

  driverDisabled(info, stateData.license_certificated, driverDisabledList);
  driverDisabled(info, stateData.second_driver_license_certificated, secondDriverDisabledList);
};

export const selfCarInsurance = (setData, stateData) => {
  // 자차보험 radio 이벤트 발생시 처리
  if (!stateData.rent_request_id) {
    setData('indemnfication_fee', null);
    setData('indemnfication_fee1', null);
    setData('indemnfication_fee2', null);
    setData('self_car_insurance_limit', null);
    insuranceInfo[1][0].type = 'radio';
    insuranceInfo[1][0].btn.name = '직접입력';
    insuranceInfo[1][1].type = 'radio';
    insuranceInfo[1][1].btn.name = '직접입력';
  }
};
