import { kakaoCallCreatedAfter220714 } from '../utils/InsuranceUtils';

export const PARTNER_RENT_CAR_NOTICE_LIST = [
  '1. 고객 결제가 진행 중일 경우에는 제안 수정 및 취소가 제한될 수 있습니다.',
  '2. 예약확정 후 차량변경은 고객과 먼저 협의 후 진행해 주세요.',
  '3. 대여료는 보험 및 왕복 딜리버리 비용이 포함되어 있습니다. (수수료 포함)',
  '4. 고객이 조기반납, 대여 연장을 요청할 경우 고객과 협의 후 진행해 주세요.',
  '5. 예약 취소 시 즉시 취소처리 및 고객 환불이 진행되니 신중히 처리해 주세요.',
];

export const rentCarNoticeData = [
  '1. 고객 결제가 진행 중일 경우에는 제안 수정 및 취소가 제한될 수 있습니다.',
  '2. 예약확정 후 차량변경은 제안 차량과 동급(모델, 연식)차량 및 업그레이드 차량만 가능',
  '합니다.',
  {
    title: '3. 아래 경우 대여료의 10% 페널티 금액이 발생합니다.',
    sub: [
      '- 배차포기하여, 고객이 요청한 차량섭외가 불가능할 경우',
      '- 약속된 배차일시를 지키지 못할 경우(업체 노쇼)',
    ],
  },
  '4. 대여료는 보험 및 왕복 딜리버리 비용이 포함되어 있습니다.',
  '5. 대여료에 수수료가 포함되어 있습니다.',
  '6. 고객의 요청에 따라 대여시간 연장(최대2시간, 유료)이 발생 할 수 있습니다.',
  '7. 배반차시의 주유량에 따라 주유비가 재정산 될 수 있습니다.',
];

// inventory완료
export const rentCarNoticeDataInven = [
  '1. 고객 결제가 진행 중일 경우에는 제안 수정 및 취소가 제한될 수 있습니다.',
  '2. 예약확정 후 차량변경은 고객과 먼저 협의 후 진행해 주세요.',
  '3. 대여료는 보험 및 왕복 딜리버리 비용이 포함되어 있습니다.',
  '4. 대여료에 수수료가 포함되어 있습니다.',
  '5. 고객이 조기반납, 대여 연장을 요청할 경우 고객과 협의 후 진행해 주세요.',
  // 화이트라벨 월렌트
  '6. 월 렌트 보증금은 최초 예약확정된 금액에서 변경하실 수 없습니다. (현장결제)',
];

export const selfContractTooltipMsg = `💡  일반렌트 콜 연결건 비대면 계약서 주의사항
  • 요청 상세페이지에서 확인 후 비대면 계약서/서명 링크를 발송해주세요.
  • 일반렌트 콜 연결건은 전송한 비대면 계약서를 삭제할 수 없습니다.
  • 요청이 취소될 경우 전송한 비대면 계약서는 자동으로 삭제됩니다.
`;

const parseOrdererForInsuranceTitle = (orderer) => {
  if (orderer === 'kakao') {
    return '카카오';
  }

  if (orderer === 'partner') {
    return '홈페이지';
  }

  return 'IMS';
};

export const parseExternalInsuranceInfoTitle = (orderer, option) => {
  const isJejuAndGeneralInsu =
    option?.location === '제주특별자치도' && option?.insuranceType === 'general';
  const isJejuAndPremiunInsu =
    option?.location === '제주특별자치도' && option?.insuranceType === 'premium';
  const isJejuAndSuperInsu =
    option?.location === '제주특별자치도' && option?.insuranceType === 'super';
  const isUlleungdo = option?.location === '경상북도' && option?.location2 === '울릉군';
  let result = `${parseOrdererForInsuranceTitle(orderer)}콜 일반렌트 제공 보험`;

  if (isJejuAndGeneralInsu) {
    result += ' (제주/일반자차)';
  }

  if (isJejuAndPremiunInsu) {
    result += '(제주/<span style="color: #f84b4b">고급자차</span>)';
  }

  if (isJejuAndSuperInsu) {
    result += '(제주/<span style="color: #f84b4b">완전자차</span>)';
  }

  if (isUlleungdo) {
    result += ' (울릉)';
  }

  return <span dangerouslySetInnerHTML={{ __html: `${result}` }}></span>;
};

export const carPriceGroupDescriptions = [
  {
    id: 1,
    desc: '경형',
    example: ['레이', '모닝', '스파크'],
  },
  {
    id: 2,
    desc: '준중형',
    example: ['아반떼', 'K3'],
  },
  {
    id: 3,
    desc: '중형',
    example: ['쏘나타', 'K5', 'SM6'],
  },
  {
    id: 4,
    desc: '준대형',
    example: ['그랜져', 'K7'],
  },
  {
    id: 5,
    desc: '중형 P',
    example: ['G70', '스팅어'],
  },
  {
    id: 6,
    desc: '준대형 P',
    example: ['G80'],
  },
  {
    id: 7,
    desc: '대형',
    example: ['K9'],
  },
  {
    id: 8,
    desc: '대형 P',
    example: ['G90'],
  },
  {
    id: 9,
    desc: '초소형',
    example: ['베뉴', '스토닉'],
  },
  {
    id: 10,
    desc: '소형',
    example: ['코나', '셀토스', '티볼리'],
  },
  {
    id: 11,
    desc: '준중형',
    example: ['투싼', '스포티지'],
  },
  {
    id: 12,
    desc: '중형',
    example: ['싼타페', '쏘렌토', 'QM6'],
  },
  {
    id: 13,
    desc: '준대형',
    example: ['펠리세이드', '모하비'],
  },
  {
    id: 14,
    desc: '준대형 P',
    example: ['GV80'],
  },
  {
    id: 15,
    desc: '승합',
    example: ['카니발, 스타렉스'],
  },
];

export const carPriceGroupNames = [
  {
    key: [1, 2],
    value: '승용 소형',
  },
  {
    key: [3],
    value: '승용 중형',
  },
  {
    key: [4],
    value: '승용 대형',
  },
  {
    key: [5, 6, 7, 8],
    value: '승용 프리미엄',
  },
  {
    key: [9, 10],
    value: '소형 SUV',
  },
  {
    key: [11, 12],
    value: '중형 SUV',
  },
  {
    key: [13, 14],
    value: '대형 SUV',
  },
  {
    key: [15],
    value: '승합',
  },
];
