import React, { useEffect, useState } from 'react';

import apiEmployees from '../../../api/user';
import Button from '../../../atoms/Button';
import Paging from '../../../components/Paging';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import useStores from '../../../stores/UseStores';
import styles from './CarBookingRegisterModal.module.scss';

interface EmployeeInfoGet {
  company: string;
  contact: string;
  id: number;
  realname: string;
  title: string;
  username: string;
  drivers_license_id?: number;
  license_status_code?: number;
  license_type?: string;
  permission?: number;
  use_connect?: number;
  validation_date?: string;
}

export default function CarBookingRegisterModal({ setData, id }: any) {
  const { modalStore } = useStores();
  const [employeeId, setEmployeeId] = useState<number | null>(null);
  const [employeeName, setEmployeeName] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [searchName, setSearchName] = useState<string>('');
  const [list, setList] = useState<Array<EmployeeInfoGet>>([]);

  useEffect(() => {
    if (id !== employeeId) {
      setEmployeeId(id);
    }

    apiEmployeeList({});
  }, []);

  const apiEmployeeList = async ({ search = '', page = 1 }) => {
    catchAsync(async () => {
      const responseEmployee = await apiEmployees.getSalesEmpolyees({
        page,
        searchOption: 'name',
        searchValue: search,
      });

      setTotalPage(responseEmployee.data.totalPage);
      setList(responseEmployee.data.list);
    });
  };

  const movePage = (page: number) => {
    if (currentPage !== page) {
      setCurrentPage(page);
      apiEmployeeList({ page });
    }
  };

  const tbodyData = () => {
    return list.map((data: EmployeeInfoGet) => {
      let checkStyle;

      if (employeeId === data.id) {
        checkStyle = styles.active_checkbox;
      } else {
        checkStyle = styles.inactive_checkbox;
      }

      return (
        <tr
          key={data.id}
          onClick={() => {
            if (employeeId === data.id) {
              setEmployeeId(null);
              setEmployeeName('');
            } else {
              setEmployeeId(data.id);
              setEmployeeName(data.realname);
            }
          }}
        >
          <td>
            <div className={`${styles.checkbox_div} ${checkStyle}`}>
              <img src={'/images/checkboxImage/check_icon_white_thin.png'} />
            </div>
          </td>
          <td>{data.realname}</td>
          <td>{data.contact}</td>
          <td>{data.company}</td>
        </tr>
      );
    });
  };

  const _searchSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    apiEmployeeList({ page: 1, search: searchName });
    setCurrentPage(1);
  };

  const _cancel = () => {
    modalStore.modalClose();
  };

  const _save = () => {
    const result = {
      id: employeeId,
      name: employeeName,
    };

    setData(result);
    modalStore.modalClose();
  };

  const resetSearch = () => {
    setSearchName('');
    apiEmployeeList({});
  };

  return (
    <div className={styles.cooking_register_modal_wrap}>
      <div className={styles.search_input_wrap}>
        <form onSubmit={_searchSubmit}>
          <SearchInput
            type="text"
            placeholder="직원명 검색"
            onChange={(value: string) => setSearchName(value)}
            value={searchName}
          />
        </form>
        <Button
          btnText="초기화"
          btnClick={resetSearch}
          className={styles.reset_button}
          outline={true}
        />
      </div>
      <div className={styles.table_area}>
        <Table
          theadWidth={[10, 30, 30, 30]}
          theadData={['선택', '직원명', '연락처', '업체명']}
          tbodyData={tbodyData()}
          emptyTable={{ text: '선택 가능한 직원이 없습니다.', style: { fontSize: '13px' } }}
        />
      </div>
      <Paging currentPage={currentPage} totalPage={totalPage} movePage={movePage} />
      <div className={styles.btn_area}>
        <Button btnClick={_cancel} outline inlineStyle={{ backgroundColor: '#ffffff' }}>
          취소
        </Button>
        <Button btnClick={_save}>저장</Button>
      </div>
    </div>
  );
}
