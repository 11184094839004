import { setStoreDefaultData } from 'helpers/callApi2/setStoreDefaultData';
import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';
import AuthStore from '../../stores/AuthStore';
import MenuStore from '../../stores/MenuStore';
export interface IMonthlyCostDetailParams {
  cost_table_id?: string;
}

export interface CarGroup {
  id: number;
  name: string;
}
type SuccessResponse = {
  id: number;
  name: string;
  d15_total_cost: number;
  d15_daily_cost: number;
  d15_security_deposit: number;
  m1_total_cost: number;
  m1_daily_cost: number;
  m1_security_deposit: number;
  car_groups: CarGroup[];
  defaultInfo: callApi2.DefaultInfo;
};
type StoreType = {
  menuStore: MenuStore;
  authStore: AuthStore;
};

export type IMonthlyCostDetailResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function getMonthlyCostDetail(params: IMonthlyCostDetailParams, store: StoreType) {
  const response = await callApi2.get<IMonthlyCostDetailResponse>(
    `/v2/group-cost-tables/monthly/${params.cost_table_id}`,
    omit(params, ['cost_table_id'])
  );

  if (!callApi2.isErrorResponse(response)) {
    setStoreDefaultData(response.data.defaultInfo, store);
  }

  return response;
}
