/* eslint-disable @typescript-eslint/ban-types */
import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import { NextRouter, withRouter } from 'next/router';
import React, { Component } from 'react';

import ConfirmAlert from '../../Alert/ConfirmAlert';
import styles from './FailDispatchCarModal.module.scss';

interface PropsType {
  stateData: any;
  onClickConfirm: Function;
  modalStore: any;
  router?: NextRouter;
}

@(withRouter as any)
@inject('modalStore')
@observer
class FailDispatchCarModal extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);
  }

  contractTypeParser = (type: string) => {
    switch (type) {
      case 'normal':
        return '일반';
      case 'insurance':
        return '보험';
      default:
    }
  };

  getContent = () => {
    const { stateData } = this.props;
    const startAt = stateData.pickup_at || stateData.start_at;
    const renderDuplicatedItems = () => {
      return (
        <div style={{ marginTop: 10 }}>
          {stateData.error?.duplicate_contracts.map((item) => {
            return (
              <div key={item.claim_id}>
                {this.contractTypeParser(item.type)} 연번
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => {
                    if (item.type === 'normal') {
                      this.props.router.push(`/normalContract/detail?id=${item.claim_id}`);
                    }

                    if (item.type === 'insurance') {
                      this.props.router.push(`/contract/detail?id=${item.claim_id}`);
                    }
                  }}
                >{` #${item.claim_id}`}</span>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <>
        <div className={styles.info_text}>
          <div>{dayjs(startAt).format('MM/DD HH:mm')}</div>
          <div style={{ marginLeft: 18 }}>{`[${
            stateData.car_identity || stateData.response_car?.car_identity
          }]`}</div>
        </div>
        <div className={styles.reconfirm_text}>
          <div>{`현재 배차중인 차량입니다. 반납 처리하거나 예약 차량 변경`}</div>
          <div>후 배차 출발을 진행해주세요.</div>
          {renderDuplicatedItems()}
        </div>
      </>
    );
  };

  render() {
    const confirmBtnStyle = { width: 108 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={this.props.onClickConfirm}
        confirmBtnStyle={confirmBtnStyle}
        hideCancelBtn
      />
    );
  }
}

export default FailDispatchCarModal;
