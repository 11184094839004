import { ForwardedRef, forwardRef } from 'react';

import * as S from './Button.style';
import { ButtonProps } from './Button.type';

const Button = forwardRef(
  (
    { children, variant, isLoading, ...rest }: ButtonProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    if (isLoading) {
      return (
        <S.Button ref={ref} $variant={variant ?? 'primary'} {...rest}>
          <S.SpinnerWrapper>
            <S.SpinnerImage width={15} height={15} src="/images/spinner.png" />
          </S.SpinnerWrapper>
        </S.Button>
      );
    }

    return (
      <S.Button ref={ref} $variant={variant ?? 'primary'} {...rest}>
        {children}
      </S.Button>
    );
  }
);

export default Button;
