import errorHandler from '../helpers/errorHandler';

/**
 * @brief API를 요청하는데 필요한 반복적인 정의를 가진 기본적인 재사용 가능한 함수. (Client Side)
 * @author wontae Kim
 *
 * @param {Function} callApiFunc
 * @param {Object} params
 * options :
 * @param {Object} options.loadingStore
 * @param {Function} options.successCb
 * @param {Function} options.errorCb
 */
export const processCallApi = async (callApiFunc = () => void 0, params = {}, options = {}) => {
  const { loadingStore, successCb, errorCb } = options;
  const loadingState = (isOn) => {
    if (loadingStore) {
      if (isOn) {
        loadingStore.on();
      } else {
        loadingStore.off();
      }
    }
  };

  try {
    loadingState(true);
    const response = await callApiFunc(params);
    const responseData = response?.data;

    if (response.status === 200) {
      if (typeof successCb === 'function') {
        successCb(responseData);
      }
    } else {
      return errorHandler(responseData, response.status);
    }
  } catch (error) {
    if (typeof errorCb === 'function') {
      errorCb(error);
    }

    console.log(error);
  } finally {
    loadingState(false);
  }
};

/**
 * @brief
 * API를 요청하는데 필요한 반복적인 정의를 가진 기본적인 재사용 가능한 함수. (목적: getInitialProps 에서 데이터 전달 용도로 사용)
 * store 작업은 서버사이드 렌더링 단계에서 필요하지 않으므로 생략한다. ( server / client 렌더링 값이 다르면 warning 이 발생함. mount 이후에 나머지 작업 처리 )
 *
 * @author wontae Kim
 *
 * @param {Function} callApiFunc
 * @param {Object} params
 */
export const simpleProcessCallApi = async (callApiFunc = () => void 0, params = {}) => {
  const response = await callApiFunc(params);
  const responseData = response?.data;

  if (response?.status === 200) {
    return responseData;
  }

  if (params.disableErrorHandler) {
    return responseData;
  }

  if (JSON.parse(process.env.NEXT_IS_SERVER)) {
    return { errorResponse: responseData };
  }

  return null;
};

/**
 * @brief 기본 callApi 사용 함수
 *
 * @param {Function} callApiFunc
 * @param {Object} params
 * options :
 * @param {Object} options included 3 options
 * @param {Object} options.loadingStore
 * @param {Function} options.successCb
 * @param {Function} options.errorCb
 * @returns {Promise | null}
 */
const processApi = async (callApiFunc = () => void 0, params = {}, options = {}) => {
  if (Object.keys(options).length > 0) {
    return processCallApi(callApiFunc, params, options);
  }

  return simpleProcessCallApi(callApiFunc, params);
};

export default processApi;
