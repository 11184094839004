import React from 'react';

import { CallCardSkeleton } from './CallCardSkeleton';
import { PageSkeleton } from './PageSkeleton';
import styles from './Skeleton.module.scss';

const SkeletonList = {
  call_card: <CallCardSkeleton />,
  page: <PageSkeleton />,
} as const;

export const Skeleton = ({ type }: { type: keyof typeof SkeletonList }) => {
  return <div className={`${styles.skeleton} ${styles[type]}`}>{SkeletonList[type]}</div>;
};
