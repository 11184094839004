import { parseServerExtensionState } from 'components/MonthlyExtension/MonthlyExtension.util';
import dayjs from 'dayjs';
import ExtensionRequestRowValue from 'domainComponents/partner/requestList/ExtensionRequestRowValue';
import React from 'react';

import { EXTRA_RESPONSE_MAX_BYTE } from '../constants/extraResponse';
import { getByte, getByteLength } from '../helpers/byteUtils';
import costComma from '../helpers/costComma';
import { isBenzCall } from './benz';
import * as parser from './DataParser';
import { returnChangedExpectedReturnTime } from './NormalContractDetailUtils';
import PartnerNormalRequestUtils from './PartnerNormalRequestUtils.tsx';

export const requestTypes = {
  ACCIDENT: 'rent_request_accident',
  INSURANCE: 'insurance_rent_request',
  MERITZ: 'meritz_rent_request',
  AUTO_MOBILE: 'auto_mobile_request',
};

const causeConst = {
  REQUEST_ID: 'request_id',
  AGREE: 'agree_service',
  MISTAKE: 'agree_mistake',
  CAR1: 'car1_detail',
  SELF_INSU: 'self_insurance',
  CUSTOM_INSU: 'customSelfInsurance',
  DELIVER: 'delivery_time',
  EXTRA: 'extra_response',
};

export const deliveryTime = [
  { text: '10분', value: '10' },
  { text: '20분', value: '20' },
  { text: '30분', value: '30' },
  { text: '45분', value: '45' },
  { text: '1시간', value: '1' },
  { text: '2시간', value: '2' },
];

export const selfInsurance = [
  { text: '30만', value: 300000 },
  { text: '50만', value: 500000 },
];

export const isCardType = (type) => {
  switch (type) {
    case 'my_location':
    case 'responsing':
    case 'responded':
      return true;
    case 'selected':
    case 'car_delivered':
    case 'finished':
      return false;
    default:
      return false;
  }
};

export const getRequestTypeText = (item) => {
  if (item.orderer) {
    return getOrdererPlatformView(item).label;
  }

  if (item.repair_shop_request) {
    return '협력공업사건';
  }

  switch (item.requestType) {
    case 'rent_request_accident':
      return '일반건';
    case 'insurance_rent_request':
      if (item.field_service_request) {
        return '현장배차건';
      }

      return '보험사건';

    case 'meritz_rent_request':
      return '메리츠건';
    case 'auto_mobile_request':
      return '종사자건';
  }
};

export const getClaimPercentage = (requestType, data) => {
  try {
    switch (requestType) {
      case requestTypes.ACCIDENT:
        if (data.cafeType !== null && data.cafeType.toLowerCase() === 'kbinsu') {
          // return `KB 손해보험을 통해 접수된 일반사고 고객입니다.\r\n해당 건의 청구율은 50%입니다.`
          return `kb손보 전산과 연결된 대차요청건 입니다.\r\n일반사고 고객이지만 50% 청구 가능하신 파트너사 분들만 제안 바랍니다.`;
        }

        return `보험사 지급기준의 65%`;

      case requestTypes.INSURANCE:
        if (data.is_percentage) {
          return `보험사 지급기준의 ${data.value}%`;
        }

        return `1일당 청구금액 ${costComma(data.value)}원`;

      case requestTypes.MERITZ:
        return `보험사 지급기준의 ${data.wanted_price}%`;
      case requestTypes.AUTO_MOBILE:
        if (isBenzCall({ type: 'requestUserWorkerType', value: data.requestUserWorkerType })) {
          return '100%';
        }

        if (data.guaranteed) {
          return '-';
        }

        return `보험사 지급기준의 65%`;
      default:
        return '-';
    }
  } catch (error) {
    console.log(error);

    return '-';
  }
};

export const hasResponse = (response) => {
  try {
    if (response.length > 0) {
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const requestWarningMessage = (data, AuthStore) => {
  try {
    const { canDatas, responses, requestStateInfo, requestBasicInfoData, penaltyInfo } = data;

    switch (requestStateInfo.value) {
      //요청중
      case 'waiting_response':
        if (penaltyInfo.isPenalty) {
          //페널티 대상
          return { state: 'penalty', hide: false };
        }

        //페널티 대상이 아닐경우
        if (canDatas.canResponse) {
          //response 객체가 없다 -> 내가 제안 할 수있다.
          return { state: 'write_response', hide: false };
        }

        if (!hasResponse(responses) && ['normaler'].includes(AuthStore.permissionUserType)) {
          return {
            state: 'normal_user_warning',
            hide: true,
            title: '일반 직원 등급은 제안을 할 수 없습니다.',
          };
        }

        if (
          !hasResponse(responses) &&
          requestBasicInfoData &&
          requestBasicInfoData.responses_count > 9
        ) {
          return {
            state: 'over_count',
            hide: true,
            title: '이미 10개의 제안이 등록되어 더이상 제안할 수 없습니다.',
          };
        }

        return { state: 'response_detail', hide: false };

      case 'waiting_car':
      //예약확정
      case 'using_car':
      //배차중
      case 'all_done':
        //반납완료
        if (!canDatas.canSeeResponse) {
          return {
            state: 'already_complete_warning',
            hide: true,
            title: '해당요청건은 이미 업체가 선정되었습니다.',
          };
        }

        return { state: 'response_detail', hide: false };
      case 'cancelled':
        return {
          state: 'cancelled_warining',
          hide: true,
          title: '해당요청건은 고객의 요청으로 취소되었습니다.',
        };
      default:
        return { state: '', message: '' };
    }
  } catch (e) {
    return { state: '', message: '' };
  }
};

export const sendResponseValidator = (data) => {
  let status = true;
  let cause = null;

  if (!data.request_id) {
    status = false;
    cause = causeConst.REQUEST_ID;
  } else if (
    !data.car1_detail ||
    data.car1_detail == '' ||
    Number(getByteLength(data.car1_detail)) > 100
  ) {
    status = false;
    cause = causeConst.CAR1;
  } else if (!data.agree_mistake) {
    status = false;
    cause = causeConst.MISTAKE;
  } else if (!data.agree_service) {
    status = false;
    cause = causeConst.AGREE;
  } else if (!data.self_insurance) {
    status = false;
    cause = causeConst.SELF_INSU;
  } else if (data.self_insurance === -1 && !data.customSelfInsurance) {
    status = false;
    cause = causeConst.CUSTOM_INSU;
  } /* else if (!data.delivery_time) {
    status = false;
    cause = causeConst.DELIVER
  }*/ else if (Number(getByte(data.extra) > EXTRA_RESPONSE_MAX_BYTE)) {
    status = false;
    cause = causeConst.EXTRA;
  }

  return { status, cause };
};

export const sendResponseFailAlert = (cause) => {
  switch (cause) {
    case causeConst.REQUEST_ID:
      alert('요청번호가 없습니다. 페이지를 새로고침 해주세요.');
      break;
    case causeConst.AGREE:
      alert('서비스 제공에 동의하셔야 합니다.');
      break;
    case causeConst.MISTAKE:
      alert('과실 상계 변동에 동의하셔야 합니다.');
      break;
    case causeConst.CAR1:
      alert('제안 차량1은 필수입니다. 0 Byte 이상 100 Byte 이하로 입력하세요.');
      break;
    case causeConst.SELF_INSU:
      alert('자차보험 금액을 선택하세요.');
      break;
    case causeConst.CUSTOM_INSU:
      alert('자차보험 기타 선택시 금액을 직접 입력해주세요.');
      break;
    /* case causeConst.DELIVER:
      alert("배차 예상 소요시간을 선택해주세요.");
      break; */
    case causeConst.EXTRA:
      alert(`기타 요청사항은 ${EXTRA_RESPONSE_MAX_BYTE}Byte 이하로 입력하세요.`);
      break;
    default:
  }
};

export const getSendResponseDataParse = (data) => {
  let indemnfication_fee =
    data.self_insurance === -1 ? parseInt(data.customSelfInsurance) : data.self_insurance;
  let returnObj = {
    request_id: data.request_id,
    car1_detail: data.car1_detail,
    car_insurance_type: '서비스 제공',
    indemnfication_fee,
  };

  if (data.extra) {
    returnObj.extra = data.extra;
  }

  if (data.car2_detail) {
    returnObj.car2_detail = data.car2_detail;
  }

  if (data.selectedCoupon) {
    returnObj.superpass_id = data.selectedCoupon.id;
  }

  // if (!!data.delivery_time) {
  //   returnObj["delivery_time"] = data.delivery_time
  // }
  return returnObj;
};

export const getRequestType = (data) => {
  try {
    const requestType = data.request_type ? data.request_type : data.requestType;
    const requestApp = data.request_app ? data.request_app : data.requestApp;

    if (data.repair_shop_request) {
      return 'repair_shop_request';
    }

    if (requestApp === 'renchul') {
      return 'renchul_request';
    } //렌출

    if (requestType === 'auto_mobile_request' && data.isDealership) {
      return 'partnered_auto_mobile_request';
    }

    if (
      requestApp === 'rencar' &&
      requestType === 'auto_mobile_request' &&
      !data.isDealership &&
      data.guaranteed
    ) {
      return 'guaranteed_auto_mobile_request';
    }

    if (requestType === 'insurance_rent_request' && (data.selfClaim || data.self_claim)) {
      //보함사 셀프
      return 'self_insurance_request';
    }

    if (requestType === 'meritz_rent_request') {
      return 'insurance_rent_request';
    } //메리츠

    if (
      requestType === 'insurance_rent_request' &&
      (data.fieldServiceRequest || data.field_service_request)
    ) {
      //현장출동
      return 'field_service_request';
    }

    if (requestType === 'insurance_rent_request' && data.userType === 'init_insurance_user') {
      //초기보상
      return 'init_insurance_request';
    }

    if (requestType === 'rent_request_accident' && (data.cafeType || data.cafe_type)) {
      //카페 셀프
      return 'cafe_rent_request_accident';
    }

    return requestType;
  } catch (error) {
    return null;
  }
};

export const getRequestTypeKorean = (data) => {
  const type = getRequestType(data);

  switch (type) {
    case 'insurance_rent_request':
    case 'init_insurance_request':
    case 'self_insurance_request':
      return '보험사 요청';

    case 'auto_mobile_request':
    case 'partnered_auto_mobile_request':
      return '자동차종사자 요청';

    case 'repair_shop_request':
      return '보험사 협력공업사 요청';

    case 'renchul_request':
    case 'field_service_request':
      if (data.dispatch_info == 'immediate') {
        return '현장출동자 요청(즉시배차)';
      }

      return '현장출동자 요청';

    case 'cafe_rent_request_accident':
    case 'rent_request_accident':
      return '일반사고고객 요청';

    default:
      return type;
  }
};

export const getPriceText = (item) => {
  const {
    wanted_price,
    price_by_percentage,
    cafe_type,
    request_type,
    requestType,
    request_app,
    requestApp,
    isDealership,
    guaranteed,
    request_user_worker_type,
  } = item;
  const reqType = request_type ? request_type : requestType;
  const reqApp = request_app ? request_app : requestApp;

  if (
    reqType === 'auto_mobile_request' && // 종사자 요청(admin에서 자동차 종사자, 정식 계약업체로 가입한 유저의 요청)
    isBenzCall({ type: 'requestUserWorkerType', value: request_user_worker_type })
  ) {
    return <span>100%</span>;
  }

  if (wanted_price) {
    if (price_by_percentage === 0) {
      return <span>{`1일당 ${costComma(wanted_price)}원`}</span>;
    }

    return wanted_price ? <span>{`${wanted_price}%`}</span> : <span>65%</span>;
  }

  if (reqType === 'rent_request_accident') {
    if (Boolean(cafe_type) && cafe_type.toLowerCase() === 'kbinsu') {
      return <span>50%</span>;
    }

    return <span>65%</span>;
  }

  if (reqType === 'meritz_rent_request') {
    return <span>50%</span>;
  }

  if (reqApp === 'rencar' && reqType === 'auto_mobile_request' && !isDealership && guaranteed) {
    return <span>-</span>;
  }

  return <span>65%</span>;
};

export const getRespondedPriceText = (item, isFlex = false) => {
  const { price, requestType, cafeType, request_user_worker_type } = item;

  if (
    requestType === 'auto_mobile_request' && // 종사자 요청(admin에서 자동차 종사자, 정식 계약업체로 가입한 유저의 요청)
    isBenzCall({ type: 'requestUserWorkerType', value: request_user_worker_type })
  ) {
    return <span>100%</span>;
  }

  if (price) {
    if (price.is_percentage) {
      return `${price.value}%`;
    }

    return (
      <div style={isFlex && { display: 'flex' }}>
        <div style={{ textAlign: 'center' }}>{MESSAGE.DAILY_PER}</div>
        <div style={{ textAlign: 'center', paddingLeft: isFlex ? '4px' : '0px' }}>
          {price.value}원
        </div>
      </div>
    );
  }

  if (requestType == 'rent_request_accident') {
    if (Boolean(cafeType) && cafeType.toLowerCase() == 'kbinsu') {
      return `50%`;
    }

    return `65%`;
  }

  if (requestType == 'meritz_rent_request') {
    return `50%`;
  }

  return `65%`;
};

export const getFreeLabel = (item, className) => {
  const { total_fee, free_request, request_type } = item;

  if (request_type === 'auto_mobile_request' && total_fee === 0 && free_request) {
    return <div className={className}>{MESSAGE.FEE_FREE}</div>;
  }

  if (request_type !== 'auto_mobile_request' && free_request) {
    return <div className={className}>{MESSAGE.FEE_FREE}</div>;
  }
};

export const getInsuranceTypeTextAndColor = (data) => {
  const type = getRequestType(data);
  let text;
  let color; // 추후 색이 들어가면 사용

  switch (type) {
    case 'insurance_rent_request':
    case 'init_insurance_request':
    case 'self_insurance_request':
      text = '보험대차-보험사';
      color = '#c4c4c4';
      break;
    case 'auto_mobile_request':
    case 'partnered_auto_mobile_request':
      text = '보험대차-자동차종사자';
      color = '#c4c4c4';
      break;
    case 'repair_shop_request':
      text = '보험대차-협력공업사';
      color = '#c4c4c4';
      break;
    case 'renchul_request':
    case 'field_service_request':
      text = '보험대차-현장출동';
      color = '#c4c4c4';
      break;
    case 'cafe_rent_request_accident':
    case 'rent_request_accident':
      text = '보험대차-일반고객';
      color = '#c4c4c4';
      break;
    case 'guaranteed_auto_mobile_request':
      text = '보증대차-자동차종사자';
      color = '#f9dfb2';
      break;
    default:
      text = type;
      color = '#c4c4c4';
  }

  return { text, color };
};

export const getOrdererPlatformView = (item) => {
  const { orderer } = item;

  return {
    label: `${parser.requestOrdererParser(orderer)}`,
    style: { backgroundColor: parser.parseOrdererToColor(orderer) },
  };
};

export const getInsuranceRentTimeCautionLabel = (item) => {
  const { dispatch_info } = item;

  if (dispatch_info === 'immediate') {
    return {
      text: `즉시배차`,
      color: `#f86438`,
    };
  }

  return '';
};

export const getNormalRentTimeCautionLabel = (item) => {
  const { pickup_at } = item;

  if (isRightPickupAt(pickup_at)) {
    return {
      text: `당일`,
      color: `#ffdc4a`,
    };
  }

  return '';

  function isRightPickupAt(_pickup_at) {
    const today = dayjs().format('YYYYMMDD');
    const pickupAt = dayjs(_pickup_at).format('YYYYMMDD');
    const diff = pickupAt - today;

    if (diff === 0) {
      return true;
    }
  }
};

export const getAgeLabel = (item) => {
  const { driver_age } = item;

  if (driver_age >= 26) {
    return '만 26세 이상';
  }

  if (driver_age >= 21) {
    return '만 21세 이상';
  }

  return '';
};

export const getRequestTypeNewText = (item) => {
  if (item.request_type === 'external') {
    return '일반';
  }

  if (item.guaranteed) {
    return '보증';
  }

  switch (item.requestType) {
    case 'external':
      return '일반';
    default:
      return '보험';
  }
};

export const getPartnerListTbodyStringNew = (props) => {
  const { item, thead, query, classNames, option } = props;
  const {
    REQUEST_ID,
    RENT_TYPE,
    REQUEST_TYPE,
    CUSTOMER_NAME,
    REQUEST_DATE,
    ALLOCATE_CAR_DATE,
    EXPECT_RETURN_DATE,
    TOTAL_COST,
    RETURN_REQUEST,
    CALL_COMPLETE,
    PHONE,
    RETURN_DATE,
    CUSTOMER_CAR,
    RENT_CAR,
    SELF_CONTRACT,
    LOCATION,
    ESPECIAL_POINT,
    CLAIM_TOTAL_COST,
    RESPONSE_TYPE,
    MEMO,
    BOOKING_PROCESS_CONFIRM,
    COMPLETE_RESERVATION,
    CANCEL_DATE,
    CANCEL_REASON,
  } = MESSAGE;

  // 분기 조건 추가시 해당 thead에 따라 조건 함수 작성
  switch (thead.label ?? thead) {
    case REQUEST_ID:
      return `${item.id}`; // 요청 번호
    case RENT_TYPE:
      return getRequestTypeNewText(item); // 요청 유형 : 일반 렌트 / 보험 대차
    case REQUEST_TYPE:
      return getRequestTypeText(item)?.replace('건', ''); // 주문처, 보험대차 건
    case '구분':
      // 화이트라벨 월렌트
      return rentalTypeKor(item?.period_type); // period_type 월렌트,일렌트 인지
    case REQUEST_DATE:
      return parser.dateParser(item.createdAt); // 보험대차 / 일반렌트 요청일
    case ALLOCATE_CAR_DATE:
      return forAllocateCarDate(item, query); // 배차일
    case EXPECT_RETURN_DATE:
      return forExpectReturnDate(item, classNames); //  반납예정일 (일반렌트)
    case RETURN_DATE:
      return parser.dateParser(item.doneAt); // 반납일
    case CUSTOMER_CAR:
      return parser.defaultParser(item.customerCar); // 고객 차량
    case TOTAL_COST:
      return forCost(costComma(item.paid_cost)); // 총요금
    case CLAIM_TOTAL_COST:
      return forCost(costComma(item.claim_total_cost)); // 청구금
    case RENT_CAR:
      return forRentCar(item); // 렌트 차량
    case LOCATION:
      return forLocation(item); // 지역주소
    case ESPECIAL_POINT:
      return forEspecialPoint(item); // 특이사항
    case RETURN_REQUEST:
      return forReturnRequest(item); // 반납요청 판단
    case CALL_COMPLETE:
      return forCallComplete(item); //통화 완료
    case RESPONSE_TYPE:
      return forAutoResponse(item);
    case SELF_CONTRACT:
      return forSelfContract(item);
    case '고객명(생년월일)':
      return forCustomerName(item);
    case '고객명':
      if (item.license_verification) {
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 4,
            }}
          >
            <div>{parser.defaultParser(item.customer_name)}</div>
            <div
              style={{
                fontSize: 10,
                width: 30,
                height: 16,
                borderRadius: 8,
                border: '1px solid #5d8fff',
                color: '#5d8fff',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              검증
            </div>
          </div>
        );
      }

      return parser.defaultParser(item.customer_name);
    case PHONE:
      return forCustomerContact(item);
    case BOOKING_PROCESS_CONFIRM:
      return forBookingConfirm(item);
    case MEMO:
      return forMemo(item.latest_memo);
    case CANCEL_DATE:
      return forCancelDate(item.removed_at);
    case CANCEL_REASON:
      return forCancelReason(item);
    case `${COMPLETE_RESERVATION}일`:
      return forReservationDate(item.select_at);
    case '면허':
      return forLicenseName(item.customer_driver_type);
    case '연장 요청':
      return (
        <ExtensionRequestRowValue
          requestId={item.extend_monthly_request?.origin_request_id}
          contractId={item.connected_normal_contract_id}
          extensionRequestState={
            item.extend_monthly_request?.status &&
            parseServerExtensionState(item.extend_monthly_request.status)
          }
        />
      );
    default:
      return '-';
  }

  function forReservationDate(date) {
    return date ? parser.dateParser(date) : '-';
  }

  function forCancelDate(date) {
    return date ? parser.dateParser(date) : '-';
  }

  function forCancelReason(item) {
    //고객자격미달, 고객노쇼, 고객예약취소
    const { noshow_report_at, is_unqualified } = item;

    if (is_unqualified) {
      return '고객자격미달';
    }

    if (noshow_report_at) {
      return '고객노쇼';
    }

    if (!noshow_report_at && !is_unqualified) {
      return '고객예약취소';
    }

    return '-';
  }

  function forMemo(memo) {
    if (memo && memo.length > 14) {
      return `${memo.substring(0, 14)}...`;
    }

    if (memo) {
      return memo;
    }

    return '-';
  }

  function forBookingConfirm(item) {
    if (item.is_confirmed === null) {
      return '-';
    }

    if (item.is_confirmed) {
      return '진행확정';
    }

    if (!item.is_confirmed) {
      // 제주 건일 경우 확정 버튼 클릭시 비대면 계약서 전송 x -> 비대면 계약서 관련 문구 미노출 조건 필요
      const isJejuRequest = item.location?.class1?.includes('제주');

      const modalData = {
        requestId: item.id,
        responseId: item.selected_response_id,
        registerContact: item.customer_contact,
        driverContact: item.customer_contact,
        carNum: item.response_car?.car_identity,
        carName: item.response_car?.car_model.model,
        carYear: item.response_car?.car_age,
        totalCost: item.paid_cost,
        startDate: item.pickup_at,
        endDate: item.dropoff_at,
        isJejuRequest,
        periodType: item.period_type,
        requestType: item.requestType,
        isExtended: Boolean(item.completed_extend_monthly_request),
        orderer: item.orderer,
      };

      return (
        <button
          style={{
            background: 'white',
            color: '#f84b4b',
            border: '1px solid #f84b4b',
            padding: '5px 10px',
            lineHeight: 1,
          }}
          onClick={(e) => {
            e.stopPropagation();
            option.confirmed(modalData);
          }}
        >
          확정하기
        </button>
      );
    }
  }

  function forAutoResponse(item) {
    if (item.requestType !== 'external') {
      return '-';
    }

    return item?.auto_response ? '자동' : '직접'; //제안 구분
  }

  // 리턴 조건 함수
  function forAllocateCarDate(_item, _query) {
    if (_item.requestType === 'external') {
      if (query.tab === 'car_delivered' || query.tab === 'finished') {
        return parser.dateParser(_item.deliveredAt);
      }

      return parser.dateParser(_item.pickup_at);
    }

    return parser.dateParser(_item.deliveredAt);
  }

  function forCost(_cost) {
    return _cost === '0' ? '-' : _cost;
  }

  function forRentCar(_item) {
    if (query.tab === 'selected' && _item.requestType !== 'external') {
      return `${parser.defaultParser(_item.selected_car)}`;
    }

    if (_item.requestType === 'external') {
      return `${parser.defaultParser(_item?.response_car?.car_name || '-')}(${
        _item?.response_car?.car_identity || '-'
      })`;
    }

    return `${parser.defaultParser(_item.response_car?.car_name)}`;
    // if (_item.requestType === 'external') { return `${parser.defaultParser(_item.selected_car)}(${_item.car_identity || "-"})` }
    // return `${parser.defaultParser(_item.selected_car)}`
  }

  function forLocation(_item) {
    return parser.locationParser(_item.location);
  }

  function forEspecialPoint(_item) {
    if (_item.requestType === 'external') {
      return '-';
    }

    if (_item.requestType == 'auto_mobile_request') {
      return _item.total_fee === 0 ? '무료' : '-';
    }

    return _item.free_request ? '무료' : '-';
  }

  function forCallComplete(_item) {
    if (_item.car_return_request_timepoint === null) {
      return '-';
    }

    if (_item.car_return_request_consulted) {
      return 'O';
    }

    if (!_item.car_return_request_consulted) {
      return <span style={{ color: '#ff0000' }}>X</span>;
    }

    return '-';
  }

  function forExpectReturnDate(_item, classNames) {
    if (_item.updated_dropoff_at) {
      return (
        <span className={classNames.expectReturnDateBadgeUseSpan}>
          {parser.dateParser(_item.updated_dropoff_at)}
          <span className={classNames.expectReturnDateBadge}>
            {returnChangedExpectedReturnTime(_item.dropoff_at, _item.updated_dropoff_at).type}
          </span>
        </span>
      );
    }

    return parser.dateParser(_item.dropoff_at);
  }

  function forReturnRequest(_item) {
    if (_item.car_return_request_timepoint) {
      return 'O';
    }

    return '-';
  }

  function forSelfContract(_item) {
    const state = _item.self_contract;

    if (_item.requestType !== 'external') {
      return '-';
    }

    if (!state) {
      return '미발송';
    }

    if (state.id && !state.done) {
      return '고객 미작성';
    }

    if (state.done && !state.request_signature_at) {
      return '고객 작성 완료';
    }

    if (state.request_signature_at) {
      return '서명 대기';
    }

    return '미발송';
  }

  function forCustomerName(item) {
    let customerName = item.customer_name;

    if (item?.driver_date_of_birth && dayjs(item?.driver_date_of_birth).isValid()) {
      customerName += `(${item?.driver_date_of_birth})`;
    }

    return customerName || '-';
  }

  function forLicenseName(customer_driver_type) {
    return customer_driver_type || '-';
  }

  function forCustomerContact(_item) {
    return _item.customer_contact || '-';
  }
};

export const forReturnRequestButton = (_router) => {
  const params = { ..._router.query };

  params.page = 1;
  params.returnRequest = !parser.stringBooleanParser(_router.query.returnRequest);
  _router.push(
    {
      query: params,
    },
    undefined,
    { shallow: true }
  );
};

export const forOrderByButton = (_router) => {
  const params = { ..._router.query };

  params.page = 1;

  switch (params.orderBy) {
    case 'id':
      params.orderBy = 'pickup_at';
      break;
    case 'pickup_at':
      params.orderBy = 'id';
      break;
    default:
      params.orderBy = 'pickup_at';
      break;
  }

  _router.push(
    {
      query: params,
    },
    undefined,
    { shallow: true }
  );
};

export const forOrderBySelect = (_router, value) => {
  const params = { ..._router.query };

  params.page = 1;
  params.orderBy = value;
  _router.push(
    {
      query: params,
    },
    undefined,
    { shallow: true }
  );
};

export const isListTab = (_query, pickTab) => {
  const { tab } = _query;

  if (pickTab) {
    if (_query.tab === pickTab) {
      return true;
    }

    return false;
  }

  return tab === 'car_delivered' || tab === 'finished' || tab === 'selected';
};

export const forPartnerRequestListParams = (_ctx) => {
  const { query } = _ctx;
  const params = {
    tab: query.tab,
    page: query.page,
  };

  if (isListTab(query) && query.request_type) {
    params.request_type = query.request_type;
  }

  if (isListTab(query) && query.request_state) {
    params.request_state = query.request_state;
  }

  if (isListTab(query, 'car_delivered') && query.returnRequest) {
    params.is_return_requested = query.returnRequest;
  }

  if (isListTab(query, 'finished') && query.claim_state) {
    params.claim_state = query.claim_state;
  }

  if (isListTab(query) && query.orderBy) {
    params.order_by = query.orderBy;
  }

  if (isListTab(query, 'selected') && query.request_type == 'external') {
    params.is_auto_response = query.is_auto_response;
  }

  if (isListTab(query, 'selected') && query.self_contract) {
    params.self_contract = query.self_contract;
  }

  if (isListTab(query) && query.search) {
    params.search = query.search;
  }

  if (isListTab(query) && query.startDate && query.endDate) {
    params.start = query.startDate;
    params.end = query.endDate;
    params.date_option = query.periodOption;
  }

  return params;
};

export const forCarInfo = (item) => {
  const { first_selected_company_car_model, car_price_groups } = item;

  if (first_selected_company_car_model) {
    return parser.defaultParser(first_selected_company_car_model);
  }

  return parser.defaultParser(car_price_groups);
};

export const forRentalFee = (item) => {
  const { min_price, max_price, paid_cost, security_deposit } = item;
  // 화이트라벨 월렌트
  const depositAmountLabel = security_deposit ? ` (보증금 ${costComma(security_deposit)}원)` : '';

  if (paid_cost) {
    return `${costComma(paid_cost)}원${depositAmountLabel}`;
  }

  if (Boolean(min_price) && Boolean(max_price)) {
    if (min_price === max_price) {
      return `${costComma(min_price)}${depositAmountLabel}`;
    }

    return `${costComma(min_price)} ~ ${costComma(max_price)}원${depositAmountLabel}`;
  }

  return `- 원`;
};

export const forRentalPeriod = (item) => {
  const { pickup_at, dropoff_at } = item;
  const { basicInfoDateParser } = PartnerNormalRequestUtils;

  return `${basicInfoDateParser(pickup_at)} - ${basicInfoDateParser(dropoff_at)}`;
};

export const workerTypeSelector = (item) => {
  // dealer, car_center, rent_car, insurance_planner
  switch (item.worker_type) {
    case 'dealer':
      return '자동차 딜러';
    case 'car_center':
      return '공업사';
    case 'rent_car':
      return '렌트 업체';
    case 'insurance_planner':
      return '보험 설계사';
    default:
      return '';
  }
};

export const rentalTypeKor = (type) => {
  switch (type) {
    case 'daily':
      return '일 렌트';
    case 'monthly':
      return '월 렌트';
    case 'long_term':
      return '장기렌트';
    default:
      return '알수없는 유형';
  }
};
