import dayjs from 'dayjs';
import { toJS } from 'mobx';
import { inject } from 'mobx-react';
import React, { Component } from 'react';

import apiCarReturnRequest from '../../../../../api/carReturnRequest';
import Input from '../../../../../atoms/Input/index.tsx';
import { getByteLength } from '../../../../../helpers/byteUtils';
import styles from './ReturnRequestDetailModal.module.scss';

@inject('authStore')
@inject('modalStore')
class ReturnRequestDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: null,
      doCallCustomer: null,
      memo: null,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    const { contracts } = this.props;

    if (Array.isArray(toJS(contracts))) {
      const lastItem = contracts[contracts.length - 1];

      this.setState(
        {
          contract: lastItem,
          doCallCustomer: lastItem.car_return_request_consulted,
        },
        async () => {
          const { id } = lastItem;
          const { type } = this.props;
          const params = {
            menuStore: this.props.menuStore,
            authStore: this.props.authStore,
            id,
            kind: type,
          };

          catchAsync(async () => {
            const response = await apiCarReturnRequest.getReturnRequestDetail(params);
            const responseData = response.data;

            if (response.status === 200) {
              this.setState({
                returnData: {
                  contract_id: responseData.contract_id,
                  claim_id: responseData.id,
                  company_contact: responseData.company_contact,
                  delivered_at: responseData.delivered_at,
                  kind: responseData.kind,
                  rent_car_name: responseData.rent_car_name,
                  rent_car_number: responseData.rent_car_number,
                  is_using_car: responseData.is_using_car,
                  car_request_return: responseData.car_request_return,
                  car_return_request_location: responseData.car_return_request_location,
                  car_return_request_timepoint: responseData.car_return_request_timepoint,
                },
              });
            }
          });
        }
      );
    }
  };

  customerConsult = () =>
    catchAsync(async () => {
      const { doCallCustomer, returnData } = this.state;
      const params = {
        contract_id: returnData.contract_id,
        kind: returnData.kind,
      };

      await (doCallCustomer
        ? apiCarReturnRequest.customerConsult
        : apiCarReturnRequest.cancelCustomerConsult)(params);
    });

  sendMemo = () =>
    catchAsync(async () => {
      const { memo, returnData } = this.state;
      const params = {
        contract_id: returnData.contract_id,
        kind: returnData.kind,
        memo,
      };
      const response = await apiCarReturnRequest.sendMemo(params);

      if (response.status === 200) {
        return true;
      }

      return false;
    });

  setCall = () => this.setState((prevState) => ({ doCallCustomer: !prevState.doCallCustomer }));

  save = async () => {
    const { doCallCustomer } = this.state;
    const { contracts } = this.props;
    const lastItem = contracts[contracts.length - 1];

    if (confirm('변경사항을 저장하시겠습니까?')) {
      if (!doCallCustomer === lastItem.car_return_request_consulted) {
        await this.customerConsult();
      }

      if (this.state.memo) {
        await this.sendMemo();
      }

      await this.props.refresh();
    }

    this.props.modalStore.modalClose();
  };

  cancel = () => {
    const { contracts } = this.props;
    const { memo } = this.state;
    const lastItem = contracts[contracts.length - 1];
    const hasChanged =
      lastItem.car_return_request_consulted !== this.state.doCallCustomer || memo !== null;

    if (hasChanged) {
      if (confirm('저장하지 않고 닫으시겠습니까? 변경사항이 삭제됩니다.')) {
        return this.props.modalStore.modalClose();
      }
    } else {
      this.props.modalStore.modalClose();
    }
  };

  setMemo = (value) => {
    const maxByte = 120;
    let returnValue = value;
    const byte = getByteLength(returnValue);

    if (byte > Number(maxByte)) {
      window.toast({
        type: 'warning',
        text: ALERT_MESSAGE.OVER_MAX_BYTE(maxByte),
      });
      returnValue = this.cutByLen(value, maxByte);
    }

    this.setState({ memo: returnValue });
  };

  cutByLen = (str, len) => {
    let l = 0;

    for (let i = 0; i < str.length; i++) {
      l += str.charCodeAt(i) > 128 ? 2 : 1;

      if (l > len) {
        return str.substring(0, i);
      }
    }

    return str;
  };

  render() {
    const { contract, returnData, doCallCustomer } = this.state;

    if (contract && returnData) {
      return (
        <div className={styles.return_request_modal_wrap} style={{ width: 520 }}>
          <div className={styles.return_request_box_header}>고객 정보</div>
          <div className={styles.return_request_box}>
            <div className={styles.return_request_row}>
              <div>배차일시 :&nbsp;</div>
              <div>{dayjs(returnData.delivered_at).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div className={styles.return_request_row}>
              <div>렌트 차량 번호 :&nbsp;</div>
              <div>{returnData.rent_car_number}</div>
            </div>
            <div className={styles.return_request_row}>
              <div>고객 이름 :&nbsp;</div>
              <div>{contract.customer_name}</div>
            </div>
            <div className={styles.return_request_row}>
              <div>고객 연락처 :&nbsp;</div>
              <div>{contract.customer_contact}</div>
            </div>
          </div>
          <div className={styles.return_request_box_header}>반납 요청 정보</div>
          <div className={styles.return_request_box}>
            <div className={styles.return_request_row}>
              <div>반납 요청 일시 :&nbsp;</div>
              <div>
                {contract.car_return_request_datetime
                  ? dayjs(contract.car_return_request_datetime).format('YYYY.MM.DD HH:mm')
                  : '-'}
              </div>
            </div>
            <div className={styles.return_request_row}>
              <div>반납 시간 :&nbsp;</div>
              <div>{returnData.car_return_request_timepoint}</div>
            </div>
            <div className={styles.return_request_row}>
              <div>반납 장소 :&nbsp;</div>
              <div>{returnData.car_return_request_location}</div>
            </div>
          </div>

          <div onClick={this.setCall} className={styles.return_request_customer_call}>
            <span>고객 통화 완료 시 체크해 주세요.</span>
            <div className={doCallCustomer ? styles.check_img_active : styles.check_img}>
              <img src={'/images/ic_check_terms_10x8.png'} />
            </div>
          </div>

          <div className={styles.return_request_box_header}>
            <span>메모</span>
            <span className={styles.desc}>반납 관련 공유할 내용을 입력해 주세요.</span>
          </div>

          <div className={styles.return_request_memo}>
            <Input
              type="text"
              className={styles.memo_input}
              placeholder="입력한 메모는 '계약서 메모'에 추가됩니다."
              handleChange={this.setMemo}
              propValue={this.state.memo === null ? '' : this.state.memo}
            />
          </div>

          <div className={styles.button_area}>
            <div className={styles.cancel_btn} onClick={this.cancel}>
              닫기
            </div>
            <div className={styles.save_btn} onClick={this.save}>
              {MESSAGE.SAVE}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className={styles.return_request_modal_wrap} style={{ width: 520, minHeight: 660 }}>
        <p>정보 로드중...</p>
      </div>
    );
  }
}

export default ReturnRequestDetailModal;
