import { ComponentPropsType } from 'interface/utils';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import Router from 'next/router';
import React, { Component } from 'react';

import * as S from './style';

interface ModalPropsType extends ComponentPropsType {
  modalClose?: () => void;
}

@inject('modalStore')
@observer
class Modal extends Component<ModalPropsType> {
  constructor(props: ModalPropsType) {
    super(props);
    Router.events.on('beforeHistoryChange', this.handler);
  }

  componentWillUnmount = () => {
    Router.events.off('beforeHistoryChange', this.handler);
  };

  handler = (toPath: string) => {
    const targetPath = toPath.split('?')[0];

    if (targetPath === Router.pathname) {
      // 같은 패쓰이면 아무일도 일어나지 않는다.
    } else {
      this.props.modalStore.modalClose();
    }
  };

  closeModal = (e) => {
    if (` ${e.target.id} `.replace(/[\n\t]/g, ' ').indexOf('modal_wrap') > -1) {
      this.props.modalClose();
    }
  };

  titleStyle = () => {
    if (this.props.modalStore.headerCenter) {
      if (this.props.modalStore.subNotice) {
        return { textAlign: 'center', paddingLeft: 0, borderBottom: 'none' } as const;
      }

      return { textAlign: 'center', paddingLeft: 0 } as const;
    }

    return {};
  };

  render() {
    return (
      <S.ModalWrap id="modal_wrap" modalOn={this.props.modalStore.modalOn}>
        <S.Modal style={toJS(this.props.modalStore.modalStyle)}>
          {Boolean(this.props.modalStore.modalTitle) && (
            <S.ModalHeader style={this.titleStyle()}>
              <S.ModalTitle style={toJS(this.props.modalStore.modalTitleStyle)}>
                {this.props.modalStore.modalTitle}
              </S.ModalTitle>
              <S.ModalSubTitle>{this.props.modalStore.subTitle}</S.ModalSubTitle>
              {!this.props.modalStore.hideCloseBtn && (
                <S.ModalClose>
                  <S.ModalCloseImg
                    src="/images/modalImages/ic-line-close-black.png"
                    onClick={() => {
                      const { resetError } = this.props.modalStore;

                      if (typeof resetError === 'function') {
                        resetError();
                      }

                      this.props.modalStore.modalClose();
                    }}
                  />
                </S.ModalClose>
              )}
            </S.ModalHeader>
          )}
          {Boolean(this.props.modalStore.subNotice) && (
            <S.ModalSubNotice>{this.props.modalStore.subNotice}</S.ModalSubNotice>
          )}
          <S.ModalBody style={toJS(this.props.modalStore.modalBodyStyle)}>
            {this.props.modalStore.modalComponent}
          </S.ModalBody>
        </S.Modal>
      </S.ModalWrap>
    );
  }
}

export default Modal;
