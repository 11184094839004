import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import Button from '../../atoms/Button/index.tsx';
import FindAddress from '../../atoms/FindAddress';
import Input from '../../atoms/Input/index.tsx';
import RencarCheckBox from '../../components/RencarCheckBox/RencarCheckBox';
import styles from './Address.module.scss';
import AddressDetail from './AddressDetail';

@inject('modalStore')
@observer
class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchAddress: null,
      search: false,
      mobileSearch: false,
    };
  }

  init = () => {
    this.setState({
      searchAddress: null,
      search: false,
    });
  };

  save = (detailAddress) => {
    const { getAddress, searchGarageAddress, detailGarageAddress, info } = this.props;
    const { searchAddress } = this.state;

    if (!searchGarageAddress && !detailAddress) {
      return alert('상세 주소를 입력해주세요.');
    }

    if (searchGarageAddress && !detailAddress && info?.detailAddress !== 'select') {
      return alert('상세 주소를 입력해주세요.');
    }

    if (searchGarageAddress && !detailAddress && info?.detailAddress == 'select') {
      if (!confirm('상세 주소없이 저장 하시겠습니까?')) {
        return;
      }
    }

    getAddress(`${searchAddress} ${detailAddress ? detailAddress : ''}`);
    searchGarageAddress &&
      searchGarageAddress(this.state.addressItem, detailAddress ? detailAddress : '');
    this.init();
    this.props.modalStore.modalClose();
  };

  cancel = () => {
    this.setState({ search: false });
    this.props.modalStore.modalClose();
    this.addressSearchModal();
  };

  addressDetailModal = ({ searchAddress, addressItem }) => {
    const modal = (
      <AddressDetail
        searchAddress={searchAddress}
        init={this.init}
        save={(detailAddress) => this.save(detailAddress)}
        cancel={this.cancel}
      />
    );
    const data = {
      modalComponent: modal,
      modalTitle: '주소 검색',
      modalStyle: {
        maxWidth: '500px',
        width: '80%',
        maxHeight: '700px',
        overflow: 'hidden',
      },
      modalBodyStyle: { overflow: 'auto' },
    };

    // 완료
    this.props.modalStore.modalOpen(data); //완료
  };

  addressSearchModal = () => {
    const searchModal = (
      <FindAddress
        height={500}
        getData={(selectAddress, data) => {
          const addressName = data.buildingName
            ? `${selectAddress} (${data.buildingName})`
            : selectAddress;

          this.setState({ searchAddress: addressName, addressItem: data });
          // this.props.modalStore.modalClose();
          this.addressDetailModal({ searchAddress: addressName, addressItem: data });
        }}
      />
    );
    const data = {
      modalComponent: searchModal,
      modalTitle: '주소 검색',
      modalStyle: {
        maxWidth: '500px',
        width: '80%',
        maxHeight: '700px',
        overflow: 'hidden',
        minHeight: '700px',
      },
      modalBodyStyle: { overflow: 'auto' },
    };

    this.props.modalStore.modalOpen(data);
  };

  mobileSearhcAddress = () => {
    return (
      <FindAddress
        mobile
        getData={(selectAddress, data) => {
          const addressName = data.buildingName
            ? `${selectAddress} (${data.buildingName})`
            : selectAddress;

          this.setState({ searchAddress: addressName });
          this.props.modalStore.modalClose();
          this.addressDetailModal();
        }}
      />
    );
  };

  render() {
    const { value, getAddress, mobile, btnClassName, info } = this.props;
    const { mobileSearch } = this.state;

    if (mobile) {
      return (
        <Fragment>
          {mobileSearch && (
            <div className={styles.mobile_address_search}>{this.mobileSearhcAddress()}</div>
          )}
          <Button
            btnText="주소 검색"
            className={`${styles.mobile_address_btn} ${btnClassName || ''}`}
            btnClick={() => this.setState({ mobileSearch: true })}
          />
        </Fragment>
      );
    }

    return (
      <div className={styles.address_wrap}>
        <Input
          placeholder={info?.placeholder ? info.placeholder : '주소를 입력해주세요'}
          propValue={value}
          handleChange={(value) => getAddress(value)}
          inlineStyle={info?.style}
          inputClick={info?.inputType === 'inputClick' ? this.addressSearchModal : null}
          clearButton={info?.clearButton}
          dataset="address"
          disabled={info?.disabled}
        />
        <Button
          btnText="주소 검색"
          className={styles.address_btn}
          btnClick={this.addressSearchModal}
          disabled={info?.disabled}
        />
        {info.checkBox && (
          <RencarCheckBox
            text={info.checkBox.text}
            value={
              info.checkBox.valueReverseForBackEndData ? !info.checkBox.value : info.checkBox.value
            }
            // valueReverseForBackEndData : 체크 Boolean이 백엔드와 다를 때 사용 (Front의 경우 체크의 의미는 true인데 backend에서 false로 설계했을 때)
            onChange={info.checkBox.func}
            containerStyle={info.checkBox.containerStyle}
            checkStyle={info.checkBox.checkStyle}
          />
        )}
      </div>
    );
  }
}

export default Address;
