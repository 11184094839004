import { BottomSheetId } from 'components/BottomSheet';
import { makeAutoObservable } from 'mobx';
import { Dispatch, ReactNode, RefObject, SetStateAction } from 'react';
import { BottomSheetProps } from 'react-spring-bottom-sheet';
import { RefHandles } from 'react-spring-bottom-sheet/dist/types';

type CustomBottomSheetProps = {
  id: BottomSheetId;
  content: ReactNode;
  isOpened: boolean;
  title?: string;
  needCloseButton?: boolean;
  actionAfterClose?: () => void;
  closeAfterActionSuccess?: () => boolean;
  setBottomSheetHeight?: Dispatch<SetStateAction<number>>;
  sheetRef?: RefObject<RefHandles>;
};

export type BottomSheetContents = CustomBottomSheetProps &
  Omit<BottomSheetProps, 'open' | 'children'>;

/**
 * BottomSheet 스토어.
 * BottomSheet List의 id, content, props 들을 갖고 있음.
 * setBottomSheetContentsList를 해줄 때는 callback으로 받아서 처리해준다.
 * 필요한 화면에서 hook으로 open, close를 해주기 때문에 그냥 set을 할 경우에는 list가 꼬일수가 있음.
 */
export default class BottomSheetStore {
  constructor() {
    makeAutoObservable(this, undefined, { deep: false });
  }

  bottomSheetContentsList: Array<BottomSheetContents> = [];

  setBottomSheetContentsList = (
    callback: (
      prevBottomSheetContentsList: Array<BottomSheetContents>
    ) => Array<BottomSheetContents>
  ) => {
    this.bottomSheetContentsList = callback(this.bottomSheetContentsList);
  };
}
