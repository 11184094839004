import { DailyPayment, LongTermPayment, MonthlyPayment } from 'stores/NormalContractStore/types';

export const INITIAL_DAILY_PAYMENT: DailyPayment = {
  rent_type: 'daily',
  dropoff_extend: null,
  total_cost: null,
  delivered_date: null,
  delivered_hour: null,
  delivered_minute: null,
  expect_return_date: null,
  expect_return_hour: null,
  expect_return_minute: null,
  payment_memo: null,
};

export const INITIAL_MONTHLY_PAYMENT: MonthlyPayment = {
  rent_type: 'monthly',
  dropoff_extend: null,
  total_cost: null, // 총 요금
  delivered_date: null,
  delivered_hour: null,
  delivered_minute: null,
  expect_return_date: null,
  expect_return_hour: null,
  expect_return_minute: null,
  payment_memo: null,
  security_deposit: null, // 보증금
};

export const INITIAL_LONG_TERM_PAYMENT: LongTermPayment = {
  rent_type: 'long_term',
  dropoff_extend: null,
  total_cost: null, // 총 요금
  delivered_date: null,
  delivered_hour: null,
  delivered_minute: null,
  expect_return_date: null,
  expect_return_hour: null,
  expect_return_minute: null,
  payment_memo: null,
  payment_method_type: null,
  prepayment_term: null,
  prepaid_amount: null,
  deposit_amount: null,
  monthly_fee: null,
  payment_day: null,
  initial_payment_date: null,
  bank_name: null,
  account_num: null,
  account_holder: null,
  payment_method_detail: null,
  alimtalk: false,
  longTermMonth: 12,
  total_fee: null,
};
