import { Logo } from 'components/TopMenu';
import React from 'react';

import ContentsWrap from '../../components/ContentsWrap';
import sideMenuStyles from '../SideMenu/SideMenuStyle.module.scss';
import styles from './Skeleton.module.scss';

export const PageSkeleton = () => {
  return (
    <>
      <Logo>
        <div />
      </Logo>
      <div className={sideMenuStyles.side_menu_wrap} style={{ justifyContent: 'center' }}>
        <div style={{ height: 20 }} />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', height: '83px', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', height: '52px', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', height: '50px', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', height: '50px', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
        <div
          className={styles.call_card_line2}
          style={{ width: 'calc(100% - 40px)', margin: 20 }}
        />
      </div>
      <ContentsWrap style={{ backgroundColor: '#f2f2f2' }}>
        <div style={{ padding: '52px 40px' }}>
          <div className={styles.call_card_line3} style={{ height: '20px', width: '160px' }} />
          <div className={styles.call_card_line3} style={{ height: '30px', width: '1300px' }} />
        </div>
      </ContentsWrap>
    </>
  );
};
