import fileDownload from 'js-file-download';
import QueryString from 'qs';

import callApi from './callApi';
import errorHandler from './errorHandler';

/**
 * @brief Download File Function use 'js-file-download'
 * @author wontae Kim
 * @param {String} endpoint
 * @param {Object} params
 * @param {String} fileName must be include extension
 * @param {Boolean} isReturn only return data
 */
async function downloadFile(
  endpoint: string,
  params,
  fileName = 'default',
  isReturn = false,
  finalCallback?: VoidFunction
) {
  if (endpoint.includes('amazonaws.com')) {
    window.open(endpoint);
  } else {
    try {
      const response = await callApi({
        method: 'GET',
        url: endpoint,
        responseType: 'blob',
        params,
        disableErrorHandler: true,
        paramsSerializer(paramObj) {
          return QueryString.stringify(paramObj, { indices: false });
        },
      });

      if (response.status === 200) {
        if (isReturn) {
          return response.data;
        }

        return fileDownload(response.data, fileName);
      }

      const blobErrorData = JSON.parse(await response.data.text());

      errorHandler(blobErrorData, blobErrorData.text);
    } catch (err) {
      console.log(err);
    } finally {
      finalCallback && finalCallback();
    }
  }
}

const mobileDownload = (data, fileName) => {
  window.location.href = `ftp://localhost:8081/`;
  // window.location.href = "kakaotalk://inappbrowser/close"

  // const blob = new Blob([data], { type: 'application/pdf' });
  // const objectUrl = URL.createObjectURL(blob);
  // const a = document.createElement('a');

  // a.href = objectUrl;
  // a.download = fileName;
  // document.body.appendChild(a);
  // a.click();

  // document.body.removeChild(a);
  // URL.revokeObjectURL(objectUrl);
};

export async function getPdfData(endpoint) {
  return await downloadFile(endpoint, null, null, true);
}

export default downloadFile;
