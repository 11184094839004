import { AlimTalkSendConfirmModal } from 'components/ModalContents/AlimTalkSendConfirmModal';
import { TEN_THOUSAND_WON } from 'constants/stringTable';
import dayjs from 'dayjs';
import find from 'lodash/find';
import React from 'react';

import apiAutoUp from '../api/autoUp';
import BookedCarChangeModal from '../components/ModalContents/BookedCarChangeModal';
import CarBookingRegisterModal from '../components/ModalContents/CarBookingRegisterModal';
import CarInfoSearchModal from '../components/ModalContents/CarInfoSearchModal';
import CarModalContent from '../components/ModalContents/CarModalContent';
import ConnectRencarRequestModal from '../components/ModalContents/ConnectRencarRequestModal';
import EtcContractListModal from '../components/ModalContents/ContractModalContent';
import DeliveredTimeChangeModal from '../components/ModalContents/DeliveredTimeChangeModal';
import PartnerModalContent from '../components/ModalContents/PartnerModalContent';
import SalesEmployeeModalContent from '../components/ModalContents/SalesEmployeeModalContent';
import SearchDriverModal from '../components/ModalContents/SearchDriverModal';
import SelectCarModal from '../components/ModalContents/SelectCarModal';
import { carNumRegExp, etcCarNumRegExp } from '../constants/regularExpression';
import { diffDate } from '../helpers/dateManipulation';
import downloadFile from '../helpers/downloadFile';
import errorHandler from '../helpers/errorHandler';
import {
  checkRegIdFormat,
  regIdFormatValidate,
  regIdFormatValidateForHana,
} from '../helpers/registrationUtils';
import { validator } from '../helpers/validator';
import PaymentSchedule from '../pages/normalContract/paymentSchedule';
import { certificateLicense } from '../utils/LicenseCodeUtils';
import * as parser from './DataParser';

export const indemnficationFeeData = [30, 50, 100];
export const selfCarInsuranceData = [
  { key: '가입', value: true },
  { key: '미가입', value: false },
];
export const insuranceAgeData = [
  { key: '만 21세 이상', value: '21' },
  { key: '만 26세 이상', value: '26' },
];
export const insurancePersonLimitData = [{ key: '무한', value: -1 }];
export const insuranceObjectLimitData = [
  { key: `2천${TEN_THOUSAND_WON}`, value: 2000 },
  { key: `5천${TEN_THOUSAND_WON}`, value: 5000 },
  { key: '1억', value: 10000 },
];
export const insuranceSelfLimitData = [{ key: `1500${TEN_THOUSAND_WON}`, value: 1500 }];
export const claimStateOptionData = [
  { text: '배차중', value: 'using_car' },
  { text: MESSAGE.ALL, value: 'all' },
  { text: '반납예정', value: 'expect_return' },
  { text: '입금대기', value: 'deposit_wait' },
  { text: '입금완료', value: 'deposit_done' },
];
export const getIdNumberSplit = (key, idNum) => {
  if (!idNum) {
    return null;
  }

  let idNumArr = idNum.split('-');

  if (idNumArr.length < 2) {
    return null;
  }

  switch (key) {
    case 'first':
      return idNumArr[0];
    case 'second':
      return idNumArr[1];
    default:
      return null;
  }
};

export const getParameterByName = (name, url) => {
  if (typeof window === 'undefined') {
    return null;
  }

  if (!url) {
    url = window.location.href;
  }

  name = name.replace(/[[\]]/g, '\\$&');
  let regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  let results = regex.exec(url);

  if (!results) {
    return null;
  }

  if (!results[2]) {
    return '';
  }

  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const customerDriverLicenseValidator = (data) => {
  const check = [
    { key: 'customer_name', value: '운전자명', necessary: true },
    {
      key: 'customer_id_number1',
      value: '생년월일 앞자리',
      reg_exp: /^[0-9]{6}$/,
      text: '생년월일은 6자리의 숫자만 입력하세요',
      necessary: true,
    },
    { key: 'customer_driver_license', value: '면허번호', necessary: true },
    { key: 'customer_driver_type', value: '면허 구분', necessary: true },
  ];

  for (let i = 0; i < check.length; i++) {
    if (data[check[i].key]) {
      if (check[i].necessary) {
        if (String(data[check[i].key]).trim().length === 0) {
          alert(`${check[i].value} 값이 비어있습니다.`);

          return false;
        }

        if (check[i].reg_exp) {
          if (!check[i].reg_exp.test(data[check[i].key])) {
            alert(check[i].text);

            return false;
          }
        }
      } else if (String(data[check[i].key]).trim().length !== 0) {
        if (check[i].reg_exp) {
          if (!check[i].reg_exp.test(data[check[i].key])) {
            alert(check[i].text);

            return false;
          }
        }
      }
    } else {
      alert(`${check[i].value} 값이 비어있습니다.`);

      return false;
    }
  }

  // if(!validator(data.customer_driver_license,'driverLicense')){
  //   alert('면허 번호에 오타가 포함되어 있습니다. 정보를 확인해 주세요.')
  //   return false;
  // }

  return true;
};

export const secondCustomerDriverLicenseValidator = (data) => {
  const check = [
    { key: 'second_driver_name', value: '운전자명', necessary: true },
    {
      key: 'second_driver_id_number1',
      value: '생년월일 앞자리',
      reg_exp: /^[0-9]{6}$/,
      text: '생년월일은 6자리의 숫자만 입력하세요',
      necessary: true,
    },
    { key: 'second_driver_license', value: '면허번호', necessary: true },
    { key: 'second_driver_type', value: '면허 구분', necessary: true },
  ];

  for (let i = 0; i < check.length; i++) {
    if (data[check[i].key]) {
      if (check[i].necessary) {
        if (String(data[check[i].key]).trim().length === 0) {
          alert(`${check[i].value} 값이 비어있습니다.`);

          return false;
        }

        if (check[i].reg_exp) {
          if (!check[i].reg_exp.test(data[check[i].key])) {
            alert(check[i].text);

            return false;
          }
        }

        if (check[i].key === 'second_driver_id_number2') {
          if (!check[i].reg.test(String(data[check[i].key].trim()))) {
            alert(`${check[i].value} 값을 확인해 주세요.`);

            return false;
          }
        }
      } else if (String(data[check[i].key]).trim().length !== 0) {
        if (check[i].reg_exp) {
          if (!check[i].reg_exp.test(data[check[i].key])) {
            alert(check[i].text);

            return false;
          }
        }
      }
    } else {
      alert(`${check[i].value} 값이 비어있습니다.`);

      return false;
    }
  }

  // if(!validator(data.second_driver_license,'driverLicense')){
  //   alert('면허 번호에 오타가 포함되어 있습니다. 정보를 확인해 주세요.')
  //   return false;
  // }

  return true;
};

export const getShowStringforContract = (item, type) => {
  try {
    /**
     * @description 페이지별 계약서의 key값이 다르다.
     * 지점계약서 에서는 contracts
     * 알반계약서 에서는 details
     */
    const getContractKey = () => {
      if (item.hasOwnProperty('contracts')) {
        return 'contracts';
      }

      if (item.hasOwnProperty('details')) {
        return 'details';
      }
    };

    const contractKey = getContractKey();
    const contracts = item[contractKey];
    const firstContract = contracts[0];
    const latestContract = contracts[contracts.length - 1];

    switch (type) {
      case 'deliver_employee':
        return parser.defaultParser(latestContract.rent_manager_name);
      case 'delivered_at':
        return parser.dateParser(firstContract.delivered_date);
      case 'rent_car_name':
        return parser.defaultParser(latestContract.rent_car_name);
      case 'rent_car_number':
        return parser.defaultParser(latestContract.rent_car_number);
      case 'customer_name':
        if (item.license_verification) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 5,
              }}
            >
              <div>{parser.defaultParser(latestContract.customer_name)}</div>
              <div
                style={{
                  fontSize: 10,
                  width: 30,
                  height: 16,
                  borderRadius: 8,
                  border: '1px solid #5d8fff',
                  color: '#5d8fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                검증
              </div>
            </div>
          );
        }

        return parser.defaultParser(latestContract.customer_name);
      case 'customer_car':
        return parser.abbreviation(parser.defaultParser(latestContract.customer_car), 15);
      case 'customer_car_number':
        return parser.defaultParser(latestContract.customer_car_number);
      case 'customer_contact':
        return parser.contractParser(latestContract.customer_contact);
      case 'fault_rate':
        return typeof latestContract.fault_rate === 'number'
          ? `${parser.faultRateParser(latestContract.fault_rate)}`
          : '-';
      case 'insurance_company':
        return parser.defaultParser(latestContract.insurance_company);
      case 'registration_id':
        return parser.defaultParser(latestContract.registration_id);
      case 'industrial_company':
        return parser.defaultParser(latestContract.industrial_company);
      case 'sales_employee_name':
        return parser.defaultParser(item.sales_employee_name);
      case 'recommender_name':
        return parser.defaultParser(item.recommender_name);
      case 'retrieve_employee_name':
        return parser.defaultParser(latestContract.retrieve_employee_name);
      case 'insurance_manager_name':
        return parser.defaultParser(latestContract.insurance_manager_name);
      case 'claim_total_cost':
        return parser.moneyParser(item.claim_total_cost);
      case 'deposit_cost':
        return parser.moneyParser(item.deposit_cost);
      case 'diffrence_cost':
        return parser.moneyParser(item.diffrence_cost);
      case 'claim_at':
        return parser.dateParser(item.claim_at);
      // case 'claim_done_at': return parser.dateParser(item.claim_done_at);
      case 'claim_done_at':
        return item.claim_done_at ? dayjs(item.claim_done_at).format('YYYY.MM.DD') : '-';
      case 'claim_user_company':
        return parser.defaultParser(item.claim_user_company);
      case 'claim_user_name':
        return parser.defaultParser(item.claim_user_name);
      case 'user_business':
        return parser.defaultParser(item.user_business ? item.user_business.business_name : '');
      case 'difference_cost':
        return parser.moneyParser(item.difference_cost);
      //normal
      case 'rent_type':
        return parser.rentTypeParser(item.rent_type);
      case 'extended_count':
        return parser.defaultParser(item.extended_count);
      case 'd_day':
        return parser.dDayParser(item.d_day);
      case 'delivered_user':
        return parser.defaultParser(latestContract.delivered_user_name);
      case 'delivered_date':
        return parser.dateParser(firstContract.delivered_date);
      case 'expect_return_date':
        return parser.dateParser(item.expect_return_date);
      case 'normal_customer_name':
        if (item.license_verification) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 5,
              }}
            >
              <div>{parser.defaultParser(item.customer_name)}</div>
              <div
                style={{
                  fontSize: 10,
                  width: 30,
                  height: 16,
                  borderRadius: 8,
                  border: '1px solid #5d8fff',
                  color: '#5d8fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                검증
              </div>
            </div>
          );
        }

        return parser.defaultParser(item.customer_name);
      case 'normal_customer_contact':
        return parser.contractParser(item.customer_contact);
      case 'total_cost':
        return parser.moneyParser(item.total_cost);
      case 'security_deposit':
        return parser.moneyParser(item.security_deposit);
      case 'return_date':
        return parser.dateParser(latestContract.return_date);
      case 'state':
        return parser.getStateKorean(item.state);
      // case 'deposit_date': return parser.dateParser(item.deposit_date);
      case 'deposit_date':
        return item.deposit_date ? dayjs(item.deposit_date).format('YYYY.MM.DD') : '-';
      case 'rent_company_name':
        return parser.defaultParser(item.rent_company_name);
      case 'fax_state':
        return parser.faxStateLabelParser(item.fax_state, item.fax_result);
      case 'return_request':
        return parser.returnRequestParser(latestContract);
      case 'claimee_name':
        return item?.claimee_name;
      case 'guaranteed':
        return item?.guaranteed ? '보증' : '보험';
      case 'claim_requested_at':
        return parser.dateParser(item?.claim_requested_at);
      default:
        return '알수없음';
    }
  } catch (error) {
    console.log(error);

    return '알수없음';
  }
};

// 기존 계약서 리스트 api -> v2 API 로 변경시 이 함수 사용 해야 함
export const getShowStringforContractV2 = (item, type) => {
  try {
    switch (type) {
      case 'deliver_employee':
        return parser.defaultParser(item.rent_manager_name);
      case 'delivered_at':
        return parser.doubleLineDateParser(item.delivered_at); // before: created_at
      // return parser.dateParser(item.delivered_at); // before: created_at
      case 'rent_car_name':
        return;
      // return parser.defaultParser(item.rent_car_name);
      case 'rent_car_number':
        return;
      // return parser.defaultParser(item.rent_car_number);
      case 'car_model':
        return parser.doubleDataParser(item.car_model, item.rent_car_number);
      case 'customer_name':
        if (item.license_verification) {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 5,
              }}
            >
              <div>{parser.defaultParser(item.customer_name)}</div>
              <div
                style={{
                  fontSize: 10,
                  width: 30,
                  height: 16,
                  borderRadius: 8,
                  border: '1px solid #5d8fff',
                  color: '#5d8fff',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                검증
              </div>
            </div>
          );
        }

        return parser.defaultParser(item.customer_name);
      case 'customer_car':
        const customerCar = parser.abbreviation(parser.defaultParser(item.customer_car), 10);

        return parser.doubleDataParser(customerCar, item.customer_car_number);
      // return parser.abbreviation(parser.defaultParser(item.customer_car), 15);
      case 'customer_car_number':
        return;
      // return parser.defaultParser(item.customer_car_number);
      case 'customer_contact':
        return parser.contractParser(item.customer_contact);
      case 'fault_rate':
        return typeof item.fault_rate === 'number'
          ? `${parser.faultRateParser(item.fault_rate)}`
          : '-';
      case 'insurance_company':
        return parser.defaultParser(item.insurance_company);
      case 'registration_id':
        return parser.defaultParser(item.registration_id);
      case 'industrial_company':
        return parser.defaultParser(item.industrial_company);
      case 'sales_employee_name':
        return parser.defaultParser(item.sales_employee_name);
      case 'recommender_name':
        return parser.defaultParser(item.recommender_name);
      case 'retrieve_employee_name':
        return parser.defaultParser(item.retrieve_employee_name);
      case 'insurance_manager_name':
        return parser.defaultParser(item.insurance_manager_name);
      case 'claim_total_cost':
        return parser.moneyParser(item.claim_total_cost);
      case 'deposit_cost':
        return parser.moneyParser(item.deposit_cost);
      case 'diffrence_cost':
        return parser.moneyParser(item.diffrence_cost);
      case 'claim_at':
        return parser.doubleLineDateParser(item.claim_at);
      // return parser.dateParser(item.claim_at);
      case 'claim_done_at':
        return item.claim_done_at ? dayjs(item.claim_done_at).format('YYYY.MM.DD') : '-';
      case 'claim_user_company':
        return parser.defaultParser(item.claim_user_company);
      case 'claim_user_name':
        return parser.defaultParser(item.claim_user_name);
      case 'user_business':
        return parser.defaultParser(item.business_name);
      case 'difference_cost':
        return parser.moneyParser(item.difference_cost);
      case 'return_request':
        return parser.returnRequestParser(item);
      //normal
      case 'rent_type':
        return parser.rentTypeParser(item.rent_type);
      case 'extended_count':
        return parser.defaultParser(item.extended_count);
      case 'd_day':
        return parser.dDayParser(item.d_day);
      case 'delivered_user':
        return parser.defaultParser(item.delivered_user_name);
      case 'delivered_date':
        return parser.dateParser(item.delivered_date);
      case 'expect_return_date':
        return parser.dateParser(item.expect_return_date);
      case 'normal_customer_name':
        return parser.defaultParser(item.customer_name);
      case 'normal_customer_contact':
        return parser.contractParser(item.customer_contact);
      case 'total_cost':
        return parser.moneyParser(item.total_cost);
      case 'return_date':
        return parser.doubleLineDateParser(item.return_date);
      // return parser.dateParser(item.return_date);
      case 'state':
        return parser.getStateKorean(item.claim_state); // claim_state 로 체크 할 것 ( state 삭제됨 )
      case 'deposit_date':
        return item.deposit_date ? dayjs(item.deposit_date).format('YYYY.MM.DD') : '-';
      case 'rent_company_name':
        return parser.defaultParser(item.rent_company_name);
      case 'fax_state':
        return parser.faxStateLabelParser(item.fax_state, item.fax_result);
      case 'claimee_name':
        return item?.claimee_name;
      case 'guaranteed':
        return item?.guaranteed ? '보증' : '보험';
      case 'claim_requested_at':
        return parser.dateParser(item?.claim_requested_at);
      default:
        return '알수없음';
    }
  } catch (error) {
    console.log(error);

    return '알수없음';
  }
};

export const downloadInsuranceContractList = (state, options) => {
  if (Boolean(state.startDate) && Boolean(state.endDate)) {
    let diff = dayjs(state.endDate).diff(state.startDate, 'day');

    if (93 < diff) {
      return alert('엑셀 다운로드 기간 설정은 최대 3개월 입니다.');
    }
  }

  const params = { claim_state: options?.claimState || 'all' };

  if (state.periodOption) {
    // API 의 periodOption 과 매칭이 되지않아 수동으로 맞춰줌
    switch (state.periodOption) {
      case 'created_at':
        params.periodOption = 'using_car';
        break;
      case 'return_date':
        params.periodOption = 'before_claim';
        break;
      case 'claim_at':
        params.periodOption = 'send_claim';
        break;
      case 'claim_done_at':
        params.periodOption = 'done_claim';
        break;
      default:
        params.periodOption = state.periodOption;
        break;
    }
  }

  if (Boolean(state.option) && Boolean(state.value)) {
    params.option = state.option;
    params.value = state.value; // auto encoded
  }

  if (Boolean(state.startDate) && Boolean(state.endDate)) {
    params.startdate = state.startDate;
    params.enddate = state.endDate;
  }

  downloadFile(
    '/v2/rencar-claims/excel',
    params,
    `${dayjs().format('YYYYMMDD')}-${params.claim_state}.xlsx`
  );
};

export const downloadAllList = (endPoint, state) => {
  if (Boolean(state.startDate) && Boolean(state.endDate)) {
    let diff = dayjs(state.endDate).diff(state.startDate, 'day');

    if (93 < diff) {
      return alert('엑셀 다운로드 기간 설정은 최대 3개월 입니다.');
    }
  }

  const params = {};

  if (state.rent_type) {
    params.rent_type = state.rent_type;
  }

  if (state.periodOption) {
    params.periodOption = state.periodOption;
  }

  if (Boolean(state.option) && Boolean(state.value)) {
    params.option = state.option;
    params.value = state.value;
  }

  if (Boolean(state.startDate) && Boolean(state.endDate)) {
    params.startDate = state.startDate;
    params.endDate = state.endDate;
  }

  if (state.claimState) {
    params.claimState = state.claimState;
    params.state = state.claimState;
  }

  if (state.searchCompanyId) {
    params.searchCompanyId = state.searchCompanyId;
  }

  if (state.state) {
    params.claimState = state.state;
    params.state = state.state;
  }

  downloadFile(endPoint, params, `${dayjs().format('YYYYMMDD')}-all_list.xlsx`);
};

export const downloadContractList = (state, contractState = null) => {
  if (Boolean(state.startDate) && Boolean(state.endDate)) {
    let diff = dayjs(state.endDate).diff(state.startDate, 'day');

    if (93 < diff) {
      return alert('엑셀 다운로드 기간 설정은 최대 3개월 입니다.');
    }
  }

  const params = {};
  let endpoint = '';

  if (contractState) {
    params.state = contractState;
    endpoint = '/v2/normal-contracts/excel';
  } else {
    params.excel = true;
    endpoint = '/normal_contract/get_all_list';
  }

  if (state.periodOption) {
    params.periodOption = state.periodOption;
  }

  if (state.rent_type) {
    params.rent_type = state.rent_type;
  }

  if (state.option && state.value) {
    params.option = state.option;
    params.value = state.value; // auto encoded
  }

  if (state.startDate && state.endDate) {
    params.startDate = state.startDate;
    params.endDate = state.endDate;
  }

  downloadFile(endpoint, params, `${dayjs().format('YYYYMMDD')}-normal_${params.state}.xlsx`);
};

// 새로작성

const isCheckParams = (params, page) => {
  if (params === page) {
    return `${params}?`;
  }

  return `${params}&`;
};

export const searchContractUtil = (path, data) => {
  const { state, props } = data;
  const serializeForm = { page: 1 };

  if (state.rent_type) {
    serializeForm.rent_type = state.rent_type;
  }

  if (state.periodOption && state.startDate && state.endDate) {
    serializeForm.periodOption = state.periodOption;
  }

  if (state.option && state.value) {
    serializeForm.option = state.option;
    serializeForm.value = state.value;
  }

  if (state.startDate && state.endDate) {
    serializeForm.startDate = state.startDate;
    serializeForm.endDate = state.endDate;
    serializeForm.periodOption = state.periodOption;
  }

  // 추가된 초건 값
  if (state.startDate && !state.endDate) {
    serializeForm.startDate = state.startDate;
    serializeForm.endDate = state.startDate;
    serializeForm.periodOption = state.periodOption;
  }

  // 추가 된 조건 값
  if (state.endDate && !state.startDate) {
    serializeForm.startDate = state.endDate;
    serializeForm.endDate = state.endDate;
    serializeForm.periodOption = state.periodOption;
  }

  if (state.is_corporation) {
    serializeForm.is_corporation = state.is_corporation;
  }

  if (state.claimState && state.claimState !== 'all') {
    serializeForm.claimState = state.claimState;
  }

  if (state.searchCompanyId) {
    serializeForm.searchCompanyId = state.searchCompanyId;
  }

  if (state.guaranteed) {
    serializeForm.guaranteed = state.guaranteed;
  }

  if (state.fax_state) {
    serializeForm.fax_state = state.fax_state;
  }

  props.router.push(
    {
      pathname: path,
      query: serializeForm,
    },
    undefined,
    { shallow: true }
  );
};

// ======== ERP 계약서 작성 변경되면서 추가되는 함수
export const setData = (key, value, Store) => {
  if (value === 'true' || (value === 'false' && !Array.isArray(value))) {
    value = JSON.parse(value);
  }

  if (value === '-') {
    value = null;
  }

  Store.setValue({
    key,
    value,
  });
};

export const findItem = (tableData, key) => {
  let result = null;

  tableData.forEach((item) => {
    if (find(item, { key })) {
      result = find(item, { key });
    }

    if (Array.isArray(item)) {
      item.forEach((item2) => {
        if (find(item2, { key })) {
          result = find(item2, { key });
        }
      });
    }
  });

  return result;
};

export const getLicenseButtonName = (info, isSecond, search) => {
  const license_certificated = isSecond
    ? info.second_driver_license_certificated
    : info.license_certificated;
  const license_certificated_code = isSecond
    ? info.second_driver_license_certificated_code
    : info.license_certificated_code;

  if (license_certificated == true) {
    const isSuccess = ['00', '02', '03', '04', '11', '12', '13', '14'].includes(
      license_certificated_code
    );

    if (!info.additional) {
      //일반계약서
      //제1운전자인지 2운전자인지 확인.
      if (isSuccess) {
        return '초기화';
      }

      return '수정';
    }

    //교체계약서
    if (!isSecond) {
      //제1 운전자
      if (isSuccess && search) {
        return '초기화';
      }

      if (isSuccess) {
        return '조회완료';
      }

      return '수정';
    }

    //제2 운전자
    if (isSuccess) {
      return '초기화';
    }

    return '수정';
  }

  return '면허 진위 조회';
};

export const checkLicense = async (driverType, storeData, store) => {
  return new Promise(async (resolve, reject) => {
    if (driverType === 'customer') {
      const licenseData = {
        customer_name: storeData.customer_name,
        customer_id_number1: storeData.customer_id_number1,
        customer_id_number2: storeData.customer_id_number2,
        customer_driver_license: storeData.customer_driver_license,
        customer_driver_type: storeData.customer_driver_type,
      };
      const validateSuccess = customerDriverLicenseValidator(licenseData);

      if (validateSuccess) {
        const result = await licenseCheckSubmit(driverType, storeData, store);

        resolve(result);
      }
    }

    if (driverType === 'second_driver') {
      const licenseData = {
        second_driver_name: storeData.second_driver_name,
        second_driver_id_number1: storeData.second_driver_id_number1,
        second_driver_id_number2: storeData.second_driver_id_number2,
        second_driver_license: storeData.second_driver_license,
        second_driver_type: storeData.second_driver_type,
      };
      const validateSuccess = secondCustomerDriverLicenseValidator(licenseData);

      if (validateSuccess) {
        const result = await licenseCheckSubmit(driverType, storeData, store);

        resolve(result);
      }
    }
  });
};

const licenseCheckSubmit = async (option, storeData, store) => {
  // 면허 진위 조회 submit
  return new Promise(async (resolve) => {
    let data = null;
    const {
      customer_driver_license,
      customer_name,
      customer_id_number1,
      customer_driver_type,
      second_driver_name,
      second_driver_id_number1,
      second_driver_license,
      second_driver_type,
    } = storeData;

    store.loadingStore.on();

    if (option === 'customer') {
      data = {
        type: option,
        customer_driver_license: customer_driver_license.trim(),
        customer_name: customer_name.trim().replace(/ /g, ''),
        customer_birth: customer_id_number1,
        customer_driver_type: customer_driver_type.trim(),
        setValue: storeData.setValue,
      };
    }

    if (option === 'second_driver') {
      data = {
        type: option,
        customer_driver_license: second_driver_license.trim(),
        customer_name: second_driver_name.trim().replace(/ /g, ''),
        customer_birth: second_driver_id_number1,
        customer_driver_type: second_driver_type.trim(),
      };
    }

    const licenseResult = await certificateLicense(data, store);

    if (licenseResult) {
      if (option === 'customer') {
        storeData.license_certificated = true;
        storeData.license_certificated_code = licenseResult;
        resolve(storeData);
      } else if (option === 'second_driver') {
        storeData.second_driver_license_certificated = true;
        storeData.second_driver_license_certificated_code = licenseResult;
        resolve(storeData);
      } else {
        resolve(false);
      }
    }
  });
};

export const selectCar = (item, storeData, ModalStore, callback) => {
  const setConnectData = () => {
    const isConnectFuelRemainPctError = item?.fuelRemainPct === 'error';
    const isConnectDrivenDistanceError = item?.driven_distance === 'error';
    const allConnectError = isConnectFuelRemainPctError && isConnectDrivenDistanceError;

    const setGasCharge = () => {
      if (isConnectFuelRemainPctError) {
        alert('통신오류로 인해 유류량 데이터를 불러오지 못했습니다. 직접 확인 후 입력해 주세요.');
        setData('gas_charge', null, storeData);

        return;
      }

      setData('gas_charge', item ? item.fuelRemainPct || item.gas_charge : 25, storeData);

      return;
    };

    const setDrivenDistance = () => {
      if (isConnectDrivenDistanceError) {
        alert('통신오류로 인해 주행거리 데이터를 불러오지 못했습니다. 직접 확인 후 입력해 주세요.');
        setData('driven_distance', null, storeData);

        return;
      }

      setData('driven_distance', item ? item.totalMileage : null, storeData);

      return;
    };

    if (allConnectError) {
      alert(
        '통신오류로 인해 유류량/주행거리 데이터를 불러오지 못했습니다. 직접 확인 후 입력해 주세요.'
      );

      setData('gas_charge', null, storeData);
      setData('driven_distance', null, storeData);

      return;
    }

    setGasCharge();
    setDrivenDistance();
  };

  setConnectData();
  ModalStore?.modalClose();
  setData('rent_car_name', item ? item.car_name : null, storeData);
  setData('rent_car_number', item ? item.car_identity : null, storeData);
  setData('oil_type', item ? item.oil_type : null, storeData);
  setData('rent_company_car_id', item ? item.id : null, storeData);
  setData('use_connect', item ? item.use_connect : null, storeData);
  setData('car_model', item ? item.car_model : null, storeData);

  if (typeof callback === 'function') {
    callback();
  }
};

export const selectCarModalOpen = (storeData, ModalStore, external, callback) => {
  const selectModal = (
    <CarModalContent
      selectCar={(item) => {
        selectCar(item, storeData, ModalStore, callback);
      }}
      defaultCarId={storeData.rent_company_car_id || ''}
    />
  );
  const data = {
    modalComponent: selectModal,
    modalTitle: external ? '차량 변경' : '차량 선택',
    modalStyle: { width: '850px' },
    modalBodyStyle: { overflow: 'auto' },
  };

  ModalStore.modalOpen(data);
};

/**
 * @name searchCustomerModalOpen
 * @description 차량 예약 페이지 (carBooking/register) 에서 '고객 검색' 클릭시 사용하는 모달 오픈 함수.
 * 운전자 검색 모달 (SearchDriverModal) 을 그대로 사용하며, 표시하는 텍스트만 약간 변화가 있습니다.
 * @param {*} ModalStore
 * @param {*} setDriverInfo
 * @param {*} key
 */
export const searchCustomerModalOpen = (
  ModalStore,
  setDriverInfo,
  key = undefined,
  selectedId = -1
) => {
  const data = {
    modalComponent: (
      <SearchDriverModal
        setDriverInfo={setDriverInfo}
        keyName={key}
        selectedId={selectedId}
        usingCustomerSearch
      />
    ),
    modalTitle: '고객 검색',
    modalStyle: {
      width: '700px',
    },
  };

  ModalStore.modalOpen(data);
};

const selectPartner = (item, storeData, ModalStore) => {
  ModalStore.modalClose();

  if (item?.id) {
    storeData.setValue({ key: 'partner_info', value: item });
  }

  storeData.setValue({
    key: 'is_send_recommender_message',
    value: item.is_send_recommender_message,
  });
};

export const deliveredTimeChangeModal = (ModalStore, option) => {
  const selectModal = <DeliveredTimeChangeModal success={ModalStore.modalClose} option={option} />;
  const data = {
    modalComponent: selectModal,
    modalTitle: option === 'rately' ? '배차 시간 변경 안내' : '조기 배차 안내',
    modalStyle: { width: '520px', height: '388px' },
  };

  ModalStore.modalOpen(data);
};

export const carChangeInfoModalOpen = (storeData, ModalStore, carChangeFunc) => {
  const selectModal = (
    <BookedCarChangeModal
      isRegister={true}
      onClickConfirm={() => carChangeModalOpen(storeData, ModalStore, carChangeFunc)}
    />
  );
  const data = {
    modalComponent: selectModal,
    modalTitle: '예약 차량 변경',
    modalStyle: { width: '520px', height: '388px' },
  };

  ModalStore.modalOpen(data);
};

const carChangeModalOpen = (storeData, ModalStore, carChangeFunc) => {
  const selectModal = (
    <SelectCarModal
      data={{
        id: storeData.request_id,
        paid_cost: storeData.total_cost,
        version: storeData.version,
        orderer: storeData.orderer,
      }}
      carChangeFunc={carChangeFunc}
    />
  );
  const data = {
    modalComponent: selectModal,
    modalTitle: '예약 차량 변경',
    // modalStyle: { width: '688px', height: '550px' },
    modalStyle: { width: '910px', height: '550px' },
  };

  ModalStore.modalOpen(data);
};

export const carBookingRegisterEmployeeModal = ({ ModalStore, setData, id }) => {
  const modal = <CarBookingRegisterModal setData={setData} id={id} />;
  const data = {
    modalComponent: modal,
    modalTitle: '배차 담당 선택',
    modalStyle: { maxWidth: '700px', width: '80%' },
  };

  ModalStore.modalOpen(data);
};

export const carBookingRegisterAlimTalkModal = ({ ModalStore, submit, contentData }) => {
  const modal = (
    <AlimTalkSendConfirmModal
      data={contentData}
      submit={submit}
      close={() => ModalStore.modalClose()}
    />
  );
  const data = {
    modalComponent: modal,
    modalTitle: '알림톡 발송 확인',
    modalStyle: {
      width: 520,
    },
    modalTitleStyle: {
      margin: 0,
    },
  };

  ModalStore.modalOpen(data);
};

export const selectPartnerModalOpen = (storeData, ModalStore) => {
  const selectModal = (
    <PartnerModalContent
      selectPartner={(item) => selectPartner(item, storeData, ModalStore)}
      defaultPartner={storeData.partner_info && storeData.partner_info}
    />
  );
  const data = {
    modalComponent: selectModal,
    modalTitle: MESSAGE.SELECT_INTRODUCE_PLACE,
    modalStyle: { maxWidth: '710px', width: '80%' },
    modalBodyStyle: { overflow: 'auto' },
  };

  ModalStore.modalOpen(data);
};

const selectEmployee = (item, storeData, ModalStore) => {
  ModalStore.modalClose();
  storeData.setValue({ key: 'sales_employee', value: item });
};

export const selectSalesEmployeeModalOpen = (storeData, ModalStore) => {
  // storeData.setValue({ key: 'sales_employee', value: null });
  const selectModal = (
    <SalesEmployeeModalContent
      selectEmployee={(item) => selectEmployee(item, storeData, ModalStore)}
      defaultEmployId={storeData.sales_employee ? storeData.sales_employee.id : null}
    />
  );
  const data = {
    modalComponent: selectModal,
    modalTitle: MESSAGE.SELECT_BUSSINESS_EMPLOYEE,
    modalStyle: { maxWidth: '700px', width: '80%' },
    modalBodyStyle: { overflow: 'auto' },
  };

  ModalStore.modalOpen(data);
};

const selectContract = (item, storeData, ModalStore) => {
  ModalStore.modalClose();
  storeData.setValue({ key: 'customer_memo', value: item });
};

export const selectContractModalOpen = (storeData, ModalStore) => {
  const selectModal = (
    <EtcContractListModal selectContract={(item) => selectContract(item, storeData, ModalStore)} />
  );
  const data = {
    modalComponent: selectModal,
    modalTitle: '기타 계약사항 선택',
    modalStyle: { width: 720 },
    modalBodyStyle: { overflow: 'auto' },
  };

  ModalStore.modalOpen(data);
};

export const normalContractValidator = (submit, NormalContractStore) => {
  const { paymentInfo } = NormalContractStore;

  const driverInfoKey = [
    // 운전자정보 필수값 키
    { key: 'customer_name', label: '운전자명' },
    { key: 'customer_contact', label: '연락처', reg: /\d{9,11}$/g },
    { key: 'customer_id_number1', label: '생년월일 앞자리', reg: /^\d{6}/g },
    { key: 'customer_address', label: '주소' },
  ];
  let corporationInfoKey = [];

  if (NormalContractStore.is_corporation) {
    corporationInfoKey.push({ key: 'corporation_name', label: '법인명' });
  }

  const rentInfoKey = [
    // 렌트 정보 필수 값 키
    { key: 'rent_car_name', label: '대여 차종' },
    { key: 'rent_car_number', label: MESSAGE.RENTAL_CAR_NUMBER },
    { key: 'oil_type', label: '유종' },
    { key: 'gas_charge', label: '유류량' },
  ];
  const insuranceInfoKey = [
    // 자차보험 필수값 키
    { key: 'insurance_age', label: '보험 가입 연령' },
    { key: 'self_car_insurance', label: MESSAGE.SELF_INSURANCE },
    { key: 'self_car_insurance_limit', label: '자차 한도' },
    { key: 'indemnfication_fee', label: MESSAGE.ONESELF_CAR_INDEMNFICATION_COST },
    // 종합보험 필수값 키
    { key: 'insurance_person_limit', label: MESSAGE.INSURANCE_OPPONENT_LIMIT },
    { key: 'insurance_object_limit', label: MESSAGE.INSURANCE_OBJECT_LIMIT },
    // { key: 'insurance_self_critical_limit', label: '자손한도 (사망, 후유장애)' },
    { key: 'insurance_self_dead_limit', label: '자손한도 (사망)' },
    { key: 'insurance_self_limit', label: '자손한도 (부상)' },
  ];
  const paymentInfoKey = [
    // 결제정보 필수값 키
    { key: 'rent_type', label: '렌트 종류' },
  ];
  // const etcInfoKey = [
  //   {key: "memo", label: "특이사항"},
  //   {key: "customer_memo", label: "기타 계약사항"},
  // ]

  // ===========================================//
  // ===========================================//
  // 조건에 따른 필수값 항목 예외 처리
  if (NormalContractStore.has_second_driver) {
    // 제2 운전자가 있을때
    const data = [
      { key: 'second_driver_name', label: '제2 운전자명' },
      { key: 'second_driver_contact', label: '제2 운전자 연락처', reg: /\d{9,11}$/g },
      { key: 'second_driver_id_number1', label: '제2 운전자 생년월일 앞자리', reg: /^\d{6}/g },
      { key: 'second_driver_address', label: '제2 운전자 주소' },
    ];

    driverInfoKey.push(...data);
  }

  if (!NormalContractStore.self_car_insurance) {
    // 자차보험 미가입일때
    const findItem = find(insuranceInfoKey, { key: 'indemnfication_fee' });
    const findItem2 = find(insuranceInfoKey, { key: 'self_car_insurance_limit' });

    insuranceInfoKey.splice(insuranceInfoKey.indexOf(findItem), 1);
    insuranceInfoKey.splice(insuranceInfoKey.indexOf(findItem2), 1);
  }

  if (
    !NormalContractStore.indemnfication_fee_button_type &&
    NormalContractStore.self_car_insurance
  ) {
    insuranceInfoKey.push({ key: 'indemnfication_fee1', label: '면책금 앞자리' });
    insuranceInfoKey.push({ key: 'indemnfication_fee2', label: '면책금 뒷자리' });
  }

  if (NormalContractStore.indemnfication_fee1 && NormalContractStore.indemnfication_fee2) {
    NormalContractStore.indemnfication_fee = `${NormalContractStore.indemnfication_fee1}~${NormalContractStore.indemnfication_fee2}`;
  }

  switch (
    NormalContractStore.rent_type // 렌트 종류에 따른 필수값
  ) {
    case 'daily':
      const dailydata = [
        { key: 'delivered_date', label: '배차일시' },
        { key: 'delivered_hour', label: MESSAGE.ALLOCATE_CAR_TIME },
        { key: 'delivered_minute', label: '배차 분' },
        { key: 'expect_return_date', label: '반납 예정 일' },
        { key: 'expect_return_hour', label: '반납 예정 시간' },
        { key: 'expect_return_minute', label: '반납 예정 분' },
        { key: 'total_cost', label: '총 요금' },
        { key: 'payment_memo', label: '결제 메모' },
      ];

      paymentInfoKey.push(...dailydata);
      break;
    case 'monthly':
      const monthlydata = [
        { key: 'delivered_date', label: '배차일시' },
        { key: 'delivered_hour', label: MESSAGE.ALLOCATE_CAR_TIME },
        { key: 'delivered_minute', label: '배차 분' },
        { key: 'expect_return_date', label: '반납 예정 일' },
        { key: 'expect_return_hour', label: '반납 예정 시간' },
        { key: 'expect_return_minute', label: '반납 예정 분' },
        { key: 'total_cost', label: '총 요금' },
        { key: 'payment_memo', label: '결제 메모' },
      ];

      paymentInfoKey.push(...monthlydata);
      break;
    case 'long_term':
      const longdata = [
        // { key: 'start_datetime', label: '계약일시' },
        // { key: 'start_datetime_hour', label: '계약시간' },
        // { key: 'start_datetime_minute', label: '계약 분' },
        // { key: 'end_datetime', label: '계약종료 일' },
        // { key: 'end_datetime_hour', label: '계약종료 시간' },
        // { key: 'end_datetime_minute', label: '계약종료 분' },
        { key: 'delivered_date', label: '계약일시' },
        { key: 'delivered_hour', label: '계약시간' },
        { key: 'delivered_minute', label: '계약 분' },
        { key: 'expect_return_date', label: '계약종료 일' },
        { key: 'expect_return_hour', label: '계약종료 시간' },
        { key: 'expect_return_hour', label: '계약종료 분' },
        { key: 'payment_day', label: '납부일' },
        { key: 'alimtalk', label: '결제 안내 알림톡' },
        { key: 'payment_memo', label: '결제 메모' },
        { key: 'monthly_fee', label: '월 납부금' },
      ];

      // if (NormalContractStore.alimtalk) {
      //   paymentInfoKey.push({ key: 'payment_method_type', label: '납부 방법' });
      // }
      if (paymentInfo.alimtalk) {
        paymentInfoKey.push({ key: 'payment_method_type', label: '납부 방법' });
      }

      paymentInfoKey.push(...longdata);
      break;
    default:
      break;
  }

  if (paymentInfo.payment_method_type) {
    switch (paymentInfo.payment_method_type) {
      case '계좌이체':
        paymentInfoKey.push({ key: 'bank_name', label: '은행명' });
        paymentInfoKey.push({ key: 'account_holder', label: '예금주' });
        paymentInfoKey.push({ key: 'account_num', label: '계좌번호' });
        break;
      case '기타':
        paymentInfoKey.push({ key: 'payment_method_detail', label: '상세 납부 방법' });
        break;
      default:
        break;
    }
  }

  const necessaryKey = [
    // 전체 필수값 키
    ...driverInfoKey,
    ...corporationInfoKey,
    ...rentInfoKey,
    ...insuranceInfoKey,
    // ...paymentInfoKey,
  ];

  for (let i = 0; i < necessaryKey.length; i++) {
    if (
      (NormalContractStore[necessaryKey[i].key] &&
        String(NormalContractStore[necessaryKey[i].key]).trim().length === 0) ||
      NormalContractStore[necessaryKey[i].key] === '' ||
      NormalContractStore[necessaryKey[i].key] === [] ||
      NormalContractStore[necessaryKey[i].key] === null ||
      NormalContractStore[necessaryKey[i].key] === undefined
    ) {
      if (necessaryKey[i].key === 'gas_charge') {
        return alert('유류량을 입력해 주세요.');
      }

      alert(`${necessaryKey[i].label}의 값이 비었습니다.`);

      return false;
    }

    if (necessaryKey[i].reg) {
      const value = NormalContractStore[necessaryKey[i].key];

      if (necessaryKey[i].reg.exec(value) === null) {
        alert(`${necessaryKey[i].label}가 올바른 형식이 아닙니다.`);

        return false;
      }
    }
  }

  for (let i = 0; i < paymentInfoKey.length; i++) {
    if (
      (paymentInfo[paymentInfoKey[i].key] &&
        String(paymentInfo[paymentInfoKey[i].key]).trim().length === 0) ||
      paymentInfo[paymentInfoKey[i].key] === '' ||
      paymentInfo[paymentInfoKey[i].key] === [] ||
      paymentInfo[paymentInfoKey[i].key] === null ||
      paymentInfo[paymentInfoKey[i].key] === undefined
    ) {
      alert(`${paymentInfoKey[i].label}의 값이 비었습니다.`);

      return false;
    }
  }

  if (NormalContractStore.is_corporation) {
    if (NormalContractStore.corporation_business_number) {
      if (!validator(NormalContractStore.corporation_business_number, 'bizNumber')) {
        return alert('사업자 번호 형식을 확인하세요.');
      }
    }

    if (NormalContractStore.corporation_number1 || NormalContractStore.corporation_number2) {
      console.log(
        'NormalContractStore.corporation_number1: ',
        NormalContractStore.corporation_number1
      );

      if (
        !/^[0-9_]{6}$/.test(NormalContractStore.corporation_number1) ||
        !/^[0-9_]{7}$/.test(NormalContractStore.corporation_number2)
      ) {
        return alert('법인 번호 형식을 확인하세요.');
      }
    }
  }

  const gasCharge = Number(NormalContractStore.gas_charge);

  if (gasCharge < 0 || gasCharge > 100) {
    return alert('1~100 사이의 숫자만 입력해 주세요.');
  }

  if (NormalContractStore.indemnfication_fee1 && NormalContractStore.indemnfication_fee2) {
    if (
      Number(NormalContractStore.indemnfication_fee1) -
        Number(NormalContractStore.indemnfication_fee2) >
      0
    ) {
      return alert('자차 면책금 범위가 올바르지 않습니다.');
    }
  }

  if (String(NormalContractStore.indemnfication_fee).includes('~')) {
    const fee1 = NormalContractStore.indemnfication_fee.split('~')[0];
    const fee2 = NormalContractStore.indemnfication_fee.split('~')[1];

    if (Number(fee1) - Number(fee2) > 0) {
      return alert('자차 면책금 범위가 올바르지 않습니다.');
    }
  }

  if (paymentInfo.prepayment_term) {
    if (paymentInfo.prepayment_term === 'selectMonth') {
      alert('선수금 매출처리 분할 회차를 설정해 주세요.');

      return false;
    }
  }

  if (String(paymentInfo.payment_memo).length > 120) {
    return alert('결제메모가 120Byte를 초과할 수 없습니다.');
  }

  submit();
};

export const carNumberValidate = (value, Store) => {
  if (typeof value === 'string') {
    value = value.trim();
  }

  if (!validator(value, 'car_number')) {
    return alert(`차량번호를 확인하세요.\n예)01가1234, 서울01가1234`);
  }

  getAutoUpInfo(value, Store);
};

// 연식조회

const setCarInfo = (info, ContractStore) => {
  setData('customer_car', info.data.model_name, ContractStore);
  setData('customer_car_engine', info.data.displacement.replace(',', ''), ContractStore);
  setData('customer_car_age', info.data.rgst_ym, ContractStore);
  setData('checked_auto_up_info', true, ContractStore);
};

const carInfoSearchModalOpen = (data, Store) => {
  const { stateData, modalStore } = Store;
  const carInfoSearchModal = (
    <CarInfoSearchModal
      data={data}
      closeModal={modalStore.modalClose}
      setCarInfo={(info) => setCarInfo(info, stateData)}
    />
  );
  const modal = {
    modalComponent: carInfoSearchModal,
    modalTitle: '연식 조회 결과',
    modalStyle: { maxWidth: 520, width: '90vh' },
    modalBodyStyle: { overflow: 'auto' },
  };

  modalStore.modalOpen(modal);
};

const getAutoUpInfo = async (data, Store) => {
  const { modalStore, loadingStore, menuStore, authStore, stateData } = Store;
  let car_number = data;
  let from = 'contract';
  // const request = {
  //   method: 'GET',
  //   path: `/search_auto_up_info?car_number=${encodeURIComponent(car_number)}&from=${from}`,
  //   menuStore: MenuStore,
  //   authStore: AuthStore
  // }

  const params = {
    menuStore,
    authStore,
    car_number,
    from,
    disableErrorHandler: true,
  };

  loadingStore.on();
  catchAsync(async () => {
    const response = await apiAutoUp.searchAutoUpInfo(params);
    const responseData = response.data;

    if (response.status === 200) {
      loadingStore.off();
      let modalData = {
        car_name: responseData.car_name,
        cash: responseData.cash,
        point: responseData.point,
        car_number,
        from,
        auto_up_free_coupon: responseData.auto_up_free_coupon,
      };

      carInfoSearchModalOpen(modalData, {
        stateData,
        modalStore,
      });
    } else {
      loadingStore.off();

      if (responseData.error_no === 230) {
        return alert('차량이 조회되지 않습니다. 차량번호를 확인해 주세요.');
      }

      errorHandler(responseData, response.status);
    }
  }, loadingStore.off);
};

// 렌카콜 연결
const connectRencarRequest = async (item, stores) => {
  setData('rent_request_id', item.id, stores.stateData);

  if (item.registrationId) {
    setData(
      'registration_id',
      await parser.registrationIdParser(item.registrationId, stores),
      stores.stateData
    );
    setData(
      'insurance_company',
      await parser.insuranceCompanyParser(
        item.registrationId,
        item.requestType,
        item.userCompany,
        stores
      ),
      stores.stateData
    );
  } else {
    setData('registration_id', null, stores.stateData);
    setData('insurance_company', null, stores.stateData);
  }

  if (item.car_history_car_number) {
    setData('customer_car_number', item.car_history_car_number, stores.stateData);
  } else {
    setData('customer_car_number', null, stores.stateData);
  }

  if (item.car_history_engine_type) {
    setData('customer_car_engine', item.car_history_engine_type, stores.stateData);
  } else {
    setData('customer_car_engine', null, stores.stateData);
  }

  if (item.car_history_first_insurance) {
    setData('customer_car_age', item.car_history_first_insurance, stores.stateData);
  } else {
    setData('customer_car_age', null, stores.stateData);
  }

  if (item.customerCar) {
    setData('customer_car', item.customerCar, stores.stateData);
  } else {
    setData('customer_car', null, stores.stateData);
  }

  if (item.indemnification_fee) {
    let fee = null;

    switch (item.indemnification_fee) {
      case 300000:
        fee = 30;
        break;
      case 500000:
        fee = 50;
        break;
      case 1000000:
        fee = 100;
        break;
      default:
        break;
    }

    setData('indemnfication_fee', fee, stores.stateData);
  }

  stores.modalStore.modalClose();
};

export const connectRencarRequestModalOpen = (stores, callback) => {
  const connectRencarRequestModal = (
    <ConnectRencarRequestModal
      closeModal={stores.modalStore.modalClose}
      // connectRencarRequest={(data) => connectRencarRequest(data, stores)}
      connectRencarRequest={(data) => {
        callback(data);
        stores.modalStore.modalClose();
      }}
    />
  );
  const modalData = {
    modalComponent: connectRencarRequestModal,
    modalTitle: MESSAGE.CONNECT_RENT_CALL,
    modalStyle: { width: '70%', textAlign: 'center' },
    modalBodyStyle: { height: '63vh', overflow: 'auto' },
  };

  stores.modalStore.modalOpen(modalData);
};

export const insuranceContractValidator = (submit, ContractStore) => {
  const basicInfoKey = [
    { key: 'delivered_date', label: MESSAGE.ALLOCATE_CAR_DATE },
    { key: 'delivered_hour', label: MESSAGE.ALLOCATE_CAR_TIME },
    { key: 'delivered_minute', label: '배차 분' },
  ];

  const carNumberRegExp = ContractStore.customer_car_verified ? carNumRegExp : etcCarNumRegExp;
  const customerCarInfoKey = [
    {
      key: 'customer_car_number',
      label: '사고 차량 번호',
      reg: carNumberRegExp,
      customAlertText: '사고 차량 번호 형식을 확인하세요.',
    },
    { key: 'customer_car', label: MESSAGE.ACCIDENT_CAR_TYPE },
    { key: 'insurance_company', label: '보험사' },
  ];
  const driverInfoKey = [
    // 운전자정보 필수값 키
    { key: 'customer_name', label: '운전자명' },
    { key: 'customer_contact', label: '연락처', reg: /\d{9,11}$/g },
    { key: 'customer_id_number1', label: '생년월일 앞자리', reg: /^\d{6}/g },
    { key: 'customer_address', label: '주소' },
  ];
  let corporationInfoKey = [];

  if (ContractStore.is_corporation) {
    corporationInfoKey.push({ key: 'corporation_name', label: '법인명' });
  }

  const rentInfoKey = [
    // 렌트 정보 필수 값 키
    { key: 'rent_car_name', label: '대여 차종' },
    { key: 'rent_car_number', label: MESSAGE.RENTAL_CAR_NUMBER },
    { key: 'oil_type', label: '유종' },
    { key: 'gas_charge', label: '유류량' },
  ];
  const insuranceInfoKey = [
    // 보험정보 필수값 키
    { key: 'insurance_age', label: '보험 가입 연령' },
    { key: 'self_car_insurance', label: MESSAGE.SELF_INSURANCE },
    { key: 'indemnfication_fee', label: MESSAGE.ONESELF_CAR_INDEMNFICATION_COST },
    { key: 'self_car_insurance_limit', label: '자차 한도' },
    { key: 'insurance_person_limit', label: MESSAGE.INSURANCE_OPPONENT_LIMIT },
    { key: 'insurance_object_limit', label: MESSAGE.INSURANCE_OBJECT_LIMIT },
    { key: 'insurance_self_limit', label: MESSAGE.INSURANCE_ONESELF_LIMIT },
    { key: 'insurance_self_dead_limit', label: MESSAGE.INSURANCE_ONESELF_LIMIT },
  ];

  // const etcInfoKey = [
  //   {key: "memo", label: "특이사항"},
  //   {key: "customer_memo", label: "기타 계약사항"},
  // ]

  // ===========================================//
  // ===========================================//
  // 조건에 따른 필수값 항목 예외 처리
  if (ContractStore.has_second_driver) {
    // 제2 운전자가 있을때
    const data = [
      { key: 'second_driver_name', label: '제2 운전자명' },
      { key: 'second_driver_contact', label: '제2 운전자 연락처', reg: /\d{9,11}$/g },
      { key: 'second_driver_id_number1', label: '제2 운전자 생년월일 앞자리', reg: /^\d{6}/g },
      { key: 'second_driver_address', label: '제2 운전자 주소' },
    ];

    driverInfoKey.push(...data);
  }

  if (!ContractStore.self_car_insurance) {
    // 자차보험 미가입일때
    const findItem = find(insuranceInfoKey, { key: 'indemnfication_fee' });
    const findItem2 = find(insuranceInfoKey, { key: 'self_car_insurance_limit' });

    insuranceInfoKey.splice(insuranceInfoKey.indexOf(findItem), 1);
    insuranceInfoKey.splice(insuranceInfoKey.indexOf(findItem2), 1);
  }

  if (!ContractStore.indemnfication_fee_button_type && ContractStore.self_car_insurance) {
    insuranceInfoKey.push({ key: 'indemnfication_fee1', label: '면책금 앞자리' });
    insuranceInfoKey.push({ key: 'indemnfication_fee2', label: '면책금 뒷자리' });
  }

  if (ContractStore.indemnfication_fee1 && ContractStore.indemnfication_fee2) {
    ContractStore.indemnfication_fee = `${ContractStore.indemnfication_fee1}~${ContractStore.indemnfication_fee2}`;
  }

  if (ContractStore.registration_id === '-') {
    ContractStore.registration_id = null;
  }

  if (getRegInputType(ContractStore.insurance_company)) {
    const result =
      ContractStore.insurance_company === '하나'
        ? regIdFormatValidateForHana(ContractStore.registration_id)
        : regIdFormatValidate(ContractStore.insurance_company, ContractStore.registration_id);

    if (result.status === 'pass') {
      ContractStore.registration_id = result.regId;
    } else if (result.status === 'fail') {
      return alert(result.message);
    }
  }

  if (ContractStore.insurance_company === '기타') {
    ContractStore.insurance_company = null;
  }

  const necessaryKey = [
    // 전체 필수값 키
    ...basicInfoKey,
    ...customerCarInfoKey,
    ...corporationInfoKey,
    ...driverInfoKey,
    ...rentInfoKey,
    ...insuranceInfoKey,
  ];

  for (let i = 0; i < necessaryKey.length; i++) {
    if (
      (ContractStore[necessaryKey[i].key] &&
        String(ContractStore[necessaryKey[i].key]).trim().length === 0) ||
      ContractStore[necessaryKey[i].key] === '' ||
      ContractStore[necessaryKey[i].key] === [] ||
      ContractStore[necessaryKey[i].key] === null ||
      ContractStore[necessaryKey[i].key] === undefined
    ) {
      if (necessaryKey[i].key === 'gas_charge') {
        return alert('유류량을 입력해 주세요.');
      }

      alert(`${necessaryKey[i].label}의 값이 비었습니다.`);

      return false;
    }

    if (necessaryKey[i].reg) {
      const value = ContractStore[necessaryKey[i].key];

      if (necessaryKey[i].reg.exec(value) === null) {
        if (necessaryKey[i].customAlertText) {
          alert(`${necessaryKey[i].customAlertText}`);

          return false;
        }

        alert(`${necessaryKey[i].label}가 올바른 형식이 아닙니다.`);

        return false;
      }
    }
  }

  if (ContractStore.is_corporation) {
    if (
      Boolean(ContractStore.corporation_business_number) &&
      !validator(ContractStore.corporation_business_number, 'bizNumber')
    ) {
      return alert('사업자 번호 형식을 확인하세요.');
    }

    if (ContractStore.corporation_number1 || ContractStore.corporation_number2) {
      if (
        !/^[0-9_]{6}$/.test(ContractStore.corporation_number1) ||
        !/^[0-9_]{7}$/.test(ContractStore.corporation_number2)
      ) {
        return alert('법인 번호 형식을 확인하세요.');
      }
    }
  }

  const gasCharge = Number(ContractStore.gas_charge);

  if (gasCharge < 0 || gasCharge > 100) {
    return alert('1~100 사이의 숫자만 입력해 주세요.');
  }

  if (ContractStore.indemnfication_fee1 && ContractStore.indemnfication_fee2) {
    if (Number(ContractStore.indemnfication_fee1) - Number(ContractStore.indemnfication_fee2) > 0) {
      return alert('자차 면책금 범위가 올바르지 않습니다.');
    }
  }

  submit();
};

export const openPaymentSchedulePreview = (info) => {
  const { modalStore } = info;
  // if (!NormalContractStore.initial_payment_date) {
  //   return alert('최초납부일을 선택해 주세요.') // TODO 필수가 아니라고함
  // }

  const selectModal = <PaymentSchedule preview />;
  const data = {
    modalComponent: selectModal,
    modalTitle: '납부스케줄 미리보기',
    modalStyle: { maxWidth: '1100px', width: '90%' },
    modalBodyStyle: { height: '70vh', overflowY: 'hidden', marginRight: 1 },
  };

  modalStore.modalOpen(data);
};

export const zeroParser = (num) => {
  if (String(num).length < 2) {
    return `0${num}`;
  }

  return num;
};

export const isEmptyCheck = (data) => {
  const empty = data === null || data === undefined || data === [] || data.length === 0;

  if (data === 0) {
    return String(data);
  }

  if (empty) {
    return false;
  }

  return data;
};

export const calcDate = (dataObj, start = null, end = null) => {
  const startDate = dataObj[`${start}`] || dataObj.delivered_date;
  const startHour = isEmptyCheck(dataObj[`${start}_hour`]) || isEmptyCheck(dataObj.delivered_hour);
  const startMinute = dataObj[`${start}_minute`] || dataObj.delivered_minute;
  const endtDate = dataObj[`${end}`] || dataObj.expect_return_date;
  const endtHour = isEmptyCheck(dataObj[`${end}_hour`]) || isEmptyCheck(dataObj.expect_return_hour);
  const endtMinute = dataObj[`${end}_minute`] || dataObj.expect_return_minute;
  let calcDate = null;

  if (startDate && startHour && startMinute && endtDate && endtHour && endtMinute) {
    const startDateTime = `${startDate} ${startHour}:${startMinute}`;
    const endDateTime = `${endtDate} ${endtHour}:${endtMinute}`;
    // const month = dayjs(endDateTime).diff(startDateTime, 'month')
    // const day = dayjs(endDateTime).diff(startDateTime, 'day')
    // const hour = dayjs(endDateTime).diff(startDateTime, 'hour')
    // const currentDayMonth = dayjs(startDate).add(1, 'month').diff(dayjs(startDate), 'day')
    // return `기간: ${zeroParser(month)}개월 ${zeroParser(Math.abs((month * currentDayMonth) - day))}일 ${zeroParser(hour - (day * 24))}시간`
    const diffMonth = dayjs(endDateTime).diff(startDateTime, 'month');

    calcDate = dayjs(endDateTime).subtract(diffMonth, 'month');
    const diffDay = dayjs(calcDate).diff(startDateTime, 'day');

    calcDate = dayjs(calcDate).subtract(diffDay, 'day');
    const diffHour = dayjs(calcDate).diff(startDateTime, 'hour');

    if (start === 'start_datetime') {
      const calcLongDate = dayjs(endDateTime).diff(startDateTime, 'day');

      return `기간: ${calcLongDate}일`;
    }

    return `기간: ${zeroParser(diffMonth)}개월 ${zeroParser(diffDay)}일 ${zeroParser(
      diffHour
    )}시간`;
  }

  if (start === 'start_datetime') {
    return '기간: 0일';
  }

  return '기간: 00개월 00일 00시간';
};

const returnDateParser = (date, rentType, monthOption = 12) => {
  if (date) {
    if (rentType === 'insurance') {
      return dayjs(date).add(7, 'day').format('YYYY-MM-DD');
    }

    if (rentType === 'daily') {
      return dayjs(date).add(1, 'day').format('YYYY-MM-DD');
    }

    if (rentType === 'monthly') {
      return dayjs(date).add(30, 'day').format('YYYY-MM-DD');
    }

    if (rentType === 'long_term') {
      return dayjs(date).add(Number(monthOption), 'month').format('YYYY-MM-DD');
    }
  }
};

export const autoReturnDate = (...args) => {
  // FIXME: 일월 / 장기렌트일때 date 키값 형식이 다름
  // 일월 : xxx_date / 장기: xxx
  const [key, data] = args;
  let isDelivery = null;
  let isReturned = null;

  if (key === 'delivered' || key === 'start_datetime') {
    isDelivery =
      data[`${key}_date`] ||
      data[key] ||
      data[`${key}_hour`] ||
      data[`${key}_minute`] ||
      data[`${key}_hour`] === 0 ||
      String(data[`${key}_minute`]).length === 2;
  }

  if (key === 'expect_return' || key === 'end_datetime') {
    isReturned =
      (data[`${key}_date`] || data[key]) &&
      data[`${key}_hour`] &&
      data[`${key}_minute`] &&
      data[`${key}_hour`] === 0;
  }

  if (isDelivery && !isReturned) {
    if (key === 'delivered') {
      const date = returnDateParser(data.delivered_date, data.rent_type, data.longTermMonth);
      const hour = data.delivered_hour;
      const min = zeroParser(data.delivered_minute);

      return { date, hour, min };
    }

    if (key === 'start_datetime') {
      const date = returnDateParser(data.start_datetime, data.rent_type, data.longTermMonth);
      const hour = data.start_datetime_hour;
      const min = zeroParser(data.start_datetime_minute);

      return { date, hour, min };
    }
  }
};

export const autoSetReturnDate = (key, propSetData, dataObj, monthOption) => {
  // if (key === 'expect_return' || key === 'delivered') {
  //   const isDellivered = dataObj['delivered_date'] && (dataObj['delivered_hour'] || dataObj['delivered_hour'] === 0) && (dataObj['delivered_minute'] && dataObj['delivered_minute'].length === 2)
  //   const isReturned = dataObj['expect_return_date'] && (dataObj['expect_return_hour'] || dataObj['expect_return_hour'] === 0) && dataObj['expect_return_minute']
  //   if (isDellivered && !isReturned) {
  //     propSetData('expect_return_date', returnDateParser(dataObj['delivered_date'], dataObj.rent_type))
  //     propSetData('expect_return_hour', dataObj['delivered_hour'])
  //     propSetData('expect_return_minute', dataObj['delivered_minute'])
  //   }
  // }
  // if (key === 'end_datetime' || key === 'start_datetime') {
  //   const isDellivered = dataObj.start_datetime && (dataObj.start_datetime_hour || dataObj.start_datetime_hour === 0) && (dataObj.start_datetime_minute && String(dataObj.start_datetime_minute).length === 2)
  //   const isReturned = dataObj.end_datetime && (dataObj.end_datetime_hour || dataObj.end_datetime_hour === 0) && dataObj.end_datetime_minute;
  //   propSetData('end_datetime', returnDateParser(dataObj.start_datetime, dataObj.rent_type, ((monthOption || monthOption === 0) ? monthOption : 12)))
  //   propSetData('end_datetime_hour', dataObj.start_datetime_hour)
  //   propSetData('end_datetime_minute', dataObj.start_datetime_minute)
  //   if (isDellivered && isReturned && (monthOption || monthOption === 0)) {
  //     propSetData('end_datetime', returnDateParser(dataObj.start_datetime, dataObj.rent_type, (monthOption || monthOption === 0) ? monthOption : 12))
  //   }
  // }
};

export const returnMatchKey = (location, index = 1) => {
  return location.pathname.split('/')[index];
};

/**
 * @brief
 * store setValue 일괄 수행 함수 (Object - key, value 순회하여 set)
 * callback 을 제외한 값 세팅에만 사용
 *
 * @param {Object} store contractStore | normalContractStore
 * @param {Object} source ex) { id: 1789, username: 'tester' }
 */
export function storeSetValues(store = {}, source = {}) {
  const sourceArr = Object.entries(source);

  if (sourceArr.length > 0) {
    for (const [key, value] of sourceArr) {
      store.setValue({ key, value });
    }
  }
}

// 보험계약서 접수번호를 두 개로 나눠서 쓰는 보험사는 true, 아니면 false를 리턴
export function getRegInputType(insuranceCompany) {
  const useDividedInput = [
    '삼성',
    'DB',
    '현대',
    'KB',
    '메리츠',
    '한화',
    'AXA',
    '하나',
    '흥국',
    '롯데',
    'MG',
    '캐롯',
    '택시공제',
    '전세버스공제',
    '렌트카공제',
  ];

  if (useDividedInput.includes(insuranceCompany)) {
    return true;
  }

  return false;
}

export const insuranceDevide = (company, id) => {
  if (getRegInputType(company)) {
    const format = checkRegIdFormat(company);
    const formatFirst = id?.substr(0, format.firstLength);
    const formatSecondId = id?.substr(format.firstLength, format.secondLength);

    if (!formatFirst && !formatSecondId) {
      return '';
    }

    return `${formatFirst}-${formatSecondId}`;
  }

  return id;
};

export const isComfirmSendContract = (_info) => {
  if (!_info) {
    alert('error');
  }

  const { user_business, claim_insurance_contact, claimee_contact, guaranteed } = _info;
  const alertBaseMessage =
    `${user_business?.business_name} 사업자로 청구를 진행합니다.\n` +
    `잘못 선택된 사업자라면 사업자 선택 버튼을 통해\n` +
    `사업자를 변경 후 청구를 진행하세요.\n`;

  let cautionMessage;

  if (guaranteed) {
    cautionMessage = !claimee_contact
      ? `※ 청구담당자의 연락처가 비어 있어 문자통보가 가지않습니다.`
      : '';
  } else {
    cautionMessage = !claim_insurance_contact
      ? `※ 보험담당자의 연락처가 비어 있어 문자통보가 가지않습니다.`
      : '';
  }

  const message = alertBaseMessage + cautionMessage;
  const result = confirm(message);

  return result;
};

export const setScheduleInfo = (apiResult, store) => {
  // [일반] 대여차량, 대여종류(일/월), 대여일시, 반납일시, 대여요금, 대여기간, 운전자명, 운전자 연락처, 소개처 정보 자동 입력 (수정 가능)
  // [보험] 대여차량, 대여일시, 사고차량번호, 접수번호, 운전자명, 운전자 연락처, 소개처 정보 자동 입력 (수정 가능)
  const { schedule, self_contract_info } = apiResult;
  const { start_at, end_at, reservation } = schedule;
  const startAt = dayjs(start_at, 'YYYY-MM-DD HH:mm:ss');
  const endAt = dayjs(end_at, 'YYYY-MM-DD HH:mm:ss');

  if (reservation.rental_type === 'insurance') {
    //사고차
    setData(
      'customer_car_number',
      reservation?.customer_car_number ? reservation?.customer_car_number : null,
      store
    );
    //보험사
    setData(
      'insurance_company',
      reservation?.insurance_company ? reservation?.insurance_company : null,
      store
    );
    //보험접수번호
    const registrationId = insuranceDevide(
      reservation?.insurance_company,
      reservation.registration_num
    );

    setData('registration_id', registrationId, store);
  }

  if (['daily', 'monthly', 'long_term'].includes(reservation.rental_type)) {
    //렌트 타입
    setData('rent_type', reservation.rental_type ? reservation.rental_type : null, store);

    if (store.setRentType) {
      store.setRentType(reservation.rental_type);
    }

    //반납일시
    if (reservation.rental_type === 'long_term') {
      //장기일경우에,
      setData('start_datetime', startAt.isValid() ? startAt.format('YYYY-MM-DD') : null, store);
      setData('start_datetime_hour', startAt.isValid() ? startAt.format('HH') : null, store);
      setData('start_datetime_minute', startAt.isValid() ? startAt.format('mm') : null, store);

      setData('end_datetime', endAt.isValid() ? endAt.format('YYYY-MM-DD') : null, store);
      setData('end_datetime_hour', endAt.isValid() ? endAt.format('HH') : null, store);
      setData('end_datetime_minute', endAt.isValid() ? endAt.format('mm') : null, store);
      const diff = diffDate(end_at, start_at);

      setData(
        'longTermMonth',
        diff?.month > 1 || diff.year > 0 ? diff?.month + 12 * diff.year : 1,
        store
      );

      if (store.setPaymentInfo) {
        store?.setPaymentInfo({
          delivered_date: startAt.isValid() ? startAt.format('YYYY-MM-DD') : null,
          delivered_hour: startAt.isValid() ? startAt.format('HH') : null,
          delivered_minute: startAt.isValid() ? startAt.format('mm') : null,
          expect_return_date: endAt.isValid() ? endAt.format('YYYY-MM-DD') : null,
          expect_return_hour: endAt.isValid() ? endAt.format('HH') : null,
          expect_return_minute: endAt.isValid() ? endAt.format('mm') : null,
          longTermMonth: diff?.month > 1 || diff.year > 0 ? diff?.month + 12 * diff.year : 1,
        });
      }
    } else {
      setData('expect_return_date', endAt.isValid() ? endAt.format('YYYY-MM-DD') : null, store);
      setData('expect_return_hour', endAt.isValid() ? endAt.format('HH') : null, store);
      setData('expect_return_minute', endAt.isValid() ? endAt.format('mm') : null, store);

      if (store.setPaymentInfo) {
        store?.setPaymentInfo({
          expect_return_date: endAt.isValid() ? endAt.format('YYYY-MM-DD') : null,
          expect_return_hour: endAt.isValid() ? endAt.format('HH') : null,
          expect_return_minute: endAt.isValid() ? endAt.format('mm') : null,
        });
      }
    }

    //총요금
    setData('total_cost', reservation.cost ? reservation.cost : null, store);
  }

  //공통사항

  //배차일시
  setData('schedule_id', schedule?.id ? schedule?.id : null, store);
  setData('delivered_date', startAt.isValid() ? startAt.format('YYYY-MM-DD') : null, store);
  setData('delivered_hour', startAt.isValid() ? startAt.format('HH') : null, store);
  setData('delivered_minute', startAt.isValid() ? startAt.format('mm') : null, store);

  if (store.setPaymentInfo) {
    store?.setPaymentInfo({
      delivered_date: startAt.isValid() ? startAt.format('YYYY-MM-DD') : null,
      delivered_hour: startAt.isValid() ? startAt.format('HH') : null,
      delivered_minute: startAt.isValid() ? startAt.format('mm') : null,
    });
  }

  // 회차장소
  setData('dropoff_address', reservation ? reservation.dropoff_address : null, store);
  //고객정보
  setData('customer_name', reservation ? reservation?.driver_name : null, store);
  setData('customer_contact', reservation ? reservation?.driver_contact : null, store);
  //렌트차량정보
  selectCar(schedule?.car, store);

  //소개처
  setData('partner_info', reservation?.recommender?.id ? reservation.recommender : null, store);

  //비대면계약서 정보 있을 경우, 비대면계약서 정보 setting
  if (self_contract_info?.done) {
    const {
      id,
      customer_name,
      customer_address,
      customer_id_number1,
      customer_driver_license,
      customer_contact,
      customer_driver_type,
    } = self_contract_info;

    setData('customer_name', customer_name, store);
    setData('customer_address', customer_address, store);
    setData('customer_id_number1', customer_id_number1, store);
    setData('customer_driver_license', customer_driver_license, store);
    setData('customer_contact', customer_contact, store);
    setData('customer_driver_type', customer_driver_type, store);
  }
};

export const rimsLabelParser = (first_driver_verification, second_driver_verification) => {
  let labelText = '미검증';

  if (first_driver_verification && second_driver_verification) {
    labelText = '검증완료';
  }

  if (first_driver_verification && !second_driver_verification) {
    labelText = '2운전자 미검증';
  }

  return labelText;
};
