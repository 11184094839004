import { BottomSheetId } from 'components/BottomSheet';
import { BottomSheetContents } from 'stores/BottomSheetStore';
import useStores from 'stores/UseStores';

/**
 * 바텀시트가 닫힐 때의 애니메이션 효과를 기다리는 시간
 */
const WAITING_BOTTOM_SHEET_CLOSE = 300;

/**
 * 바텀시트 open, close 관리하는 hook.
 * open할 때는 isOpened를 제외한 bottomSheetContents Prop들을 받음.
 * close할 때는 id만 받아서 해당 바텀시트의 isOpned를 false로 변경해 바텀시트를 닫고 리스트에서 해당 바텀시트를 제거한다.
 * 바텀시트의 id는 상수로 관리하고 같은 id의 바텀시트는 하나만 렌더링 해주도록 한다.
 */
export const useBottomSheet = () => {
  const { bottomSheetStore } = useStores();
  const { setBottomSheetContentsList } = bottomSheetStore;

  const isOpenedBottomSheet = (bottomSheetId: BottomSheetId) =>
    bottomSheetStore.bottomSheetContentsList.find(({ id }) => id === bottomSheetId);

  const openBottomSheet = (bottomSheetContents: Omit<BottomSheetContents, 'isOpened'>) => {
    setBottomSheetContentsList((prevBottomSheetContentsList) => {
      const alreadyOpened = isOpenedBottomSheet(bottomSheetContents.id);

      if (alreadyOpened) {
        return [
          ...prevBottomSheetContentsList.filter(({ id }) => id !== bottomSheetContents.id),
          { ...bottomSheetContents, isOpened: true },
        ];
      }

      return [...prevBottomSheetContentsList, { ...bottomSheetContents, isOpened: true }];
    });
  };

  const closeBottomSheet = (bottomSheetId: BottomSheetId, needClosingAnimation = true) => {
    // 기본적으로 close animation을 적용하지만 필요없을 경우에는 리스트에서만 삭제한다. ex) Home에서의 바텀시트
    if (needClosingAnimation) {
      setBottomSheetContentsList((prevBottomSheetContentsList) =>
        prevBottomSheetContentsList.map((currentBottomSheet) => {
          if (currentBottomSheet.id === bottomSheetId) {
            // eslint-disable-next-line no-param-reassign
            currentBottomSheet.isOpened = false;
          }

          return currentBottomSheet;
        })
      );
    }

    // close 애니메이션 이후에 리스트에서 바텀시트를 제거한다.
    setTimeout(
      () =>
        setBottomSheetContentsList((prevBottomSheetContentsList) =>
          prevBottomSheetContentsList.filter((currentBottomSheet) => {
            return currentBottomSheet.id !== bottomSheetId;
          })
        ),
      needClosingAnimation ? WAITING_BOTTOM_SHEET_CLOSE : 0
    );
  };

  return { isOpenedBottomSheet, openBottomSheet, closeBottomSheet };
};
