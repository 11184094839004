import Router from 'next/router';
import { withRouter } from 'next/router';
import React from 'react';

import { routeFiltering } from '../utils/RouteUtils';
const checkUserAuthentication = (authStore) => {
  return {
    jwt: authStore.jwt,
    belongTo: authStore.belongTo,
    permission: authStore.permission,
    use_form: authStore.use_form,
    use_partner: authStore.use_partner,
    use_external: authStore.use_external,
    permissionUserType: authStore.permissionUserType,
  };
};

const withPrivateRoute = (WrappedComponent) => {
  const hocComponent = withRouter(({ ...props }) => <WrappedComponent {...props} />);

  hocComponent.getInitialProps = async (context) => {
    const userAuth = checkUserAuthentication(context.mobxStore.authStore);
    let wrappedProps = {};
    let errorName = undefined;

    if (WrappedComponent.getInitialProps) {
      wrappedProps = await WrappedComponent.getInitialProps(context);
    }

    if (!userAuth?.jwt && context.pathname !== '/') {
      return { ...wrappedProps, userAuth, errorName: 'forbidden' };
    }

    userAuth.groupLeader = wrappedProps?.defaultInfo?.group_leader;
    errorName = routeFiltering({ pathname: context.pathname, ...userAuth, query: context.query });

    return { ...wrappedProps, ...(errorName ? { errorName } : {}) };
  };

  return hocComponent;
};

export default withPrivateRoute;
