import React, { Component } from 'react';

import styles from './Page.module.scss';
import PageItem from './PageItem';

const pageRangeDisplayed = 5;
const prevPageImg = '/images/paging/prev_page_icon.png';
const nextPageImg = '/images/paging/next_page_icon.png';

class Paging extends Component {
  makePageInfo = () => {
    const totalPage = parseInt(this.props.totalPage);
    const currentPage = parseInt(this.props.currentPage);
    let total_pages = totalPage;
    let current_page = currentPage;
    let has_previous_page = currentPage > 1;
    let previous_page = currentPage - 1;
    let has_next_page = totalPage > 0 && currentPage != totalPage;
    let next_page = currentPage + 1;
    let first_page = Math.max(1, current_page - Math.floor(pageRangeDisplayed / 2));
    let last_page = Math.min(total_pages, current_page + Math.floor(pageRangeDisplayed / 2));

    if (last_page - first_page + 1 < pageRangeDisplayed) {
      if (current_page < total_pages / 2) {
        last_page = Math.min(
          total_pages,
          last_page + (pageRangeDisplayed - (last_page - first_page))
        );
      } else {
        first_page = Math.max(1, first_page - (pageRangeDisplayed - (last_page - first_page)));
      }
    }

    if (last_page - first_page + 1 > pageRangeDisplayed) {
      if (current_page > total_pages / 2) {
        first_page++;
      } else {
        last_page--;
      }
    }

    return {
      total_pages,
      current_page,
      has_previous_page,
      previous_page,
      has_next_page,
      next_page,
      first_page,
      last_page,
    };
  };

  isPrevPageVisible(has_previous_page) {
    if (!has_previous_page) return false;

    return true;
  }

  isNextPageVisible(has_next_page) {
    if (!has_next_page) return false;

    return true;
  }

  buildPages() {
    const pages = [];
    const { currentPage, movePage } = this.props;
    const pageInfo = this.makePageInfo();

    for (let i = pageInfo.first_page; i <= pageInfo.last_page; i++) {
      pages.push(
        <PageItem
          isActive={i === parseInt(currentPage)}
          key={i}
          pageNumber={i}
          pageText={`${i}`}
          onClick={movePage}
        />
      );
    }

    this.isPrevPageVisible(pageInfo.has_previous_page) &&
      pages.unshift(
        <PageItem
          key={`prev${pageInfo.previous_page}`}
          pageNumber={pageInfo.previous_page}
          onClick={movePage}
          image={prevPageImg}
          isDisabled={!pageInfo.has_previous_page}
        />
      );

    this.isNextPageVisible(pageInfo.has_next_page) &&
      pages.push(
        <PageItem
          key={`next${pageInfo.next_page}`}
          pageNumber={pageInfo.next_page}
          onClick={movePage}
          image={nextPageImg}
          isDisabled={!pageInfo.has_next_page}
        />
      );

    return pages;
  }

  render() {
    if (this.props.totalPage < 2) return null;

    const pages = this.buildPages();

    return <div className={styles.pages_wrap}> {pages} </div>;
  }
}

export default Paging;
