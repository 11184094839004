import callApi from '../../helpers/callApi';
export * from './getInventoryCarList';
export default {
  getCarBrandList(params) {
    const isForeign = params.type === '수입차' ? 1 : 0;
    let endPoint = `/v3/car-brands?overseas=${isForeign}`;

    return callApi({
      method: 'GET',
      url: endPoint,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  getCarModelList(params) {
    const brandId = params?.brand_id ? params.brand_id : 1;
    let endPoint = `/v3/car-models?brand_id=${brandId}`;

    return callApi({
      method: 'GET',
      url: endPoint,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  getWaitingStatusList(params) {
    const serializeForm = {
      page: params.page,
      per_page: params.per_page,
      state: params.state,
    };

    if (params.searchValue) {
      serializeForm.search = params.searchValue;
    }

    if (params.carGroupId) {
      serializeForm.car_group_id = params.carGroupId;
    }

    if (params.carRegisterStatus) {
      serializeForm.car_registration_status = params.carRegisterStatus;
    }

    if (params.washStatus) {
      serializeForm.is_washed = params.washStatus;
    }

    if (params.is_day_off) {
      serializeForm.is_day_off = true;
    }

    return callApi({
      method: 'GET',
      url: '/v2/rent-company-cars',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  getMemoList(params) {
    return callApi({
      method: 'POST',
      url: `/get_car_memo/${params.page}`,
      data: { car_id: params.carId },
      serverCookie: params?.cookie || null,
    });
  },
  addMemo(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-company-cars/${params.carId}/memos`,
      data: {
        state: params.carState,
        booking: params.booking,
        memo: params.memo,
      },
    });
  },
  getCarList(params) {
    const serializeForm = { page: params.page || 1, state: params.state || 'all' };

    if (params.searchValue) {
      serializeForm.search = decodeURIComponent(params.searchValue);
    }

    if (params.businessId) {
      serializeForm.business_id = params.businessId;
    }

    if (params.carRegisterStatus) {
      serializeForm.car_registration_status = params.carRegisterStatus;
    }

    if (params.carType) {
      serializeForm.car_type = params.carType;
    }

    if (params.carSize) {
      serializeForm.car_size = params.carSize;
    }

    if (params.listNum) {
      serializeForm.per_page = params.listNum;
    }

    if (params.onlyNoGroup) {
      serializeForm.only_no_group = params.onlyNoGroup;
    }

    if (params.carGroupId) {
      serializeForm.car_group_id = params.carGroupId;
    }

    if (params.modelId) {
      serializeForm.model_id = params.modelId;
    }

    if (params.normalContractId) {
      serializeForm.normal_contract_id = params.normalContractId;
    }

    return callApi({
      method: 'GET',
      url: '/v2/rent-company-cars',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params?.cookie || null,
    });
  },
  removeCar(params) {
    return callApi({
      method: 'DELETE',
      url: `/v2/rent-company-cars/${params.carId}`,
    });
  },
  applyCar(params) {
    return callApi({
      method: 'POST',
      url: '/v3/rent-company-cars',
      data: params,
    });
  },
  uploadCarExcel(params) {
    return callApi({
      method: 'POST',
      url: '/v3/rent-company-cars/upload-excel',
      data: params.file,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  editCarInfo(params) {
    return callApi({
      method: 'POST',
      url: `/v3/rent-company-cars/${params.carId}`,
      data: params.data,
      serverCookie: params?.cookie || null,
    });
  },
  getCarInfo(params) {
    return callApi({
      method: 'GET',
      url: `/v3/rent-company-cars/${params.carId}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  downloadCarExcel() {
    return `/v3/rent-company-cars/excel`;
  },
  downloadCarRegisterImage(params) {
    return callApi({
      method: 'POST',
      url: `/v3/rent-company-cars/${params.carId}`,
      data: params.data,
    });
  },
  // 구버젼 삭제 22.12.01
  // getProposalCarList(params) {
  //   return callApi({
  //     method: 'GET',
  //     url: `v2/rental-company-cars/requests/${params.id}`,
  //     params: {
  //       page: params.page,
  //       car_price_group_id: params.carPriceGroupId,
  //       selected_car_id: params.focusCarId,
  //       search: params.search,
  //       car_group_id: params.car_group_id,
  //     },
  //     stores: {
  //       menuStore: params.menuStore,
  //       authStore: params.authStore,
  //     },
  //     serverCookie: params.cookie,
  //   });
  // },
  // inventory완료 ts로 변경 22.12.01
  // getResponsibleCarList(params) {
  //   return callApi({
  //     method: 'GET',
  //     url: `/v2/rental-company-cars/requests-with-group/${params.id}`,
  //     params: {
  //       page: params.page,
  //       search: params.search,
  //       car_group_id: params.car_group_id,
  //       car_size: params.car_size,
  //     },
  //     stores: {
  //       menuStore: params.menuStore,
  //       authStore: params.authStore,
  //     },
  //     serverCookie: params.cookie,
  //   });
  // },
  getDisposedCarList(params) {
    const serializedForm = {
      page: params.page,
      trade_type: params.carStatus,
      business_id: params.businessCompany,
    };

    if (params.searchValue) {
      serializedForm.search = decodeURIComponent(params.searchValue);
    }

    return callApi({
      method: 'GET',
      url: 'v2/rent-company-cars/trade-logs',
      params: serializedForm,
      stores: {
        authStore: params.authStore,
        menuStore: params.menuStore,
      },
      serverCookie: params?.cookie || null,
    });
  },
  editDisposeInfo(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-company-cars/${params.carId}/trade-logs/update`,
      data: params.data,
      serverCookie: params?.cookie || null,
    });
  },
  addDisposeInfo(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-company-cars/${params.carId}/trade-logs`,
      data: params.data,
      serverCookie: params?.cookie || null,
    });
  },
  addDisposeAttachment(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-company-cars/${params.carId}/attachments`,
      data: {
        attachments: params.attachments,
        remove_list: params.remove_list,
      },
      serverCookie: params?.cookie || null,
    });
  },
  changeInsuranceAge(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-company-cars/${params.carId}/insurance-age`,
      data: {
        insurance_age: params.insuranceAge,
      },
      serverCookie: params?.cookie || null,
    });
  },
  toggleCarFlags(params) {
    return callApi({
      method: 'POST',
      url: `/v2/rent-company-cars/${params.id}/flags`,
      data: {
        [params.type]: params.status,
      },
      serverCookie: params?.cookie || null,
    });
  },
  getHolidayCarList(params) {
    const serializeForm = {
      page: params.page || 1,
      exclude_past: params.excludePast,
    };

    if (params.start && params.end) {
      serializeForm.start = params.start;
      serializeForm.end = params.end;
    }

    if (params.option) {
      serializeForm.option = params.option;
    }

    if (params.value) {
      serializeForm.search = decodeURIComponent(params.value);
    }

    if (params.perPage) {
      serializeForm.per_page = params.perPage;
    }

    if (params.carId) {
      serializeForm.company_car_id = params.carId;
    }

    // if (params.excludePast) {
    //   serializeForm.exclude_past = params.excludePast
    // }
    return callApi({
      method: 'GET',
      url: '/v2/company-car-schedules/day-off',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params?.cookie || null,
    });
  },
  addHoliday(params) {
    params.is_day_off = true;

    return callApi({
      method: 'POST',
      url: '/v2/company-car-schedules',
      data: params,
    });
  },
  deleteHoliday(params) {
    return callApi({
      method: 'POST',
      url: '/v2/company-car-schedules/delete',
      data: { ids: params.ids },
      serverCookie: params?.cookie || null,
    });
  },
  getCarSubModelList(params) {
    return callApi({
      method: 'GET',
      url: `/v3/car-models/${params.modelId}/submodels`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params?.cookie || null,
    });
  },
};
