import { ReactNode, useEffect, useState } from 'react';

interface DeferredComponentProps {
  children: ReactNode;
  componentForWaiting?: ReactNode;
  defferedTime?: number;
}

/**
 * 기본 렌더링이 연기 되는 시간(ms)
 * 평균 응답 230ms https://rencar.atlassian.net/browse/IMS-9161
 */
const DEFAULT_DEFFERED_TIME = 230;

/**
 * 일정 시간 지연 후 렌더링 해주는 컴포넌트.
 * 지연 시간 동안은 빈 화면 혹은 componentForWaiting을 보여주고 시간이 지나면 전달 받은 children을 렌더링.
 * ex) 스켈레톤을 감쌀 경우: 200ms 전 까지는 빈 화면이 노출. 200ms 이후에는 스켈레톤 노출. 스켈레톤으로 인한 깜빡임 현상 제거.
 */
export const DeferredComponent = ({
  children,
  componentForWaiting,
  defferedTime,
}: DeferredComponentProps) => {
  const [isDeferred, setIsDeferred] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIsDeferred(true);
    }, defferedTime || DEFAULT_DEFFERED_TIME);

    return () => clearTimeout(timeoutId);
  }, []);

  if (!isDeferred) {
    return componentForWaiting ? <>{componentForWaiting}</> : null;
  }

  return <>{children}</>;
};
