export const analytics = {
  google: { trackingId: 'UA-158873290-1' },
  staging_google: { trackingId: 'UA-166798843-2' },
};

export const kakaoAppKey = {
  nativeAppKey: 'b101dade5e8b1d9727224763c3989c57',
  restApiKey: 'ca15d9665a2b1a72a6509edef86d839f',
  javascriptKey: '5a5c8efefd98a39e298d81cb1504222f',
  adminKey: '680b1c24fd745e732ba988feeadc34f3',
};

export const iamportKey = {
  code: 'imp84859247',
  restApiKey: '0225982473635617',
  restApiSecret: '7BkbKH9MWnnUdAmWZZhwFshuW95SrACzbWLWDVIzi5uwGRtGRKdwdv09E626GEFRAqOemxOEDjbjaE01',
};

export const gtmId = {
  id: 'GTM-KGDLT34',
  staging_id: 'GTM-NZL3J9Q',
};

export const tMap = {
  project_key: 'l7xxb70433e94ed04e98816d67cdd5ff939f',
};

export const channelIOKey = {
  plugin_key: '4297726c-0b9a-466a-847f-9cb4bf0c2cb9',
};
