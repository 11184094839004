import Image from 'next/image';
import { forwardRef, MutableRefObject, useRef } from 'react';

import { useCheckBox } from './CheckBox.hook';
import * as S from './CheckBox.style';
import { CheckBoxProps } from './CheckBox.type';

const CheckBox = forwardRef(
  (
    {
      styles,
      imageSrc,
      imagePropsWithoutSrc,
      gap,
      label,
      disabled,
      defaultChecked,
      checked,
      onClick,
      ...rest
    }: CheckBoxProps,
    propRef: MutableRefObject<HTMLInputElement>
  ) => {
    const selfRef = useRef<HTMLInputElement>(null);
    const ref = propRef ?? selfRef;
    const { isChecked, handleClick } = useCheckBox({
      checked,
      defaultChecked,
      onClick,
    });

    return (
      <S.Wrapper
        style={styles?.wrapper}
        $disabled={disabled}
        $gap={gap}
        onClick={(event) => {
          event.preventDefault();

          if (disabled) {
            return;
          }

          ref.current?.click();
        }}
      >
        <S.CheckImageWrapper style={styles?.imageWrapper} $checked={isChecked} $disabled={disabled}>
          <Image
            style={{ opacity: isChecked ? 1 : 0 }}
            src={imageSrc ?? '/images/ic-check-white-12@3x.png'}
            width={12}
            height={12}
            alt="체크"
            {...imagePropsWithoutSrc}
          />
        </S.CheckImageWrapper>
        {label}
        <input
          readOnly
          ref={ref}
          className="hidden"
          type="checkbox"
          defaultChecked={defaultChecked}
          checked={checked}
          onClick={(event) => {
            event.stopPropagation();

            handleClick(event);
          }}
          {...rest}
        />
      </S.Wrapper>
    );
  }
);

export default CheckBox;
