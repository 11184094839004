import { toJS } from 'mobx';
import { inject } from 'mobx-react';
import React, { Component } from 'react';

import Button from '../../../atoms/Button/index.tsx';
import styles from './ComfirmAlert.module.scss';
@inject('modalStore')
class ConfirmAlert extends Component {
  onClickCancel = () => {
    if (this.props.onClickCancel && typeof this.props.onClickCancel === 'function') {
      this.props.onClickCancel();
    }

    this.props.modalStore.modalClose();
  };

  onClickConfirm = () => {
    if (this.props.onClickConfirm && typeof this.props.onClickConfirm === 'function') {
      this.props.onClickConfirm();
    }
  };
  render() {
    return (
      <div className={styles.modal_wrap}>
        <div className={styles.content_wrap}>
          <div className={styles.list_text}>{toJS(this.props.content)}</div>
          <div className={styles.button_wrap}>
            {!this.props.hideCancelBtn && (
              <Button
                className={styles.cancel}
                inlineStyle={toJS(this.props.cancelBtnStyle) || {}}
                btnText={this.props.cancelButtonText ? this.props.cancelButtonText : '취소'}
                btnClick={this.onClickCancel}
              />
            )}
            <Button
              className={styles.confirm}
              inlineStyle={toJS(this.props.confirmBtnStyle) || {}}
              btnText={this.props.confirmButtonText ? this.props.confirmButtonText : '확인'}
              btnClick={this.onClickConfirm}
              disabled={this.props.confirmDisabled}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ConfirmAlert;
