import { ErrorBoundaryState } from 'components/ErrorBoundary';
import { inject, observer } from 'mobx-react';
import React, { ErrorInfo } from 'react';
import ModalStore from 'stores/ModalStore';

import styles from './ModalErrorBoundary.module.scss';

interface Props {
  modalStore?: ModalStore;
  children: React.ReactNode;
}

@inject('modalStore')
@observer
class ModalErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error: Error): Pick<ErrorBoundaryState, 'hasError' | 'error'> {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error, errorInfo }, () => {
      this.openErrorModal();
    });
  }

  resetError = async (): Promise<void> => {
    this.setState({
      hasError: false,
      error: null,
      errorInfo: null,
    });
  };

  openErrorModal = () => {
    const modal = (
      <div className={styles.modal_container}>
        <div>닫기 버튼을 누르고 다시 한 번 시도해주세요.</div>
        <div className={styles.close_button_container}>
          <button
            className={styles.close_button}
            onClick={() => {
              this.props.modalStore.modalClose();
              this.resetError();
            }}
          >
            닫기
          </button>
        </div>
      </div>
    );

    const data = {
      modalComponent: modal,
      modalTitle: '알 수 없는 오류가 발생했습니다.',
      modalStyle: { width: '500px', minHeight: '500px' },
      resetError: this.resetError,
    };

    this.props.modalStore.modalOpen(data);
  };

  render() {
    return this.props.children;
  }
}

export default ModalErrorBoundary;
