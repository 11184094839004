import React, { ButtonHTMLAttributes, Component, MouseEventHandler, ReactNode } from 'react';

import styles from './Button.module.scss';

interface CommonButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /** @deprecated inlineStyle은 기존 로직 유지를 위함입니다. style을 사용하세요 */
  inlineStyle?: React.CSSProperties;
  /** @deprecated btnClick은 기존 로직 유지를 위함입니다. onClick을 사용하세요 */
  btnClick?: React.MouseEventHandler<HTMLButtonElement> | Function;
  outline?: boolean;
  loading?: boolean;
}

interface TextButtonProps extends CommonButtonProps {
  /** @deprecated btnText는 기존 로직 유지를 위함입니다. children을 사용하세요 */
  btnText: string;
  children?: never; // children 속성이 허용되지 않도록 설정
}

interface ChildrenButtonProps extends CommonButtonProps {
  children: ReactNode;
  /** @deprecated btnText는 children 속성과 함께 사용될 수 없습니다. */
  btnText?: never; // btnText 속성이 허용되지 않도록 설정
}

/** @summary btnText와 children props는 동시에 올 수 없습니다. */
type ComponentProps = TextButtonProps | ChildrenButtonProps;

const Loading = () => (
  <div className={styles.loading_wrap}>
    <img src="/images/spinner.png" className={styles.loading} />
  </div>
);

class Button extends Component<ComponentProps> {
  handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    const { btnClick, loading, onClick } = this.props;

    if (loading) return;

    if (typeof btnClick === 'function') {
      btnClick(e);

      return;
    }

    if (typeof onClick === 'function') {
      onClick(e);

      return;
    }
  };

  renderChildren = () => {
    const { loading, children, btnText } = this.props;

    if (loading) return <Loading />;

    if (children) return children;

    return btnText;
  };

  render() {
    const { className, inlineStyle, outline, loading, btnText, btnClick, style, ...restBtnProps } =
      this.props;

    return (
      <button
        {...restBtnProps}
        className={`${outline ? styles.outline : styles.btn_default} ${className || ''}`}
        onClick={this.handleClick}
        style={inlineStyle || style}
      >
        {this.renderChildren()}
      </button>
    );
  }
}

export default Button;
