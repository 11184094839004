export const RENCAR_COMPANY = '아이엠에스모빌리티(주)';
export const RENCAR = '렌카';
export const GONG_CHA = '공차';
export const CH = '씨에이치';
export const RENT_CAR_COMPANY = '렌터카업체';
export const RENCAR_BOSS_NAME = '조영탁 대표';
export const BUSSINESS_LICENSE_NUMBER = '사업자 등록 번호: 206-86-77227';
export const RENCAR_ADDRESS =
  '주소: 서울특별시 광진구 아차산로 355, 302호(자양동, 타워더모스트광진아크로텔)';
export const SALE_NUMBER = '통신판매업신고번호: 제2019-서울광진-2048호';
export const COPY_RIGHT = '© IMS Mobility Inc.';

export const USE_TERM = '이용약관';
export const LOCATION_SERVICE_TERM = '위치기반서비스 이용약관';
export const PRIVACY_DATA_TERM = '개인정보 처리방침';
export const CUSTOMER_TERM = '고객 이용약관';

export const MOBILE_CONTRACT = '모바일 계약서';
export const CAR_LICENSE_FACT_YN = '면허증 진위여부';
export const SEARCH_CAR_OLD = '연식 조회';
export const CLAIM_SYSTEM = '청구 시스템';
export const JOIN_PLATFORM = '가입 플랫폼';

export const SEARCH_AT_MAIN = '메인에서 조회';
export const SEARCH_AT_CONTRACT = '계약서작성에서 조회';
export const SEARCH_AT_ALLOCATE_CAR = '배차중에서 조회';
export const SEARCH_AT_WAIT_CLAIM = '청구대기에서 조회';
export const SEARCH_CAR_OR_CAR_NUMBER = '차량명 또는 차량번호 검색';

//요청
export const SUGGESTION = '제안';
export const SUPERPASS_COUPON = '슈퍼패스 쿠폰';

//계약서 작성
export const ETC_CONTRACT_POINT = '기타 계약사항';
export const CONTRACT_DOCUMENT_INFO = '계약서 정보';

//계약서 상세
export const SELECT_INTRODUCE_PLACE = '소개처 선택';
export const SELECT_BUSSINESS_EMPLOYEE = '영업 직원 선택';
export const SUPERPASS = '슈퍼패스';
export const RANT_CAR_INFO = '렌터카 정보';
export const INSURANCE_INFO = '보험 정보';
export const FOLLOWING_COMPANY_CALL = '지정 렌트업체 콜';

//소개처관리
export const REVISE_INTRODUCE_PLACE_INFO = '소개처 정보 수정';

//사업자관리
export const TAX_BILL_JOIN = '세금계산서 발행';

//차량관리
export const REVISE_REGISTER_CAR = '등록 차량 수정';
export const SEARCH_HISTORY = '조회이력';

//비대면,반납완료
export const CONTRACT_INFO = '계약정보';

//게시판
export const VIEW_DETAIL = '상세보기';
export const COMPANY_NOTICE = '사내 공지사항';
export const WRITE_COMPANY_NOTICE = '사내 공지사항 작성';
export const REVISE_COMPANY_NOTICE = '사내 공지사항 수정';

//알림
export const ALARM = '알림';

//사업자관리
export const INPUT_EMPLOYEE_INFO_ESSENTIAL = '직원 정보 입력(필수)';
export const SETTING_EMPLOYEE_AUTHORITY_ESSENTIAL = '직원 권한 설정 (필수)';
export const EMPLOYEE_DRIVE_LICENSE_INFO = '직원 면허 정보 (선택)';
