import { Component } from 'react';
// import { captureException } from '../helpers/sentry';
let loadingStore = null;

export const setLoadingStore = (ls) => {
  loadingStore = ls;
};

String.prototype.validStr = function (argument) {
  const str = argument || this.toString();

  if (str === 'null' || str === 'undefined') {
    return '';
  }

  if (str && typeof str === 'string') {
    return str;
  }

  return str ? str.toString() : '';
};

Component.prototype.asyncSetState = function (obj) {
  return new Promise((resolve) => {
    this.setState(obj, resolve);
  });
};

/**
 * 모든 async 함수에서 쓰는것을 권장함.
 * @param {function} fn async함수에서 exception을 캐치하기위한 util function
 *
 * @param {function} finallyFunc finally에서 수행하여야할 function 없어도된다.
 */
global.catchAsync = (fn, finallyFunc = () => void 0) => {
  return (
    Promise.resolve(fn())
      // .catch((err) => captureException(err))
      .catch((err) => console.error(err))
      .finally(() => {
        finallyFunc();

        if (typeof loadingStore?.off === 'function') {
          loadingStore.off();
        }
      })
  );
};
