import dayjs from 'dayjs';

import apiInsuComp from '../api/insuranceCompanies';
import { parseTotalInsuranceCompanyList } from './InitFetchFunctions/contract/parseTotalInsuranceCompanyList';

const getInsuranceArray = async (stores, needToGuaranteed = false) => {
  try {
    const params = {
      menuStore: stores?.menuStore,
      authStore: stores?.authStore,
    };
    const response = await apiInsuComp.getInsuCompanies(params, needToGuaranteed);
    const responseData = response.data;
    const insuranceCompany = responseData.insurance_companies;
    const sliceIndex = insuranceCompany.findIndex((element) => element.id === 20);

    const callbackParams = [
      ...insuranceCompany.slice(0, sliceIndex),
      insuranceCompany[insuranceCompany.length - 1],
      ...insuranceCompany.slice(sliceIndex, insuranceCompany.length - 1),
    ];

    if (response.status === 200) {
      return !needToGuaranteed
        ? parseData(callbackParams)
        : parseTotalInsuranceCompanyList(callbackParams);
    }

    return [];
  } catch (error) {
    console.log(error);
    alert('보험사 목록 로딩 실패');

    return [];
  }
};

const parseData = (data) => {
  let resultArray = [];

  for (const item of data) {
    resultArray.push({
      text: item.name,
      value: item.id,
    });
  }

  return resultArray;
};

export default getInsuranceArray;

export const kakaoCallCreatedAfter220714 = (create_date) => {
  const createdDate = dayjs(create_date, 'YYYY-MM-DD HH:mm:ss');
  const changeInsuranceDate = dayjs('2022-07-14 00:00:00', 'YYYY-MM-DD HH:mm:ss'); // 2022-07-14 00:00

  return createdDate.isAfter(changeInsuranceDate) && dayjs().isAfter(changeInsuranceDate);
};
