import filter from 'lodash/filter';
import React, { Component } from 'react';

import Tooltip from '../../../../components/Tooltip';
import styles from './PureTable.module.scss';

class Table extends Component {
  constructor(props) {
    super(props);
    this.tbody = null;
    this.state = {
      didMounted: null,
    };
  }

  componentDidMount = () => {
    this.setState({ didMounted: true });
  };

  _renderThead = () => {
    const { theadData, theadWidth } = this.props;

    if (this.props.theadTooltip) {
      return theadData.map((data, i) => {
        return (
          <th
            key={i}
            style={{
              width: theadWidth ? `${theadWidth[i]}%` : null,
            }}
          >
            {data.label}
            {data.showTooltip && (
              <Tooltip
                message={data.tooltipMessage}
                position="bottom"
                style={{
                  marginLeft: data.margin,
                  fontSize: '14px',
                  fontWeight: 'normal',
                  top: '15px',
                }}
                triangle={{ top: '0px', left: '20px' }}
              >
                <img src={'/images/ic_question_32x32.png'} className={styles.tooltipImg} />
              </Tooltip>
            )}
          </th>
        );
      });
    }

    return theadData.map((data, i) => {
      return (
        <th
          key={i}
          style={{
            width: theadWidth ? `${theadWidth[i]}%` : 'auto',
          }}
        >
          {data}
        </th>
      );
    });
  };

  getOverlayStyle = (start) => {
    const { tbody } = this;
    const { didMounted } = this.state;

    if (tbody && didMounted) {
      const rowLength = filter(tbody.childNodes, (tag) => tag.tagName === 'TR').length;

      return {
        position: 'absolute',
        top: start * 50,
        left: 0,
        height: (rowLength - start) * 50,
        width: '100%',
        backgroundColor: 'rgb(239,239,239,0.5)',
      };
    }
  };

  renderOverRay = () => {
    const { overlay } = this.props;

    if (overlay) {
      if (Number.isInteger(overlay.start)) {
        const overlayStyle = this.getOverlayStyle(overlay.start);

        return (
          <div className={styles.overlay} style={overlayStyle}>
            {overlay.children}
          </div>
        );
      }
    }
  };

  render() {
    const { tbodyData, scrollHeight } = this.props;

    return (
      <table className={styles.table_scroll_style}>
        <thead>
          <tr>{this._renderThead()}</tr>
        </thead>
        <tbody
          ref={(r) => {
            this.props.tbodyRef(r);
            this.tbody = r;
          }}
          style={{ height: '100%', position: 'relative' }}
        >
          {tbodyData}
          {this.renderOverRay()}
        </tbody>
      </table>
    );
  }
}

export default Table;
