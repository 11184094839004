import { TEN_THOUSAND_WON } from 'constants/stringTable';

import costComma from './costComma';

export const getTenThousnadStr = (cost) => {
  return ['0,000', '0'].includes(cost) ? '' : ` ${cost}만원`;
};

export const parseKoreanMoney = (fee) => {
  const strFee = String(fee);

  if (strFee == '-1') {
    return '무한';
  }

  if (strFee == '-2') {
    return '면제';
  }

  if (strFee.indexOf('~') >= 0) {
    if (strFee.split('~')[0] === strFee.split('~')[1]) {
      return `${strFee.split('~')[0]}${TEN_THOUSAND_WON}`;
    }

    return `${strFee}${TEN_THOUSAND_WON}`;
  }

  if (strFee.indexOf('-') >= 0) {
    if (strFee.split('-')[0] === strFee.split('-')[1]) {
      return `${strFee.split('-')[0]}${TEN_THOUSAND_WON}`;
    }

    return `${strFee}${TEN_THOUSAND_WON}`;
  }

  if (strFee.length >= 5) {
    const index = strFee.length - 4;
    const tenMillion = getTenThousnadStr(costComma(strFee.slice(index, strFee.length)));

    return tenMillion
      ? `${costComma(strFee.slice(0, index))}억` + `${tenMillion}`
      : `${costComma(strFee.slice(0, index))}억원`;
  }

  if (strFee.length == 0) {
    return '없음';
  }

  return fee ? `${costComma(fee)}${TEN_THOUSAND_WON}` : '-';
};
