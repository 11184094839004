import { inject, observer } from 'mobx-react';
import React, { Component, Fragment } from 'react';

import Button from '../../../../atoms/Button';
import Input from '../../../../atoms/Input';
import styles from './ClaimModalStyles.module.scss';
@inject('modalStore')
@observer
class NormalConfirmReturnCarModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
  }

  submit = () => {
    if (!this.validation()) {
      return;
    }

    this.props.openNormalSetReturnCarModal();
  };

  validation = () => {
    const rentCarNumber = this.props.contractInfo?.rent_car_number.slice(-4);
    const { value } = this.state;

    if (value?.length < 4) {
      alert('예약 차량번호 뒤 네자리를 입력해 주세요.');

      return false;
    }

    if (rentCarNumber != value) {
      alert('예약 차량 번호와 불일치 합니다. 차량 번호를 확인해주세요.');

      return false;
    }

    return true;
  };

  render() {
    return (
      <div className={styles.normal_contract_return_confirm_modal_wrap}>
        <div className={styles.confirm_text}>
          <div>반납 정보 설정을 위해</div>
          <div>반납 차량 번호 뒤 네자리를 입력해 주세요.</div>
        </div>
        <div className={styles.input_label}>반납 차량 번호 뒤 네자리</div>
        <div className={styles.input_container}>
          <Input
            className={styles.input}
            placeholder={'예) 1234'}
            maxLength={4}
            type={'number'}
            propValue={this.state.value}
            handleChange={(value) => this.setState({ value })}
          />
          <Button className={styles.confirm_button} btnText={'확인'} btnClick={this.submit} />
        </div>
      </div>
    );
  }
}

export default NormalConfirmReturnCarModal;
