import callApi from '../../helpers/callApi';

export default {
  getSearchHistory(params) {
    const serializeForm = { page: params.page };

    if (params.startDate && params.endDate) {
      serializeForm.startDate = params.startDate;
      serializeForm.endDate = params.endDate;
    }

    return callApi({
      method: 'GET',
      url: '/get_auto_up_history',
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  searchAutoUpInfo(params) {
    return callApi({
      disableErrorHandler: params.disableErrorHandler,
      method: 'GET',
      url: '/search_auto_up_info',
      params: {
        car_number: params.car_number,
        from: params.from,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
    });
  },
  setAutoUpCash(params) {
    return callApi({
      method: 'POST',
      url: '/set_use_auto_up_cash',
      data: {
        car_number: params.car_number,
        from_location: params.from_location,
        claim_id: params.claim_id,
      },
    });
  },
};
