export const lotteCostData = {
  A: [
    {
      data_active: true,
      car_type: 'A-1',
      car_name: '스파크 / 더넥스트스파크 / 모닝 / 올뉴모닝',
      day_cost: {
        '1day': 95000,
        '3day': 86000,
        '5day': 81000,
        '7day': 76000,
        '6hour': 53000,
        '10hour': 68000,
        '20hour': 76000,
      },
    },
    {
      data_active: true,
      car_type: 'A-2',
      car_name: '레이',
      day_cost: {
        '1day': 100000,
        '3day': 90000,
        '5day': 85000,
        '7day': 80000,
        '6hour': 56000,
        '10hour': 72000,
        '20hour': 80000,
      },
    },
    {
      data_active: true,
      car_type: 'A-3',
      car_name: '엑센트 / 크루즈 / 프라이드',
      day_cost: {
        '1day': 100000,
        '3day': 90000,
        '5day': 85000,
        '7day': 80000,
        '6hour': 56000,
        '10hour': 72000,
        '20hour': 80000,
      },
    },
    {
      data_active: true,
      car_type: 'A-4',
      car_name: 'K3 / SM3 / 쏘울 / 아반떼 / 아베오',
      day_cost: {
        '1day': 110000,
        '3day': 99000,
        '5day': 94000,
        '7day': 88000,
        '6hour': 62000,
        '10hour': 79000,
        '20hour': 88000,
      },
    },
    {
      data_active: true,
      car_type: 'A-5',
      car_name: 'i30 / 아반떼 하이브리드',
      day_cost: {
        '1day': 120000,
        '3day': 108000,
        '5day': 102000,
        '7day': 96000,
        '6hour': 67000,
        '10hour': 86000,
        '20hour': 96000,
      },
    },
  ],
  B: [
    {
      data_active: false,
      car_type: 'B-1',
      car_name: 'K5 / YF쏘나타 / 말리부 / 쏘나타Y20 / 쏘나타 하이브리드',
      day_cost: {
        '1day': 165000,
        '3day': 149000,
        '5day': 140000,
        '7day': 132000,
        '6hour': 92000,
        '10hour': 119000,
        '20hour': 132000,
      },
    },
    {
      data_active: true,
      car_type: 'B-2',
      car_name: 'NEW SM5 / 올뉴말리부',
      day_cost: {
        '1day': 170000,
        '3day': 153000,
        '5day': 145000,
        '7day': 136000,
        '6hour': 95000,
        '10hour': 122000,
        '20hour': 136000,
      },
    },
    {
      data_active: true,
      car_type: 'B-3',
      car_name: 'K5 MX / K5 SX / LF쏘나타 / SM6',
      day_cost: {
        '1day': 175000,
        '3day': 158000,
        '5day': 149000,
        '7day': 140000,
        '6hour': 98000,
        '10hour': 126000,
        '20hour': 140000,
      },
    },
    {
      data_active: false,
      car_type: 'B-4',
      car_name: 'i40 SALOON',
      day_cost: {
        '1day': 200000,
        '3day': 180000,
        '5day': 170000,
        '7day': 160000,
        '6hour': 112000,
        '10hour': 144000,
        '20hour': 160000,
      },
    },
  ],
  C: [
    {
      data_active: true,
      car_type: 'C-1',
      car_name: 'ALL NEW K7 2.4 / K7 2.4 / SM7 2.0, 2.5 / 그랜저 HG240',
      day_cost: {
        '1day': 267000,
        '3day': 240000,
        '5day': 227000,
        '7day': 214000,
        '6hour': 150000,
        '10hour': 193000,
        '20hour': 214000,
      },
    },
    {
      data_active: true,
      car_type: 'C-2',
      car_name:
        'ALL NEW K7 3.0 / K7 2.7, 3.0 / 그랜저 HG220, HG300 / 그랜저 IG 2.2, 3.0 / 임팔라 2.5 / 스팅어 2.2(D)',
      day_cost: {
        '1day': 299000,
        '3day': 269000,
        '5day': 254000,
        '7day': 239000,
        '6hour': 167000,
        '10hour': 215000,
        '20hour': 239000,
      },
    },
    {
      data_active: true,
      car_type: 'C-3',
      car_name: '아슬란 3.0',
      day_cost: {
        '1day': 377000,
        '3day': 339000,
        '5day': 320000,
        '7day': 302000,
        '6hour': 211000,
        '10hour': 272000,
        '20hour': 302000,
      },
    },
    {
      data_active: true,
      car_type: 'C-4',
      car_name: '체어맨 W CW600',
      day_cost: {
        '1day': 378000,
        '3day': 340000,
        '5day': 321000,
        '7day': 302000,
        '6hour': 211000,
        '10hour': 272000,
        '20hour': 302000,
      },
    },
    {
      data_active: true,
      car_type: 'C-5',
      car_name: '체어맨 W600 / 제네시스 330 / K9 330',
      day_cost: {
        '1day': 399000,
        '3day': 359000,
        '5day': 339000,
        '7day': 319000,
        '6hour': 223000,
        '10hour': 287000,
        '20hour': 319000,
      },
    },
    {
      data_active: true,
      car_type: 'C-6',
      car_name: 'G80 3.8 / K9 3.8 / THE NEW K9 3.8 / 에쿠스 JS380, VS380 / 제네시스 3.8',
      day_cost: {
        '1day': 447000,
        '3day': 402000,
        '5day': 380000,
        '7day': 358000,
        '6hour': 251000,
        '10hour': 322000,
        '20hour': 358000,
      },
    },
    {
      data_active: true,
      car_type: 'C-7',
      car_name: 'EQ900 3.3T / EQ900 3.8',
      day_cost: {
        '1day': 470000,
        '3day': 423000,
        '5day': 400000,
        '7day': 376000,
        '6hour': 263000,
        '10hour': 338000,
        '20hour': 376000,
      },
    },
    {
      data_active: true,
      car_type: 'C-8',
      car_name: 'THE NEW K9 5.0',
      day_cost: {
        '1day': 500000,
        '3day': 450000,
        '5day': 425000,
        '7day': 400000,
        '6hour': 280000,
        '10hour': 360000,
        '20hour': 400000,
      },
    },
    {
      data_active: true,
      car_type: 'C-9',
      car_name: '에쿠스 VS500',
      day_cost: {
        '1day': 550000,
        '3day': 495000,
        '5day': 468000,
        '7day': 440000,
        '6hour': 308000,
        '10hour': 396000,
        '20hour': 440000,
      },
    },
    {
      data_active: true,
      car_type: 'C-10',
      car_name: 'GENESIS EQ900 5.0',
      day_cost: {
        '1day': 580000,
        '3day': 522000,
        '5day': 493000,
        '7day': 464000,
        '6hour': 325000,
        '10hour': 418000,
        '20hour': 464000,
      },
    },
    {
      data_active: true,
      car_type: 'C-11',
      car_name: '에쿠스 VL500',
      day_cost: {
        '1day': 600000,
        '3day': 540000,
        '5day': 510000,
        '7day': 480000,
        '6hour': 336000,
        '10hour': 432000,
        '20hour': 480000,
      },
    },
  ],
  D: [
    {
      data_active: true,
      car_type: 'D-1',
      car_name: 'QM3 / 스포티지R / 올란도 / 올뉴카렌스 / 코란도C / 투싼 ix / 트랙스 / 티볼리',
      day_cost: {
        '1day': 180000,
        '3day': 162000,
        '5day': 153000,
        '7day': 144000,
        '6hour': 101000,
        '10hour': 130000,
        '20hour': 144000,
      },
    },
    {
      data_active: true,
      car_type: 'D-2',
      car_name: '올뉴스포티지 / 올뉴투싼',
      day_cost: {
        '1day': 195000,
        '3day': 176000,
        '5day': 166000,
        '7day': 156000,
        '6hour': 109000,
        '10hour': 140000,
        '20hour': 156000,
      },
    },
    {
      data_active: true,
      car_type: 'D-3',
      car_name: 'QM5 / 캡티바',
      day_cost: {
        '1day': 200000,
        '3day': 180000,
        '5day': 170000,
        '7day': 160000,
        '6hour': 112000,
        '10hour': 144000,
        '20hour': 160000,
      },
    },
    {
      data_active: true,
      car_type: 'D-4',
      car_name: '그랜드 스타렉스 11인승, 12인승',
      day_cost: {
        '1day': 220000,
        '3day': 198000,
        '5day': 187000,
        '7day': 176000,
        '6hour': 123000,
        '10hour': 158000,
        '20hour': 176000,
      },
    },
    {
      data_active: true,
      car_type: 'D-5',
      car_name: '올뉴쏘렌토 / 싼타페',
      day_cost: {
        '1day': 245000,
        '3day': 221000,
        '5day': 208000,
        '7day': 196000,
        '6hour': 137000,
        '10hour': 176000,
        '20hour': 196000,
      },
    },
    {
      data_active: true,
      car_type: 'D-6',
      car_name: '뉴카니발 9인승',
      day_cost: {
        '1day': 270000,
        '3day': 243000,
        '5day': 230000,
        '7day': 216000,
        '6hour': 151000,
        '10hour': 194000,
        '20hour': 216000,
      },
    },
    {
      data_active: true,
      car_type: 'D-7',
      car_name: '올뉴카니발 7인승 리무진 / 코란도 투리스모 9인승 / 코란도 투리스모 11인승',
      day_cost: {
        '1day': 290000,
        '3day': 261000,
        '5day': 247000,
        '7day': 232000,
        '6hour': 162000,
        '10hour': 209000,
        '20hour': 232000,
      },
    },
    {
      data_active: true,
      car_type: 'D-8',
      car_name: '그랜드카니발 11인승 / 맥스크루즈 / 베라크루즈 / 올 뉴 카니발 11인승',
      day_cost: {
        '1day': 299000,
        '3day': 269000,
        '5day': 254000,
        '7day': 239000,
        '6hour': 167000,
        '10hour': 215000,
        '20hour': 239000,
      },
    },
    {
      data_active: true,
      car_type: 'D-9',
      car_name: '모하비',
      day_cost: {
        '1day': 350000,
        '3day': 315000,
        '5day': 298000,
        '7day': 280000,
        '6hour': 196000,
        '10hour': 252000,
        '20hour': 280000,
      },
    },
    {
      data_active: true,
      car_type: 'D-10',
      car_name:
        '올뉴카니발 7인승 하이리무진 / 올뉴카니발 9인승 하이리무진 / 그랜드카니발 11인승 하이리무진',
      day_cost: {
        '1day': 370000,
        '3day': 330000,
        '5day': 315000,
        '7day': 296000,
        '6hour': 207000,
        '10hour': 266000,
        '20hour': 296000,
      },
    },
  ],
};
