import * as S from './style';

export const AddressItem = ({
  name,
  address,
  jibunAddress,
  onClick,
}: {
  name: string;
  address: string;
  jibunAddress: string;
  onClick: (addressType: string) => void;
}) => {
  return (
    <S.RootWrapper>
      <S.Summary>{name}</S.Summary>

      <div onClick={() => onClick('jibunAddress')} style={{ display: 'flex' }}>
        <S.RoadName>지번</S.RoadName>
        <S.SpecificAddress>{jibunAddress}</S.SpecificAddress>
      </div>
      <div onClick={() => onClick('roadNameAddress')} style={{ display: 'flex' }}>
        <S.RoadName>도로명</S.RoadName>
        <S.SpecificAddress>{address}</S.SpecificAddress>
      </div>
    </S.RootWrapper>
  );
};
