import dayjs from 'dayjs';
import { inject, observer } from 'mobx-react';
import React, { Component } from 'react';

import apiRentContract from '../../../api/rentContract';
import Button from '../../../atoms/Button/index.tsx';
import SearchInput from '../../../components/SearchInput';
import Table from '../../../components/Table';
import Paging from '../../Paging';
import styles from './SearchDriverModal.module.scss';

@inject('authStore')
@inject('menuStore')
@inject('modalStore')
@observer
class SearchDriverModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: null,
      selectedId: null,
      selectedItem: null,
      tableData: [],
      currentPage: 1,
      totalPage: 1,
      loading: true,
    };
  }

  componentDidMount() {
    this.getDriverList();
  }

  getDriverList = async () => {
    this.setState({ callApi: true });
    const params = {
      menuStore: this.props.menuStore,
      authStore: this.props.authStore,
      searchValue: this.state.searchValue,
      page: this.state.currentPage,
    };

    const response = await apiRentContract.getDriverList(params);
    const responseData = response.data;

    if (response.status === 200) {
      const customersData = responseData.customers.map((data, i) => {
        return { ...data, id: i + (this.state.currentPage - 1) * 20 };
      });

      this.setState({
        tableData: customersData,
        totalPage: responseData.total_page,
        selectedId: this.props.selectedId || null,
        loading: false,
      });
    }

    setTimeout(() => this.setState({ callApi: false }), 1000);
  };

  search = (e) => {
    e.preventDefault();

    if (this.state.searchValue && !this.state.callApi) {
      this.setState({ currentPage: 1 }, this.getDriverList);
    }
  };

  resetSearch = () => {
    if (this.state.searchValue !== null)
      this.setState({ searchValue: null }, () => this.getDriverList());
  };

  select = () => {
    const { selectedItem } = this.state;

    if (selectedItem) {
      this.props.setDriverInfo(selectedItem, this.props.keyName);
      this.props.modalStore.modalClose();
    } else {
      this.props.modalStore.modalClose();
    }
  };

  movePage = (page) => {
    this.setState({ currentPage: page }, () => this.getDriverList());
  };

  renderTableData = () => {
    const { tableData } = this.state;
    const setHyphen = (contact) => {
      const contactArr = Array.from(contact);

      contactArr.splice(3, 0, '-');
      contactArr.splice(8, 0, '-');

      return contactArr.join('');
    };

    if (tableData.length > 0) {
      return tableData.map((item, i) => {
        let checkboxStyle;
        const isSelected = item.id === this.state.selectedId;

        if (isSelected) {
          checkboxStyle = styles.active_checkbox;
        } else {
          checkboxStyle = styles.inactive_checkbox;
        }

        return (
          <tr
            key={i}
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ selectedItem: item, selectedId: item.id })}
          >
            <td key={i}>
              <div className={`${styles.checkbox_div} ${checkboxStyle}`}>
                <img src={'/images/checkboxImage/check_icon_white_thin.png'} />
              </div>
            </td>
            <td>{item.name}</td>
            <td>{item.id_number1}</td>
            <td>{setHyphen(item.contact)}</td>
            <td>{dayjs(item.latest_delivered_datetime).format('YY.MM.DD')}</td>
          </tr>
        );
      });
    }

    return null;
  };

  render() {
    const { usingCustomerSearch } = this.props;

    return (
      <div className={styles.modal_wrap}>
        <div className={styles.modal_header_area}>
          <div className={styles.search_area}>
            <form className={styles.search_form} onSubmit={this.search}>
              <SearchInput
                type="text"
                placeholder={`${usingCustomerSearch ? '고객' : '운전자'}명 또는 연락처 검색`}
                onChange={(searchValue) => this.setState({ searchValue })}
                value={this.state.searchValue}
              />
            </form>
            <Button
              btnText="초기화"
              btnClick={this.resetSearch}
              className={styles.reset_button}
              outline={true}
            />
          </div>
          <p>{`* 계약서 작성 이력이 있는 ${
            usingCustomerSearch ? '고객' : '운전자'
          }만 검색됩니다.`}</p>
        </div>
        <div className={styles.table_area}>
          <Table
            theadWidth={[10, 20, 20, 30, 30]}
            theadData={[
              '선택',
              `${usingCustomerSearch ? '고객' : '운전자'}명`,
              '생년월일',
              '연락처',
              '배차일',
            ]}
            tbodyData={this.renderTableData()}
            theadStyle={{ fontSize: '13px', fontWeight: 'bold' }}
            emptyTable={{
              text: this.state.loading
                ? '로딩중...'
                : usingCustomerSearch
                ? '검색된 결과가 없습니다.'
                : '선택 가능한 운전자가 없습니다.',
              style: { fontSize: '13px', padding: '55px', border: 'none' },
            }}
          />
        </div>
        <Paging
          currentPage={this.state.currentPage}
          totalPage={this.state.totalPage}
          movePage={this.movePage}
        />
        <div className={styles.button_area}>
          <Button
            btnText="취소"
            btnClick={() => this.props.modalStore.modalClose()}
            className={styles.cancel_button}
            outline={true}
          />
          <Button btnText="선택 완료" className={styles.select_button} btnClick={this.select} />
        </div>
      </div>
    );
  }
}

export default SearchDriverModal;
