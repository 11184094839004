import callApi from '../../helpers/callApi';

export default {
  connectLogin(params) {
    return callApi({
      method: 'POST',
      url: 'v3/connect/login',
      data: {
        email: params.email,
        password: params.password,
      },
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  connectLogout() {
    return callApi({
      method: 'POST',
      url: 'v3/connect/logout',
    });
  },
  getCarbomWebUrl(params) {
    return callApi({
      method: 'GET',
      url: 'v3/connect/web-url',
      params: {
        car_identity: params?.car_identity ?? null,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      disableErrorHandler: params.disableErrorHandler,
      serverCookie: params.cookie || null,
    });
  },
  connectCarInterlock(data) {
    return callApi({
      method: 'POST',
      url: `v2/rent-company-cars/${data.carId}/update/connect`,
      data: {
        use_connect: data.useConnect,
      },
      disableErrorHandler: data.disableErrorHandler,
    });
  },
  getCarData(params) {
    return callApi({
      method: 'GET',
      url: `v3/connect/car/detail`,
      params: {
        car_identity: params?.carId,
        disableErrorHandler: params?.disableErrorHandler ?? null,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getDriverData(params) {
    return callApi({
      method: 'GET',
      url: `v3/connect/driver`,
      params: {
        rent_contract_id: params?.rentContractId ? String(params?.rentContractId) : null,
        normal_contract_detail_id: params?.normalContractDetailId
          ? String(params?.normalContractDetailId)
          : null,
      },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
      disableErrorHandler: params?.disableErrorHandler ?? null,
    });
  },
  getCarControlAuth(params) {
    return callApi({
      method: 'POST',
      url: `v2/rental-company-workers/${params.id}/update/connect`,
      data: { use_connect: params.useConnect },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
  getAlimtalkAuth(params) {
    return callApi({
      method: 'POST',
      url: `v2/rental-company-workers/${params.id}/update/alimtalk`,
      data: { enable: params.isTalk },
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie || null,
    });
  },
};
