import * as callApi2 from '../../helpers/callApi2';

export type EtcContractItem = {
  content: string;
  created_at: string;
  id: number;
  title: string;
};

type GetEtcContractListResponse =
  | callApi2.GetSuccessResponse<{ other_contract_details: EtcContractItem[] }>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

/** 기타 계약사항 목록 */
export const getEtcContractList = async () => {
  const response = await callApi2.get<GetEtcContractListResponse>(`/v2/other-contract-detail`, {});

  return response;
};
