import callApi from '../../helpers/callApi';

export * from './deletePartner';
export * from './getAutoResponseAgreeState';
export * from './getPartnerDetail';
export * from './getPartnerList';
export * from './getPolicies';
export * from './insertPartner';
export * from './updatePartner';
export default {
  getPartnerList(params) {
    const serializeForm = {};

    if (params?.searchOptionValue && params?.searchValue) {
      serializeForm.option = params.searchOptionValue;
      serializeForm.value = decodeURIComponent(params.searchValue);
    }

    return callApi({
      method: 'GET',
      url: `/v2/partners?page=${params.page}`,
      params: serializeForm,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  removePartner(params) {
    return callApi({
      method: 'POST',
      url: `/v2/partners/${params.id}/delete`,
    });
  },
  registerPartner(params) {
    return callApi({
      method: 'POST',
      url: '/v2/partners',
      data: params,
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  partnerDetail(params) {
    return callApi({
      method: 'GET',
      url: `/v2/partners/${params.id}`,
      stores: {
        menuStore: params.menuStore,
        authStore: params.authStore,
      },
      serverCookie: params.cookie,
    });
  },
  editPartner(params) {
    return callApi({
      method: 'POST',
      url: `/v2/partners/${params.id}`,
      data: params,
    });
  },
  registerPartnerExcel(params) {
    return callApi({
      method: 'POST',
      url: '/v2/partners/excel',
      data: params,
      disableErrorHandler: params.disableErrorHandler,
    });
  },
  autoResponseAgree(params) {
    return callApi({
      method: 'POST',
      url: '/v2/rent-company/auto-response/agree',
      data: params,
    });
  },
  getDeliveryTime(params) {
    return callApi({
      method: 'GET',
      url: '/v2/delivery-time',
      stores: {
        menuStore: params?.menuStore,
        authStore: params?.authStore,
      },
      serverCookie: params?.cookie,
    });
  },
  setDeliveryTime(params) {
    return callApi({
      method: 'POST',
      url: `/v3/delivery-time`,
      data: params,
    });
  },
};
