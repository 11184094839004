import * as callApi2 from '../../helpers/callApi2';

type PutEtcContractResponse =
  | callApi2.SuccessResponse<{ success: boolean; id: number }>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

type PutEtcContractBody = {
  title: string;
  content: string;
};

/** 기타계약사항 수정 */
export const putEtcContractApi = async (body: PutEtcContractBody, id: number) => {
  const response = await callApi2.put<PutEtcContractResponse>(
    `/v2/other-contract-detail/${id}`,
    body
  );

  return response;
};
