import { inject, observer } from 'mobx-react';
import { NextRouter, withRouter } from 'next/router';
import React, { Component } from 'react';

import { routerLoadingAndPush } from '../../../helpers/routerHelper';
import ConfirmAlert from '../../Alert/ConfirmAlert';
import styles from './WebContractGuide.module.scss';

type PropsType = {
  data: any;
  router?: NextRouter;
  modalStore?: any;
  loadingStore?: any;
};

@(withRouter as any)
@inject('modalStore')
@inject('loadingStore')
@observer
class WebContractGuide extends Component<PropsType> {
  constructor(props: PropsType) {
    super(props);
  }

  getContent = () => {
    return (
      <>
        <div className={styles.text}>
          <div>천재지변이나 서버다운등의 사유가 아닐 경우에 PC로</div>
          <div>계약서를 작성하시면 페널티 적용 대상이 됩니다.</div>
        </div>
      </>
    );
  };

  writeContract = () => {
    if (this.props.router) {
      routerLoadingAndPush({
        url: `/normalContract/register?id=${this.props.data?.id}&response_id=${this.props.data?.response_id}`,
        router: this.props.router,
        loadingStore: this.props.loadingStore,
      });
    }
  };

  render() {
    const cancelBtnStyle = { width: 74, padding: 0 };
    const confirmBtnStyle = { width: 108 };

    return (
      <ConfirmAlert
        content={this.getContent()}
        onClickConfirm={() => {
          this.writeContract();
          this.props.modalStore?.modalClose();
        }}
        cancelBtnStyle={cancelBtnStyle}
        confirmBtnStyle={confirmBtnStyle}
      />
    );
  }
}

export default WebContractGuide;
