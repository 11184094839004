import * as callApi2 from '../../helpers/callApi2';

type AddEtcContractResponse =
  | callApi2.SuccessResponse<{ success: boolean; id: number }>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

type AddEtcContractBody = {
  title: string;
  content: string;
};

/** 기타 계약사항 등록 */
export const addEtcContractApi = async (body: AddEtcContractBody) => {
  const response = await callApi2.post<AddEtcContractResponse>('/v2/other-contract-detail', body);

  return response;
};
