/* eslint-disable no-case-declarations */
import filter from 'lodash/filter';
import React, { Component } from 'react';

import Tooltip from '../../components/Tooltip';
import styles from './Table.module.scss';

class Table extends Component {
  constructor(props) {
    super(props);
    this.tbody = null;
  }

  tableClass = () => {
    if (this.props.small) {
      return styles.small_style;
    }

    if (this.props.scrollHeight) {
      return styles.table_scroll_style;
    }

    if (this.props.notice) {
      return styles.table_notice_style;
    }

    return `${styles.table_style} ${this.props.containerClass || ''}`;
  };

  // 스타일 확장성 개선 - 기존 theadStyle -> theadWidth 로 사용
  /* ex) theadStyle={
    marginRight: [ 0, 20, 20, 20 ] >> 1,2,3 번째 열만 20 적용
    marginRight: 20 >> 모든 열 20 적용
  } */

  /**
   * basic table style - theadData, theadWidth, theadStyle, headFix, theadFontWeight (테이블 전체 적용)
   * theadPartStyle (thead column head 별로 스타일 적용)
   */
  renderThead = () => {
    const { theadClass, theadData, theadWidth, theadStyle, headFix, thead } = this.props;

    if (thead) {
      return thead;
    }

    return (
      <thead className={theadClass || ''}>
        <tr>
          {theadData.map((data, i) => {
            const thStyle = data.theadPartStyle;
            const thStyles = {
              fontWeight: this.props.theadFontWeight,
            };
            // if (headFix) thStyles.borderTop = 'none';

            if (theadStyle && typeof theadStyle === 'object') {
              Object.keys(theadStyle).map((key) => {
                Object.assign(thStyles, {
                  [key]: Array.isArray(theadStyle[key]) ? theadStyle[key][i] : theadStyle[key],
                });
              });
            }

            let returnTh = null;
            const thCombindStyles = { ...thStyles, ...(thStyle || {}) };

            if (typeof data === 'string') {
              returnTh = (
                <th
                  key={i}
                  width={theadWidth ? `${theadWidth[i]}%` : null}
                  style={thCombindStyles}
                  className={headFix && styles.head_fix}
                >
                  {data}
                </th>
              );
            } else {
              returnTh = this.renderTypeTh(data, i, theadWidth, thCombindStyles);
            }

            return returnTh;
          })}
        </tr>
      </thead>
    );
  };

  renderTypeTh = (data, i, theadWidth, style) => {
    const { headFix } = this.props;

    switch (data.type) {
      case 'text':
        let thStyle = style;

        if (data.showTooltip) {
          thStyle.minWidth = '80px';
          thStyle.paddingBottom = '6px';
        }

        // if (headFix) thStyle.borderTop = 'none';
        return (
          <th
            className={headFix && styles.head_fix}
            key={i}
            width={theadWidth ? `${theadWidth[i]}%` : null}
            style={thStyle}
          >
            {Boolean(data?.necessary) && (
              <img src="/images/ic-input-necessary@3x.png" style={{ width: 6, marginRight: 5 }} />
            )}
            {data.label}
            {data.showTooltip && (
              <Tooltip
                message={data.tooltipMessage}
                position="bottom"
                style={{
                  marginLeft: data.margin,
                  fontSize: '14px',
                  fontWeight: 'normal',
                  top: data.top ? data.top : '15px',
                }}
                triangle={{
                  top: '0px',
                  left: data?.flipHorizontal ? 'auto' : '20px',
                  right: data?.flipHorizontal ? '20px' : 'auto',
                  ...data.triangleStyle,
                }}
              >
                <img
                  // src={"/images/ic_question_32x32.png"}
                  src="/images/question/question-white.png"
                  alt="question-mark-icon"
                  className={styles.tooltipImg}
                />
              </Tooltip>
            )}
          </th>
        );
      case 'checkbox':
        let checkboxStyle;

        if (data.checked) {
          checkboxStyle = styles.active_checkbox;
        } else {
          checkboxStyle = styles.inactive_checkbox;
        }

        return (
          <th
            key={i}
            width={theadWidth ? `${theadWidth[i]}%` : null}
            className={headFix && styles.head_fix}
          >
            <div className={`${styles.checkbox_div} ${checkboxStyle}`} onClick={data.onClick}>
              <img src="/images/checkboxImage/check_icon_white_thin.png" />
            </div>
          </th>
        );
    }
  };

  getOverlayStyle = (start) => {
    const { tbody } = this;

    if (tbody) {
      const rowLength = filter(tbody.childNodes, (tag) => tag.tagName === 'TR').length;

      return {
        position: 'absolute',
        top: start * 50,
        left: 0,
        height: (rowLength - start) * 50,
        width: '100%',
        backgroundColor: 'rgb(239,239,239,0.5)',
      };
    }
  };

  renderOverRay = () => {
    const { overlay } = this.props;

    if (overlay) {
      if (Object.prototype.hasOwnProperty.call(overlay, 'start')) {
        return (
          <div className={styles.overlay} style={this.getOverlayStyle(overlay.start)}>
            {overlay.children}
          </div>
        );
      }
    }
  };

  getOverlayStyle = (start) => {
    const { tbody } = this;

    if (tbody) {
      const rowLength = filter(tbody.childNodes, (tag) => tag.tagName === 'TR').length;

      return {
        position: 'absolute',
        top: start * 50,
        left: 0,
        height: (rowLength - start) * 50,
        width: '100%',
        backgroundColor: 'rgb(239,239,239,0.5)',
      };
    }
  };

  renderOverRay = () => {
    const { overlay } = this.props;

    if (overlay) {
      if (Object.prototype.hasOwnProperty.call(overlay, 'start')) {
        return (
          <div className={styles.overlay} style={this.getOverlayStyle(overlay.start)}>
            {overlay.children}
          </div>
        );
      }
    }
  };

  render() {
    const { tbodyData, scrollHeight, emptyTable } = this.props;
    const tbodyStyle = {
      maxHeight: scrollHeight ? scrollHeight : 'auto',
      position: 'relative',
      backgroundColor: 'none',
    };

    return (
      <table className={this.tableClass()}>
        {this.renderThead()}
        <tbody ref={(r) => (this.tbody = r)} style={tbodyStyle}>
          {(Boolean(tbodyData) && tbodyData.length > 0) || !emptyTable ? (
            tbodyData
          ) : (
            <tr>
              <td colSpan="100%" style={emptyTable.style || {}}>
                {emptyTable.text}
              </td>
            </tr>
          )}
          {this.renderOverRay()}
        </tbody>
      </table>
    );
  }
}

export default Table;
