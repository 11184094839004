import React from 'react';

import Input from '../../atoms/Input/index.tsx';
import styles from './SearchInput.module.scss';

const SEARCH_ICON = '/images/searchImages/search_black_icon.png';

const SearchInput = (props) => {
  return (
    <div style={props.style} className={styles.search_input_btn_area}>
      <Input
        type={props.type}
        placeholder={props.placeholder}
        className={styles.search_input}
        handleChange={props.onChange}
        propValue={props.value || ''}
        inputRef={props.inputRef}
      />
      <button type="submit">
        <img src={SEARCH_ICON} />
      </button>
    </div>
  );
};

export default SearchInput;
