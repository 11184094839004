import { omit } from 'lodash';

import * as callApi2 from '../../helpers/callApi2';

export interface IDeletePeriodCostParams {
  id: number;
}

type SuccessResponse = {
  id: number;
  success: boolean;
};
export type IDeletePeriodCostResponse =
  | callApi2.SuccessResponse<SuccessResponse>
  | callApi2.ErrorResponse<105, 'InvalidValueError', 400>;

export async function deletePeriod(params: IDeletePeriodCostParams) {
  return callApi2._delete<IDeletePeriodCostResponse>(
    `/v2/group-cost-tables/periods/${params.id}`,
    omit(params, ['id'])
  );
}
