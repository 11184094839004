import selfContractInfo, { uploadSignImage } from 'api/selfContractInfo';
import Button from 'atoms/Button';
import { useBottomSheet } from 'hooks/useBottomSheet';
import { ComponentTypeWithRouter } from 'interface/utils';
import { withRouter } from 'next/router';
import { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';

import styles from './ContractSignBottomSheet.module.scss';

type ContractSignBottomSheetProps = {
  contractId: string;
  contactNum: string;
};

const ContractSignBottomSheet = (props: ContractSignBottomSheetProps & ComponentTypeWithRouter) => {
  const { closeBottomSheet } = useBottomSheet();

  const [isDisabled, setIsDisabled] = useState(true);
  const [apiResponse, setApiResponse] = useState(true);

  const signCanvasRef = useRef<SignatureCanvas>();

  const uploadSignImg = async () => {
    const signCanvas = signCanvasRef.current?.getCanvas();
    const getBlob = () => {
      return new Promise((resolve, reject) => {
        signCanvas.toBlob((value) => {
          resolve(value);
        });
      });
    };

    const blob = (await getBlob()) as Blob;
    const formData = new FormData();

    formData.append('File', blob, 'filename.png');
    const params = { file: formData };

    const signImageInfo = await uploadSignImage(params);

    return signImageInfo;
  };

  const submit = async () => {
    if (isDisabled || !apiResponse) return;

    setApiResponse(false);
    catchAsync(
      async () => {
        const uploadImage = await uploadSignImg();

        const params = {
          // @ts-ignore: Unreachable code error
          signatureImage: uploadImage.filename,
          contactNum: props.contactNum,
          id: props.contractId,
        };

        const response = await selfContractInfo.updateContractSignature(params);
        const responseData = response.data;

        if (responseData.success === true) {
          props.router.replace({
            pathname: '/mobile/selfInfoSignature/complete',
            query: { id: props.contractId, companyName: props.router.query.companyName },
          });
          closeBottomSheet('CONTRACT_SIGN');

          return;
        }

        props.router.replace({
          pathname: '/mobile/selfInfoSignature/expire',
          query: { id: props.contractId, companyName: props.router.query.companyName },
        });
        closeBottomSheet('CONTRACT_SIGN');
      },
      () => setApiResponse(false)
    );
  };

  const signReset = () => {
    signCanvasRef.current?.clear();
    setIsDisabled(true);
  };

  const signEnd = () => {
    setIsDisabled(false);
  };

  return (
    <div className={styles.wrap}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <p className={styles.title}>서명하기</p>
        <img
          src={'/images/ic-line-close-black.png'}
          width={20}
          height={20}
          onClick={() => closeBottomSheet('CONTRACT_SIGN')}
        />
      </div>
      <div className={styles.sign_canvas_area}>
        <SignatureCanvas
          ref={signCanvasRef}
          penColor="black"
          onEnd={signEnd}
          canvasProps={{ style: { width: '100%', height: '100%' } }}
        />
      </div>
      <div className={styles.button_container}>
        <Button className={styles.sign_reset_button} btnClick={signReset} outline>
          초기화
        </Button>
        <Button
          id="sign_self_info_sign"
          className={`
            ${styles.sign_sumit_button}
            ${isDisabled ? styles.button_inactive : styles.button_active}
            `}
          btnClick={submit}
        >
          서명 완료
        </Button>
      </div>
    </div>
  );
};

export default withRouter(ContractSignBottomSheet);
