import styled from 'styled-components';

export const NonResult = () => {
  return (
    <div>
      <TotalCount>
        총 <CountNumber>0</CountNumber>건
      </TotalCount>

      <Caution>검색 결과가 없습니다.</Caution>
    </div>
  );
};

const TotalCount = styled.h2`
  font-size: 16px;
  font-weight: bold;

  margin-top: 20px;
  margin-bottom: 13px;
`;

const CountNumber = styled.span`
  color: #0088fd;
`;

const Caution = styled.div`
  border: 1px solid #efefef;
  padding: 23px 0;
  border-right: none;
  border-left: none;
  font-size: 14px;
`;
