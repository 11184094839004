import { withRouter } from 'next/router';
import React from 'react';

import Button from '../../atoms/Button/index.tsx';
import styles from './MobileNotFound.module.scss';

const MobileNotFound = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.content_wrap}>
        <img src="/images/notfoundImages/img_nopage.png" />
        <div className={styles.right_block}>
          <p className={styles.content_text_title}>페이지를 찾을 수 없습니다.</p>
          <p className={styles.content_text}>주소가 맞는지 확인해 본 후 다시 한번 시도해보세요.</p>
          <Button
            className={styles.back_btn}
            onClick={() => {
              if (props.goBack) {
                props.goBack();

                return;
              }

              props.router.back();
            }}
            btnText="이전 페이지로"
          />
        </div>
      </div>
    </div>
  );
};

export default withRouter(MobileNotFound);
